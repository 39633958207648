import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { POPUP_CONTAINER_CHOICES } from 'o2x-store/src/models/Tutorial';
import { useStore } from 'o2x-store/src/stores';
import { EMOJI_NAMES } from 'o2x-store/src/utils/emoji';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Animated,
  FlatList,
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import EmojiSelector from 'src/components/EmojiSelector';
import NavigationBar from 'src/components/NavigationBar';
import TeamActivityItem from 'src/components/Teams/TeamActivityItem';
import UserReactions from 'src/components/UserReactions';
import config from 'src/config';
import SettingsIcon from '../../assets/images/settings.svg';
import Popup from '../../components/Popup';

type Props = {
  route: any;
};

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
const TeamDetail: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const team = store.teams.teams.get(`${id}`);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState<boolean>(false);
  const [visibleReactions, setVisibleReactions] = useState<boolean>(false);
  const titleOpacity = useRef(new Animated.Value(0));

  useFocusEffect(
    useCallback(() => {
      if (team) {
        store.teams.fetchTeamActivity(team.id, page);
      }
    }, [team, page]),
  );

  const resetPage = useCallback(() => {
    setPage(1);
  }, [setPage]);

  const incrementPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
  }, [setPage]);

  const fetchNextActivities = useCallback(async () => {
    if (team) {
      const result = await store.teams.fetchTeamActivity(team.id, page + 1);
      if (result.ok) {
        incrementPage();
      }
    }
  }, [team, page]);

  const refreshActivities = useCallback(async () => {
    if (team) {
      setRefreshing(true);
      const result = await store.teams.fetchTeamActivity(team.id, 1);
      if (result.ok) {
        resetPage();
      }
      setRefreshing(false);
    }
  }, [team, page, setRefreshing]);

  const onAddReaction = useCallback(
    (key: EMOJI_NAMES) => {
      if (team && selectedActivity) {
        store.teams.addReaction(key, selectedActivity, `${team.id}`);
        setVisibleEmojiPicker(false);
      }
    },
    [selectedActivity, team, setVisibleEmojiPicker],
  );

  const toggleEmojiPicker = useCallback(() => {
    setVisibleEmojiPicker((prev) => !prev);
    setVisibleReactions(false);
  }, [setVisibleEmojiPicker, setVisibleReactions]);

  const closeEmojiPicker = useCallback(() => {
    setVisibleEmojiPicker(false);
  }, [setVisibleEmojiPicker]);

  const toggleReactions = useCallback(() => {
    setVisibleReactions((prev) => !prev);
    setVisibleEmojiPicker(false);
  }, [setVisibleEmojiPicker, setVisibleReactions]);

  const closeReactions = useCallback(() => {
    setVisibleReactions(false);
  }, [setVisibleReactions]);

  const renderActivity = useCallback(
    ({ item }) => (
      <TeamActivityItem
        activity={item}
        onSelectEmoji={() => {
          setSelectedActivity(`${item.id}`);
          toggleEmojiPicker();
        }}
        onViewReactions={() => {
          setSelectedActivity(`${item.id}`);
          toggleReactions();
        }}
      />
    ),
    [toggleEmojiPicker, setSelectedActivity],
  );

  const renderHeader = useCallback(
    () => (
      <Layout style={styles.listHeaderContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={
                team?.logoThumbnail
                  ? { uri: team.logoThumbnail }
                  : require('../../assets/images/icon.png')
              }
            />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{team?.name}</Text>
              <Text style={styles.subtitle}>
                {team?.members.length} {Humanize.pluralize(team?.members.length, 'member')}
              </Text>
            </View>
          </View>
          {isAuthor && (
            <TouchableOpacity style={styles.settingsContainer} onPress={onEdit}>
              <SettingsIcon style={styles.settingsIcon} />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.listHeader}>
          <Text style={styles.subtitle}>RECENT ACTIVITY</Text>
        </View>
      </Layout>
    ),
    [team],
  );

  const onEdit = useCallback(() => {
    navigation.navigate('TeamEdit', { teamId: id });
  }, [id]);

  const isAuthor = useMemo(
    () => team && store.auth.user && store.auth.user?.id === team?.user,
    [team, store.auth.user],
  );

  return (
    <Layout style={[styles.container, { marginBottom: insets.bottom }]}>
      <Popup component={POPUP_CONTAINER_CHOICES.TEAM_DETAIL} />
      <View style={[styles.scrollContainer, { marginTop: insets.top }]}>
        <Layout style={styles.content}>
          {!!team && (
            <AnimatedFlatList
              style={styles.activityList}
              data={team.teamActivities}
              renderItem={renderActivity}
              ListHeaderComponent={renderHeader}
              keyExtractor={(item) => `activity-${item.id}`}
              onEndReached={fetchNextActivities}
              onRefresh={refreshActivities}
              refreshing={refreshing}
              onScroll={Animated.event(
                [
                  {
                    nativeEvent: { contentOffset: { y: titleOpacity.current } },
                  },
                ],
                { useNativeDriver: true },
              )}
            />
          )}
        </Layout>
      </View>
      <NavigationBar
        style={[styles.navigation, { top: insets.top }]}
        title="Recent Activity"
        titleOpacity={titleOpacity.current.interpolate({
          inputRange: [0, config.titleDisplayOffsetOnScroll],
          outputRange: [0, 1],
        })}
      />
      <EmojiSelector
        onSelect={onAddReaction}
        isVisible={visibleEmojiPicker}
        onClose={closeEmojiPicker}
      />
      <UserReactions
        teamId={id}
        activityId={selectedActivity}
        isVisible={visibleReactions}
        onClose={closeReactions}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listHeaderContainer: {
    paddingHorizontal: 24,
  },
  header: {
    marginBottom: 26,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 1000,
  },
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 24,
  },
  content: {
    paddingTop: 60,
    paddingHorizontal: 24,

    flex: 1,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  subtitle: {
    fontSize: 14,
    color: 'blue-secondary',
    lineHeight: 17,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 29,
  },
  titleContainer: {
    paddingHorizontal: 12,
  },
  seeMore: {
    textAlign: 'right',
    color: 'light-gray',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  activityList: {
    marginVertical: 12,
    marginHorizontal: -24,
    flex: 1,
  },
  assessmentList: {
    marginTop: 12,
    flex: 1,
  },
  settingsContainer: {
    bottom: 20,
  },
  settingsIcon: {
    width: 22,
    height: 22,
  },
});

export default observer(TeamDetail);
