import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';

type Props = {
  value?: number;
  containerStyle?: ViewStyle;
  largeMarkStyle?: ViewStyle;
  smallMarkStyle?: ViewStyle;
  radius?: number;
  numOfMarks?: number;
  markInterval?: number;
  inactiveColor?: string;
  activeColor?: string;
};

const CircularRuler: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const {
    value = 100,
    containerStyle,
    largeMarkStyle,
    smallMarkStyle,
    radius = 130,
    numOfMarks = 60,
    markInterval = 5,
    activeColor = 'black',
    inactiveColor = 'black',
  } = props;

  const [lines] = useState(Array(numOfMarks).fill(0));

  const diameter = radius * 2;

  return (
    <View
      style={[styles.center, { height: diameter, width: diameter }, containerStyle]}
      pointerEvents="box-none">
      {lines.map((_line, index) => {
        const rotateZ = `${(360 * index) / numOfMarks}deg`;
        const markStyle =
          index % markInterval === 0
            ? [styles.large, largeMarkStyle]
            : [styles.small, smallMarkStyle];
        const backgroundColor = (index / numOfMarks) * 100 > value ? inactiveColor : activeColor;
        return (
          <View
            key={`line-${index}`}
            style={[
              styles.item,
              {
                height: diameter,
                transform: [{ rotateZ }],
              },
            ]}>
            <View style={[markStyle, { backgroundColor }]} />
          </View>
        );
      })}
    </View>
  );
};
const themedStyles = StyleService.create({
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  item: {
    position: 'absolute',
    height: 40,
    width: 20,
    alignItems: 'center',
  },
  large: {
    height: 20,
    width: 6,
    borderRadius: 2,
  },
  small: {
    marginTop: 10,
    height: 10,
    width: 3,
    borderRadius: 1,
  },
});

export default CircularRuler;
