import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import Team from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo } from 'react';
import { FlatList, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TeamIcon from '../../assets/images/team.svg';
import TopNav from '../../components/Question/TopNav';
import TeamItem from './TeamItem';

type Props = {};

const TeamInvite: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const store = useStore();
  const navigation = useNavigation();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const goToDetail = useCallback((item: Team) => {
    navigation.navigate('TeamDetailInvite', { id: item.id });
  }, []);

  const renderItem = useCallback(
    ({ item }) => (item ? <TeamItem onPress={() => goToDetail(item)} team={item} /> : null),
    [],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const keyExtractor = useCallback((item: Team) => `${item.id}`, []);

  useFocusEffect(
    useCallback(() => {
      store.teams.fetchPendingTeams();
    }, []),
  );

  const pendingTeamArray = useMemo(
    () => Array.from(store.teams.pending_teams.values()),
    [store.teams.pending_teams.values()],
  );

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout
            style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
            <View style={styles.navigationContainer}>
              <TopNav />
            </View>
            <View style={isMobile ? styles.contentMobile : styles.content}>
              <View style={styles.header}>
                <TeamIcon height={30} width={30} />
                <Text style={styles.title}> Pending Invites</Text>
              </View>
              <FlatList
                data={pendingTeamArray}
                renderItem={renderItem}
                ListEmptyComponent={null}
                keyExtractor={keyExtractor}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: 30,
  },
  contentMobile: {
    flex: 1,
    padding: 15,
  },
  header: {
    paddingHorizontal: 24,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginLeft: 6,
  },
  settingContainer: {
    paddingVertical: 15,
  },
  setting: {
    fontSize: 18,
    lineHeight: 22,
    marginTop: 10,
  },
  scroll: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 10,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  newContainer: {
    padding: 24,
  },
});

export default observer(TeamInvite);
