import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Video } from 'expo-av';
import ThriveMediaFile, {
  THRIVE_MEDIA_FILE_TYPE,
} from 'o2x-store/src/models/ThriveMediaFile';
import React, { useEffect, useRef, useState } from 'react';
import { Image, ImageStyle, StyleProp, View, ViewStyle } from 'react-native';
import MediaFile from '../../models/MediaFile';
import { useNativeStore } from '../../stores';

type Props = {
  style?: StyleProp<ViewStyle>;
  object: ThriveMediaFile | null;
  size: number;
};

const ThriveMeditateVisuals: React.FC<Props> = (props) => {
  const { style, object, size } = props;
  const { thriveStart, mediaStorage } = useNativeStore();
  const styles = useStyleSheet(themedStyles);
  const video = useRef<Video | null>(null);

  const [imageUri, setImageUri] = useState<string>('');
  const [videoUri, setVideoUri] = useState<string>('');

  if (!object) {
    return null;
  }

  useEffect(() => {
    if (video.current) {
      thriveStart.video = video.current;
    }
  }, [video.current]);

  useEffect(() => {
    if (!video.current) {
      return;
    }
    if (thriveStart.paused) {
      video.current.pauseAsync();
    } else {
      video.current.playAsync();
    }
  }, [thriveStart.paused]);

  useEffect(() => {
    let videoFile: MediaFile | undefined;
    if (object.type === THRIVE_MEDIA_FILE_TYPE.VIDEO) {
      videoFile = mediaStorage.getOrCreateMediaFile(object, 'content');
      videoFile?.downloadMedia();
      if (videoFile && !!videoFile.uri) {
        setVideoUri(videoFile.uri);
        setImageUri('');
      }
    }
  }, [object, setVideoUri, setImageUri]);

  useEffect(() => {
    if (object.type === THRIVE_MEDIA_FILE_TYPE.IMAGE) {
      setImageUri(object.content);
      setVideoUri('');
    }
  }, [object, setVideoUri, setImageUri]);

  return (
    <View style={[style, { width: size, height: size }]}>
      {!!videoUri && (
        <Video
          style={{ width: size, height: size }}
          ref={video}
          source={{ uri: videoUri }}
          resizeMode="cover"
          shouldPlay
          isLooping
          isMuted
          onPlaybackStatusUpdate={thriveStart.onPlaybackStatusUpdate}
        />
      )}
      {!!imageUri && (
        <Image
          style={styles.image as StyleProp<ImageStyle>}
          source={{ uri: imageUri }}
        />
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  image: {
    flex: 1,
  },
});

export default ThriveMeditateVisuals;
