import { RouteProp, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  Alert,
  Animated,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import config from 'src/config';
import MoreVertical from '../../assets/images/more-vertical.svg';
import NavigationBar from '../../components/NavigationBar';
import SweatStartWorkout from '../../components/SweatStart/SweatStartWorkout';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = { route: RouteProp<AppStackParamList, 'SweatContinue'> };

const SweatContinue: React.FC<Props> = (props) => {
  const { programId, workoutId } = props.route.params;
  const { sweat } = useStore();
  const { sweatStart } = useNativeStore();

  const program = !!programId && sweat.sweatPrograms.get(`${programId}`);
  const workout = sweat.sweatWorkouts.get(`${workoutId}`);
  const titleOpacity = useRef(new Animated.Value(0));

  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  useKeepAwake();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const goBack = useCallback(() => {
    if (sweatStart.active) {
      if (Platform.OS === 'web') {
        const doEnd = confirm('Are you sure you want to go back?');
        if (doEnd) {
          sweatStart.endWorkout();
          sweatStart.reset();
          navigation.goBack();
        }
      } else {
        Alert.alert('Are you sure you want to go back?', undefined, [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Go Back',
            onPress: () => {
              sweatStart.endWorkout();
              sweatStart.reset();
              navigation.goBack();
            },
          },
        ]);
      }
    } else {
      sweatStart.reset();
      navigation.goBack();
    }
  }, [sweatStart.active]);

  const onOptions = useCallback(() => {
    navigation.navigate('SweatOptions', {
      programId,
      workoutId: workout?.id,
    });
  }, [workout, programId]);

  const renderListHeader = useCallback(
    () => (
      <Layout style={styles.headerContainer}>
        <Layout style={[styles.header, { paddingRight: insets.right }]}>
          <Text style={styles.title}>{workout?.title.split(': Week ')[0]}</Text>
          <TouchableOpacity onPress={onOptions}>
            <MoreVertical />
          </TouchableOpacity>
        </Layout>
        {!!workout?.name ||
          (!workout?.program && (
            <Layout style={styles.subheader}>
              <Text style={styles.subheaderText} category="c1">
                {workout?.dayDisplay}
              </Text>
            </Layout>
          ))}
      </Layout>
    ),
    [],
  );

  useEffect(() => {
    if (!workout) {
      sweat.fetchSweatWorkout(workoutId);
    }
  }, [workout]);

  useEffect(() => {
    if (workout && (!workout.steps || isNumber(workout.steps[0]))) {
      workout.fetch();
    }
  }, [workout]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <Layout style={[{ paddingTop: insets.top }, styles.navigationContainer]}>
              <NavigationBar
                title={workout?.title}
                titleOpacity={titleOpacity.current.interpolate({
                  inputRange: [0, config.titleDisplayOffsetOnScroll],
                  outputRange: [0, 1],
                })}
                showBack={false}
                showClose
                onClose={goBack}
              />
            </Layout>

            <SweatStartWorkout
              program={program || undefined}
              loading={workout?.loading || false}
              workout={workout}
              titleOpacity={titleOpacity.current}
              renderListHeader={renderListHeader}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  headerContainer: {
    marginBottom: 24,
  },
  navigationContainer: {
    paddingHorizontal: 4,
    paddingTop: 4,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
  },
  header: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  subheader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
  subheaderText: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
  },
});

export default observer(SweatContinue);
