import { RouteProp, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import moment from 'moment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { SweatWorkoutSummaryEditModalParamList } from '../AppContainer/modals/SweatWorkoutSummaryEditModal';

type Props = {
  route: RouteProp<SweatWorkoutSummaryEditModalParamList, 'DurationPickerModal'>;
};

const hours = new Array(10).fill('').map((item, index) => index);

const minutes = new Array(60).fill('').map((item, index) => index);
const seconds = new Array(60).fill('').map((item, index) => index);

const DurationPickerModal: React.FC<Props> = (props) => {
  const { summaryId } = props.route.params;
  const store = useStore();
  const summary = store.user.sweatWorkoutProgress.get(`${summaryId}`);
  const duration = moment.utc(summary.time * 1000);
  const [selectedHour, setSelectedHour] = useState(duration.hour());
  const [selectedMinute, setSelectedMinute] = useState(duration.minute());
  const [selectedSeconds, setSelectedSeconds] = useState(duration.second());
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const hourListRef = useRef<FlatList>(null);
  const minuteListRef = useRef<FlatList>(null);
  const secondListRef = useRef<FlatList>(null);

  const onEdit = useCallback(() => {
    store.user.updateSweatWorkoutProgress({
      id: summaryId,
      time: selectedHour * 3600 + selectedMinute * 60 + selectedSeconds,
    });
    navigation.navigate('SweatWorkoutSummaryEdit', { summaryId });
  }, [selectedSeconds, selectedMinute, selectedHour, summaryId]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  useEffect(() => {
    hourListRef.current?.scrollToIndex({ index: selectedHour });
    minuteListRef.current?.scrollToIndex({ index: selectedMinute });
    secondListRef.current?.scrollToIndex({ index: selectedSeconds });
  }, [hourListRef, minuteListRef, secondListRef]);

  const keyExtractor = useCallback((item) => `${item}`, []);

  const onPressHour = useCallback((item) => {
    setSelectedHour(item);
  }, []);

  const onPressMinute = useCallback((item) => {
    setSelectedMinute(item);
  }, []);

  const onPressSecond = useCallback((item) => {
    setSelectedSeconds(item);
  }, []);

  const renderHour = useCallback(
    ({ item }) => (
      <TouchableOpacity
        onPress={() => onPressHour(item)}
        style={[styles.item, selectedHour === item && styles.itemIsSelected]}>
        <Text style={styles.itemText} category="c1">
          {item}
        </Text>
      </TouchableOpacity>
    ),
    [selectedHour],
  );

  const renderMinute = useCallback(
    ({ item }) => (
      <TouchableOpacity
        onPress={() => onPressMinute(item)}
        style={[styles.item, selectedMinute === item && styles.itemIsSelected]}>
        <Text style={styles.itemText} category="c1">
          {item}
        </Text>
      </TouchableOpacity>
    ),
    [selectedMinute],
  );

  const renderSecond = useCallback(
    ({ item }) => (
      <TouchableOpacity
        onPress={() => onPressSecond(item)}
        style={[styles.item, selectedSeconds === item && styles.itemIsSelected]}>
        <Text style={styles.itemText} category="c1">
          {item}
        </Text>
      </TouchableOpacity>
    ),
    [selectedSeconds],
  );

  const currentTime = useMemo(() => {
    const data = moment.utc((selectedHour * 3600 + selectedMinute * 60 + selectedSeconds) * 1000);
    return data.format('HH:mm:ss');
  }, [selectedHour, selectedMinute, selectedSeconds]);

  return (
    <View style={styles.container}>
      <Layout style={styles.modal}>
        <View style={styles.content}>
          <Text style={styles.timeText}>{currentTime}</Text>
        </View>
        <View style={styles.pickerContainer}>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Hr</Text>
            <FlatList
              ref={hourListRef}
              data={hours}
              renderItem={renderHour}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                hourListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (hours.length > 0) {
                    hourListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
            />
          </View>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Min</Text>
            <FlatList
              ref={minuteListRef}
              data={minutes}
              renderItem={renderMinute}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                minuteListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (minutes.length > 0) {
                    minuteListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
            />
          </View>
          <View style={styles.durationListContainer}>
            <Text style={styles.categoryText}>Sec</Text>
            <FlatList
              ref={secondListRef}
              data={seconds}
              renderItem={renderSecond}
              keyExtractor={keyExtractor}
              style={styles.scroll}
              onScrollToIndexFailed={(error) => {
                secondListRef.current?.scrollToOffset({
                  offset: error.averageItemLength * error.index,
                  animated: true,
                });
                setTimeout(() => {
                  if (seconds.length > 0) {
                    secondListRef.current?.scrollToIndex({
                      index: error.index,
                      animated: true,
                    });
                  }
                }, 100);
              }}
            />
          </View>
        </View>
        <View style={styles.actions}>
          <TouchableOpacity style={styles.action} onPress={goBack}>
            <Text style={styles.actionText} category="c1">
              Cancel
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.action, styles.submitButton]} onPress={onEdit}>
            <Text style={styles.actionText} category="c1">
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </View>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  action: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    marginTop: 12,
  },
  actionText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: 24,
  },
  scroll: {
    height: 300,
  },
  header: {
    marginBottom: 30,
  },
  modal: {
    width: '80%',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#051023',
  },
  item: {
    borderColor: '#051023',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flex: 1,
  },
  itemIsSelected: {
    backgroundColor: 'olive',
  },
  categoryText: {
    fontWeight: '600',
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
    color: '#8F9BB3',
  },
  timeText: {
    color: 'white',
    fontSize: 15,
    lineHeight: 20,
    paddingHorizontal: 16,
    padding: 10,
  },
  itemText: {
    fontWeight: '600',
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
    color: 'white',
  },
  submitButton: {
    backgroundColor: 'olive',
  },
  pickerContainer: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  durationListContainer: {
    flex: 1,
  },
});

export default DurationPickerModal;
