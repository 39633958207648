import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import ReadMore from 'react-native-read-more-text';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AssessmentSummaryCalendar from '../../components/AssessmentSummaryCalendar';
import NavigationBar from '../../components/NavigationBar';
import BottomNav from '../../components/Question/BottomNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentSummary'>;
};

const AssessmentSummary: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const { assessment: assessmentStore } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  const onNext = useCallback(() => {
    navigation.navigate('AssessmentHistory');
  }, []);

  const renderTruncatedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          Read More
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const renderRevealedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          Show Less
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  useEffect(() => {
    assessment?.fetch(true);
  }, [assessment]);

  if (!assessment) {
    return null;
  }

  const {
    previousScore,
    previousScoreBadge,
    bestScore,
    bestScoreBadge,
    averageScore,
    maxScore,
  } = assessment;

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar />
      </Layout>
      <ScrollView
        style={styles.content}
        contentContainerStyle={styles.contentContainer}
      >
        <Text style={styles.header} category="h2">
          Assessment Complete!
        </Text>
        <Layout style={styles.scoreContainer}>
          <Text style={styles.score}>{`${previousScore?.toFixed(1)}`}</Text>
          <Text style={styles.maxScore}>{` /${maxScore.toFixed(1)}`}</Text>
        </Layout>
        {!!previousScoreBadge && (
          <Layout style={styles.scoreBadge}>
            <Text style={styles.scoreBadgeText}>{previousScoreBadge}</Text>
          </Layout>
        )}
        <Layout style={styles.scoreSummary}>
          <Layout style={styles.scoreSummaryItem}>
            <Text style={styles.scoreSummaryValue}>
              {previousScore === null ? 'N/A' : previousScore.toFixed(1)}
            </Text>
            {!!previousScoreBadge && (
              <Text style={[styles.scoreBadgeText, styles.scoreBadgeTextSm]}>
                {previousScoreBadge}
              </Text>
            )}
            <Text style={styles.scoreSummaryLabel}>Previous</Text>
          </Layout>
          <Layout style={styles.scoreSummaryItem}>
            <Text style={styles.scoreSummaryValue}>
              {bestScore === null ? 'N/A' : bestScore.toFixed(1)}
            </Text>
            {!!bestScoreBadge && (
              <Text style={[styles.scoreBadgeText, styles.scoreBadgeTextSm]}>
                {bestScoreBadge}
              </Text>
            )}
            <Text style={styles.scoreSummaryLabel}>Best</Text>
          </Layout>
          <Layout style={styles.scoreSummaryItem}>
            <Text style={styles.scoreSummaryValue}>
              {averageScore === null ? 'N/A' : averageScore.toFixed(1)}
            </Text>
            <Text style={styles.scoreSummaryLabel}>Average</Text>
          </Layout>
        </Layout>
        <AssessmentSummaryCalendar
          style={styles.calendar}
          maxScore={assessment.maxScore}
          summary={assessment.summary}
        />
        <Layout style={styles.descriptionContainer}>
          <ReadMore
            numberOfLines={3}
            renderTruncatedFooter={renderTruncatedFooter}
            renderRevealedFooter={renderRevealedFooter}
          >
            <Text style={styles.description} category="p2">
              {assessment.description}
            </Text>
          </ReadMore>
        </Layout>
      </ScrollView>
      {/* <ProgressBar /> */}
      <BottomNav showBack={false} nextLabel="Finish" onNext={onNext} />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  calendar: {
    marginHorizontal: 24,
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingVertical: 12,
  },
  descriptionContainer: {
    marginHorizontal: 24,
    marginVertical: 24,
  },
  description: {},
  header: {
    marginBottom: 12,
    marginHorizontal: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  readMore: {
    marginTop: 8,
    textDecorationLine: 'underline',
    color: 'gray',
  },
  score: {
    fontSize: 64,
  },
  scoreBadge: {
    alignSelf: 'center',
  },
  scoreBadgeText: {
    backgroundColor: 'olive',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'center',
  },
  scoreBadgeTextSm: {
    fontSize: 10,
    marginBottom: 4,
  },
  scoreContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  scoreSummary: {
    marginTop: 24,
    marginBottom: 24,
    marginHorizontal: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  scoreSummaryItem: {
    flex: 1,
    alignItems: 'center',
  },
  scoreSummaryValue: {
    fontSize: 36,
    lineHeight: 43,
  },
  scoreSummaryLabel: {
    fontSize: 10,
    lineHeight: 12,
    color: 'blue-secondary',
  },
});

export default observer(AssessmentSummary);
