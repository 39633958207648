import Slider from '@react-native-community/slider';
import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { Audio } from 'expo-av';
import {
  INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  INTERRUPTION_MODE_IOS_DO_NOT_MIX,
} from 'expo-av/build/Audio';
import { useKeepAwake } from 'expo-keep-awake';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Breathe from '../../assets/images/breathe.svg';
import NavigationBar from '../../components/NavigationBar';
import ThriveBreatheIndicator from '../../components/ThriveBreatheIndicator';
import ThriveStartTimer from '../../components/ThriveStart/ThriveStartTimer';
import { useNativeStore } from '../../stores';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';

type Props = {
  route: any;
};

const DownloadedBreathe: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const theme = useTheme();
  const { thriveStart } = useNativeStore();
  useKeepAwake();

  const { initialDuration } = props.route.params;

  const [volume, setVolume] = useState(1);

  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      allowsRecordingIOS: false,
      staysActiveInBackground: true,
      interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      shouldDuckAndroid: true,
      interruptionModeAndroid: INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
    });

    thriveStart.stop();
    thriveStart.setAudio(null);
    thriveStart.setVideo(null);
    thriveStart.setVolume(1);
    if (!initialDuration) {
      thriveStart.setDefaultTime(5 * 60);
    } else if (initialDuration !== 'infinity') {
      thriveStart.setDefaultTime(initialDuration);
    } else if (initialDuration === 'infinity') {
      thriveStart.setDefaultTime(Infinity);
    } else {
      thriveStart.setDefaultTime(initialDuration);
    }
  }, []);

  const goBack = useCallback(() => {
    thriveStart.stop();
    thriveStart.setAudio(null);
    thriveStart.setVideo(null);
    navigation.goBack();
  }, []);

  const onSetVolume = useCallback((value) => {
    thriveStart.setVolume(value);
    setVolume(value);
  }, []);

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar goBack={goBack} />
      </Layout>
      <Layout style={styles.content}>
        <Layout style={styles.headerContainer}>
          <Breathe style={styles.icon} />
          <Text style={styles.header} category="h2">
            Breathe
          </Text>
        </Layout>
        <Layout style={styles.indicator}>
          <ThriveBreatheIndicator />
        </Layout>
        <Text style={styles.label} category="c2">
          Volume
        </Text>
        <Slider
          value={volume}
          onSlidingComplete={onSetVolume}
          onSlidingStart={onSetVolume}
          maximumValue={1}
          minimumValue={0}
          minimumTrackTintColor={theme.orange}
          maximumTrackTintColor={theme.gray}
          thumbTintColor={theme.white}
        />
      </Layout>
      <ThriveStartTimer dontShowComplete activity={THRIVE_ACTIVITY.BREATHE} addTime={30} />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  icon: {
    marginRight: 16,
  },
  indicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  label: {
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  select: {
    marginBottom: 24,
  },
});

export default observer(DownloadedBreathe);
