import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  SnapshotOutOf,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import EventModel, { EventCheckin } from '../models/Event';
import Recipe from '../models/Recipe';
import SavedList from '../models/SavedList';
import SweatProgram from '../models/SweatProgram';
import SweatWorkout from '../models/SweatWorkout';
import ThriveExercise from '../models/ThriveExercise';
import ThriveProgram from '../models/ThriveProgram';
import * as api from '../services/api';
import { LIST_TYPE } from '../utils/list';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/SavedListStore')
export default class SavedListStore extends Model({
  recipeList: prop_mapObject(() => new Map<string, SavedList>()),
  sweatWorkoutList: prop_mapObject(() => new Map<string, SavedList>()),
  sweatProgramList: prop_mapObject(() => new Map<string, SavedList>()),
  thriveExerciseList: prop_mapObject(() => new Map<string, SavedList>()),
  thriveProgramList: prop_mapObject(() => new Map<string, SavedList>()),
  eventList: prop_mapObject(() => new Map<string, SavedList>()),
}) {
  @observable
  loading = false;

  @modelFlow
  createList = _async(function* (
    this: SavedListStore,
    data: Partial<SnapshotOutOf<SavedList>>,
    type: LIST_TYPE,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.createList(rootStore.auth.token, data, type));
      if (type === LIST_TYPE.RECIPE) {
        this.recipeList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.RECIPE }),
        );
      } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
        this.sweatWorkoutList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.SWEAT_WORKOUT }),
        );
      } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
        this.thriveExerciseList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.THRIVE_EXERCISE }),
        );
      }
    } catch (error) {
      console.warn('[DEBUG] create list error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  getList = _async(function* (this: SavedListStore, type: LIST_TYPE, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let newList: SavedList | undefined;
    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.getList(rootStore.auth.token, type, id, true));

      if (type === LIST_TYPE.RECIPE) {
        newList = new SavedList({
          ...entities,
          type: LIST_TYPE.RECIPE,
          items: entities.items.map((item: SnapshotOutOf<Recipe>) => new Recipe(item)),
        });
        this.recipeList.set(
          `${entities.id}`,
          new SavedList({
            ...entities,
            type: LIST_TYPE.RECIPE,
            items: entities.items.map((item: SnapshotOutOf<Recipe>) => item.id),
          }),
        );
        (entities.items || []).forEach((item: Recipe) => {
          const id = `${item.id}`;
          rootStore.eat.recipes.set(id, new Recipe(item));
        });
      } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
        newList = new SavedList({
          ...entities,
          type: LIST_TYPE.SWEAT_WORKOUT,
          items: entities.items.map(
            (item: ModelInstanceCreationData<SweatWorkout>) => new SweatWorkout(item),
          ),
        });
        this.sweatWorkoutList.set(
          `${entities.id}`,
          new SavedList({
            ...entities,
            type: LIST_TYPE.SWEAT_WORKOUT,
            items: entities.items.map((item: SnapshotOutOf<SweatWorkout>) => item.id),
          }),
        );
        (entities.items || []).forEach((item: SweatWorkout) => {
          const id = `${item.id}`;
          rootStore.sweat.createOrUpdateSweatWorkout(item);
        });
      } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
        newList = new SavedList({
          ...entities,
          type: LIST_TYPE.THRIVE_EXERCISE,
          items: entities.items.map(
            (item: ModelInstanceCreationData<ThriveExercise>) => new ThriveExercise(item),
          ),
        });
        this.thriveExerciseList.set(
          `${entities.id}`,
          new SavedList({
            ...entities,
            type: LIST_TYPE.THRIVE_EXERCISE,
            items: entities.items.map((item: SnapshotOutOf<ThriveExercise>) => item.id),
          }),
        );
        (entities.items || []).forEach((item: ThriveExercise) => {
          const id = `${item.id}`;
          rootStore.thrive.createOrUpdateThriveExercise(item);
        });
      } else if (type === LIST_TYPE.EVENT) {
        newList = new SavedList({
          ...entities,
          type: LIST_TYPE.EVENT,
          items: entities.items.map(
            (
              item: SnapshotOutOf<EventModel> & {
                checkin: ModelInstanceCreationData<EventCheckin>;
              },
            ) =>
              new EventModel({
                ...item,
                checkin: item.checkin ? new EventCheckin(item.checkin) : null,
              }),
          ),
        });
        this.eventList.set(
          `${entities.id}`,
          new SavedList({
            ...entities,
            type: LIST_TYPE.EVENT,
            items: entities.items.map((item: SnapshotOutOf<EventModel>) => item.id),
          }),
        );
        (entities.items || []).forEach((item: EventModel) => {
          const id = `${item.id}`;
          rootStore.event.events.set(
            id,
            new EventModel({
              ...item,
              checkin: item.checkin ? new EventCheckin(item.checkin) : null,
            }),
          );
        });
      }
    } catch (error) {
      console.warn('[DEBUG] update list error', error);
      return getError(error);
    }

    this.loading = false;
    return newList;
  });

  @modelFlow
  updateList = _async(function* (
    this: SavedListStore,
    data: Partial<SnapshotOutOf<SavedList>>,
    type: LIST_TYPE,
    id: string,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.updateList(rootStore.auth.token, data, type, id));
      if (type === LIST_TYPE.RECIPE) {
        this.recipeList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.RECIPE }),
        );
      } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
        this.sweatWorkoutList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.SWEAT_WORKOUT }),
        );
      } else if (type === LIST_TYPE.SWEAT_PROGRAM) {
        this.sweatProgramList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.SWEAT_PROGRAM }),
        );
      } else if (type === LIST_TYPE.THRIVE_PROGRAM) {
        this.thriveProgramList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.THRIVE_PROGRAM }),
        );
      } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
        this.thriveExerciseList.set(
          `${entities.id}`,
          new SavedList({ ...entities, type: LIST_TYPE.THRIVE_EXERCISE }),
        );
      } else if (type === LIST_TYPE.EVENT) {
        this.eventList.set(`${entities.id}`, new SavedList({ ...entities, type: LIST_TYPE.EVENT }));
      }
    } catch (error) {
      console.warn('[DEBUG] update list error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteList = _async(function* (this: SavedListStore, type: LIST_TYPE, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const { response } = yield* _await(api.deleteList(rootStore.auth.token, type, id));
      if (!('error' in response)) {
        if (type === LIST_TYPE.RECIPE) {
          this.recipeList.delete(`${id}`);
        } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
          this.sweatWorkoutList.delete(`${id}`);
        } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
          this.thriveExerciseList.delete(`${id}`);
        }
      }
    } catch (error) {
      console.warn('[DEBUG] delete list error', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchLists = _async(function* (
    this: SavedListStore,
    listType: LIST_TYPE,
    clear: boolean = false,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let toReturn: Map<string, SavedList> = new Map<string, SavedList>();
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchLists(rootStore.auth.token, listType, true)));
    } catch (error) {
      console.warn('[DEBUG] error fetching lists', error);
      return getError(error);
    }

    if (clear) {
      this.recipeList.clear();
      this.sweatWorkoutList.clear();
      this.sweatProgramList.clear();
      this.thriveProgramList.clear();
      this.thriveExerciseList.clear();
    }

    if (listType === LIST_TYPE.RECIPE) {
      (entities.results || []).forEach((listData: SnapshotOutOf<SavedList>) => {
        this.recipeList.set(
          `${listData.id}`,
          new SavedList({ ...listData, type: LIST_TYPE.RECIPE }),
        );
      });
      toReturn = this.recipeList;
    } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
      (entities.results || []).forEach((listData: SnapshotOutOf<SavedList>) => {
        this.sweatWorkoutList.set(
          `${listData.id}`,
          new SavedList({ ...listData, type: LIST_TYPE.SWEAT_WORKOUT }),
        );
      });
      toReturn = this.sweatWorkoutList;
    } else if (listType === LIST_TYPE.SWEAT_PROGRAM) {
      (entities.results || []).forEach(
        (
          listData: SnapshotOutOf<SavedList> & {
            items: [ModelInstanceCreationData<SweatProgram>];
          },
        ) => {
          const itemIds = Array<number>();
          (listData.items || []).forEach((data: ModelInstanceCreationData<SweatProgram>) => {
            const id = `${data.id}`;
            itemIds.push(data.id);
            rootStore.sweat.createOrUpdateSweatProgram(data);
          });
          this.sweatProgramList.set(
            `${listData.id}`,
            new SavedList({
              ...listData,
              items: itemIds,
              type: LIST_TYPE.SWEAT_PROGRAM,
            }),
          );
        },
      );
      toReturn = this.sweatProgramList;
    } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
      (entities.results || []).forEach((listData: SnapshotOutOf<SavedList>) => {
        this.thriveExerciseList.set(
          `${listData.id}`,
          new SavedList({ ...listData, type: LIST_TYPE.THRIVE_EXERCISE }),
        );
      });
      toReturn = this.thriveExerciseList;
    } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
      (entities.results || []).forEach(
        (
          listData: SnapshotOutOf<SavedList> & {
            items: [ModelInstanceCreationData<ThriveProgram>];
          },
        ) => {
          const itemIds = Array<number>();
          (listData.items || []).forEach((data: ModelInstanceCreationData<ThriveProgram>) => {
            itemIds.push(data.id);
            rootStore.thrive.createOrUpdateThriveProgram(data);
          });
          this.thriveProgramList.set(
            `${listData.id}`,
            new SavedList({
              ...listData,
              items: itemIds,
              type: LIST_TYPE.THRIVE_PROGRAM,
            }),
          );
        },
      );
      toReturn = this.thriveProgramList;
    } else if (listType === LIST_TYPE.EVENT) {
      (entities.results || []).forEach(
        (
          listData: SnapshotOutOf<SavedList> & {
            items: [SnapshotOutOf<EventModel>];
          },
        ) => {
          const id = `${listData.id}`;
          const itemIds = Array<number>();
          (listData.items || []).forEach((data: SnapshotOutOf<EventModel>) => {
            const id = `${data.id}`;
            itemIds.push(data.id);
            rootStore.event.events.set(
              id,
              new EventModel({
                ...data,
                checkin: data.checkin ? new EventCheckin(data.checkin) : null,
              }),
            );
          });
          this.eventList.set(
            id,
            new SavedList({
              ...listData,
              items: itemIds,
              type: LIST_TYPE.EVENT,
            }),
          );
        },
      );
      toReturn = this.eventList;
    }

    this.loading = false;
    return toReturn;
  });

  @modelFlow
  fetchItems = _async(function* (this: SavedListStore, listType: LIST_TYPE, id: string) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchItems(rootStore.auth.token, listType, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching list items', error);
      return getError(error);
    }

    if (listType === LIST_TYPE.RECIPE) {
      (entities || []).forEach((data: SnapshotOutOf<Recipe>) => {
        const id = `${data.id}`;
        rootStore.eat.recipes.set(id, new Recipe(data));
      });
    } else if (listType === LIST_TYPE.SWEAT_PROGRAM) {
      (entities || []).forEach((data: ModelInstanceCreationData<SweatProgram>) => {
        const id = `${data.id}`;
        rootStore.sweat.createOrUpdateSweatProgram(data);
      });
    } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
      (entities || []).forEach((data: ModelInstanceCreationData<SweatWorkout>) => {
        const id = `${data.id}`;
        rootStore.sweat.createOrUpdateSweatWorkout(data);
      });
    } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
      (entities || []).forEach((data: ModelInstanceCreationData<ThriveProgram>) => {
        rootStore.thrive.createOrUpdateThriveProgram(data);
      });
    } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
      (entities || []).forEach((data: ModelInstanceCreationData<ThriveExercise>) => {
        rootStore.thrive.createOrUpdateThriveExercise(data);
      });
    } else if (listType === LIST_TYPE.EVENT) {
      (entities || []).forEach((data: SnapshotOutOf<EventModel>) => {
        const id = `${data.id}`;
        rootStore.event.events.set(
          id,
          new EventModel({
            ...data,
            checkin: data.checkin ? new EventCheckin(data.checkin) : null,
          }),
        );
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  addItems = _async(function* (
    this: SavedListStore,
    items: Array<String>,
    type: LIST_TYPE,
    id: string,
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.addItems(rootStore.auth.token, items, type, id));
      if (type === LIST_TYPE.RECIPE) {
        this.recipeList.get(`${id}`)?.addItems(items.map((i) => Number(i)));
        items.forEach((item) => {
          rootStore.eat.recipes.get(`${item}`)?.setSaveList(Number(id));
        });
      } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
        this.sweatWorkoutList.get(`${id}`)?.addItems(items.map((i) => Number(i)));
        items.forEach((item) => {
          rootStore.sweat.sweatWorkouts.get(`${item}`)?.setSaveList(Number(id));
        });
      } else if (type === LIST_TYPE.SWEAT_PROGRAM) {
        this.sweatProgramList.get(`${id}`)?.addItems(items.map((i) => Number(i)));
        items.forEach((item) => {
          rootStore.sweat.sweatPrograms.get(`${item}`)?.setSaveList(Number(id));
        });
      } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
        this.thriveExerciseList.get(`${id}`)?.addItems(items.map((i) => Number(i)));
        items.forEach((item) => {
          rootStore.thrive.thriveExercises.get(`${item}`)?.setSaveList(Number(id));
        });
      } else if (type === LIST_TYPE.THRIVE_PROGRAM) {
        this.thriveProgramList.get(`${id}`)?.addItems(items.map((i) => Number(i)));
        items.forEach((item) => {
          rootStore.thrive.thrivePrograms.get(`${item}`)?.setSaveList(Number(id));
        });
      } else if (type === LIST_TYPE.EVENT) {
        const eventList = this.eventList.get(`${id}`);
        if (eventList) {
          eventList.addItems(items.map((i) => Number(i)));
          this.eventList.set(`${id}`, eventList);
        }
        items.forEach((item) => {
          const eventItem = rootStore.event.events.get(`${item}`);
          if (eventItem) {
            eventItem.setSaveList(Number(id));
            rootStore.event.events.set(`${item}`, eventItem);
          }
        });
      }
    } catch (error) {
      console.warn('[DEBUG] error adding items', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  removeItems = _async(function* (this: SavedListStore, items: Array<String>, type: LIST_TYPE) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      const {
        response: { entities },
      } = yield* _await(api.removeItems(rootStore.auth.token, items, type));
      if (!('error' in entities)) {
        const { listIds, itemIds } = entities;
        if (type === LIST_TYPE.RECIPE) {
          listIds.forEach((listId: Number) => {
            const list = this.recipeList.get(`${listId}`);
            list?.removeItems(items.map((i) => Number(i)));
          });
          itemIds.forEach((item: Number) => {
            rootStore.eat.recipes.get(`${item}`)?.clearSaveList();
          });
        } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
          listIds.forEach((listId: Number) => {
            const list = this.sweatWorkoutList.get(`${listId}`);
            list?.removeItems(items.map((i) => Number(i)));
          });
          itemIds.forEach((item: Number) => {
            rootStore.sweat.sweatWorkouts.get(`${item}`)?.clearSaveList();
          });
        } else if (type === LIST_TYPE.SWEAT_PROGRAM) {
          listIds.forEach((listId: Number) => {
            const list = this.sweatProgramList.get(`${listId}`);
            list?.removeItems(items.map((i) => Number(i)));
          });
          itemIds.forEach((item: Number) => {
            rootStore.sweat.sweatPrograms.get(`${item}`)?.clearSaveList();
          });
        } else if (type === LIST_TYPE.THRIVE_EXERCISE) {
          listIds.forEach((listId: Number) => {
            const list = this.thriveExerciseList.get(`${listId}`);
            list?.removeItems(items.map((i) => Number(i)));
          });
          itemIds.forEach((item: Number) => {
            rootStore.thrive.thriveExercises.get(`${item}`)?.clearSaveList();
          });
        } else if (type === LIST_TYPE.THRIVE_PROGRAM) {
          listIds.forEach((listId: Number) => {
            const list = this.thriveProgramList.get(`${listId}`);
            list?.removeItems(items.map((i) => Number(i)));
          });
          itemIds.forEach((item: Number) => {
            rootStore.thrive.thrivePrograms.get(`${item}`)?.clearSaveList();
          });
        } else if (type === LIST_TYPE.EVENT) {
          listIds.forEach((listId: Number) => {
            const eventList = this.eventList.get(`${listId}`);
            if (eventList) {
              eventList.removeItems(items.map((i) => Number(i)));
              this.eventList.set(`${listId}`, eventList);
            }
          });
          itemIds.forEach((item: Number) => {
            const eventItem = rootStore.event.events.get(`${item}`);
            if (eventItem) {
              eventItem.clearSaveList();
              rootStore.event.events.set(`${item}`, eventItem);
            }
          });
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error removing items', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
