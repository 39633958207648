import { useNavigation } from '@react-navigation/core';
import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import ThriveIcon from '../../assets/images/thrive-icon.svg';
import Rating from '../Rating';

type Props = {
  item: ThriveProgram | any;
};

const FTEThriveProgramItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { item } = props;
  const { name, durationDisplay, rating, coverImageThumbnail } = item;

  const color = getTaskColor(TASK_TYPE.THRIVE);

  // const onOpen = useCallback(() => {
  //   navigation.navigate('ThriveStart', { id: item.id, isFte: true });
  // }, []);

  const onOpen = useCallback(() => {
    navigation.navigate('ProgramDetail', {
      screen: 'ProgramDetail',
      params: {
        type: TASK_TYPE.THRIVE,
        id: item.id,
      },
    });
  }, []);

  const onSave = useCallback(() => {}, []);

  return (
    <TouchableOpacity
      onPress={onOpen}
      style={[styles.container, { borderColor: color }]}
    >
      {coverImageThumbnail ? (
        <Image
          style={styles.thumbnail as StyleProp<ImageStyle>}
          source={{ uri: coverImageThumbnail }}
        />
      ) : (
        <View style={styles.taskIconContainer}>
          <ThriveIcon height={40} width={40} />
        </View>
      )}
      <View style={styles.content}>
        <Text numberOfLines={2}>{name}</Text>
        {!!durationDisplay && (
          <Text style={[styles.subtitle, { color }]} category="c2">
            {durationDisplay}
          </Text>
        )}
      </View>
      {false && (
        <TouchableOpacity style={styles.bookmarkButton} onPress={onSave}>
          <Icon
            style={styles.bookmark}
            fill="white"
            name={true ? 'bookmark-outline' : 'bookmark'}
          />
        </TouchableOpacity>
      )}
      {rating >= 0 && (
        <View style={styles.rating}>
          <Rating rating={rating} size={8} />
        </View>
      )}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 80,
    flexDirection: 'row',
    borderLeftWidth: 4,
    marginHorizontal: 24,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 12,
  },
  subtitle: {
    marginTop: 4,
  },
  thumbnail: {
    height: 80,
    width: 80,
  },
  taskIconContainer: {
    height: 80,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 4,
    bottom: 4,
    justifyContent: 'center',
  },
  bookmark: {
    width: 22,
    height: 22,
  },
  bookmarkButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FTEThriveProgramItem;
