import {
  Input,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import TimerInput from 'src/containers/AssessmentDetail/TimerInput';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  divider?: boolean;
  dividerStyle?: StyleProp<ViewStyle>;
  subtitle?: boolean;
}

const TimeInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    initial,
    required,
    divider = true,
    dividerStyle,
    subtitle = false,
  } = props;
  const [value, setValue] = useState(initial as string);
  const [status, setStatus] = useState('');
  const [isEditingTimer, setIsEditingTimer] = useState(false);
  const inputRef = useRef<Input>(null);

  const onFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    onFocus();
  }, []);

  const onChangeText = useCallback(
    (text: string) => {
      let result = '';
      let duration = text.replace(/:/g, '');
      if (duration.length % 2 === 1 && duration.length > 1) {
        result = `${duration.slice(0, 1)}:`;
        duration = duration.slice(1);
      }
      result += duration.replace(/..\B/g, '$&:');
      setStatus('');
      setValue(result);
      onChange(question.key, result);
      if (!result && required) setStatus('danger');
    },
    [value, setValue],
  );

  const goBack = useCallback(() => {
    setIsEditingTimer(false);
  }, []);

  const onOpenTimerModal = useCallback(() => {
    setIsEditingTimer(true);
  }, []);

  const onSetTime = useCallback(
    (time: string) => {
      setValue(time);
      onChange(question.key, time);
    },
    [setValue, onChange],
  );
  return (
    <>
      {/* <TouchableWithoutFeedback onPress={onFocus}> */}
      {!!isEditingTimer && (
        <TimerInput
          isEditingTimer={isEditingTimer}
          goBack={goBack}
          setTotalTime={onSetTime}
          value={value}
        />
      )}
      {/* <TouchableWithoutFeedback onPress={onOpenTimerModal}> */}
      <Layout style={styles.container}>
        <TouchableOpacity style={styles.weightInput} onPress={onOpenTimerModal}>
          <Text
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 16,
              marginLeft: 10,
            }}
          >
            {value}
          </Text>
        </TouchableOpacity>
        {/* <Input
            ref={inputRef}
            style={styles.weightInput}
            value={value}
            status={status}
            onChangeText={onChangeText}
            underlineColorAndroid="transparent"
            keyboardType="number-pad"
          /> */}
      </Layout>
      {/* </TouchableWithoutFeedback> */}
      {/* {divider && <Divider style={[styles.divider, dividerStyle]} />} */}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  // weightInput: {
  //   fontSize: 20,
  //   width: '100%',
  //   outline: 'none',
  //   borderWidth: 1,
  // },
  weightInput: {
    width: '100%',
    outline: 'none',
    borderWidth: 1,
    borderColor: '#3A3B3C',
    borderRadius: 5,
    height: 30,
    backgroundColor: 'dark-blue',
  },
  label: {
    color: '#C4C4C4',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
});

export default TimeInput;
