import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useEffect, useMemo } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useNativeStore } from 'src/stores';

type Props = {
  item: any;
  type: TASK_TYPE;
  onPress?: () => void;
  disabled?: boolean;
};

const DownloadListItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { downloads } = useNativeStore();

  const { item, type, disabled, onPress } = props;

  const color = getTaskColor(type);

  const media = useMemo(() => {
    if (item.image) {
      return downloads.getOrCreateThumbnail({
        uri: item.image,
        id: item.id,
        type,
      });
    }
    return null;
  }, [item.image]);

  useEffect(() => {
    if (media) {
      media.downloadMedia();
    }
  }, [media]);

  const image = media && media.downloaded ? media.localUri : item.image;

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <Layout style={[styles.container, { borderColor: color }]} level="2">
        {!image ? (
          <View style={styles.padding} />
        ) : (
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: image }}
          />
        )}
        <View style={styles.details}>
          <Text numberOfLines={1}>{item.displayName ?? item.name}</Text>
          {!!item.subtitle && (
            <Text style={[styles.subtitle, { color }]} numberOfLines={1}>
              {item.subtitle}
            </Text>
          )}
        </View>
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    height: 60,
    marginTop: 12,
    borderLeftWidth: 4,
    marginHorizontal: 20,
    flexDirection: 'row',
  },
  subtitle: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  details: {
    justifyContent: 'space-evenly',
    flex: 1,
    marginRight: 12,
  },
  image: {
    width: 60,
    height: 60,
    marginRight: 12,
  },
  padding: {
    width: 15,
  },
});

export default DownloadListItem;
