import { Icon, Layout, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useNativeStore } from 'src/stores';
import { Props, themedStyles } from '.';

const WorkoutGeneratorItem: React.FC<Props> = (props) => {
  const { task, showIcon = false, showEllipses = false } = props;
  const styles = useStyleSheet(themedStyles);
  const { workoutFilter } = useNativeStore();
  const { navigateWithPayWall } = useIsSubscribed();

  const onPress = useCallback(() => {
    workoutFilter.reset();
    navigateWithPayWall('WorkoutGeneratorFilter');
  }, [navigateWithPayWall]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
      >
        <Layout style={styles.contentContainer} level="2">
          <Layout style={styles.imageWrapper}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/workout-generator-thumbnail.png')}
            />
          </Layout>
          <Layout
            style={
              Platform.OS !== 'web'
                ? styles.textContainer
                : styles.textContainerWeb
            }
          >
            <Text
              style={Platform.OS !== 'web' ? styles.title : styles.titleWeb}
            >
              {task.title}
            </Text>
            {!!task.subtitle && (
              <Text
                style={[
                  Platform.OS !== 'web' ? styles.subtitle : styles.subtitleWeb,
                  { color: task.color },
                ]}
              >
                {task.subtitle}
              </Text>
            )}
          </Layout>
          {showEllipses && (
            <Icon style={styles.icon} fill="#FFFFFF" name="more-horizontal" />
          )}
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export default observer(WorkoutGeneratorItem);
