import { useNavigation } from '@react-navigation/core';
import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import ThriveExercise from 'o2x-store/src/models/ThriveExercise';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import ThriveIcon from '../../assets/images/thrive-icon.svg';

type Props = {
  item: ThriveExercise | any;
};

const FTEThriveExerciseItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { item } = props;
  const { name, duration, imageThumbnail } = item;

  const color = getTaskColor(TASK_TYPE.THRIVE);

  const onOpen = useCallback(() => {
    navigation.navigate('ThriveYogaDetail', { id: item.id });
  }, []);

  const onSave = useCallback(() => {}, []);

  return (
    <TouchableOpacity
      onPress={onOpen}
      style={[styles.container, { borderColor: color }]}
    >
      {imageThumbnail ? (
        <Image
          style={styles.thumbnail as StyleProp<ImageStyle>}
          source={{ uri: imageThumbnail }}
        />
      ) : (
        <View style={styles.taskIconContainer}>
          <ThriveIcon height={40} width={40} />
        </View>
      )}
      <View style={styles.content}>
        <Text numberOfLines={2}>{name}</Text>
        {!!duration && (
          <Text style={[styles.subtitle, { color }]} category="c2">
            {duration}
          </Text>
        )}
      </View>
      {false && (
        <TouchableOpacity style={styles.bookmarkButton} onPress={onSave}>
          <Icon
            style={styles.bookmark}
            fill="white"
            name={true ? 'bookmark-outline' : 'bookmark'}
          />
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 80,
    flexDirection: 'row',
    borderLeftWidth: 4,
    marginHorizontal: 24,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 12,
  },
  subtitle: {
    marginTop: 4,
  },
  thumbnail: {
    height: 80,
    width: 80,
  },
  taskIconContainer: {
    height: 80,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 4,
    bottom: 4,
    justifyContent: 'center',
  },
  bookmark: {
    width: 22,
    height: 22,
  },
  bookmarkButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FTEThriveExerciseItem;
