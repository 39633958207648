import {
  Icon,
  Input,
  Layout,
  Modal,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import config from 'o2x-store/src/config';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { RootStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  NativeScrollEvent,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  loadModalVisible: boolean;
  hideLoadModalVisible: () => void;
  content: string;
  setWorkoutTemplate?: Function;
  setStepTemplate?: Function;
  setExerciseSetTemplate?: Function;
  setWeekTemplate?: Function;
  setPrepare?: Function;
  setRecover?: Function;
  setPrepName?: Function;
  setRecName?: Function;
  store?: RootStore;
};

const { width, height } = Dimensions.get('window');

const LoadModalTemplate: React.FC<Props> = (props) => {
  const {
    loadModalVisible,
    hideLoadModalVisible,
    content,
    setWorkoutTemplate,
    setStepTemplate,
    setExerciseSetTemplate,
    setWeekTemplate,
    setRecover,
    setPrepare,
    setPrepName,
    setRecName,
    store,
  } = props;
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const styles = useStyleSheet(themedStyles);
  const [selectedId, setSelectedId] = useState(0);
  const [nextRequest, setNextRequest] = useState('');
  const [templates, setTemplates] = useState<SweatWorkout[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [resultInfo, setResultInfo] = useState('');
  const [hasError, setHasError] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(0);
  const [toBeDeletedType, setToBeDeletedType] = useState('');
  const animation = useRef(new Animated.Value(0)).current;

  const fetchTemplates = useCallback(() => {
    (async () => {
      if (loadModalVisible) {
        setLoading(true);
        setSelectedId(0);
        let fetchData;
        if (content === 'workouts')
          fetchData = await store?.fteLibrary.fetchFteWorkoutTemplates();
        else if (content === 'steps')
          fetchData = await store?.fteLibrary.fetchFteWorkoutStepTemplates();
        else if (content === 'exercise-sets')
          fetchData = await store?.fteLibrary.fetchFteWorkoutStepExerciseSetTemplates();
        else if (content === 'global-sets')
          fetchData = await store?.fteLibrary.fetchGlobalStepExerciseSetTemplates();
        else if (content === 'week-workout')
          fetchData = await store?.fteLibrary.fetchFteWeekWorkoutTemplates();
        setNextRequest(fetchData?.extra?.next);
        setTemplates(fetchData?.extra?.results);
        setLoading(false);
      }
    })();
  }, [loadModalVisible]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const onPressOk = () => {
    if (content === 'workouts') {
      setPrepare && setPrepare(selectedTemplate?.ftePrepare?.id);
      setRecover && setRecover(selectedTemplate?.fteRecover?.id);
      setPrepName &&
        setPrepName(
          selectedTemplate?.ftePrepare?.name
            ? selectedTemplate?.ftePrepare.name
            : 'O2X Standard Prepare Step',
        );
      setRecName &&
        setRecName(
          selectedTemplate?.fteRecover?.name
            ? selectedTemplate?.fteRecover.name
            : 'O2X Standard Prepare Step',
        );
      setWorkoutTemplate && setWorkoutTemplate(selectedTemplate);
    } else if (content === 'steps') {
      setStepTemplate && setStepTemplate(selectedTemplate);
    } else if (content === 'exercise-sets' || content === 'global-sets') {
      setExerciseSetTemplate && setExerciseSetTemplate(selectedTemplate);
    } else if (content === 'week-workout') {
      setWeekTemplate && setWeekTemplate(selectedTemplate);
    }
    hideLoadModalVisible();
  };

  const onSearch = async () => {
    setLoading(true);
    setSelectedId(0);
    let fetchData;
    if (content === 'workouts') {
      const url = `${config.urls.fteLibrary}workout-templates/?search=${search}&limit=10`;
      fetchData = await store?.fteLibrary.fetchFteWorkoutTemplates(url);
    } else if (content === 'steps') {
      const url = `${config.urls.fteLibrary}workout-step-templates/?search=${search}&limit=10`;
      fetchData = await store?.fteLibrary.fetchFteWorkoutStepTemplates(url);
    } else if (content === 'exercise-sets') {
      const url = `${config.urls.fteLibrary}workout-exercise-set-templates/?search=${search}&limit=10`;
      fetchData = await store?.fteLibrary.fetchFteWorkoutStepExerciseSetTemplates(
        url,
      );
    } else if (content === 'global-sets') {
      const url = `${config.urls.fteLibrary}global-exercise-set-templates/?search=${search}&limit=10`;
      fetchData = await store?.fteLibrary.fetchGlobalStepExerciseSetTemplates(
        url,
      );
    } else if (content === 'week-workout') {
      const url = `${config.urls.fteLibrary}week-workout-templates/?search=${search}&limit=10`;
      fetchData = await store?.fteLibrary.fetchFteWeekWorkoutTemplates(url);
    }
    setNextRequest(fetchData?.extra?.next);
    setTemplates(fetchData?.extra?.results);
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (nextRequest) {
      let fetchData;
      if (content === 'workouts')
        fetchData = await store?.fteLibrary.fetchFteWorkoutTemplates(
          nextRequest,
        );
      else if (content === 'steps')
        fetchData = await store?.fteLibrary.fetchFteWorkoutStepTemplates(
          nextRequest,
        );
      else if (content === 'exercise-sets')
        fetchData = await store?.fteLibrary.fetchFteWorkoutStepExerciseSetTemplates(
          nextRequest,
        );
      else if (content === 'global-sets')
        fetchData = await store?.fteLibrary.fetchGlobalStepExerciseSetTemplates(
          nextRequest,
        );
      else if (content === 'week-workout')
        fetchData = await store?.fteLibrary.fetchFteWeekWorkoutTemplates(
          nextRequest,
        );

      setTemplates([...templates!, ...fetchData?.extra?.results]);
      setNextRequest(fetchData?.extra?.next);
    }
    setLoadingMore(false);
  };

  const onDelete = useCallback((id: number) => {
    setIsDeleting(true);
    setToBeDeletedId(id);
    Animated.timing(animation, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsDeleting(false);
    animation.setValue(0);
  }, []);

  const onConfirmDelete = useCallback(async () => {
    setIsDeleting(false);
    animation.setValue(0);
    if (content === 'workouts')
      await store?.fteLibrary.deleteFteWorkoutTemplate(toBeDeletedId);
    else if (content === 'steps')
      await store?.fteLibrary.deleteFteWorkoutStepTemplate(toBeDeletedId);
    else if (content === 'exercise-sets')
      await store?.fteLibrary.deleteFteWorkoutStepExerciseSetTemplate(
        toBeDeletedId,
      );
    else if (content === 'global-sets')
      await store?.fteLibrary.deleteGlobalStepExerciseSetTemplate(
        toBeDeletedId,
      );
    else if (content === 'week-workout')
      await store?.fteLibrary.deleteFteWeekWorkoutTemplate(toBeDeletedId);

    const index = templates?.findIndex((x) => x.id === toBeDeletedId);
    const array = [...templates!];
    array?.splice(index!, 1);
    setTemplates([...array!]);
  }, [toBeDeletedId, templates]);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Modal
      visible={loadModalVisible}
      onBackdropPress={hideLoadModalVisible}
      backdropStyle={{
        backgroundColor: '#091C2D',
        opacity: 0.8,
      }}
    >
      <Layout style={{ height: 350, width: 300 }}>
        <Layout style={{ height: '100%', width: '100%' }}>
          <Input
            size="small"
            style={styles.input}
            placeholder="search"
            accessoryLeft={() => (
              <Icon style={styles.icon} name="search-outline" fill="white" />
            )}
            value={search}
            onChangeText={(text) => setSearch(text)}
            onKeyPress={(key) =>
              key.nativeEvent.key === 'Enter' ? onSearch() : null
            }
          />
          {!!loading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <ScrollView
              style={styles.exerciseContainer}
              onScroll={({ nativeEvent }) => {
                if (isCloseToBottom(nativeEvent)) {
                  loadMore();
                }
              }}
              scrollEventThrottle={400}
            >
              {templates?.map((template, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.exercise,
                      template.id === selectedId && {
                        backgroundColor: '#767F6A',
                      },
                    ]}
                    onPress={() => {
                      setSelectedId(template.id);
                      setSelectedTemplate(template);
                    }}
                  >
                    <Text style={styles.exerciseText}>{template.name}</Text>
                    <TouchableOpacity
                      style={{ marginLeft: 'auto' }}
                      onPress={() => onDelete(template.id)}
                    >
                      <Icon
                        style={{ width: 20, height: 20 }}
                        name="trash-2-outline"
                        fill="white"
                      />
                    </TouchableOpacity>
                  </TouchableOpacity>
                );
              })}
              {!loading && loadingMore && (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              )}
            </ScrollView>
          )}
          <TouchableOpacity style={styles.okButton} onPress={onPressOk}>
            <Text style={{ fontSize: 14, letterSpacing: 0.5 }}>Done</Text>
          </TouchableOpacity>
        </Layout>
      </Layout>
      <View
        style={!!isMobile ? styles.mobileCenter : styles.center}
        pointerEvents={isDeleting ? undefined : 'none'}
      >
        {isDeleting && (
          <TouchableOpacity
            activeOpacity={1}
            style={!!isMobile ? styles.mobileCenter : styles.center}
            onPress={onCancelDelete}
          />
        )}
        <Animated.View
          style={[
            styles.popUpContainer,
            {
              opacity,
              transform: [{ scale }, { translateY }],
            },
          ]}
        >
          <Icon
            style={styles.savedIcon}
            name={
              isDeleting
                ? 'question-mark-circle-outline'
                : hasError
                ? 'close-circle-outline'
                : 'checkmark-circle-2-outline'
            }
            fill={isDeleting ? 'orange' : hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>
            {isDeleting ? 'Are you sure you want to' : resultInfo}
          </Text>
          {isDeleting && (
            <Text style={styles.saveText}>delete this template?</Text>
          )}
          {isDeleting && (
            <View style={styles.buttons}>
              <TouchableOpacity style={styles.button} onPress={onCancelDelete}>
                <Text style={styles.deleteText}>NO</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={onConfirmDelete}>
                <Text style={styles.deleteText}>YES</Text>
              </TouchableOpacity>
            </View>
          )}
        </Animated.View>
      </View>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    width: 200,
    marginRight: 20,
    marginBottom: 20,
  },
  input: {
    marginTop: 20,
    width: '90%',
    alignSelf: 'center',
    backgroundColor: '#1A3248',
  },
  icon: {
    width: 20,
    height: 20,
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  exerciseContainer: {
    maxHeight: '75%',
    marginTop: 10,
    alignSelf: 'center',
    width: '100%',
  },
  okButton: {
    marginTop: 'auto',
    marginBottom: 15,
    marginLeft: 'auto',
    marginRight: 15,
    padding: 5,
    backgroundColor: '#767F6A',
  },
  exercise: {
    marginBottom: 5,
    alignSelf: 'center',
    width: '90%',
    padding: 4,
    flexDirection: 'row',
  },
  exerciseText: {
    fontSize: 15,
    letterSpacing: 1.2,
    marginLeft: 3,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: width,
    height: height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
    textTransform: 'uppercase',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
});

export default LoadModalTemplate;
