import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import EventSpecialists from '../../Events/EventSpecialists';
import NetPromoterModal from '../../NetPromoterModal';

export type NetPromoterModalParamList = {
  NetPromoterModal: { eventId: number };
};

type Props = {};

const Stack = createStackNavigator();

const NetPromoterStackModal: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
    }}
    headerMode="none"
    mode="modal"
  >
    <Stack.Screen name="EventSpecialists" component={EventSpecialists} />
    <Stack.Screen name="NetPromoterModal" component={NetPromoterModal} />
  </Stack.Navigator>
);

export default NetPromoterStackModal;
