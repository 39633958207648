import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import EventModel from 'o2x-store/src/models/Event';
import { Program } from 'o2x-store/src/models/Program';
import SavedList from 'o2x-store/src/models/SavedList';
import ThriveExercise from 'o2x-store/src/models/ThriveExercise';
import { useStore } from 'o2x-store/src/stores';
import { listToTypeWeb, LIST_TYPE, taskToType } from 'o2x-store/src/utils/list';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, ScrollView, TextInput, TouchableOpacity, View } from 'react-native';
import { SectionGrid } from 'react-native-super-grid';
import { useMediaQuery } from 'react-responsive';
import SavedGridItem from 'src/components/Saved/SavedGridItem';
import SavedListBar from 'src/components/Saved/SavedListBar';
import SavedProgramGridItem from 'src/components/Saved/SavedProgramGridItem';
import { useIsSubscribed } from 'src/hooks/subscription';
import Edit from '../../assets/images/edit.svg';
import NewListItem from '../../components/Saved/NewListItem';
import ConditionalWrapper from '../ConditionalWrapper';

type Props = {
  route?: any | null;
};

const Saved: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const navigation = useNavigation();
  const {
    route: { params },
  } = props;
  const { type } = params || { type: LIST_TYPE.RECIPE };

  const [selected, setSelected] = useState<LIST_TYPE>(type);
  const [selectedList, setSelectedList] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const theme = useTheme();

  const savedRecipeList = store.savedList.recipeList;
  const savedSweatWorkoutList = store.savedList.sweatWorkoutList;
  const savedSweatProgramList = store.savedList.sweatProgramList;
  const savedThriveProgramList = store.savedList.thriveProgramList;
  const savedThriveExerciseList = store.savedList.thriveExerciseList;
  const savedEventLists = store.savedList.eventList;
  const { navigateWithPayWall } = useIsSubscribed();
  const loadedTypes: Array<LIST_TYPE> = [];
  const [items, setItems] = useState<Map<string, Program | EventModel | ThriveExercise>>(
    new Map<string, Program | EventModel>(),
  );

  const isDeviceMaxWidth1024 = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        setSelectedList(null);
        if (type) {
          setSelected(type);
        }
        const results = await store.savedList.fetchLists(selected);
        if (!('errors' in results)) {
          loadedTypes.push(type);
        }
        setLoading(false);
      })();
    }, [type]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (selected === LIST_TYPE.THRIVE_PROGRAM || selected === LIST_TYPE.THRIVE_EXERCISE) {
          const missingProgram: Array<string> = [];
          const missingExercise: Array<string> = [];
          const programLists = store.savedList.thriveProgramList;
          const programs = new Map<string, Program | ThriveExercise>();
          programLists.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const savedProgram = store.thrive.thrivePrograms.get(`${id}`);
              if (savedProgram) {
                programs.set(`program-${id}`, savedProgram);
              } else {
                missingProgram.push(`${id}`);
              }
            });
          });

          if (missingProgram.length) {
            await store.thrive.fetchThriveProgramsByIds(missingProgram);
          }

          const exerciseList = store.savedList.thriveExerciseList;
          exerciseList.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const thriveExercise = store.thrive.thriveExercises.get(`${id}`);
              if (thriveExercise) {
                programs.set(`workout-${id}`, thriveExercise);
              } else {
                missingExercise.push(`${id}`);
              }
            });
          });

          if (missingExercise.length) {
            await store.thrive.fetchThriveExerciseByIds(missingExercise);
          }

          setItems(programs);
        } else if (selected === LIST_TYPE.SWEAT_PROGRAM || selected === LIST_TYPE.SWEAT_WORKOUT) {
          const missingProgram: Array<string> = [];
          const missingWorkout: Array<string> = [];
          const programLists = store.savedList.sweatProgramList;
          const programs = new Map<string, Program>();
          programLists.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const savedProgram = store.sweat.sweatPrograms.get(`${id}`);
              if (savedProgram) {
                programs.set(`program-${id}`, savedProgram);
              } else {
                missingProgram.push(`${id}`);
              }
            });
          });

          if (missingProgram.length) {
            await store.sweat.fetchSweatProgramsByIds(missingProgram);
          }
          const workoutList = store.savedList.sweatWorkoutList;
          workoutList.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const savedWorkout = store.sweat.sweatWorkouts.get(`${id}`);
              if (savedWorkout) {
                programs.set(`workout-${id}`, savedWorkout);
              } else {
                missingWorkout.push(`${id}`);
              }
            });
          });

          if (missingWorkout.length) {
            await store.sweat.fetchSweatWorkoutByIds(missingWorkout);
          }

          setItems(programs);
        } else if (selected === LIST_TYPE.RECIPE) {
          const missingRecipe: Array<string> = [];
          const programLists = store.savedList.recipeList;
          const programs = new Map<string, Program>();
          programLists.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const savedProgram = store.eat.recipes.get(`${id}`);
              if (savedProgram) {
                programs.set(`${id}`, savedProgram);
              } else {
                missingRecipe.push(`${id}`);
              }
            });
          });
          if (missingRecipe.length) {
            await store.eat.fetchRecipesByIds(missingRecipe);
          }
          setItems(programs);
        } else if (selected === LIST_TYPE.EVENT) {
          const missingEvent: Array<string> = [];
          const eventsLists = store.savedList.eventList;
          const events = new Map<string, EventModel>();
          eventsLists.forEach((l: SavedList) => {
            l.items.forEach((i: Number) => {
              let id: number;
              if (typeof i === 'number') {
                id = i;
              } else {
                id = i.id;
              }
              const savedEvents = store.event.events.get(`${id}`);
              if (savedEvents) {
                events.set(`${id}`, savedEvents);
              } else {
                missingEvent.push(`${id}`);
              }
            });
          });
          if (missingEvent.length) {
            await store.event.fetchEventsByIds(missingEvent, true);
          }
          setItems(events);
        }
      })();
    }, [
      selected,
      store.savedList.recipeList,
      store.savedList.sweatWorkoutList,
      store.savedList.thriveProgramList,
      store.savedList.sweatProgramList,
      store.savedList.thriveExerciseList,
      store.savedList.eventList,
      store.eat.loading,
      store.sweat.loading,
      store.thrive.loading,
      store.event.loading,
      loading,
    ]),
  );

  const renderProgramItem = useCallback(
    ({ item }) =>
      item ? (
        <TouchableOpacity onPress={() => onPressListItem(item)}>
          <SavedProgramGridItem
            key={`saved-${item.type}-${item.id}`}
            program={item}
            showHandle={false}
          />
        </TouchableOpacity>
      ) : null,
    [selected],
  );

  const sections = useMemo(() => {
    let data = Array.from(items.values());
    if (search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();
      data = data.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseSearch) ||
          item.description?.toLowerCase().includes(lowerCaseSearch) ||
          item.title?.toLowerCase().includes(lowerCaseSearch),
      );
    }
    return [{ title: 'saved', data }];
  }, [items, search, selected]);

  const reloadLists = useCallback(
    (listType) => {
      (async () => {
        setLoading(true);
        setSelectedList(null);
        if (indexOf(loadedTypes, listType) == -1) {
          // Only load if not previously loaded
          const results = await store.savedList.fetchLists(listType);
          if (!('errors' in results)) {
            loadedTypes.push(listType);
          }
        }
        setSelected(listType);
        setLoading(false);
      })();
    },
    [selected],
  );

  const createList = useCallback(() => {
    navigation.navigate('ListEdit', { listType: selected });
  }, [selected]);

  const editPlans = useCallback(
    (item) => {
      let source: Map<string, SavedList> | undefined;
      if (selected === LIST_TYPE.SWEAT_PROGRAM) {
        source = savedSweatProgramList;
      } else if (selected === LIST_TYPE.THRIVE_PROGRAM) {
        source = savedThriveProgramList;
      } else if (selected === LIST_TYPE.EVENT) {
        source = savedEventLists;
      }
      navigation.navigate('PlansEdit', {
        listType: selected,
        listIds: Array.from(source?.values() || []).map((l) => l.id),
      });
    },
    [selected, savedSweatProgramList, savedThriveProgramList, savedEventLists],
  );

  const editList = useCallback(
    (list) => {
      navigation.navigate('ListDetail', {
        listType: selected,
        listId: list.id,
      });
    },
    [selected],
  );

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <SavedGridItem
          key={`saved-${item.type}-${item.id}`}
          list={item}
          showHandle
          onPress={() => editList(item)}
        />
      ) : null,

    [selected, selectedList],
  );

  const onPressListItem = useCallback(
    (item) => {
      if (item.type === TASK_TYPE.SWEAT && item.sweat_list_type === LIST_TYPE.SWEAT_WORKOUT) {
        navigateWithPayWall('SweatContinue', { workoutId: item.id });
      } else if (selected === LIST_TYPE.EVENT) {
        navigation.navigate('EventDetail', {
          id: item.id,
        });
      } else if (item.type === TASK_TYPE.THRIVE && item.listType === LIST_TYPE.THRIVE_EXERCISE) {
        navigation.navigate('ThriveYogaDetail', {
          id: item.id,
        });
      } else {
        navigation.navigate('ProgramDetail', {
          type: item.type,
          id: item.id,
        });
      }
    },
    [navigateWithPayWall, selected],
  );

  return (
    <Layout style={isDeviceMaxWidth600 ? styles.containerMaxWidth600 : styles.container}>
      <View style={isDeviceMaxWidth600 ? styles.navContainerMaxWidth600 : styles.navContainer}>
        <View style={styles.taskBar}>
          <SavedListBar
            selected={listToTypeWeb(selected)}
            onSelect={(type) => reloadLists(taskToType(type))}
          />
        </View>
      </View>

      {loading ||
      store.eat.loading ||
      store.sweat.loading ||
      store.thrive.loading ||
      store.event.loading ? (
        <View style={styles.loading}>
          <Spinner />
        </View>
      ) : (
        <>
          {selected === LIST_TYPE.RECIPE && (
            <ConditionalWrapper
              condition={isDeviceMaxWidth600}
              wrapper={(children: any) => <ScrollView>{children}</ScrollView>}>
              <View
                style={
                  isDeviceMaxWidth600 ? styles.listContainerMaxWidth600 : styles.listContainer
                }>
                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.savedListContainerMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.savedListContainerMaxWidth1024
                      : styles.savedListContainer
                  }>
                  <Text style={styles.listText}> Lists </Text>
                  <FlatList
                    data={Array.from(savedRecipeList.values())}
                    renderItem={renderItem}
                    ListEmptyComponent={null}
                    keyExtractor={(item) => `${item.id}`}
                  />

                  <View style={styles.newContainer}>
                    <NewListItem key="new-eat" type={LIST_TYPE.RECIPE} onPress={createList} />
                  </View>
                </View>

                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.sideNavMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.sideNavMaxWidth1024
                      : styles.sideNav
                  }>
                  <View style={!isDeviceMaxWidth600 && styles.sideNavHeader}>
                    <View style={styles.left}>
                      <Layout style={styles.search}>
                        <Icon style={styles.searchIcon} name="search" fill={theme.white} />
                        <TextInput
                          style={styles.searchInput}
                          placeholder="Search"
                          placeholderTextColor={theme.white}
                          returnKeyType="search"
                          value={search}
                          onChangeText={setSearch}
                        />
                      </Layout>
                    </View>
                  </View>
                  <SectionGrid
                    itemDimension={242}
                    sections={sections}
                    renderItem={renderProgramItem}
                    keyExtractor={(item) => `${item.id}`}
                  />
                </View>
              </View>
            </ConditionalWrapper>
          )}
          {(selected === LIST_TYPE.SWEAT_PROGRAM || selected === LIST_TYPE.SWEAT_WORKOUT) && (
            <ConditionalWrapper
              condition={isDeviceMaxWidth600}
              wrapper={(children: any) => <ScrollView>{children}</ScrollView>}>
              <View
                style={
                  isDeviceMaxWidth600 ? styles.listContainerMaxWidth600 : styles.listContainer
                }>
                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.savedListContainerMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.savedListContainerMaxWidth1024
                      : styles.savedListContainer
                  }>
                  <Text style={styles.listText}> Lists </Text>
                  <View style={styles.subSelection}>
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.SWEAT_PROGRAM);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.SWEAT_PROGRAM &&
                          styles.subSelectionContainerSelected,
                      ]}>
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.SWEAT_PROGRAM && styles.selectionTextSelected,
                        ]}>
                        Plans
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.SWEAT_WORKOUT);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.SWEAT_WORKOUT &&
                          styles.subSelectionContainerSelected,
                      ]}>
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.SWEAT_WORKOUT && styles.selectionTextSelected,
                        ]}>
                        Workouts
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {selected === LIST_TYPE.SWEAT_PROGRAM && (
                    <TouchableOpacity style={styles.edit} onPress={editPlans}>
                      <Edit />
                      <View style={styles.editPadding} />
                      <Text style={styles.link}>Edit List</Text>
                    </TouchableOpacity>
                  )}

                  {selected === LIST_TYPE.SWEAT_WORKOUT && (
                    <>
                      <FlatList
                        data={Array.from(savedSweatWorkoutList.values())}
                        renderItem={renderItem}
                        ListEmptyComponent={null}
                        keyExtractor={(item) => `${item.id}`}
                      />

                      <View style={styles.newContainer}>
                        <NewListItem
                          key="new-sweat-workout"
                          type={LIST_TYPE.SWEAT_WORKOUT}
                          onPress={createList}
                        />
                      </View>
                    </>
                  )}
                </View>
                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.sideNavMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.sideNavMaxWidth1024
                      : styles.sideNav
                  }>
                  <View style={!isDeviceMaxWidth600 && styles.sideNavHeader}>
                    <View style={styles.left}>
                      <Layout style={styles.search}>
                        <Icon style={styles.searchIcon} name="search" fill={theme.white} />
                        <TextInput
                          style={styles.searchInput}
                          placeholder="Search"
                          placeholderTextColor={theme.white}
                          returnKeyType="search"
                          value={search}
                          onChangeText={setSearch}
                        />
                      </Layout>
                    </View>
                  </View>
                  <SectionGrid
                    itemDimension={242}
                    sections={sections}
                    renderItem={renderProgramItem}
                    keyExtractor={(item) => `${item.id}`}
                  />
                </View>
              </View>
            </ConditionalWrapper>
          )}
          {(selected === LIST_TYPE.THRIVE_PROGRAM || selected === LIST_TYPE.THRIVE_EXERCISE) && (
            <ConditionalWrapper
              condition={isDeviceMaxWidth600}
              wrapper={(children: any) => <ScrollView>{children}</ScrollView>}>
              <View
                style={
                  isDeviceMaxWidth600 ? styles.listContainerMaxWidth600 : styles.listContainer
                }>
                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.savedListContainerMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.savedListContainerMaxWidth1024
                      : styles.savedListContainer
                  }>
                  <Text style={styles.listText}> Lists </Text>
                  <View style={styles.subSelection}>
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.THRIVE_PROGRAM);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.THRIVE_PROGRAM &&
                          styles.subSelectionContainerSelected,
                      ]}>
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.THRIVE_PROGRAM &&
                            styles.selectionTextSelectedThrive,
                        ]}>
                        Plans
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.THRIVE_EXERCISE);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.THRIVE_EXERCISE &&
                          styles.subSelectionContainerSelected,
                      ]}>
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.THRIVE_EXERCISE &&
                            styles.selectionTextSelectedThrive,
                        ]}>
                        Exercise
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {selected === LIST_TYPE.THRIVE_PROGRAM && (
                    <TouchableOpacity style={styles.edit} onPress={editPlans}>
                      <Edit />
                      <View style={styles.editPadding} />
                      <Text style={styles.link}>Edit List</Text>
                    </TouchableOpacity>
                  )}

                  {selected === LIST_TYPE.THRIVE_EXERCISE && (
                    <>
                      <FlatList
                        data={Array.from(savedThriveExerciseList.values())}
                        renderItem={renderItem}
                        ListEmptyComponent={null}
                        keyExtractor={(item) => `${item.id}`}
                      />

                      <View style={styles.newContainer}>
                        <NewListItem
                          key="new-thrive-workout"
                          type={LIST_TYPE.THRIVE_EXERCISE}
                          onPress={createList}
                        />
                      </View>
                    </>
                  )}
                </View>
                <View
                  style={
                    isDeviceMaxWidth600
                      ? styles.sideNavMaxWidth600
                      : isDeviceMaxWidth1024
                      ? styles.sideNavMaxWidth1024
                      : styles.sideNav
                  }>
                  <View style={!isDeviceMaxWidth600 && styles.sideNavHeader}>
                    <View style={styles.left}>
                      <Layout style={styles.search}>
                        <Icon style={styles.searchIcon} name="search" fill={theme.white} />
                        <TextInput
                          style={styles.searchInput}
                          placeholder="Search"
                          placeholderTextColor={theme.white}
                          returnKeyType="search"
                          value={search}
                          onChangeText={setSearch}
                        />
                      </Layout>
                    </View>
                  </View>
                  <SectionGrid
                    itemDimension={242}
                    sections={sections}
                    renderItem={renderProgramItem}
                    keyExtractor={(item) => `${item.id}`}
                  />
                </View>
              </View>
            </ConditionalWrapper>
          )}
          {selected === LIST_TYPE.EVENT && (
            <ConditionalWrapper
              condition={isDeviceMaxWidth600}
              wrapper={(children: any) => <ScrollView>{children}</ScrollView>}>
              <View
                style={isDeviceMaxWidth600 ? styles.thriveHeaderMaxWidth600 : styles.thriveHeader}>
                <Layout style={styles.search}>
                  <Icon style={styles.searchIcon} name="search" fill={theme.white} />
                  <TextInput
                    style={styles.searchInput}
                    placeholder="Search"
                    placeholderTextColor={theme.white}
                    returnKeyType="search"
                    value={search}
                    onChangeText={setSearch}
                  />
                </Layout>
                <TouchableOpacity style={styles.edit} onPress={editPlans}>
                  <Edit />
                  <View style={styles.editPadding} />
                  <Text style={styles.link}>Edit List</Text>
                </TouchableOpacity>
              </View>

              <View
                style={
                  isDeviceMaxWidth600 ? styles.gridContainerMaxWidth600 : styles.gridContainer
                }>
                <SectionGrid
                  itemDimension={242}
                  sections={sections}
                  renderItem={renderProgramItem}
                  keyExtractor={(item) => `${item.id}`}
                />
              </View>
            </ConditionalWrapper>
          )}
        </>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 24,
  },
  containerMaxWidth600: {
    flex: 1,
  },
  navContainer: {
    paddingHorizontal: 24,
  },
  navContainerMaxWidth600: {
    paddingHorizontal: 15,
  },
  taskBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  newContainer: {
    paddingVertical: 26,
  },
  subSelection: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    marginTop: 30,
  },

  subSelectionContainer: {
    flex: 1,
    paddingVertical: 5,
    backgroundColor: 'darker-blue',
  },
  subSelectionContainerSelected: {
    backgroundColor: 'dark-blue',
  },
  selectionText: {
    color: 'blue-secondary',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  selectionTextSelected: {
    color: 'cyan',
  },
  selectionTextSelectedThrive: {
    color: 'orange',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    marginTop: 10,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  sweatText: {
    color: 'cyan',
    fontSize: 16,
  },
  thriveText: {
    color: 'orange',
    fontSize: 16,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  searchIcon: {
    height: 24,
    width: 24,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    marginHorizontal: 10,
    outlineWidth: 0,
  },
  searchWrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
  },
  listContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 24,
  },
  listContainerMaxWidth600: {
    flex: 1,
    paddingHorizontal: 15,
  },
  savedListContainer: {
    flex: 1,
    paddingTop: 60,
  },
  savedListContainerMaxWidth600: {
    width: '100%',
    paddingTop: 10,
  },
  savedListContainerMaxWidth1024: {
    width: '40%',
    paddingTop: 10,
  },
  gridContainer: {
    flex: 1,
    paddingHorizontal: 24,
  },
  gridContainerMaxWidth600: {
    flex: 1,
    paddingHorizontal: 15,
  },
  sideNav: {
    flex: 3,
    margin: 24,
  },
  sideNavMaxWidth600: {
    width: '100%',
    margTop: 10,
  },
  sideNavMaxWidth1024: {
    width: '58%',
    margin: 24,
  },
  sideNavHeader: {
    paddingTop: 24,
    paddingBottom: 24,
    flexDirection: 'row',
  },
  sideNavTitle: {
    color: 'white',
    fontSize: 22,
    fontWeight: '700',
  },
  listText: {
    color: 'white',
    fontWeight: '700',
    fontHeight: 29,
    fontSize: 24,
  },
  edit: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
  editPadding: {
    paddingHorizontal: 5,
  },
  thriveHeader: {
    marginHorizontal: 24,
    marginTop: 30,
  },
  thriveHeaderMaxWidth600: {
    marginHorizontal: 15,
    marginTop: 10,
  },
  left: {
    flex: 1,
  },
});

export default observer(Saved);
