import Constants from 'expo-constants';
import * as Font from 'expo-font';
import * as Notifications from 'expo-notifications';
import firebase from 'firebase/compat/app';
import * as analytics from 'o2x-store/src/services/analytics';
import { StorageService } from 'o2x-store/src/services/storage';
import { createRootStore, RootStore } from 'o2x-store/src/stores';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';
import config from 'src/config';
import { NativeStorageService } from '../services/storage';
import { createNativeStore, NativeStore } from '../stores';

let InAppPurchases: any;

export const useInit = () => {
  const [storageService] = useState<StorageService>(new NativeStorageService());
  const [store] = useState<RootStore>(createRootStore(storageService));
  const [nativeStore] = useState<NativeStore>(createNativeStore(storageService));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (!store.auth.token) {
      console.log('[DEBUG] reset');
      analytics.setUser();
      nativeStore.reset();
    } else {
      if (store.auth.user) {
        analytics.setUser(store.auth.user);
      }
      // Prefetch data
      store.sweat.fetchActiveSweatGlobalSteps();
      store.sweat.fetchActiveSweatWorkouts();
      store.sweat.fetchActiveSweatPrograms();
      store.assessment.fetchPhysicalAssessments();
      store.assessment.fetchFormAssessments();
      store.assessment.fetchBodyComposition();
      if (Platform.OS !== 'web') {
        // store.sweat.fetchSweatExercises();
        store.thrive.fetchThriveMediaFiles();
        (async () => {
          const { installationId: deviceId, deviceName } = Constants;
          const { data: pushToken } = await Notifications.getDevicePushTokenAsync();
          const { data: expoPushToken } = await Notifications.getExpoPushTokenAsync({
            experienceId: '@bldai/o2x-native',
          });
          const { buildVersion: appVersion } = config;
          store.registerDevice(deviceId, pushToken, expoPushToken, appVersion, deviceName);
        })();
      }
    }
  }, [ready, store.auth.token]);

  useEffect(() => {
    Sentry.init({
      dsn: 'https://b6167b8c94d94888a086713f602026c6@o115442.ingest.sentry.io/5220091',
    });
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: 'AIzaSyBit6jjOIy4pDdRIFa66JTpGya-xsyhlyw',
        authDomain: 'o2x-app.firebaseapp.com',
        databaseURL: 'https://o2x-app.firebaseio.com',
        projectId: 'o2x-app',
        storageBucket: 'o2x-app.appspot.com',
        messagingSenderId: '342062492057',
        appId: '1:342062492057:web:f26b08166cf3263cde4b08',
        measurementId: 'G-NBBC9WP630',
      });
    }

    (async () => {
      await store.load();
      await nativeStore.load();
      await Font.loadAsync({
        Lato: require('../assets/fonts/Lato-Regular.ttf'),
        'Lato-Bold': require('../assets/fonts/Lato-Bold.ttf'),
      });
      if (Constants.appOwnership !== 'expo' && ['ios', 'android'].includes(Platform.OS)) {
        if (!InAppPurchases) {
          try {
            InAppPurchases = require('expo-in-app-purchases');
          } catch {
            console.warn(
              'Unable to load expo-in-app-purchases.  This is expected if running in Expo Go.',
            );
          }
        }
        InAppPurchases.setPurchaseListener(store.purchaseProduct);
      }

      if (Platform.OS !== 'web') {
        const settings = await Notifications.getPermissionsAsync();
        if (
          !settings.granted &&
          settings.ios?.status !== Notifications.IosAuthorizationStatus.PROVISIONAL
        ) {
          await Notifications.requestPermissionsAsync({
            ios: {
              allowAlert: true,
              allowBadge: true,
              allowSound: true,
              allowAnnouncements: true,
            },
          });
        }
        await Notifications.setBadgeCountAsync(0);
      }
      if (store.auth.user) await analytics.setUser(store.auth.user);
      analytics.logAppOpen();
      setReady(true);
    })();
  }, []);

  return {
    store,
    nativeStore,
    ready,
  };
};
