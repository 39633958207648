import React from 'react';
import { View } from 'react-native';
import Svg from 'react-native-svg';
import { VictoryPie } from 'victory';

interface CircularProgressBarProps {
  score: number | null;
  maxScore: number;
}

export const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  score,
  maxScore,
}) => {
  const innerRadius = 120;
  const percent = score === null ? 100 : (score / maxScore) * 100;
  const data = [
    { x: 1, y: percent },
    { x: 2, y: 100 - percent },
  ];

  const strokeColor = (): string => {
    if (score === null) {
      return '#C4C4C4';
    }
    if (percent <= 49) {
      return '#C64141';
    }
    if (percent <= 74) {
      return '#CFAC42';
    }

    if (percent <= 89) {
      return '#4E7B89';
    }

    return '#767F6A';
  };

  return (
    <View>
      <Svg viewBox="0 0 400 400" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          width={400}
          height={400}
          data={data}
          innerRadius={innerRadius}
          labels={() => null}
          style={{
            data: {
              opacity: score === null ? '0.1' : '1',
              fill: ({ datum }) => {
                return datum.x === 1 ? strokeColor() : 'transparent';
              },
            },
          }}
        />
      </Svg>
    </View>
  );
};

export default CircularProgressBar;
