import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import ResourceModel from 'o2x-store/src/models/Resource';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import { Image, ImageStyle, StyleProp, TouchableOpacity } from 'react-native';
import ImageLoad from 'react-native-image-placeholder';
import { useMediaQuery } from 'react-responsive';
import Docs from '../../assets/images/resource-document.svg';
import Play from '../../assets/images/resource-play.svg';

export type Props = {
  resource: ResourceModel;
  selected: TASK_TYPE;
};

const ResourceItem: React.FC<Props> = (props) => {
  const { resource, selected } = props;
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const onPress = useCallback(() => {
    if (resource.videoLink) {
      navigation.navigate('VideoModal', {
        resourceId: `${resource.name}${resource.id}`,
      });
    } else if (resource.fileUpload) {
      window.open(resource.fileUpload, '_blank');
    } else if (resource.externalUrl) {
      window.open(resource.externalUrl, '_blank');
    }
  }, [resource]);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableOpacity
      style={[
        isMobile ? styles.buttonContainerMobile : styles.buttonContainer,
        {
          borderColor: getTaskColor(selected),
        },
      ]}
      onPress={onPress}>
      <Layout style={styles.thumbnailWrapper}>
        {resource.imageThumbnail ? (
          <ImageLoad
            style={styles.thumbnail as StyleProp<ImageStyle>}
            source={{ uri: resource.imageThumbnail }}
            loadingStyle={{ size: 'small', color: '#091C2D' }}
            placeholderSource={require('../../assets/images/logo.png')}
          />
        ) : (
          <Image
            style={styles.thumbnail as StyleProp<ImageStyle>}
            source={require('../../assets/images/logo.png')}
          />
        )}
      </Layout>

      <Layout style={styles.buttonDetails}>
        {resource.videoLink ? (
          <Play stroke={getTaskColor(selected)} />
        ) : (
          <Docs stroke={getTaskColor(selected)} />
        )}
        <Text style={styles.title}>{resource.name}</Text>
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  buttonContainer: {
    backgroundColor: '#1A3248',
    maxWidth: 315,
    borderLeftWidth: 3.8,
    flexDirection: 'row',
  },
  buttonContainerMobile: {
    backgroundColor: '#1A3248',
    width: '100%',
    borderLeftWidth: 3.8,
    flexDirection: 'row',
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
  },
  thumbnailWrapper: {
    width: 77,
    height: 80,
  },
  buttonDetails: {
    backgroundColor: 'transparent',
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
    paddingVertical: 5,
  },
  title: {
    fontSize: 14,
    color: 'white',
    fontWeight: '400',
    letterSpacing: 1,
    paddingLeft: 10,
    width: '85%',
  },
});

export default ResourceItem;
