export enum ASSESSMENT_TYPE {
  FORM = 'form',
  PHYSICAL = 'physical',
  BODY = 'bodyComposition',
}

export enum FORM_ASSESSMENT_SCORING_TYPE {
  TOTAL_SCORE = 'total_score',
  AVERAGE_SCORE = 'average_score',
  AVERAGE_PER_CATEGORY = 'average_per_category',
  CUSTOM_READINESS = 'custom_readiness',
  CUSTOM_BODY_COMP = 'custom_body_comp',
  CUSTOM_HP_SURVEY = 'custom_hp_survey',
  CUSTOM_MOBILITY_CAPACITY = 'custom_mobility_capacity',
}

export type Assessment = {
  id: number;
  name: string;
  preview: string;
  description: string;
  coverImage: string | null;
  coverImageThumbnail: string | null;
  previousScore: number | null;
  maxScore: number;
  currentSubmission: number | null;
  previousSubmission: number | null;
  bestScore: number | null;
  averageScore: number | null;
  type: ASSESSMENT_TYPE;
  scoringType: FORM_ASSESSMENT_SCORING_TYPE | null;
};

export type AssessmentPreviewItem = {
  title: string;
  description: string;
};

export type AssessmentScoringDisplayData = {
  label?: string;
  headers: Array<string>;
  values: Array<Array<string>>;
};

export type AssessmentScoringItemDisplay = {
  title: string;
  data: Array<AssessmentScoringDisplayData>;
};

export type AssessmentScoringDisplay = {
  scoringDisplay: Array<AssessmentScoringItemDisplay>;
  badgeDisplay: AssessmentScoringDisplayData;
};
