import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { UserQuestion } from '../../models/UserQuestion';
import { callApiWithToken } from './base';

export const fetchTopics = async (token: string) =>
  callApiWithToken(config.urls.userQuestionTopics, token);

export const createUserQuestion = async (token: string, data: UserQuestion) =>
  callApiWithToken(
    config.urls.userQuestions,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );
