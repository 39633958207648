import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { getListColor, LIST_TYPE } from 'o2x-store/src/utils/list';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

export type Props = {
  key: string;
  type: LIST_TYPE;
  onPress: () => void;
};

const NewListItem: React.FC<Props> = (props) => {
  const { type, onPress } = props;
  const styles = useStyleSheet(themedStyles);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout
      style={[
        isMobile ? styles.containerMobile : styles.container,
        {
          borderLeftWidth: 4,
          borderColor: getListColor(type),
          borderStyle: 'solid',
        },
      ]}
      level="2"
    >
      <TouchableOpacity
        style={isMobile ? styles.contentMobile : styles.content}
        onPress={onPress}
      >
        <View>
          <Text>+ New List</Text>
        </View>
      </TouchableOpacity>
    </Layout>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    height: 40,
    wdith: 410,
  },
  containerMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
    height: 40,
    width: '100%',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 12,
    marginRight: 16,
    marginVertical: 8,
  },
  contentMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginVertical: 5,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
});

export default NewListItem;
