import { useNavigation } from '@react-navigation/native';
import { Divider, Layout, Popover, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { Image, ImageStyle, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Logout from '../../assets/images/logout.svg';
import Menu from '../../assets/images/menu.svg';
import Polygon from '../../assets/images/polygon.svg';
import Profile from '../../assets/images/profile.svg';
import Settings from '../../assets/images/settings.svg';

type Props = {
  isProfileSelected?: boolean;
  isAccountSettingsSelected?: boolean;
};

const Header: React.FC<Props> = ({ isProfileSelected, isAccountSettingsSelected }) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const navigation = useNavigation();
  const { auth, user: userStore } = store;
  const today = new Date();

  const [user, setUser] = useState<User | null>();
  const [isAccountVisible, setAccountVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  useEffect(
    useCallback(() => {
      (async () => {
        const storedUser = auth.user;
        if (storedUser) {
          setUser(storedUser);
        } else {
          await userStore.fetchMe();
          setUser(auth.user);
        }
      })();
    }, []),
  );

  const logOut = useCallback(() => {
    setAccountVisible(false);
    auth.logOut();
  }, []);

  const onPressMenu = useCallback(() => {
    navigation.openDrawer();
  }, []);

  const onPressAccountProfile = useCallback(() => {
    setAccountVisible(false);
    navigation.navigate('Profile');
  }, []);

  const onPressAccountSetting = useCallback(() => {
    setAccountVisible(false);
    navigation.navigate('Settings', { screen: 'SettingsAccount' });
  }, []);

  const renderToggleButton = () => (
    <TouchableOpacity onPress={() => setAccountVisible(!isAccountVisible)}>
      <Layout style={styles.accountContainer}>
        <Image
          style={styles.profile as StyleProp<ImageStyle>}
          source={
            user?.profileImage
              ? { uri: user?.profileImage }
              : require('../../assets/images/user_placeholder.png')
          }
        />
        {!isTabletOrMobile && (
          <Layout style={styles.accountDetails}>
            <Text style={styles.fullName}>{user?.fullName || user?.email}</Text>
            <Text style={styles.o2xId}>{`O2X ID #${user?.o2XId}`}</Text>
          </Layout>
        )}
      </Layout>
    </TouchableOpacity>
  );

  return (
    <Layout style={styles.headerContainer}>
      {!!isTabletOrMobile && (
        <Layout style={styles.headerLeft}>
          <TouchableOpacity style={styles.headerBtn} onPress={onPressMenu}>
            <Menu />
          </TouchableOpacity>
        </Layout>
      )}
      <Layout style={styles.headerMain}>
        <Text style={styles.day}>{format(today, 'EEEE')}</Text>
        <Text style={styles.month}>{format(today, 'd MMM')}</Text>
      </Layout>
      <Layout style={[styles.row, styles.headerRight]}>
        <Popover
          visible={isAccountVisible}
          placement="bottom end"
          anchor={renderToggleButton}
          style={styles.popover as StyleProp<ViewStyle>}
          onBackdropPress={() => setAccountVisible(false)}>
          <Layout style={styles.accountDropDown}>
            <Layout style={styles.polygon}>
              <Polygon />
            </Layout>
            <Layout style={styles.dropDownContent}>
              <Image
                style={styles.profileDropdown as StyleProp<ImageStyle>}
                source={
                  user?.profileImage
                    ? { uri: user?.profileImage }
                    : require('../../assets/images/user_placeholder.png')
                }
              />
              <Text style={styles.fullName}>{user?.fullName || user?.email}</Text>
              <Text style={styles.o2xId}>{`O2X ID #${user?.o2XId}`}</Text>
              <Divider style={styles.divider} />
              <TouchableOpacity
                style={[styles.accountBtn, isProfileSelected && { backgroundColor: '#767F6A' }]}
                onPress={onPressAccountProfile}>
                <Profile />
                <Text style={styles.accountBtnText}>Profile</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.accountBtn,
                  isAccountSettingsSelected && {
                    backgroundColor: '#767F6A',
                  },
                ]}
                onPress={onPressAccountSetting}>
                <Settings />
                <Text style={styles.accountBtnText}>Account Settings</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.logout} onPress={logOut}>
                <Logout />
                <Text style={styles.logoutText}>Log Out</Text>
              </TouchableOpacity>
            </Layout>
          </Layout>
        </Popover>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  headerContainer: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerLeft: {
    flexGrow: 0,
  },
  headerMain: {
    flexGrow: 1,
  },
  headerRight: {
    flexGrow: 0,
  },
  day: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  month: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginTop: 3,
  },
  row: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerBtn: {
    marginHorizontal: 15,
  },
  accountContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  profile: {
    height: 30,
    width: 30,
    borderRadius: 15,
    marginHorizontal: 15,
  },
  accountDetails: {
    backgroundColor: 'transparent',
    paddingRight: 10,
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  o2xId: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#4E7B89',
    textTransform: 'uppercase',
    marginTop: 3,
  },
  accountDropDown: {
    width: 240,
    backgroundColor: 'transparent',
  },
  polygon: {
    width: '100%',
    alignItems: 'flex-end',
    paddingHorizontal: 24,
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  dropDownContent: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 35,
    paddingHorizontal: 24,
    backgroundColor: '#203950',
  },
  profileDropdown: {
    height: 64,
    width: 64,
    borderRadius: 32,
    marginBottom: 24,
  },
  popover: {
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
  divider: {
    width: 123,
    height: 1,
    marginTop: 18,
    marginBottom: 20,
    backgroundColor: '#31576D',
  },
  accountBtn: {
    width: 240,
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 25,
    marginTop: 10,
  },
  accountBtnText: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    marginLeft: 18,
  },
  logout: {
    width: '100%',
    height: 46,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'olive',
    marginTop: 30,
  },
  logoutText: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    marginLeft: 20,
  },
});

export default observer(Header);
