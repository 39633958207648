import { useNavigation } from '@react-navigation/native';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Text } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import SettingsContainer from '../Settings/SettingsContainer';

type Props = {};

const About: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeArea();

  return (
    <SettingsContainer>
      <Text style={styles.title}>About O2X</Text>
    </SettingsContainer>
  );
};

const themedStyles = StyleService.create({
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    letterSpacing: 1,
    marginBottom: 24,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    letterSpacing: 1,
    marginBottom: 32,
  },
});

export default observer(About);
