import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { isEmpty, split } from 'lodash';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import EventModel from 'o2x-store/src/models/Event';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  ScrollView,
  Share,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
// import { showLocation } from 'react-native-map-link';
import ReadMore from 'react-native-read-more-text';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from '../../components/NavigationBar';
import ProgramDetailActions from '../../components/ProgramDetailActions';
import UserDetail from '../../components/UserDetail';
import { useSaveList } from '../../hooks/list';
import EventCtaLink from './EventCtaLink';

type Props = {
  route: any;
};

const EventDetail: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const [event, setEvent] = useState<EventModel>();
  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(store, LIST_TYPE.EVENT);
  const color = '#4E7B89'; // blue-secondary
  const { user } = store.auth;
  const [loading, setLoading] = useState(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        // We refetch on focus to reflect changes on checkin
        if (store.event.events.get(`${id}`) === undefined) {
          await store.event.fetchEventsByIds([`${id}`]);
        }
        setEvent(store.event.events.get(`${id}`));
        setLoading(false);
      })();
    }, [store.event.events, store.event.loading]),
  );

  const renderTruncatedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={[styles.readMore, { color }]} category="p2">
          Read More
        </Text>
      </TouchableOpacity>
    ),
    [color],
  );

  const renderRevealedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={[styles.readMore, { color }]} category="p2">
          Show Less
        </Text>
      </TouchableOpacity>
    ),
    [color],
  );

  const toggleBookmark = useCallback(() => {
    if (event) {
      if (event.saveList.length === 0) {
        // Save
        addToList(savedList, `${event.id}`);
      } else {
        // Remove
        removeFromList(savedList, `${event.id}`);
      }
    }
  }, [savedList, event]);

  const onMap = useCallback(() => {
    if (event?.location) {
      const [latitude, longitude] = split(event.location, ',');
      const url = `https://www.google.com/maps/@${latitude},${longitude}?q=${encodeURIComponent(
        event.address,
      )}`;
      return Linking.openURL(url);
    }
  }, [event]);

  const onCheckin = useCallback(() => {
    // if (!user?.address || !user?.city || !user?.stateCode || !user?.zipCode) {
    //   alert('Please enter your address, city, state code and zip code.');
    //   return;
    // }
    navigation.navigate('EventCheckin', { id: event?.id });
  }, [event]);

  const goToSpecialists = useCallback(() => {
    navigation.navigate('EventSpecialists', {
      screen: 'EventSpecialists',
      params: {
        id: event?.id,
      },
    });
  }, [event]);

  const onShare = useCallback(async () => {
    if (event) {
      const shareData = {
        message: event.viewUrl,
        url: event.viewUrl,
        title: event.name,
      };
      try {
        const result = await Share.share(shareData);
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }, [event]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const openEventSurvey = () => {
    if (Platform.OS === 'web') {
      return window.open(config.urls.eventSurvey, '_blank');
    }
    return Linking.openURL(config.urls.eventSurvey);
  };

  const openAssessment = useCallback(() => {
    if (event?.hasAssessment) {
      let params = {};
      if (event?.physicalAssessment) {
        params = {
          assessmentId: event?.physicalAssessment,
          type: ASSESSMENT_TYPE.PHYSICAL,
        };
      } else if (event?.formAssessment) {
        params = {
          assessmentId: event?.formAssessment,
          type: ASSESSMENT_TYPE.FORM,
        };
      }
      if (!isEmpty(params)) {
        navigation.navigate('AssessmentHistory', params);
      }
    }
  }, [event]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            {!event || loading ? (
              <View style={styles.loader}>
                <Spinner />
              </View>
            ) : (
              <>
                <Image
                  style={styles.cover as StyleProp<ImageStyle>}
                  source={{ uri: event.coverImageBanner }}
                />
                <ScrollView
                  style={
                    isMobile
                      ? styles.scrollMobile
                      : isDeviceMaxWidth600
                      ? styles.scrollMaxWidth600
                      : styles.scroll
                  }>
                  <Layout style={styles.content}>
                    <Text category="h2">{event.name}</Text>
                    <View style={styles.subText}>
                      <Text style={[styles.subtitle, { color }]} category="c2">
                        {event.timeDisplayStr(user?.settings.twentyFourHourClock)}
                      </Text>
                      {event.checkin && event.checkin.isComplete && (
                        <Text style={styles.checkIn}>CHECKED-IN</Text>
                      )}
                    </View>
                    <ProgramDetailActions
                      style={styles.actions}
                      onMap={event.location ? onMap : undefined}
                      onSave={toggleBookmark}
                      onShare={onShare}
                      saved={event ? event.saveList.length > 0 : false}
                    />
                    {event.specialists.map((specialist: number) => (
                      <UserDetail style={styles.author} id={specialist} label="Hosted by" />
                    ))}
                    {!!event.eventCtaUrls &&
                      event.eventCtaUrls.length > 0 &&
                      event.eventCtaUrls.map((eventCta, index) => {
                        if (eventCta.ctaUrl) {
                          return (
                            <EventCtaLink
                              key={index}
                              styleCtaButton={styles.ctaButton}
                              styleCtaLabel={styles.ctaLabel}
                              eventCta={eventCta}
                            />
                          );
                        }
                      })}
                    {!!event.description && (
                      <ReadMore
                        numberOfLines={5}
                        renderTruncatedFooter={renderTruncatedFooter}
                        renderRevealedFooter={renderRevealedFooter}>
                        <Text category="p2">{event.description}</Text>
                      </ReadMore>
                    )}
                  </Layout>
                </ScrollView>
                {!(event.checkin && event.checkin.isComplete) && (
                  <TouchableOpacity style={styles.button} onPress={onCheckin}>
                    <Text style={styles.buttonText}>Check in</Text>
                  </TouchableOpacity>
                )}
                {event.checkin && event.checkin.isComplete && (
                  <>
                    <TouchableOpacity
                      style={[styles.button, { backgroundColor: '#203950' }]}
                      onPress={openAssessment}>
                      <Text style={styles.buttonText}>Take the Assessment</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={openEventSurvey}>
                      <Text style={styles.buttonText}>Take the Survey</Text>
                    </TouchableOpacity>
                  </>
                )}
                {/* event.usersWithoutSurvey.length > 0 && (
                    <TouchableOpacity
                      style={styles.button}
                      onPress={goToSpecialists}
                    >
                      <Text style={styles.buttonText}>
                        Take daily specialist survey
                      </Text>
                    </TouchableOpacity>
                  )} */}
                <NavigationBar
                  style={[styles.navigation]}
                  showBack={false}
                  showClose
                  onClose={goBack}
                />
              </>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  actions: {
    marginTop: 20,
    marginBottom: 24,
  },
  author: {
    marginBottom: 16,
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  rating: {
    marginTop: 30,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
  },
  scroll: {
    flex: 1,
    paddingHorizontal: '25%',
  },
  scrollMobile: {
    flex: 1,
    paddingHorizontal: '5%',
  },
  scrollMaxWidth600: {
    flex: 1,
    paddingHorizontal: '15%',
  },
  subtitle: {
    marginTop: 4,
    maxWidth: '70%',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  checkIn: {
    fontSize: 14,
    color: 'white',
  },
  subText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    margiVertical: 20,
  },
  ctaButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    height: 40,
    backgroundColor: 'olive',
  },
  ctaLabel: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export default observer(EventDetail);
