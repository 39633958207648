import { useNavigation } from '@react-navigation/core';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { LinearGradient } from 'expo-linear-gradient';
import { compact } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Image, ImageStyle, SectionList, StyleProp, TouchableOpacity, View } from 'react-native';
import DLSweatItem from 'src/components/Downloaded/DLSweatItem';
import DLSweatTabs from 'src/components/Downloaded/DLSweatTabs';
import DownloadedItem from 'src/models/DownloadedItem';
import { useNativeStore } from 'src/stores';
import TopNav from '../../components/Question/TopNav';

enum WORKOUT_TYPE {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

type Props = {
  route: any;
};

const DownloadedSweatProgram: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { downloads } = useNativeStore();
  useKeepAwake();

  const { id, workoutId, programId, programTitle } = props.route.params;

  const [workoutType, setWorkoutType] = useState(WORKOUT_TYPE.SWEAT);
  const [expandedExercise, setExpandedExercise] = useState<number>();

  const program = downloads.sweatPrograms.get(`${programId}`);

  const workout =
    programId && program
      ? program.workouts.get(`${workoutId}`)
      : downloads.sweatWorkouts.get(`${id}`);

  const banner = program?.imageBanner ?? workout?.imageBanner;

  const media = useMemo(() => {
    if (banner) {
      return downloads.getOrCreateThumbnail({
        uri: banner,
        type: 'sweat',
        id: programId ?? id,
      });
    }
    return null;
  }, [banner, programId, id]);

  useEffect(() => {
    if (media) {
      media.downloadMedia();
    }
  }, [media]);

  const sections = useMemo(() => {
    if (!workout || workout.sections.length < 3) return [];

    if (workoutType === WORKOUT_TYPE.PREPARE) {
      const data = compact(
        workout.sections[0].exercises.map((s) => downloads.sweatExercises.get(`${s}`)),
      );
      return [{ data, title: '' }];
    }
    if (workoutType === WORKOUT_TYPE.RECOVER) {
      const data = compact(
        workout.sections[workout.sections.length - 1].exercises.map((s) =>
          downloads.sweatExercises.get(`${s}`),
        ),
      );
      return [{ data, title: '' }];
    }
    const sections: any[] = [];
    workout.sections.slice(1, -1).forEach((section) => {
      const title =
        section.timer && section.timer !== 'variable'
          ? section.timer
          : (section.circuitSets || 1) > 1
          ? 'circuit'
          : 'perform in order';
      const data: DownloadedItem[] = [];
      section.exercises.forEach((s) => {
        const exercise = downloads.sweatExercises.get(`${s}`);
        if (exercise) data.push(exercise);
      });
      sections.push({
        title,
        data,
        work: section.timerWork,
        rest: section.timerRest,
      });
    });
    return sections;
  }, [workout, workoutType]);

  useEffect(() => {
    setExpandedExercise(undefined);
  }, [workoutType]);

  const renderItem = useCallback(
    ({ section, item }) => (
      <DLSweatItem
        item={item}
        expandedExercise={expandedExercise}
        setExpandedExercise={setExpandedExercise}
        workoutType={workoutType}
        type={section?.title}
        work={section.work}
        rest={section.rest}
      />
    ),
    [expandedExercise, workoutType],
  );

  const renderSectionHeader = useCallback(({ section }) => {
    if (!section.title) return null;
    return (
      <Text category="c1" style={styles.section}>
        {section.title}
      </Text>
    );
  }, []);

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const keyExtractor = useCallback((i, j) => `${j}${i.id}${i.exercise}`, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onStart = useCallback(() => {
    navigation.navigate('DownloadedWorkout', {
      id,
      workoutId,
      programId,
      programTitle,
    });
  }, []);

  if (!workout) return null;

  const imageBanner = media && media.downloaded ? media.localUri : banner;

  return (
    <Layout style={styles.container}>
      <View style={styles.header}>
        {imageBanner ? (
          <Image style={styles.cover as StyleProp<ImageStyle>} source={{ uri: imageBanner }} />
        ) : (
          <View style={styles.cover} />
        )}
        <LinearGradient colors={['transparent', '#10283E']} style={styles.coverGradient} />
        <Text category="h2" style={styles.title}>
          {programTitle ?? workout.title ?? workout.displayName}
        </Text>
        <Text style={styles.subtitle} category="c1">
          {workout.dayDisplay}
        </Text>
      </View>
      <DLSweatTabs type={workoutType} setType={setWorkoutType} />
      <View style={styles.content}>
        <SectionList
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
          ListFooterComponent={renderFooter}
          keyExtractor={keyExtractor}
          sections={sections}
        />
      </View>
      <TouchableOpacity style={styles.button} onPress={onStart}>
        <Text style={styles.label} category="c1">
          START
        </Text>
      </TouchableOpacity>
      <View style={styles.navigationContainer}>
        <TopNav style={styles.navigationStyle} onBack={goBack} showClose={false} />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  content: {
    flex: 1,
  },
  tabs: {
    flexDirection: 'row',
  },
  section: {
    padding: 12,
    backgroundColor: 'black',
    textTransform: 'uppercase',
  },
  navigationContainer: {
    position: 'absolute',
    height: 100,
    width: '100%',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigationStyle: {
    backgroundColor: 'transparent',
  },
  header: {
    height: 290,
  },
  footer: {
    height: 30,
  },
  label: {
    textAlign: 'center',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'olive',
  },
  cover: {
    height: 170,
  },
  coverGradient: {
    position: 'absolute',
    height: 120,
    width: '100%',
    top: 50,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    marginHorizontal: 25,
    marginTop: 12,
  },
  subtitle: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
    marginHorizontal: 25,
    marginTop: 8,
  },
});

export default observer(DownloadedSweatProgram);
