import {
  Icon,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
} from 'o2x-store/src/models/FTELibrary';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import DraggableFlatList, {
  RenderItemParams,
} from 'react-native-draggable-flatlist';
import { useMediaQuery } from 'react-responsive';
import AddExerciseModal from './AddExerciseModal';
import AddGlobalStepModal from './AddGlobalStepModal';
import AddStep from './AddStep';
import ExerciseParamModal from './ExerciseParameterModal';
import StepItem from './StepItem';

type Props = {
  content: string;
  stepList?: (FTESweatWorkoutStep | SweatWorkoutStep)[];
  setStepList?: Function;
  workoutList?: (FTESweatWorkout | SweatWorkout)[];
  setWorkoutList?: Function;
  workout?: FTESweatWorkout | SweatWorkout;
  workoutIndex?: number;
  setPrepare: Function;
  setRecover: Function;
  setPrepName: Function;
  setRecName: Function;
  prepName: string;
  recName: string;
  prepare: number | null;
  recover: number | null;
  setAllPrepare?: Function;
  setAllRecover?: Function;
  stepClipboard?: FTESweatWorkoutStep | SweatWorkoutStep;
  setStepClipboard?: Function;
};

const stepTypes: { [key: string]: string } = {
  '': 'Perform in Order',
  amrap: 'AMRAP (As Many Rounds as Possible)',
  emom: 'EMOM (Every Minute On the Minute)',
  tabata: 'TABATA',
  variable: 'Standard Circuit',
};

const StepDetails: React.FC<Props> = (props) => {
  const {
    content,
    stepList,
    setStepList,
    workoutList,
    setWorkoutList,
    workout,
    workoutIndex,
    setPrepare,
    setRecover,
    setPrepName,
    setRecName,
    prepName,
    recName,
    prepare,
    recover,
    setAllPrepare,
    setAllRecover,
    stepClipboard,
    setStepClipboard,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const [sets, setSets] = useState(1);
  const [timerWork, setTimerWork] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [exerModalVisible, setExerModalVisible] = useState(false);
  const [stepModalVisible, setStepModalVisible] = useState(false);
  const [stepType, setStepType] = useState('');
  const [paramModalVisible, setParamModalVisible] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [exerIndex, setExerIndex] = useState(0);
  const [exercise, setExercise] = useState<FTESweatWorkoutStepExercise>();
  const [removeIndex, setRemoveIndex] = useState<number>();
  const [isEditingStep, setIsEditingStep] = useState(false);

  useEffect(() => {
    if (removeIndex! >= 0) {
      if (content === 'workout' && setStepList) {
        const list = [...stepList!];
        list.splice(removeIndex, 1);
        setStepList([...list]);
      } else if (content === 'program' && setWorkoutList) {
        const workoutCopy = workout;
        const workoutListCopy = [...workoutList!];
        workoutCopy?.steps?.splice(removeIndex, 1);
        workoutListCopy[workoutIndex] = workoutCopy;
        setWorkoutList(workoutListCopy);
      }
      setRemoveIndex(undefined);
    }
  }, [removeIndex, stepList]);

  const hideExerModal = useCallback(() => {
    setExerModalVisible(false);
  }, []);

  const showExerModal = useCallback((index: number) => {
    setStepIndex(index);
    setExerModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const convertTime = (time: string) => {
    if (time.includes(':')) {
      const [minutes, seconds] = time.split(':');
      const totalTimeInSeconds = +minutes * 60 + +seconds;
      return totalTimeInSeconds;
    } else {
      return parseInt(time);
    }
  };

  const addStep = useCallback(() => {
    setSets(1);
    setTimerWork('');
    showModal();
  }, [workoutList, selectedType, setWorkoutList]);

  const addStepDetailed = useCallback(() => {
    if (!isEditingStep) {
      if (selectedType === '' || selectedType === 'tabata') {
        const stepObject: Partial<FTESweatWorkoutStep> = {
          id: Math.random(),
          circuitSets: selectedType === 'tabata' ? 8 : 1,
          circuitTimer: selectedType,
          exercises: [],
        };
        if (content === 'workout' && setStepList) {
          const list = stepList ? [...stepList!, stepObject] : [stepObject];
          setStepList(list);
        } else {
          if (setWorkoutList) {
            const workoutCopy = workout;
            const workoutListCopy = [...workoutList!];
            workoutCopy.steps = workoutCopy?.steps
              ? [...workoutCopy?.steps, stepObject]
              : [stepObject];
            workoutListCopy[workoutIndex] = workoutCopy;
            setWorkoutList(workoutListCopy);
          }
        }
      } else {
        const stepObject: Partial<FTESweatWorkoutStep> = {
          id: Math.random(),
          circuitSets: selectedType === 'tabata' ? 8 : sets,
          circuitTimer: selectedType,
          circuitTimerWork: convertTime(timerWork),
          exercises: [],
        };
        if (content === 'workout' && setStepList) {
          const list = stepList ? [...stepList!, stepObject] : [stepObject];
          setStepList(list);
        } else {
          if (setWorkoutList) {
            const workoutCopy = workout;
            const workoutListCopy = [...workoutList!];
            workoutCopy.steps = workoutCopy?.steps
              ? [...workoutCopy?.steps, stepObject]
              : [stepObject];
            workoutListCopy[workoutIndex] = workoutCopy;
            setWorkoutList(workoutListCopy);
          }
        }
      }
    } else {
      if (content === 'workout' && stepList && setStepList) {
        const arr = [...stepList];
        arr[stepIndex].circuitSets = selectedType === 'tabata' ? 8 : sets;
        arr[stepIndex].circuitTimer = selectedType;
        arr[stepIndex].circuitTimerWork = convertTime(timerWork);
        setStepList([...arr]);
      } else {
        if (workoutList) {
          const workoutCopy = workout;
          const workoutListCopy = [...workoutList!];
          workoutCopy.steps[stepIndex].circuitTimer = selectedType;
          workoutCopy.steps[stepIndex].circuitSets =
            selectedType === 'tabata' ? 8 : sets;
          workoutCopy.steps[stepIndex].circuitTimerWork =
            convertTime(timerWork);
          workoutListCopy[workoutIndex] = workoutCopy;
          setWorkoutList(workoutListCopy);
        }
      }
      setIsEditingStep(false);
    }
    hideModal();
  }, [
    selectedType,
    setWorkoutList,
    workoutList,
    timerWork,
    sets,
    stepList,
    workout,
  ]);

  const editStepDetails = useCallback(
    (timer: string, index: number) => {
      if (timer === '' || timer === 'tabata') {
        if (content === 'workout' && stepList && setStepList) {
          const arr = [...stepList];
          arr[index].circuitTimer = timer;
          arr[index].circuitSets = 8;
          setStepList([...arr]);
        } else {
          const workoutCopy = workout;
          const workoutListCopy = [...workoutList!];
          workoutCopy.steps[index].circuitTimer = timer;
          workoutCopy.steps[index].circuitSets = 8;
          workoutListCopy[workoutIndex] = workoutCopy;
          setWorkoutList(workoutListCopy);
        }
      } else {
        setIsEditingStep(true);
        setStepIndex(index);
        setSets(1);
        setTimerWork('');
        showModal();
      }
    },
    [stepList, workoutList, workout],
  );

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  const renderItem = useCallback(
    ({
      item,
      index,
      drag,
    }: RenderItemParams<FTESweatWorkoutStep | SweatWorkoutStep>) => {
      return (
        <StepItem
          key={`${item.id!}-${index}-${item.exercises?.length}`}
          stepIndex={index}
          setStepIndex={setStepIndex}
          step={item}
          setExerIndex={setExerIndex}
          setExercise={setExercise}
          setSelectedType={setSelectedType}
          editStepDetails={editStepDetails}
          setParamModalVisible={setParamModalVisible}
          showExerModal={showExerModal}
          stepJSON={JSON.stringify(item)}
          onRemoveStep={() => setRemoveIndex(index)}
          content={content}
          stepList={stepList}
          setStepList={setStepList}
          workoutList={workoutList}
          setWorkoutList={setWorkoutList}
          workout={workout}
          workoutIndex={workoutIndex}
          stepClipboard={stepClipboard}
          setStepClipboard={setStepClipboard}
          pasteStep={pasteStep}
          drag={drag}
        />
      );
    },
    [stepList, stepClipboard, workoutList],
  );

  const pasteStep = (index?: number) => {
    const workoutCopy = workout;
    const workoutListCopy = [...workoutList!];
    if (!index && index !== 0) {
      if (workoutCopy) {
        if (workoutCopy?.steps) workoutCopy?.steps?.push(stepClipboard);
        else workoutCopy.steps = [stepClipboard];
      }
    } else {
      workoutCopy?.steps?.splice(index, 0, stepClipboard);
    }
    workoutListCopy[workoutIndex] = workoutCopy;
    setWorkoutList(workoutListCopy);
    if (setStepClipboard) setStepClipboard(undefined);
  };

  return (
    <ScrollView
      contentContainerStyle={
        content === 'program'
          ? styles.programStepDetails
          : [
              styles.stepDetails,
              isMobile ? { width: '90%', marginLeft: '10%' } : {},
            ]
      }
      style={
        content === 'program'
          ? isMobile
            ? styles.programContainerMobile
            : styles.programContainer
          : isMobile
          ? styles.containerMobile
          : styles.container
      }
    >
      {!!isModalVisible && (
        <AddStep
          sets={sets}
          timerWork={timerWork}
          setSets={setSets}
          setTimerWork={setTimerWork}
          selectedType={selectedType}
          addStepDetailed={addStepDetailed}
          isModalVisible={isModalVisible}
          hideModal={hideModal}
        />
      )}

      {!!exerModalVisible && (
        <AddExerciseModal
          content={content}
          exerModalVisible={exerModalVisible}
          hideExerModal={hideExerModal}
          stepList={stepList}
          setStepList={setStepList}
          stepIndex={stepIndex}
          workout={workout}
          workoutList={workoutList}
          setWorkoutList={setWorkoutList}
          workoutIndex={workoutIndex}
        />
      )}
      {!!paramModalVisible && (
        <ExerciseParamModal
          content={content}
          stepList={stepList}
          setStepList={setStepList}
          paramModalVisible={paramModalVisible}
          setParamModalVisible={setParamModalVisible}
          exercise={exercise}
          exerciseIndex={exerIndex}
          stepIndex={stepIndex}
          workoutList={workoutList}
          setWorkoutList={setWorkoutList}
          workoutIndex={workoutIndex}
        />
      )}
      {!!stepModalVisible && (
        <AddGlobalStepModal
          type={stepType}
          hideStepModal={() => setStepModalVisible(false)}
          stepModalVisible={stepModalVisible}
          setPrepare={setPrepare}
          setRecover={setRecover}
          setPrepName={setPrepName}
          setRecName={setRecName}
          currentId={stepType === 'prepare' ? prepare : recover}
          content={content}
          setAllPrepare={setAllPrepare}
          setAllRecover={setAllRecover}
        />
      )}
      <Layout style={{ width: '100%' }}>
        <Text style={[styles.stepText]}>Prepare</Text>
        <TouchableOpacity
          style={[styles.detailInput, styles.prepareContainer]}
          onPress={() => {
            setStepType('prepare');
            setStepModalVisible(true);
          }}
        >
          <Text style={styles.prepareText} numberOfLines={1}>
            {prepName}
          </Text>
        </TouchableOpacity>
        <Text style={styles.stepText}>Steps</Text>

        {!!stepList && !!setStepList && (
          <View style={{ flex: 1, width: '100%' }}>
            <DraggableFlatList
              data={stepList}
              renderItem={renderItem}
              keyExtractor={(item) => `${item.id}`}
              onDragEnd={({ data }) => setStepList(data)}
            />
          </View>
        )}

        {!!stepList && !!setWorkoutList && (
          <DraggableFlatList
            data={stepList}
            renderItem={renderItem}
            keyExtractor={(item) => `${item.id}`}
            onDragEnd={({ data }) => {
              const workoutCopy = workout;
              const workoutListCopy = [...workoutList!];
              workoutCopy.steps = data;
              workoutListCopy[workoutIndex] = workoutCopy;
              setWorkoutList(workoutListCopy);
            }}
          />
        )}

        {!!stepClipboard && (
          <TouchableOpacity
            style={{ marginTop: 10, flexDirection: 'row' }}
            onPress={() => pasteStep(undefined)}
          >
            <Icon
              name="copy-outline"
              style={{ width: 16, height: 16, marginRight: 7 }}
              fill="#8F9BB3"
            />
            <Text style={[styles.stepText, { marginTop: 0 }]}>
              Paste step here
            </Text>
          </TouchableOpacity>
        )}
        <Select
          size="medium"
          style={[styles.detailInput, { marginTop: 5 }]}
          placeholder="+  Add Step"
          onSelect={(index: IndexPath | IndexPath[]) => {
            setSelectedType(Object.keys(stepTypes)[index.row]);
            addStep();
          }}
        >
          {Object.values(stepTypes).map((type, index) => {
            return <SelectItem key={index} title={type} />;
          })}
        </Select>
        <Text style={[styles.stepText]}>Recover</Text>
        <TouchableOpacity
          style={[styles.detailInput, styles.prepareContainer]}
          onPress={() => {
            setStepType('recover');
            setStepModalVisible(true);
          }}
        >
          <Text style={styles.prepareText} numberOfLines={1}>
            {recName}
          </Text>
        </TouchableOpacity>
      </Layout>
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    // height: '90%',
    marginRight: 20,
    flexGrow: 0,
  },
  containerMobile: {
    width: '100%',
    height: '90%',
    marginRight: 20,
    flexGrow: 0,
  },
  programContainer: {
    marginRight: 10,
    marginTop: 10,
    paddingRight: 15,
    width: '100%',
    flexGrow: 0,
  },
  programContainerMobile: {
    height: '60%',
    marginRight: 10,
    marginTop: 10,
    paddingRight: 15,
    width: '100%',
    flexGrow: 0,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  detailInput: {
    width: '100%',
    marginTop: 10,
  },
  stepContainer: {
    width: '100%',
    backgroundColor: '#767F6A',
    height: 30,
    marginTop: 5,
    marginBottom: 5,
  },
  stepDetails: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexGrow: 0,
  },
  programStepDetails: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 15,
    flexGrow: 0,
  },
  stepText: {
    fontSize: 14,
    color: '#8F9BB3',
    marginTop: 10,
    fontWeight: 'bold',
  },
  stepTitle: {
    fontSize: 15,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  exerciseContainer: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#767F6A',
    height: 30,
    marginTop: 5,
    marginBottom: 5,
    width: '85%',
    marginLeft: 'auto',
  },
  exerciseText: {
    fontSize: 15,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  prepareContainer: {
    height: 40,
    borderRadius: 5,
    backgroundColor: '#203950',
    borderColor: 'black',
    borderWidth: 0.2,
    marginTop: 5,
  },
  prepareText: {
    fontSize: 12,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
  },
});

export default observer(StepDetails);
