import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { THRIVE_MEDIA_FILE_TYPE } from '../../models/ThriveMediaFile';
import { THRIVE_ACTIVITY_TYPE } from '../../utils/thrive';
import { callApiWithToken } from './base';

export const fetchThriveMediaFiles = async (token: string, type: THRIVE_MEDIA_FILE_TYPE) =>
  callApiWithToken(`${config.urls.thriveMediaFiles}${type}/`, token);

export const fetchThriveExercises = async (token: string) =>
  callApiWithToken(config.urls.thriveExercises, token);

export const fetchThriveExercisesByIds = async (token: string, ids: string) =>
  callApiWithToken(
    `${config.urls.thriveExercises}?id__in=${ids}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchThriveProgram = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${id}/`, token);

export const fetchThrivePrograms = async (token: string, url?: string) =>
  callApiWithToken(url || config.urls.thrivePrograms, token);

export const fetchActiveThrivePrograms = async (token: string, userId: number | undefined) =>
  callApiWithToken(`${config.urls.thrivePrograms}?active=True&user=${userId || ''}`, token);

export const fetchThriveProgramDays = async (token: string, program: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${program}/days/`, token);

export const fetchThriveProgramDay = async (token: string, program: number, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${program}/days/${id}/`, token);

export const startThriveProgram = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${id}/start/`, token, 'POST');
export const fetchThriveProgramsByIds = async (token: string, ids: string) =>
  callApiWithToken(
    `${config.urls.thrivePrograms}?id__in=${ids}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const batchStartThrivePrograms = async (token: string, data: Object) =>
  callApiWithToken(
    `${config.urls.thrivePrograms}batch_start/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const markCompleteThriveProgram = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${id}/mark_complete/`, token, 'POST');

export const startThriveProgramDay = async (token: string, program: number, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${program}/days/${id}/start/`, token, 'POST');

export const markCompleteThriveProgamDay = async (token: string, program: number, id: number) =>
  callApiWithToken(
    `${config.urls.thrivePrograms}${program}/days/${id}/mark_complete/`,
    token,
    'POST',
  );

export const findThrivePrograms = async (token: string) =>
  callApiWithToken(`${config.urls.thrivePrograms}?r=1&limit=5`, token, 'GET');

export const fetchThriveProgramRatings = async (token: string, program: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${program}/ratings/`, token);

export const postThriveProgramRate = async (token: string, program: number, rating: number) =>
  callApiWithToken(
    `${config.urls.thrivePrograms}${program}/rate/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ rating }),
  );

export const searchThrivePrograms = async (token: string, qs: string, url?: string) =>
  callApiWithToken(url || `${config.urls.thrivePrograms}?${qs}`, token, 'GET', {}, undefined, true);

export const searchThriveExercises = async (token: string, qs: string, url?: string) =>
  callApiWithToken(
    url || `${config.urls.thriveExercises}?${qs}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const fetchThriveProgramPreview = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.thrivePrograms}${id}/preview/`, token, 'GET');

export const logThriveActivity = async (
  token: string,
  activity: THRIVE_ACTIVITY_TYPE,
  start: string,
  end: string,
) =>
  callApiWithToken(
    `${config.urls.thriveActivity}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ activity, start, end }),
  );
