import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import SavedList from 'o2x-store/src/models/SavedList';
import React from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Handle from '../../assets/images/handle-web.svg';

export type Props = {
  list: SavedList | undefined;
  showHandle?: boolean;
  showAdd?: boolean;
  key: string;
  onPress: () => void;
};

const SavedListItem: React.FC<Props> = (props) => {
  const { list, showHandle = false, showAdd = false, onPress } = props;
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout
      style={[
        isMobile ? styles.containerMobile : styles.container,
        { borderLeftWidth: 4, borderColor: list?.color, borderStyle: 'solid' },
      ]}
      level="2"
    >
      {list && (
        <TouchableOpacity style={styles.contentContainer} onPress={onPress}>
          {!!list && !!list.image && (
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={{ uri: list.image }}
            />
          )}
          <Layout
            style={isMobile ? styles.contentMobile : styles.content}
            level="2"
          >
            <View style={styles.label}>
              <Text numberOfLines={1}>{list.title}</Text>
              {!!list.subtitle && (
                <Text
                  style={[styles.subtitle, { color: list?.color }]}
                  numberOfLines={1}
                >
                  {list.subtitle}
                </Text>
              )}
            </View>
            <View style={styles.iconContainer}>
              {showHandle && <Handle fill="#FFFFFF" />}
              {showAdd && (
                <Icon style={styles.plus} name="plus-outline" fill="#FFFFFF" />
              )}
            </View>
          </Layout>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

export const themedStyles = StyleService.create({
  container: {
    marginTop: 12,
    height: 60,
    width: 410,
  },
  containerMobile: {
    marginTop: 12,
    height: 60,
    width: '100%',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 5,
    marginRight: 16,
    marginVertical: 8,
  },
  contentMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginVertical: 5,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  label: {
    flexDirection: 'column',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    marginTop: 7,
  },
  image: {
    height: 60,
    width: 60,
  },
  plus: {
    width: 22,
    height: 22,
  },
  iconContainer: {
    alignSelf: 'center',
    paddingBottom: 8,
  },
});

export default SavedListItem;
