import Slider from '@react-native-community/slider';
import { StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useNativeStore } from '../../stores';

type Props = {
  isHidden?: boolean;
};

const ThriveStartSlider: React.FC<Props> = (props) => {
  const { thriveStart } = useNativeStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  const onValueChange = useCallback(() => {
    if (thriveStart.playing) {
      thriveStart.pause();
    }
  }, [thriveStart.playing]);

  if (props.isHidden) {
    return null;
  }

  return (
    <View style={styles.sliderContainer}>
      <Slider
        style={styles.slider}
        minimumValue={0}
        minimumTrackTintColor={theme.white}
        maximumValue={thriveStart.duration}
        maximumTrackTintColor={theme['darker-blue']}
        thumbImage={require('../../assets/images/slider-thumbnail.png')}
        value={thriveStart.position}
        onValueChange={onValueChange}
        onSlidingComplete={thriveStart.setPosition}
      />
    </View>
  );
};

const themedStyles = StyleService.create({
  slider: {
    flex: 1,
  },
  sliderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
});

export default observer(ThriveStartSlider);
