import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { filter } from 'lodash';
import { observer } from 'mobx-react-lite';
import PhysicalAssessment from 'o2x-store/src/models/PhysicalAssessment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { SectionGrid } from 'react-native-super-grid';
import Filter from '../../assets/images/filter.svg';
import AssessmentItem from '../../components/AssessmentItem';

type Props = {};

const AssessmentList: React.FC<Props> = (props) => {
  const { assessment } = useStore();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const data = useMemo(() => {
    let data = assessment.assessments;

    if (assessment.bodyComposition.size > 0) {
      // no longer use old body comp assessment
      // data = [...data, BodyCompositionAssessment];
    }

    if (search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();
      data = data.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseSearch) ||
          item.description.toLowerCase().includes(lowerCaseSearch),
      );
    }

    if (data.length === 0) {
      return [];
    }

    const myAssessments = filter(data, (d: PhysicalAssessment) => d.currentSubmission !== null);
    const otherAssessments = filter(data, (d: PhysicalAssessment) => d.currentSubmission === null);

    if (myAssessments.length > 0 && otherAssessments.length === 0) {
      return [
        {
          title: 'My Assessments',
          data: myAssessments,
        },
      ];
    }

    if (myAssessments.length === 0 && otherAssessments.length > 0 && search.length > 0) {
      return [
        {
          title: 'All Assessments',
          data: otherAssessments,
        },
      ];
    }

    return [
      {
        title: 'My Assessments',
        data: myAssessments,
      },
      {
        title: 'All Assessments',
        data: otherAssessments,
      },
    ];
  }, [
    assessment.assessments.values(),
    assessment.physicalAssessments.values(),
    assessment.physicalAssessmentSubmissions.values(),
    search,
  ]);

  const keyExtractor = useCallback((item) => `${item.type}-${item.id}`, []);

  const renderItem = useCallback(
    ({ item }) => (
      <AssessmentItem
        name={item.name}
        coverImageThumbnail={item.coverImageThumbnail}
        id={item.id}
        type={item.type}
        previousScore={item.previousScore}
        scoringType={item.scoringType}
      />
    ),
    [],
  );

  const renderSeparator = useCallback(() => <View style={styles.separator} />, []);

  const renderSectionHeader = useCallback(
    ({ section: { title } }) => <Text style={styles.sectionTitle}>{title}</Text>,
    [],
  );

  const renderSectionFooter = useCallback(
    ({ section }) => (
      <View
        style={[
          styles.sectionFooter,
          section.title === 'My Assessments' &&
            section.data.length === 0 &&
            styles.sectionFooterEmpty,
        ]}>
        {section.title === 'My Assessments' && section.data.length === 0 && (
          <Text style={styles.emptyAssessments}>
            Start your first assessment by selecting one below
          </Text>
        )}
      </View>
    ),
    [],
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        await assessment.fetchPhysicalAssessments();
        await assessment.fetchFormAssessments();
        // await assessment.fetchBodyComposition();
        setLoading(false);
      })();
    }, [setLoading]),
  );

  return (
    <>
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout style={styles.container}>
              <Layout style={styles.searchWrapper}>
                {/*
                <Layout style={styles.row}>
                  <TouchableOpacity style={styles.saved}>
                    <Saved />
                    <Text style={styles.savedText}>View Saved</Text>
                  </TouchableOpacity>
                </Layout>
                */}
                <Layout style={styles.search}>
                  <Icon style={styles.searchIcon} name="search" fill={theme.white} />
                  <TextInput
                    style={styles.searchInput}
                    placeholder="Search"
                    placeholderTextColor={theme.white}
                    returnKeyType="search"
                    value={search}
                    onChangeText={setSearch}
                  />
                  <Filter height={20} width={20} stroke={theme.white} />
                </Layout>
              </Layout>
              <SectionGrid
                style={styles.list}
                itemDimension={242}
                sections={data}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                ItemSeparatorComponent={renderSeparator}
                renderSectionHeader={renderSectionHeader}
                renderSectionFooter={renderSectionFooter}
              />
            </Layout>
          </ScrollView>
        </Layout>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 5,
  },
  list: {
    flex: 1,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#091C2D',
    padding: 10,
    width: '100%',
  },
  searchIcon: {
    height: 24,
    width: 24,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    marginHorizontal: 10,
    outlineWidth: 0,
  },
  searchWrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
    paddingHorizontal: 10,
  },
  separator: {
    height: 12,
  },
  loader: {
    flex: 1,
    backgroundColor: 'wallpaper',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    marginBottom: 12,
    paddingHorizontal: 10,
  },
  sectionFooter: {
    marginBottom: 24,
  },
  emptyAssessments: {
    fontWeight: '600',
    fontSize: 18,
    color: 'blue-secondary',
    paddingBottom: 30,
    maxWidth: '85%',
  },
  row: {
    backgroundColor: 'transparent',
  },
  saved: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  savedText: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    marginHorizontal: 20,
  },
});

export default observer(AssessmentList);
