import { observable } from 'mobx';
import { getRoot, model, Model, modelFlow, _async, _await, prop, modelAction } from 'mobx-keystone';
import { RootStore } from '.';
import config from '../config';
import {
  FTEGroupBreathe,
  FTEGroupRecipeListModel,
  FTEGroupSweatProgramListModel,
  FTEGroupSweatWorkoutListModel,
  FTEGroupThriveExerciseListModel,
  FTEGroupThriveMeditateListModel,
  FTEGroupThriveProgramListModel,
  FTEGroupThriveSleepListModel,
  FTEOrganizationBreathe,
  FTEOrganizationModel,
  FTEOrganizationRecipeListModel,
  FTEOrganizationSweatProgramListModel,
  FTEOrganizationSweatWorkoutListModel,
  FTEOrganizationThriveExerciseListModel,
  FTEOrganizationThriveMeditateListModel,
  FTEOrganizationThriveProgramListModel,
  FTEOrganizationThriveSleepListModel,
  FTESweatExercise,
  FTESweatProgram,
  FTESweatProgramLibraryModel,
  FTESweatWorkout,
  FTESweatWorkoutLibraryModel,
  FTESweatWorkoutStepExerciseSet,
  FTEUserBreathe,
  FTEUserRecipeListModel,
  FTEUserSweatProgramListModel,
  FTEUserSweatWorkoutListModel,
  FTEUserThriveExerciseListModel,
  FTEUserThriveMeditateListModel,
  FTEUserThriveProgramListModel,
  FTEUserThriveSleepListModel,
  OSSGroup,
} from '../models/FTELibrary';
import SweatProgram from '../models/SweatProgram';
import SweatWorkout, { SweatWorkoutStep } from '../models/SweatWorkout';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/FTELibraryStore')
export default class FTELibraryStore extends Model({
  fteOrganization: prop<FTEOrganizationModel[]>(() => [] as FTEOrganizationModel[]),
  ossGroups: prop<OSSGroup[]>(() => [{ groupName: 'All' }] as OSSGroup[]),
}) {
  @observable
  loading = false;

  @modelAction
  setFTEOrganization(fteOrganization: FTEOrganizationModel[]) {
    this.fteOrganization = [...fteOrganization];
  }

  @modelAction
  setOSSGroups(ossGroups: OSSGroup[]) {
    this.ossGroups = [{ groupName: 'All' } as OSSGroup, ...ossGroups];
  }

  getOOSGroupById(id: number) {
    return this.ossGroups.find((group) => group.id == id)!;
  }

  @modelFlow
  fetchFTEOrganization = _async(function* (this: FTELibraryStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMyFTEOrganizations(rootStore.auth.token)));
      this.setFTEOrganization(entities);
    } catch (error) {
      console.warn('[DEBUG] error fetching fte organizations', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchOrganizationDetails = _async(function* (this: FTELibraryStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchOrganizationDetails(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching fte organizations', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchOSSGroups = _async(function* (this: FTELibraryStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchOSSGroups(rootStore.auth.token)));
      this.setOSSGroups(entities.results);
    } catch (error) {
      console.warn('[DEBUG] error fetching fte organizations', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createOSSGroup = _async(function* (this: FTELibraryStore, data: OSSGroup) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createOSSGroup(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating oss group', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateOSSGroup = _async(function* (this: FTELibraryStore, data: OSSGroup) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.UpdateOSSGroup(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating oss group', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteOSSGroup = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteOSSGroup(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting oss group', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  searchFTEOrganization = _async(function* (this: FTELibraryStore, qs: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.searchFTEOrganizations(rootStore.auth.token, qs)));
    } catch (error) {
      console.warn('[DEBUG] error fetching fte organizations', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTERecipes = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-recipes/?limit=20`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTEThrivePrograms = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-thrive-programs/?limit=20`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTEThriveExercises = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-thrive-exercises/?limit=20&is_yoga=True`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercises', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTESweatExercises = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    const initialUrl = `${config.urls.fteLibrary}fte-sweat-exercises/?limit=10`;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat exercises', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchExistingSweatPrograms = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}existing-sweat-programs/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchExistingSweatWorkouts = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}existing-sweat-workouts/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchMySweatPrograms = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-sweat-programs/`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchMySweatWorkouts = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-sweat-workouts/`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTESleepAudioFiles = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-thrive-audio/?in_sleep=True&limit=20/`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sleep audio files', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFTEMeditateAudioFiles = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.fteLibrary}fte-thrive-audio/?in_meditate=True&limit=20/`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchContent(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching meditate audio files', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserRecipeList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId ? `${config.urls.fteLibrary}fte-user-eat/?user=${id}&fte=${fteId}` : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserRecipeList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgRecipeList = _async(function* (
    this: FTELibraryStore,
    userId: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-eat/?organization=${userId}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetOrganizationRecipeList(rootStore.auth.token, userId, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupRecipeList = _async(function* (
    this: FTELibraryStore,
    groupId: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-eat/?group=${groupId}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupRecipeList(rootStore.auth.token, groupId, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserSweatProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-sweatprogram/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserSweatProgramList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgSweatProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-sweatprogram/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTargetOrganizationSweatProgramList(rootStore.auth.token, id, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupSweatProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-sweatprogram/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupSweatProgramList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-sweatworkout/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserSweatWorkoutList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-sweatworkout/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTargetOrganizationSweatWorkoutList(rootStore.auth.token, id, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-sweatworkout/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupSweatWorkoutList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserThriveProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-thriveprogram/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserThriveProgramList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgThriveProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-thriveprogram/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTargetOrganizationThriveProgramList(rootStore.auth.token, id, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupThriveProgramList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-thriveprogram/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupThriveProgramList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-thriveexercise/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserThriveExerciseList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercise', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-thriveexercise/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTargetOrganizationThriveExerciseList(rootStore.auth.token, id, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercise', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-thriveexercise/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupThriveExerciseList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive exercise', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserThriveSleepList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-thrivesleep/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserThriveSleepList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgThriveSleepList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-thrivesleep/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetOrganizationThriveSleepList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupThriveSleepList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-thrivesleep/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupThriveSleepList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
    contentUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url =
      contentUrl ||
      (fteId
        ? `${config.urls.fteLibrary}fte-user-thrivemeditate/?user=${id}&fte=${fteId}`
        : undefined);
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserThriveMeditateList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrgThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-organization-thrivemeditate/?organization=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchTargetOrganizationThriveMeditateList(rootStore.auth.token, id, url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    id: number,
    fteId?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const url = fteId
      ? `${config.urls.fteLibrary}fte-group-thrivemeditate/?group=${id}&fte=${fteId}`
      : undefined;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupThriveMeditateList(rootStore.auth.token, id, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetUserBreathe = _async(function* (this: FTELibraryStore, fte: number, user: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetUserBreathe(rootStore.auth.token, fte, user)));
    } catch (error) {
      console.warn('[DEBUG] error fetching breathe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetOrganizationBreathe = _async(function* (
    this: FTELibraryStore,
    fte: number,
    org: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetOrganizationBreathe(rootStore.auth.token, fte, org)));
    } catch (error) {
      console.warn('[DEBUG] error fetching breathe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchTargetGroupBreathe = _async(function* (this: FTELibraryStore, fte: number, org: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTargetGroupBreathe(rootStore.auth.token, fte, org)));
    } catch (error) {
      console.warn('[DEBUG] error fetching breathe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchGroupBreatheContents = _async(function* (this: FTELibraryStore, fte: number, user: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchGroupBreatheContents(rootStore.auth.token, fte, user)));
    } catch (error) {
      console.warn('[DEBUG] error fetching breathe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserRecipeList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserRecipeListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserRecipeList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user recipe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgRecipeList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationRecipeListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgRecipeList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization recipe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupRecipeList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupRecipeListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupRecipeList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization recipe list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserSweatProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserSweatProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserSweatProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user sweat program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgSweatProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationSweatProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgSweatProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization sweat program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupSweatProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupSweatProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupSweatProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization sweat program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserSweatWorkoutListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserSweatWorkoutList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user sweat workout list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationSweatWorkoutListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgSweatWorkoutList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization sweat workout list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupSweatWorkoutList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupSweatWorkoutListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupSweatWorkoutList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization sweat workout list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserThriveProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserThriveProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserThriveProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user thrive program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgThriveProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationThriveProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgThriveProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupThriveProgramList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupThriveProgramListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupThriveProgramList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive program list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserThriveExerciseListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserThriveExerciseList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user thrive exercise list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationThriveExerciseListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgThriveExerciseList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive exercise list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupThriveExerciseList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupThriveExerciseListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupThriveExerciseList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive exercise list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserThriveSleepList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserThriveSleepListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserThriveSleepList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgThriveSleepList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationThriveSleepListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgThriveSleepList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupThriveSleepList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupThriveSleepListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupThriveSleepList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive sleep list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetUserThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEUserThriveMeditateListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetUserThriveMeditateList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating user thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetOrgThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEOrganizationThriveMeditateListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetOrgThriveMeditateList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateTargetGroupThriveMeditateList = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTEGroupThriveMeditateListModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateTargetGroupThriveMeditateList(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating organization thrive meditate list', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFteSweatProgramLibrary = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTESweatProgramLibraryModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFteSweatProgramLibrary(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating fte sweat program library', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFteSweatWorkoutLibrary = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTESweatWorkoutLibraryModel>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFteSweatWorkoutLibrary(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating fte sweat workout library', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteSweatWorkout = _async(function* (this: FTELibraryStore, data: FTESweatWorkout) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteSweatWorkout(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte sweat workout', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteSweatProgram = _async(function* (this: FTELibraryStore, data: FTESweatProgram) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteSweatProgram(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte sweat program', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFteSweatWorkout = _async(function* (
    this: FTELibraryStore,
    id: number,
    data: FTESweatWorkout,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFteSweatWorkout(rootStore.auth.token, id, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating fte sweat workout', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFteSweatProgram = _async(function* (
    this: FTELibraryStore,
    id: number,
    data: FTESweatProgram,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFteSweatProgram(rootStore.auth.token, id, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating fte sweat program', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteSweatWorkout = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteSweatWorkout(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting fte sweat workout', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteSweatProgram = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteSweatProgram(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting fte sweat program', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteUserBreathe = _async(function* (this: FTELibraryStore, data: FTEUserBreathe) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteUserBreathe(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte user breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteOrganizationBreathe = _async(function* (
    this: FTELibraryStore,
    data: FTEOrganizationBreathe,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteOrganizationBreathe(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte organization breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteGroupBreathe = _async(function* (this: FTELibraryStore, data: FTEGroupBreathe) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteGroupBreathe(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte organization breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteUserBreathe = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteUserBreathe(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting fte user breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteOrganizationBreathe = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteOrganizationBreathe(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting fte organization breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteGroupBreathe = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteGroupBreathe(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting fte organization breathe', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  // fetch Users' progress
  @modelFlow
  fetchFteUserSweatProgramProgress = _async(function* (
    this: FTELibraryStore,
    user: number,
    programs: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFteUserSweatProgramProgress(rootStore.auth.token, user, programs),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat program progress', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteUserSweatWorkoutProgress = _async(function* (
    this: FTELibraryStore,
    user: number,
    workouts?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFteUserSweatWorkoutProgress(rootStore.auth.token, user, workouts),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workout progress', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteUserSweatExerciseProgress = _async(function* (
    this: FTELibraryStore,
    workoutProgress?: number,
    exercise?: number,
    user?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFteUserSweatExerciseProgress(
          rootStore.auth.token,
          workoutProgress,
          exercise,
          user,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat exercise progress', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createWeightInput = _async(function* (
    this: FTELibraryStore,
    exerciseProgress?: number,
    weight?: string,
    exerciseSet?: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.createWeightInput(rootStore.auth.token, exerciseProgress, weight, exerciseSet),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating weight input', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteUserThriveProgramProgress = _async(function* (
    this: FTELibraryStore,
    user: number,
    programs: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFteUserThriveProgramProgress(rootStore.auth.token, user, programs),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive program progress', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFteSweatExercise = _async(function* (this: FTELibraryStore, data: FTESweatExercise) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFteSweatExercise(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating fte sweat exercise', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFteSweatExercise = _async(function* (
    this: FTELibraryStore,
    id: number,
    data: Partial<FTESweatExercise>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFteSweatExercise(rootStore.auth.token, id, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating fte sweat exercise', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  saveFteWorkoutStepTemplate = _async(function* (
    this: FTELibraryStore,
    data: Partial<SweatWorkoutStep>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.saveFteWorkoutStepTemplate(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteWorkoutStepTemplate = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteWorkoutStepTemplate(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  saveFteWeekWorkoutTemplate = _async(function* (
    this: FTELibraryStore,
    data: Partial<SweatProgram>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.saveFteWeekWorkoutTemplate(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteWeekWorkoutTemplate = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteWeekWorkoutTemplate(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  saveFteWorkoutTemplate = _async(function* (this: FTELibraryStore, data: Partial<SweatWorkout>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.saveFteWorkoutTemplate(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteWorkoutTemplate = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteWorkoutTemplate(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  saveGlobalStepExerciseSetTemplate = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTESweatWorkoutStepExerciseSet>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.saveGlobalStepExerciseSetTemplate(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteGlobalStepExerciseSetTemplate = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteGlobalStepExerciseSetTemplate(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  saveFteWorkoutStepExerciseSetTemplate = _async(function* (
    this: FTELibraryStore,
    data: Partial<FTESweatWorkoutStepExerciseSet>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.saveFteWorkoutStepExerciseSetTemplate(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFteWorkoutStepExerciseSetTemplate = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFteWorkoutStepExerciseSetTemplate(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error saving template', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteWeekWorkoutTemplates = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    const initial_url = url || `${config.urls.fteLibrary}week-workout-templates/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchFteWeekWorkoutTemplates(rootStore.auth.token, initial_url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching templates', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteWorkoutTemplates = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    const initial_url = url || `${config.urls.fteLibrary}workout-templates/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchFteWorkoutTemplates(rootStore.auth.token, initial_url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching templates', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteWorkoutStepTemplates = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    const initial_url = url || `${config.urls.fteLibrary}workout-step-templates/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchFteWorkoutStepTemplates(rootStore.auth.token, initial_url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching templates', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchGlobalStepExerciseSetTemplates = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    const initial_url = url || `${config.urls.fteLibrary}global-exercise-set-templates/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchGlobalStepExerciseSetTemplates(rootStore.auth.token, initial_url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching templates', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchFteWorkoutStepExerciseSetTemplates = _async(function* (this: FTELibraryStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    const initial_url = url || `${config.urls.fteLibrary}workout-exercise-set-templates/?limit=10`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFteWorkoutStepExerciseSetTemplates(rootStore.auth.token, initial_url),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching templates', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchSweatProgramById = _async(function* (this: FTELibraryStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatProgramById(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });
}
