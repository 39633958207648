import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  buttonStyle?: any;
  iconStyle?: any;
  onPress?: () => void;
};

const ExportPDFButton: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const { buttonStyle, iconStyle, onPress } = props;

  return (
    <TouchableOpacity style={[styles.container, buttonStyle]} onPress={onPress}>
      <Icon
        style={[styles.icon, iconStyle]}
        name="file-outline"
        fill="#FFFFFF"
      />
      <Text style={styles.text}>Download as PDF</Text>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  icon: {
    width: 20,
    height: 20,
  },
  text: {
    fontSize: 14,
    marginLeft: 4,
  },
});

export default ExportPDFButton;
