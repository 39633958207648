import { useFocusEffect } from '@react-navigation/native';
import { Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import EventModel from 'o2x-store/src/models/Event';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { Image, ImageStyle, Platform, StyleProp, TouchableOpacity } from 'react-native';
import { useNativeStore } from 'src/stores';

export type Props = {
  event: EventModel;
  onPress: () => void;
  onSave: () => void;
  showBookmark?: boolean;
};

const EventItem: React.FC<Props> = (props) => {
  const { event, onPress, onSave, showBookmark = true } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const { notification } = useNativeStore();
  const { user } = store.auth;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (Platform.OS !== 'web') {
          if (store.auth.user?.settings.eventReminders && event) {
            await notification.setEventNotification(`${event.id}`, event.name, event.timeStart);
          }
        }
      })();
    }, [event, store.auth.user?.settings.eventReminders]),
  );

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Layout style={styles.contentContainer} level="2">
        {!!event.coverImageThumbnail && (
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: event.coverImageThumbnail }}
          />
        )}
        <Layout style={styles.content} level="2">
          <Text>{event.name}</Text>
          <Text style={styles.subtitle}>
            {event.timeDisplayStr(user?.settings.twentyFourHourClock)}
          </Text>
        </Layout>
        {showBookmark && (
          <TouchableOpacity style={styles.bookmarkButton} onPress={onSave}>
            <Icon
              style={styles.bookmark}
              fill="white"
              name={event.saveList.length === 0 ? 'bookmark-outline' : 'bookmark'}
            />
          </TouchableOpacity>
        )}
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
    borderStyle: 'solid',
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  content: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    flex: 1,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  subtitle: {
    fontSize: 12,
    color: 'blue-secondary',
    lineHeight: 22,
    marginTop: 7,
  },
  image: {
    height: 100,
    width: 100,
  },
  bookmark: {
    width: 22,
    height: 22,
  },
  bookmarkFilled: {
    background: 'white',
    color: 'white',
  },
  bookmarkButton: {
    padding: 8,
    alignSelf: 'flex-start',
  },
});

export default EventItem;
