import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { range } from 'lodash';
import moment from 'moment';
import { FormAssessmentSummary } from 'o2x-store/src/models/FormAssesment';
import { PhysicalAssessmentSummary } from 'o2x-store/src/models/PhysicalAssessment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  maxScore: number;
  summary: FormAssessmentSummary[] | PhysicalAssessmentSummary[];
};

// !!!: Month in moment is zero indexed while month in Django is not.
const OverallGraph: React.FC<Props> = ({ maxScore, summary }) => {
  const styles = useStyleSheet(themedStyles);
  // Add 2 since it's zero indexed and we want to include this month.
  const end = moment().month() + 2;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const barHeight = isMobile ? 200 : 240;

  const isFormAssessment = (
    item: FormAssessmentSummary | PhysicalAssessmentSummary,
  ): item is FormAssessmentSummary => (item as FormAssessmentSummary) !== undefined;

  const color = (percentage: number): string => {
    if (percentage <= 0.49) {
      return '#C64141';
    }
    if (percentage <= 0.74) {
      return '#CFAC42';
    }

    if (percentage <= 0.89) {
      return '#4E7B89';
    }

    return '#767F6A';
  };

  return (
    <Layout style={isMobile ? styles.containerMobile : styles.container}>
      {range(end - 5, end).map((month) => {
        const item = summary.find((i) => i.month === month);
        let percentage = 0;

        if (item && isFormAssessment(item)) {
          const { averageScore } = item;
          percentage = averageScore / maxScore;

          if (!isFinite(percentage)) {
            percentage = 0;
          }
        }

        return (
          <Layout style={styles.item} key={month}>
            <Layout style={isMobile ? styles.barContainerMobile : styles.barContainer}>
              <Layout
                style={[
                  styles.bar,
                  {
                    backgroundColor: color(percentage),
                    height: barHeight * percentage,
                  },
                ]}
              />
            </Layout>
            <Layout style={styles.divider} />
            <Text style={[styles.label, isMobile && { marginTop: 14 }]} category="c2">
              {/* Subtract 1 since moment is zero indexed. */}
              {moment()
                .month(month - 1)
                .format('MMM')}
            </Text>
          </Layout>
        );
      })}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    width: '100%',
  },
  barContainer: {
    height: 240,
    width: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  barContainerMobile: {
    height: 200,
    width: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  container: {
    height: 310,
    marginTop: 40,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  containerMobile: {
    marginTop: 20,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  divider: {
    height: 2.7,
    alignSelf: 'stretch',
    backgroundColor: '#C4C4C4',
  },
  item: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  label: {
    marginTop: 18,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#C4C4C4',
  },
  spacer: {
    backgroundColor: 'transparent',
  },
});

export default OverallGraph;
