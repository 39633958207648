import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import Url from 'url-parse';

@model('o2x-store/SweatExercise')
export default class SweatExercise extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  description: prop<string>(''),
  video: prop<string | null>(null),
  videoLink: prop<string>(''),
  equipments: prop<string[]>(() => []),
  displayName: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<SweatExercise>) {
    Object.assign(this, data);
  }

  @computed
  get videoId(): string | undefined {
    // Assumes that the videoLink is youtube.
    const url = new Url(this.videoLink, true);
    return url.query.v;
  }
}
