import '@expo/match-media';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { ScrollView } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Logo from '../../assets/images/logo.svg';
import Wallpaper from '../../assets/images/wallpaper-default.svg';

type Props = {};

const Content: React.FC<Props> = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <Layout style={styles.contentTabletOrMobile}>
      <Logo style={styles.logoTabletOrMobile} />
      <Layout style={styles.authContainerTabletOrMobile}>{children}</Layout>
    </Layout>
  );
};
const TabletOrMobile: React.FC<Props> = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  const isLandscape = useMediaQuery({ orientation: 'landscape' });

  return (
    <Layout style={styles.container}>
      <Wallpaper style={styles.wallpaper} />
      {isLandscape ? (
        <ScrollView style={styles.scroll}>
          <Content>{children}</Content>
        </ScrollView>
      ) : (
        <Content>{children}</Content>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 10,
  },
  wallpaper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  coverPhoto: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '45%',
    width: '100%',
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5%',
  },
  authContainer: {
    width: 518,
    height: 645,
    backgroundColor: '#091C2D',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '14%',
  },
  linearGradient: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '46%',
    width: '100%',
  },
  contentTabletOrMobile: {
    flex: 1,
    backgroundColor: 'transparent',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoTabletOrMobile: {
    alignSelf: 'center',
  },
  authContainerTabletOrMobile: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  scroll: {
    flex: 1,
  },
});

export default TabletOrMobile;
