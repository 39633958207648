import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { WorkoutDetail } from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatWorkoutDetail'>;
};

const SweatWorkoutDetail: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const { sweat } = useStore();
  const workout = sweat.sweatWorkouts.get(`${id}`);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);
  const [workoutDetail, setWorkoutDetail] = useState<WorkoutDetail | null>(
    null,
  );

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!workout) {
          setLoading(true);
          await sweat.fetchSweatWorkoutByIds([`${id}`]);
          setLoading(false);
        }
      })();
    }, []),
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (workout) {
        const detail = await sweat.fetchSweatWorkoutDetail(workout.id);
        setWorkoutDetail(detail);
        setLoading(false);
      }
    })();
  }, [workout, setWorkoutDetail, setLoading]);

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.workoutDetail}>
        <View style={styles.listItem}>
          <View style={[styles.marker]} />
          <Text style={styles.listItemText}>{item}</Text>
        </View>
      </View>
    ),
    [],
  );
  const keyExtractor = useCallback((item) => `${item}`, []);

  if (!workout) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav style={styles.nav} showClose={false} />
            </View>
            {loading ? (
              <View style={styles.loading}>
                <Spinner />
              </View>
            ) : (
              <View style={styles.scroll}>
                <Layout style={styles.content}>
                  <View style={styles.header}>
                    <Text category="h2">{workout.displayName}</Text>
                  </View>
                  {!!workoutDetail && (
                    <FlatList
                      style={styles.detailContent}
                      renderItem={renderItem}
                      data={workoutDetail.detail.data}
                      keyExtractor={keyExtractor}
                    />
                  )}
                </Layout>
              </View>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    paddingVertical: 0,
    backgroundColor: 'black',
  },
  navigationContainer: {
    padding: 16,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingHorizontal: 26,
  },
  scroll: {
    flex: 1,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  detailContent: {
    marginVertical: 10,
  },
  workoutDetail: {
    marginHorizontal: 22,
    paddingHorizontal: 26,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 7,
  },
  marker: {
    width: 5,
    height: 5,
    alignSelf: 'flex-start',
    marginTop: 6,
    backgroundColor: 'cyan',
  },
  nav: {
    backgroundColor: 'black',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 20,
  },
});

export default observer(SweatWorkoutDetail);
