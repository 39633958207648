import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useRef } from 'react';
import { Animated, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DOWNLOAD_TYPE } from 'src/models/DownloadedList';
import DownloadListItem from '../../components/Downloaded/DownloadListItem';
import NavigationBar from '../../components/NavigationBar';
import config from '../../config';
import { useNativeStore } from '../../stores';

type Props = {
  route: any;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const DownloadList: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { downloads } = useNativeStore();

  const { type, downloadType, title } = props.route.params;
  const { recipes, sweatPrograms, sweatWorkouts, thrivePrograms } = downloads;

  const opacity = useRef(new Animated.Value(0)).current;

  const color = getTaskColor(type);
  const list =
    downloadType === DOWNLOAD_TYPE.RECIPE
      ? recipes
      : downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM
      ? sweatPrograms
      : downloadType === DOWNLOAD_TYPE.SWEAT_WORKOUT
      ? sweatWorkouts
      : downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM
      ? thrivePrograms
      : null;

  const editList = useCallback(() => {
    navigation.navigate('DownloadEdit', {
      title,
      type,
      downloadType,
    });
  }, []);

  const onScroll = useCallback((e) => {
    opacity.setValue(e.nativeEvent.contentOffset.y);
  }, []);

  const onPress = useCallback((id) => {
    if (downloadType === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      navigation.navigate('DownloadedSweatProgram', { id });
    } else {
      navigation.navigate('DownloadedProgram', { id, downloadType, type });
    }
  }, []);

  const titleOpacity = opacity.interpolate({
    inputRange: [0, config.titleDisplayOffsetOnScroll],
    outputRange: [0, 1],
  });

  if (!list) return null;

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      <AnimatedScrollView
        style={[styles.scrollContainer, { marginTop: insets.top }]}
        onScroll={onScroll}>
        <Layout style={styles.scrollWrapper}>
          <Text category="h2" style={styles.header}>
            {`Downloaded ${title}s`}
          </Text>
          <View style={styles.subHeader}>
            <Text style={[styles.subTitle, { color }]}>
              {`${list.size} ${title}${list.size === 1 ? '' : 's'}`}
            </Text>
            <TouchableOpacity onPress={editList}>
              <Text style={styles.link}>Edit</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.listContainer}>
            {Array.from(list.values()).map((item) => (
              <DownloadListItem
                key={item.id}
                item={item}
                type={type}
                onPress={() => onPress(item.id)}
              />
            ))}
          </View>
        </Layout>
      </AnimatedScrollView>
      <NavigationBar
        style={[styles.navigation, { top: insets.top }]}
        titleOpacity={titleOpacity}
        title={title}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
  },
  navigationContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  header: {
    marginBottom: 12,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    paddingHorizontal: 24,
  },
  scrollWrapper: {
    flex: 1,
    paddingTop: 60,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  subHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
    paddingHorizontal: 24,
  },
  subTitle: {
    fontSize: 14,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  listContainer: {
    flex: 1,
    paddingBottom: 24,
  },
});

export default observer(DownloadList);
