import { Modal, StyleService, useStyleSheet } from '@ui-kitten/components';
import { Video } from 'expo-av';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import MediaFile from 'src/models/MediaFile';
import Close from '../../assets/images/close-modal.svg';
import Maximize from '../../assets/images/maximize.svg';
import Minimize from '../../assets/images/minimize.svg';
import Play from '../../assets/images/play-video.svg';
import ConditionalWrapper from '../../containers/ConditionalWrapper';

type Props = {
  style?: StyleProp<ViewStyle>;
  videoLink?: string | null;
  size: number;
  pauseInitial?: boolean;
  minimizeMaximize?: boolean;
  limitMaxHeight?: boolean;

  videoFile?: MediaFile;
  muted?: boolean;
  allowSlowDown?: boolean;
  allowPause?: boolean;
};

const VideoPlayer: React.FC<Props> = ({
  style,
  videoLink,
  size,
  pauseInitial = true,
  minimizeMaximize = true,
  limitMaxHeight = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [isModalVisible, setModalVisible] = useState(false);
  const videoRef = useRef<Video>(null);
  const maximizeVideoRef = useRef<Video>(null);

  const [isPaused, setIsPaused] = useState(true);
  const [width, setWidth] = useState(size);
  const height = width;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const maxHeight = Dimensions.get('window').height;

  useEffect(
    useCallback(() => {
      if (!pauseInitial) {
        isModalVisible ? maximizeVideoRef.current?.playAsync() : videoRef.current?.playAsync();
        setIsPaused(false);
      }
    }, [pauseInitial, videoLink, isModalVisible, setIsPaused]),
  );

  const onPlayPause = async () => {
    if (!isPaused) {
      isModalVisible ? maximizeVideoRef.current?.pauseAsync() : videoRef.current?.pauseAsync();
    } else {
      isModalVisible ? maximizeVideoRef.current?.playAsync() : videoRef.current?.playAsync();
    }
    setIsPaused(!isPaused);
  };

  const onMaximize = () => {
    if (limitMaxHeight) {
      setWidth(maxHeight);
    } else {
      setWidth(width * 2);
    }
    setModalVisible(true);
    setIsPaused(true);
  };

  const onMinimize = () => {
    setWidth(size);
    setModalVisible(false);
    setIsPaused(true);
  };

  if (!videoLink) {
    return null;
  }

  return (
    <ConditionalWrapper
      condition={isModalVisible}
      wrapper={(children: any) => (
        <Modal
          visible={isModalVisible}
          backdropStyle={styles.backdrop}
          onBackdropPress={onMinimize}>
          {children}
        </Modal>
      )}>
      <TouchableWithoutFeedback onPress={onPlayPause}>
        <View style={[styles.container, { width }, style]}>
          <Video
            style={{ width, height }}
            ref={isModalVisible ? maximizeVideoRef : videoRef}
            source={{ uri: videoLink }}
            resizeMode="contain"
            isLooping
          />
          {isPaused && (
            <View style={[styles.pausePlayContainer, { width, height }]}>
              <Play />
            </View>
          )}
          {minimizeMaximize &&
            (!isModalVisible ? (
              <TouchableOpacity style={styles.minimizeMaximize} onPress={onMaximize}>
                {!isMobile && <Maximize />}
              </TouchableOpacity>
            ) : (
              <>
                <TouchableOpacity style={styles.closeModal} onPress={onMinimize}>
                  <Close />
                </TouchableOpacity>
                <TouchableOpacity style={styles.minimizeMaximize} onPress={onMinimize}>
                  <Minimize />
                </TouchableOpacity>
              </>
            ))}
        </View>
      </TouchableWithoutFeedback>
    </ConditionalWrapper>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  container: {
    backgroundColor: 'transparent',
  },
  pausePlayContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  minimizeMaximize: {
    position: 'absolute',
    right: 15,
    bottom: 15,
  },
  closeModal: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 20,
  },
});

export default observer(VideoPlayer);
