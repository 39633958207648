import { useNavigation } from '@react-navigation/core';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { CTA_CHOICES } from 'o2x-store/src/models/ThriveProgramExercise';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import { useNativeStore } from 'src/stores';
import ChevronDown from '../../assets/images/chevron-down.svg';
import VideoPlayer from '../VideoPlayer';

type Props = {
  item: any;
  index: number;
  color: string;
};

const width = Dimensions.get('window').width - 60;

const DLThriveItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { downloads } = useNativeStore();

  const { color, item, index } = props;

  const [showData, setShowData] = useState(false);

  const videoFile = useMemo(() => downloads.getOrCreateThriveMediaFile(item), []);

  useEffect(() => {
    if (videoFile) {
      videoFile.downloadMedia();
    }
  }, [videoFile]);

  const onToggle = useCallback(() => {
    setShowData(!showData);
  }, [showData]);

  const onPressCTA = useCallback(() => {
    if (item.cta === CTA_CHOICES.SLEEP) {
      navigation.navigate('DownloadedSleep', {
        initialDuration: item.timerLengthInSeconds,
      });
    } else if (item.cta === CTA_CHOICES.MEDITATE) {
      navigation.navigate('DownloadedMeditate', {
        initialDuration: item.timerLengthInSeconds,
      });
    } else if (item.cta === CTA_CHOICES.BREATHE) {
      navigation.navigate('DownloadedBreathe', {
        initialDuration: item.timerLengthInSeconds,
      });
    }
  }, [item.cta]);

  if (!item.instructions && !item.cta) return null;

  return (
    <View>
      <TouchableOpacity style={styles.button} onPress={onToggle}>
        <Layout style={[styles.container, { borderColor: color }]} level="2">
          <Text numberOfLines={1} style={styles.title}>
            {`Exercise ${index + 1}`}
          </Text>
          <View style={styles.toggle}>
            <ChevronDown style={showData ? styles.up : {}} />
          </View>
        </Layout>
      </TouchableOpacity>
      {showData && (
        <View style={styles.items}>
          {!!item.instructions && <Text style={styles.label}>{item.instructions.trim()}</Text>}
          {!!item.cta && (
            <View style={styles.ctaContainer}>
              <TouchableOpacity style={styles.ctaButton} onPress={onPressCTA}>
                <Text style={styles.cta} category="label">
                  {item.cta}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {!!item.description && (
            <Text style={styles.description} category="c1">
              {item.description}
            </Text>
          )}
          {videoFile && <VideoPlayer videoFile={videoFile} size={width} muted />}
        </View>
      )}
    </View>
  );
};

export const themedStyles = StyleService.create({
  container: {
    height: 40,
    marginTop: 14,
    borderLeftWidth: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  title: {
    flex: 1,
  },
  button: {
    marginHorizontal: 30,
  },
  up: {
    transform: [{ rotateZ: '180deg' }],
  },
  toggle: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  items: {
    paddingVertical: 6,
    paddingHorizontal: 12,
    backgroundColor: 'darker-blue',
    marginHorizontal: 30,
  },
  label: {
    fontSize: 16,
    marginLeft: 7,
    marginVertical: 6,
  },
  description: {
    fontSize: 16,
    marginVertical: 6,
  },
  ctaContainer: {
    backgroundColor: 'darker-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
  },
  ctaButton: {
    height: 36,
    width: 120,
    backgroundColor: 'olive',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cta: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export default DLThriveItem;
