import { useNavigation } from '@react-navigation/core';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import ThriveMediaFile from 'o2x-store/src/models/ThriveMediaFile';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';
import ThriveIcon from '../../assets/images/thrive-icon.svg';

type Props = {
  item: ThriveMediaFile | any;
};

const FTEThriveSleepItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { item } = props;
  const { name } = item;

  const color = getTaskColor(TASK_TYPE.THRIVE);

  const onOpen = useCallback(() => {
    navigation.navigate('ThriveSleep', { item });
  }, []);

  return (
    <TouchableOpacity
      onPress={onOpen}
      style={[styles.container, { borderColor: color }]}
    >
      <View style={styles.taskIconContainer}>
        <ThriveIcon height={40} width={40} />
      </View>
      <View style={styles.content}>
        <Text numberOfLines={2}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 80,
    flexDirection: 'row',
    borderLeftWidth: 4,
    marginHorizontal: 24,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 12,
  },
  subtitle: {
    marginTop: 4,
  },
  thumbnail: {
    height: 80,
    width: 80,
  },
  taskIconContainer: {
    height: 80,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 4,
    bottom: 4,
    justifyContent: 'center',
  },
  bookmark: {
    width: 22,
    height: 22,
  },
  bookmarkButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FTEThriveSleepItem;
