import '@expo/match-media';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LabeledInput from '../../components/LabeledInput';
import Social from '../../services/social';
import { getErrors } from '../../utils/errors';
import Account from '../Account';

type Props = {};

const SignIn: React.FC<Props> = (props) => {
  const { auth } = useStore();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { register, setValue, handleSubmit, errors } = useForm();
  const passwordRef = useRef<TextInput>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSignUp = useCallback(() => {
    navigation.navigate('SignUpCode');
  }, []);

  const onSubmitEditingEmail = useCallback(() => {
    passwordRef.current?.focus();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }

      setLoading(true);
      const result = await auth.signIn(data);
      setLoading(false);
      if (!result.ok) {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [loading],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onFacebook = useCallback(async () => {
    setLoading(true);

    let result;
    result = await Social.signInWithFacebook();

    if (!result.ok) {
      setLoading(false);
      return;
    }

    await auth.signInWithFacebook(result.token);
  }, [auth]);

  const onGoogle = useCallback(async () => {
    setLoading(true);

    let result;
    result = await Social.signInWithGoogle();

    if (!result.ok) {
      setLoading(false);
      return;
    }

    await auth.signInWithGoogle(result.token);
  }, [auth]);

  const onResetPassword = useCallback(() => {
    navigation.navigate('ResetPassword');
  }, []);

  useEffect(() => {
    register('email', { required: true });
    register('password', { required: true });
  }, [register]);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Account>
      {isTabletOrMobile ? (
        <Text style={styles.portalTextTablet} category="label">
          Tactical Performance
        </Text>
      ) : (
        <Text style={styles.portalText} category="h1">
          Member Portal
        </Text>
      )}
      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      <Layout
        style={isMobile ? styles.signInContainerMobile : styles.signInContainer}
      >
        <LabeledInput
          style={styles.inputContainer}
          autoCapitalize="none"
          keyboardType="email-address"
          returnKeyType="next"
          autoFocus
          onChangeText={(text) => setValue('email', text)}
          onSubmitEditing={onSubmitEditingEmail}
          label="Email"
          divider
        />
        <LabeledInput
          ref={passwordRef}
          style={styles.inputContainer}
          returnKeyType="go"
          secureTextEntry
          onChangeText={(text) => setValue('password', text)}
          onSubmitEditing={onSubmitWrapped}
          label="Password"
          divider
        />
        <TouchableOpacity
          onPress={onSubmitWrapped}
          disabled={loading}
          style={[
            styles.button,
            styles.signInButton,
            // !formState.isValid && styles.disabled,
          ]}
        >
          {loading ? (
            <View style={styles.spinner}>
              <Spinner status="basic" />
            </View>
          ) : (
            <Text style={styles.buttonLabel} category="s2">
              Sign in
            </Text>
          )}
        </TouchableOpacity>
      </Layout>

      {/* <Text style={[styles.label, styles.or]} category="c2">
        or
      </Text>
      <Layout style={styles.row}>
        <TouchableOpacity
          onPress={onFacebook}
          style={[styles.button, styles.socialAuthButton]}
        >
          <SocialSignInBackground style={styles.buttonBg} />
          <Text style={styles.socialAuthButtonLabel} category="s2">
            Sign in with
          </Text>
          <FacebookLogo />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onGoogle}
          style={[styles.button, styles.socialAuthButton]}
        >
          <SocialSignInBackground style={styles.buttonBg} />
          <Text style={styles.socialAuthButtonLabel} category="s2">
            Sign in with
          </Text>
          <GoogleLogo />
        </TouchableOpacity>
      </Layout> */}

      <Layout style={[styles.row]}>
        <TouchableOpacity onPress={onResetPassword}>
          <Text
            category="label"
            style={[styles.normal, styles.link, styles.forgotPassword]}
          >
            I forgot my password
          </Text>
        </TouchableOpacity>
      </Layout>
      <Layout style={[styles.row, { marginTop: 15 }]}>
        <Text category="label" style={styles.normal}>
          New User?
        </Text>
        <TouchableOpacity onPress={onSignUp}>
          <Text category="label" style={[styles.normal, styles.link]}>
            Sign Up
          </Text>
        </TouchableOpacity>
      </Layout>
    </Account>
  );
};

const themedStyles = StyleService.create({
  signInContainer: {
    backgroundColor: 'transparent',
    width: '50%',
  },
  signInContainerMobile: {
    backgroundColor: 'transparent',
    width: '70%',
  },
  button: {
    justifyContent: 'center',
    height: 40,
    width: '100%',
    marginBottom: 20,
  },
  buttonBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  signInButton: {
    justifyContent: 'center',
    height: 40,
    backgroundColor: 'olive',
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    textAlign: 'center',
    color: 'danger',
    marginBottom: 15,
  },
  label: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  link: {
    marginLeft: 5,
    textDecorationLine: 'underline',
  },
  inputContainer: {
    width: '100%',
  },
  normal: {
    fontWeight: 'normal',
  },
  or: {
    marginBottom: '4%',
  },
  row: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  socialAuthButton: {
    flex: 1,
    width: '50%',
    maxWidth: 156,
    marginHorizontal: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialAuthButtonLabel: {
    marginRight: 8,
    textTransform: 'uppercase',
    letterSpacing: 1,
    textAlign: 'center',
  },
  spinner: {
    alignSelf: 'center',
  },
  forgotPassword: {
    marginTop: 20,
  },
  portalText: {
    textAlign: 'center',
    letterSpacing: 1,
    marginBottom: '10%',
  },
  portalTextTablet: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 20,
  },
});

export default SignIn;
