import config from '../../config';
import { callApiWithToken } from './base';

export const fetchRecipes = async (token: string, url?: string) =>
  callApiWithToken(url || config.urls.recipes, token, 'GET');

export const fetchRecipesByIds = async (token: string, ids: string) =>
  callApiWithToken(`${config.urls.recipes}?id__in=${ids}`, token, 'GET');

export const fetchRecipesRatings = async (token: string, program: number) =>
  callApiWithToken(`${config.urls.recipes}${program}/ratings/`, token);

export const postRecipesRate = async (token: string, program: number, rating: number) =>
  callApiWithToken(
    `${config.urls.recipes}${program}/rate/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ rating }),
  );

export const searchRecipes = async (token: string, qs: string, url?: string) =>
  callApiWithToken(url || `${config.urls.recipes}?${qs}`, token, 'GET', {}, undefined, true);
