import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get, toLower } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import RightOutline from '../../assets/images/right-outline.svg';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ReadinessAssessmentResult'>;
};

const BodyCompAssessmentResult: React.FC<Props> = (props) => {
  const { submissionId, assessmentId } = props.route.params;
  const { auth, assessment: assessmentStore } = useStore();

  const [assessment, setAssessment] = useState<FormAssessment>();
  const [submission, setSubmission] = useState<FormAssessmentSubmission>();
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});

  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation();

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);

        let tempAssessment = assessmentStore.formAssessments.get(`${assessmentId}`);
        let tempSubmission = assessmentStore.formAssessmentSubmissions.get(`${submissionId}`);

        // check if they exist in store
        if (!tempAssessment) {
          await assessmentStore.fetchFormAssessmentByIds([`${assessmentId}`]);
        }
        if (!tempSubmission) {
          await assessmentStore.fetchFormAssessmentSubmission(submissionId);
        }
        await assessmentStore.fetchFormAssessmentSubmissionResult(submissionId);

        // get values after fetching
        tempAssessment = assessmentStore.formAssessments.get(`${assessmentId}`);
        tempSubmission = assessmentStore.formAssessmentSubmissions.get(`${submissionId}`);

        setAssessment(tempAssessment);
        setSubmission(tempSubmission);
        setSummary(tempSubmission!.result || {});
        setLoading(false);
      })();
    }, [assessmentId, submissionId]),
  );

  const goToAssessment = useCallback(() => {
    navigation.navigate('Plan');
  }, []);

  const goBack = useCallback(() => {
    navigation.navigate('AssessmentHistory', {
      assessmentId,
      type: ASSESSMENT_TYPE.FORM,
    });
  }, [assessmentId]);

  const onOpenExternalLink = useCallback((url: string) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS == 'web') {
          window.open(url, '_blank');
        } else {
          Linking.openURL(url);
        }
      } else {
        console.warn(`[DEBUG] Cannot open URI: ${url}`);
      }
    });
  }, []);

  const getColor = (label: string) => {
    if (toLower(label) === 'yellow') {
      return '#FBBC04'; // Can change shade here
    }
    if (toLower(label) === 'green') {
      return '#34A853';
    }
    if (toLower(label) === 'red') {
      return '#EA4335';
    }
    return 'transparent';
  };

  if (!submission || !currentUser) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={() => {}}>
      <Layout style={styles.body}>
        <ScrollView>
          <Layout style={isMobile ? styles.containerMobile : styles.container}>
            <Layout style={styles.header}>
              <Layout style={styles.row}>
                <TouchableOpacity onPress={goToAssessment}>
                  <Text
                    style={
                      isDeviceMaxWidth900 ? styles.assessmentTexMaxWidth900 : styles.assessmentText
                    }>
                    Assessments
                  </Text>
                </TouchableOpacity>
                {isDeviceMaxWidth900 ? (
                  <RightOutline
                    style={styles.rightOutline}
                    width="6"
                    height="10"
                    viewBox="0 0 8 14"
                  />
                ) : (
                  <RightOutline />
                )}
                <TouchableOpacity onPress={goBack}>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameHeaderMaxWidth900
                        : styles.assessmentNameHeader
                    }>
                    {assessment!.name}
                  </Text>
                </TouchableOpacity>
                {isDeviceMaxWidth900 ? (
                  <RightOutline
                    style={styles.rightOutline}
                    width="6"
                    height="10"
                    viewBox="0 0 8 14"
                  />
                ) : (
                  <RightOutline />
                )}
                <Text
                  style={
                    isDeviceMaxWidth900
                      ? styles.assessmentNameHeaderMaxWidth900
                      : styles.assessmentNameHeader
                  }>
                  {moment(submission.created).format('MMM D, YYYY')}
                </Text>
              </Layout>
            </Layout>
            <Layout style={isMobile ? styles.userContainerMobile : styles.userContainer}>
              <Layout style={styles.user}>
                <Image
                  style={styles.profile as StyleProp<ImageStyle>}
                  source={
                    currentUser?.profileImage
                      ? { uri: currentUser?.profileImage }
                      : require('../../assets/images/user_placeholder.png')
                  }
                />
                <Layout style={isMobile ? styles.userDataMobile : styles.userData}>
                  <Text
                    style={
                      isMobile
                        ? styles.fullNameMobile
                        : isDeviceMaxWidth900
                        ? styles.fullNameMaxWidth900
                        : styles.fullName
                    }>
                    {currentUser?.fullName}
                  </Text>
                  <Text
                    style={isDeviceMaxWidth900 ? styles.professionMaxWidth900 : styles.profession}>
                    {`O2X ID #${currentUser?.o2XId}`}
                  </Text>
                </Layout>
              </Layout>
              <Layout style={styles.assessmentContainer}>
                <Text
                  style={
                    isDeviceMaxWidth900 ? styles.assessmentNameMaxWidth900 : styles.assessmentName
                  }>
                  {assessment!.name}
                </Text>
                <Text
                  style={
                    isDeviceMaxWidth900 ? styles.assessmentDateMaxWidth900 : styles.assessmentDate
                  }>
                  {submission.created === null
                    ? ''
                    : `date taken: ${moment(submission.created).format('MMM DD, YYYY')}`}
                </Text>
              </Layout>
            </Layout>
            {!!summary && (
              <Layout style={styles.tableContainerWrapper}>
                <Layout
                  style={[
                    styles.tableContainer,
                    !!isDeviceMaxWidth768 && {
                      flexDirection: 'column-reverse',
                    },
                  ]}>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Name</Text>
                      <Text style={[styles.tableValue, { flex: 3, paddingLeft: -8 }]}>
                        {currentUser!.fullName}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>O2X ID#</Text>
                      <Text style={[styles.tableValue, { flex: 3, paddingLeft: -8 }]}>
                        {currentUser!.o2XId}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Test Date</Text>
                      <Text style={styles.tableValue}>
                        {submission.created === null
                          ? ''
                          : `${moment(submission.created).format('MMM DD, YYYY')}`}
                      </Text>
                      <Text style={styles.tableLabel}>Age</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'age', '')}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Gender</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'gender', '')}
                      </Text>
                      <Text style={styles.tableLabel}>Height (in)</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'height', '')}
                      </Text>
                    </Layout>
                  </Layout>
                  <Layout style={[styles.tableColumn, styles.bordered, { flex: 1 }]}>
                    <Layout style={styles.tableColumn}>
                      <Text style={styles.overallLabel}>Body Composition Score</Text>
                      <Text style={[styles.question, styles.textCentered]}>
                        (out of {assessment?.maxScore} pts)
                      </Text>
                    </Layout>
                    <Layout style={styles.tableColumn}>
                      {loading ? (
                        <View style={styles.loader}>
                          <Spinner />
                        </View>
                      ) : (
                        <Text
                          style={[
                            styles.overallValue,
                            {
                              backgroundColor: getColor(get(summary.overallScore, 'label')),
                            },
                          ]}>
                          {get(summary.overallScore, 'value', '?')}
                        </Text>
                      )}
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerHeader}>
                  <Text style={styles.tableContainerHeaderText}>Body Composition Analysis</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Weight (lbs)</Text>
                      <Text style={[styles.tableValue, { flex: 2, paddingLeft: -8 }]}>
                        {get(get(summary, 'answers', {}), 'weight', '')}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Skeletal Muscle Mass (lbs)</Text>
                      <Text style={[styles.tableValue, { flex: 2, paddingLeft: -8 }]}>
                        {Number(
                          get(get(get(summary, 'answers', {}), 'skeletalMuscleMass', {}), 'answer'),
                        ).toFixed(2)}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Percent Body Fat</Text>
                      <Text style={styles.tableValue}>
                        {Number(
                          get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'answer'),
                        ).toFixed(2)}
                      </Text>
                      <Text
                        style={[
                          styles.categoryValue,
                          styles.textCentered,
                          {
                            padding: 10,
                            flex: 1,
                            backgroundColor: getColor(
                              get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'label'),
                            ),
                          },
                        ]}>
                        {get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'label')}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for body fat percentage based on the World Health
                      Organization (WHO) classifications
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      styles.bordered,
                      { flex: 1.5, justifyContent: 'space-around' },
                    ]}>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'< 24.9 %'}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'< 25.0 - 29.9 %'}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'> 30.0 %'}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginTop: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={styles.tableLabelSmaller}>{'<'}</Text>
                    <Text style={styles.tableLabelSmaller}>Less than</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={styles.tableLabelSmaller}>{'>'}</Text>
                    <Text style={styles.tableLabelSmaller}>Greater than</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={styles.tableLabelSmaller}>=</Text>
                    <Text style={styles.tableLabelSmaller}>Equal to</Text>
                  </Layout>
                </Layout>

                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('green'),
                          padding: 8,
                          marginLeft: 100,
                        },
                      ]}>
                      Green
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('yellow'),
                          padding: 8,
                          marginLeft: 100,
                        },
                      ]}>
                      Yellow
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('red'),
                          padding: 8,
                          marginLeft: 100,
                        },
                      ]}>
                      Red
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Keep up the hard work. Green is great - but doesn't mean perfect.{' '}
                    </Text>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Still room for improvement. Work on these sections to get in the Green.{' '}
                    </Text>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Focus is needed in this area to help mitigate the risk of illness or injury.{' '}
                    </Text>
                  </Layout>
                </Layout>
                <Text style={styles.disclaimer}>
                  Disclaimer: It is impossible to properly diagnose and recommend a course of action
                  from a distance. The information provided by our O2X specialists is intended to be
                  educational and general in nature, and should not replace the personal advice and
                  recommendations of your own health care professionals. To make sure you're doing
                  the right thing, you should consult with your health care providers before taking
                  any course of action.
                </Text>
              </Layout>
            )}
          </Layout>
        </ScrollView>
      </Layout>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  tableContainerWrapper: {
    paddingVertical: 15,
    justifyContent: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#697484',
    paddingHorizontal: 10,
    paddingVertical: 17,
    // marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  tableColumn: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableLabelHeader: {
    fontSize: 16,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableLabel: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableValue: {
    fontSize: 16,
    lineHeight: 19,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  overallLabel: {
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: 8,
  },
  overallValue: {
    fontSize: 40,
    textAlign: 'center',
    color: 'white',
    margin: 8,
  },
  bordered: {
    borderWidth: 1,
    borderColor: '#697484',
  },
  tableContainerHeader: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    backgroundColor: '#31576D',
  },
  tableContainerHeaderText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 22,
  },
  tableContainerSubHeader: {
    paddingHorizontal: 25,
    paddingVertical: 2,
    backgroundColor: '#767f6a',
  },
  tableContainerSubHeaderText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 22,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  categoryLabel: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tableLabelSmaller: {
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'center',
    padding: 10,
  },
  tableLabelSmallerMobile: {
    fontSize: 10,
    lineHeight: 12,
    textAlign: 'center',
    padding: 6,
  },
  categoryValue: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  question: {
    fontSize: 14,
    lineHeight: 16,
    // textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  answer: {
    fontSize: 16,
    lineHeight: 19,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  textCentered: {
    textAlign: 'center',
  },
  link: {
    borderBottomWidth: 1,
    // prev color: #4e7b89
    borderColor: '#79d6d9',
    color: '#79d6d9',
  },
  disclaimer: {
    fontStyle: 'italic',
    fontSize: 12,
    fontWeight: '400',
    marginTop: 20,
  },
});

export default observer(BodyCompAssessmentResult);
