import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isNaN } from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  FTEOrganizationBreathe,
  FTEOrganizationModel,
  FTEUserBreathe,
  OSSGroup,
} from 'o2x-store/src/models/FTELibrary';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Animated, ScrollView, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { getErrors } from '../../../utils/errors';

type Props = {
  org?: FTEOrganizationModel;
  user?: User;
  group?: OSSGroup;
  onNotification: boolean;
};

const BreatheContent: React.FC<Props> = (props) => {
  const { org, user, group, onNotification } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [breatheList, setBreatheList] = useState<FTEUserBreathe[] | FTEOrganizationBreathe[]>([]);

  const [initialLoading, setInitialLoading] = useState(false);

  const [name, setName] = useState('');
  const [timeLimit, setTimeLimit] = useState('');
  const [status, setStatus] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);

  const { register, setValue, handleSubmit } = useForm();

  const [hasError, setHasError] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const notifReceiver = org
    ? org.users
        .map((user) => {
          if (user.id !== store.auth.user?.id) return user.id;
        })
        .toString()
        .replace(/,\s*$/, '')
    : user
    ? user?.id.toString()
    : group?.users.toString().replace(/,\s*$/, '');

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoading(true);
        const breathe = user
          ? await store.fteLibrary.fetchTargetUserBreathe(store.auth.user?.id!, user.id)
          : org
          ? await store.fteLibrary.fetchTargetOrganizationBreathe(store.auth.user?.id!, org.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupBreathe(store.auth.user?.id!, group.id)
          : null;
        setBreatheList(breathe?.extra?.results);
        setInitialLoading(false);
      })();
    }, [org, user, group]),
  );

  useFocusEffect(
    useCallback(() => {
      register({ name: 'name' }, { required: true });
      register({ name: 'timeLimit' }, { required: true });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('name', name);
    setValue('timeLimit', timeLimit);
  }, [name, timeLimit]);

  const onSubmit = useCallback(
    async (data) => {
      data.fte = store.auth.user?.id;
      user ? (data.user = user.id) : org ? (data.organization = org?.id) : (data.group = group?.id);
      setLoadingSave(true);
      const result = org
        ? await store.fteLibrary.createFteOrganizationBreathe(data)
        : user
        ? await store.fteLibrary.createFteUserBreathe(data)
        : group
        ? await store.fteLibrary.createFteGroupBreathe(data)
        : null;
      if (result?.ok) {
        const breathe = user
          ? await store.fteLibrary.fetchTargetUserBreathe(store.auth.user?.id!, user.id)
          : org
          ? await store.fteLibrary.fetchTargetOrganizationBreathe(store.auth.user?.id!, org.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupBreathe(store.auth.user?.id!, group.id)
          : null;
        if (onNotification) {
          await store.fteEvent.sendNotification({
            recipientUsers: notifReceiver,
            title: 'Your On-site Specialist just posted a new THRIVE breathe exercise',
            body: 'New Content',
            extra: {
              type: 'New Content',
              content: 'thrive',
              fte: {
                id: store.auth.user.id,
                email: store.auth.user?.email,
                fullName: store.auth.user?.fullName,
                firstName: store.auth.user?.firstName,
                lastName: store.auth.user?.lastName,
                o2xId: store.auth.user?.o2XId,
                organizations: store.auth.user?.organization,
                profileImage: store.auth.user?.profileImage,
              },
              solo: !!user,
            },
          });
        }
        setName('');
        setTimeLimit('');
        setBreatheList(breathe?.extra?.results);
      } else {
        console.log(getErrors(result?.errors));
      }
      setLoadingSave(false);
      setHasError(!result?.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => animation.setValue(0));
    },
    [org, user, group, onNotification],
  );
  const onSubmitWrapped = handleSubmit(onSubmit);

  const onDeleteBreathe = useCallback(
    async (id: number) => {
      setLoadingSave(true);
      user
        ? await store.fteLibrary.deleteFteUserBreathe(id)
        : org
        ? await store.fteLibrary.deleteFteOrganizationBreathe(id)
        : await store.fteLibrary.deleteFteGroupBreathe(id);
      const breathe = user
        ? await store.fteLibrary.fetchTargetUserBreathe(store.auth.user?.id!, user.id)
        : org
        ? await store.fteLibrary.fetchTargetOrganizationBreathe(store.auth.user?.id!, org.id)
        : group
        ? await store.fteLibrary.fetchTargetGroupBreathe(store.auth.user?.id!, group.id)
        : null;
      setBreatheList(breathe?.extra?.results);
      setLoadingSave(false);
    },
    [user, org, group],
  );

  const onChangeText = useCallback(
    (text: string) => {
      if (text.length > 0 && isNaN(parseInt(text.charAt(text.length - 1)))) return;
      let result = '';
      let duration = text.replace(/:/g, '');
      if (duration.length % 2 === 1 && duration.length > 1) {
        result = `${duration.slice(0, 1)}:`;
        duration = duration.slice(1);
      }
      result += duration.replace(/..\B/g, '$&:');
      setStatus('');
      if (result.length < 6) setTimeLimit(result);
      if (!result) setStatus('danger');
    },
    [timeLimit, setTimeLimit],
  );

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Layout style={isMobile ? styles.createContainerMobile : styles.createContainer}>
      <Layout style={isMobile ? styles.listContainerMobile : styles.listContainer}>
        <Text style={styles.listHeader}>CREATE BREATHE CONTENT</Text>
        <Input
          size="small"
          style={styles.input}
          value={name}
          onChangeText={(text) => setName(text)}
          placeholder="Title"
          label="Breathe Exercise Title"
        />
        <Input
          size="small"
          style={styles.input}
          value={timeLimit}
          onChangeText={onChangeText}
          placeholder="MM:SS"
          label="Duration"
        />
        {loadingSave ? (
          <Layout style={styles.loadingSave}>
            <Spinner />
          </Layout>
        ) : (
          <TouchableOpacity
            style={isMobile ? styles.saveButtonMobile : styles.saveButton}
            onPress={onSubmitWrapped}>
            <Text style={styles.saveText}>Add</Text>
          </TouchableOpacity>
        )}
      </Layout>
      <Layout style={styles.listContainer}>
        <Text style={styles.listHeader}>SELECTED BREATHE LIBRARY</Text>
        <ScrollView style={styles.saveContainer}>
          {initialLoading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            breatheList?.map((breathe, index) => (
              <Layout key={index} style={styles.dataContainer}>
                <Text style={styles.dataText}>
                  {breathe.name} {'  (Duration: '} {breathe.timeLimit} {') '}{' '}
                </Text>
                <TouchableOpacity
                  style={styles.buttonIcon}
                  onPress={() => onDeleteBreathe(breathe.id)}>
                  <Icon name="minus-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
              </Layout>
            ))
          )}
        </ScrollView>
      </Layout>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[styles.popUpContainer, { opacity, transform: [{ translateY }, { scale }] }]}
          pointerEvents="none">
          <Icon
            style={styles.savedIcon}
            name={hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'}
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>{hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}</Text>
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  createContainer: {
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  listContainer: {
    width: '45%',
    height: '93%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  createContainerMobile: {
    width: '95%',
    paddingTop: 20,
    marginRight: 0,
    marginTop: 0,
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#1A3248',
  },
  listContainerMobile: {
    width: '47%',
    height: '96%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  listHeader: {
    marginTop: 15,
    marginLeft: 15,
    paddingLeft: 10,
    borderLeft: '2px solid #04B7D6',
    height: 'auto',
    fontSize: 15,
    color: 'white',
    maxWidth: '90%',
  },
  contentContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 20,
    backgroundColor: '#091C2D',
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 12,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  loadMore: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    marginBottom: 15,
    marginTop: 10,
  },
  loadText: {
    color: 'white',
    fontSize: 12,
    margin: 'auto',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  loadingSave: {
    position: 'absolute',
    right: 20,
    bottom: 10,
    padding: 5,
    backgroundColor: 'transparent',
  },
  saveContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 45,
    backgroundColor: '#091C2D',
  },
  saveButton: {
    position: 'absolute',
    backgroundColor: 'olive',
    width: 70,
    height: 30,
    right: 10,
    bottom: 10,
    padding: 5,
  },
  saveButtonMobile: {
    backgroundColor: 'olive',
    width: 70,
    height: 25,
    marginTop: 1,
    marginLeft: '40%',
    padding: 5,
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  input: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1A3248',
    marginTop: 15,
    marginBottom: 5,
  },
});

export default observer(BreatheContent);
