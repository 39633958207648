import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';

@model('o2x-store/Organization')
export default class Organization extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  description: prop<string>(''),
  logo: prop<string>(''),
  logoThumbnail: prop<string>(''),
  logoBanner: prop<string>(''),
  logoCard: prop<string>(''),
  address: prop<string>(''),
  city: prop<string>(''),
  stateCode: prop<string>(''),
  countryCode: prop<string>(''),
  zipCode: prop<string>(''),
  contactPerson: prop<string>(''),
  contactNumber: prop<string>(''),
  activeOn: prop<string>(''),
  activeUntil: prop<string>(''),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<Organization>) {
    Object.assign(this, data);
  }
}
