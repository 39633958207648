import { camelize, decamelize } from 'humps';
import { each } from 'lodash';
import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  prop_mapObject,
  SnapshotOutOf,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import User, {
  UserDailyDebrief,
  UserDailyEatHabit,
  UserDailyThriveHabit,
  UserDebriefProfile,
  UserEatProfile,
  UserSetting,
  UserSweatProfile,
  UserThriveProfile,
} from '../models/User';
import UserSweatWorkoutExerciseProgress from '../models/UserSweatWorkoutExerciseProgress';
import UserSweatWorkoutProgress from '../models/UserSweatWorkoutProgress';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import { SCORE_TYPE } from '../utils/scores';

@model('o2x-store/UserStore')
export default class UserStore extends Model({
  users: prop_mapObject(() => new Map<string, User>()),
  eatProfile: prop<UserEatProfile | null>(null),
  dailyEatHabit: prop<UserDailyEatHabit | null>(null),
  dailyThriveHabit: prop<UserDailyThriveHabit | null>(null),
  sweatProfile: prop<UserSweatProfile | null>(null),
  sweatWorkoutProgress: prop_mapObject(
    () => new Map<string, UserSweatWorkoutProgress>(),
  ),
  thriveProfile: prop<UserThriveProfile | null>(null),
  debriefProfile: prop<UserDebriefProfile | null>(null),
  dailyDebrief: prop<UserDailyDebrief | null>(null),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateSweatWorkoutProgress(
    data: ModelInstanceCreationData<UserSweatWorkoutProgress>,
  ) {
    const id = `${data.id}`;
    if (this.sweatWorkoutProgress.has(id)) {
      this.sweatWorkoutProgress.get(id)!.update(data);
    } else {
      const sweatWorkoutProgress = new UserSweatWorkoutProgress(data);
      this.sweatWorkoutProgress.set(id, sweatWorkoutProgress);
      sweatWorkoutProgress.update(data);
    }
  }

  @modelFlow
  fetchMe = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMe(rootStore.auth.token)));
      if (entities.eatProfile) {
        this.eatProfile = new UserEatProfile({
          ...entities.eatProfile,
          monitorFields: (
            entities.eatProfile?.monitorFields || []
          ).map((f: string) => camelize(f)),
        });
        this.thriveProfile = new UserThriveProfile({
          ...entities.thriveProfile,
          monitorFields: (
            entities.thriveProfile?.monitorFields || []
          ).map((f: string) => camelize(f)),
        });
      }
    } catch (error) {
      console.warn('[DEBUG] error fetching user data', error);
      return getError(error);
    }

    if (entities) {
      if (rootStore.auth.user) {
        rootStore.auth.user.update(entities);
      } else {
        rootStore.auth.setUser(new User(entities));
      }
    }

    this.loading = false;
    return getSuccess({ user: rootStore.auth.user });
  });

  @modelFlow
  updateMe = _async(function* (this: UserStore, data: Partial<User>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateMe(rootStore.auth.token, data)));
      if (entities) {
        if (rootStore.auth.user) {
          rootStore.auth.user.update(entities);
        } else {
          rootStore.auth.setUser(new User(entities));
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error updating me', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateMePhoto = _async(function* (this: UserStore, photo: any) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    let response;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateMePhoto(rootStore.auth.token, photo)));
      if (entities) {
        if (rootStore.auth.user) {
          rootStore.auth.user.update(entities);
        } else {
          rootStore.auth.setUser(new User(entities));
        }
      }
    } catch (error) {
      console.warn('[DEBUG] error updating me photo', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchUser = _async(function* (this: UserStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let userData: ModelInstanceCreationData<User>;
    try {
      ({
        response: { entities: userData },
      } = yield* _await(api.fetchUser(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching user', error);
      return getError(error);
    }

    const key = `${userData.id}`;
    if (this.users.has(key)) {
      this.users.get(key)!.update(userData);
    } else {
      const user = new User(userData);
      this.users.set(key, user);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSettings = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSettings(rootStore.auth.token)));
      if (rootStore.auth.user) {
        rootStore.auth.user.settings = new UserSetting(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error updating user settings', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateSettings = _async(function* (this: UserStore, data: Partial<User>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    let response;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateSettings(rootStore.auth.token, data)));
      if (entities && rootStore.auth.user) {
        rootStore.auth.user.settings = new UserSetting(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error updating user settings', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchEatProfile = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchEatProfile(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching eat profile', error);
      return getError(error);
    }

    this.eatProfile = null;
    entities.results.forEach((data: any) => {
      this.eatProfile = new UserEatProfile({
        ...data,
        monitorFields: data.monitorFields.map((f: string) => camelize(f)),
      });
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateEatProfile = _async(function* (
    this: UserStore,
    data: Partial<UserEatProfile>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserEatProfile>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(
          api.updateEatProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createEatProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating eat profile', error);
      return getError(error);
    }

    if (entities) {
      const scoreMap = each(
        entities?.score || {},
        (value: SCORE_TYPE | null, key: string) => {
          return { [camelize(key)]: value };
        },
      );
      this.eatProfile = new UserEatProfile({
        ...entities,
        monitorFields: (entities?.monitorFields || []).map((f: string) =>
          camelize(f),
        ),
        score: scoreMap,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyEatHabit = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDailyEatHabit(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error updating eat habit', error);
      return getError(error);
    }

    this.dailyEatHabit = null;
    entities.results.forEach((data: any) => {
      this.dailyEatHabit = new UserDailyEatHabit(data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateDailyEatHabit = _async(function* (
    this: UserStore,
    data: Partial<UserDailyEatHabit>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserDailyEatHabit>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(api.updateDailyEatHabit(rootStore.auth.token, data)));
      } else {
        ({
          response: { entities },
        } = yield* _await(api.createDailyEatHabit(rootStore.auth.token, data)));
        analytics.logDailyEatHabitLog();
      }
    } catch (error) {
      console.warn('[DEBUG] error updating eat habit', error);
      return getError(error);
    }

    if (entities) {
      const scoreMap = each(
        entities?.score || {},
        (value: SCORE_TYPE | null, key: string) => {
          return { [camelize(key)]: value };
        },
      );
      this.dailyEatHabit = new UserDailyEatHabit({
        ...entities,
        score: scoreMap,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyThriveHabit = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDailyThriveHabit(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error updating thrive habit', error);
      return getError(error);
    }

    this.dailyThriveHabit = null;
    entities.results.forEach((data: any) => {
      this.dailyThriveHabit = new UserDailyThriveHabit(data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateDailyThriveHabit = _async(function* (
    this: UserStore,
    data: Partial<UserDailyThriveHabit>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserDailyThriveHabit>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(
          api.updateDailyThriveHabit(rootStore.auth.token, data),
        ));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createDailyThriveHabit(rootStore.auth.token, data),
        ));
        analytics.logDailyThriveHabitLog();
      }
    } catch (error) {
      console.warn('[DEBUG] error updating thrive habit', error);
      return getError(error);
    }

    if (entities) {
      const scoreMap = each(
        entities?.score || {},
        (value: SCORE_TYPE | null, key: string) => {
          return { [camelize(key)]: value };
        },
      );
      this.dailyThriveHabit = new UserDailyThriveHabit({
        ...entities,
        score: scoreMap,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatProfile = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatProfile(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat profile', error);
      return getError(error);
    }

    this.sweatProfile = null;
    entities.results.forEach((data: any) => {
      this.sweatProfile = new UserSweatProfile(data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateSweatProfile = _async(function* (
    this: UserStore,
    data: Partial<UserSweatProfile>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserSweatProfile>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(api.updateSweatProfile(rootStore.auth.token, data)));
      } else {
        ({
          response: { entities },
        } = yield* _await(api.createSweatProfile(rootStore.auth.token, data)));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating sweat profile', error);
      return getError(error);
    }

    if (entities) {
      this.sweatProfile = new UserSweatProfile(entities);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThriveProfile = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchThriveProfile(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching thrive profile', error);
      return getError(error);
    }

    this.thriveProfile = null;
    entities.results.forEach((data: any) => {
      this.thriveProfile = new UserThriveProfile({
        ...data,
        monitorFields: data.monitorFields.map((f: string) => camelize(f)),
      });
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateThriveProfile = _async(function* (
    this: UserStore,
    data: Partial<UserThriveProfile>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserThriveProfile>;
    let response;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(
          api.updateThriveProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createThriveProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating thrive profile', error);
      return getError(error);
    }

    if (entities) {
      const scoreMap = each(
        entities?.score || {},
        (value: SCORE_TYPE | null, key: string) => {
          return { [camelize(key)]: value };
        },
      );
      this.thriveProfile = new UserThriveProfile({
        ...entities,
        monitorFields: (entities?.monitorFields || []).map((f: string) =>
          camelize(f),
        ),
        score: scoreMap,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchHistory = _async(function* (
    this: UserStore,
    dateStart: string,
    dateEnd: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchHistory(rootStore.auth.token, dateStart, dateEnd),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching history', error);
      return getError(error);
    }

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchSummary = _async(function* (
    this: UserStore,
    dateStart: string,
    dateEnd: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchSummary(rootStore.auth.token, dateStart, dateEnd),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching summary', error);
      return getError(error);
    }

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchRecentSweatWorkoutProgress = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchRecentSweatWorkoutProgress(rootStore.auth.token),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching recent sweat workout', error);
      return getError(error);
    }
    this.createOrUpdateSweatWorkoutProgress(entities);

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchSweatWorkoutProgress = _async(function* (this: UserStore, id: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchSweatWorkoutProgress(rootStore.auth.token, id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching recent sweat workout', error);
      return getError(error);
    }

    this.createOrUpdateSweatWorkoutProgress(entities);

    this.loading = false;
    return entities;
  });

  @modelFlow
  updateSweatWorkoutProgress = _async(function* (
    this: UserStore,
    data: Partial<UserSweatWorkoutProgress>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateSweatWorkoutProgress(rootStore.auth.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching recent sweat workout', error);
      return getError(error);
    }

    this.createOrUpdateSweatWorkoutProgress(entities);
    this.loading = false;
    return entities;
  });

  @modelFlow
  updateSweatWorkoutExerciseProgress = _async(function* (
    this: UserStore,
    data: Partial<UserSweatWorkoutExerciseProgress>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateSweatWorkoutExerciseProgress(rootStore.auth.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching recent sweat workout', error);
      return getError(error);
    }

    this.createOrUpdateSweatWorkoutProgress(entities);
    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchSweatWorkoutProgresses = _async(function* (
    this: UserStore,
    dateStart: string,
    dateEnd: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<UserSweatWorkoutProgress>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchSweatWorkoutProgresses(
          rootStore.auth.token,
          dateStart,
          dateEnd,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching recent sweat workout', error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateSweatWorkoutProgress(data));
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDailyDebrief = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDailyDebrief(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching daily debrief', error);
      return getError(error);
    }

    this.dailyDebrief = null;
    entities.results.forEach((data: any) => {
      this.dailyDebrief = new UserDailyDebrief({
        ...data,
        monitorFields: (data.monitorFields || []).map((f: string) =>
          camelize(f),
        ),
      });
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateDailyDebrief = _async(function* (
    this: UserStore,
    data: Partial<UserDailyDebrief>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserDailyDebrief>;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(api.updateDailyDebrief(rootStore.auth.token, data)));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createDailyDebrief(rootStore.auth.token, {
            ...data,
          }),
        ));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating daily debrief', error);
      return getError(error);
    }

    if (entities) {
      this.dailyDebrief = new UserDailyDebrief({
        ...entities,
      });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDebriefProfile = _async(function* (this: UserStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchDebriefProfile(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching debrief profile', error);
      return getError(error);
    }

    this.debriefProfile = null;
    entities.results.forEach((data: any) => {
      this.debriefProfile = new UserDebriefProfile({
        ...data,
        monitorFields: (data.monitorFields || []).map((f: string) =>
          camelize(f),
        ),
      });
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateDebriefProfile = _async(function* (
    this: UserStore,
    data: Partial<UserDebriefProfile>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: SnapshotOutOf<UserDebriefProfile>;
    try {
      if (data.id) {
        ({
          response: { entities },
        } = yield* _await(
          api.updateDebriefProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      } else {
        ({
          response: { entities },
        } = yield* _await(
          api.createDebriefProfile(rootStore.auth.token, {
            ...data,
            monitorFields: (data?.monitorFields || []).map((f: string) =>
              decamelize(f),
            ),
          }),
        ));
      }
    } catch (error) {
      console.warn('[DEBUG] error updating debrief profile', error);
      return getError(error);
    }

    if (entities) {
      const scoreMap = each(
        entities?.score || {},
        (value: SCORE_TYPE | null, key: string) => {
          return { [camelize(key)]: value };
        },
      );
      this.debriefProfile = new UserDebriefProfile({
        ...entities,
        monitorFields: (entities?.monitorFields || []).map((f: string) =>
          camelize(f),
        ),
        score: scoreMap,
      });
    }

    this.loading = false;
    return getSuccess();
  });
}
