import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { filter, indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import EventModel from 'o2x-store/src/models/Event';
import SavedList from 'o2x-store/src/models/SavedList';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CheckBox from '../../components/CheckBox';
import EventItem from '../../components/Events/EventItem';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const EventListEdit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { listType, listIds },
    },
  } = props;

  const root = useStore();
  const { savedList } = root;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const [selectedList, setSelectedList] = useState<SavedList>();
  const [items, setItems] = useState<Map<string, EventModel>>();
  const [selectedItems, setSelectedItems] = useState<Array<number>>(new Array<number>());

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (listIds.length > 0) {
      const listId = listIds[0];
      const newList = savedList.eventList.get(`${listId}`);
      setSelectedList(newList);
      const items = new Map<string, EventModel>();
      const source = root.event.events;
      newList?.items.forEach((itemId: number) => {
        const item = source.get(`${itemId}`);
        if (item) {
          items.set(`${itemId}`, item);
        }
      });
      setItems(items);
    }
  }, [listType, listIds]);

  const onSubmit = useCallback(
    async (data) => {
      if (selectedList && selectedItems.length > 0) {
        setLoading(true);
        const updateData = {
          items: filter(selectedList?.items, (i) => indexOf(selectedItems, i) === -1),
        };
        const result = await savedList.updateList(updateData, listType, selectedList.id);
        setLoading(false);
        if (result.ok) {
          navigation.goBack();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [listType, selectedList, selectedItems],
  );

  const onToggleSelected = (checked: boolean, itemId: number) => {
    if (checked) {
      const newSelectedItems = [...selectedItems, itemId];
      setSelectedItems(newSelectedItems);
    } else {
      const filteredSelectedItems = filter(selectedItems, (i) => i !== itemId);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const removeSelectedFromDisplay = useCallback(() => {
    const filteredItems = new Map<string, EventModel>();
    items?.forEach((item: EventModel) => {
      filteredItems.set(`${item.id}`, item);
    });
    selectedItems.forEach((itemId: number) => {
      filteredItems?.delete(`${itemId}`);
    });
    setItems(filteredItems);
  }, [selectedItems, items]);

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <View style={styles.itemContainer}>
          <CheckBox
            key={item.id}
            fill="white"
            checked={indexOf(selectedItems, item.id) > -1}
            onChange={(key: string, checked: boolean) => {
              onToggleSelected(checked, item.id);
            }}
            style={styles.input}
          />
          <View style={styles.inputLabel}>
            <EventItem
              event={item}
              key={item.id}
              onPress={() => {}}
              onSave={() => {}}
              showBookmark={false}
            />
          </View>
        </View>
      ) : null,
    [selectedItems],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav onClose={goBack} onBack={goBack} />
      </View>

      <Layout style={styles.scrollHeader}>
        {!selectedList ? (
          <Text category="h2" style={styles.header}>
            New List
          </Text>
        ) : (
          <Text category="h2" style={styles.header}>
            Edit List
          </Text>
        )}
        {!!errorMessage && (
          <Text style={styles.error} category="label">
            {errorMessage}
          </Text>
        )}
        {selectedList && (
          <View style={styles.actionLinks}>
            <Text style={{ color: selectedList.color, fontSize: 14 }}>
              {selectedItems.length} Selected
            </Text>
            <TouchableOpacity onPress={removeSelectedFromDisplay}>
              <Text style={styles.link}>Remove</Text>
            </TouchableOpacity>
          </View>
        )}
      </Layout>
      <ScrollView style={styles.scroll}>
        {items && (
          <FlatList
            data={Array.from(items.values())}
            renderItem={renderItem}
            keyExtractor={(item) => `${item.id}`}
          />
        )}
      </ScrollView>

      <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
        <TouchableOpacity
          style={[styles.button, styles.buttonCancel]}
          disabled={loading}
          onPress={goBack}>
          <Text style={styles.buttonLabel} category="c1">
            Cancel
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} disabled={loading} onPress={onSubmit}>
          <Text style={styles.buttonLabel} category="c1">
            Save
          </Text>
        </TouchableOpacity>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'olive',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    marginVertical: 30,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
  },
  inputContainer: {
    flex: 1,
  },
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    flexGrow: 1,
    padding: 24,
  },
  scrollHeader: {
    paddingHorizontal: 24,
  },
  delete: {
    width: 24,
    marginLeft: 10,
  },
  deleteText: {
    color: 'red',
    fontSize: 18,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    marginBottom: 20,
  },
  inlineInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  input: {
    paddingTop: 30,
  },
  itemContainer: {
    flexDirection: 'row',
  },
  inputLabel: {
    marginLeft: 24,
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
});

export default observer(EventListEdit);
