export abstract class StorageService {
  abstract getItemAsync: (key: string) => Promise<string | null>;

  abstract setItemAsync: (key: string, item: string) => Promise<void>;

  abstract removeItemAsync: (key: string) => Promise<void>;

  abstract getSecureItemAsync: (key: string) => Promise<string | null>;

  abstract setSecureItemAsync: (key: string, item: string) => Promise<void>;

  abstract removeSecureItemAsync: (key: string) => Promise<void>;
}
