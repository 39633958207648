import {
  Divider,
  Input,
  Layout,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ScrollView,
  StyleProp,
  TextInput,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import InputAccessory from './InputAccessory';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  divider?: boolean;
  dividerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const MAX_INCH = 108;
const INCHES_TO_FOOT = 12;

const DailyLogHeightInput: React.FC<Props> = (props) => {
  const {
    question,
    onChange,
    initial,
    required,
    divider = true,
    dividerStyle,
    disabled = false,
    inputStyle,
    textStyle,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const data = [...Array(MAX_INCH + 1).keys()].map((i) => i);
  const [valueFt, setValueFt] = useState(
    !!initial ? String(Math.floor((initial as number) / INCHES_TO_FOOT)) : '',
  );
  const [valueIn, setValueIn] = useState(
    !!initial ? String((initial as number) % INCHES_TO_FOOT) : '',
  );
  const [isFocused, setIsFocused] = useState(false);
  const [scalePadding, setScalePadding] = useState(0);

  const ftRef = useRef<TextInput>(null);
  const inRef = useRef<TextInput>(null);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    if (initial && !isFocused) {
      setValueFt(String(Math.floor((initial as number) / INCHES_TO_FOOT)));
      setValueIn(String((initial as number) % INCHES_TO_FOOT));
    }
  }, [initial]);

  useEffect(() => {
    setValueFt(valueFt);
    setValueIn(valueIn);

    if (valueFt) {
      onChange(
        question.key,
        parseFloat(valueFt) * INCHES_TO_FOOT +
          parseFloat(valueIn ? valueIn : '0'),
      );
    } else {
      onChange(question.key, null);
    }
  }, [valueFt, valueIn]);

  const onFocusFoot = useCallback(() => {
    ftRef.current?.focus();
  }, []);

  const onFocusInch = useCallback(() => {
    inRef.current?.focus();
  }, []);

  const handleScroll = useCallback(
    (event: any) => {
      const offsetY =
        event.nativeEvent.contentOffset.y < 0
          ? 0
          : event.nativeEvent.contentOffset.y;

      setValueFt(String(Math.floor(offsetY / 7 / INCHES_TO_FOOT)));
      setValueIn(String(Math.floor((offsetY / 7) % INCHES_TO_FOOT)));
    },
    [setValueFt, setValueIn],
  );

  return (
    <View style={styles.container}>
      <View style={isMobile ? styles.heightInputMobile : styles.heightInput}>
        <Layout style={styles.heightInputContainer}>
          <TouchableWithoutFeedback onPress={onFocusFoot}>
            <View style={styles.inputContainer}>
              <Input
                ref={ftRef}
                style={[styles.input, inputStyle]}
                textStyle={textStyle}
                value={valueFt}
                onChangeText={(nextValue) => {
                  setValueFt(nextValue);
                }}
                underlineColorAndroid="transparent"
                accessoryRight={() => <InputAccessory label="ft" />}
                keyboardType="decimal-pad"
                disabled={disabled}
              />
            </View>
          </TouchableWithoutFeedback>
          <Divider style={[styles.divider, dividerStyle]} />
          <TouchableWithoutFeedback onPress={onFocusInch}>
            <View style={styles.inputContainer}>
              <Input
                ref={inRef}
                style={[styles.input, inputStyle]}
                textStyle={textStyle}
                value={valueIn}
                onChangeText={(nextValue) => {
                  setValueIn(nextValue);
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
                underlineColorAndroid="transparent"
                accessoryRight={() => <InputAccessory label="in" />}
                keyboardType="decimal-pad"
                disabled={disabled}
              />
            </View>
          </TouchableWithoutFeedback>
        </Layout>
        {divider && <Divider style={[styles.divider, dividerStyle]} />}
      </View>
      <View
        onLayout={(event) => {
          setScalePadding(event.nativeEvent.layout.height - 1);
        }}
        style={styles.heightScale}
      >
        <ScrollView
          contentContainerStyle={{
            justifyContent: 'flex-end',
          }}
          bounces={false}
          onScroll={handleScroll}
          snapToInterval={7}
          scrollEventThrottle={16}
        >
          {data.map((i) => {
            return (
              <View
                key={i}
                style={[
                  i % 12 === 0
                    ? isMobile
                      ? styles.scaleBigMobile
                      : styles.scaleBig
                    : i % 6 === 0
                    ? isMobile
                      ? styles.scaleMediumMobile
                      : styles.scaleMedium
                    : isMobile
                    ? styles.scaleSmallMobile
                    : styles.scaleSmall,
                  i === MAX_INCH && { marginBottom: 0 },
                ]}
              />
            );
          })}
          <View style={{ height: scalePadding }}></View>
        </ScrollView>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heightInputContainer: {
    display: 'flex',
    backgroundColor: 'transparent',
  },
  inputContainer: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  input: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
  heightInput: {
    flex: 2,
    marginRight: 50,
    marginLeft: 20,
  },
  heightInputMobile: {
    flex: 2,
    marginRight: 20,
  },
  heightScale: {
    backgroundColor: 'transparent',
  },
  scaleBig: {
    width: 109,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
  scaleBigMobile: {
    width: 60,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
  scaleMedium: {
    width: 71,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
  scaleMediumMobile: {
    width: 45,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
  scaleSmall: {
    width: 55,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
  scaleSmallMobile: {
    width: 25,
    height: 1,
    marginBottom: 6,
    backgroundColor: 'white',
    alignSelf: 'flex-end',
  },
});

export default DailyLogHeightInput;
