import config from '../../config';
import { callApiWithToken } from './base';

export const fetchResources = async (token: string) =>
  callApiWithToken(config.urls.resources, token, 'GET');

export const fetchEatResources = async (token: string) =>
  callApiWithToken(`${config.urls.resources}?is_eat=True`, token, 'GET');

export const fetchSweatResources = async (token: string) =>
  callApiWithToken(`${config.urls.resources}?is_sweat=True`, token, 'GET');

export const fetchThriveResources = async (token: string) =>
  callApiWithToken(`${config.urls.resources}?is_thrive=True`, token, 'GET');
