import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  SectionList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import Filter from '../../assets/images/filter.svg';
import ExploreFilterItem from '../../components/ExploreFilterItem';
import NavigationBar from 'src/components/NavigationBar';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useStore } from 'o2x-store/src/stores';
import { SweatEquipment } from 'o2x-store/src/models/Sweat';
import { useNativeStore } from 'src/stores';

type Props = {
  route?: any | null;
};

type SweatFilters = {
  [key: string]: string[];
};

const selected = TASK_TYPE.SWEAT;
const fill = getTaskColor(selected);

const WorkoutGeneratorEquipmentsFilter: React.FC<Props> = (props) => {
  const {
    route: {
      params: { selectedFilters, count },
    },
  } = props;
  const [currentFilter, setCurrentFilter] = useState<SweatFilters>({});

  const { exploreFilter, workoutFilter } = useNativeStore();
  const [filters, setFilters] = useState({ ...workoutFilter.filters });

  const filterCount = Object.values(filters).filter((filter) => filter).length;
  const [workoutCount, setWorkoutCount] = useState(count);

  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const titleOpacity = useRef(new Animated.Value(0));
  const store = useStore();
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });
  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });
  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });
  useFocusEffect(
    useCallback(() => {
      store.sweat.resetSweatEquipments();
      store.sweat.fetchSweatEquipments(true);
    }, []),
  );
  useEffect(() => {
    (async () => {
      setLoading(true);
      const allFilters = { ...currentFilter, ...selectedFilters };
      const result = await store.sweat.generateSweatWorkoutCount(allFilters);
      if (result.ok) {
        setWorkoutCount(result.extra?.count);
      }
      setLoading(false);
    })();
  }, [currentFilter, filters]);
  const onClearAll = useCallback(() => {
    setFilters({});
    setCurrentFilter({});
  }, []);
  const onNext = useCallback(() => {
    if (!loading) {
      const allFilters = { ...currentFilter, ...selectedFilters };
      navigation.navigate('WorkoutGeneratorResult', {
        selectedFilters: allFilters,
        count: workoutCount,
      });
    }
  }, [loading, workoutCount]);
  const filterSections = useMemo(() => {
    return {
      [selected]: [
        {
          key: 'equipments',
          data: Array.from(store.sweat.sweatEquipments.values()).map(
            (eq: SweatEquipment) => ({
              key: `equipment-${eq.id}`,
              label: eq.name,
            }),
          ),
        },
      ],
    };
  }, [store.sweat.sweatEquipments.values()]);
  const onSelectFilter = useCallback((filter, key, value, checked) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));

    setCurrentFilter((prevCurrentFilter) => ({
      ...prevCurrentFilter,
      [key]:
        key in prevCurrentFilter
          ? checked
            ? prevCurrentFilter[key].filter((v) => v != value)
            : [...prevCurrentFilter[key], value]
          : [value],
    }));
  }, []);

  const renderItem = useCallback(
    ({ item, section }) => {
      return (
        <View style={styles.filterItem}>
          <ExploreFilterItem
            fill={fill}
            item={item}
            checked={filters[item.key]}
            onSelect={onSelectFilter}
            sectionKey={section.key}
          />
        </View>
      );
    },
    [fill, filters],
  );
  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }
        >
          <Layout style={styles.container}>
            <Layout
              style={
                isMobile
                  ? styles.contentMobile
                  : isDeviceMaxWidth600
                  ? styles.contentMaxWidth600
                  : isDeviceMaxWidth900
                  ? styles.contentMaxWidth900
                  : styles.content
              }
            >
              <Text category="h2" style={styles.title}>
                Workout Generator
              </Text>
              <Layout style={styles.filter}>
                <Filter height={20} width={20} stroke={theme['white']} />
                <Text style={styles.filterLabel}>Filters</Text>
                <TouchableOpacity
                  style={styles.filterText}
                  onPress={onClearAll}
                >
                  <Text style={[styles.clearAllLabel]} category="c1">
                    Clear All ({filterCount})
                  </Text>
                </TouchableOpacity>
              </Layout>

              <Text style={styles.label} category="c1">
                Equipments
              </Text>

              <SectionList
                style={styles.list}
                contentContainerStyle={styles.listContainer}
                sections={filterSections[selected]}
                extraData={filters}
                renderItem={renderItem}
                ItemSeparatorComponent={renderSeparator}
                stickySectionHeadersEnabled={false}
              />
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => store.sweat.fetchSweatEquipments(true)}
              >
                <View style={styles.workoutButton}>
                  <Text style={styles.filterNumLabel}>see more</Text>
                </View>
              </TouchableOpacity>

              <Layout style={styles.bottomPadding} />
            </Layout>
            <TouchableOpacity style={styles.bottomNav} onPress={onNext}>
              <View style={styles.workoutButton}>
                <Text style={styles.filterNumLabel}>next</Text>
              </View>
            </TouchableOpacity>
          </Layout>
          <NavigationBar
            style={[styles.navigation, { paddingTop: insets.top }]}
            title="Workout Generator"
            titleOpacity={titleOpacity.current.interpolate({
              inputRange: [0, 60, 80],
              outputRange: [0, 0, 1],
            })}
            showBack={false}
            showClose={true}
            withBackground={false}
          />
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  contentMaxWidth900: {
    flex: 1,
    paddingBottom: 20,
    paddingHorizontal: '20%',
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: 'white',
    marginTop: 20,
    textAlign: 'center',
  },
  actionButton: {
    flex: 1,
    backgroundColor: 'darker-blue',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  bottomPadding: {
    height: 10,
  },
  filterText: {
    paddingHorizontal: 10,
  },
  clearAllLabel: {
    color: fill,
    textTransform: 'uppercase',
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    marginHorizontal: 24,
  },
  filterLabel: {
    flex: 1,
    marginHorizontal: 16,
  },
  filterButton: {
    paddingRight: 0,
  },
  filterItem: {
    marginBottom: 8,
  },
  list: {
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 20,
    flex: 1,
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'white',
    borderBottomWidth: 1,
    marginHorizontal: 24,
    marginVertical: 8,
    paddingVertical: 4,
  },
  searchIcon: {
    height: 24,
    width: 24,
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
  },
  spacer: {
    height: 8,
  },
  bottomNav: {
    height: 48,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fill,
  },
  workoutButton: {
    flexDirection: 'row',
  },
  filterNumMatches: {
    color: fill,
    marginRight: 5,
  },
  filterNumLabel: {
    color: 'white',
    textTransform: 'uppercase',
  },
  label: {
    color: 'blue-secondary',
    textTransform: 'uppercase',
    marginTop: 24,
    marginBottom: 12,
    marginLeft: 18,
  },
});

export default observer(WorkoutGeneratorEquipmentsFilter);
