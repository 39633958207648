export const getStateForEventJoin = (eventCode: string) => ({
  routes: [
    {
      name: 'Home',
      state: {
        routes: [
          {
            name: 'More',
            routes: [{ name: 'More' }, { name: 'Events' }],
          },
        ],
      },
    },
    {
      name: 'EventJoin',
      params: { eventCode },
    },
  ],
});

export const getStateForCreateProfile = (registrationCode: string) => ({
  routes: [
    { name: 'Landing' },
    { name: 'SignUpCode' },
    { name: 'CreateProfile', params: { registrationCode } },
  ],
});

export const getStateForEventView = (eventId: string) => ({
  routes: [
    {
      name: 'EventDetail',
      params: { id: eventId },
    },
  ],
});

export const getStateForProgramView = (
  programType: string,
  programId: string,
) => ({
  routes: [
    {
      name: 'ProgramDetail',
      state: {
        routes: [
          {
            name: 'ProgramDetail',
            params: { type: programType, id: programId },
          },
        ],
      },
    },
  ],
});
