import Slider from '@react-native-community/slider';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import Apple from '../../assets/images/apple.svg';
import Beer from '../../assets/images/beer.svg';
import Burger from '../../assets/images/burger.svg';
import Carrot from '../../assets/images/carrot.svg';
import Clock from '../../assets/images/clock.svg';
import CoffeeCup from '../../assets/images/coffee-cup.svg';
import Croissant from '../../assets/images/croissant.svg';
import Cupcake from '../../assets/images/cupcake.svg';
import EmptyGlass from '../../assets/images/empty-glass.svg';
import EnergyDrink from '../../assets/images/energy-drink.svg';
import FriedChicken from '../../assets/images/fried-chicken.svg';
import Fries from '../../assets/images/fries.svg';
import FullGlass from '../../assets/images/full-glass.svg';
import GainWeight from '../../assets/images/gain-weight.svg';
import HighEnergy from '../../assets/images/high-energy.svg';
import LoseWeight from '../../assets/images/lose-weight.svg';
import LowEnergy from '../../assets/images/low-energy.svg';
import MaintainWeight from '../../assets/images/maintain-weight.svg';
import ModerateEnergy from '../../assets/images/moderate-energy.svg';
import Pitcher from '../../assets/images/pitcher.svg';
import Run from '../../assets/images/run.svg';
import Salad from '../../assets/images/salad.svg';
import Sit from '../../assets/images/sit.svg';
import SmallApple from '../../assets/images/small-apple.svg';
import SmallBeer from '../../assets/images/small-beer.svg';
import SmallCarrot from '../../assets/images/small-carrot.svg';
import SmallCoffeeCup from '../../assets/images/small-coffee-cup.svg';
import SmallCupcake from '../../assets/images/small-cupcake.svg';
import SmallEnergyDrink from '../../assets/images/small-energy-drink.svg';
import SmallGlass from '../../assets/images/small-glass.svg';
import SmallWine from '../../assets/images/small-wine.svg';
import SmallestCupcake from '../../assets/images/smallest-cupcake.svg';
import Soda from '../../assets/images/soda.svg';
import Spaghetti from '../../assets/images/spaghetti.svg';
import Steak from '../../assets/images/steak.svg';
import Walk from '../../assets/images/walk.svg';
import Wine from '../../assets/images/wine.svg';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  disabled?: boolean;
  fill?: string;
}

enum MAX_VALUE {
  ACTIVITY_LEVEL = 2,
  WATER = 128,
  TREAT = 15,
  SERVING = 8,
  DRINK = 7,
  CALORIE = 2500,
  SLEEP = 8,
}

const MINIMUM_STEP = 1;
const WATER_STEP = 8;
const CALORIE_STEP = 500;

const DailyLogSliderInput: React.FC<Props> = (props) => {
  const { question, onChange, initial, required, disabled = false, fill } = props;

  const styles = useStyleSheet(themedStyles);
  let initialIndex = 0;
  (question.options || []).forEach((option: Array<number | string>, index: number) => {
    if (option[0] === initial) {
      initialIndex = index;
    }
  });

  const checkedDataType = useCallback((): number => {
    if (typeof initial === 'number') {
      return initial < 0 ? 0 : initial;
    }
    if (typeof initial === 'string') {
      if (/^-?\d+$/.test(initial)) {
        return parseInt(initial);
      }
    }

    return 0;
  }, [initial]);

  const [value, setValue] = useState(question.options.length > 0 ? initialIndex : checkedDataType);

  const [currentValue, setCurrentValue] = useState(value);

  const onValueChange = useCallback(
    (v: number) => {
      setValue(v);
      if (question.options.length > 0) {
        onChange(question.key, question.options[v][0]);
      } else {
        onChange(question.key, v);
      }
    },
    [question, onChange],
  );

  useEffect(() => {
    onValueChange(value);
  }, [onValueChange]);

  const renderSlider = useCallback(
    (step: number, maximumValue: number) => (
      <Slider
        value={value}
        onValueChange={(val) => setCurrentValue(val)}
        onSlidingComplete={(val) => onValueChange(val)}
        style={styles.slider}
        step={step}
        minimumValue={0}
        maximumValue={maximumValue}
        minimumTrackTintColor={fill}
        maximumTrackTintColor="white"
        thumbTintColor="white"
      />
    ),
    [value, fill],
  );

  const renderIcons = useCallback(
    (Icon: any, maxValue: number, maxIcon: number, step: number = 1) => (
      <View style={styles.iconRow}>
        {[...Array(value === maxValue ? maxIcon : Math.floor(value / step)).keys()].map((i) => (
          <Icon key={i} style={styles.icon} />
        ))}
      </View>
    ),
    [value],
  );

  const renderAlternatingIcons = useCallback(
    (Icon1: any, Icon2: any, maxValue: number, maxIcon: number, step: number = 1) => (
      <View style={styles.iconRow}>
        {[...Array(value === maxValue ? maxIcon : Math.floor(value / step)).keys()].map(
          (i, idx) => {
            if (idx % 2 === 0) {
              return <Icon1 key={i} style={styles.icon} />;
            }
            return <Icon2 key={i} style={styles.icon} />;
          },
        )}
      </View>
    ),
    [value],
  );

  const renderTriLevelSlider = useCallback(
    (FirstIcon: any, SecondIcon: any, ThirdIcon: any, values: string[]) => (
      <>
        <View style={styles.iconContainer}>
          <View style={styles.iconRow}>
            {value === 0 ? <FirstIcon /> : value === 1 ? <SecondIcon /> : <ThirdIcon />}
          </View>
        </View>
        {renderSlider(MINIMUM_STEP, MAX_VALUE.ACTIVITY_LEVEL)}
        <Text style={[styles.level, { color: fill }]}>{values[currentValue]}</Text>
      </>
    ),
    [value, currentValue, renderSlider],
  );

  const renderWaterSlider = useCallback(
    () => (
      <>
        <View style={styles.waterIconContainer}>
          <View style={styles.iconRow}>
            {value === 0 ? (
              <EmptyGlass />
            ) : (
              renderIcons(
                value >= 96 ? Pitcher : value / WATER_STEP > 4 ? SmallGlass : FullGlass,
                MAX_VALUE.WATER,
                4,
                value >= 96 ? 32 : WATER_STEP,
              )
            )}
          </View>
        </View>
        {renderSlider(WATER_STEP, MAX_VALUE.WATER)}
        <Text style={styles.water}>
          {`${currentValue}${currentValue === MAX_VALUE.WATER ? '+' : ''} fl oz`}
        </Text>
      </>
    ),
    [value, currentValue, renderIcons, renderSlider],
  );

  const renderTreatSlider = useCallback(
    () => (
      <>
        <View style={styles.iconContainer}>
          {value > 0 &&
            renderIcons(
              value === MAX_VALUE.TREAT ? SmallestCupcake : value > 4 ? SmallCupcake : Cupcake,
              MAX_VALUE.TREAT,
              MAX_VALUE.TREAT * 2,
            )}
        </View>
        {renderSlider(MINIMUM_STEP, MAX_VALUE.TREAT)}
        <Text style={[styles.level, { color: fill }]}>
          {`${currentValue} ${currentValue === MAX_VALUE.TREAT ? '+' : ''}`}
        </Text>
      </>
    ),
    [value, currentValue, renderIcons, renderSlider],
  );

  const renderServingSlider = useCallback(
    (Icon: any, SmallIcon: any, maxValue: number, maxIcon: number) => (
      <>
        <View style={styles.iconContainer}>
          {value > 0 && renderIcons(value > 4 ? SmallIcon : Icon, maxValue, maxIcon)}
        </View>
        {renderSlider(MINIMUM_STEP, maxValue)}
        <Text style={[styles.level, { color: fill }]}>
          {`${currentValue} ${currentValue === maxValue ? '+' : ''}`}
        </Text>
      </>
    ),
    [value, currentValue, renderIcons, renderSlider],
  );

  const renderAlternatingServingSlider = useCallback(
    (
      Icon1: any,
      SmallIcon1: any,
      Icon2: any,
      SmallIcon2: any,
      maxValue: number,
      maxIcon: number,
    ) => (
      <>
        <View style={styles.iconContainer}>
          {value > 0 &&
            renderAlternatingIcons(
              value > 4 ? SmallIcon1 : Icon1,
              value > 4 ? SmallIcon2 : Icon2,
              maxValue,
              maxIcon,
            )}
        </View>
        {renderSlider(MINIMUM_STEP, maxValue)}
        <Text style={[styles.level, { color: fill }]}>
          {`${currentValue} ${currentValue === maxValue ? '+' : ''}`}
        </Text>
      </>
    ),
    [value, currentValue, renderAlternatingIcons, renderSlider],
  );

  const renderDrinkIcons = useCallback(
    (FirstIcon: any, SecondIcon: any, maxValue: number) => (
      <View style={styles.iconRow}>
        {[...Array(value === maxValue ? 4 : value - Math.floor(value / 2)).keys()].map((i) => (
          <FirstIcon key={i} style={styles.icon} />
        ))}
        {[...Array(value === maxValue ? 4 : Math.floor(value / 2)).keys()].map((i) => (
          <SecondIcon key={i} style={styles.icon} />
        ))}
      </View>
    ),
    [value],
  );

  const renderDrinkSlider = useCallback(
    (
      FirstIcon: any,
      SecondIcon: any,
      FirstSmallIcon: any,
      SecondSmallIcon: any,
      maxValue: number,
    ) => (
      <>
        <View style={styles.iconContainer}>
          {value > 0 &&
            renderDrinkIcons(
              value > 4 ? FirstSmallIcon : FirstIcon,
              value > 4 ? SecondSmallIcon : SecondIcon,
              maxValue,
            )}
        </View>
        {renderSlider(MINIMUM_STEP, maxValue)}
        <Text style={[styles.level, { color: fill }]}>
          {`${currentValue} ${currentValue === maxValue ? '+' : ''}`}
        </Text>
      </>
    ),
    [value, currentValue, renderSlider],
  );

  const renderCalorieSlider = useCallback(() => {
    const calorieIcons = [
      null,
      <Salad />,
      <>
        <Salad />
        <Steak />
        <Croissant />
      </>,
      <>
        <Fries />
        <Burger />
        <Soda />
      </>,
      <>
        <Fries />
        <Burger />
        <Soda />
        <Spaghetti />
      </>,
      <>
        <Burger />
        <Soda />
        <Spaghetti />
        <FriedChicken />
      </>,
    ];

    return (
      <>
        <View style={styles.iconContainer}>
          <View style={styles.iconRow}>{calorieIcons[value / CALORIE_STEP]}</View>
        </View>
        {renderSlider(CALORIE_STEP, MAX_VALUE.CALORIE)}
        <Text style={[styles.level, { color: fill }]}>
          {`${currentValue} ${currentValue === MAX_VALUE.CALORIE ? '+' : ''}`}
        </Text>
      </>
    );
  }, [value, currentValue, renderSlider]);

  const renderSleepSlider = useCallback(
    () => (
      <>
        <View style={styles.iconContainer}>
          {value > 0 && renderIcons(Clock, MAX_VALUE.SLEEP, MAX_VALUE.SLEEP - 1)}
        </View>
        {renderSlider(MINIMUM_STEP, MAX_VALUE.SLEEP)}
        <Text style={[styles.level, styles.levelOrigCase, { color: fill }]}>
          {`${currentValue} ${currentValue === MAX_VALUE.SLEEP ? '+' : ''} hrs`}
        </Text>
      </>
    ),
    [value, currentValue, renderIcons, renderSlider],
  );

  const renderField = useCallback(() => {
    if (question) {
      switch (question.key) {
        case 'activityLevel':
          return renderTriLevelSlider(Sit, Walk, Run, ['low', 'moderate', 'high']);
        case 'waterOz':
          return renderWaterSlider();
        case 'treatsPerDay':
          return renderTreatSlider();
        case 'fruitsAndVegetablesPerDay':
          return renderAlternatingServingSlider(
            Apple,
            SmallApple,
            Carrot,
            SmallCarrot,
            MAX_VALUE.SERVING,
            16,
          );
        case 'fruitsPerDay':
          return renderServingSlider(Apple, SmallApple, MAX_VALUE.SERVING, 16);
        case 'vegetablesPerDay':
          return renderServingSlider(Carrot, SmallCarrot, MAX_VALUE.SERVING, 14);
        case 'caffeinePerDay':
          return renderDrinkSlider(
            CoffeeCup,
            EnergyDrink,
            SmallCoffeeCup,
            SmallEnergyDrink,
            MAX_VALUE.DRINK,
          );
        case 'alcoholPerDay':
          return renderDrinkSlider(Beer, Wine, SmallBeer, SmallWine, MAX_VALUE.DRINK);
        case 'caloriesPerDay':
          return renderCalorieSlider();
        case 'energyLevel':
          return renderTriLevelSlider(LowEnergy, ModerateEnergy, HighEnergy, [
            'low',
            'moderate',
            'high',
          ]);
        case 'weightGoal':
          return renderTriLevelSlider(GainWeight, MaintainWeight, LoseWeight, [
            'gain weight',
            'maintain weight',
            'lose weight',
          ]);
        case 'hoursSleep':
          return renderSleepSlider();
        default:
          return null;
      }
    }
  }, [
    question,
    renderTriLevelSlider,
    renderWaterSlider,
    renderTreatSlider,
    renderServingSlider,
    renderDrinkSlider,
    renderCalorieSlider,
  ]);

  return <View style={styles.sliderContainer}>{renderField()}</View>;
};

const themedStyles = StyleService.create({
  sliderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  slider: {
    width: '70%',
    height: 60,
  },
  level: {
    fontSize: 32,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  levelOrigCase: {
    textTransform: 'none',
  },
  water: {
    fontSize: 32,
    fontWeight: '700',
    color: '#91C300',
  },
  iconContainer: {
    width: 290,
    height: 75,
    marginTop: 50,
  },
  waterIconContainer: {
    width: 210,
    height: 75,
    marginTop: 50,
  },
  iconRow: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  icon: {
    marginHorizontal: 4,
    marginTop: 5,
  },
});

export default DailyLogSliderInput;
