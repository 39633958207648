import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Settings from '../../../components/Drawer/Settings';
import About from '../../About';
import SettingsAccount from '../../Settings/Account';
import SettingsData from '../../Settings/Data';
import SettingsDisplay from '../../Settings/Display';
import SettingsNotifications from '../../Settings/Notifications';
import SettingsTeam from '../../Settings/Team';

type Props = {};

const Drawer = createDrawerNavigator();

const SettingsDrawer: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });

  return (
    <Drawer.Navigator
      openByDefault={!isTabletOrMobile}
      drawerType={isTabletOrMobile ? 'front' : 'permanent'}
      overlayColor={isTabletOrMobile ? '#000' : 'transparent'}
      initialRouteName="SettingsAccount"
      drawerStyle={styles.drawer}
      drawerContent={(props: DrawerContentComponentProps) => (
        <Settings {...props} />
      )}
    >
      <Drawer.Screen name="SettingsAccount" component={SettingsAccount} />
      <Drawer.Screen name="SettingsDisplay" component={SettingsDisplay} />
      <Drawer.Screen
        name="SettingsNotifications"
        component={SettingsNotifications}
      />
      <Drawer.Screen name="SettingsTeam" component={SettingsTeam} />
      <Drawer.Screen name="SettingsData" component={SettingsData} />
      <Drawer.Screen name="About" component={About} />
    </Drawer.Navigator>
  );
};

const themedStyles = StyleService.create({
  drawer: {
    backgroundColor: 'wallpaper',
    width: 240,
    borderRightWidth: 0,
  },
});

export default SettingsDrawer;
