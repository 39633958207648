import { useNavigation } from '@react-navigation/native';
import { Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useEffect } from 'react';
import {
  Animated,
  BackHandler,
  Platform,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Back from '../../assets/images/back.svg';
import Background from '../../assets/images/navigation-bar-background.svg';

type Props = {
  style?: StyleProp<ViewStyle>;
  title?: string;
  titleOpacity?: Animated.AnimatedInterpolation;
  titleStyle?: StyleProp<ViewStyle>;
  accessory?: () => JSX.Element;
  // If we want the screen to handle back.
  goBack?: () => void;
  onClose?: () => void;
  withBackground?: boolean;
  showAccessory?: boolean;
  showClose?: boolean;
  showBack?: boolean;
  closeColor?: string;
  backgroundWidth?: string | number;
  opaqueBackgroundColor?: string;
};

const NavigationBar: React.FC<Props> = (props) => {
  const {
    style,
    title = '',
    titleOpacity = 0,
    titleStyle,
    accessory,
    goBack,
    backgroundWidth,
    withBackground = true,
    showAccessory = true,
    showClose = false,
    showBack = true,
    onClose,
    closeColor = 'white',
    opaqueBackgroundColor = '#10283E',
  } = props;
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  const isDeviceMaxWidth = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const onBack = useCallback(() => {
    if (goBack) {
      goBack();
    } else {
      navigation.goBack();
    }
    return true;
  }, [goBack]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', onBack);
    return () => backHandler.remove();
  }, [onBack]);

  const defaultWidth = Platform.OS === 'web' ? '100%' : undefined;

  return (
    <>
      <Animated.View
        style={[
          styles.background,
          {
            backgroundColor: opaqueBackgroundColor,
            height: 60,
            opacity: titleOpacity,
          },
        ]}
      />
      {withBackground && (
        <Background style={styles.background} width={backgroundWidth || defaultWidth} />
      )}
      <Layout
        style={[
          styles.container,
          !isDeviceMaxWidth &&
            Platform.OS === 'web' && {
              paddingTop: insets.top,
              marginBottom: 20,
            },
          style,
        ]}>
        {showBack && (
          <TouchableOpacity style={styles.backButton} onPress={onBack}>
            <Back />
          </TouchableOpacity>
        )}
        <Animated.View style={[styles.titleWrapper, { opacity: titleOpacity }]}>
          <Text style={[styles.title, titleStyle]} category="h3">
            {title}
          </Text>
        </Animated.View>
        <Layout style={styles.accessoryWrapper}>
          {showAccessory && accessory && accessory()}
          {showClose && (
            <TouchableOpacity onPress={onClose || onBack}>
              <Icon style={styles.closeIcon} fill={closeColor} name="close-outline" />
            </TouchableOpacity>
          )}
        </Layout>
      </Layout>
    </>
  );
};

const themedStyles = StyleService.create({
  accessoryWrapper: {
    backgroundColor: 'transparent',
    width: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 80,
  },
  backButton: {
    padding: 10,
    paddingTop: 0,
  },
  container: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
  },
  title: {
    textAlign: 'center',
  },
  titleWrapper: {
    flex: 1,
    paddingBottom: 15,
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
});

export default NavigationBar;
