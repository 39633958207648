import { Icon, Layout, Popover, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

type Props = {
  removeItem: () => void;
  saveItem: () => void;
  loadItem: () => void;
};

const ExerciseSetPopover: React.FC<Props> = (props) => {
  const { removeItem, saveItem, loadItem } = props;
  const styles = useStyleSheet(themedStyles);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const remove = () => {
    removeItem();
    setIsOptionsVisible(false);
  };

  const save = () => {
    saveItem();
    setIsOptionsVisible(false);
  };

  const load = () => {
    loadItem();
    setIsOptionsVisible(false);
  };

  const renderToggleButton = () => (
    <TouchableOpacity
      style={{
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      onPress={() => {
        setIsOptionsVisible(true);
      }}>
      <Icon
        name="more-vertical-outline"
        fill="white"
        style={{
          width: 15,
          height: 15,
        }}
      />
    </TouchableOpacity>
  );
  return (
    <Popover
      visible={isOptionsVisible}
      placement="bottom end"
      anchor={renderToggleButton}
      style={
        {
          borderWidth: 0.2,
          borderColor: 'black',
        } as StyleProp<ViewStyle>
      }
      onBackdropPress={() => {
        setIsOptionsVisible(false);
      }}>
      <Layout style={{ height: 180, width: 150 }}>
        <TouchableOpacity
          style={{
            width: '100%',
            height: '33.33%',
            flexDirection: 'row',
            borderBottomWidth: 1.5,
            borderBottomColor: '#203950',
          }}
          onPress={remove}>
          <Icon
            name="trash-2-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Remove Set
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            width: '100%',
            height: '33.33%',
            flexDirection: 'row',
            borderBottomWidth: 1.5,
            borderBottomColor: '#203950',
          }}
          onPress={save}>
          <Icon
            name="save-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Save Template
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: '100%', height: '33.33%', flexDirection: 'row' }}
          onPress={load}>
          <Icon
            name="file-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Load Template
          </Text>
        </TouchableOpacity>
      </Layout>
    </Popover>
  );
};

const themedStyles = StyleService.create({});

export default observer(ExerciseSetPopover);
