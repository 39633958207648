import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { PREPARE_ID } from 'o2x-store/src/constants/sweat';
import SweatWorkout, { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, TouchableWithoutFeedback, View } from 'react-native';
// import VideoPlayer from '../VideoPlayer';
import ReactPlayer from 'react-player';
import { DEFAULT_TIME_TABATA } from 'src/models/SweatStart';
import { getTimeDisplay } from 'src/utils/timeDisplay';
import ChevronDown from '../../assets/images/chevron-down.svg';
import ChevronUp from '../../assets/images/chevron-up.svg';
import { useNativeStore } from '../../stores';

type Props = {
  workout?: SweatWorkout;
  step: SweatWorkoutStep;
  stepExercise: SweatWorkoutStepExercise;
  showVideo?: boolean;
  newScreen?: boolean;
  summaryId?: number;
};

const SweatStartItem: React.FC<Props> = (props) => {
  const { step, stepExercise, showVideo = true } = props;
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const { sweatStart, mediaStorage } = useNativeStore();
  const { sweat } = useStore();
  const shouldAddHeader = step.id > PREPARE_ID ? step.circuitSets === 1 : true;
  const exercise = sweat.getSweatExercise(stepExercise.exercise);
  const exerciseVideoFile = useMemo(
    () => exercise && mediaStorage.getOrCreateMediaFile(exercise, 'video'),
    [exercise],
  );
  const canExpand = exercise?.description || exercise?.video || stepExercise.instructions;
  const expand = canExpand && sweatStart.expandStepExercise === stepExercise.id;
  const focus =
    !sweatStart.workoutId ||
    (step.id > PREPARE_ID && !sweatStart.breakdownTime[stepExercise.id]) ||
    (step.id <= PREPARE_ID && !sweatStart.globalBreakdownTime[stepExercise.id]) ||
    sweatStart.stepExerciseId === stepExercise.id ||
    expand;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [localUri, setLocalUri] = useState<string | null>(null);
  const onLayout = useCallback((event) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const toggleExpand = useCallback(() => {
    if (!stepExercise || !canExpand) {
      return;
    }

    if (expand) {
      sweatStart.setExpandStepExercise(null);
    } else {
      sweatStart.setExpandStepExercise(stepExercise.id);
    }
  }, [sweatStart, stepExercise, expand]);

  if (!exercise) {
    return null;
  }

  const workRestTimerDisplay = useMemo(() => {
    const timerDisplay = [];

    if (stepExercise.workoutTimerWork) {
      timerDisplay.push(getTimeDisplay(stepExercise.workoutTimerWork || undefined));
    } else if (!!step.circuitTimerWork && step.circuitTimer === 'variable') {
      timerDisplay.push(getTimeDisplay(step.circuitTimerWork || undefined));
    }

    if (stepExercise.workoutTimerRestComputed) {
      timerDisplay.push(getTimeDisplay(stepExercise.workoutTimerRestComputed));
    } else if (!!step.circuitTimerRest && step.circuitTimer === 'variable') {
      timerDisplay.push(getTimeDisplay(step.circuitTimerRest));
    }

    return timerDisplay.join(' | ');
  }, [step, stepExercise]);

  let subscribed = true;

  useEffect(() => {
    (async () => {
      if (exerciseVideoFile && (!exerciseVideoFile.loading || Platform.OS === 'web')) {
        const localUri =
          Platform.OS !== 'web' ? await exerciseVideoFile.downloadMedia() : exerciseVideoFile.uri;
        if (localUri) {
          setLocalUri(localUri);
        }
      }
    })();
    return () => {
      subscribed = false;
    };
  }, [exerciseVideoFile, exerciseVideoFile?.loading, subscribed, setLocalUri]);

  return (
    <>
      {shouldAddHeader && (!!stepExercise.sets || !!stepExercise.reps) && (
        <Layout style={[styles.headerContainer, !focus && styles.blurContainer]}>
          <View style={styles.headerContent}>
            {!!stepExercise.sets && (
              <Text style={styles.headerText}>
                {`${stepExercise.sets} ${Humanize.pluralize(parseInt(stepExercise.sets), 'set')}`}
              </Text>
            )}
            {!!stepExercise.reps && (
              <Text style={[styles.headerText, styles.cyanText]}>
                {`${stepExercise.reps} ${Humanize.pluralize(parseInt(stepExercise.reps), 'rep')}`}
                {!!stepExercise.isRepsEachSide && ' each side'}
              </Text>
            )}
          </View>
        </Layout>
      )}
      <Layout style={[styles.container, !focus && styles.blurContainer]}>
        <TouchableWithoutFeedback onPress={toggleExpand}>
          <Layout style={[styles.contentContainer]}>
            <Layout style={styles.content}>
              <Layout style={styles.contentItem}>
                {!!exercise.video && localUri && (
                  <Layout style={styles.thumbnailWrapper}>
                    {/* <Video
                      style={styles.thumbnail}
                      source={{ uri: localUri }}
                      resizeMode="cover"
                    /> */}
                    <ReactPlayer
                      url={localUri}
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      height={60}
                      width={60}
                    />
                  </Layout>
                )}

                <Layout style={styles.contentTextItem}>
                  <Text style={styles.title} numberOfLines={1}>
                    {exercise.name}
                  </Text>
                  {step?.circuitTimer === 'tabata' && (
                    <Text style={styles.timeText}>{getTimeDisplay(DEFAULT_TIME_TABATA)}</Text>
                  )}
                  <Layout style={styles.subtitleTextContainer}>
                    {shouldAddHeader
                      ? !!stepExercise.timeDistanceSubtitle && (
                          <>
                            <Text style={[styles.subtitleText, styles.cyanText]}>
                              {stepExercise.timeDistanceSubtitle}
                            </Text>
                            {/*
                            {stepExercise.hasRest && (
                              <Text
                                style={[styles.subtitleText, styles.cyanText]}
                              >
                                {` | `}
                              </Text>
                            )}
                            */}
                          </>
                        )
                      : !!stepExercise.subtitle && (
                          <>
                            <Text style={[styles.subtitleText, styles.cyanText]}>
                              {stepExercise.subtitle}
                            </Text>
                            {/*
                            {stepExercise.hasRest && (
                              <Text
                                style={[styles.subtitleText, styles.cyanText]}
                              >
                                {` | `}
                              </Text>
                            )}
                            */}
                          </>
                        )}

                    {/*
                    {stepExercise.hasRest && (
                      <Text style={styles.subtitleText}>
                        REST{' '}
                        <Text style={[styles.subtitleText, styles.grayText]}>
                          {stepExercise.rest}
                        </Text>
                      </Text>
                    )}
                    */}
                  </Layout>

                  <Layout style={styles.subtitleTextContainer}>
                    {/* Work/Rest time */}
                    <Text style={styles.timerText}>{workRestTimerDisplay}</Text>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
            <View style={styles.expandButton}>
              {!!canExpand && (expand ? <ChevronUp /> : <ChevronDown />)}
            </View>
          </Layout>
        </TouchableWithoutFeedback>
        {!!expand && (
          <Layout style={[styles.expandContainer]} onLayout={onLayout}>
            {!!exercise.description && (
              <Text style={styles.description} category="c1">
                {exercise.description}
              </Text>
            )}
            {!!stepExercise.instructions && (
              <Text style={styles.description} category="c1">
                {stepExercise.instructions}
              </Text>
            )}
            {!!exerciseVideoFile && !!showVideo && (
              // <VideoPlayer
              //   videoLink={localUri}
              //   size={Math.min((width - 240) * 0.8, 800)}
              //   pauseInitial={false}
              //   minimizeMaximize={false}
              // />
              <ReactPlayer
                url={localUri}
                playing
                loop
                style={{
                  alignSelf: 'center',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  width: 450,
                  height: 450,
                }}
              />
            )}
          </Layout>
        )}
      </Layout>
    </>
  );
};

const themedStyles = StyleService.create({
  blurContainer: {
    opacity: 0.5,
  },
  container: {
    flex: 1,
    paddingBottom: 2,
    backgroundColor: 'darler-blue',
  },
  headerContainer: {
    backgroundColor: 'black',
    height: 40,
    justifyContent: 'center',
  },
  headerContent: {
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  headerText: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginHorizontal: 3,
  },
  cyanText: {
    color: 'cyan',
  },
  grayText: {
    color: 'light-gray',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: 'wallpaper',
    minHeight: 60,
  },
  description: {
    margin: 12,
  },
  expandButton: {
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandContainer: {
    backgroundColor: 'wallpaper',
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'cyan',
    width: 4,
  },
  label: {
    flex: 1,
    textAlign: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    width: 60,
    marginRight: 8,
    backgroundColor: 'black',
  },
  title: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  timeText: {
    fontSize: 12,
    lineHeight: 14,
  },
  contentItem: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  contentTextItem: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  thumbnail: {
    // flex: 1,
    // resizeMode: 'cover',
    width: 60,
    height: 60,
  },
  thumbnailWrapper: {
    width: 60,
    height: 60,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: 'black',
  },
  subtitleTextContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    paddingVertical: 2,
  },
  subtitleText: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
  },
  changeToUp: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  hidden: {
    opacity: 0,
  },
  timerText: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'cyan',
  },
});

export default observer(SweatStartItem);
