import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ProgramDetail from 'src/containers/ProgramDetail';
import ProgramPreview from 'src/containers/ProgramDetail/Preview';
import RatingModal from 'src/containers/RatingModal';
import SweatContinue from 'src/containers/SweatContinue';
import SweatOptions from 'src/containers/SweatOptions';
import SweatOptionsJump from 'src/containers/SweatOptions/SweatOptionsJump';
import SweatOptionsQuit from 'src/containers/SweatOptions/SweatOptionsQuit';
import SweatStart from 'src/containers/SweatStart';
import SweatStartDetail from 'src/containers/SweatStart/SweatStartDetail';
import SweatStartDetailInstruction from 'src/containers/SweatStart/SweatStartDetailInstruction';
import SweatWorkoutComplete from 'src/containers/SweatWorkoutComplete';
import ThriveActivityComplete from 'src/containers/ThriveActivityComplete';
import ThriveBreathe from 'src/containers/ThriveBreathe';
import ThriveMeditate from 'src/containers/ThriveMeditate';
import ThriveOptions from 'src/containers/ThriveOption';
import ThriveOptionsJump from 'src/containers/ThriveOption/ThriveOptionsJump';
import ThriveOptionsQuit from 'src/containers/ThriveOption/ThriveOptionsQuit';
import ThriveProgramDayComplete from 'src/containers/ThriveProgramDayComplete';
import ThriveSetTime from 'src/containers/ThriveSetTime';
import ThriveSleep from 'src/containers/ThriveSleep';
import ThriveStart from 'src/containers/ThriveStart';
import AddHabitEat from 'src/containers/Today/AddHabitEat';
import AddHabitSweat from 'src/containers/Today/AddHabitSweat';
import AddHabitThrive from 'src/containers/Today/AddHabitThrive';
import DailyDebriefEat from 'src/containers/Today/DailyDebriefEat';
import DailyDebriefSweat from 'src/containers/Today/DailyDebriefSweat';
import DailyDebriefThrive from 'src/containers/Today/DailyDebriefThrive';
// import DailyDebriefQuestion from 'src/containers/Today/DailyDebriefQuestion';
import EatHabit from 'src/containers/Today/EatHabit';
import EatHabitQuestion from 'src/containers/Today/EatHabitQuestion';
import EatHabitSummary from 'src/containers/Today/EatHabitSummary';
import EatProfile from 'src/containers/Today/EatProfile';
import EatProfileQuestion from 'src/containers/Today/EatProfileQuestion';
import EatProfileSummary from 'src/containers/Today/EatProfileSummary';
import QuestionEat from 'src/containers/Today/QuestionEat';
import QuestionSweat from 'src/containers/Today/QuestionSweat';
import QuestionThrive from 'src/containers/Today/QuestionThrive';
import ThriveHabitQuestion from 'src/containers/Today/ThriveHabitQuestion';
import ThriveHabitSummary from 'src/containers/Today/ThriveHabitSummary';
import ThriveProfile from 'src/containers/Today/ThriveProfile';
import ThriveProfileQuestion from 'src/containers/Today/ThriveProfileQuestion';
import ThriveProfileSummary from 'src/containers/Today/ThriveProfileSummary';
import WorkoutGenerator from 'src/containers/WorkoutGenerator';
import Workout from 'src/containers/WorkoutGenerator/Workout';
import WorkoutGeneratorFilter from 'src/containers/WorkoutGenerator/WorkoutGeneratorFilter';
import WorkoutGeneratorResult from 'src/containers/WorkoutGenerator/WorkoutGeneratorResult';
import AccountSetting from '../../AccountSetting';
import Header from '../../Header';
import Profile from '../../Profile';
import Today from '../../Today';
import SweatWorkoutSummaryEditModal from '../modals/SweatWorkoutSummaryEditModal';

type Props = {};

const Stack = createStackNavigator();

const TodayStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal">
    <Stack.Screen name="Today" component={Today} />
    <Stack.Screen
      name="Profile"
      component={Profile}
      options={{
        headerTitle: () => <Header isProfileSelected />,
      }}
    />
    <Stack.Screen
      name="AccountSetting"
      component={AccountSetting}
      options={{
        headerTitle: () => <Header isAccountSettingsSelected />,
      }}
    />
    {/* <Stack.Screen
      name="DailyDebriefQuestion"
      component={DailyDebriefQuestion}
    /> */}
    <Stack.Screen name="DailyDebriefEat" component={DailyDebriefEat} />
    <Stack.Screen name="DailyDebriefSweat" component={DailyDebriefSweat} />
    <Stack.Screen name="DailyDebriefThrive" component={DailyDebriefThrive} />
    <Stack.Screen name="AddHabitEat" component={AddHabitEat} />
    <Stack.Screen name="AddHabitSweat" component={AddHabitSweat} />
    <Stack.Screen name="AddHabitThrive" component={AddHabitThrive} />
    <Stack.Screen name="QuestionEat" component={QuestionEat} />
    <Stack.Screen name="QuestionSweat" component={QuestionSweat} />
    <Stack.Screen name="QuestionThrive" component={QuestionThrive} />

    <Stack.Screen name="EatProfile" component={EatProfile} />
    <Stack.Screen name="EatProfileQuestion" component={EatProfileQuestion} />
    <Stack.Screen name="EatProfileSummary" component={EatProfileSummary} />
    <Stack.Screen name="EatHabit" component={EatHabit} />
    <Stack.Screen name="EatHabitQuestion" component={EatHabitQuestion} />
    <Stack.Screen name="EatHabitSummary" component={EatHabitSummary} />
    <Stack.Screen name="ThriveProfile" component={ThriveProfile} />
    <Stack.Screen name="ThriveProfileQuestion" component={ThriveProfileQuestion} />
    <Stack.Screen name="ThriveProfileSummary" component={ThriveProfileSummary} />
    <Stack.Screen name="ThriveHabitQuestion" component={ThriveHabitQuestion} />
    <Stack.Screen name="ThriveHabitSummary" component={ThriveHabitSummary} />
    <Stack.Screen name="ProgramDetail" component={ProgramDetail} />
    <Stack.Screen name="ProgramPreview" component={ProgramPreview} />
    <Stack.Screen name="RatingModal" component={RatingModal} />
    <Stack.Screen name="SweatStart" component={SweatStart} />
    <Stack.Screen name="SweatContinue" component={SweatContinue} />
    <Stack.Screen name="SweatStartDetail" component={SweatStartDetail} />
    <Stack.Screen name="SweatStartDetailInstruction" component={SweatStartDetailInstruction} />
    <Stack.Screen name="SweatWorkoutComplete" component={SweatWorkoutComplete} />
    <Stack.Screen name="SweatWorkoutSummary" component={SweatWorkoutSummaryEditModal} />
    <Stack.Screen name="SweatOptions" component={SweatOptions} />
    <Stack.Screen name="SweatOptionsJump" component={SweatOptionsJump} />
    <Stack.Screen name="SweatOptionsQuit" component={SweatOptionsQuit} />
    <Stack.Screen name="ThriveStart" component={ThriveStart} />
    <Stack.Screen name="ThriveOptionsJump" component={ThriveOptionsJump} />
    <Stack.Screen name="ThriveOptionsQuit" component={ThriveOptionsQuit} />
    <Stack.Screen name="ThriveProgramDayComplete" component={ThriveProgramDayComplete} />
    <Stack.Screen name="ThriveSleep" component={ThriveSleep} />
    <Stack.Screen name="ThriveBreathe" component={ThriveBreathe} />
    <Stack.Screen name="ThriveMeditate" component={ThriveMeditate} />
    <Stack.Screen name="ThriveSetTime" component={ThriveSetTime} />
    <Stack.Screen name="ThriveOptions" component={ThriveOptions} />
    <Stack.Screen name="ThriveActivityComplete" component={ThriveActivityComplete} />
    <Stack.Screen name="WorkoutGenerator" component={WorkoutGenerator} />
    <Stack.Screen name="WorkoutGeneratorFilter" component={WorkoutGeneratorFilter} />
    <Stack.Screen name="WorkoutGeneratorResult" component={WorkoutGeneratorResult} />
    <Stack.Screen name="Workout" component={Workout} />
  </Stack.Navigator>
);

export default TodayStack;
