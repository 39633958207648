import '@expo/match-media';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import TabletOrMobile from './TabletOrMobile';
import Web from './Web';

type Props = {};

const Account: React.FC<Props> = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });
  return isTabletOrMobile ? <TabletOrMobile>{children}</TabletOrMobile> : <Web>{children}</Web>;
};

export default Account;
