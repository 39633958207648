import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import ResourceModel from 'o2x-store/src/models/Resource';
import { useStore } from 'o2x-store/src/stores';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useMemo, useState } from 'react';
import { Dimensions, ScrollView, TextInput, View } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { useMediaQuery } from 'react-responsive';
import ResourceItem from 'src/components/Resource';
import ListBar from 'src/components/Resource/ListBar';

type Props = {
  route?: any | null;
};

const Resource: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { resources } = useStore();

  const {
    route: { params },
  } = props;
  const { type } = params || { type: TASK_TYPE.EAT };
  const [selected, setSelected] = useState<TASK_TYPE>(type);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Array<ResourceModel>>();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const renderItem = useCallback(
    (item) =>
      item.videoLink || item.fileUpload || item.externalUrl ? (
        <ResourceItem resource={item} selected={selected} />
      ) : null,

    [selected],
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (selected === TASK_TYPE.EAT) {
          setItems(
            Array.from(resources.resources.values()).filter((resource) => resource.isEat === true),
          );
        } else if (selected === TASK_TYPE.SWEAT) {
          setItems(
            Array.from(resources.resources.values()).filter(
              (resource) => resource.isSweat === true,
            ),
          );
        } else if (selected === TASK_TYPE.THRIVE) {
          setItems(
            Array.from(resources.resources.values()).filter(
              (resource) => resource.isThrive === true,
            ),
          );
        }
      })();
    }, [selected, type, loading]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);

        if (type) {
          setSelected(type);
        }
        await resources.fetchResources();

        setLoading(false);
      })();
    }, [type]),
  );

  const reloadResources = useCallback(
    (type) => {
      (async () => {
        setLoading(true);
        switch (type) {
          case TASK_TYPE.EAT:
            await resources.fetchEatResources();
            break;
          case TASK_TYPE.SWEAT:
            await resources.fetchSweatResources();
            break;
          case TASK_TYPE.THRIVE:
            await resources.fetchThriveResources();
            break;
          default:
            break;
        }

        setSelected(type);
        setLoading(false);
      })();
    },
    [selected],
  );

  const data = useMemo(() => {
    let data = items;
    if (search.length > 0) {
      const lowerCaseSearch = search.toLowerCase();
      data = data.filter((item) => item.name.toLowerCase().includes(lowerCaseSearch));
    }
    return data;
  }, [items, search, loading]);

  return (
    <Layout style={isDeviceMaxWidth600 ? styles.containerMaxWidth600 : styles.container}>
      <ScrollView>
        <View style={isDeviceMaxWidth600 ? styles.navContainerMaxWidth600 : styles.navContainer}>
          <View style={styles.taskBar}>
            <ListBar selected={selected} onSelect={(type) => reloadResources(type)} />
          </View>
          <View style={isDeviceMaxWidth600 ? styles.headerMaxWidth600 : styles.header}>
            <Layout style={styles.search}>
              <Icon style={styles.searchIcon} name="search" fill={theme.white} />
              <TextInput
                style={styles.searchInput}
                placeholder="Search"
                placeholderTextColor={theme.white}
                returnKeyType="search"
                value={search}
                onChangeText={setSearch}
              />
            </Layout>
          </View>
        </View>
        <View style={isDeviceMaxWidth600 ? styles.gridContainerMaxWidth600 : styles.gridContainer}>
          {loading || resources.loading ? (
            <View style={styles.loading}>
              <Spinner />
            </View>
          ) : (
            <View style={styles.listContainer}>
              <FlatGrid
                itemDimension={isMobile ? Dimensions.get('window').width : 270}
                data={data}
                keyExtractor={(item) => `${item.id}`}
                spacing={10}
                renderItem={({ item }) => renderItem(item)}
                itemContainerStyle={{ justifyContent: 'flex-start' }}
                fixed={false}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 24,
  },
  containerMaxWidth600: {
    flex: 1,
  },
  navContainer: {
    paddingHorizontal: 24,
  },
  navContainerMaxWidth600: {
    paddingHorizontal: 15,
  },
  gridContainer: {
    paddingHorizontal: 14,
  },
  gridContainerMaxWidth600: {
    paddingHorizontal: 5,
  },
  header: {
    marginTop: 20,
  },
  headerMaxWidth600: {
    marginTop: 10,
  },
  taskBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  search: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  searchIcon: {
    height: 24,
    width: 24,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: 22,
    marginHorizontal: 10,
    outlineWidth: 0,
  },
  searchWrapper: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  listContainer: {
    flex: 1,
    marginTop: 10,
  },
});

export default observer(Resource);
