import {
  Divider,
  Input,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  StyleProp,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import CheckBox from '../CheckBox';
import InputAccessory from './InputAccessory';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  divider?: boolean;
  dividerStyle?: StyleProp<ViewStyle>;
  fill?: string;
}

const NumberInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    initial,
    required,
    divider = true,
    dividerStyle,
    status: statusProp,
    fill = 'white',
  } = props;
  const [value, setValue] = useState(initial ? String(initial) : '');
  const [status, setStatus] = useState(statusProp);
  const [checked, setChecked] = useState(false);
  const inputRef = useRef<Input>(null);

  const onFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    onFocus();
  }, []);

  const onCheck = useCallback(() => {
    setChecked(!checked);
    if (checked) {
      onChange(question.key, null);
    } else {
      onChange(question.key, parseFloat(value));
    }
  }, [checked, question]);
  return (
    <>
      <TouchableWithoutFeedback onPress={onFocus}>
        <View style={styles.container}>
          <Input
            ref={inputRef}
            style={styles.input}
            value={checked ? '' : value}
            status={status}
            onChangeText={(nextValue) => {
              setStatus('');
              setValue(nextValue);
              if (parseFloat(nextValue)) {
                onChange(question.key, parseFloat(nextValue));
              }
              if (!nextValue && required) setStatus('danger');
            }}
            underlineColorAndroid="transparent"
            accessoryRight={
              question.label
                ? () => <InputAccessory label={question.label!} />
                : undefined
            }
            keyboardType="decimal-pad"
            blurOnSubmit={false}
          />
        </View>
      </TouchableWithoutFeedback>
      {divider && <Divider style={[styles.divider, dividerStyle]} />}
      {question.allowSkip && (
        <View style={styles.skip}>
          <CheckBox
            key="dont-know"
            status={status}
            checked={checked}
            onChange={onCheck}
            fill={fill}
          />
          <Text style={styles.skipText}>I don't know</Text>
        </View>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  input: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
  skip: {
    flexDirection: 'row',
  },
  skipText: {
    marginLeft: 10,
    color: 'white',
    fontSize: 14,
  },
});

export default NumberInput;
