import { TASK_TYPE } from 'o2x-store/src/utils/tasks';

export const ExploreFilterSections = {
  [TASK_TYPE.EAT]: [
    {
      key: 'meal',
      data: [
        {
          key: 'breakfast',
          label: 'Breakfast',
        },
        {
          key: 'desserts',
          label: 'Desserts',
        },
        {
          key: 'dinner',
          label: 'Dinner',
        },
        {
          key: 'drinks',
          label: 'Drinks',
        },
        {
          key: 'lunch',
          label: 'Lunch',
        },
        {
          key: 'sauces_marinades',
          label: 'Sauces/Marinades',
        },
        {
          key: 'sides',
          label: 'Sides',
        },
        {
          key: 'snack',
          label: 'Snack',
        },
      ],
    },
    {
      key: 'protein',
      data: [
        {
          key: 'pork',
          label: 'Pork',
        },
        {
          key: 'poultry',
          label: 'Poultry',
        },
        {
          key: 'red_meat',
          label: 'Red Meat',
        },
        {
          key: 'seafood',
          label: 'Seafood',
        },
        {
          key: 'vegetarian',
          label: 'Vegetarian',
        },
      ],
    },
  ],
  [TASK_TYPE.SWEAT]: [
    {
      key: 'type',
      data: [
        {
          key: 'short-sweat',
          label: 'Short SWEAT',
        },
        {
          key: 'tabata',
          label: 'TABATA',
        },
        {
          key: 'emom',
          label: 'EMOM',
        },
        {
          key: 'amrap',
          label: 'AMRAP',
        },
        {
          key: 'interval',
          label: 'Interval',
        },
        {
          key: 'strength-training',
          label: 'Strength Training',
        },
      ],
    },
    {
      key: 'difficulty',
      data: [
        {
          key: 'beginner',
          label: 'Beginner',
        },
        {
          key: 'intermediate',
          label: 'Intermediate',
        },
        {
          key: 'advanced',
          label: 'Advanced',
        },
      ],
    },
  ],
  [TASK_TYPE.THRIVE]: [
    {
      key: 'difficulty',
      data: [
        {
          key: 'intermediate',
          label: 'Intermediate',
        },
        {
          key: 'advanced',
          label: 'Advanced',
        },
        {
          key: 'beginner',
          label: 'Beginner',
        },
      ],
    },
  ],
};
