import { camelize } from 'humps';
import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import config from '../config';
import User, { UserEatProfile, UserThriveProfile } from '../models/User';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { StorageService } from '../services/storage';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/AuthStore')
export default class AuthStore extends Model({
  token: prop<string | null>(null),
  user: prop<User | null>(null),
}) {
  storageService!: StorageService;

  @observable
  loading = false;

  @modelAction
  setUser = (user: User | null) => {
    this.user = user;
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    this.loading = true;

    this.token = yield* _await(
      this.storageService.getSecureItemAsync(config.tokenKey),
    );

    this.loading = false;
  });

  @modelFlow
  storeToken = _async(function* (this: AuthStore, token: string) {
    this.token = token;
    yield* _await(
      this.storageService.setSecureItemAsync(config.tokenKey, token),
    );
  });

  @modelFlow
  removeToken = _async(function* (this: AuthStore) {
    this.token = null;
    yield* _await(this.storageService.removeSecureItemAsync(config.tokenKey));
  });

  @modelFlow
  signUp = _async(function* (
    this: AuthStore,
    data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      registrationCode: string;
    },
  ) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.signUp(data));
      yield* _await(this.storeToken(entities.key));
      this.user = new User(entities.user);
      yield* _await(analytics.setUser(this.user));
      analytics.logSignUp();
    } catch (error) {
      console.warn('[DEBUG] sign in error', error);
      return getError(error);
    }

    return getSuccess();
  });

  @modelFlow
  signIn = _async(function* (
    this: AuthStore,
    data: { email: string; password: string },
  ) {
    const rootStore = getRoot<RootStore>(this);
    try {
      const {
        response: { entities },
      } = yield* _await(api.signIn(data));
      yield* _await(this.storeToken(entities.key));
      this.user = new User(entities.user);
      yield* _await(analytics.setUser(this.user));
      analytics.logLogin();
      if (entities.user.eatProfile) {
        rootStore.user.eatProfile = new UserEatProfile({
          ...entities.user.eatProfile,
          monitorFields: (
            entities.user.eatProfile?.monitorFields || []
          ).map((f: string) => camelize(f)),
        });
      }
      if (entities.user.thriveProfile) {
        rootStore.user.thriveProfile = new UserThriveProfile({
          ...entities.user.thriveProfile,
          monitorFields: (
            entities.user.thriveProfile?.monitorFields || []
          ).map((f: string) => camelize(f)),
        });
      }
    } catch (error) {
      console.warn('[DEBUG] sign in error', error);
      return getError(error);
    }

    return getSuccess();
  });

  @modelFlow
  logOut = _async(function* (this: AuthStore) {
    if (this.token) {
      try {
        yield* _await(analytics.logLogout());
        yield* _await(api.logOut(this.token));
        this.user = null;
      } catch (error) {
        console.warn('[DEBUG] log out error', error);
      }
    }

    const rootStore = getRoot<RootStore>(this);
    yield* _await(rootStore.reset());
  });

  @modelFlow
  reset = _async(function* (this: AuthStore) {
    yield* _await(this.removeToken());
    this.user = null;
  });

  @modelFlow
  signInWithFacebook = _async(function* (this: AuthStore, accessToken: string) {
    try {
      const {
        response: {
          entities: { token, user },
        },
      } = yield api.signInWithFacebook(accessToken);
      yield this.storeToken(token);
      this.user = new User(user);
    } catch (error) {
      return getError(error);
    }

    return getSuccess();
  });

  @modelFlow
  signInWithGoogle = _async(function* (this: AuthStore, accessToken: string) {
    try {
      const {
        response: {
          entities: { token, user },
        },
      } = yield api.signInWithGoogle(accessToken);
      yield this.storeToken(token);
      this.user = new User(user);
    } catch (error) {
      return getError(error);
    }

    return getSuccess();
  });

  @modelFlow
  passwordReset = _async(function* (this: AuthStore, data: { email: string }) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.passwordReset(this.token || '', data));
    } catch (error) {
      console.warn('[DEBUG] sign in error', error);
      return getError(error);
    }
    return getSuccess();
  });
}
