import {
  Divider,
  Input,
  Layout,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useRef, useState } from 'react';
import { TextInput, TouchableWithoutFeedback } from 'react-native';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  subtitle?: boolean;
}

const DailyLogTextInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { question, onChange, initial, required } = props;
  const [value, setValue] = useState(initial as string);
  const [status, setStatus] = useState('');
  const inputRef = useRef<TextInput>(null);

  const onFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      <TouchableWithoutFeedback onPress={onFocus}>
        <Layout style={styles.container}>
          <Input
            ref={inputRef}
            style={styles.input}
            value={value}
            status={status}
            onChangeText={(nextValue) => {
              setStatus('');
              setValue(nextValue);
              onChange(question.key, nextValue);
              if (!nextValue && required) setStatus('danger');
            }}
            underlineColorAndroid="transparent"
            multiline
          />
        </Layout>
      </TouchableWithoutFeedback>
      <Divider style={styles.divider} />
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginHorizontal: 20,
  },
  input: {
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  divider: {
    backgroundColor: '#697484',
    marginTop: 0,
    marginHorizontal: 20,
  },
});

export default DailyLogTextInput;
