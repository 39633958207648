import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Fuse from 'fuse.js';
import { observer } from 'mobx-react-lite';
import { FTEOrganizationModel, FTESweatWorkout, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Animated, ScrollView, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { getErrors } from '../../../utils/errors';
import CreateSweatWorkout from '../Library/CreateSweatWorkout';

type Props = {
  org?: FTEOrganizationModel;
  user?: User;
  group?: OSSGroup;
  onNotification: boolean;
};

const SweatWorkoutContent: React.FC<Props> = (props) => {
  const { org, user, group, onNotification } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [fteSweatWorkoutList, setFteSweatWorkoutList] = useState<SweatWorkout[]>();
  const [targetSweatWorkoutList, setTargetSweatWorkoutList] = useState<SweatWorkout[]>();
  const [libraryList, setLibraryList] = useState<SweatWorkout[]>();
  const [targetList, setTargetList] = useState<SweatWorkout[]>();

  const [workout, setWorkout] = useState<FTESweatWorkout>();

  const [assignList, setAssignList] = useState<Number[]>();
  const [progressList, setProgressList] = useState<number[]>();
  const [workoutLibraryId, setWorkoutLibraryId] = useState<number>();
  const [workoutIds, setWorkoutIds] = useState<number[]>();
  const [initialLoadingA, setInitialLoadingA] = useState(false);
  const [initialLoadingB, setInitialLoadingB] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [id, setId] = useState(0);

  const [query, setQuery] = useState('');
  const [fuzzy, setFuzzy] = useState('');

  const { register, setValue, handleSubmit } = useForm();
  const animation = useRef(new Animated.Value(0)).current;

  const fuseOptions = {
    distance: 100,
    includeMatches: true,
    keys: ['name', 'displayName'],
    location: 0,
    minMatchCharLength: 1,
    shouldSort: true,
    threshold: 0.6,
  };

  const notifReceiver = org
    ? org.users
        .map((user) => {
          if (user.id !== store.auth.user?.id) return user.id;
        })
        .toString()
        .replace(/,\s*$/, '')
    : user
    ? user?.id.toString()
    : group?.users.toString().replace(/,\s*$/, '');

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingA(true);
        const workouts = user
          ? await store.fteLibrary.fetchTargetUserSweatWorkoutList(user.id)
          : org
          ? await store.fteLibrary.fetchTargetOrgSweatWorkoutList(org.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupSweatWorkoutList(group.id)
          : null;

        setId(workouts?.extra?.results[0].id);
        setAssignList(workouts?.extra?.results[0].workouts);
        setProgressList(workouts?.extra?.results[0].workouts);
        setTargetSweatWorkoutList(workouts?.extra?.results[0].workoutList);
        setTargetList(workouts?.extra?.results[0].workoutList);
        setInitialLoadingA(false);
      })();
    }, [org, user, group]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setInitialLoadingB(true);
        const fetchData = await store.fteLibrary.fetchMySweatWorkouts();
        setWorkoutLibraryId(fetchData.extra?.results[0].id);
        setWorkoutIds(fetchData.extra?.results[0].workouts);
        setFteSweatWorkoutList(fetchData.extra?.results[0].workoutDetails);
        setLibraryList(fetchData.extra?.results[0].workoutDetails);
        setInitialLoadingB(false);
      })();
    }, []),
  );

  const initialLoading = initialLoadingA || initialLoadingB;

  useFocusEffect(
    useCallback(() => {
      register({ name: 'id' }, { required: false });
      register({ name: 'workouts' }, { required: true });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('id', id);
    setValue('workouts', assignList);
  }, [assignList, id]);

  const addToAssign = (workout: SweatWorkout) => {
    if (assignList?.includes(workout.id) || targetSweatWorkoutList?.includes(workout)) return;
    setAssignList(assignList ? [...assignList!, workout.id] : [workout.id]);
    setTargetSweatWorkoutList(
      targetSweatWorkoutList ? [...targetSweatWorkoutList!, workout] : [workout],
    );
    setTargetList(targetList ? [...targetList!, workout] : [workout]);
  };

  const removeFromAssign = (workout: SweatWorkout) => {
    const arr = [...assignList!];
    const arr2 = [...targetList!];
    const arr3 = [...targetSweatWorkoutList!];
    const index = arr.indexOf(workout.id);
    const index2 = arr3.indexOf(workout);
    arr3.splice(index2, 1);
    arr2.splice(index, 1);
    arr.splice(index, 1);
    setAssignList([...arr]);
    setTargetList([...arr2]);
    setTargetSweatWorkoutList([...arr3]);
  };

  const onSubmit = useCallback(
    async (data) => {
      setLoadingSave(true);
      const result = org
        ? await store.fteLibrary.updateTargetOrgSweatWorkoutList(data)
        : user
        ? await store.fteLibrary.updateTargetUserSweatWorkoutList(data)
        : group
        ? await store.fteLibrary.updateTargetGroupSweatWorkoutList(data)
        : null;
      if (result?.ok) {
        const updatedWorkouts = org
          ? await store.fteLibrary.fetchTargetOrgSweatWorkoutList(org?.id)
          : user
          ? await store.fteLibrary.fetchTargetUserSweatWorkoutList(user?.id)
          : group
          ? await store.fteLibrary.fetchTargetGroupSweatWorkoutList(group?.id)
          : null;
        if (updatedWorkouts) {
          setAssignList(updatedWorkouts?.extra?.results[0].workouts);
          setProgressList(updatedWorkouts?.extra?.results[0].workouts);
          setTargetSweatWorkoutList(updatedWorkouts?.extra?.results[0].workoutList);
          setTargetList(updatedWorkouts?.extra?.results[0].workoutList);
        }
        if (onNotification) {
          await store.fteEvent.sendNotification({
            recipientUsers: notifReceiver,
            title: 'Your On-site Specialist just posted a new SWEAT workout',
            body: 'New Content',
            extra: {
              type: 'New Content',
              content: 'sweat',
              fte: {
                id: store.auth.user?.id,
                email: store.auth.user?.email,
                fullName: store.auth.user?.fullName,
                firstName: store.auth.user?.firstName,
                lastName: store.auth.user?.lastName,
                o2xId: store.auth.user?.o2XId,
                organizations: store.auth.user?.organization,
                profileImage: store.auth.user?.profileImage,
              },
              solo: !!user,
            },
          });
        }
      } else {
        console.log(getErrors(result?.errors));
      }
      setLoadingSave(false);
      setHasError(!result?.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => animation.setValue(0));
    },
    [org, user, group, onNotification],
  );
  const onSubmitWrapped = handleSubmit(onSubmit);

  const searchLibrary = () => {
    const fuse = new Fuse(libraryList!, fuseOptions);
    const matches = fuse.search(query);
    if (matches.length > 0) {
      const results = matches.map((result) => result.item);
      setFteSweatWorkoutList(results);
    }
    if (query.length === 0) {
      setFteSweatWorkoutList(libraryList);
    }
  };

  const searchTarget = () => {
    const fuse = new Fuse(targetList!, fuseOptions);
    const matches = fuse.search(fuzzy);
    if (matches.length > 0) {
      const results = matches.map((result) => result.item);
      setTargetSweatWorkoutList(results);
    }
    if (fuzzy.length === 0) {
      setTargetSweatWorkoutList(targetList);
    }
  };

  const openEditor = (workout: FTESweatWorkout) => {
    workout.steps?.map((step) => {
      step?.exercises?.map((exercise) => {
        Number.isInteger(exercise.exercise) ? null : (exercise.exercise = exercise.exercise.id);
      });
    });
    setWorkout(workout);
    setEdit(true);
  };

  const onSaveEdit = async () => {
    setEdit(false);
    setInitialLoadingA(true);
    const fetchData = await store.fteLibrary.fetchMySweatWorkouts();
    setFteSweatWorkoutList(fetchData.extra?.results[0].workoutDetails);
    setLibraryList(fetchData.extra?.results[0].workoutDetails);
    setInitialLoadingA(false);
  };

  const editWorkout = () => (
    <CreateSweatWorkout
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10,
      }}
      onClose={() => setEdit(false)}
      workout={workout}
      workoutLibrary={workoutIds}
      libraryId={workoutLibraryId}
      onSave={() => onSaveEdit()}
    />
  );
  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Layout style={isMobile ? styles.createContainerMobile : styles.createContainer}>
      {edit && editWorkout()}
      <Layout style={isMobile ? styles.listContainerMobile : styles.listContainer}>
        <Text style={styles.listHeader}>SWEAT WORKOUT LIBRARY</Text>
        <Input
          size="small"
          style={styles.search}
          accessoryLeft={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
          placeholder="Search"
          value={query}
          onChangeText={(text) => setQuery(text)}
          onKeyPress={(key) => (key.nativeEvent.key === 'Enter' ? searchLibrary() : null)}
        />
        <ScrollView style={styles.contentContainer}>
          {initialLoading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            fteSweatWorkoutList?.map((workout, index) => (
              <Layout key={index} style={styles.dataContainer}>
                <Text style={styles.dataText}>{workout.displayName}</Text>
                <TouchableOpacity style={styles.buttonIcon} onPress={() => addToAssign(workout)}>
                  <Icon name="plus-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
                <TouchableOpacity style={styles.editIcon} onPress={() => openEditor(workout)}>
                  <Icon name="edit-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
              </Layout>
            ))
          )}
        </ScrollView>
      </Layout>
      <Layout style={styles.listContainer}>
        <Text style={styles.listHeader}>SELECTED SWEAT WORKOUT LIBRARY</Text>
        <Input
          size="small"
          style={styles.search}
          accessoryLeft={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
          placeholder="Search"
          value={fuzzy}
          onChangeText={(text) => setFuzzy(text)}
          onKeyPress={(key) => (key.nativeEvent.key === 'Enter' ? searchTarget() : null)}
        />
        <ScrollView style={styles.saveContainer}>
          {initialLoading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            targetSweatWorkoutList?.map((workout, index) => (
              <Layout key={index} style={styles.dataContainer}>
                <Text style={styles.dataText}>{workout.displayName}</Text>
                <TouchableOpacity
                  style={styles.buttonIcon}
                  onPress={() => removeFromAssign(workout)}>
                  <Icon name="minus-outline" fill="white" style={styles.icon} />
                </TouchableOpacity>
              </Layout>
            ))
          )}
        </ScrollView>
        {loadingSave ? (
          <Layout style={styles.loadingSave}>
            <Spinner />
          </Layout>
        ) : (
          <TouchableOpacity style={styles.saveButton} onPress={onSubmitWrapped}>
            <Text style={styles.saveText}>Save</Text>
          </TouchableOpacity>
        )}
      </Layout>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[styles.popUpContainer, { opacity, transform: [{ translateY }, { scale }] }]}
          pointerEvents="none">
          <Icon
            style={styles.savedIcon}
            name={hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'}
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>{hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}</Text>
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  createContainer: {
    width: '80%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  listContainer: {
    width: '45%',
    height: '93%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  createContainerMobile: {
    width: '95%',
    height: '100%',
    paddingTop: 20,
    marginRight: 0,
    marginTop: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#1A3248',
  },
  listContainerMobile: {
    width: '47%',
    height: '93%',
    marginTop: '15px',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  listHeader: {
    marginTop: 15,
    marginLeft: 15,
    paddingLeft: 10,
    borderLeft: '2px solid #04B7D6',
    height: 'auto',
    fontSize: 15,
    color: 'white',
    maxWidth: '90%',
  },
  contentContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 20,
    backgroundColor: '#091C2D',
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 12,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
  },
  editIcon: {
    position: 'absolute',
    right: 30,
  },
  icon: {
    width: 20,
    height: 20,
  },
  loadMore: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    marginBottom: 15,
    marginTop: 10,
  },
  loadText: {
    color: 'white',
    fontSize: 12,
    margin: 'auto',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  loadingSave: {
    position: 'absolute',
    right: 20,
    bottom: 10,
    padding: 5,
    backgroundColor: 'transparent',
  },
  saveContainer: {
    width: '92%',
    marginLeft: 10,
    marginBottom: 45,
    backgroundColor: '#091C2D',
  },
  saveButton: {
    position: 'absolute',
    backgroundColor: 'olive',
    width: 70,
    height: 30,
    right: 10,
    bottom: 10,
    padding: 5,
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  search: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1A3248',
    marginTop: 15,
    marginBottom: 5,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
});

export default observer(SweatWorkoutContent);
