import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React from 'react';
import SweatVideoStorageObserver from './SweatVideoStorageObserver';
import ThriveMediaStorageObserver from './ThriveMediaStorageObserver';
import ThriveVideoStorageObserver from './ThriveVideoStorageObserver';

type Props = {};

const MediaStorageObserver: React.FC<Props> = (props) => {
  const { auth } = useStore();
  if (!auth?.user?.isSubscribed) return null;
  return (
    <>
      <SweatVideoStorageObserver />
      <ThriveMediaStorageObserver />
      <ThriveVideoStorageObserver />
    </>
  );
};

export default observer(MediaStorageObserver);
