import { RouteProp, useNavigation } from '@react-navigation/native';
import { Icon, Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { indexOf, keys } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import { useStore } from 'o2x-store/src/stores';
import { getListMapByType, LIST_TYPE, taskToListType } from 'o2x-store/src/utils/list';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { SectionGrid } from 'react-native-super-grid';
import { useMediaQuery } from 'react-responsive';
import ExploreTaskTabBar from 'src/components/ExploreTaskBar';
import { useIsSubscribed } from 'src/hooks/subscription';
import Breathe from '../../assets/images/breathe.svg';
import Dice from '../../assets/images/dice-explore.svg';
import Meditate from '../../assets/images/meditate.svg';
import Right from '../../assets/images/right-arrow.svg';
import Sleep from '../../assets/images/sleep.svg';
import Star from '../../assets/images/star-explore.svg';
import Yoga from '../../assets/images/yoga-icon.svg';
import ExploreItem from '../../components/ExploreItem';
import FilterInput from '../../components/FilterInput';
import { Filters } from '../../models/ExploreFilter';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { ExploreFilterSections } from '../ExploreFilter/constants';

type Props = {
  route: RouteProp<AppStackParamList, 'Explore'>;
};

const Explore: React.FC<Props> = (props) => {
  const {
    route: { params },
  } = props;
  const { selected: initial } = params || { selected: TASK_TYPE.EAT };

  const { navigateWithPayWall } = useIsSubscribed();
  const navigation = useNavigation();
  const { exploreFilter, settings, workoutFilter } = useNativeStore();
  const { search, filters } = exploreFilter;
  const { eat, sweat, thrive, savedList: savedListStore } = useStore();
  const [selected, setSelected] = useState<TASK_TYPE>(initial);
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [reloading, setReloading] = useState(true);
  const [sweatMore, setSweatMore] = useState('');

  const styles = useStyleSheet(themedStyles);
  useEffect(() => {
    setSelected(initial);
  }, [setSelected, initial]);

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onSelect = useCallback(
    (type) => {
      setReloading(true);
      setLoading(true);
      setSelected(type);
      settings.setLastExplore(type);
      exploreFilter.reset();
    },
    [setLoading],
  );

  const onOpenFilter = useCallback(() => {
    navigation.navigate('ExploreFilter', { selected });
  }, [selected]);

  const renderItem = useCallback(
    ({ item }) => {
      const savedListMap = getListMapByType(savedListStore, taskToListType(item.type));
      return <ExploreItem program={item} savedList={savedListMap} />;
    },
    [
      savedListStore.recipeList.values(),
      savedListStore.sweatProgramList.values(),
      savedListStore.thriveProgramList.values(),
    ],
  );

  const renderSectionHeader = useCallback(
    () => (
      <View style={styles.headerContainer}>
        {selected === TASK_TYPE.EAT && (
          <>
            <View style={styles.filterContainer}>
              <TouchableOpacity
                style={[styles.actionItem, styles.actionItemHorizontal]}
                onPress={goToSavedRecipes}>
                <Icon
                  style={[
                    isMobile ? styles.actionIconMobile : styles.actionIcon,
                    styles.actionIconHorizontal,
                  ]}
                  fill="#C4C4C4"
                  name="bookmark-outline"
                />
                <Text style={isMobile ? styles.actionTextMobile : styles.actionText}>
                  View saved
                </Text>
              </TouchableOpacity>
              <View style={styles.filter}>
                {!isDeviceMaxWidth600 && <FilterInput onPress={onOpenFilter} selected={selected} />}
              </View>
            </View>
            {isDeviceMaxWidth600 && (
              <View style={styles.filter}>
                <FilterInput onPress={onOpenFilter} selected={selected} />
              </View>
            )}
          </>
        )}
        {selected === TASK_TYPE.SWEAT && (
          <>
            <View
              style={isDeviceMaxWidth600 ? styles.actionSectionMaxWidth600 : styles.actionSection}>
              <TouchableOpacity
                style={
                  isDeviceMaxWidth600
                    ? styles.actionContainerMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.actionContainerMaxWidth768
                    : styles.actionContainer
                }
                onPress={findASweatPlan}>
                <View style={styles.leftIconContainer}>
                  <Star />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>
                    Help me find a Plan{' '}
                  </Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  isDeviceMaxWidth600 ? styles.actionContainerMaxWidth600 : styles.actionContainer,
                  styles.lastItem,
                ]}
                onPress={goToWorkoutGenerator}>
                <View style={styles.leftIconContainer}>
                  <Dice />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>
                    Generate a Workout
                  </Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.filterContainer}>
              <TouchableOpacity
                style={[styles.actionItem, styles.actionItemHorizontal]}
                onPress={goToSavedSweatPlans}>
                <Icon
                  style={[
                    isMobile ? styles.actionIconMobile : styles.actionIcon,
                    styles.actionIconHorizontal,
                  ]}
                  fill="#C4C4C4"
                  name="bookmark-outline"
                />
                <Text style={isMobile ? styles.actionTextMobile : styles.actionText}>
                  View saved
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.actionItem, styles.actionItemHorizontal]}
                onPress={goToActivePlan}>
                <Icon
                  style={[
                    isMobile ? styles.actionIconMobile : styles.actionIcon,
                    styles.actionIconHorizontal,
                  ]}
                  fill="#C4C4C4"
                  name="bookmark-outline"
                />
                <Text style={isMobile ? styles.actionTextMobile : styles.actionText}>
                  View active
                </Text>
              </TouchableOpacity>
              <View style={styles.filter}>
                {!isDeviceMaxWidth600 && <FilterInput onPress={onOpenFilter} selected={selected} />}
              </View>
            </View>
            {isDeviceMaxWidth600 && (
              <View style={styles.filter}>
                <FilterInput onPress={onOpenFilter} selected={selected} />
              </View>
            )}
          </>
        )}
        {selected === TASK_TYPE.THRIVE && (
          <>
            <View
              style={isDeviceMaxWidth600 ? styles.actionSectionMaxWidth600 : styles.actionSection}>
              <TouchableOpacity
                style={
                  isDeviceMaxWidth600
                    ? styles.actionContainerMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.actionContainerMaxWidth768
                    : styles.actionContainer
                }
                onPress={findAThrivePlan}>
                <View style={styles.leftIconContainer}>
                  <Star />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>
                    Help me find a Plan{' '}
                  </Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={
                  isDeviceMaxWidth600
                    ? styles.actionContainerMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.actionContainerMaxWidth768
                    : styles.actionContainer
                }
                onPress={goToSleep}>
                <View style={styles.leftIconContainer}>
                  <Sleep />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>Sleep</Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={
                  isDeviceMaxWidth600
                    ? styles.actionContainerMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.actionContainerMaxWidth768
                    : styles.actionContainer
                }
                onPress={goToBreathe}>
                <View style={styles.leftIconContainer}>
                  <Breathe />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>Breathe</Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={
                  isDeviceMaxWidth600
                    ? styles.actionContainerMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.actionContainerMaxWidth768
                    : styles.actionContainer
                }
                onPress={goToMeditate}>
                <View style={styles.leftIconContainer}>
                  <Meditate />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>Meditate</Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  isDeviceMaxWidth600 ? styles.actionContainerMaxWidth600 : styles.actionContainer,
                  styles.lastItem,
                ]}
                onPress={goToYoga}>
                <View style={styles.leftIconContainer}>
                  <Yoga />
                </View>
                <View style={isMobile ? styles.textIconContainerMobile : styles.textIconContainer}>
                  <Text style={isMobile ? styles.iconTextMobile : styles.iconText}>Yoga</Text>
                </View>
                <View style={styles.rightIconContainer}>
                  <Right />
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.filterContainer}>
              <TouchableOpacity
                style={[styles.actionItem, styles.actionItemHorizontal]}
                onPress={goToSavedThrivePlans}>
                <Icon
                  style={[
                    isMobile ? styles.actionIconMobile : styles.actionIcon,
                    styles.actionIconHorizontal,
                  ]}
                  fill="#C4C4C4"
                  name="bookmark-outline"
                />
                <Text style={isMobile ? styles.actionTextMobile : styles.actionText}>
                  View saved
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.actionItem, styles.actionItemHorizontal]}
                onPress={goToActivePlan}>
                <Icon
                  style={[
                    isMobile ? styles.actionIconMobile : styles.actionIcon,
                    styles.actionIconHorizontal,
                  ]}
                  fill="#C4C4C4"
                  name="bookmark-outline"
                />
                <Text style={isMobile ? styles.actionTextMobile : styles.actionText}>
                  View active
                </Text>
              </TouchableOpacity>
              <View style={styles.filter}>
                {!isDeviceMaxWidth600 && <FilterInput onPress={onOpenFilter} selected={selected} />}
              </View>
            </View>
            {isDeviceMaxWidth600 && (
              <View style={styles.filter}>
                <FilterInput onPress={onOpenFilter} selected={selected} />
              </View>
            )}
          </>
        )}
        {/*
        {!!selected && (
          <Text style={styles.header} category="c1">
            {!!exploreFilter.isFiltering ? 'Search Results' : 'Recommended'}
          </Text>
        )}
        */}
      </View>
    ),
    [exploreFilter, selected],
  );

  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);

  const mealFilter = useMemo(
    () => ExploreFilterSections[TASK_TYPE.EAT].find((k) => k.key === 'meal'),
    [ExploreFilterSections],
  );

  const proteinFilter = useMemo(
    () => ExploreFilterSections[TASK_TYPE.EAT].find((k) => k.key === 'protein'),
    [ExploreFilterSections],
  );

  const filtersToQS = (searchStr: string, searchFilters: Filters) => {
    const queryParams: string[] = [];
    if (searchStr) {
      queryParams.push(`q=${searchStr}`);
    }
    if (searchFilters && selected === TASK_TYPE.SWEAT) {
      keys(searchFilters).forEach((k) => {
        if (searchFilters[k]) {
          if (k.startsWith('equipment')) {
            queryParams.push(`equipment=${k.replace('equipment-', '')}`);
          } else if (k.startsWith('professions')) {
            queryParams.push(`professions=${k.replace('professions-', '')}`);
          } else {
            queryParams.push(`difficulty=${k}`);
          }
        }
      });
    } else if (searchFilters && selected === TASK_TYPE.EAT) {
      const mealChoices = mealFilter ? mealFilter.data.map((f) => f.key) : [];
      const proteinChoices = proteinFilter ? proteinFilter.data.map((f) => f.key) : [];
      keys(searchFilters).forEach((k) => {
        if (searchFilters[k]) {
          if (indexOf(mealChoices, k) > -1) {
            queryParams.push(`meal=${k}`);
          } else if (indexOf(proteinChoices, k) > -1) {
            queryParams.push(`protein=${k}`);
          }
        }
      });
    } else if (searchFilters && selected === TASK_TYPE.THRIVE) {
      keys(searchFilters).forEach((k) => {
        if (searchFilters[k]) {
          queryParams.push(`difficulty=${k}`);
        }
      });
    }
    return queryParams.join('&');
  };

  useEffect(() => {
    if (
      (selected === TASK_TYPE.EAT && !eat.recipes.size) ||
      (selected === TASK_TYPE.SWEAT && !sweat.sweatPrograms.size) ||
      (selected === TASK_TYPE.THRIVE && !thrive.thrivePrograms.size)
    ) {
      setReloading(false);
    }
  }, [eat.recipes.size, sweat.sweatPrograms.size, thrive.thrivePrograms.size]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!exploreFilter.isFiltering) {
        setListLoading(true);
        if (selected === TASK_TYPE.EAT) {
          await savedListStore.fetchLists(LIST_TYPE.RECIPE);

          await eat.fetchRecipes();
        } else if (selected === TASK_TYPE.SWEAT) {
          await savedListStore.fetchLists(LIST_TYPE.SWEAT_PROGRAM);

          const result = await sweat.fetchSweatPrograms(true);
          setSweatMore(result.extra?.next);
        } else if (selected === TASK_TYPE.THRIVE) {
          await savedListStore.fetchLists(LIST_TYPE.THRIVE_PROGRAM);

          await thrive.fetchThrivePrograms();
        }
        setListLoading(false);
      }

      setLoading(false);
    })();
  }, [selected, exploreFilter.isFiltering, setLoading]);

  useEffect(() => {
    (async () => {
      if (exploreFilter.isFiltering) {
        setLoading(true);

        if (!listLoading) {
          if (selected === TASK_TYPE.EAT) {
            await eat.searchRecipes(filtersToQS(search, filters));
          } else if (selected === TASK_TYPE.SWEAT) {
            const result = await sweat.searchSweatPrograms(filtersToQS(search, filters));
            setSweatMore(result.extra?.next);
          } else if (selected === TASK_TYPE.THRIVE) {
            await thrive.searchThrivePrograms(filtersToQS(search, filters));
          }
          setLoading(false);
        }
      }
    })();
  }, [listLoading, exploreFilter, search, filters]);

  const loadMoreContent = async () => {
    if (selected === TASK_TYPE.SWEAT && sweatMore) {
      setLoading(true);
      await savedListStore.fetchLists(LIST_TYPE.SWEAT_PROGRAM);
      const result = await sweat.fetchSweatPrograms(true, sweatMore);
      setSweatMore(result.extra?.next);
      setLoading(false);
    }
  };

  const goToSavedRecipes = useCallback(() => {
    navigation.navigate('SavedStack', {
      screen: 'Saved',
      params: {
        type: LIST_TYPE.RECIPE,
      },
    });
  }, []);

  const goToSavedSweatPlans = useCallback(() => {
    navigation.navigate('SavedStack', {
      screen: 'Saved',
      params: {
        type: LIST_TYPE.SWEAT_PROGRAM,
      },
    });
  }, []);

  const findASweatPlan = useCallback(() => {
    navigation.navigate('FindSweatPlanQuestion');
  }, []);

  const goToSleep = useCallback(() => {
    navigateWithPayWall('ThriveSleep');
  }, [navigateWithPayWall]);

  const goToBreathe = useCallback(() => {
    navigateWithPayWall('ThriveBreathe');
  }, [navigateWithPayWall]);

  const goToMeditate = useCallback(() => {
    navigateWithPayWall('ThriveMeditate');
  }, [navigateWithPayWall]);

  const goToYoga = useCallback(() => {
    navigateWithPayWall('ThriveYoga');
  }, [navigateWithPayWall]);

  const goToWorkoutGenerator = useCallback(() => {
    workoutFilter.reset();
    navigateWithPayWall('WorkoutGeneratorFilter');
  }, [navigateWithPayWall]);

  const goToSavedThrivePlans = useCallback(() => {
    navigation.navigate('SavedStack', {
      screen: 'Saved',
      params: {
        type: LIST_TYPE.THRIVE_PROGRAM,
      },
    });
  }, []);

  const findAThrivePlan = useCallback(() => {
    navigation.navigate('FindThrivePlanQuestion');
  }, []);

  const goToActivePlan = useCallback(() => {
    navigation.navigate('ActiveList', {
      type: taskToListType(selected),
    });
  }, [selected]);

  const sections = useMemo(() => {
    let data: Program[] = [];

    if (reloading) {
      return [{ title: 'recommended', data: [] }];
    }

    if (selected === TASK_TYPE.EAT) {
      data = Array.from(eat.recipes.values());
    } else if (selected === TASK_TYPE.SWEAT) {
      data = Array.from(sweat.sweatPrograms.values());
    } else if (selected === TASK_TYPE.THRIVE) {
      data = Array.from(thrive.thrivePrograms.values());
    }
    return [{ title: 'recommended', data }];
  }, [
    selected,
    search,
    filters,
    exploreFilter.isFiltering,
    reloading,
    eat.recipes.values(),
    sweat.sweatPrograms.values(),
    thrive.thrivePrograms.values(),
  ]);

  const keyExtractor = useCallback((item) => `${item.type}-${item.id}`, []);

  return (
    <Layout style={isMobile ? styles.containerMobile : styles.container}>
      <Layout style={styles.topBar}>
        <ExploreTaskTabBar selected={selected} onSelect={onSelect} />
      </Layout>
      {!!reloading || (!!exploreFilter.isFiltering && loading) ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <SectionGrid
          style={styles.content}
          itemDimension={242}
          sections={sections}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={renderSeparator}
          renderSectionHeader={renderSectionHeader}
          onEndReached={() => loadMoreContent()}
          onEndReachedThreshold={0.05}
          ListFooterComponent={
            <>
              {!!loading && (
                <View style={styles.loader}>
                  <Spinner />
                </View>
              )}
            </>
          }
        />
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingTop: 40,
  },
  containerMobile: {
    flex: 1,
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingVertical: 12,
    paddingRight: 24,
  },
  header: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    marginVertical: 12,
    marginTop: 26,
  },
  spacer: {
    height: 12,
  },
  topBar: {
    backgroundColor: 'transparent',
  },
  actions: {
    marginHorizontal: 10,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionsVertical: {
    marginTop: 0,
    flexDirection: 'column',
  },
  actionItem: {
    alignItems: 'center',
    paddingRight: 12,
  },
  actionIcon: {
    width: 30,
    height: 30,
  },
  actionIconMobile: {
    width: 20,
    height: 20,
  },
  actionIconHorizontal: {
    marginRight: 12,
  },
  actionIconVertical: {
    marginBottom: 8,
  },
  actionItemHorizontal: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  actionItemVertical: {
    marginHorizontal: 10,
    width: 80,
    alignItems: 'center',
  },
  actionText: {
    textTransform: 'uppercase',
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    textAlign: 'center',
  },
  actionTextMobile: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  iconText: {
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    // doesn't exist
    // wordBreak: 'break-word',
    flexWrap: 'wrap',
  },
  iconTextMobile: {
    fontSize: 12,
    color: 'white',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },

  filterContainer: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filter: {
    flex: 1,
  },
  headerContainer: {
    marginBottom: 10,
  },
  actionSection: {
    paddingTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  actionSectionMaxWidth600: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  actionContainer: {
    flexGrow: 1,
    paddingVertical: 10,
    marginRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'olive',
  },
  actionContainerMaxWidth768: {
    flexGrow: 1,
    paddingVertical: 10,
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'olive',
  },
  actionContainerMaxWidth600: {
    width: '48%',
    marginTop: 10,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'olive',
  },
  lastItem: {
    marginRight: 0,
  },
  leftIconContainer: {
    paddingLeft: 10,
  },
  rightIconContainer: {
    marginRight: 10,
  },
  textIconContainer: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  textIconContainerMobile: {
    paddingLeft: 5,
    width: '50%',
  },
});

export default observer(Explore);
