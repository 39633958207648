import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Divider,
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { format, sub } from 'date-fns';
import { filter, get, indexOf, isNull, startCase } from 'lodash';
import { observer } from 'mobx-react-lite';
import PhysicalAssessment from 'o2x-store/src/models/PhysicalAssessment';
import { UserSummary } from 'o2x-store/src/models/Summary';
import { useStore } from 'o2x-store/src/stores';
import {
  getTaskColor,
  TASK_COMPONENT_TYPE,
  TASK_TYPE,
} from 'o2x-store/src/utils/tasks';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { useMediaQuery } from 'react-responsive';
import DailyDebriefItem from 'src/components/TodayItem/DailyDebriefItem';
import EatHabitItem from 'src/components/TodayItem/EatHabitItem';
import EatProfileItem from 'src/components/TodayItem/EatProfileItem';
import MeditateItem from 'src/components/TodayItem/MeditateItem';
import ProgramItem from 'src/components/TodayItem/ProgramItem';
import SleepItem from 'src/components/TodayItem/SleepItem';
import ThriveHabitItem from 'src/components/TodayItem/ThriveHabitItem';
import ThriveProfileItem from 'src/components/TodayItem/ThriveProfileItem';
import UserProfileItem from 'src/components/TodayItem/UserProfileItem';
import WorkoutGeneratorItem from 'src/components/TodayItem/WorkoutGeneratorItem';
import WorkoutItem from 'src/components/TodayItem/WorkoutItem';
// import RightArrow from '../../assets/images/assessment-arrow.svg';
import Collapse from '../../assets/images/collapse.svg';
import Eat from '../../assets/images/eat-logo.svg';
import Expand from '../../assets/images/expand.svg';
// import Info from '../../assets/images/info.svg';
import Sweat from '../../assets/images/sweat-logo.svg';
import Thrive from '../../assets/images/thrive-logo.svg';
import AssessmentItem from '../../components/AssessmentItem';
import ScoreBadge from '../../components/ScoreBadge';
import TodayItem, { Props as TaskProps } from '../../components/TodayItem/';
import { useNativeStore } from '../../stores';

type Props = {};

const todayItemMapping = {
  [TASK_COMPONENT_TYPE.EAT_HABIT]: EatHabitItem,
  [TASK_COMPONENT_TYPE.THRIVE_HABIT]: ThriveHabitItem,
  [TASK_COMPONENT_TYPE.EAT_PROFILE]: EatProfileItem,
  [TASK_COMPONENT_TYPE.THRIVE_PROFILE]: ThriveProfileItem,
  [TASK_COMPONENT_TYPE.SWEAT_PROGRAM]: ProgramItem,
  [TASK_COMPONENT_TYPE.THRIVE_PROGRAM]: ProgramItem,
  [TASK_COMPONENT_TYPE.WORKOUT_GENERATOR]: WorkoutGeneratorItem,
  [TASK_COMPONENT_TYPE.SLEEP]: SleepItem,
  [TASK_COMPONENT_TYPE.MEDITATE]: MeditateItem,
  [TASK_COMPONENT_TYPE.USER_PROFILE]: UserProfileItem,
  [TASK_COMPONENT_TYPE.SWEAT_WORKOUT]: WorkoutItem,
  [TASK_COMPONENT_TYPE.MEDITATE]: MeditateItem,
  [TASK_COMPONENT_TYPE.DAILY_DEBRIEF]: DailyDebriefItem,
  [TASK_COMPONENT_TYPE.DEBRIEF_PROFILE]: DailyDebriefItem,
};

const getTodayItemComponent = (
  componentType: TASK_COMPONENT_TYPE | null,
  props: TaskProps,
) => {
  let Component: React.FC<TaskProps> = TodayItem;
  if (
    componentType &&
    [
      TASK_COMPONENT_TYPE.EAT_HABIT,
      TASK_COMPONENT_TYPE.EAT_PROFILE,
      TASK_COMPONENT_TYPE.THRIVE_HABIT,
      TASK_COMPONENT_TYPE.THRIVE_PROFILE,
    ].includes(componentType)
  ) {
    return null; // HIDE EAT AND THRIVE SURVEY
  }
  if (componentType) {
    Component = get(todayItemMapping, componentType) || TodayItem;
  }
  return <Component {...props} />;
};

const Today: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(true);
  const [isStatsExpanded, setIsStatsExpanded] = useState(false);
  const [isAssessmentExpanded, setIsAssessmentExpanded] = useState(false);
  const {
    user: userStore,
    task,
    assessment,
    sweat: sweatStore,
    thrive: thriveStore,
  } = useStore();
  const navigation = useNavigation();
  const { settings } = useNativeStore();
  const today = new Date();

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const [summary, setSummary] = useState<UserSummary>();
  const [eatMonitorFields, setEatMonitorFields] = useState<string[]>([]);
  const [thriveMonitorFields, setThriveMonitorFields] = useState<string[]>([]);

  const eatTask = Array.from(task.today.values()).find(
    (t) => t.type === TASK_TYPE.EAT,
  );
  const sweatTask = Array.from(task.today.values()).find(
    (t) => t.type === TASK_TYPE.SWEAT,
  );
  const thriveTask = Array.from(task.today.values()).find(
    (t) => t.type === TASK_TYPE.THRIVE,
  );

  const todayTasks = useMemo(() => {
    return Array.from(task.today.values());
  }, [task.today.values()]);

  const todayTasksEat = useMemo(() => {
    return (
      (task.today &&
        Array.from(task.today.values()).filter(
          (t) => t.type === TASK_TYPE.EAT,
        )) ||
      []
    );
  }, [task.today.values(), userStore.eatProfile]);
  const todayTasksSweat = useMemo(() => {
    return (
      (task.today &&
        Array.from(task.today.values()).filter(
          (t) => t.type === TASK_TYPE.SWEAT,
        )) ||
      []
    );
  }, [task.today.values()]);
  const todayTasksThrive = useMemo(() => {
    return (
      (task.today &&
        Array.from(task.today.values()).filter(
          (t) => t.type === TASK_TYPE.THRIVE,
        )) ||
      []
    );
  }, [task.today.values()]);

  const data = useMemo(() => {
    let data = assessment.assessments;

    const myAssessments = filter(
      data,
      (d: PhysicalAssessment) => d.currentSubmission !== null,
    );

    return myAssessments;
  }, [
    assessment.assessments.values(),
    assessment.physicalAssessments.values(),
    assessment.physicalAssessmentSubmissions.values(),
  ]);

  const explore = [
    {
      type: TASK_TYPE.EAT,
      title: 'Explore EAT Recipes',
      desciption: 'Seafood, Vegetarian, and more...',
      image: 'eat-explore-taskbar.png',
    },
    {
      type: TASK_TYPE.SWEAT,
      title: 'Explore SWEAT plans',
      desciption: 'Strength Training, Running, and more...',
      image: 'sweat-explore-taskbar.png',
    },
    {
      type: TASK_TYPE.THRIVE,
      title: 'Explore THRIVE plans',
      desciption: 'Sleep Health, Yoga, and more...',
      image: 'thrive-explore-taskbar.png',
    },
  ];

  const goToExplore = useCallback((type: TASK_TYPE) => {
    navigation.navigate('ExploreStack', {
      screen: 'Explore',
      params: { screen: 'Explore', params: { selected: type } },
    });
    settings.setLastExplore(type);
  }, []);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        await task.fetchTasks('all', true, true);
        // Fetch data
        const fetchedData = await userStore.fetchSummary(
          format(sub(today, { days: 6 }), 'yyyy-MM-dd'),
          format(today, 'yyyy-MM-dd'),
        );
        if (!('errors' in fetchedData)) {
          setSummary(fetchedData);
        }
        setLoading(false);
        setEatMonitorFields(
          userStore.eatProfile?.monitorFields.slice(0, 4) || [],
        );
        setThriveMonitorFields(
          userStore.thriveProfile?.monitorFields.slice(0, 1) || [],
        );
      })();
    }, [setLoading]),
  );

  const keyExtractor = useCallback((item) => `${item.type}-${item.id}`, []);

  const renderAssessments = useCallback(
    (item) => (
      <AssessmentItem
        name={item.name}
        coverImageThumbnail={item.coverImageThumbnail}
        id={item.id}
        type={item.type}
        previousScore={item.previousScore}
        isSubmission={true}
      />
    ),
    [],
  );

  const renderToday = useCallback((item) => {
    return (
      <View style={styles.todayItem}>
        {getTodayItemComponent(item.component, {
          task: item,
          key: item.key,
        })}
      </View>
    );
  }, []);

  const renderExplore = useCallback(
    (item) => (
      <TouchableOpacity
        style={[styles.exploreBtn, { borderColor: getTaskColor(item.type) }]}
        onPress={() => goToExplore(item.type)}
      >
        <Layout style={styles.thumbnailWrapper}>
          <Image
            style={styles.thumbnail as StyleProp<ImageStyle>}
            source={require(`../../assets/images/${item.image}`)}
          />
        </Layout>
        <Layout style={styles.exploreInfo}>
          <Text style={styles.exploreTitle}>{item.title}</Text>
          <Text
            style={[
              styles.exploreDescription,
              { color: getTaskColor(item.type) },
            ]}
          >
            {item.desciption}
          </Text>
        </Layout>
      </TouchableOpacity>
    ),
    [],
  );

  const onStatsExpanded = useCallback(
    (isExpanded: boolean) => {
      setIsStatsExpanded(!isExpanded);
      if (!isStatsExpanded) {
        setEatMonitorFields(userStore.eatProfile?.monitorFields || []);
        setThriveMonitorFields(userStore.thriveProfile?.monitorFields || []);
      } else {
        setEatMonitorFields(
          userStore.eatProfile?.monitorFields.slice(0, 4) || [],
        );
        setThriveMonitorFields(
          userStore.thriveProfile?.monitorFields.slice(0, 1) || [],
        );
      }
    },
    [
      setIsStatsExpanded,
      isStatsExpanded,
      setEatMonitorFields,
      setThriveMonitorFields,
    ],
  );

  return (
    <>
      {loading ? (
        <Layout style={styles.loader}>
          <Spinner />
        </Layout>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout
              style={isMobile ? styles.containerMobile : styles.container}
            >
              {/* <Layout style={styles.newSurveyContainer}>
                <Layout style={styles.infoContainer}>
                  <Info />
                  <Text style={styles.newSurvey}>NEW SURVEY</Text>
                  <Text style={styles.overallExperience}>
                    Overall O2X Experience Critique
                  </Text>
                </Layout>
                <RightArrow />
              </Layout> */}
              <Layout style={styles.contentContainer}>
                <Layout style={styles.titleContainer}>
                  <Text style={styles.title}>My Stats</Text>
                  <TouchableOpacity
                    onPress={() => onStatsExpanded(isStatsExpanded)}
                  >
                    {isStatsExpanded ? <Collapse /> : <Expand />}
                  </TouchableOpacity>
                </Layout>

                <Layout style={!isDeviceMaxWidth600 && styles.statsContainer}>
                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Layout style={styles.category}>
                      <Eat width={24} height={24} viewBox="0 0 107 134" />
                      <Text
                        style={[
                          styles.categoryName,
                          { color: getTaskColor(TASK_TYPE.EAT) },
                        ]}
                      >
                        Eat
                      </Text>
                    </Layout>
                    {isDeviceMaxWidth600 && (
                      <Divider
                        style={[
                          styles.divider,
                          { backgroundColor: getTaskColor(TASK_TYPE.EAT) },
                        ]}
                      />
                    )}
                    <Layout
                      style={
                        isDeviceMaxWidth600
                          ? styles.statsDataContainerMaxWidth600
                          : styles.statsDataContainer
                      }
                    >
                      {!summary?.dailyEatHabit.count && !!eatTask ? (
                        <Layout style={styles.statsInfo}>
                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.infoTextContainer
                                : styles.fieldTextContainer
                            }
                          >
                            <Layout
                              style={[
                                styles.infoIcon,
                                {
                                  backgroundColor: getTaskColor(TASK_TYPE.EAT),
                                },
                              ]}
                            >
                              <Text style={styles.info}>!</Text>
                            </Layout>
                          </Layout>
                          <Text style={styles.fieldUnit}>
                            {eatMonitorFields.length > 0
                              ? 'You have not logged EAT habits in the last 7 days'
                              : 'You are not tracking your EAT habits'}
                          </Text>
                        </Layout>
                      ) : (
                        <>
                          {indexOf(eatMonitorFields, 'waterOz') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit?.waterOzAvg || 0}
                                </Text>
                                <ScoreBadge
                                  type={summary?.dailyEatHabitScore?.waterOzAvg}
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                Ounces of water a Day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'treatsPerDay') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit?.treatsPerDayAvg || 0}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore?.treatsPerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                indulgent food items a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'fruitsPerDay') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit?.fruitsPerDayAvg || 0}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore?.fruitsPerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                servings of fruits a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'vegetablesPerDay') >
                            -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit
                                    ?.vegetablesPerDayAvg || 0}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore
                                      ?.vegetablesPerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                servings of vegetables a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'caffeinePerDay') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit?.caffeinePerDayAvg ||
                                    0}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore
                                      ?.caffeinePerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                caffeinated drinks a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'alcoholPerDay') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyEatHabit?.alcoholPerDayAvg ||
                                    0}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore
                                      ?.alcoholPerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                alcoholic drinks a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'caloriesPerDay') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {isNull(
                                    summary?.dailyEatHabit?.caloriesPerDayAvg,
                                  )
                                    ? 'N/A'
                                    : summary?.dailyEatHabit?.caloriesPerDayAvg}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore
                                      ?.caloriesPerDayAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                calories a day
                              </Text>
                            </Layout>
                          )}
                          {indexOf(eatMonitorFields, 'energyLevel') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldText}>
                                  {summary?.dailyEatHabit?.energyLevelAvg
                                    ? startCase(
                                        summary?.dailyEatHabit?.energyLevelAvg,
                                      )
                                    : 'None'}
                                </Text>
                                <ScoreBadge
                                  type={
                                    summary?.dailyEatHabitScore?.energyLevelAvg
                                  }
                                  size={15}
                                />
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                energy level a day
                              </Text>
                            </Layout>
                          )}{' '}
                        </>
                      )}
                    </Layout>
                  </Layout>

                  <Layout
                    style={[
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer,
                      { marginVertical: 10 },
                    ]}
                  >
                    <Layout style={styles.category}>
                      <Sweat width={25} height={25} viewBox="0 0 145 89" />
                      <Text
                        style={[
                          styles.categoryName,
                          { color: getTaskColor(TASK_TYPE.SWEAT) },
                        ]}
                      >
                        sweat
                      </Text>
                    </Layout>
                    {isDeviceMaxWidth600 && (
                      <Divider
                        style={[
                          styles.divider,
                          { backgroundColor: getTaskColor(TASK_TYPE.SWEAT) },
                        ]}
                      />
                    )}
                    <Layout
                      style={
                        isDeviceMaxWidth600
                          ? styles.statsDataContainerMaxWidth600
                          : styles.statsDataContainer
                      }
                    >
                      {!summary?.sweatWorkoutProgress.count &&
                      !summary?.sweatWorkoutProgress.isCompleteCount &&
                      !!sweatTask ? (
                        <Layout style={styles.statsInfo}>
                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.infoTextContainer
                                : styles.fieldTextContainer
                            }
                          >
                            <Layout
                              style={[
                                styles.infoIcon,
                                {
                                  backgroundColor: getTaskColor(
                                    TASK_TYPE.SWEAT,
                                  ),
                                },
                              ]}
                            >
                              <Text style={styles.info}>!</Text>
                            </Layout>
                          </Layout>
                          <Text style={styles.fieldUnit}>
                            You have not done a SWEAT workout in the last 7 days
                          </Text>
                        </Layout>
                      ) : (
                        <>
                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.categoryDataMaxWidth600
                                : isDeviceMaxWidth768
                                ? styles.categoryDataMaxWidth768
                                : styles.categoryData
                            }
                          >
                            <Layout style={styles.fieldTextContainer}>
                              <Text style={styles.fieldNumber}>
                                {summary?.sweatWorkoutProgress?.timeAvg || 0}
                              </Text>
                            </Layout>
                            <Text style={styles.fieldUnit}>
                              minutes of workout a day
                            </Text>
                          </Layout>

                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.categoryDataMaxWidth600
                                : isDeviceMaxWidth768
                                ? styles.categoryDataMaxWidth768
                                : styles.categoryData
                            }
                          >
                            <Layout style={styles.fieldTextContainer}>
                              <Text style={styles.fieldText}>
                                {summary?.sweatWorkoutProgress
                                  ?.workoutDifficultyAvg || 'None'}
                              </Text>
                            </Layout>
                            <Text style={styles.fieldUnit}>
                              average workout difficulty
                            </Text>
                          </Layout>
                        </>
                      )}
                    </Layout>
                  </Layout>

                  <Layout
                    style={
                      isDeviceMaxWidth600
                        ? styles.categoryContainerMaxWidth600
                        : styles.categoryContainer
                    }
                  >
                    <Layout style={styles.category}>
                      <Thrive width={24} height={24} viewBox="0 0 119 134" />
                      <Text
                        style={[
                          styles.categoryName,
                          { color: getTaskColor(TASK_TYPE.THRIVE) },
                        ]}
                      >
                        thrive
                      </Text>
                    </Layout>
                    {isDeviceMaxWidth600 && (
                      <Divider
                        style={[
                          styles.divider,
                          { backgroundColor: getTaskColor(TASK_TYPE.THRIVE) },
                        ]}
                      />
                    )}
                    <Layout
                      style={
                        isDeviceMaxWidth600
                          ? styles.statsDataContainerMaxWidth600
                          : styles.statsDataContainer
                      }
                    >
                      {!summary?.dailyThriveHabit.count &&
                      !summary?.thriveActivity.count &&
                      !summary?.thriveProgramDayProgress.count &&
                      !!thriveTask ? (
                        <Layout style={styles.statsInfo}>
                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.infoTextContainer
                                : styles.fieldTextContainer
                            }
                          >
                            <Layout
                              style={[
                                styles.infoIcon,
                                {
                                  backgroundColor: getTaskColor(
                                    TASK_TYPE.THRIVE,
                                  ),
                                },
                              ]}
                            >
                              <Text style={styles.info}>!</Text>
                            </Layout>
                          </Layout>
                          <Text style={styles.fieldUnit}>
                            You have not done a THRIVE exercise in the last 7
                            days
                          </Text>
                        </Layout>
                      ) : (
                        <>
                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.categoryDataMaxWidth600
                                : isDeviceMaxWidth768
                                ? styles.categoryDataMaxWidth768
                                : styles.categoryData
                            }
                          >
                            <Layout style={styles.fieldTextContainer}>
                              <Text style={styles.fieldNumber}>
                                {summary?.thriveProgramDayProgress
                                  ?.isCompleteCount || 0}
                              </Text>
                            </Layout>
                            <Text style={styles.fieldUnit}>
                              program exercises completed
                            </Text>
                          </Layout>

                          <Layout
                            style={
                              isDeviceMaxWidth600
                                ? styles.categoryDataMaxWidth600
                                : isDeviceMaxWidth768
                                ? styles.categoryDataMaxWidth768
                                : styles.categoryData
                            }
                          >
                            <Layout style={styles.fieldTextContainer}>
                              <Text style={styles.fieldNumber}>
                                {summary?.thriveActivity?.count || 0}
                              </Text>
                            </Layout>
                            <Text style={styles.fieldUnit}>
                              thrive activities completed
                            </Text>
                          </Layout>

                          {indexOf(thriveMonitorFields, 'hoursSleep') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyThriveHabit?.hoursSleepAvg ||
                                    0}
                                </Text>
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                hours of sleep
                              </Text>
                            </Layout>
                          )}

                          {indexOf(thriveMonitorFields, 'qualitySleep') >
                            -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyThriveHabit?.qualitySleepAvg ||
                                    0}
                                </Text>
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                quality of sleep
                              </Text>
                            </Layout>
                          )}

                          {indexOf(thriveMonitorFields, 'stressLevel') > -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyThriveHabit?.stressLevelAvg ||
                                    0}
                                </Text>
                              </Layout>
                              <Text style={styles.fieldUnit}>stress level</Text>
                            </Layout>
                          )}

                          {indexOf(thriveMonitorFields, 'goalsAchieved') >
                            -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyThriveHabit
                                    ?.goalsAchievedAvg || 0}
                                </Text>
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                goals achieved
                              </Text>
                            </Layout>
                          )}

                          {indexOf(thriveMonitorFields, 'purposeLevel') >
                            -1 && (
                            <Layout
                              style={
                                isDeviceMaxWidth600
                                  ? styles.categoryDataMaxWidth600
                                  : isDeviceMaxWidth768
                                  ? styles.categoryDataMaxWidth768
                                  : styles.categoryData
                              }
                            >
                              <Layout style={styles.fieldTextContainer}>
                                <Text style={styles.fieldNumber}>
                                  {summary?.dailyThriveHabit?.purposeLevelAvg ||
                                    0}
                                </Text>
                              </Layout>
                              <Text style={styles.fieldUnit}>
                                purpose level
                              </Text>
                            </Layout>
                          )}
                        </>
                      )}
                    </Layout>
                  </Layout>
                </Layout>
              </Layout>
            </Layout>
            <Layout
              style={
                isMobile ? styles.gridContainerMobile : styles.gridContainer
              }
            >
              <Layout style={styles.contentContainer}>
                <Layout
                  style={[
                    styles.titleContainer,
                    { paddingLeft: isMobile ? 10 : 15 },
                  ]}
                >
                  <Text style={styles.title}>Assessments</Text>
                  <TouchableOpacity
                    onPress={() =>
                      setIsAssessmentExpanded(!isAssessmentExpanded)
                    }
                  >
                    {isAssessmentExpanded ? <Collapse /> : <Expand />}
                  </TouchableOpacity>
                </Layout>
                <Layout style={styles.assessmentsContainer}>
                  {data.length === 0 ? (
                    <Text style={styles.emptyAssessments}>
                      You have not taken any assessment
                    </Text>
                  ) : (
                    <FlatGrid
                      itemDimension={232}
                      data={isAssessmentExpanded ? data : data.slice(0, 4)}
                      keyExtractor={keyExtractor}
                      spacing={10}
                      renderItem={({ item }) => renderAssessments(item)}
                      itemContainerStyle={{ justifyContent: 'flex-start' }}
                      fixed={false}
                    />
                  )}
                </Layout>
              </Layout>
              <Layout style={styles.todayContainer}>
                <Layout style={[styles.titleContainer, styles.gridTitle]}>
                  <Text style={styles.title}>Today</Text>
                </Layout>
                <FlatGrid
                  itemDimension={316}
                  data={todayTasks}
                  spacing={10}
                  renderItem={({ item }) => renderToday(item)}
                  itemContainerStyle={{ justifyContent: 'flex-start' }}
                  fixed={false}
                />
              </Layout>
              <Layout style={styles.exploreContainer}>
                <Layout style={[styles.titleContainer, styles.gridTitle]}>
                  <Text style={styles.title}>Explore</Text>
                </Layout>
                <FlatGrid
                  itemDimension={316}
                  data={explore}
                  spacing={10}
                  renderItem={({ item }) => renderExplore(item)}
                  itemContainerStyle={{ justifyContent: 'flex-start' }}
                  fixed={false}
                />
              </Layout>
              {/* <Layout style={styles.externalLinkContainer}>
                <Layout style={styles.externalLinkBtn}>
                  <Image
                    style={
                      styles.externalLinkThumbnail as StyleProp<ImageStyle>
                    }
                    source={require('../../assets/images/shop.png')}
                  />
                  <Layout style={styles.externalLinkInfo}>
                    <Text style={styles.externalLinkTitle}>
                      Tactical Athletic Shop
                    </Text>
                    <Text style={styles.externalLinkDescription}>
                      Shop our exclusive products
                    </Text>
                  </Layout>
                </Layout>
              </Layout> */}
            </Layout>
          </ScrollView>
        </Layout>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
  },
  containerMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
  },
  gridContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingLeft: 10,
    paddingRight: 20,
  },
  gridContainerMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 15,
  },
  gridTitle: {
    paddingLeft: 10,
  },
  loader: {
    flex: 1,
    backgroundColor: 'wallpaper',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newSurveyContainer: {
    width: '100%',
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#1A3248',
    borderLeftWidth: 4,
    borderColor: 'olive',
    paddingLeft: 24,
    paddingRight: 17,
  },
  infoContainer: {
    flexDirection: 'row',
    backgroundColor: '#1A3248',
    alignItems: 'center',
  },
  newSurvey: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'olive',
    marginLeft: 20,
    marginRight: 18,
  },
  overallExperience: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  contentContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: 30,
  },
  titleContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
    marginRight: 6,
  },
  statsContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#697484',
    paddingHorizontal: 25,
    paddingVertical: 17,
    marginTop: 10,
  },
  categoryContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categoryContainerMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  category: {
    width: 110,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categoryName: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginLeft: 10,
  },
  statsDataContainer: {
    width: '85%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  statsDataContainerMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
    flexWrap: 'wrap',
  },
  categoryData: {
    width: '33%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  categoryDataMaxWidth768: {
    width: '48%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  categoryDataMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  fieldTextContainer: {
    width: 120,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  fieldNumber: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
  },
  fieldUnit: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
    color: '#4E7B89',
    marginLeft: 24,
  },
  fieldText: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: 'white',
  },
  statsInfo: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  infoIcon: {
    width: 16,
    height: 16,
    borderRadius: 50,
    alignItems: 'center',
  },
  info: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: '700',
    color: 'wallpaper',
    alignSelf: 'center',
  },
  infoTextContainer: {
    width: 20,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  assessmentsContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
  },
  exploreContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    marginLeft: 5,
    marginTop: 5,
  },
  exploreBtn: {
    maxWidth: 400,
  },
  todayContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    marginLeft: 5,
    marginTop: 5,
  },
  todayItem: {
    maxWidth: 400,
    marginTop: -16,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
  },
  thumbnailWrapper: {
    width: '100%',
    height: 100,
    backgroundColor: 'transparent',
  },
  exploreInfo: {
    width: '100%',
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: '#1A3248',
  },
  exploreTitle: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  exploreDescription: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginTop: 3,
  },
  externalLinkContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    marginTop: 30,
  },
  externalLinkBtn: {
    width: '32%',
    maxWidth: 400,
    minWidth: 316,
    marginBottom: 10,
    flexDirection: 'row',
    backgroundColor: '#203950',
  },
  externalLinkThumbnail: {
    width: 100,
    height: 100,
    resizeMode: 'cover',
  },
  externalLinkInfo: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginLeft: 20,
  },
  externalLinkTitle: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  externalLinkDescription: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'olive',
    marginTop: 3,
  },
  emptyAssessments: {
    fontWeight: '600',
    fontSize: 18,
    color: 'blue-secondary',
    marginLeft: 15,
    marginBottom: 20,
  },
  divider: {
    width: '100%',
    height: 0.5,
    marginVertical: 5,
  },
});

export default observer(Today);
