import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { get, isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef } from 'react';
import { Alert, Animated, Platform, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import config from 'src/config';
import NavigationBar from '../../components/NavigationBar';
import SweatStartWorkout from '../../components/SweatStart/SweatStartWorkout';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatStart'>;
};

const SweatStart: React.FC<Props> = (props) => {
  const { id, jumpWorkoutId, isFte } = props.route.params;
  const navigation = useNavigation();
  const { sweatStart } = useNativeStore();
  const { sweat } = useStore();
  const program = sweat.sweatPrograms.get(`${id}`);
  const workoutId = jumpWorkoutId || get(program, 'userProgress.currentWorkout', null);
  const workout = workoutId && sweat.sweatWorkouts.get(`${workoutId}`);
  const titleOpacity = useRef(new Animated.Value(0));

  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  useKeepAwake();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const goBack = useCallback(() => {
    if (sweatStart.active) {
      if (Platform.OS === 'web') {
        const doEnd = confirm('Are you sure you want to go back?');
        if (doEnd) {
          sweatStart.endWorkout();
          sweatStart.reset();
          navigation.goBack();
        }
      } else {
        Alert.alert('Are you sure you want to go back?', undefined, [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Go Back',
            onPress: () => {
              sweatStart.endWorkout();
              sweatStart.reset();
              navigation.goBack();
            },
          },
        ]);
      }
    } else {
      sweatStart.reset();
      navigation.goBack();
    }
  }, [sweatStart.active]);

  useFocusEffect(
    useCallback(() => {
      sweat.fetchSweatProgramsByIds([`${id}`]);
    }, [id, sweat]),
  );

  useEffect(() => {
    if (program) {
      if (!program.userProgress?.program || program.userProgress.isComplete) {
        program.startProgram();
      } else if (!workout) {
        program.fetchProgramWorkouts();
      }
    }
  }, [program, workout]);

  useEffect(() => {
    if (workout && (!workout.steps || isNumber(workout.steps[0]))) {
      workout.fetch();
    }
  }, [workout]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar
                title={program?.name}
                titleOpacity={titleOpacity.current.interpolate({
                  inputRange: [0, config.titleDisplayOffsetOnScroll],
                  outputRange: [0, 1],
                })}
                withBackground={false}
                showBack={false}
                showClose
                onClose={goBack}
              />
            </Layout>
            <SweatStartWorkout
              loading={program?.loading || workout?.loading || false}
              program={program}
              workout={workout}
              titleOpacity={titleOpacity.current}
              isFte={isFte}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
});

export default observer(SweatStart);
