import Constants from 'expo-constants';
import config from 'o2x-store/src/config';

const getConfig = () => {
  const version = Constants.nativeAppVersion || '0.0.0';
  const build = Constants.nativeBuildVersion || '0';
  const updateBuild = '3934';
  const nativeConfig = {
    buildVersion: `v${version} build ${build} (${updateBuild})`,
    nativeStoreKey: `${config.storeKey}-native`,
    deepLinkPrefixes: [
      'https://o2x.com/',
      'https://app.o2x.com/',
      'https://dev.app.o2x.com/',
      'exps://o2x.com/',
      'exps://app.o2x.com/',
      'exps://dev.app.o2x.com/',
      'o2x://',
      'exp://127.0.0.1:19000/--/',
    ],
    deepLinkUrls: {
      eatView: 'l/eat/recipes/view/',
      events: 'l/events/join/',
      eventsView: 'l/events/view/',
      register: 'l/register/code/',
      sweatView: 'l/sweat/programs/view/',
      thriveView: 'l/thrive/programs/view/',
    },
    titleDisplayOffsetOnScroll: 160,
  };

  console.log({ nativeConfig });
  return {
    ...nativeConfig,
    ...config,
  };
};

export default getConfig();
