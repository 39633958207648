import { Input, StyleService, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useState } from 'react';
import CheckInput from './CheckInput';

type Props = {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: Array<string | number>;
  checkStyle?: any;
  optionStyle?: any;
  optionStyleSelected?: any;
  required: boolean;
  status?: string;
  fill?: string;
};

const OTHER_VALUE = 'other';
const OTHER_LABEL = 'Other';

const MultiSelectCheckInputOther: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    status = '',
    checkStyle,
    optionStyle,
    optionStyleSelected,
    initial,
    fill,
  } = props;

  let optionValues: Array<string | number> = [];
  let initialChecked: { [key: string]: string | number } = {};
  if (initial) {
    question.options.map((option) => {
      const value = option[0];
      const label = option[1];
      if (initial.includes(value)) {
        initialChecked[label] = value;
      } else {
        delete initialChecked[label];
      }
      optionValues.push(value);
    });
  }

  const [checked, setChecked] = useState<{ [key: string]: string | number }>(
    initialChecked,
  );

  let initialOtherValues: Array<string | number> = [];
  if (checked) {
    initialOtherValues = initial
      .split(',')
      .filter((i) => !optionValues.includes(i));
  }

  const [otherValue, setOtherValue] = useState<string | number>(
    initialOtherValues.join(','),
  );

  return (
    <>
      {question.options.map((option) => {
        const value = option[0];
        const label = option[1] as string;
        if (label === OTHER_LABEL) {
          return null;
        } else {
          return (
            <CheckInput
              key={label}
              field={label}
              label={label}
              initial={initial.includes(value)}
              status={status || 'basic-plain'}
              style={checkStyle}
              optionStyle={optionStyle}
              optionStyleSelected={optionStyleSelected}
              onChange={(k: string, v: boolean) => {
                setChecked((prev) => {
                  const next = { ...prev };
                  if (v) {
                    next[k] = value;
                  } else {
                    delete next[k];
                  }
                  onChange(question.key, Array.from(Object.values(next)));
                  return next;
                });
              }}
              fill={fill}
              labelStyle={styles.label}
            />
          );
        }
      })}
      <CheckInput
        key={OTHER_LABEL}
        field={OTHER_LABEL}
        label={OTHER_LABEL}
        initial={!!initial && initial.includes(OTHER_VALUE)}
        status={status || 'basic-plain'}
        style={checkStyle}
        optionStyle={optionStyle}
        optionStyleSelected={optionStyleSelected}
        onChange={(k: string, v: boolean) => {
          setChecked((prev) => {
            const next = { ...prev };
            if (v) {
              next[k] = OTHER_VALUE;
            } else {
              delete next[k];
              setOtherValue('');
            }
            onChange(question.key, Array.from(Object.values(next)));
            return next;
          });
        }}
        fill={fill}
        labelStyle={styles.label}
      />
      <Input
        style={styles.weightInput}
        value={`${otherValue}`}
        status={status}
        disabled={!!initial && !initial.includes(OTHER_VALUE)}
        onChangeText={(textValue) => {
          setOtherValue((prev) => {
            const currentChecked = Array.from(Object.values(checked));
            currentChecked.push(textValue);
            console.log('current checked', currentChecked);
            onChange(question.key, currentChecked);
            return textValue;
          });
        }}
        underlineColorAndroid="transparent"
        multiline
      />
    </>
  );
};

const themedStyles = StyleService.create({
  label: {
    fontSize: 18,
    lineHeight: 22,
  },
  weightInput: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
});

export default MultiSelectCheckInputOther;
