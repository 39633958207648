import { useNavigation } from '@react-navigation/native';
import { Icon, Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import React, { useCallback, useEffect } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import Rating from '../Rating';
import RatingBar from './RatingBar';

type Props = {
  style?: StyleProp<ViewStyle>;
  program: Program;
};

const getRateType = (programType: string) => {
  if (programType === 'eat') {
    return 'recipe';
  }
  if (programType === 'sweat') {
    return 'plan';
  }
  if (programType === 'thrive') {
    return 'plan';
  }
  return null;
};

const RatingDetail: React.FC<Props> = (props) => {
  const { style, program } = props;
  const { ratingsBreakdown } = program;
  const rating = get(ratingsBreakdown, 'averageRating', 0);
  const totalRatings = get(ratingsBreakdown, 'totalRatings', 0);
  const ratings = get(ratingsBreakdown, 'ratings');
  const ratingsKeys =
    ratings && Object.keys(ratings).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const maxRating = 5;
  const stars = Array.from(Array(maxRating), (_, i) => i + 1);

  const onPress = useCallback(() => {
    navigation.navigate('RatingModal', { type: program.type, id: program.id });
  }, []);

  useEffect(() => {
    program.fetchRatings();
  }, [program.id]);

  return (
    <Layout style={[styles.container, style]}>
      <TouchableOpacity onPress={onPress}>
        {program.userRating !== 0 ? (
          <>
            <Layout style={styles.ratingHeader}>
              <Text style={styles.headerLabel} category="h3">
                Your Rating
              </Text>
            </Layout>
            <View style={styles.ratingContainer}>
              {stars.map((index, rate: number) => (
                <Icon
                  key={index}
                  fill={program.userRating > rate ? theme.white : theme.black}
                  stroke={theme.gray}
                  strokeWidth={0.5}
                  width={20}
                  height={20}
                  name="star"
                />
              ))}
            </View>
          </>
        ) : (
          <Text style={[styles.ratingText, { color: program.color }]}>
            {`Rate this ${getRateType(program.type)}`}
          </Text>
        )}
      </TouchableOpacity>
      {totalRatings >= 10 && (
        <Layout style={styles.container}>
          <Layout style={styles.header}>
            <Text style={styles.headerLabel} category="h3">
              {`Ratings (${Humanize.compactInteger(totalRatings, 1)})`}
            </Text>
            <Rating rating={rating} />
          </Layout>
          {ratings && ratingsKeys && (
            <Layout>
              {ratingsKeys.map((key) => (
                <RatingBar
                  key={key}
                  style={styles.ratingBar}
                  rating={parseInt(key, 10)}
                  percentage={(ratings[key] / totalRatings) * 100}
                />
              ))}
            </Layout>
          )}
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {},
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 16,
  },
  headerLabel: {
    flex: 1,
  },
  ratingBar: {
    marginBottom: 4,
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingHeader: {
    marginBottom: 10,
  },

  ratingText: {
    fontSize: 16,
    lineHeight: 19,
    textDecorationLine: 'underline',
  },
});

export default observer(RatingDetail);
