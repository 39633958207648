import UserSweatWorkoutExerciseProgress from 'o2x-store/src/models/UserSweatWorkoutExerciseProgress';
import UserSweatWorkoutProgress from 'o2x-store/src/models/UserSweatWorkoutProgress';
import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import User, {
  UserDailyDebrief,
  UserDailyEatHabit,
  UserDailyThriveHabit,
  UserDebriefProfile,
  UserEatProfile,
  UserSweatProfile,
  UserThriveProfile,
} from '../../models/User';
import { callApiWithToken, prepareFileForUpload } from './base';

export const fetchUser = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.users}${id}/`, token, 'GET');

export const fetchMe = async (token: string) =>
  callApiWithToken(`${config.urls.users}me/`, token, 'GET');

export const updateMe = async (token: string, data: Partial<User>) =>
  callApiWithToken(
    `${config.urls.users}me/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateMePhoto = async (token: string, photo: any) => {
  const data = new FormData();
  const file = await prepareFileForUpload(photo);
  data.append('profile_image', file);
  return callApiWithToken(`${config.urls.users}me/`, token, 'PATCH', {}, data);
};

export const fetchSettings = async (token: string) =>
  callApiWithToken(`${config.urls.users}my_settings/`, token, 'GET');

export const updateSettings = async (token: string, data: Partial<User>) =>
  callApiWithToken(
    `${config.urls.users}my_settings/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchDailyDebrief = async (token: string) =>
  callApiWithToken(config.urls.dailyDebrief, token, 'GET');

export const createDailyDebrief = async (
  token: string,
  data: Partial<UserDailyDebrief>,
) =>
  callApiWithToken(
    config.urls.dailyDebrief,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateDailyDebrief = async (
  token: string,
  data: Partial<UserDailyDebrief>,
) =>
  callApiWithToken(
    config.urls.dailyDebrief + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchDebriefProfile = async (token: string) =>
  callApiWithToken(config.urls.debriefProfile, token, 'GET');

export const createDebriefProfile = async (
  token: string,
  data: Partial<UserDebriefProfile>,
) =>
  callApiWithToken(
    config.urls.debriefProfile,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateDebriefProfile = async (
  token: string,
  data: Partial<UserDebriefProfile>,
) =>
  callApiWithToken(
    config.urls.debriefProfile + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchEatProfile = async (token: string) =>
  callApiWithToken(config.urls.eatProfile, token, 'GET');

export const createEatProfile = async (
  token: string,
  data: Partial<UserEatProfile>,
) =>
  callApiWithToken(
    config.urls.eatProfile,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateEatProfile = async (
  token: string,
  data: Partial<UserEatProfile>,
) =>
  callApiWithToken(
    config.urls.eatProfile + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchDailyEatHabit = async (token: string) =>
  callApiWithToken(`${config.urls.dailyEatHabit}today/`, token, 'GET');

export const createDailyEatHabit = async (
  token: string,
  data: Partial<UserDailyEatHabit>,
) =>
  callApiWithToken(
    `${config.urls.dailyEatHabit}today/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateDailyEatHabit = async (
  token: string,
  data: Partial<UserDailyEatHabit>,
) =>
  callApiWithToken(
    `${config.urls.dailyEatHabit}today/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchDailyThriveHabit = async (token: string) =>
  callApiWithToken(`${config.urls.dailyThriveHabit}today/`, token, 'GET');

export const createDailyThriveHabit = async (
  token: string,
  data: Partial<UserDailyThriveHabit>,
) =>
  callApiWithToken(
    `${config.urls.dailyThriveHabit}today/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateDailyThriveHabit = async (
  token: string,
  data: Partial<UserDailyThriveHabit>,
) =>
  callApiWithToken(
    `${config.urls.dailyThriveHabit}today/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchSweatProfile = async (token: string) =>
  callApiWithToken(config.urls.sweatProfile, token, 'GET');

export const createSweatProfile = async (
  token: string,
  data: Partial<UserSweatProfile>,
) =>
  callApiWithToken(
    config.urls.sweatProfile,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateSweatProfile = async (
  token: string,
  data: Partial<UserSweatProfile>,
) =>
  callApiWithToken(
    config.urls.sweatProfile + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchThriveProfile = async (token: string) =>
  callApiWithToken(config.urls.thriveProfile, token, 'GET');

export const createThriveProfile = async (
  token: string,
  data: Partial<UserThriveProfile>,
) =>
  callApiWithToken(
    config.urls.thriveProfile,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateThriveProfile = async (
  token: string,
  data: Partial<UserThriveProfile>,
) =>
  callApiWithToken(
    config.urls.thriveProfile + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchHistory = async (
  token: string,
  dateStart: string,
  dateEnd: string,
) =>
  callApiWithToken(
    `${config.urls.users}my_history/?created_localdate__gte=${dateStart}&created_localdate__lte=${dateEnd}`,
    token,
    'GET',
  );

export const fetchSummary = async (
  token: string,
  dateStart: string,
  dateEnd: string,
) =>
  callApiWithToken(
    `${config.urls.users}my_summary/?created_localdate__gte=${dateStart}&created_localdate__lte=${dateEnd}`,
    token,
    'GET',
  );

export const fetchSweatWorkoutProgress = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.sweatWorkoutProgress}${id}/`, token, 'GET');

export const fetchSweatWorkoutProgresses = async (
  token: string,
  dateStart: string,
  dateEnd: string,
) =>
  callApiWithToken(
    `${config.urls.sweatWorkoutProgress}?created_localdate__gte=${dateStart}&created_localdate__lte=${dateEnd}`,
    token,
    'GET',
  );

export const fetchRecentSweatWorkoutProgress = async (token: string) =>
  callApiWithToken(
    `${config.urls.sweatWorkoutProgress}most_recent/`,
    token,
    'GET',
  );

export const updateSweatWorkoutProgress = async (
  token: string,
  data: Partial<UserSweatWorkoutProgress>,
) =>
  callApiWithToken(
    config.urls.sweatWorkoutProgress + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const updateSweatWorkoutExerciseProgress = async (
  token: string,
  data: Partial<UserSweatWorkoutExerciseProgress>,
) =>
  callApiWithToken(
    config.urls.sweatExerciseProgress + data.id + '/',
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const validatePurchaseIOS = async (token: string, receipt: string) =>
  callApiWithToken(
    config.urls.validatePurchaseIOS,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ receipt })),
  );

export const validatePurchaseAndroid = async (
  token: string,
  subscriptionId: string,
  purchaseToken: string,
) =>
  callApiWithToken(
    config.urls.validatePurchaseAndroid,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ subscriptionId, token: purchaseToken })),
  );

export const registerDevice = async (
  token: string,
  deviceId: string,
  deviceName: string,
  pushToken: string,
  expoPushToken: string,
  appVersion: string,
) =>
  callApiWithToken(
    config.urls.registerDevice,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        deviceId,
        deviceName,
        pushToken,
        expoPushToken,
        appVersion,
      }),
    ),
  );
