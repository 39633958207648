import Slider from '@react-native-community/slider';
import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  IndexPath,
  Layout,
  Select,
  SelectGroup,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { Audio } from 'expo-av';
import {
  INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  INTERRUPTION_MODE_IOS_DO_NOT_MIX,
} from 'expo-av/build/Audio';
import { useKeepAwake } from 'expo-keep-awake';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { THRIVE_MEDIA_FILE_TYPE } from 'o2x-store/src/models/ThriveMediaFile';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Dimensions,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from 'src/components/NavigationBar';
import Meditate from '../../assets/images/meditate.svg';
import ThriveMeditateVisuals from '../../components/ThriveMeditateVisuals';
import ThriveStartTimer from '../../components/ThriveStart/ThriveStartTimer';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveApp'>;
};

const ThriveMeditate: React.FC<Props> = (props) => {
  const { params } = props.route;
  const { initialDuration } = params || {};
  const navigation = useNavigation();
  const { thrive } = useStore();
  const { thriveStart, mediaStorage } = useNativeStore();
  thriveStart.setActivityType(THRIVE_ACTIVITY_TYPE.MEDITATE);
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  useKeepAwake();

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const files = Array.from(thrive.thriveMediaFiles.values());
  const audios = [
    {
      title: 'Guided Audio',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.AUDIO && f.inMeditate && f.isGuided),
        (d) => d.name,
      ),
    },
    {
      title: 'Audio',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.AUDIO && f.inMeditate && !f.isGuided),
        (d) => d.name,
      ),
    },
  ].filter((s) => s.data.length > 0);
  const visuals = [
    {
      title: 'Guided Videos',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.VIDEO && f.inMeditate && f.isGuided),
        (d) => d.name,
      ),
    },
    {
      title: 'Videos',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.VIDEO && f.inMeditate && !f.isGuided),
        (d) => d.name,
      ),
    },
    {
      title: 'Images',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.IMAGE && f.inMeditate),
        (d) => d.name,
      ),
    },
  ].filter((s) => s.data.length > 0);

  useEffect(() => {
    analytics.logThriveActivityOpen(THRIVE_ACTIVITY_TYPE.MEDITATE);
  }, []);

  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [volume, setVolume] = useState(1);
  const [selectedAudio, setSelectedAudio] = useState(new IndexPath(0, 0));
  const [selectedVisual, setSelectedVisual] = useState(new IndexPath(0, 0));
  const [allowed, setAllowed] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);

  const goBack = useCallback(() => {
    if (thriveStart.playing) {
      if (Platform.OS === 'web') {
        const doEnd = confirm('Are you sure you want to go back?');
        if (doEnd) {
          thriveStart.stop();
          navigation.goBack();
        }
      } else {
        Alert.alert('Are you sure you want to go back?', undefined, [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Go Back',
            onPress: () => {
              thriveStart.stop();
              navigation.goBack();
            },
          },
        ]);
      }
    } else {
      navigation.goBack();
    }
  }, [thriveStart.playing]);

  const onSelectAudio = useCallback(
    (index) => {
      setSelectedAudio(index);
      thriveStart.setAudio(audios[index.section!]?.data[index.row]);
    },
    [thriveStart],
  );

  const onSelectVideo = useCallback(
    (index) => {
      setSelectedVisual(index);
      thriveStart.setVideo(visuals[index.section!]?.data[index.row]);
    },
    [thriveStart],
  );

  const onChangeVolume = useCallback(
    (volume) => {
      setVolume(volume);
      thriveStart.setVolume(volume);
    },
    [thriveStart],
  );

  useEffect(() => {
    if (isMobile) {
      setWidth(width * 0.9 - 30); // 90% modal width - (15 padding * 2)
    } else if (isDeviceMaxWidth600) {
      setWidth(width * 0.7 - 60); // 70% modal width - (30 padding * 2)
    } else if (isDeviceMaxWidth768) {
      setWidth(width * 0.7 - 100); // 70% modal width - (50 padding * 2)
    }

    if (audios.length > 0) {
      const defaultAudio = initialDuration ? audios[1].data[0] : audios[0].data[0];
      const mediaFile = mediaStorage.mediaFiles.get(mediaStorage._getKey(defaultAudio));
      if (mediaFile && mediaFile?.downloaded) {
        setSelectedAudio(initialDuration ? new IndexPath(0, 1) : new IndexPath(0, 0));
        thriveStart.setAudio(defaultAudio);
      } else {
        thriveStart.setAudio(null);
        setSelectedAudio(undefined);
      }
    }
  }, []);

  useEffect(() => {
    if (visuals.length > 0) {
      if (visuals[0]?.data.length > 0) {
        const defaultVideo = visuals[0]?.data[0];
        const mediaFile = mediaStorage.mediaFiles.get(mediaStorage._getKey(defaultVideo));
        if (mediaFile && mediaFile?.downloaded) {
          setSelectedVisual(new IndexPath(0, 0));
          thriveStart.setVideo(defaultVideo);
        } else {
          setSelectedVisual(undefined);
          thriveStart.setVideo(null);
        }
      }
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
        allowsRecordingIOS: false,
        staysActiveInBackground: false,
        interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        shouldDuckAndroid: true,
        interruptionModeAndroid: INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false,
      });
    }, [allowed]),
  );

  const onTimerPlay = useCallback(() => {
    setStartTime((prevStartTime) => prevStartTime || moment().toDate());
  }, [setStartTime]);

  useFocusEffect(
    useCallback(() => {
      const logActivity = () => {
        if (startTime !== null) {
          thrive.logThriveActivity(THRIVE_ACTIVITY_TYPE.MEDITATE, startTime, moment().toDate());
        }
      };
      return logActivity;
    }, [startTime]),
  );

  useEffect(() => {
    /* Only needed if recording audio
    (async () => {
      let permission: PermissionResponse = await Audio.getPermissionsAsync();
      if (
        permission.status !== Audio.PermissionStatus.GRANTED &&
        permission.canAskAgain
      ) {
        permission = await Audio.requestPermissionsAsync();
      }
      if (permission.status === Audio.PermissionStatus.GRANTED) {
        setAllowed(true);
      }
    })();
    */
    thrive.fetchThriveMediaFiles();
    thriveStart.setVolume(1);
    if (!initialDuration) {
      thriveStart.setDefaultTime(10 * 60); // 10 minutes.
    } else if (initialDuration !== 'infinity') {
      thriveStart.setDefaultTime(initialDuration);
    } else if (initialDuration === 'infinity') {
      thriveStart.setDefaultTime(Infinity);
    }
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar onClose={goBack} showClose showBack={false} withBackground={false} />
            </Layout>
            <ScrollView
              contentContainerStyle={isDeviceMaxWidth768 ? styles.contentMobile : styles.content}>
              <View
                style={
                  isMobile
                    ? styles.contentSectionMobile
                    : isDeviceMaxWidth600
                    ? styles.contentSectionMaxWidth600
                    : isDeviceMaxWidth768
                    ? styles.contentSectionMaxWidth768
                    : !thriveStart.playing && !thriveStart.paused
                    ? styles.contentSectionNoVisuals
                    : styles.contentSection
                }>
                {!isDeviceMaxWidth768 && (thriveStart.playing || thriveStart.paused) && (
                  <ThriveMeditateVisuals
                    style={styles.videoWrapper}
                    object={thriveStart.videoObject}
                    size={Math.min((width - 240) * 0.8, 800) / 2}
                  />
                )}
                <Layout
                  style={
                    isDeviceMaxWidth768
                      ? styles.meditateControllsMobile
                      : !thriveStart.playing && !thriveStart.paused
                      ? styles.meditateControllsNoVisuals
                      : styles.meditateControlls
                  }>
                  <Layout style={styles.headerContainer}>
                    <Meditate style={styles.icon} />
                    <Text style={styles.header} category="h2">
                      Meditate
                    </Text>
                  </Layout>
                  {isDeviceMaxWidth768 && (thriveStart.playing || thriveStart.paused) && (
                    <ThriveMeditateVisuals
                      style={styles.videoWrapper}
                      object={thriveStart.videoObject}
                      size={width}
                    />
                  )}

                  {audios.length > 0 && (
                    <>
                      <Text style={styles.label} category="c2">
                        Sound
                      </Text>
                      {/* @ts-ignore */}
                      <Select
                        placeholder="Select an option"
                        style={styles.select}
                        value={thriveStart.audioObject?.name}
                        selectedIndex={selectedAudio}
                        onSelect={onSelectAudio}>
                        {audios.map((s) => (
                          <SelectGroup key={s.title} title={s.title}>
                            {s.data.map((f) => (
                              <SelectItem key={`${f.id}`} title={f.name} />
                            ))}
                          </SelectGroup>
                        ))}
                      </Select>
                    </>
                  )}
                  {visuals.length > 0 && (
                    <>
                      <Text style={styles.label} category="c2">
                        Visual
                      </Text>
                      {/* @ts-ignore */}
                      <Select
                        placeholder="Select an option"
                        style={styles.select}
                        value={thriveStart.videoObject?.name}
                        selectedIndex={selectedVisual}
                        onSelect={onSelectVideo}>
                        {visuals.map((s) => (
                          <SelectGroup key={s.title} title={s.title}>
                            {s.data.map((f) => (
                              <SelectItem key={`${f.id}`} title={f.name} />
                            ))}
                          </SelectGroup>
                        ))}
                      </Select>
                    </>
                  )}
                  <Text style={styles.label} category="c2">
                    Volume
                  </Text>
                  <Slider
                    style={styles.slider}
                    minimumValue={0}
                    minimumTrackTintColor={theme.orange}
                    maximumValue={1}
                    maximumTrackTintColor={theme.gray}
                    thumbTintColor={theme.white}
                    value={volume}
                    onSlidingComplete={onChangeVolume}
                  />
                </Layout>
              </View>
            </ScrollView>
            <ThriveStartTimer
              activity={THRIVE_ACTIVITY.MEDITATE}
              addTime={15 * 60}
              isMeditate={!!thriveStart.video}
              onTimerPlay={onTimerPlay}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  meditateControlls: {
    backgroundColor: 'transparent',
  },
  meditateControllsNoVisuals: {
    backgroundColor: 'transparent',
    width: '50%',
  },
  meditateControllsMobile: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  modalMobile: {
    width: '90%',
    height: '95%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '95%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  contentMobile: {
    flex: 1,
    alignItems: 'center',
  },
  contentSection: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentSectionNoVisuals: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentSectionMobile: {
    width: '100%',
    paddingHorizontal: 15,
  },
  contentSectionMaxWidth600: {
    width: '100%',
    paddingHorizontal: 30,
  },
  contentSectionMaxWidth768: {
    width: '100%',
    paddingHorizontal: 50,
  },
  contentSectionPadded: {
    paddingHorizontal: '25%',
  },
  contentSectionPaddedMobile: {
    paddingHorizontal: '5%',
  },
  icon: {
    marginRight: 16,
  },
  header: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  label: {
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  select: {
    marginBottom: 24,
  },
  slider: {
    marginBottom: 12,
  },
  videoWrapper: {
    // marginLeft: -24, // offset left padding.
    marginBottom: 16,
  },
});

export default observer(ThriveMeditate);
