import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {};

const SettingsContainer: React.FC<Props> = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });

  return (
    <Layout style={styles.container}>
      <Layout
        style={
          isTabletOrMobile
            ? styles.settingsContainerTabletOrMobile
            : styles.settingsContainer
        }
      >
        {children}
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  settingsContainer: {
    flex: 1,
    marginHorizontal: 25,
    marginTop: 20,
    marginBottom: 25,
    padding: 45,
    backgroundColor: '#1A3248',
  },
  settingsContainerTabletOrMobile: {
    flex: 1,
    margin: 15,
  },
});

export default observer(SettingsContainer);
