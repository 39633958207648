import { useNavigation } from '@react-navigation/core';
import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Recipe from 'o2x-store/src/models/Recipe';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import EatIcon from '../../assets/images/eat-icon.svg';
import Rating from '../Rating';

type Props = {
  item: Recipe | any;
};

const FTERecipeItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { item } = props;
  const { name, servings, rating, image } = item;

  const color = getTaskColor(TASK_TYPE.EAT);

  const onOpen = useCallback(() => {
    navigation.navigate('EatStart', { id: item.id });
  }, []);

  const onSave = useCallback(() => {}, []);

  return (
    <TouchableOpacity
      onPress={onOpen}
      style={[styles.container, { borderColor: color }]}
    >
      {image ? (
        <Image
          style={styles.thumbnail as StyleProp<ImageStyle>}
          source={{ uri: image }}
        />
      ) : (
        <View style={styles.taskIconContainer}>
          <EatIcon height={40} width={40} />
        </View>
      )}
      <View style={styles.content}>
        <Text numberOfLines={2}>{name}</Text>
        {!!servings && (
          <Text style={[styles.subtitle, { color }]} category="c2">
            {servings}
          </Text>
        )}
      </View>
      {false && (
        <TouchableOpacity style={styles.bookmarkButton} onPress={onSave}>
          <Icon
            style={styles.bookmark}
            fill="white"
            name={true ? 'bookmark-outline' : 'bookmark'}
          />
        </TouchableOpacity>
      )}
      {rating >= 0 && (
        <View style={styles.rating}>
          <Rating rating={rating} size={8} />
        </View>
      )}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 80,
    flexDirection: 'row',
    borderLeftWidth: 4,
    marginHorizontal: 24,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 12,
  },
  subtitle: {
    marginTop: 4,
  },
  thumbnail: {
    height: 80,
    width: 80,
  },
  taskIconContainer: {
    height: 80,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 4,
    bottom: 4,
    justifyContent: 'center',
  },
  bookmark: {
    width: 22,
    height: 22,
  },
  bookmarkButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default FTERecipeItem;
