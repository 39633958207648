import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import CheckBox from '../../components/CheckBox';

interface Props {
  onChange: (k: string, v: any) => void;
  initial: boolean;
  field: string;
  label?: string;
  children?: any;
  style?: any;
  optionStyle?: any;
  optionStyleSelected?: any;
  status?: string;
  fill?: string;
  labelStyle?: any;
}

const CheckInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    onChange,
    initial,
    field,
    label,
    style,
    status = '',
    optionStyle,
    optionStyleSelected,
    fill = 'white',
    labelStyle,
  } = props;

  const [checked, setChecked] = useState(initial);

  const onPress = useCallback(() => {
    setChecked(!checked);
    onChange(field, !checked);
  }, [field, checked]);

  return (
    <CheckBox
      status={status}
      checked={checked}
      style={[
        styles.container,
        styles.option,
        style,
        optionStyle,
        checked && styles.optionSelected,
        checked && optionStyleSelected,
      ]}
      fill={fill}
      key={field}
      field={field}
      onChange={onPress}
      label={label}
      labelStyle={labelStyle}
    />
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
  },
  weightInput: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
  label: {
    color: '#C4C4C4',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  optionGroup: {
    width: '100%',
  },
  option: {
    width: '100%',
    backgroundColor: 'dark-blue',
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginBottom: 8,
  },
  optionSelected: {},
  optionText: {
    color: 'dark-blue',
    fontSize: 18,
    lineHeight: 22,
  },
  optionTextSelected: {
    color: 'green',
    fontSize: 18,
    lineHeight: 22,
  },
});

export default CheckInput;
