import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ScrollView } from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {};

const HpaChartInfo: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout style={styles.body}>
      <ScrollView>
        <Text
          style={isDeviceMaxWidth768 ? styles.headerMaxWidth768 : styles.header}
        >
          How to use this assessment
        </Text>
        <Layout style={styles.content}>
          <Text
            style={
              isDeviceMaxWidth768
                ? styles.rankingTextMaxWidth768
                : styles.rankingText
            }
          >
            Ranking
          </Text>
          <Text
            style={isDeviceMaxWidth768 ? styles.textMaxWidth768 : styles.text}
          >
            The goal of the rankings are to help you understand where you are
            today, so you can take small steps towards being 1% better tomorrow.
            Consider this your baseline score. At the end of this report you
            will find customized recommendations based on your responses.
          </Text>
          <Layout
            style={
              isDeviceMaxWidth600
                ? styles.rankingContainerMaxWidth600
                : styles.rankingContainer
            }
          >
            <Layout
              style={[
                isDeviceMaxWidth600
                  ? styles.rankingCardMaxWidth600
                  : styles.rankingCard,
                { backgroundColor: '#C64141' },
              ]}
            >
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.rankingMaxWidth768
                    : styles.ranking
                }
              >
                Yikes!
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.scoreRangeMaxWidth768
                    : styles.scoreRange
                }
              >
                0-49
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768 ? styles.rankMaxWidth768 : styles.rank
                }
              >
                You’ve got some work to do, remember you can go far by taking it
                one step at a time.
              </Text>
            </Layout>
            <Layout
              style={[
                isDeviceMaxWidth600
                  ? styles.rankingCardMaxWidth600
                  : styles.rankingCard,
                { backgroundColor: '#CFAC42' },
              ]}
            >
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.rankingMaxWidth768
                    : styles.ranking
                }
              >
                STANDARD
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.scoreRangeMaxWidth768
                    : styles.scoreRange
                }
              >
                50-74
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768 ? styles.rankMaxWidth768 : styles.rank
                }
              >
                Life is about more than just getting by. Start pushing the
                needle towards excellence.
              </Text>
            </Layout>
            <Layout
              style={[
                isDeviceMaxWidth600
                  ? styles.rankingCardMaxWidth600
                  : styles.rankingCard,
                { backgroundColor: '#4E7B89' },
              ]}
            >
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.rankingMaxWidth768
                    : styles.ranking
                }
              >
                OUTSTANDING
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.scoreRangeMaxWidth768
                    : styles.scoreRange
                }
              >
                75-89
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768 ? styles.rankMaxWidth768 : styles.rank
                }
              >
                At this stage, small tweaks to your daily routine separate you
                from elite performers.
              </Text>
            </Layout>
            <Layout
              style={[
                isDeviceMaxWidth600
                  ? styles.rankingCardMaxWidth600
                  : styles.rankingCard,
                { backgroundColor: '#767F6A' },
              ]}
            >
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.rankingMaxWidth768
                    : styles.ranking
                }
              >
                ELITE
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768
                    ? styles.scoreRangeMaxWidth768
                    : styles.scoreRange
                }
              >
                90+
              </Text>
              <Text
                style={
                  isDeviceMaxWidth768 ? styles.rankMaxWidth768 : styles.rank
                }
              >
                Consistency is king. You’ve reached elite status, so let’s make
                sure you maintain it.
              </Text>
            </Layout>
          </Layout>
        </Layout>
      </ScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
  },
  header: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  headerMaxWidth768: {
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    alignSelf: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  rankingText: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginVertical: 20,
  },
  rankingTextMaxWidth768: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginVertical: 15,
  },
  text: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
  },
  textMaxWidth768: {
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: '400',
  },
  rank: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textAlign: 'center',
  },
  rankMaxWidth768: {
    fontSize: 13,
    letterSpacing: 1,
    fontWeight: '400',
    textAlign: 'center',
  },
  rankingContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'transparent',
    marginTop: 20,
  },
  rankingContainerMaxWidth600: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: 'transparent',
    marginTop: 20,
    flexWrap: 'wrap',
  },
  rankingCard: {
    width: '23.5%',
    backgroundColor: 'red',
    alignItems: 'center',
    padding: 15,
  },
  rankingCardMaxWidth600: {
    width: '48%',
    backgroundColor: 'red',
    alignItems: 'center',
    padding: 15,
    marginTop: 10,
  },
  ranking: {
    fontSize: 20,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  rankingMaxWidth768: {
    fontSize: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  scoreRange: {
    fontSize: 32,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginVertical: 15,
  },
  scoreRangeMaxWidth768: {
    fontSize: 24,
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    marginVertical: 10,
  },
});

export default observer(HpaChartInfo);
