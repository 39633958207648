import { useNavigation } from '@react-navigation/core';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import { SectionList, View } from 'react-native';
import DLPreviewItem from 'src/components/Downloaded/DLPreviewItem';
import { DOWNLOAD_TYPE } from 'src/models/DownloadedList';
import { useNativeStore } from 'src/stores';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
};

const DownloadedPreview: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { downloads } = useNativeStore();

  const { id, type, downloadType } = props.route.params;

  const color = getTaskColor(type);

  const program =
    downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM
      ? downloads.sweatPrograms.get(`${id}`)
      : downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM
      ? downloads.thrivePrograms.get(`${id}`)
      : null;

  const programPreview =
    downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM
      ? program?.sweatPreview
      : downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM
      ? program?.thrivePreview
      : null;

  const onPress = useCallback(
    (data) => {
      if (!programPreview) return;
      const programTitle = programPreview.name;

      if (downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
        navigation.navigate('DownloadedThriveProgram', {
          ...data,
          programId: id,
          programTitle,
        });
      } else if (downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
        navigation.navigate('DownloadedSweatProgram', {
          ...data,
          programId: id,
          programTitle,
        });
      }
    },
    [programPreview],
  );

  const renderItem = useCallback(
    ({ item, section }) => (
      <DLPreviewItem color={color} item={item} title={section?.title} onPress={onPress} />
    ),
    [],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => <Text style={styles.sectionTitle}>{section.title}</Text>,
    [],
  );

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const keyExtractor = useCallback(
    (item) =>
      downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM ? item.workoutId.toString() : item.id.toString(),
    [],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  if (!programPreview) return null;

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav style={styles.navigationStyle} onBack={goBack} showClose={false} />
      </View>
      <View style={styles.header}>
        <Text category="h2">{programPreview.name}</Text>
      </View>
      <View style={styles.content}>
        <SectionList
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
          ListFooterComponent={renderFooter}
          sections={programPreview.preview as any}
          stickySectionHeadersEnabled={false}
          keyExtractor={keyExtractor}
        />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  content: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigationStyle: {
    backgroundColor: 'transparent',
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  scroll: {
    flex: 1,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 20,
    textTransform: 'uppercase',
    paddingHorizontal: 26,
  },
  footer: {
    height: 30,
  },
});

export default DownloadedPreview;
