import {
  CheckBox,
  IndexPath,
  Layout,
  Select,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { FTEOrganizationModel, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import { RootStore } from 'o2x-store/src/stores';
import React from 'react';
import { Image, ImageStyle, StyleProp } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

type Props = {
  fteOrganizations: FTEOrganizationModel[] | undefined;
  store: RootStore;
  groups: OSSGroup[];
  query: string;
  selectedGroup: OSSGroup | undefined;
  setSelectedGroup: Function;
  onCheckOrg: Function;
  onCheckUser: Function;
  addedUsers: number[];
};

const SelectUserList: React.FC<Props> = (props) => {
  const {
    fteOrganizations,
    store,
    query,
    groups,
    selectedGroup,
    setSelectedGroup,
    onCheckOrg,
    onCheckUser,
    addedUsers,
  } = props;
  const styles = useStyleSheet(themedStyles);

  return (
    <ScrollView>
      {fteOrganizations
        ?.filter((org) =>
          query.trim().length > 0
            ? org.users.length > 0 &&
              org.users.filter(
                (user) =>
                  (user.id !== store?.auth.user?.id &&
                    (user.email.toLowerCase().includes(query.trim().toLowerCase()) ||
                      user.fullName.toLowerCase().includes(query.trim().toLowerCase()) ||
                      user.o2XId.includes(query.trim()))) ||
                  user.promoCode?.toLowerCase().includes(query.trim().toLowerCase()) ||
                  store.fteLibrary.ossGroups
                    .filter(
                      (group) =>
                        group.groupName !== 'All' && group.groupName.includes(query.trim()),
                    )
                    .some((group) => group.users.includes(user.id)),
              ).length > 0
            : org.users.length > 0,
        )
        .map((org, index) => (
          <Layout style={styles.invContainer} key={index}>
            <Layout style={styles.orgContainer}>
              <Image
                style={styles.profile as StyleProp<ImageStyle>}
                source={
                  org.logo ? { uri: org.logo } : require('../../assets/images/user_placeholder.png')
                }
              />
              <Text style={[styles.orgText, { maxWidth: '20%' }]} numberOfLines={2}>
                {selectedGroup ? selectedGroup.groupName : org.name}
              </Text>
              <Select
                size="small"
                style={styles.select}
                value={
                  selectedGroup
                    ? store.fteLibrary.getOOSGroupById(selectedGroup.id).groupName
                    : 'All'
                }
                onSelect={(index: IndexPath | IndexPath[]) => {
                  setSelectedGroup(index.row ? groups[index.row] : undefined);
                }}>
                {groups.map((group, index) => (
                  <SelectItem key={index} title={group.groupName} />
                ))}
              </Select>
              <CheckBox
                style={styles.addInvite}
                onChange={(checked) =>
                  selectedGroup
                    ? onCheckOrg(
                        checked,
                        org.users.filter((user) => selectedGroup.users.includes(user.id)),
                      )
                    : onCheckOrg(checked, org.users)
                }
                checked={
                  selectedGroup
                    ? org.users
                        .filter((user) => selectedGroup.users.includes(user.id))
                        .filter((user) => user.id !== store.auth.user?.id)
                        .map((user) => user.id)
                        .every((item) => addedUsers?.includes(item))
                    : org.users
                        .filter((user) => user.id !== store.auth.user?.id)
                        .map((user) => user.id)
                        .every((item) => addedUsers?.includes(item))
                }
              />
            </Layout>
            <ScrollView style={styles.userContainer}>
              {org.users
                .filter((user) => (selectedGroup ? selectedGroup?.users.includes(user.id) : user))
                .filter((user) =>
                  query.trim().length > 0
                    ? (user.id !== store?.auth.user?.id &&
                        (user.email.toLowerCase().includes(query.trim().toLowerCase()) ||
                          user.fullName.toLowerCase().includes(query.trim().toLowerCase()) ||
                          user.o2XId.includes(query.trim()))) ||
                      user.promoCode?.toLowerCase().includes(query.trim().toLowerCase()) ||
                      store.fteLibrary.ossGroups
                        .filter(
                          (group) =>
                            group.groupName !== 'All' && group.groupName.includes(query.trim()),
                        )
                        .some((group) => group.users.includes(user.id))
                    : user.id !== store?.auth.user?.id,
                )
                .map((user, index) => (
                  <Layout style={styles.userContent} key={index}>
                    <Image
                      style={styles.userProfile as StyleProp<ImageStyle>}
                      source={
                        user.profileImage
                          ? { uri: user.profileImage }
                          : require('../../assets/images/user_placeholder.png')
                      }
                    />
                    <Text style={styles.orgText}>{user.fullName ? user.fullName : user.email}</Text>
                    <CheckBox
                      style={styles.addInvite}
                      onChange={(checked) => onCheckUser(checked, user)}
                      checked={addedUsers?.includes(user.id)}
                    />
                  </Layout>
                ))}
            </ScrollView>
          </Layout>
        ))}
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  addInvite: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 15,
  },
  orgText: {
    opacity: 0.6,
    fontSize: 14,
    alignSelf: 'center',
  },
  profile: {
    width: 40,
    height: 40,
    alignSelf: 'center',
    borderRadius: 20,
    marginHorizontal: 10,
  },
  userContainer: {
    width: '100%',
    marginBottom: 15,
    backgroundColor: '#091C2D',
    maxHeight: 250,
  },
  userContent: {
    width: '100%',
    borderBottom: '1px solid  #1A3248',
    height: 40,
    flexDirection: 'row',
  },
  userProfile: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    borderRadius: 15,
    marginLeft: 60,
    marginRight: 10,
  },
  invContainer: {
    marginLeft: '5%',
    width: '90%',
    backgroundColor: 'transparent',
  },
  orgContainer: {
    marginTop: 15,
    width: '100%',
    height: 50,
    backgroundColor: '#203950',
    flexDirection: 'row',
  },
  select: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 10,
    borderColor: 'transparent',
    width: '40%',
  },
});

export default React.memo(SelectUserList, (prevProps, nextProps) => {
  if (
    prevProps.addedUsers !== nextProps.addedUsers ||
    prevProps.query !== nextProps.query ||
    prevProps.query.length !== nextProps.query.length ||
    prevProps.selectedGroup !== nextProps.selectedGroup
  ) {
    return false;
  }
  return true;
});
