import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import DrawerContent from '../../../components/Drawer';
import AskAnExpertStack from '../stacks/AskAnExpertStack';
import AssessmentStack from '../stacks/AssessmentStack';
import EventStack from '../stacks/EventStack';
import ExploreStack from '../stacks/ExploreStack';
import FTEAdminStack from '../stacks/FTEAdminStack';
import JoinOrganizationStack from '../stacks/JoinOrganizationStack';
import OrganizationStack from '../stacks/OrganizationStack';
import ResourceStack from '../stacks/ResourceStack';
import SavedStack from '../stacks/SavedStack';
import SettingsStack from '../stacks/SettingsStack';
import TodayStack from '../stacks/TodayStack';

type Props = {};

const ShopStack: React.FC<Props> = () => <Layout />;

const Drawer = createDrawerNavigator();

const HomeTab: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  return (
    <Drawer.Navigator
      openByDefault={!isTabletOrMobile}
      drawerType={isTabletOrMobile ? 'front' : 'permanent'}
      overlayColor={isTabletOrMobile ? '#000' : 'transparent'}
      initialRouteName="Today"
      drawerContent={(props: DrawerContentComponentProps) => <DrawerContent {...props} />}
      drawerStyle={styles.drawer}>
      <Drawer.Screen name="Today" component={TodayStack} />

      {/* FTE Admin Page */}
      <Drawer.Screen name="FTEAdmin" component={FTEAdminStack} />
      {/* Must keep name `ExploreStack` */}
      <Drawer.Screen name="ExploreStack" component={ExploreStack} />
      <Drawer.Screen name="SavedStack" component={SavedStack} />
      <Drawer.Screen name="Plan" component={AssessmentStack} />
      <Drawer.Screen name="AskAnExpertDrawer" component={AskAnExpertStack} />
      <Drawer.Screen name="Events" component={EventStack} />
      <Drawer.Screen name="Organization" component={OrganizationStack} />
      <Drawer.Screen name="Resources" component={ResourceStack} />
      {/* <Drawer.Screen name="Shop" component={ShopStack} /> */}
      {/* <Drawer.Screen name="Teams" component={TeamStack} /> */}
      <Drawer.Screen name="Settings" component={SettingsStack} />
      <Drawer.Screen name="JoinOrganization" component={JoinOrganizationStack} />
    </Drawer.Navigator>
  );
};

const themedStyles = StyleService.create({
  drawer: {
    backgroundColor: '#10283E',
    width: 240,
    borderRightWidth: 0,
    shadowColor: '#000000',
    shadowOpacity: 0.4,
    shadowRadius: 10,
  },
});

export default HomeTab;
