import * as Permissions from 'expo-permissions';

export const askPermissions = async (
  permissions: Permissions.PermissionType[],
  onSuccess: () => any,
  onFailure: () => any,
) => {
  try {
    let finalStatus = '';

    const { status: currentStatus } = await Permissions.getAsync(
      ...permissions,
    );
    finalStatus = currentStatus;

    if (currentStatus !== 'granted') {
      const { status } = await Permissions.askAsync(...permissions);
      finalStatus = status;
    }

    if (finalStatus === 'granted') {
      onSuccess();
      return true;
    }

    onFailure();
    return false;
  } catch (error) {
    console.warn('[ERROR] ask permissions', error);
    onFailure();
    return false;
  }
};

export const checkPermissions = async (
  permissions: Permissions.PermissionType[],
  onSuccess: () => any,
  onFailure: () => any,
) => {
  try {
    const { status } = await Permissions.getAsync(...permissions);
    if (status === 'granted') {
      onSuccess();
      return true;
    }

    onFailure();
    return false;
  } catch (error) {
    console.warn('[ERROR] check permissions', error);
    onFailure();
    return false;
  }
};
