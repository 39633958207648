import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import PopupContainer from '../models/Tutorial';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/TutorialStore')
export default class TutorialStore extends Model({
  tutorials: prop_mapObject(() => new Map<string, PopupContainer>()),
}) {
  @observable
  loading = false;

  @modelFlow
  markPopupAsSeen = _async(function* (this: TutorialStore, popupId: number, forLater = false) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let response;
    try {
      ({ response } = yield* _await(api.markPopupAsSeen(rootStore.auth.token, popupId, forLater)));
    } catch (error) {
      console.warn('[DEBUG] error fetching tutorials', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deletePopupContainer = _async(function* (this: TutorialStore, component: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      this.tutorials.delete(component);
    } catch (error) {
      console.warn('[DEBUG] error deleting popup container', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deletePopupContainers = _async(function* (this: TutorialStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      this.tutorials.clear();
    } catch (error) {
      console.warn('[DEBUG] error deleting popup containers', error);
      return getError(error);
    }

    this.loading = false;
    console.log('[TUTORIALS] Deleted all popup containers.');
    return getSuccess();
  });

  @modelFlow
  fetchTutorialsForToday = _async(function* (this: TutorialStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTutorialsForToday(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching tutorials', error);
      return getError(error);
    }

    (entities.results || []).forEach((popupData: ModelInstanceCreationData<PopupContainer>) => {
      const component = `${popupData.component}`;
      if (this.tutorials.has(component)) {
        this.tutorials.set(component, new PopupContainer(popupData));
        console.log(`[TUTORIALS] Updated popup ${component}.`);
      } else {
        const popup = new PopupContainer(popupData);
        this.tutorials.set(component, popup);
        console.log(`[TUTORIALS] Saved new popup ${component}.`);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  resetTutorial = _async(function* (this: TutorialStore) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let response;
    try {
      ({ response } = yield* _await(api.resetTutorial(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error resetting tutorials', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
