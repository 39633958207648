import { IndexPath, Select, SelectItem, StyleService, useStyleSheet } from '@ui-kitten/components';
import { indexOf } from 'lodash';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | Array<number> | null;
  required: boolean;
  status?: string;
  multi?: boolean;
}

const SelectInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { question, onChange, initial, status = 'basic-plain', multi = false } = props;

  let initialIndex: Array<number> | IndexPath | null = null;
  if (multi) {
    initialIndex = [];
  }

  if (initial) {
    if (!multi) {
      question.options.forEach((option: Array<number | string>, index: number) => {
        if (option[0] === initial) {
          initialIndex = new IndexPath(index);
        }
      });
    } else {
      question.options.forEach((option: Array<number | string>, index: number) => {
        if (indexOf(initial, option[0]) > -1) {
          initialIndex.push(new IndexPath(index));
        }
      });
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  const getDisplayValue = useCallback(() => {
    if (!multi) {
      return selectedIndex ? question.options[Number(`${selectedIndex}`) - 1][1] : '';
    }
    const values: string[] = [];
    (selectedIndex || []).forEach((index: IndexPath) => {
      values.push(question.options[Number(`${index}`) - 1][1]);
    });
    if (values.length > 0) {
      return values.join(', ');
    }
    return '';
  }, [selectedIndex, question]);

  const onSelect = useCallback(
    (index) => {
      setSelectedIndex(index);
      if (!multi) {
        onChange(question.key, question.options[Number(`${index}`) - 1][0]);
      } else {
        const values = index.map((idx: IndexPath) => question.options[Number(`${idx}`) - 1][0]);
        onChange(question.key, values);
      }
    },
    [selectedIndex, question],
  );

  const isSelected = useCallback(
    (index: number) => {
      if (!multi) {
        return selectedIndex && `${selectedIndex}` === `${index}`;
      }
      const selectedIndices = (selectedIndex || []).map((idx: IndexPath) => `${idx}`);
      return selectedIndex && indexOf(selectedIndices, `${index}`) > -1;
    },
    [selectedIndex],
  );

  return (
    <View style={styles.container}>
      <Select
        status={status}
        multiSelect={multi}
        style={styles.optionGroup}
        selectedIndex={selectedIndex}
        value={getDisplayValue()}
        onSelect={onSelect}>
        {question.options.map((option: any, index: number) => (
          <SelectItem
            style={isSelected(index) ? styles.optionSelected : styles.option}
            key={`option-${option[0]}`}
            title={option[1]}
          />
        ))}
      </Select>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  weightInput: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
  label: {
    color: '#C4C4C4',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  optionGroup: {
    width: '100%',
  },
  option: {
    width: '100%',
    backgroundColor: 'dark-blue',
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginBottom: 0,
  },
  optionSelected: {
    width: '100%',
    backgroundColor: 'dark-blue',
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginBottom: 0,
  },
  optionText: {
    color: 'dark-blue',
    fontSize: 18,
    lineHeight: 22,
  },
  optionTextSelected: {
    color: 'green',
    fontSize: 18,
    lineHeight: 22,
  },
});

export default SelectInput;
