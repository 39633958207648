import { Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import UserSweatWorkoutProgress from 'o2x-store/src/models/UserSweatWorkoutProgress';
import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import {
  FTESweatWorkout,
  FTESweatWorkoutStepExercise,
} from '../../../../o2x-store/src/models/FTELibrary';
import WorkoutStep from './WorkoutStep';

type Props = {
  day: FTESweatWorkout | undefined;
  onClose: () => void;
  onPressExercise: (exercise: FTESweatWorkoutStepExercise) => void;
  exercisesUserNotes: Map<number, string> | undefined;
  workoutMap: Map<number, UserSweatWorkoutProgress> | undefined;
  exercisesWeightInputs: Map<number, string[]> | undefined;
};

const EditsAndComments: React.FC<Props> = ({
  day,
  onClose,
  onPressExercise,
  exercisesUserNotes,
  workoutMap,
  exercisesWeightInputs,
}) => {
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });
  return (
    <Layout style={styles.container}>
      <Layout style={isMobile ? styles.mobileBg : styles.bgContainer}>
        <Layout style={styles.header}>
          <Text style={styles.headerText}>
            Day
            {day ? day.day : ''}
          </Text>

          <TouchableOpacity onPress={onClose} style={[styles.close, { marginLeft: 'auto' }]}>
            <Icon name="close-outline" fill="white" style={styles.icon} />
          </TouchableOpacity>
        </Layout>

        <Layout style={styles.contentContainer}>
          <ScrollView contentContainerStyle={{ width: '100%' }}>
            <ScrollView horizontal contentContainerStyle={{ width: '100%' }}>
              <Layout
                style={{
                  width: '95%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}>
                {day &&
                  day.steps &&
                  day.steps.map((step, index) => (
                    <WorkoutStep
                      day={day}
                      step={step}
                      exercisesUserNotes={exercisesUserNotes}
                      index={index}
                      key={index.toString()}
                      onPressExercise={onPressExercise}
                      workoutMap={workoutMap}
                      exercisesWeightInputs={exercisesWeightInputs}
                    />
                  ))}
              </Layout>
            </ScrollView>
          </ScrollView>
        </Layout>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '90%',
    height: '90%',
    marginLeft: '4%',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileBg: {
    position: 'absolute',
    width: '87%',
    height: '90%',
    left: '26px',
    right: '26px',
    paddingRight: 10,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '93%',
    height: '87%',
    alignSelf: 'center',
    marginBottom: '40px',
  },
  header: {
    width: '92%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
    paddingTop: '15px',
  },
  headerText: {
    color: 'white',
    fontSize: 24,
    marginLeft: 'auto',
  },
  close: {
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

export default observer(EditsAndComments);
