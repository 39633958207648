import { useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

export const useAppStateChange = (callback?: () => void) => {
  const [appState, setAppState] = useState<AppStateStatus>();

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    setAppState(nextAppState);
    if (callback) callback();
  };

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  return appState;
};
