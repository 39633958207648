export const mapping = {
  strict: {
    'text-font-family': 'Lato',
    'text-heading-1-font-size': 32,
    'text-heading-1-line-height': 38,
    'text-heading-1-font-weight': '700',
    'text-heading-1-font-family': 'Lato-Bold',
    'text-heading-2-font-size': 24,
    'text-heading-2-line-height': 29,
    'text-heading-2-font-weight': '700',
    'text-heading-2-font-family': 'Lato-Bold',
    'text-heading-3-font-size': 18,
    'text-heading-3-line-height': 22,
    'text-heading-3-font-weight': '700',
    'text-heading-3-font-family': 'Lato-Bold',
    'text-paragraph-1-font-size': 18,
    'text-paragraph-1-line-height': 22,
    'text-paragraph-1-font-weight': '400',
    'text-paragraph-2-font-size': 16,
    'text-paragraph-2-line-height': 19,
    'text-paragraph-2-font-weight': '400',
    'text-caption-1-font-size': 14,
    'text-caption-1-line-height': 17,
    'text-caption-1-font-weight': '400',
    'text-caption-2-font-size': 12,
    'text-caption-2-line-height': 14,
    'text-caption-2-font-weight': '400',
    'text-caption-font-family': 'Lato-Bold',
    'text-subtitle-1-font-size': 12,
    'text-subtitle-1-line-height': 14,
    'text-subtitle-1-font-weight': '700',
    'text-subtitle-font-family': 'Lato-Bold',
    'text-subtitle-2-font-size': 10,
    'text-subtitle-2-line-height': 12,
    'text-subtitle-2-font-weight': '700',
    'text-label-font-size': 14,
    'text-label-line-height': 17,
    'text-label-font-weight': '700',
    'text-label-font-family': 'Lato-Bold',
  },
  components: {
    CheckBox: {
      meta: {
        appearances: {},
        states: {},
        parameters: {},
        variantGroups: {
          status: {
            'basic-eat': {
              default: true,
            },
            'basic-sweat': {
              default: true,
            },
            'basic-thrive': {
              default: true,
            },
            'basic-plain': {
              default: true,
            },
          },
        },
      },
      appearances: {
        default: {
          variantGroups: {
            status: {
              'basic-eat': {
                state: {
                  checked: {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  focused: {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  'checked.focused': {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  indeterminate: {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  active: {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                  'checked.active': {
                    borderColor: 'green',
                    backgroundColor: 'green',
                  },
                },
              },
              'basic-sweat': {
                state: {
                  checked: {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                  focused: {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                  'checked.focused': {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                  indeterminate: {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                  active: {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                  'checked.active': {
                    borderColor: 'cyan',
                    backgroundColor: 'cyan',
                  },
                },
              },
              'basic-thrive': {
                state: {
                  checked: {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                  focused: {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                  'checked.focused': {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                  indeterminate: {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                  active: {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                  'checked.active': {
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                  },
                },
              },
              'basic-plain': {
                state: {
                  checked: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  focused: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  'checked.focused': {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  indeterminate: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  active: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  'checked.active': {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                },
              },
            },
          },
        },
      },
    },
    Radio: {
      meta: {
        appearances: {},
        states: {},
        parameters: {},
        variantGroups: {
          status: {
            'basic-eat': {
              default: false,
            },
            'basic-sweat': {
              default: false,
            },
            'basic-thrive': {
              default: false,
            },
            'basic-plain': {
              default: true,
            },
          },
        },
      },
      appearances: {
        default: {
          mapping: {
            textFontSize: 18,
          },
          variantGroups: {
            status: {
              'basic-eat': {
                state: {
                  checked: {
                    borderColor: 'green',
                    backgroundColor: 'transparent',
                    iconTintColor: 'green',
                    textColor: 'green',
                  },
                  'checked.focused': {
                    borderColor: 'green',
                    backgroundColor: 'transparent',
                    iconTintColor: 'green',
                    textColor: 'green',
                  },
                  focused: {
                    borderColor: 'green',
                    backgroundColor: 'transparent',
                    iconTintColor: 'green',
                  },
                  active: {
                    borderColor: 'green',
                    backgroundColor: 'transparent',
                    iconTintColor: 'green',
                  },
                  'checked.active': {
                    borderColor: 'green',
                    backgroundColor: 'transparent',
                    iconTintColor: 'green',
                  },
                },
              },
              'basic-sweat': {
                state: {
                  checked: {
                    borderColor: 'cyan',
                    backgroundColor: 'transparent',
                    iconTintColor: 'cyan',
                    textColor: 'cyan',
                  },
                  'checked.focused': {
                    borderColor: 'cyan',
                    backgroundColor: 'transparent',
                    iconTintColor: 'cyan',
                    textColor: 'cyan',
                  },
                  focused: {
                    borderColor: 'cyan',
                    backgroundColor: 'transparent',
                    iconTintColor: 'cyan',
                  },
                  active: {
                    borderColor: 'cyan',
                    backgroundColor: 'transparent',
                    iconTintColor: 'cyan',
                  },
                  'checked.active': {
                    borderColor: 'cyan',
                    backgroundColor: 'transparent',
                    iconTintColor: 'cyan',
                  },
                },
              },
              'basic-thrive': {
                state: {
                  checked: {
                    borderColor: 'orange',
                    backgroundColor: 'transparent',
                    iconTintColor: 'orange',
                    textColor: 'orange',
                  },
                  'checked.focused': {
                    borderColor: 'orange',
                    backgroundColor: 'transparent',
                    iconTintColor: 'orange',
                    textColor: 'orange',
                  },
                  focused: {
                    borderColor: 'orange',
                    backgroundColor: 'transparent',
                    iconTintColor: 'orange',
                  },
                  active: {
                    borderColor: 'orange',
                    backgroundColor: 'transparent',
                    iconTintColor: 'orange',
                  },
                  'checked.active': {
                    borderColor: 'orange',
                    backgroundColor: 'transparent',
                    iconTintColor: 'orange',
                  },
                },
              },
              'basic-plain': {
                state: {
                  checked: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  focused: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  'checked.focused': {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  indeterminate: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  active: {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                  'checked.active': {
                    borderColor: 'white',
                    backgroundColor: 'white',
                    iconTintColor: '#000000',
                  },
                },
              },
            },
          },
        },
      },
    },
    Select: {
      meta: {
        variantGroups: {
          status: {
            'basic-plain': {
              default: true,
            },
            'basic-sweat': {
              default: true,
            },
          },
        },
      },
      appearances: {
        variantGroups: {
          'basic-plain': {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            borderRadius: 0,
            state: {
              focused: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
              hover: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
              active: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
              disabled: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
    },
    Input: {
      meta: {
        variantGroups: {
          status: {
            'basic-plain': {
              default: false,
            },
            basic: {
              default: true,
            },
          },
        },
      },
      appearances: {
        variantGroups: {
          status: {
            'basic-plain': {
              borderColor: 'border-basic-color-4',
              backgroundColor: 'background-basic-color-2',
              textColor: 'text-basic-color',
              labelColor: 'text-hint-color',
              captionColor: 'text-hint-color',
              placeholderColor: 'text-hint-color',
              iconTintColor: 'text-hint-color',
              captionIconTintColor: 'text-hint-color',
              state: {
                focused: {
                  borderColor: 'color-primary-default',
                  backgroundColor: 'background-basic-color-1',
                  iconTintColor: 'text-primary-color',
                },
                hover: {
                  borderColor: 'border-basic-color-4',
                  backgroundColor: 'background-basic-color-3',
                },
                disabled: {
                  borderColor: 'border-basic-color-4',
                  backgroundColor: 'background-basic-color-2',
                  textColor: 'text-disabled-color',
                  labelColor: 'text-disabled-color',
                  captionColor: 'text-disabled-color',
                  placeholderColor: 'text-disabled-color',
                  iconTintColor: 'text-disabled-color',
                  captionIconTintColor: 'text-disabled-color',
                },
              },
            },
          },
        },
      },
    },
  },
};
