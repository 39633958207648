import { isDate, isString, map, snakeCase } from 'lodash';
import moment from 'moment';
import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { EventCheckin, EventSurvey, FTEEventModel } from '../../models/Event';
import { callApiWithToken } from './base';

export const fetchEvents = async (token: string) =>
  callApiWithToken(config.urls.events, token, 'GET', {}, undefined, true);

export const fetchEventsByRange = async (
  token: string,
  dateStart?: Date,
  dateEnd?: Date,
  q?: string,
) => {
  const qs = map({ dateStart, dateEnd, q }, (value: Date | undefined | string, key: string) =>
    isDate(value)
      ? `${snakeCase(key)}=${moment(value).format('YYYY-MM-DD')}`
      : isString(value)
      ? `${snakeCase(key)}=${value}`
      : '',
  ).join('&');
  return callApiWithToken(
    `${config.urls.events}by_range/?${qs}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );
};

export const fetchEventsByIds = async (token: string, ids: string) =>
  callApiWithToken(`${config.urls.events}?id__in=${ids}`, token, 'GET', {}, undefined, true);

export const joinEvent = async (token: string, data: { inviteCode: string }) =>
  callApiWithToken(
    `${config.urls.events}join/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createCheckin = async (token: string, eventId: string, data: Partial<EventCheckin>) =>
  callApiWithToken(
    `${config.urls.events}${eventId}/checkins/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateCheckin = async (token: string, eventId: string, data: Partial<EventCheckin>) =>
  callApiWithToken(
    `${config.urls.events}${eventId}/checkins/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createEventSurvey = async (
  token: string,
  eventId: string,
  data: Partial<EventSurvey>,
) =>
  callApiWithToken(
    `${config.urls.events}${eventId}/survey/today/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const fetchFTEEvents = async (
  token: string,
  id: number,
  month?: number,
  year?: number,
  limit?: number,
  url?: string,
) =>
  limit
    ? callApiWithToken(
        `${config.urls.fteevents}?limit=${limit}&fte=${id}&month=${month}&year=${year}`,
        token,
        'GET',
      )
    : callApiWithToken(
        url || `${config.urls.fteevents}?fte=${id}&month=${month}&year=${year}`,
        token,
        'GET',
      );

export const fetchPendingInvites = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.fteevents}my_pending_invites/?fte=${id}`, token, 'GET');

export const createFTEEvent = async (token: string, data: FTEEventModel) =>
  callApiWithToken(
    config.urls.fteevents,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFTEEvent = async (token: string, id: number, all: boolean) =>
  callApiWithToken(`${config.urls.fteevents}${id}/${all ? 'delete_all/' : ''}`, token, 'DELETE');

export const updateFTEEvent = async (token: string, data: Partial<FTEEventModel>, all: boolean) =>
  callApiWithToken(
    `${config.urls.fteevents}${data.id}/${all ? 'update_all/' : ''}`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const sendNotification = async (
  token: string,
  data: {
    recipientUsers: string;
    title: string;
    body: string;
  },
) =>
  callApiWithToken(
    `${config.urls.sendNotifications}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );
