import { useNavigation } from '@react-navigation/core';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  onOpen?: () => void;
  hidden?: boolean;
};

const DLBottomBar: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  const { onOpen, hidden } = props;

  const onBack = useCallback(async () => {
    navigation.goBack();
  }, []);

  if (hidden) return null;

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      <TouchableOpacity style={styles.backButton} onPress={onBack}>
        <Text style={styles.label} category="c1">
          BACK
        </Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.openButton} onPress={onOpen}>
        <Text style={styles.label} category="c1">
          OPEN
        </Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  backButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'dark-blue',
  },
  container: {
    flexDirection: 'row',
  },
  label: {
    textAlign: 'center',
  },
  openButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'olive',
  },
});

export default DLBottomBar;
