import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from '../../components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';

type Props = {};

const EatProfile: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    store.user.fetchEatProfile();
    store.question.fetchEatProfileQuestions();
  }, []);

  const onBegin = useCallback(() => {
    navigation.navigate('EatProfileQuestion');
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <Layout style={styles.navigationContainer}>
              <TopNav showBack={false} showClose onClose={goBack} />
            </Layout>
            <Layout
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <Text style={styles.title}>Start tracking your EAT habits</Text>
              <Text style={styles.description}>
                We'll help you track your daily intake to build a healthy routine and maximize your
                performance.
              </Text>
              <Text style={styles.description}>
                To start, answer a few questions to establish your baseline.
              </Text>
            </Layout>
            <BottomNav
              onBack={() => navigation.goBack()}
              onNext={onBegin}
              nextLabel="Begin"
              nextColor={theme.olive}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: 20,
    paddingHorizontal: '25%',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 20,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
    justifyContent: 'flex-start',
    padding: 20,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 32,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export default observer(EatProfile);
