import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';

export type InputQuestionProp = {
  question: string;
  type: string;
  key: string;
  options: (string | number)[][];
  label?: string;
  allowSkip?: boolean;

  // For slider inputs.
  minimumValue?: number;
  minimumValueLabel?: string;
  maximumValue?: number;
  maximumValueLabel?: string;
  step?: number;
};

@model('o2x-store/Question')
export default class Question extends Model({
  question: prop<string>(''),
  type: prop<string>(''),
  key: prop<string>(''),
  options: prop<Array<Array<string | number>>>(),
  label: prop<string>(''),
  required: prop<boolean>(true),
  minimumValue: prop<number>(1),
  maximumValue: prop<number>(10),
  minimumValueLabel: prop<string>('1'),
  maximumValueLabel: prop<string>('10'),
  step: prop<number>(1),
  allowSkip: prop<boolean>(false),
}) {
  getRefId = () => `${this.key}`;

  @modelAction
  update(data: ModelInstanceCreationData<Question>) {
    Object.assign(this, data);
  }
}
