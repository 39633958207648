import { useNavigation } from '@react-navigation/native';
import { Icon, Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import * as WebBrowser from 'expo-web-browser';
import ThriveProgramExercise, { CTA_CHOICES } from 'o2x-store/src/models/ThriveProgramExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import YoutubePlayer from 'react-native-youtube-iframe';
import { useNativeStore } from '../../stores';
import VideoPlayer from '../VideoPlayer';

type Props = {
  item: ThriveProgramExercise;
  onExpand: (isExpanded: boolean) => void;
};

const ThriveStartItem: React.FC<Props> = (props) => {
  const { item, onExpand } = props;
  const { mediaStorage } = useNativeStore();
  const { thrive } = useStore();
  const exercise = thrive.thriveExercises.get(`${item.exercise}`);
  const exerciseVideoFile = useMemo(
    () => exercise && mediaStorage.getOrCreateMediaFile(exercise, 'video'),
    [exercise],
  );
  const exerciseYoutubeId = exercise?.videoId;
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const [expand, setExpand] = useState(false);
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const [localUri, setLocalUri] = useState<string | null>(null);

  const onLayout = useCallback((event) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const toggleExpand = useCallback(() => {
    setExpand((expand) => {
      if (onExpand) {
        onExpand(!expand);
      }
      return !expand;
    });
  }, [onExpand]);

  const openExercise = useCallback(() => {
    if (item.cta === CTA_CHOICES.BREATHE) {
      navigation.navigate('ThriveBreathe', {
        initialDuration: item.ctaDuration,
      });
    } else if (item.cta === CTA_CHOICES.MEDITATE) {
      navigation.navigate('ThriveMeditate', {
        initialDuration: item.ctaDuration,
      });
    } else if (item.cta === CTA_CHOICES.SLEEP) {
      navigation.navigate('ThriveSleep', { initialDuration: item.ctaDuration });
    } else if (item.cta === CTA_CHOICES.DOWNLOAD) {
      WebBrowser.openBrowserAsync(item.ctaFile!);
    }
  }, [item]);

  let subscribed = true;

  useEffect(() => {
    (async () => {
      if (exerciseVideoFile && (!exerciseVideoFile.loading || Platform.OS === 'web')) {
        const localUri =
          Platform.OS !== 'web' ? await exerciseVideoFile.downloadMedia() : exerciseVideoFile.uri;
        if (localUri && subscribed) {
          setLocalUri(localUri);
        }
      }
    })();
    return () => {
      subscribed = false;
    };
  }, [exerciseVideoFile, exerciseVideoFile?.loading, subscribed, setLocalUri]);

  return (
    <>
      <TouchableWithoutFeedback onPress={toggleExpand}>
        <Layout style={styles.container}>
          <Text style={styles.instructions}>{exercise ? exercise.name : item.title}</Text>
          {!!item.label && !expand && (
            <Layout style={styles.labelContainer}>
              <Text style={styles.label} category="c2">
                {item.label}
              </Text>
            </Layout>
          )}
          <View style={styles.spacer} />
          <Icon
            fill={theme.white}
            width={24}
            height={24}
            name={expand ? 'chevron-up' : 'chevron-down'}
          />
        </Layout>
      </TouchableWithoutFeedback>
      {expand && (
        <Layout style={styles.expanded} onLayout={onLayout}>
          <View style={styles.expandedContent}>
            <Text category="c1">{item.instructions}</Text>
            {!!item.detailLabel && (
              <TouchableOpacity style={styles.actionButton} onPress={openExercise}>
                <Text style={styles.action} category="label">
                  {item.detailLabel}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {(!!exerciseVideoFile && (
            <VideoPlayer
              videoLink={localUri}
              size={Math.min((width - 240) * 0.8, 800)}
              minimizeMaximize={false}
            />
          )) ||
            (!!exerciseYoutubeId && (
              <YoutubePlayer
                width={width}
                height={(width / 4) * 3}
                videoId={exerciseYoutubeId}
                initialPlayerParams={{
                  loop: true,
                  modestbranding: true,
                  rel: false,
                }}
                webViewProps={{ scrollEnabled: false }}
                play
              />
            ))}
        </Layout>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
    marginHorizontal: 12,
    height: 40,
    backgroundColor: 'olive',
  },
  action: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderLeftWidth: 4,
    borderColor: 'orange',
    backgroundColor: 'dark-blue',
  },
  instructions: {
    marginRight: 12,
  },
  expanded: {
    backgroundColor: 'dark-blue',
  },
  expandedContent: {
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  label: {
    fontFamily: 'Lato-Bold',
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  labelContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'olive',
    paddingHorizontal: 12,
    height: 25,
  },
  spacer: {
    flex: 1,
  },
});

export default ThriveStartItem;
