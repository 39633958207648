import moment from 'moment';

export const getTimeDisplay = (time: number = 0): string => {
  const duration = moment.duration(Math.max(time, 0), 'seconds');
  const display = [];

  const hours = duration.hours();
  if (hours > 0) {
    display.push(String(hours).padStart(2, '0'));
  }

  const minutes = duration.minutes();
  if (minutes >= 0) {
    display.push(String(minutes).padStart(2, '0'));
  }

  const seconds = duration.seconds();
  if (seconds >= 0) {
    display.push(String(seconds).padStart(2, '0'));
  }

  return display.join(':');
};

export const getHMSTimeDisplay = (time: number = 0): string => {
  const duration = moment.duration(Math.max(time, 0), 'seconds');
  const display = [];

  const hours = duration.hours();
  display.push(String(hours).padStart(2, '0'));

  const minutes = duration.minutes();
  display.push(String(minutes).padStart(2, '0'));

  const seconds = duration.seconds();
  display.push(String(seconds).padStart(2, '0'));

  return display.join(':');
};

export const getTimeFromString = (input: string): number => {
  const splitTime = input.split(':');
  const totalTime =
    (Number(splitTime[0]) | 0) * 3600 +
    (Number(splitTime[1]) | 0) * 60 +
    (Number(splitTime[2]) | 0);
  return totalTime;
};

export const getTimeHumanDisplay = (time: number = 0): string => {
  const duration = moment.duration(time, 'seconds');
  const display = [];

  const hours = duration.hours();
  if (hours > 0) {
    display.push(`${hours} hour${hours === 1 ? '' : 's'}`);
  }

  const minutes = duration.minutes();
  if (minutes > 0) {
    display.push(`${minutes} minute${minutes === 1 ? '' : 's'}`);
  }

  const seconds = duration.seconds();
  if (seconds > 0) {
    display.push(`${seconds} second${seconds === 1 ? '' : 's'}`);
  }

  return display.join(' and ');
};
