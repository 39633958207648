import { Icon, Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TopNav from 'src/components/Question/TopNav';
import { getErrors } from 'src/utils/errors';

type Props = {
  route: any;
};

const { height, width } = Dimensions.get('window');
const FteEventDetails: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const { item, selectedFte, checkPendingInvites } = props.route.params;
  const [guests, setGuests] = useState<number[]>(item.guests);
  const [guestDetails, setGuestDetails] = useState<User[]>(item.guestDetails);
  const [pending, setPending] = useState<number[]>(item.pending);
  const [pendingDetails, setPendingDetails] = useState<User[]>(item.pendingDetails);
  const [declined, setDeclined] = useState<number[]>(item.declined);
  const [declinedDetails, setDeclinedDetails] = useState<User[]>(item.declinedDetails);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [resultError, setResultError] = useState('');

  const store = useStore();

  const animation = useRef(new Animated.Value(0)).current;

  const onPressYes = useCallback(async () => {
    const guestsArr = [...guests, store.auth.user.id];
    const guestDetailsArr = [...guestDetails, store.auth.user!];
    const pendingArr = [...pending];
    const pendingDetailsArr = [...pendingDetails];
    const declinedArr = [...declined];
    const declinedDetailsArr = [...declinedDetails];

    if (pending.includes(store.auth.user?.id)) {
      const index = pending.indexOf(store.auth.user?.id);
      pendingArr.splice(index, 1);
      pendingDetailsArr.splice(index, 1);
      setPending(pendingArr);
      setPendingDetails(pendingDetailsArr);
    }
    if (declined.includes(store.auth.user?.id)) {
      const index = declined.indexOf(store.auth.user?.id);
      declinedArr.splice(index, 1);
      declinedDetailsArr.splice(index, 1);
      setDeclined(declinedArr);
      setDeclinedDetails(declinedDetailsArr);
    }
    const data = {
      id: item.id,
      guests: guestsArr,
      pending: pendingArr,
      declined: declinedArr,
    };
    setLoading(true);
    const result = await store.fteEvent.updateFTEEvent(data, true);
    checkPendingInvites(selectedFte);

    if (result.ok) {
      setGuests([...guestsArr]);
      setGuestDetails([...guestDetailsArr]);
    } else {
      setResultError(getErrors(result.errors));
    }
    setHasError(!result?.ok);
    Animated.timing(animation, {
      toValue: 3,
      duration: 2000,
      useNativeDriver: false,
    }).start(() => animation.setValue(0));
    setLoading(false);
  }, [
    item,
    guests,
    guestDetails,
    store.auth.user,
    pending,
    pendingDetails,
    declined,
    declinedDetails,
  ]);

  const onPressNo = useCallback(async () => {
    const declinedArr = [...declined, store.auth.user.id];
    const declinedDetailsArr = [...declinedDetails, store.auth.user!];
    const pendingArr = [...pending];
    const pendingDetailsArr = [...pendingDetails];
    const guestsArr = [...guests];
    const guestDetailsArr = [...guestDetails];

    if (pending.includes(store.auth.user?.id)) {
      const index = pending.indexOf(store.auth.user?.id);
      pendingArr.splice(index, 1);
      pendingDetailsArr.splice(index, 1);
      setPending(pendingArr);
      setPendingDetails(pendingDetailsArr);
    }
    if (guests.includes(store.auth.user?.id)) {
      const index = declined.indexOf(store.auth.user?.id);
      guestsArr.splice(index, 1);
      guestDetailsArr.splice(index, 1);
      setGuests(guestsArr);
      setGuestDetails(guestDetailsArr);
    }
    const data = {
      id: item.id,
      guests: guestsArr,
      pending: pendingArr,
      declined: declinedArr,
    };
    setLoading(true);
    const result = await store.fteEvent.updateFTEEvent(data, true);
    checkPendingInvites(selectedFte);

    if (result.ok) {
      setDeclined([...declinedArr]);
      setDeclinedDetails([...declinedDetailsArr]);
    } else {
      console.log(getErrors(result.errors));
    }
    setLoading(false);
  }, [
    item,
    guests,
    guestDetails,
    pending,
    pendingDetails,
    declined,
    declinedDetails,
    store.auth.user,
  ]);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const frequencies: { [key: string]: string } = {
    '': 'Does not repeat',
    DAILY: 'Repeats Every Day',
    WEEKLY: 'Repeats Every Week',
    MONTHLY: 'Repeats Every Month',
    YEARLY: 'Repeats Every Year',
  };

  return (
    <Layout style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Schedule</Text>
      </View>
      <View style={styles.detailContainer}>
        <Icon name="checkmark-square-2-outline" style={styles.icon} fill="#4E7B89" />
        <Text style={[styles.detailText, { textTransform: 'uppercase' }]}>{item.eventName}</Text>
      </View>
      <View style={styles.detailContainer}>
        <Icon name="calendar-outline" style={styles.icon} fill="#4E7B89" />
        <Text style={styles.detailText}>
          {format(parseISO(item.date), 'MMMM dd, yyyy')}{' '}
          <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
            {item.recurring
              ? `${frequencies[item.rrule]} until ${format(
                  parseISO(item.endRecurringDate),
                  'MMMM dd, yyyy',
                )}`
              : ''}
          </Text>
        </Text>
      </View>
      <View style={styles.detailContainer}>
        <Icon name="clock-outline" style={styles.icon} fill="#4E7B89" />
        <Text style={styles.detailText}>
          {format(new Date(`${new Date().toDateString()} ${item.timeStart}`), 'HH:mm a')} -{' '}
          {format(new Date(`${new Date().toDateString()} ${item.timeEnd}`), 'HH:mm a')}
        </Text>
      </View>
      {!!item.location && (
        <View style={styles.detailContainer}>
          <Icon name="pin-outline" style={styles.icon} fill="#4E7B89" />
          <Text style={styles.detailText}>{item.location}</Text>
        </View>
      )}
      {!!item.link && (
        <View style={[styles.detailContainer, { width: '100%' }]}>
          <Icon name="link-2-outline" style={styles.icon} fill="#4E7B89" />
          <Hyperlink linkDefault>
            <Text style={[styles.detailText, { alignSelf: 'flex-start', width: width * 0.8 }]}>
              {item.link}
            </Text>
          </Hyperlink>
        </View>
      )}
      <View style={styles.detailContainer}>
        <Icon name="people-outline" style={styles.icon} fill="#4E7B89" />
        <Text style={styles.detailText}>
          {`Guests ${
            item.maxNumberOfGuests ? `(max participants: ${item.maxNumberOfGuests})` : ''
          }`}
        </Text>
      </View>
      <View style={styles.guestView}>
        <ScrollView>
          <View style={styles.guestContainer}>
            <Image
              style={styles.profile as StyleProp<ImageStyle>}
              source={
                selectedFte.profileImage
                  ? { uri: selectedFte.profileImage }
                  : require('../../assets/images/user_placeholder.png')
              }
            />
            <View style={styles.guestDetails}>
              <Text style={styles.guestText}>
                {selectedFte.fullName ? selectedFte.fullName : selectedFte.email}
              </Text>
              <Text style={[styles.guestText, { fontSize: 14, opacity: 0.6 }]}>Organizer</Text>
            </View>
          </View>
          {guestDetails.length > 0 && <Text style={styles.detailText}>Going</Text>}
          {guestDetails.map((guest, index) => (
            <View style={styles.guestContainer} key={index}>
              <Image
                style={styles.profile as StyleProp<ImageStyle>}
                source={
                  guest.profileImage
                    ? { uri: guest.profileImage }
                    : require('../../assets/images/user_placeholder.png')
                }
              />
              <View style={styles.guestDetails}>
                <Text style={styles.guestText}>
                  {guest.fullName ? guest.fullName : guest.email}
                </Text>
              </View>
            </View>
          ))}
          {pendingDetails.length > 0 && <Text style={styles.detailText}>Pending Invites</Text>}
          {pendingDetails.map((guest, index) => (
            <View style={styles.guestContainer} key={index}>
              <Image
                style={styles.profile as StyleProp<ImageStyle>}
                source={
                  guest.profileImage
                    ? { uri: guest.profileImage }
                    : require('../../assets/images/user_placeholder.png')
                }
              />
              <View style={styles.guestDetails}>
                <Text style={styles.guestText}>
                  {guest.fullName ? guest.fullName : guest.email}
                </Text>
              </View>
            </View>
          ))}
          {declinedDetails.length > 0 && <Text style={styles.detailText}>Declined</Text>}
          {declinedDetails.map((guest, index) => (
            <View style={styles.guestContainer} key={index}>
              <Image
                style={styles.profile as StyleProp<ImageStyle>}
                source={
                  guest.profileImage
                    ? { uri: guest.profileImage }
                    : require('../../assets/images/user_placeholder.png')
                }
              />
              <View style={styles.guestDetails}>
                <Text style={styles.guestText}>
                  {guest.fullName ? guest.fullName : guest.email}
                </Text>
              </View>
            </View>
          ))}
        </ScrollView>
      </View>

      {(item.guests.includes(store.auth.user?.id!)
        ? true
        : item.pending.includes(store.auth.user?.id!)
        ? true
        : item.declined.includes(store.auth.user?.id!)
        ? true
        : item.maxNumberOfGuests
        ? item.guests.length < item.maxNumberOfGuests
        : !!item.allowGuests &&
          !item.guests.length &&
          !item.pending.length &&
          !item.declined.length &&
          selectedFte.id !== store.auth.user?.id) && (
        <View style={styles.joinContainer}>
          <Text style={styles.joinText}>Join this Event?</Text>
          {loading ? (
            <View style={styles.loading}>
              <Spinner />
            </View>
          ) : (
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                style={[
                  styles.noButton,
                  declined.includes(store.auth.user?.id!) && styles.disabled,
                ]}
                disabled={declined.includes(store.auth.user?.id!)}
                onPress={onPressNo}>
                <Text style={styles.joinText}>No</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.yesButton, guests.includes(store.auth.user?.id!) && styles.disabled]}
                disabled={guests.includes(store.auth.user?.id!)}
                onPress={onPressYes}>
                <Text style={styles.joinText}>Yes</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      <View style={styles.navigationContainer}>
        <TopNav showClose={false} style={styles.topNav} />
      </View>
      <View style={styles.center}>
        <Animated.View
          style={[styles.popUpContainer, { opacity, transform: [{ translateY }, { scale }] }]}>
          <Icon
            style={styles.savedIcon}
            name={hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'}
            fill={hasError ? 'black' : 'olive'}
          />
          <Text style={styles.saveText}>{hasError ? resultError : 'JOINED'}</Text>
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 22,
    alignSelf: 'center',
  },
  detailContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    flexDirection: 'row',
  },
  detailText: {
    width: '80%',
    fontSize: 16,
    color: 'white',
    alignSelf: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    marginHorizontal: 10,
  },
  guestView: {
    maxHeight: height / 2,
  },
  guestContainer: {
    flexDirection: 'row',
    marginHorizontal: 40,
    marginVertical: 5,
  },
  guestDetails: {
    width: '100%',
    alignSelf: 'center',
  },
  guestText: {
    width: '80%',
    fontSize: 16,
    color: 'white',
  },
  profile: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  joinContainer: {
    marginVertical: 20,
    marginHorizontal: 20,
    flexDirection: 'row',
  },
  joinText: {
    fontSize: 16,
    color: 'white',
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  noButton: {
    marginHorizontal: 10,
    height: 30,
    width: 50,
    borderWidth: 2,
    borderColor: 'olive',
  },
  yesButton: {
    height: 30,
    width: 50,
    backgroundColor: 'olive',
  },
  loading: {
    marginHorizontal: 10,
    height: 30,
    width: 50,
  },
  disabled: {
    opacity: 0.2,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    height: 200,
    width: 200,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
});

export default observer(FteEventDetails);
