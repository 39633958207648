import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import UserSweatWorkoutExerciseProgress from 'o2x-store/src/models/UserSweatWorkoutExerciseProgress';
import React, { useCallback, useState } from 'react';
import { Image, ImageStyle, ScrollView, StyleProp, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import {
  FTESweatProgram,
  FTESweatWorkout,
  FTESweatWorkoutStepExercise,
} from '../../../../o2x-store/src/models/FTELibrary';
import User from '../../../../o2x-store/src/models/User';
import UserSweatWorkoutProgress from '../../../../o2x-store/src/models/UserSweatWorkoutProgress';
import { useStore } from '../../../../o2x-store/src/stores';
import EditsAndComments from './EditsAndComments';
import ExerciseHistory from './ExerciseHistory';
import ProgramDetail from './ProgramDetail';

type Props = {
  user: User | undefined;
  onClose: () => void;
};

const UserProgramsAndExercises: React.FC<Props> = ({ user, onClose }) => {
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const [programList, setProgramList] = useState<FTESweatProgram[]>();
  const [query, setQuery] = useState('');
  const [exerciseList, setExerciseList] = useState<FTESweatWorkoutStepExercise[]>([]);
  const [programsProgress, setProgramsProgress] = useState<UserSweatWorkoutProgress[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState<string>('UserProgramsAndExercises');
  const [targetProgram, setTargetProgram] = useState<FTESweatProgram>();
  const [targetUser, setTargetUser] = useState<User>();
  const [targetDay, setTargetDay] = useState<FTESweatWorkout>();
  const [targetExercise, setTargetExercise] = useState<FTESweatWorkoutStepExercise>();
  const [prevScreen, setPrevScreen] = useState<string>('UserProgramsAndExercises');
  const [exerciseMap, setExerciseMap] = useState<
    Map<
      number,
      {
        exercise: FTESweatWorkoutStepExercise;
        date: string;
        userNotes: string;
      }[]
    >
  >();
  const [exercisesUserNotes, setExercisesUserNotes] = useState<Map<number, string>>();
  const [workoutMap, setWorkoutMap] = useState<Map<number, UserSweatWorkoutProgress>>();
  const [weightInputs, setWeightInputs] = useState<Map<number, string[]>>();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (user) {
          setLoading(true);
          const fetchPrograms = await store.fteLibrary.fetchTargetUserSweatProgramList(user.id);
          const fetchProgramProgress = await store.fteLibrary.fetchFteUserSweatProgramProgress(
            user.id,
            fetchPrograms.extra?.results[0].programs?.toString()!,
          );
          setProgramList(fetchPrograms.extra?.results[0].programList);
          setProgramsProgress(fetchProgramProgress.extra?.results);

          const exercises = await getExercises(fetchPrograms.extra?.results[0].programList);
          setExerciseList(exercises.list);
          setExerciseMap(exercises.map);
          setExercisesUserNotes(exercises.userNotes);
          setLoading(false);
        }
      })();
    }, []),
  );

  const getExercises = async (programs: FTESweatProgram[]) => {
    const exercisesSet = new Set<number>();
    const exercisesMap = new Map<
      number,
      {
        exercise: FTESweatWorkoutStepExercise;
        date: string;
        userNotes: string;
      }[]
    >();
    const exercisesList: FTESweatWorkoutStepExercise[] = [];
    const workoutMap = new Map<number, UserSweatWorkoutProgress>();

    const exercisesUserNotes = new Map<number, string>();
    const exercisesWeightInputs = new Map<number, string[]>();

    if (user) {
      const fetchWorkoutProgress = await store.fteLibrary.fetchFteUserSweatWorkoutProgress(user.id);
      fetchWorkoutProgress.extra?.results.map((progress: UserSweatWorkoutProgress) => {
        if (progress.workout) workoutMap.set(progress.workout, progress);
      });
    }

    const fteExerciseProgress = await store.fteLibrary.fetchFteUserSweatExerciseProgress(
      undefined,
      undefined,
      user?.id,
    );

    fteExerciseProgress.extra?.results.map(
      (exerciseProgress: UserSweatWorkoutExerciseProgress, index: number) => {
        const id = exerciseProgress.exercise;
        const { userNotes } = exerciseProgress;
        exercisesUserNotes.set(exerciseProgress.id, userNotes);
        exercisesWeightInputs.set(exerciseProgress.id, exerciseProgress.weightInputs);
        const exerciseObj: Partial<SweatWorkoutStepExercise> = {
          exercise: id,
          exerciseName: exerciseProgress.exerciseName,
          exerciseSets: exerciseProgress.exerciseSets,
        };
        let date: string = 'Ongoing';
        if (exerciseProgress.isComplete) {
          date = exerciseProgress.dateCompleted;
        }

        if (id) {
          if (!exercisesSet.has(id)) {
            exercisesSet.add(id);
            exercisesList.push(exerciseObj);
          }
        }
        if (!exercisesMap.has(id)) {
          exercisesMap.set(id, []);
        }

        exercisesMap.get(id)?.push({
          exercise: exerciseObj,
          date,
          userNotes,
          weightInputs: exerciseProgress.weightInputs,
          userExerciseSetInputs: exerciseProgress.userExerciseSetInputs,
        });
      },
    );

    const promiseProgram = programs.map(async (program) => {
      const prog = await store.fteLibrary.fetchSweatProgramById(program.id);
      prog.extra?.results[0].workouts.map((workout) => {
        let date: string;
        if (workout.id && workoutMap.has(workout.id) && workoutMap.get(workout.id)?.dateCompleted) {
          date = workoutMap.get(workout.id)?.dateCompleted as string;
        } else {
          date = 'Ongoing';
        }
        workout.steps.map((step) => {
          step.exercises?.map((exercise) => {
            const { id } = exercise.exercise;
            let userNotes = '';
            if (workout.id && workoutMap.has(workout.id)) {
              const exerciseProgress = workoutMap
                .get(workout.id)
                ?.userSweatExerciseProgress.find((x) => x.workoutStepExercise == exercise.id);
              if (exerciseProgress) userNotes = exerciseProgress.userNotes;
            }
            if (exercise.id) exercisesUserNotes.set(exercise.id, userNotes);
            if (id) {
              if (!exercisesSet.has(id)) {
                exercisesSet.add(id);
                exercisesList.push(exercise);
              }
              if (!exercisesMap.has(id)) {
                exercisesMap.set(id, []);
                exercisesMap.get(id)?.push({
                  exercise,
                  date,
                  userNotes,
                });
              }
            }
          });
        });
      });
    });

    setWeightInputs(exercisesWeightInputs);
    setWorkoutMap(workoutMap);
    await Promise.all(promiseProgram);

    return {
      list: exercisesList,
      map: exercisesMap,
      userNotes: exercisesUserNotes,
    };
  };

  const getProgramStatus = (id: number) => {
    const progress = programsProgress.find((progress: any) => progress.program == id);
    return progress ? progress.isComplete : false;
  };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <>
      {currentScreen === 'ExerciseHistory' && (
        <ExerciseHistory
          exercises={
            targetExercise && exerciseMap && exerciseMap.get(targetExercise.exercise.id)
              ? (exerciseMap.get(targetExercise.exercise.id) as {
                  exercise: FTESweatWorkoutStepExercise;
                  date: string;
                  userNotes: string;
                }[])
              : targetExercise && exerciseMap && exerciseMap.get(targetExercise.exercise)
              ? (exerciseMap.get(targetExercise.exercise) as {
                  exercise: FTESweatWorkoutStepExercise;
                  date: string;
                  userNotes: string;
                }[])
              : []
          }
          onClose={() => setCurrentScreen(prevScreen)}
        />
      )}
      {currentScreen === 'EditsAndComments' && (
        <EditsAndComments
          day={targetDay}
          exercisesUserNotes={exercisesUserNotes}
          onClose={() => setCurrentScreen('ProgramDetail')}
          onPressExercise={(exercise) => {
            setTargetExercise(exercise);
            setCurrentScreen('ExerciseHistory');
            setPrevScreen('EditsAndComments');
          }}
          workoutMap={workoutMap}
          exercisesWeightInputs={weightInputs}
        />
      )}
      {currentScreen === 'ProgramDetail' && (
        <ProgramDetail
          program={targetProgram}
          user={targetUser}
          onClose={() => setCurrentScreen('UserProgramsAndExercises')}
          onPressDay={(day) => {
            setTargetDay(day);
            setCurrentScreen('EditsAndComments');
          }}
        />
      )}
      {currentScreen === 'UserProgramsAndExercises' && (
        <Layout style={styles.container}>
          <Layout style={isMobile ? styles.mobileBg : styles.bgContainer}>
            <Layout style={styles.header}>
              <Image
                style={styles.logo as StyleProp<ImageStyle>}
                source={
                  user?.profileImage
                    ? { uri: user.profileImage }
                    : require('../../../assets/images/user_placeholder.png')
                }
              />
              <Text style={styles.headerText}>{user?.fullName ? user.fullName : user?.email}</Text>

              <TouchableOpacity onPress={onClose} style={[styles.close, { marginLeft: 'auto' }]}>
                <Icon name="close-outline" fill="white" style={styles.icon} />
              </TouchableOpacity>
            </Layout>
            <ScrollView style={styles.contentContainer}>
              {loading ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                <>
                  <Layout style={{ marginBottom: '20px' }}>
                    <ScrollView style={styles.userScroll}>
                      {programList ? (
                        <Layout style={styles.tableHeader}>
                          <View style={[styles.headerContainer, { width: '70%' }]}>
                            <Text style={styles.textHeader}>Programs</Text>
                          </View>
                          <View style={[styles.headerContainer, { width: '30%' }]}>
                            <Text style={styles.textHeader}>Status</Text>
                          </View>
                        </Layout>
                      ) : null}
                      {programList
                        ? programList?.map((program, index) => (
                            <Layout key={index} style={styles.tableData}>
                              <Layout style={styles.dataContainer}>
                                <TouchableOpacity
                                  style={[styles.headerContainer, { width: '70%' }]}
                                  onPress={() => {
                                    setTargetProgram(program);
                                    setTargetUser(user);
                                    setCurrentScreen('ProgramDetail');
                                  }}>
                                  <Text style={styles.dataText}>{program?.name}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={[styles.headerContainer, { width: '30%' }]}
                                  onPress={() => {
                                    setTargetProgram(program);
                                    setTargetUser(user);
                                    setCurrentScreen('ProgramDetail');
                                  }}>
                                  <Text style={styles.dataText}>
                                    {program.id && getProgramStatus(program.id)
                                      ? 'Complete'
                                      : 'Ongoing'}
                                  </Text>
                                </TouchableOpacity>
                              </Layout>
                            </Layout>
                          ))
                        : null}
                    </ScrollView>
                  </Layout>
                  <Input
                    size="small"
                    style={isMobile ? [styles.search, { width: '60%' }] : styles.search}
                    value={query}
                    onChangeText={(text) => setQuery(text)}
                    accessoryRight={() => (
                      <Icon style={styles.icon} name="search-outline" fill="white" />
                    )}
                    placeholder="Search Exercise History..."
                  />
                  <Layout>
                    <ScrollView style={styles.userScroll}>
                      {programList ? (
                        <Layout style={styles.tableHeader}>
                          <View style={[styles.headerContainer, { width: '100%' }]}>
                            <Text style={styles.textHeader}>Exercise History</Text>
                          </View>
                        </Layout>
                      ) : null}
                      {exerciseList
                        ? exerciseList
                            .filter((exercise) =>
                              query.trim().length > 0
                                ? exercise.exerciseName
                                    .toLowerCase()
                                    .includes(query.trim().toLowerCase())
                                : true,
                            )
                            .map((exercise, index) => (
                              <Layout key={index} style={styles.tableData}>
                                <Layout style={styles.dataContainer}>
                                  <TouchableOpacity
                                    style={[styles.headerContainer, { width: '100%' }]}
                                    onPress={() => {
                                      setTargetExercise(exercise);
                                      setCurrentScreen('ExerciseHistory');
                                      setPrevScreen('UserProgramsAndExercises');
                                    }}>
                                    <Text style={styles.dataText}>{exercise.exerciseName}</Text>
                                  </TouchableOpacity>
                                </Layout>
                              </Layout>
                            ))
                        : null}
                    </ScrollView>
                  </Layout>
                </>
              )}
            </ScrollView>
          </Layout>
        </Layout>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '90%',
    height: '90%',
    marginLeft: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileBg: {
    position: 'absolute',
    width: '87%',
    height: '90%',
    left: '26px',
    right: '26px',
    paddingRight: 20,
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  header: {
    width: '95%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  logo: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 10,
  },
  close: {
    alignSelf: 'center',
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
  tableHeader: {
    width: '100%',
    height: '40px',
    backgroundColor: '#091C2D',
    flexDirection: 'row',
  },
  textHeader: {
    color: 'white',
    fontSize: 14,
  },
  headerContainer: {
    border: '1px solid #1A3248',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableData: {
    width: '100%',
    height: '40px',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '92%',
    marginLeft: '26px',
    marginBottom: '40px',
  },
  search: {
    width: '35%',
    marginTop: '30px',
    marginBottom: '20px',
    backgroundColor: '#1A3248',
  },
  dataContainer: {
    backgroundColor: '#091C2D',
    width: '100%',
    height: '40px',
    flexDirection: 'row',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  dataText: {
    color: '#4E7B89',
    fontSize: 14,
  },
  userScroll: {
    maxHeight: '220px',
  },
});

export default observer(UserProgramsAndExercises);
