import { RouteProp, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import CheckBox from 'src/components/CheckBox';
import TopNav from 'src/components/Question/TopNav';
import BottomNav from '../../components/Question/BottomNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'DailyDebriefQuestion'>;
};

const questions: { [key: string]: string } = {
  hoursSleep: 'How much sleep did you get?',
  skillsToManageStress: 'What skills did you use to manage the stress?',
  qualitySleep: 'Rate the quality of your sleep',
  stressLevel: 'How much stress did you experience?',
  balanceInLife: 'How would you rate your work/life balance?',
  goalsAchieved:
    'Did you feel like you had the physical and mental capacity to achieve your goals?',
};

const AddHabitThrive: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const { habits = [] } = props?.route.params ?? {};

  const [currentHabits, setCurrentHabits] = useState(habits);

  const task = TASK_TYPE.THRIVE;
  const color = getTaskColor(task);

  const select = useCallback(
    (item: string) => {
      if (currentHabits.includes(item)) {
        setCurrentHabits(currentHabits.filter((h) => h !== item));
      } else {
        setCurrentHabits([...currentHabits, item]);
      }
    },
    [currentHabits],
  );

  const onSubmit = useCallback(() => {
    navigation.navigate('DailyDebriefThrive', { habits: currentHabits });
  }, [currentHabits]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <Layout style={styles.navigationContainer}>
              <TopNav
                showBack
                showClose
                onClose={() => navigation.navigate('Today')}
                onBack={goBack}
              />
            </Layout>
            <Layout
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <View style={styles.margin}>
                <Text category="h2">
                  {'Add '}
                  <Text category="h2" style={[styles.title, { color }]}>
                    SWEAT
                  </Text>
                  {' habits'}
                </Text>
                <Text category="p2" style={styles.description}>
                  Select the SWEAT habits you’d like to track in your Daily Log.
                </Text>
                {Object.keys(questions).map((item) => (
                  <TouchableWithoutFeedback key={item} onPress={() => select(item)}>
                    <View style={styles.itemContainer}>
                      <View style={styles.padding}>
                        <CheckBox fill={color} checked={currentHabits.includes(item)} disabled />
                      </View>
                      <Text category="p1" style={styles.question}>
                        {questions[item]}
                      </Text>
                    </View>
                  </TouchableWithoutFeedback>
                ))}
                <View style={styles.bottom} />
              </View>
            </Layout>
            <BottomNav onBack={goBack} onNext={onSubmit} nextLabel="OK" nextColor={theme.olive} />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  description: {
    marginVertical: 15,
  },
  itemContainer: {
    flexDirection: 'row',
    backgroundColor: '#203950',
    borderRadius: 5,
    marginBottom: 5,
    paddingVertical: 6,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  margin: {
    flex: 1,
    marginHorizontal: 24,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  padding: {
    marginRight: 12,
  },
  question: {
    marginVertical: 15,
    flex: 1,
  },
  title: {
    color: 'white',
    textTransform: 'uppercase',
  },
  contentContainer: {
    flex: 1,
    overflowY: 'scroll',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
    justifyContent: 'flex-start',
    overflowY: 'scroll',
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
    justifyContent: 'flex-start',
    overflowY: 'scroll',
  },
  bottom: {
    height: 10,
  },
});

export default AddHabitThrive;
