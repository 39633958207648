import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  modelAction,
  prop_mapObject,
  ModelInstanceCreationData,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import { TagModel } from '../models/Tag';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

// TODO: Make this class generic. Currently, it works for exactly one tag type.
@model('o2x-store/TagStore')
export default class TagStore extends Model({
  tags: prop_mapObject(() => new Map<string, TagModel>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchTags = _async(function* (this: TagStore, type: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchTags(rootStore.auth.token, type)));
    } catch (error) {
      console.warn('[DEBUG] error fetching tags', error);
      return getError(error);
    }

    console.log(entities);
    this.tags.clear();
    entities.forEach((data: TagModel) => {
      this.tags.set(`${data.id}`, data);
    });

    this.loading = false;
    return getSuccess();
  });
}
