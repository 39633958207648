import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Eat from './Eat';
import GetStarted from './GetStarted';
import Sweat from './Sweat';
import Thrive from './Thrive';
import WelcomeMessage from './WelcomeMessage';

type Props = {};

const WELCOME_STEPS = [WelcomeMessage, Eat, Sweat, Thrive, GetStarted];

const Welcome: React.FC<Props> = (props) => {
  const { auth, user } = useStore();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const [step, setStep] = useState(0);

  useFocusEffect(
    useCallback(() => {
      user.updateMe({ isOnboarded: true });
    }, []),
  );

  const onNextStep = () => {
    if (step < WELCOME_STEPS.length - 1) {
      setStep(step + 1);
    } else {
      navigation.navigate('Home');
    }
  };

  const onPreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      auth.logOut();
    }
  };

  const renderActiveStep = () => {
    const ContentComponent = WELCOME_STEPS[step];
    return <ContentComponent />;
  };

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scrollContainer}>
        <Layout style={styles.scrollWrapper}>{renderActiveStep()}</Layout>
      </ScrollView>
      <Layout style={styles.steps}>
        {WELCOME_STEPS.map((_, index) => (
          <View
            key={index}
            style={[
              styles.stepBg,
              {
                backgroundColor: step >= index ? theme.white : theme.gray,
                width: `${(100 - WELCOME_STEPS.length) / WELCOME_STEPS.length}%`,
              },
            ]}
          />
        ))}
      </Layout>
      <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
        {step !== WELCOME_STEPS.length - 1 && (
          <TouchableOpacity style={[styles.button, styles.previous]} onPress={onPreviousStep}>
            <Text style={styles.label} category="c2">
              Back
            </Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity style={[styles.button, styles.next]} onPress={onNextStep}>
          <Text style={styles.label} category="c2">
            {step === WELCOME_STEPS.length - 1 ? "Let's go" : 'Next'}
          </Text>
        </TouchableOpacity>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
  },
  container: {
    flex: 1,
  },
  label: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  next: {
    backgroundColor: 'olive',
  },
  previous: {
    backgroundColor: 'dark-blue',
  },
  scroll: {
    flex: 1,
  },
  scrollContainer: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    paddingTop: 24,
    paddingBottom: 40,
    paddingHorizontal: 24,
  },
  scrollWrapper: {
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  steps: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 3,
  },
  step: {
    height: 4,
    marginLeft: 1,
    marginRight: 1,
    flex: 1,
  },
  stepBg: {
    height: 4,
    width: '100%',
  },
  wallpaper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export default Welcome;
