import * as Analytics from 'expo-firebase-analytics';
import snakeCaseKeys from 'snakecase-keys';
import BodyComposition from '../models/BodyComposition';
import FormAssessment from '../models/FormAssesment';
import PhysicalAssessment from '../models/PhysicalAssessment';
import { Program } from '../models/Program';
import Recipe from '../models/Recipe';
import SweatProgram from '../models/SweatProgram';
import SweatWorkout from '../models/SweatWorkout';
import ThriveMediaFile from '../models/ThriveMediaFile';
import ThriveProgram from '../models/ThriveProgram';
import ThriveProgramDay from '../models/ThriveProgramDay';
import User from '../models/User';

export const setUser = async (user?: User) => {
  if (user) {
    await Analytics.setUserId(user.o2XId);
    const { organizationObj: organization } = user;
    if (organization) {
      console.log(
        '[DEBUG:FIREBASE] setUser',
        user.o2XId,
        organization.id,
        organization.name,
      );
      await Analytics.setUserProperties(
        snakeCaseKeys({
          organizationId: `${organization.id}`,
          organization: `${organization.name}`,
          o2xId: `${user.o2XId}`,
        }),
      );
    } else {
      console.log('[DEBUG:FIREBASE] setUser', user.o2XId);
      await Analytics.setUserProperties(
        snakeCaseKeys({
          o2xId: `${user.o2XId}`,
        }),
      );
    }
  } else {
    console.log('[DEBUG:FIREBASE] setUser');
    await Analytics.setUserId(null);
    await Analytics.setUserProperties({});
  }
};

export const setCurrentScreen = async (screenName?: string) => {
  console.log('[DEBUG:FIREBASE] setCurrentScreen', screenName);
  await Analytics.setCurrentScreen(screenName);
};

export const logSignUp = async () => {
  console.log('[DEBUG:FIREBASE] logSignUp');
  await Analytics.logEvent('sign_up');
};

export const logLogin = async () => {
  console.log('[DEBUG:FIREBASE] logLogin');
  await Analytics.logEvent('login');
};

export const logLogout = async () => {
  console.log('[DEBUG:FIREBASE] logLogout');
  await Analytics.logEvent('logout');
};

export const logAppOpen = async () => {
  console.log('[DEBUG:FIREBASE] logOpenApp');
  await Analytics.logEvent('app_open');
};

export const logProgramOpen = async (program: Program) => {
  const { id: programId, name: programName, type: programType } = program;
  console.log(
    '[DEBUG:FIREBASE] logProgramOpen',
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'program_open',
    snakeCaseKeys({
      programId,
      programName,
      programType,
    }),
  );
};

export const logProgramStart = async (
  program: Recipe | SweatProgram | ThriveProgram,
) => {
  const { id: programId, name: programName, type: programType } = program;
  console.log(
    '[DEBUG:FIREBASE] logProgramStart',
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'program_start',
    snakeCaseKeys({
      programId,
      programName,
      programType,
    }),
  );
};

export const logProgramComplete = async (
  program: Recipe | SweatProgram | ThriveProgram,
) => {
  const { id: programId, name: programName, type: programType } = program;
  console.log(
    '[DEBUG:FIREBASE] logProgramStart',
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'program_complete',
    snakeCaseKeys({
      programId,
      programName,
      programType,
    }),
  );
};

export const logDailyEatHabitLog = async () => {
  console.log('[DEBUG:FIREBASE] logDailyEatHabitLog');
  await Analytics.logEvent('daily_eat_habit_log');
};

export const logSweatWorkoutStart = async (
  sweatWorkout: SweatWorkout,
  sweatProgram?: SweatProgram,
) => {
  const { id: workoutId, displayName: workoutName } = sweatWorkout;
  const { id: programId, name: programName, type: programType } =
    sweatProgram || {};
  console.log(
    '[DEBUG:FIREBASE] logSweatWorkoutStart',
    workoutId,
    workoutName,
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'sweat_workout_start',
    snakeCaseKeys({
      workoutId,
      workoutName,
      programId,
      programName,
      programType,
    }),
  );
};

export const logSweatWorkoutComplete = async (
  sweatWorkout: SweatWorkout,
  sweatProgram?: SweatProgram,
) => {
  const { id: workoutId, displayName: workoutName } = sweatWorkout;
  const { id: programId, name: programName, type: programType } =
    sweatProgram || {};
  console.log(
    '[DEBUG:FIREBASE] logSweatWorkoutComplete',
    workoutId,
    workoutName,
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'sweat_workout_complete',
    snakeCaseKeys({
      workoutId,
      workoutName,
      programId,
      programName,
      programType,
    }),
  );
};

export const logWorkoutGeneratorOpen = async () => {
  console.log('[DEBUG:FIREBASE] logWorkoutGeneratorOpen');
  await Analytics.logEvent('workout_generator_open');
};

export const logDailyThriveHabitLog = async () => {
  console.log('[DEBUG:FIREBASE] logDailyThriveHabitLog');
  await Analytics.logEvent('daily_thrive_habit_log');
};

export const logThriveProgramDayStart = async (
  thriveProgramDay: ThriveProgramDay,
  thriveProgram: ThriveProgram,
) => {
  const { id: thriveProgramDayId, dayDisplay } = thriveProgramDay;
  const { id: programId, name: programName, type: programType } = thriveProgram;
  const thriveProgramDayName = `${programName} ${dayDisplay}`;
  console.log(
    '[DEBUG:FIREBASE] logThriveProgramDayStart',
    thriveProgramDayId,
    thriveProgramDayName,
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'sweat_workout_start',
    snakeCaseKeys({
      thriveProgramDayId,
      thriveProgramDayName,
      programId,
      programName,
      programType,
    }),
  );
};

export const logThriveProgramDayComplete = async (
  thriveProgramDay: ThriveProgramDay,
  thriveProgram: ThriveProgram,
) => {
  const { id: thriveProgramDayId, dayDisplay } = thriveProgramDay;
  const { id: programId, name: programName, type: programType } = thriveProgram;
  const thriveProgramDayName = `${programName} ${dayDisplay}`;
  console.log(
    '[DEBUG:FIREBASE] logThriveProgramDayComplete',
    thriveProgramDayId,
    thriveProgramDayName,
    programId,
    programName,
    programType,
  );
  await Analytics.logEvent(
    'sweat_workout_complete',
    snakeCaseKeys({
      thriveProgramDayId,
      thriveProgramDayName,
      programId,
      programName,
      programType,
    }),
  );
};

export const logThriveActivityOpen = async (thriveActivityType: string) => {
  console.log('[DEBUG:FIREBASE] logThriveActivityOpen', thriveActivityType);
  await Analytics.logEvent(
    'thrive_activity_open',
    snakeCaseKeys({ thriveActivityType }),
  );
};

export const logThriveActivityStart = async (
  thriveActivityType: string,
  audioFile?: ThriveMediaFile,
  visualFile?: ThriveMediaFile,
) => {
  const { id: audioId, name: audioName } = audioFile || {};
  const { id: visualId, name: visualName } = visualFile || {};
  console.log(
    '[DEBUG:FIREBASE] logThriveActivityStart',
    thriveActivityType,
    audioId,
    audioName,
    visualId,
    visualName,
  );
  await Analytics.logEvent(
    'thrive_activity_start',
    snakeCaseKeys({
      thriveActivityType,
      audioId,
      audioName,
      visualId,
      visualName,
    }),
  );
};

export const logThriveActivityComplete = async (
  thriveActivityType: string,
  audioFile?: ThriveMediaFile,
  visualFile?: ThriveMediaFile,
) => {
  const { id: audioId, name: audioName } = audioFile || {};
  const { id: visualId, name: visualName } = visualFile || {};
  console.log(
    '[DEBUG:FIREBASE] logThriveActivityComplete',
    thriveActivityType,
    audioId,
    audioName,
    visualId,
    visualName,
  );
  await Analytics.logEvent(
    'thrive_activity_complete',
    snakeCaseKeys({
      thriveActivityType,
      audioId,
      audioName,
      visualId,
      visualName,
    }),
  );
};

export const logAssessmentOpen = async (
  assessment: FormAssessment | PhysicalAssessment | BodyComposition,
) => {
  const {
    id: assessmentId,
    name: assessmentName,
    type: assessmentType,
  } = assessment;
  console.log(
    '[DEBUG:FIREBASE] logAssessmentOpen',
    assessmentId,
    assessmentName,
    assessmentType,
  );
  await Analytics.logEvent(
    'assessment_open',
    snakeCaseKeys({
      assessmentId,
      assessmentName,
      assessmentType,
    }),
  );
};

export const logAssessmentStart = async (
  assessment: FormAssessment | PhysicalAssessment | BodyComposition,
) => {
  const {
    id: assessmentId,
    name: assessmentName,
    type: assessmentType,
  } = assessment;
  console.log(
    '[DEBUG:FIREBASE] logAssessmentStart',
    assessmentId,
    assessmentName,
    assessmentType,
  );
  await Analytics.logEvent(
    'assessment_start',
    snakeCaseKeys({
      assessmentId,
      assessmentName,
      assessmentType,
    }),
  );
};

export const logAssessmentComplete = async (
  assessment: FormAssessment | PhysicalAssessment | BodyComposition,
) => {
  const {
    id: assessmentId,
    name: assessmentName,
    type: assessmentType,
  } = assessment;
  console.log(
    '[DEBUG:FIREBASE] logAssessmentComplete',
    assessmentId,
    assessmentName,
    assessmentType,
  );
  await Analytics.logEvent(
    'assessment_complete',
    snakeCaseKeys({
      assessmentId,
      assessmentName,
      assessmentType,
    }),
  );
};
