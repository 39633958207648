const getConfig = () => {
  const config = {
    debug: process.env.O2X_DEBUG === 'True',
    storeKey: process.env.O2X_STORE_KEY!,
    tokenKey: `${process.env.O2X_STORE_KEY!}-token`,
    urls: {
      api: process.env.O2X_API_URL!,
      portal: process.env.O2X_PORTAL_URL!,
      signUp: 'auth/registration/',
      signIn: 'auth/login/',
      signInWithFacebook: 'auth/facebook/',
      signInWithGoogle: 'auth/google/',
      socialAccounts: 'auth/socialaccounts/',
      logOut: 'auth/logout/',
      passwordReset: 'auth/password/reset/',
      recipes: 'eat/recipes/',
      sweatPrograms: 'sweat/programs/',
      sweatGlobalSteps: 'sweat/global-steps/',
      sweatWorkouts: 'sweat/workouts/',
      sweatExercises: 'sweat/exercises/',
      sweatEquipments: 'sweat/equipments/default_trial/',
      sweatProfessions: 'sweat/professions/',
      sweatImageFiles: 'sweat/image-files/',
      sweatCategories: 'sweat/categories/default/',
      thriveMediaFiles: 'thrive/content/',
      thrivePrograms: 'thrive/programs/',
      thriveExercises: 'thrive/exercises/',
      tasks: 'tasks/',
      tutorials: 'tutorial/',
      users: 'users/',
      eatProfile: 'users/eat-profile/',
      sweatProfile: 'users/sweat-profile/',
      thriveProfile: 'users/thrive-profile/',
      dailyEatHabit: 'users/daily-eat-habit/',
      dailyThriveHabit: 'users/daily-thrive-habit/',
      dailyDebrief: 'users/daily-debrief/',
      debriefProfile: 'users/debrief-profile/',
      weightInput: 'users/sweat-weight-input/',
      sweatExerciseProgress: 'users/sweat-exercise-progress/',
      sweatWorkoutProgress: 'users/sweat-workout-progress/',
      sweatProgramProgress: 'users/sweat-program-progress/',
      thriveProgramProgress: 'users/thrive-program-progress/',
      formAssessments: 'assessments/form/',
      formAssessmentDownload: 'assessments/download/',
      formAssessmentSubmissions: 'assessments/form-submissions/',
      physicalAssessments: 'assessments/physical/',
      physicalAssessmentSubmissions: 'assessments/physical-submissions/',
      bodyComposition: 'assessments/body-composition/',
      recipeList: 'users/recipe-list/',
      sweatProgramList: 'users/sweat-program-list/',
      sweatWorkoutList: 'users/sweat-workout-list/',
      teams: 'teams/',
      thriveProgramList: 'users/thrive-program-list/',
      thriveExerciseList: 'users/thrive-exercise-list/',
      thriveActivity: 'users/thrive-activity/',
      externalShop: 'https://www.o2x.com/o2x-shop/',
      userQuestionTopics: 'user_questions/topics/',
      userQuestions: 'user_questions/questions/',
      eventList: 'users/event-list/',
      events: 'events/',
      fteevents: 'fte-events/',
      organizations: 'organizations/organizations/',
      ossGroups: 'organizations/oss-groups/',
      utils: 'utils/',
      validatePurchaseIOS: 'users/validate_ios_store_subscription/',
      validatePurchaseAndroid: 'users/validate_android_store_subscription/',
      registerDevice: 'users/register_device/',
      dashboard: 'dashboard/',
      dashboardFiles: 'dashboard/files/dashboard-files/',
      deleteMessage: 'contact/delete_message/',
      contact: 'contact/messages/',
      groupConversation: 'contact/group_conversation/',
      sendEmailMessage: 'contact/messages/send_email/',
      fteLibrary: 'fte-library/',
      conversation: 'contact/conversation/',
      conversationStaging: 'contact/conversation_staging/',
      contactFiles: 'contact/files/contact-files/',
      tags: 'tags/tags/',
      app: 'https://app.o2x.com',
      terms: 'https://o2x.com/terms-services/',
      privacy: 'https://o2x.com/privacy-policy',
      oldPortal: 'https://portal.o2x.com',
      resources: 'resources/',
      sendNotifications: 'notifications/send/',
      // eventSurvey: 'https://www.surveymonkey.com/r/o2xworkshopsurvey',
      // UPDATED EVENTY SURVEY FOR 2022
      eventSurvey: 'https://www.surveymonkey.com/r/O2XSurvey2022',
    },
    googleSignInConfig: {
      iosClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_IOS_CLIENT_ID,
      androidClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_ANDROID_CLIENT_ID,
      iosStandaloneAppClientId: process.env.REACT_APP_GOOGLE_SIGN_IN_IOS_STANDALONE_CLIENT_ID,
      androidStandaloneAppClientId:
        process.env.REACT_APP_GOOGLE_SIGN_IN_ANDROID_STANDALONE_CLIENT_ID,
      clientId: process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID,
    },
  };

  console.log(config.urls.api);
  console.log({ config });
  return config;
};

export default getConfig();
