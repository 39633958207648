import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import React from 'react';
import { Dimensions, ScrollView, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  prepSearch: string;
  recSearch: string;
  setRecSearch: Function;
  setPrepSearch: Function;
  onSearchStep: Function;
  isLoading: boolean;
  loadingPrep: boolean;
  loadingRec: boolean;
  loadingMoreRecover: boolean;
  loadingMorePrepare: boolean;
  prepareList: SweatGlobalStep[] | undefined;
  recoverList: SweatGlobalStep[] | undefined;
  setRecoverStep: Function;
  setPrepareStep: Function;
  setCreateContent: Function;
  onRemoveStep: Function;
  loadMoreStep: Function;
};

const { width, height } = Dimensions.get('window');

const Library: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    prepSearch,
    recSearch,
    setRecSearch,
    setPrepSearch,
    onSearchStep,
    isLoading,
    loadingPrep,
    loadingRec,
    loadingMoreRecover,
    loadingMorePrepare,
    prepareList,
    recoverList,
    setRecoverStep,
    setPrepareStep,
    setCreateContent,
    onRemoveStep,
    loadMoreStep,
  } = props;
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <View style={isMobile ? styles.rowMobile : styles.row}>
      <Layout style={isMobile ? styles.listContainerMobile : styles.listContainer}>
        <View style={styles.headerContainer}>
          <Text style={isMobile ? styles.headerTextMobile : styles.headerText}>
            SWEAT Prepare Library
          </Text>
        </View>
        <View style={styles.headerContainer}>
          <Input
            size="small"
            style={styles.search}
            accessoryLeft={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
            value={prepSearch}
            onChangeText={(text) => setPrepSearch(text)}
            placeholder="Search"
            onKeyPress={(key) => (key.nativeEvent.key === 'Enter' ? onSearchStep('prepare') : null)}
          />
        </View>
        <ScrollView style={styles.library}>
          {isLoading || loadingPrep ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <>
              {prepareList?.map((prepare, index) => (
                <Layout key={index} style={styles.dataContainer}>
                  <Text style={isMobile ? styles.dataTextMobile : styles.dataText}>
                    {prepare.name}
                  </Text>
                  <TouchableOpacity
                    style={[styles.buttonIcon, { right: 40 }]}
                    onPress={() => {
                      setRecoverStep(undefined);
                      setPrepareStep(prepare);
                      setCreateContent('global');
                    }}>
                    <Icon name="edit-outline" fill="white" style={styles.icon} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.buttonIcon}
                    onPress={() => onRemoveStep('prepare', prepare.id, index)}>
                    <Icon name="minus-outline" fill="white" style={styles.icon} />
                  </TouchableOpacity>
                </Layout>
              ))}
              {!isLoading && loadingMorePrepare ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                <TouchableOpacity
                  style={{
                    alignSelf: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  onPress={() => loadMoreStep('prepare')}>
                  <Text style={[styles.dataText, { maxWidth: '100%', opacity: 0.5 }]}>
                    Load More
                  </Text>
                </TouchableOpacity>
              )}
            </>
          )}
        </ScrollView>
      </Layout>
      <Layout style={isMobile ? styles.listContainerMobile : styles.listContainer}>
        <View style={styles.headerContainer}>
          <Text style={isMobile ? styles.headerTextMobile : styles.headerText}>
            SWEAT Recover Library
          </Text>
        </View>
        <View style={styles.headerContainer}>
          <Input
            size="small"
            style={styles.search}
            accessoryLeft={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
            value={recSearch}
            onChangeText={(text) => setRecSearch(text)}
            placeholder="Search"
            onKeyPress={(key) => (key.nativeEvent.key === 'Enter' ? onSearchStep('recover') : null)}
          />
        </View>
        <ScrollView style={styles.library}>
          {isLoading || loadingRec ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <>
              {recoverList?.map((recover, index) => (
                <Layout key={index} style={styles.dataContainer}>
                  <Text style={isMobile ? styles.dataTextMobile : styles.dataText}>
                    {recover.name}
                  </Text>
                  <TouchableOpacity
                    style={[styles.buttonIcon, { right: 40 }]}
                    onPress={() => {
                      setPrepareStep(undefined);
                      setRecoverStep(recover);
                      setCreateContent('global');
                    }}>
                    <Icon name="edit-outline" fill="white" style={styles.icon} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.buttonIcon}
                    onPress={() => onRemoveStep('recover ', recover.id, index)}>
                    <Icon name="minus-outline" fill="white" style={styles.icon} />
                  </TouchableOpacity>
                </Layout>
              ))}
              {!isLoading && loadingMoreRecover ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                <TouchableOpacity
                  style={{
                    alignSelf: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  onPress={() => loadMoreStep('recover')}>
                  <Text style={[styles.dataText, { maxWidth: '100%', opacity: 0.5 }]}>
                    Load More
                  </Text>
                </TouchableOpacity>
              )}
            </>
          )}
        </ScrollView>
      </Layout>
    </View>
  );
};

const themedStyles = StyleService.create({
  main: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bgContainer: {
    width: '100%',
    // height: '95%',
    marginTop: 15,
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  createContainer: {
    // height: '24px',
    width: '570px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '26px',
  },
  createButton: {
    width: '180px',
    height: '30px',
    backgroundColor: 'olive',
  },
  MobileCreateContainer: {
    height: 'auto',
    width: '95%',
    flexDirection: 'row',
    marginLeft: '26px',
  },
  MobileCreateButton: {
    width: '30%',
    marginRight: '2%',
    padding: 2,
    height: '30px',
    backgroundColor: 'olive',
  },
  disabledButton: {
    opacity: 0.5,
  },
  createText: {
    color: 'white',
    margin: 'auto',
    fontSize: 12,
  },
  listContainer: {
    flex: 1,
    height: '95%',
    backgroundColor: '#203950',
    marginLeft: 40,
  },
  listContainerMobile: {
    flex: 1,
    backgroundColor: '#203950',
    height: '95%',
    width: 300,
    marginLeft: 40,
  },
  headerContainer: {
    width: '100%',
    height: '50px',
  },
  headerText: {
    color: 'white',
    margin: 'auto',
    fontSize: 14,
  },
  headerTextMobile: {
    color: 'white',
    marginHorizontal: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontSize: 12,
  },
  search: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#1A3248',
  },
  icon: {
    width: 20,
    height: 20,
  },
  library: {
    flexDirection: 'column',
    backgroundColor: '#051023',
    marginBottom: 45,
    width: '100%',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  dataContainer: {
    width: '100%',
    marginTop: 15,
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  dataText: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 14,
    maxWidth: '70%',
  },
  dataTextMobile: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 15,
    color: 'white',
    fontSize: 12,
    maxWidth: '70%',
  },
  buttonIcon: {
    position: 'absolute',
    right: 10,
    top: -2,
  },
  row: {
    flexDirection: 'row',
    width: '58%',
    padding: 0,
    justifyContent: 'space-between',
  },
  rowMobile: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width,
    height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
    textTransform: 'uppercase',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
});

export default observer(Library);
