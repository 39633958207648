import { useFocusEffect } from '@react-navigation/native';
import { Icon, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Image, ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native';
import { useNativeStore } from 'src/stores';
import Filter from '../../assets/images/filter.svg';
import RefreshIcon from '../../assets/images/refresh.svg';

type Props = {
  workoutId?: number;
  isSaved: boolean;
  onSave: () => void;
  onGenerate: () => void;
  onFilter: () => void;
  onDownload?: () => void;
  isDownloaded?: boolean;
  isDownloading?: boolean;
  collapseFilter?: boolean;
  isFte?: boolean;
  onTimerPress?: () => void;
  newScreen?: boolean;
};

const WorkoutGeneratorHeader: React.FC<Props> = (props) => {
  const { workoutId, isSaved, onSave, onGenerate, onFilter, collapseFilter = false, isFte } = props;
  const { sweat } = useStore();
  const workout = sweat.sweatWorkouts.get(`${workoutId}`);
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { workoutFilter } = useNativeStore();
  const { filters } = workoutFilter;

  const [imageBanner, setImageBanner] = useState<string>('');
  const filterOpacity = useRef(new Animated.Value(1)).current;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        const result = await sweat.fetchRandomSweatImageFile();
        if (result.entities) {
          if (result.entities.contentBanner) {
            setImageBanner(result.entities.contentBanner);
          }
        }
      })();
    }, [setImageBanner]),
  );

  useEffect(() => {
    if (!collapseFilter) {
      Animated.timing(filterOpacity, {
        toValue: 1,
        duration: 600,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(filterOpacity, {
        toValue: 0,
        duration: 600,
        useNativeDriver: true,
      }).start();
    }
  }, [collapseFilter, filterOpacity]);

  const getCategory = useCallback(() => {
    switch (filters.category) {
      case 'bw':
        return 'Body Weight Only';
      case 'equipment':
        return 'Tactical Athlete';
      default:
        return 'All';
    }
  }, [filters]);

  return (
    <>
      <Image
        style={styles.cover as StyleProp<ImageStyle>}
        source={
          imageBanner
            ? { uri: imageBanner }
            : require('../../assets/images/workout-generator-thumbnail.png')
        }
      />
      <LinearGradient colors={['transparent', '#10283E']} style={styles.coverGradient} />
      <View style={[styles.header, !collapseFilter && { marginBottom: 36 }]}>
        {!!workout?.name || !workout?.program ? (
          <Text style={[styles.title, !!workout?.dayDisplay && { bottom: 20 }]}>
            {workout?.displayName}
          </Text>
        ) : (
          <>
            <Text style={[styles.title, !!workout?.dayDisplay && { bottom: 20 }]}>
              {workout.displayName.split(': Week ')[0]}
            </Text>
            <Text style={styles.subtitle}> {workout.dayDisplay} </Text>
          </>
        )}
      </View>
      {!collapseFilter && (
        <Animated.View style={{ opacity: filterOpacity }}>
          <View style={styles.actions}>
            <TouchableOpacity style={styles.action} onPress={onFilter}>
              <Filter style={styles.actionIcon} height={20} stroke={theme.white} />
              <Text style={styles.actionText} category="c2">
                Filter
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.action} onPress={onGenerate}>
              <RefreshIcon style={styles.actionIcon} height={20} />
              <Text style={styles.actionText} category="c2">
                Regenerate
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.action} onPress={onSave}>
              <Icon
                style={styles.actionIcon}
                fill="white"
                name={isSaved ? 'bookmark' : 'bookmark-outline'}
              />
              <Text style={styles.actionText} category="c2">
                Save
              </Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    position: 'relative',
    marginTop: 30,
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: 'white',
    position: 'absolute',
    width: '90%',
    bottom: 0,
  },
  subtitle: {
    color: 'cyan',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  actions: {
    flexDirection: 'row',
  },
  action: {
    alignItems: 'center',
    marginRight: 30,
  },
  actionIcon: {
    width: 20,
    height: 20,
    marginBottom: 5,
  },
  actionText: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  filterSection: {
    marginVertical: 5,
  },

  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  cover: {
    height: 170,
    marginHorizontal: -24,
  },
  coverGradient: {
    position: 'absolute',
    top: 50,
    height: 120,
    width: '100%',
    marginHorizontal: -24,
  },
});

export default WorkoutGeneratorHeader;
