import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { Author } from 'o2x-store/src/models/Program';
import { useStore } from 'o2x-store/src/stores';
import React, { useEffect, useMemo } from 'react';
import { Image, ImageStyle, StyleProp, View, ViewStyle } from 'react-native';
import { useNativeStore } from 'src/stores';

type Props = {
  style?: StyleProp<ViewStyle>;
  id?: number;
  author?: Author;
  label?: string;
  isDownloaded?: boolean;
};

const UserDetail: React.FC<Props> = (props) => {
  const { style, id, author, label = 'Created By', isDownloaded } = props;
  const { users } = useStore();
  const { downloads } = useNativeStore();
  const isAuthorValid = author && Object.values(author).find((v) => !!v);
  const user = users.users.get(`${id}`);
  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    if (id) {
      users.fetchUser(id);
    }
  }, [id]);

  const media = useMemo(() => {
    if (isDownloaded && author) {
      const image = author.pictureThumbnail || author.picture;
      if (image) {
        return downloads.getOrCreateThumbnail({
          uri: image,
          id: author.id,
          type: 'author',
        });
      }
    }
    return null;
  }, [isDownloaded, author]);

  useEffect(() => {
    if (media) {
      media.downloadMedia();
    }
  }, [media]);

  if (!user && !isAuthorValid) {
    return null;
  }

  let image: string | null = '';
  let title: string | null = '';
  if (user) {
    image = user.profileImage;
    title = [user.fullName, user.licensedIssuedBody, user.companyName]
      .filter((text) => !!text)
      .join('\n');
  } else if (author) {
    image = media && media.downloaded ? media.localUri : author.pictureThumbnail || author.picture;
    title = [author.fullName, author.biography].filter((text) => !!text).join('\n');
  }

  return (
    <Layout style={[styles.container, style]}>
      <Image
        style={styles.image as StyleProp<ImageStyle>}
        source={image ? { uri: image } : require('../../assets/images/user_placeholder.png')}
      />
      <View style={styles.content}>
        {!!label && <Text style={styles.label}>{label}</Text>}
        <Text category="c1">{title}</Text>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
  },

  content: { flex: 1 },
  image: {
    borderRadius: 30,
    width: 60,
    height: 60,
    marginRight: 16,
  },
  label: {
    color: 'blue-secondary',
    fontSize: 10,
    lineHeight: 12,
    textTransform: 'uppercase',
    marginBottom: 2,
  },
});

export default observer(UserDetail);
