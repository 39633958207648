import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../../components/Question/TopNav';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatOptions'>;
  navigation: StackNavigationProp<AppStackParamList, 'SweatOptions'>;
};

const SweatOptionsQuit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { programId, workoutId },
    },
    navigation,
  } = props;
  const { sweat } = useStore();
  const theme = useTheme();
  const program = sweat.sweatPrograms.get(`${programId}`);
  const workout = workoutId
    ? sweat.sweatWorkouts.get(`${workoutId}`)
    : sweat.sweatWorkouts.get(`${program?.userProgress?.currentWorkout}`);
  const styles = useStyleSheet(themedStyles);
  const { sweatStart } = useNativeStore();
  const [loading, setLoading] = useState<boolean>(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  if (!program) {
    return null;
  }
  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onQuit = useCallback(async () => {
    setLoading(true);
    if (program && !sweatStart.repeat) {
      if (!sweatStart.workout) {
        await program.startWorkout();
      }
      await program.markCompleteCurrentWorkout(
        {
          totalTime: sweatStart.totalTime,
          stepExerciseTimeBreakdown: sweatStart.breakdownTime,
          globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
        },
        workoutId,
      );
    } else if (workout) {
      if (!sweatStart.workout) {
        await workout.startWorkout();
      }
      await workout.markComplete({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
      });
    } else {
      return null;
    }

    sweatStart.endWorkout();
    sweatStart.reset();

    await program.markComplete();
    setLoading(false);
    navigation.popToTop();
  }, [program, loading, sweatStart.repeat]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav
                style={styles.navigationStyle}
                showBack={false}
                onClose={goBack}
              />
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.header}>
                <Text category="h2">Quit Plan</Text>
              </View>
              <Text style={styles.subHeaderText}>
                Are you sure you want to quit this plan?
              </Text>
              {loading ? (
                <View style={styles.loading}>
                  <Spinner />
                </View>
              ) : (
                <View style={styles.inputView}>
                  <View style={styles.inputLabel}>
                    <Layout style={styles.indicator} />
                    <Image
                      style={styles.image as StyleProp<ImageStyle>}
                      source={{ uri: program.coverImage }}
                    />
                    <View>
                      <Text style={styles.inputName}>{program.name}</Text>
                      <Text style={styles.inputDay}>{workout?.dayDisplay}</Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
            <Layout style={styles.actions}>
              <TouchableOpacity
                style={[styles.button, styles.cancelButton]}
                onPress={goBack}
              >
                <Text style={styles.buttonLabel} category="c1">
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.quitButton]}
                onPress={onQuit}
              >
                <Text style={styles.buttonLabel} category="c1">
                  Quit
                </Text>
              </TouchableOpacity>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cancelButton: {
    backgroundColor: 'dark-blue',
  },
  quitButton: {
    backgroundColor: 'red',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  navigationStyle: {
    backgroundColor: 'black',
  },
  contentContainer: {
    flex: 1,
    padding: 24,
  },
  completeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completeIcon: {
    marginRight: 12,
  },
  completeText: {
    color: 'gray',
    textTransform: 'uppercase',
  },
  header: {
    marginTop: -24,
    marginBottom: 20,
  },
  optionItem: {
    marginVertical: 20,
  },
  optionText: {
    fontSize: 18,
    lineHeight: 22,
  },
  inputView: {
    flexDirection: 'row',
    paddingVertical: 15,
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    flex: 1,
    alignItems: 'center',
  },
  inputName: {
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    maxWidth: '100%',
    marginVertical: 5,
  },
  inputDay: {
    fontSize: 10,
    lineHeight: 12,
    color: 'cyan',
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 12,
  },
  subHeaderText: {
    fontSize: 18,
    lineHeight: 22,
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'cyan',
    width: 4,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
});

export default SweatOptionsQuit;
