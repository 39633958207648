import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { ASSESSMENT_TYPE, FORM_ASSESSMENT_SCORING_TYPE } from 'o2x-store/src/models/Assessment';
import React, { useCallback } from 'react';
import { Image, ImageStyle, StyleProp, TouchableOpacity } from 'react-native';
import RightArrow from '../../assets/images/assessment-arrow.svg';

type Props = {
  name: string;
  coverImageThumbnail: string;
  id: number;
  type: ASSESSMENT_TYPE;
  previousScore?: number;
  isSubmission?: boolean;
  scoringType: FORM_ASSESSMENT_SCORING_TYPE | null;
};

const AssessmentItem: React.FC<Props> = ({
  name,
  coverImageThumbnail,
  id,
  type,
  previousScore,
  isSubmission,
  scoringType,
}: {
  name: string;
  coverImageThumbnail: string;
  id: number;
  type: ASSESSMENT_TYPE;
  previousScore?: number;
  isSubmission?: boolean;
  scoringType?: FORM_ASSESSMENT_SCORING_TYPE | null;
}) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const onPressSubmission = useCallback(() => {
    navigation.navigate('Plan', {
      screen: 'AssessmentHistory',
      params: { assessmentId: id, type },
    });
  }, [id, type]);

  const onPress = useCallback(() => {
    if (type === ASSESSMENT_TYPE.BODY) {
      navigation.navigate('BodyCompositionAssessment', {
        assessmentId: id,
        type,
      });
      return;
    }
    navigation.navigate('AssessmentHistory', { assessmentId: id, type });
  }, [id, type, scoringType]);

  return (
    <Layout style={styles.container}>
      <TouchableOpacity
        style={styles.assessment}
        onPress={isSubmission ? onPressSubmission : onPress}>
        <Layout style={styles.assessmentBody}>
          {coverImageThumbnail ? (
            <Layout style={styles.thumbnailWrapper}>
              <Image
                style={styles.thumbnail as StyleProp<ImageStyle>}
                source={{ uri: coverImageThumbnail }}
              />
            </Layout>
          ) : (
            <Layout style={styles.thumbnailWrapper}>
              <Image
                style={styles.thumbnail as StyleProp<ImageStyle>}
                source={require('../../assets/images/logo.png')}
              />
            </Layout>
          )}
          <Layout style={styles.nameContainer}>
            <Text style={styles.name}>{name}</Text>
          </Layout>
        </Layout>

        <Layout style={styles.assessmentFooter}>
          <Text style={styles.actionText}>
            {previousScore ? `View ${name} Results` : 'Take the Assessment & view results'}
          </Text>
          <RightArrow width={24} height={24} />
        </Layout>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    paddingHorizontal: 5,
    width: 242,
  },
  assessment: {
    width: 232,
    height: 220,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
  },
  thumbnailWrapper: {
    width: 228,
    height: 112,
  },
  assessmentBody: {
    width: 228,
    height: 157,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#091C2D',
    flexDirection: 'column',
  },
  actionText: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 1,
    width: 141,
  },
  assessmentFooter: {
    width: 228,
    height: 63,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#1A3248',
    paddingHorizontal: 13,
  },
  nameContainer: {
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#091C2D',
  },
  name: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    textAlign: 'center',
    letterSpacing: 1,
  },
});

export default AssessmentItem;
