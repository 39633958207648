import React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  color: string;
};

const LevelIcon: React.FC<Props> = ({ color }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <Path
      d="M8 13.3337V6.66699"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M12 13.3337V2.66699"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      d="M4 13.3337V10.667"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

export default LevelIcon;
