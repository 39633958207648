import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import SavedList from 'o2x-store/src/models/SavedList';
import React from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

export type Props = {
  list: SavedList | undefined;
  key: string;
  onPress: () => void;
};

const ActiveListItem: React.FC<Props> = (props) => {
  const { list, onPress } = props;
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout
      style={[
        styles.container,
        { borderLeftWidth: 4, borderColor: list?.color, borderStyle: 'solid' },
      ]}
      level="2"
    >
      {list && (
        <TouchableOpacity style={styles.contentContainer} onPress={onPress}>
          {!!list && !!list.image && (
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={{ uri: list.image }}
            />
          )}
          <Layout style={styles.content} level="2">
            <View style={styles.label}>
              <Text numberOfLines={1} style={isMobile && styles.labelMobile}>
                {list.title}
              </Text>
              {!!list.subtitle && (
                <Text
                  style={[
                    isMobile ? styles.subtitleMobile : styles.subtitle,
                    { color: list?.color },
                  ]}
                  numberOfLines={1}
                >
                  {list.subtitle}
                </Text>
              )}
            </View>
          </Layout>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

export const themedStyles = StyleService.create({
  container: {
    marginTop: 12,
    height: 60,
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 5,
    marginRight: 16,
    marginVertical: 8,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  label: {
    width: '90%',
    flexDirection: 'column',
    marginLeft: 5,
  },
  labelMobile: {
    width: '100%',
    fontSize: 14,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    marginTop: 7,
  },
  subtitleMobile: {
    fontSize: 12,
    lineHeight: 22,
    marginTop: 7,
  },
  image: {
    height: 60,
    width: 60,
  },
  plus: {
    width: 22,
    height: 22,
  },
});

export default ActiveListItem;
