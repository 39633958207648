import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isFinite, range } from 'lodash';
import moment from 'moment';
import { PhysicalAssessmentSummary } from 'o2x-store/src/models/PhysicalAssessment';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  maxScore: number;
  summary: PhysicalAssessmentSummary[];
};

// !!!: Month in moment is zero indexed while month in Django is not.
const AssessmentSummaryCalendar: React.FC<Props> = (props) => {
  const { style, maxScore, summary } = props;
  const styles = useStyleSheet(themedStyles);
  // Add 2 since it's zero indexed and we want to include this month.
  const end = moment().month() + 2;

  return (
    <Layout style={[styles.container, style]}>
      {range(end - 6, end).map((month) => {
        const item = summary.find((i) => i.month === month);
        let percentage = 0;
        if (item) {
          percentage = item.averageScore / maxScore;
          if (!isFinite(percentage)) {
            percentage = 0;
          }
        }
        return (
          <Layout style={styles.item} key={month}>
            <Layout style={styles.barContainer}>
              <Layout style={[styles.spacer, { flex: 1 - percentage }]} />
              <Layout style={[styles.bar, { flex: percentage }]} />
            </Layout>
            <Layout style={styles.divider} />
            <Text style={styles.label} category="c2">
              {/* Subtract 1 since moment is zero indexed. */}
              {moment()
                .month(month - 1)
                .format('MMM')}
            </Text>
          </Layout>
        );
      })}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    backgroundColor: 'blue-secondary',
  },
  barContainer: {
    height: 55,
    width: 12,
  },
  container: {
    height: 75,
    flexDirection: 'row',
  },
  divider: {
    height: 2,
    alignSelf: 'stretch',
    backgroundColor: 'white',
  },
  item: {
    flex: 1,
    alignItems: 'center',
  },
  label: {
    marginTop: 2,
    textTransform: 'uppercase',
  },
  spacer: {
    backgroundColor: 'transparent',
  },
});

export default AssessmentSummaryCalendar;
