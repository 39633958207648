import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { filter, indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import SavedList from 'o2x-store/src/models/SavedList';
import { useStore } from 'o2x-store/src/stores';
import { getListByType, getSource, LIST_TYPE } from 'o2x-store/src/utils/list';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FlatList,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'src/components/Question/TopNav';
import DeleteIcon from '../../assets/images/delete.svg';
import CheckBox from '../../components/CheckBox';
import LabeledInput from '../../components/LabeledInput';
import SavedProgramItem from '../../components/Saved/SavedProgramItem';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const ListEdit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { listType, listId },
    },
  } = props;

  const root = useStore();
  const { savedList } = root;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();
  const nameRef = useRef<TextInput>(null);

  const [selectedList, setSelectedList] = useState<SavedList>();
  const [items, setItems] = useState<Map<string, Program>>(new Map<string, Program>());
  const [selectedItems, setSelectedItems] = useState<Array<number>>(new Array<number>());

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const insets = useSafeAreaInsets();

  useEffect(() => {
    const newList = getListByType(savedList, listType, listId);
    setSelectedList(newList);
    const items = new Map<string, Program>();
    const source = getSource(root, listType);
    newList?.items.forEach((itemId: number) => {
      const item = source.get(`${itemId}`);
      if (item) {
        items.set(`${itemId}`, item);
      }
    });
    setItems(items);
  }, [listType, listId]);

  const onSubmitEditingName = useCallback(() => {
    nameRef.current?.focus();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (selectedList) {
        const updateData: { name?: string; items?: number[] } = {
          name: getValues('name'),
        };
        if (selectedItems.length > 0) {
          updateData.items = filter(selectedList.items, (i) => indexOf(selectedItems, i) === -1);
        }
        const result = await savedList.updateList(updateData, listType, selectedList.id);
        setLoading(false);
        if (result.ok) {
          navigation.goBack();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      } else {
        const result = await savedList.createList(data, listType);
        setLoading(false);
        if (result.ok) {
          navigation.goBack();
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [listType, selectedList, selectedItems],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onToggleSelected = (checked: boolean, itemId: number) => {
    if (checked) {
      const newSelectedItems = [...selectedItems, itemId];
      setSelectedItems(newSelectedItems);
    } else {
      const filteredSelectedItems = filter(selectedItems, (i) => i !== itemId);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const removeSelectedFromDisplay = useCallback(() => {
    const filteredItems = new Map<string, Program>();
    items?.forEach((item: Program) => {
      filteredItems.set(`${item.id}`, item);
    });
    selectedItems.forEach((itemId: number) => {
      filteredItems?.delete(`${itemId}`);
    });
    setItems(filteredItems);
  }, [selectedItems, items]);

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <View style={styles.itemContainer}>
          <CheckBox
            key={item.id}
            fill={getTaskColor(item.type)}
            checked={indexOf(selectedItems, item.id) > -1}
            onChange={(key: string, checked: boolean) => {
              onToggleSelected(checked, item.id);
            }}
            style={styles.input}
          />
          <View style={styles.inputLabel}>
            <SavedProgramItem program={item} showHandle={false} key={item.id} />
          </View>
        </View>
      ) : null,
    [selectedItems],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onDelete = useCallback(() => {
    navigation.navigate('ListDelete', { listType, listId });
  }, [selectedList]);

  useEffect(() => {
    register({ name: 'name' }, { required: true });
  }, [register]);

  let placeholder: string = '';
  if (listType === LIST_TYPE.RECIPE) {
    placeholder = 'Favorite recipes';
  } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
    placeholder = 'Favorite workouts';
  }

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onClose={goBack} showBack={false} />
            </View>
            <View style={isDeviceMaxWidth600 ? styles.scrollMobile : styles.scroll}>
              <Layout
                style={isDeviceMaxWidth600 ? styles.scrollWrapperMobile : styles.scrollWrapper}>
                <View
                  style={
                    isDeviceMaxWidth600 ? styles.headerContainerMobile : styles.headerContainer
                  }>
                  {!selectedList ? (
                    <Text category="h2" style={styles.header}>
                      New List
                    </Text>
                  ) : (
                    <>
                      <Text category="h2" style={styles.header}>
                        Edit List
                      </Text>
                      <Text style={[styles.subtitle, { color: selectedList?.color }]}>
                        {selectedList?.subtitle}
                      </Text>
                    </>
                  )}
                  {!!errorMessage && (
                    <Text style={styles.error} category="label">
                      {errorMessage}
                    </Text>
                  )}
                  <View style={styles.inlineInput}>
                    <TouchableOpacity
                      style={
                        isDeviceMaxWidth600 ? styles.inputContainerMobile : styles.inputContainer
                      }>
                      <LabeledInput
                        style={
                          isDeviceMaxWidth600 ? styles.inputContainerMobile : styles.inputContainer
                        }
                        label="Name"
                        returnKeyType="go"
                        onChangeText={(text) => setValue('name', text)}
                        onSubmitEditing={onSubmitEditingName}
                        ref={nameRef}
                        placeholder={placeholder}
                        divider
                        defaultValue={selectedList?.name || ''}
                        multiline
                      />
                    </TouchableOpacity>
                    {selectedList && (
                      <TouchableOpacity style={styles.delete} onPress={onDelete}>
                        <DeleteIcon style={styles.deleteIcon} />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
                <View style={styles.listContainer}>
                  {selectedList && (
                    <View style={styles.actionLinks}>
                      <Text style={{ color: selectedList.color, fontSize: 14 }}>
                        {selectedItems.length} Selected
                      </Text>
                      <TouchableOpacity onPress={removeSelectedFromDisplay}>
                        <Text style={styles.link}>Remove</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {items && (
                    <FlatList
                      data={Array.from(items.values())}
                      renderItem={renderItem}
                      keyExtractor={(item) => `${item.id}`}
                      style={isMobile ? styles.listItemMobile : styles.listItem}
                    />
                  )}
                </View>
              </Layout>
            </View>
            {!selectedList ? (
              <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
                <TouchableOpacity
                  style={styles.button}
                  disabled={loading}
                  onPress={onSubmitWrapped}>
                  <Text style={styles.buttonLabel} category="c1">
                    Create
                  </Text>
                </TouchableOpacity>
              </Layout>
            ) : (
              <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
                <TouchableOpacity
                  style={[styles.button, styles.buttonCancel]}
                  disabled={loading}
                  onPress={goBack}>
                  <Text style={styles.buttonLabel} category="c1">
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} disabled={loading} onPress={onSubmit}>
                  <Text style={styles.buttonLabel} category="c1">
                    Save
                  </Text>
                </TouchableOpacity>
              </Layout>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'olive',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    marginVertical: 30,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center',
  },
  inputContainer: {
    flex: 1,
    width: 410,
  },
  inputContainerMobile: {
    flex: 1,
    width: '100%',
  },
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    alignItems: 'center',
  },
  scrollMobile: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
  },
  scrollWrapper: {
    flex: 1,
  },
  scrollWrapperMobile: {
    width: '100%',
  },
  delete: {
    width: 24,
    marginLeft: 10,
  },
  deleteText: {
    color: 'red',
    fontSize: 18,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    marginBottom: 20,
  },
  inlineInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 10,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  input: {
    paddingTop: 30,
  },
  itemContainer: {
    flexDirection: 'row',
  },
  inputLabel: {
    marginLeft: 24,
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  headerContainerMobile: {
    width: '100%',
    paddingHorizontal: 15,
  },
  listItem: {
    paddingHorizontal: 24,
  },
  listItemMobile: {
    paddingHorizontal: 15,
  },
  listContainer: {
    flex: 1,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: -20,
    marginBottom: 30,
  },
});

export default observer(ListEdit);
