import { useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { keys } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import { getListMapByType, LIST_TYPE } from 'o2x-store/src/utils/list';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FlatGrid } from 'react-native-super-grid';
import { useMediaQuery } from 'react-responsive';
import FilterInput from 'src/components/FilterInput';
import TopNav from 'src/components/Question/TopNav';
import ThriveYogaItem from 'src/components/ThriveYoga/ThriveYogaItem';
import { Filters } from 'src/models/ExploreFilter';
import { useNativeStore } from 'src/stores';
import Yoga from '../../assets/images/yoga-icon.svg';

type Props = {};

const ThriveYoga: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const { thrive, savedList: savedListStore } = useStore();
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(true);
  const { exploreFilter } = useNativeStore();
  const { search, filters } = exploreFilter;
  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (exploreFilter.isFiltering) {
        await thrive.searchThriveExercises(filtersToQS(search, filters));
      } else {
        await savedListStore.fetchLists(LIST_TYPE.THRIVE_EXERCISE);
        await thrive.fetchThriveExercises();
      }
      setLoading(false);
    })();
  }, [exploreFilter.isFiltering, search, filters]);

  const filtersToQS = (searchStr: string, searchFilters: Filters) => {
    const queryParams: string[] = [];
    if (searchStr) {
      queryParams.push(`q=${searchStr}`);
    }
    if (searchFilters) {
      keys(searchFilters).forEach((k) => {
        if (searchFilters[k]) {
          queryParams.push(`difficulty=${k}`);
        }
      });
    }
    return queryParams.join('&');
  };
  const goBack = useCallback(() => {
    exploreFilter.reset();
    navigation.goBack();
  }, []);

  const renderItem = useCallback(
    ({ item }) => {
      const savedListMap = getListMapByType(savedListStore, LIST_TYPE.THRIVE_EXERCISE);
      return (
        <View style={styles.itemSpacing}>
          <ThriveYogaItem item={item} savedList={savedListMap} />
        </View>
      );
    },
    [savedListStore.thriveExerciseList.values()],
  );

  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);

  const onOpenFilter = useCallback(() => {
    navigation.navigate('ThriveYogaFilter', {
      selected: THRIVE_ACTIVITY_TYPE.YOGA,
    });
  }, []);

  const data = useMemo(
    () => Array.from(thrive.thriveExercises.values()).filter((exercise) => exercise.isYoga),
    [search, filters, thrive.thriveExercises.values()],
  );

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav onBack={goBack} showClose={false} />
      </View>

      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.content}>
          <View style={styles.header}>
            <Yoga height={30} width={30} />
            <Text style={styles.title}> Yoga</Text>
          </View>
          <View style={styles.contentWrapper}>
            <FilterInput onPress={onOpenFilter} selected={TASK_TYPE.THRIVE} />
            <View style={isMobile ? styles.scrollMobile : styles.scroll}>
              <View style={styles.list}>
                <FlatGrid
                  data={data}
                  renderItem={renderItem}
                  itemDimension={242}
                  style={styles.listContainer}
                  ItemSeparatorComponent={renderSeparator}
                  keyExtractor={(item) => `${item.id}`}
                />
              </View>
            </View>
          </View>
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  navigationContainer: {
    marginHorizontal: 24,
    paddingVertical: 12,
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  list: {
    paddingBottom: 24,
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 24,
  },
  header: {
    paddingHorizontal: 24,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginLeft: 6,
  },
  itemSpacing: {
    marginVertical: 5,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    margiVertical: 20,
  },
  scroll: {
    flex: 1,
    height: '90%',
  },
  scrollMobile: {
    width: '100%',
    height: '90%',
  },
  contentWrapper: {
    marginRight: 40,
    flex: 1,
  },
  spacer: {
    height: 12,
  },
});

export default observer(ThriveYoga);
