import { useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { DashboardModel } from 'o2x-store/src/models/Dashboard';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DashboardItem from 'src/components/FTE/DashboardItem';
import TopNav from 'src/components/Question/TopNav';

type Props = {
  route: any;
};

const DashboardFte: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  const [nextRequest, setNextRequest] = useState('');
  const [dashboard, setDashboard] = useState<Array<DashboardModel>>();
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const store = useStore();

  const { selectedFte } = props.route.params;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        const fetchData = await store.dashboard.fetchDashboard(selectedFte.id);
        setDashboard(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setLoading(false);
      })();
    }, []),
  );

  const renderItem = useCallback(
    ({ item }) => <DashboardItem item={item} />,
    [],
  );

  const renderHeader = useCallback(
    () => <View style={styles.listHeader} />,
    [],
  );

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const loadMore = useCallback(async () => {
    if (nextRequest) {
      setLoadingMore(true);
      const fetchData = await store.dashboard.fetchDashboard(
        undefined,
        nextRequest,
      );
      setDashboard([...dashboard!, ...fetchData.extra?.results]);
      setNextRequest(fetchData.extra?.next);
      setLoadingMore(false);
    }
  }, [dashboard, nextRequest]);

  return (
    <Layout
      style={[
        styles.container,
        { paddingTop: insets.top, paddingBottom: insets.bottom },
      ]}
    >
      <View style={styles.header}>
        <Text style={styles.headerText}>Dashboard</Text>
      </View>
      {loading ? (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      ) : (
        <FlatList
          data={dashboard}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListHeaderComponent={renderHeader}
          onEndReachedThreshold={0.5}
          onEndReached={loadMore}
        />
      )}
      {loadingMore && (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      )}
      <View style={styles.navigationContainer}>
        <TopNav showClose={false} style={styles.topNav} />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 54,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 22,
    alignSelf: 'center',
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  loading: {
    margin: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  listHeader: {
    height: 16,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
});

export default observer(DashboardFte);
