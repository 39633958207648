import { Input, Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import ExerciseParameterModal from 'src/components/FTE/ExerciseParameterModal';
import AddExerciseModal from './AddExerciseModal';
import ExerciseItem from './ExerciseItem';

type Props = {
  type: string;
  exerModalVisible: boolean;
  paramModalVisible: boolean;
  setParamModalVisible: Function;
  exerciseList: SweatGlobalStepExercise[];
  setExerciseList: Function;
  loadingSave: boolean;
  showExerModal: Function;
  hideExerModal: () => void;
  name: string;
  setName: Function;
  onSubmit: Function;
  onSubmitEdit: Function;
  data: Partial<SweatGlobalStep>;
  disabled: boolean;
  id?: number;
};

const CreateStep: React.FC<Props> = (props) => {
  const {
    type,
    exerModalVisible,
    paramModalVisible,
    setParamModalVisible,
    exerciseList,
    setExerciseList,
    loadingSave,
    showExerModal,
    hideExerModal,
    name,
    setName,
    onSubmit,
    onSubmitEdit,
    data,
    disabled,
    id,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [exerIndex, setExerIndex] = useState(0);
  const [exercise, setExercise] = useState<SweatGlobalStepExercise>();
  const [removeIndex, setRemoveIndex] = useState<number>();

  useEffect(() => {
    if (removeIndex! >= 0) {
      const list = [...exerciseList!];
      list.splice(removeIndex, 1);
      setExerciseList([...list]);
      setRemoveIndex(undefined);
    }
  }, [removeIndex]);

  const renderItem = useCallback(
    ({ item, index, drag }: RenderItemParams<SweatGlobalStepExercise>) => (
      <ExerciseItem
        index={index}
        exercise={item}
        setExerIndex={setExerIndex}
        setExercise={setExercise}
        setParamModalVisible={setParamModalVisible}
        style={styles.exerciseContainer}
        exerciseData={JSON.stringify(item)}
        onRemoveExercise={() => setRemoveIndex(index)}
        content="global"
        exerciseList={exerciseList}
        drag={drag}
      />
    ),
    [exerciseList],
  );
  return (
    <ScrollView style={styles.container}>
      {!!exerModalVisible && (
        <AddExerciseModal
          content="global"
          exerModalVisible={exerModalVisible}
          hideExerModal={hideExerModal}
          exerciseList={exerciseList}
          setExerciseList={setExerciseList}
        />
      )}
      {!!paramModalVisible && (
        <ExerciseParameterModal
          content="global"
          paramModalVisible={paramModalVisible}
          setParamModalVisible={setParamModalVisible}
          exercise={exercise}
          exerciseIndex={exerIndex}
          exerciseList={exerciseList}
          setExerciseList={setExerciseList}
        />
      )}
      <Layout style={styles.header}>
        <Text style={styles.headerText}>{type === 'prepare' ? 'Prepare' : 'Recover'}</Text>
        {loadingSave ? (
          <Layout style={styles.loadingSave}>
            <Spinner />
          </Layout>
        ) : (
          <>
            <TouchableOpacity
              style={[styles.saveButton, disabled && { opacity: 0.3 }]}
              onPress={() => onSubmitEdit(id, data)}
              disabled={disabled}>
              <Text style={styles.saveText}>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.saveButton, { marginLeft: 10 }]}
              onPress={() => onSubmit(data)}>
              <Text style={styles.saveText}>Save {type === 'prepare' ? 'Prepare' : 'Recover'}</Text>
            </TouchableOpacity>
          </>
        )}
      </Layout>

      <Input
        size="small"
        placeholder="Name"
        value={name}
        onChangeText={(text) => setName(text)}
        style={styles.input}
      />
      {/* {exerciseList?.map((exercise, index) => {
        return (
          <ExerciseItem
            index={index}
            exercise={exercise}
            setExerIndex={setExerIndex}
            setExercise={setExercise}
            setParamModalVisible={setParamModalVisible}
            style={styles.exerciseContainer}
            exerciseData={JSON.stringify(exercise)}
            onRemoveExercise={() => setRemoveIndex(index)}
            content="global"
            exerciseList={exerciseList}
          />
        );
      })} */}
      {exerciseList && (
        <DraggableFlatList
          data={exerciseList}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${item.id!}-${index}`}
          onDragEnd={({ data }) => setExerciseList(data)}
        />
      )}

      <TouchableOpacity style={styles.addContainer} onPress={() => showExerModal()}>
        <Text style={styles.addText}>+ Add Exercise</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '45%',
    height: '100%',
  },
  header: {
    marginTop: 15,
    width: '85%',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: 16,
    letterSpacing: 1,
  },
  input: {
    width: '85%',
    alignSelf: 'center',
    marginTop: 10,
  },
  exerciseContainer: {
    height: 30,
    width: '82%',
    // alignSelf: 'center',
    borderWidth: 2,
    borderColor: '#767F6A',
    marginTop: 5,
    marginBottom: 5,
  },
  exerciseText: {
    fontSize: 16,
    letterSpacing: 1,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  addContainer: {
    height: 32,
    width: '85%',
    alignSelf: 'center',
    backgroundColor: '#767F6A',
    marginTop: 10,
  },
  addText: {
    fontSize: 16,
    letterSpacing: 1,
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  saveButton: {
    width: 100,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 'auto',
  },
  loadingSave: {
    position: 'absolute',
    padding: 8,
    right: 30,
    top: 12,
    backgroundColor: 'transparent',
  },
  saveText: {
    margin: 'auto',
    color: 'white',
    fontSize: 12,
  },
});

export default React.memo(CreateStep, (prevProps, nextProps) => {
  if (
    prevProps.exerciseList !== nextProps.exerciseList ||
    prevProps.name !== nextProps.name ||
    prevProps.exerModalVisible !== nextProps.exerModalVisible ||
    prevProps.paramModalVisible !== nextProps.paramModalVisible
  ) {
    return false;
  }
  return true;
});
