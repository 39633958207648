import { computed } from 'mobx';

import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { getTaskColor, TASK_COMPONENT_TYPE, TASK_TYPE } from '../utils/tasks';

@model('o2x-store/Task')
export default class Task extends Model({
  id: prop<string | null>(),
  component: prop<TASK_COMPONENT_TYPE>(),
  title: prop<string>(''),
  subtitle: prop<string>(''),
  type: prop<TASK_TYPE>(),
  image: prop<string>(''),
  rating: prop<number>(3.8),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<Task>) {
    Object.assign(this, data);
  }

  @computed
  get name(): string {
    return this.title;
  }

  @computed
  get color(): string {
    return getTaskColor(this.type);
  }

  @computed
  get key(): string {
    let id: string = this.component;
    if (this.id) {
      id += `-${this.id}`;
    }
    return id;
  }
}
