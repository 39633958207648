import { Audio } from 'expo-av';
import { first, get, last } from 'lodash';
import { computed, observable } from 'mobx';
import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatWorkout, {
  SweatStartSection,
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import SweatStore from 'o2x-store/src/stores/SweatStore';
import { InteractionManager } from 'react-native';
import { getTimeDisplay } from '../utils/timeDisplay';

export enum TIMER_TYPE {
  TIMER = 'timer', // N to 0
  STOPWATCH = 'stopwatch', // 0 to N
}
export const DEFAULT_TIME = 60;
export const DEFAULT_TIME_TABATA = 20;
export const DEFAULT_REST_TABATA = 10;
export const DEFAULT_REST_GLOBAL = 10;

export enum SET_SIDE_DISPLAY {
  RIGHT = 'Right',
  LEFT = 'Left',
}
const SET_SIDE_DISPLAY_OPTIONS = [SET_SIDE_DISPLAY.LEFT, SET_SIDE_DISPLAY.RIGHT];

const READY_TIME = ['READY', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1'];

@model('o2x-native/SweatStart')
export default class SweatStart extends Model({
  // Time values are in seconds.
  totalTime: prop<number>(0),
  breakdownTime: prop<{ [key: number]: number }>(() => ({})),
  globalBreakdownTime: prop<{ [key: number]: number }>(() => ({})), // prepare and recover
  circuitBreakdownSets: prop<{ [key: number]: number }>(() => ({})),
  circuitSets: prop<{ [key: number]: number }>(() => ({})),
  workoutId: prop<number | null>(null),
  stepId: prop<number | null>(null),
  isGlobalStep: prop<boolean>(false),
  stepExerciseId: prop<number | null>(null),
  exerciseId: prop<number | null>(null),
  expandStepExercise: prop<number | null>(null),
  expandStep: prop<number | null>(null),
  repeat: prop<boolean>(false),
  isWorkoutGenerator: prop<boolean>(false),
  elapsedTime: prop<{ [key: number]: number }>(() => ({})),
  // Was previously used to determine if we show "start next workout day".
  finished: prop<boolean>(false),
}) {
  timer: NodeJS.Timeout | null = null;

  timerUpdated: number = 0;

  workout?: SweatWorkout;

  steps?: SweatStartSection[];

  step?: SweatStartSection;

  exercise?: SweatExercise;

  beepSound: Audio.Sound | null = null;

  enableReadyInBetween: boolean = false;

  @observable
  showReady: boolean = false;

  @observable
  readyIndex: number = 0;

  @observable
  type: TIMER_TYPE = TIMER_TYPE.TIMER;

  @observable
  countDown: number = DEFAULT_TIME;

  @observable
  currentCircuitRound: number = 1;

  @observable
  circuitExerciseTime: number = 0;

  @observable
  currentRound: number = 1;

  @observable
  stepExercise?: SweatWorkoutStepExercise | SweatGlobalStepExercise;

  @observable
  stepExerciseOrder: number = 0;

  @observable
  stepExerciseSet: number = 1;

  @observable
  currentStepExerciseSet: number = 1;

  @observable
  active = false;

  @observable
  isRestTimer: boolean = false;

  @observable
  workoutTimerRunning: boolean = false;

  @computed
  get currentCircuitSet(): number {
    if (!this.step) {
      return 0;
    }
    return this.step.circuitSets - this.circuitSets[this.step.id] + 1;
  }

  @computed
  get nextCircuitSet(): number | undefined {
    const nextStep = this.getNextStep;

    if (nextStep) {
      const nextIsCircuit = nextStep.circuitSets !== 1;
      if (nextIsCircuit) {
        const nextCircuit = get(this.circuitSets, nextStep.id);
        if (nextCircuit) {
          const nextStepExercise = this.getNextStepExercise(false);
          if (first(nextStep.exercises)!.id === nextStepExercise!.id) {
            return nextStep.circuitSets - nextCircuit + 2;
          }
          return nextStep.circuitSets - nextCircuit + 1;
        }
        if (nextStep.circuitSets === 0) {
          return Infinity;
        }
        return 1;
      }
    }
  }

  exerciseTimeDisplay(id: number): string {
    if (this.showReady) {
      return this.readyDisplay;
    }
    if (this.isGlobalStep) {
      return this.stepExercise?.workoutTimerWork
        ? getTimeDisplay(this.countDown)
        : getTimeDisplay(get(this.globalBreakdownTime, id, 0));
    }
    if (this.type === TIMER_TYPE.TIMER) {
      return getTimeDisplay(this.countDown);
    }
    if (this.isCircuitExercise) {
      return getTimeDisplay(this.circuitExerciseTime);
    }
    return getTimeDisplay(get(this.breakdownTime, id, 0));
  }

  @computed
  get readyDisplay(): string {
    if (this.showReady && this.readyIndex < READY_TIME.length) {
      return READY_TIME[this.readyIndex];
    }
    return 'READY';
  }

  @computed
  get shouldBlink(): boolean {
    return !!(
      (this.showReady &&
        (this.readyIndex === 0 ||
          (READY_TIME.length - this.readyIndex <= 3 &&
            (READY_TIME.length - this.readyIndex) % 2))) ||
      (this.type === TIMER_TYPE.TIMER && this.countDown <= 3 && this.countDown % 2)
    );
  }

  @computed
  get shouldBeep(): boolean {
    return (
      (this.showReady && READY_TIME.length - this.readyIndex <= 3) ||
      (this.type === TIMER_TYPE.TIMER && this.countDown <= 3)
    );
  }

  @computed
  get totalTimeDisplay(): string {
    return getTimeDisplay(this.totalTime);
  }

  @computed
  get isExerciseSetDone(): boolean {
    // changed this to handle bug when current set > total sets
    // return this.currentStepExerciseSet >= this.stepExerciseSet;
    return this.stepExercise?.isRepsEachSide
      ? this.stepExercise?.setsAsNumber
        ? this.currentStepExerciseSet >= this.stepExercise?.setsAsNumber * 2
        : this.currentStepExerciseSet >= 2
      : this.currentStepExerciseSet >= this.stepExercise?.setsAsNumber!;
  }

  @computed
  get currentStepExerciseSetDisplay(): string {
    if (this.stepExercise?.isRepsEachSide) {
      return `${Math.ceil(this.currentStepExerciseSet / 2)}`;
    }
    return `${this.currentStepExerciseSet}`;
  }

  @computed
  get currentStepExerciseSetSideDisplay(): SET_SIDE_DISPLAY | '' {
    if (this.stepExercise?.isRepsEachSide) {
      return SET_SIDE_DISPLAY_OPTIONS[(this.currentStepExerciseSet - 1) % 2]; // 2 being length of SET_SIDE_DISPLAY_OPTIONS
    }
    return '';
  }

  @modelAction
  initStepExercise(stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise | undefined) {
    if (stepExercise) {
      const timerWork = stepExercise.workoutTimerWork || this.step?.circuitTimerWork;
      if (!stepExercise.setsAsNumber && stepExercise.isRepsEachSide) {
        this.stepExerciseSet = 2;
      }
      if (this.step?.circuitTimer === 'variable' || !this.step?.circuitTimer) {
        if (timerWork) {
          this.type = TIMER_TYPE.TIMER;
          this.countDown = timerWork;
        } else {
          this.type = TIMER_TYPE.STOPWATCH;
          this.countDown = DEFAULT_TIME;
        }
        if (stepExercise.setsAsNumber) {
          this.stepExerciseSet = stepExercise.isRepsEachSide
            ? stepExercise.setsAsNumber * 2
            : stepExercise.setsAsNumber;
        } else if (!stepExercise.setsAsNumber && stepExercise.isRepsEachSide) {
          this.stepExerciseSet = 2;
        } else {
          this.stepExerciseSet = 1;
        }
      } else if (this.isGlobalStep) {
        this.type = TIMER_TYPE.STOPWATCH;
        this.countDown = DEFAULT_TIME;
        this.stepExerciseSet = 1;
      } else if (this.step!.circuitTimer === 'tabata') {
        this.countDown = DEFAULT_TIME_TABATA;
        this.stepExerciseSet = 1;
      } else if (this.step!.circuitTimer === 'emom') {
        this.countDown = DEFAULT_TIME;
        this.stepExerciseSet = 1;
      } else if (this.step!.circuitTimer === 'amrap') {
        this.countDown = this.step?.circuitTimerWork || DEFAULT_TIME;
        this.stepExerciseSet = 1;
      } else {
        this.type = TIMER_TYPE.STOPWATCH;
        this.countDown = DEFAULT_TIME;
        this.stepExerciseSet = 1;
      }
    }
    this.stepExercise = stepExercise;
    this.currentStepExerciseSet = 1;
    this.readyIndex = 0;
  }

  @modelAction
  prevExerciseSet() {
    if (this.stepExercise) {
      if (this.stepExercise.workoutTimerWork && this.currentStepExerciseSet) {
        this.currentStepExerciseSet = Math.max(this.currentStepExerciseSet - 1, 0);
        this.countDown = this.stepExercise.workoutTimerWork; // reset
      }
    }
  }

  @modelAction
  nextExerciseSet(sweat: SweatStore) {
    if (this.stepExercise) {
      if (this.stepExercise.workoutTimerWork && this.currentStepExerciseSet) {
        this.currentStepExerciseSet += 1;
        this.countDown = this.stepExercise.workoutTimerWork; // reset
      } else if (this.step?.circuitTimerWork && this.currentStepExerciseSet) {
        this.currentStepExerciseSet += 1;
        this.countDown = this.step?.circuitTimerWork; // reset
      } else if (
        !this.stepExercise.workoutTimerWork &&
        !this.step?.circuitTimerWork &&
        this.currentStepExerciseSet &&
        (this.step?.circuitTimer === 'variable' || !this.step?.circuitTimer || this.isGlobalStep)
      ) {
        this.currentStepExerciseSet += 1;
        this.type = TIMER_TYPE.STOPWATCH;
        this.circuitExerciseTime = 0; // reset
      }
      this.isRestTimer = false;
    }
  }

  @modelAction
  startWorkout(workout: SweatWorkout, sweat: SweatStore, customized?: boolean) {
    const steps = workout.sweatStartSections;
    const step = get(steps, 0);
    const stepExercise = get(step, 'exercises[0]');
    const exercise = get(stepExercise, 'exercise');
    if (!steps || steps.length === 0 || !step || !stepExercise || !exercise) {
      console.warn('[DEBUG] there are no steps or exercises yet', getSnapshot(workout));
      return;
    }

    this.finished = false;
    this.workout = workout;
    this.steps = steps;
    this.step = step;
    this.isGlobalStep = step.id < 0;
    this.circuitSets[step.id] = step.circuitSets === 0 ? Infinity : step.circuitSets;
    this.initStepExercise(stepExercise);
    this.exercise = sweat.getSweatExercise(exercise)!;
    this.workoutId = workout.id;
    this.stepId = step.id;
    this.stepExerciseId = stepExercise.id;
    this.stepExerciseOrder = 0;
    this.exerciseId = exercise;
    this.expandStepExercise = stepExercise.id;
    this.expandStep = step.id;
    if (step.circuitTimer === 'amrap') {
      this.currentCircuitRound = 0;
    } else {
      this.currentCircuitRound = 1;
    }
    this.circuitExerciseTime = 0;
    if (!this.isRestTimer) {
      this.showReady = true;
    }
    this.readyIndex = 0;
    if (!customized) {
      this.initializeTimerWork();
      this.initializeBeep();
      this.startTimer();
    }
  }

  @modelAction
  endWorkout() {
    this.finished = true;
    this.stopTimer();
    /*
    this.workout = undefined;
    this.steps = undefined;
    this.step = undefined;
    this.circuitSets = {};
    this.stepExercise = undefined;
    this.exercise = undefined;
    this.workoutId = null;
    this.stepId = null;
    this.stepExerciseId = null;
    this.exerciseId = null;
    this.expandStepExercise = null;
    */
  }

  @modelAction
  reset() {
    this.finished = false;
    this.stopTimer();
    this.workout = undefined;
    this.steps = undefined;
    this.step = undefined;
    this.isGlobalStep = false;
    this.stepExercise = undefined;
    this.stepExerciseSet = 1;
    this.exercise = undefined;
    this.workoutId = null;
    this.stepId = null;
    this.stepExerciseId = null;
    this.stepExerciseOrder = 0;
    this.exerciseId = null;
    this.expandStepExercise = null;
    this.expandStep = null;
    this.totalTime = 0;
    this.breakdownTime = {};
    this.circuitSets = {};
    this.globalBreakdownTime = {};
    this.circuitBreakdownSets = {};
    this.repeat = false;
    this.currentCircuitRound = 1;
    this.circuitExerciseTime = 0;
    this.currentStepExerciseSet = 1;
  }

  @modelAction
  prevExercise = (sweat: SweatStore) => {
    if (!this.step || !this.stepExercise) {
      return;
    }

    let step: SweatStartSection;
    let stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise;

    let newStepExerciseOrder = 0;

    if (this.stepExerciseOrder === 0) {
      // if (this.circuitSets[this.step.id] < this.step.circuitSets) { <-- Why is this commented out? - G
      step = this.step;
      stepExercise = last(step.exercises)!;
      newStepExerciseOrder = step.exercises.length - 1;
    } else {
      step = this.step;
      stepExercise = get(step, ['exercises', this.stepExerciseOrder - 1]);
      newStepExerciseOrder = this.stepExerciseOrder - 1;
    }

    const exercise = get(stepExercise, 'exercise');
    this.step = step;
    this.isGlobalStep = step.id < 0;
    this.exercise = sweat.getSweatExercise(exercise);
    this.stepId = step.id;
    this.stepExerciseId = stepExercise.id;
    this.stepExerciseOrder = newStepExerciseOrder;
    this.exerciseId = exercise;
    this.expandStepExercise = stepExercise.id;
    this.expandStep = step.id;

    // skipped for amrap, so timer won't reset
    if (this.step.circuitTimer !== 'amrap') {
      this.initStepExercise(stepExercise);
      this.circuitExerciseTime = 0;
      this.initializeTimerWork();
    } else {
      // set exercise for rep count/details display
      this.stepExercise = stepExercise;
    }
  };

  @computed
  get getNextStep(): SweatWorkoutStep | undefined {
    if (!this.step || !this.stepExercise) {
      return;
    }

    if (last(this.step.exercises)!.id === this.stepExerciseId) {
      if (this.circuitSets[this.step.id] > 1) {
        return this.step;
      }
      return get(this, ['steps', this.step.order + 1]);
    }
    return this.step;

    return;
  }

  @computed
  get getPrevStep(): SweatWorkoutStep | undefined {
    if (!this.step || !this.stepExercise) {
      return;
    }
    if (this.step.order) {
      return get(this, ['steps', this.step.order - 1]);
    }
  }

  @modelAction
  getNextStepExercise = (forDisplay: boolean = false): SweatWorkoutStepExercise | undefined => {
    if (!this.step || !this.stepExercise) {
      return;
    }

    let step: SweatStartSection;

    if (last(this.step.exercises)!.id === this.stepExerciseId) {
      if (this.circuitSets[this.step.id] > 1) {
        if (this.step.exercises) {
          return this.step.exercises[0];
        }
      } else {
        if (this.step.circuitTimer === 'amrap' && forDisplay) {
          if (this.step.exercises) {
            return this.step.exercises[0];
          }
        }
        step = get(this, ['steps', this.step.order + 1]);
        if (step) {
          if (step.exercises) {
            return step.exercises[0];
          }
        }
      }
    } else if (this.step.exercises) {
      if (this.stepExerciseOrder + 1 < this.step.exercises.length) {
        return get(this.step, ['exercises', this.stepExerciseOrder + 1]);
      }
    }
  };

  @computed
  get isCircuitExercise(): boolean {
    if (!this.step) {
      return false;
    }

    return this.step.circuitSets !== 1 || !!this.step.circuitTimer;
  }

  @modelAction
  setExercise = (sweat: SweatStore, stepExerciseId: number) => {
    if (!this.step || !this.stepExercise) {
      return;
    }

    let stepExercise: SweatWorkoutStepExercise;
    const stepExerciseOrder = this.step.exercises.findIndex((e) => e.id === stepExerciseId);
    stepExercise = get(this.step, ['exercises', stepExerciseOrder]);
    const exercise = get(stepExercise, 'exercise');

    this.exercise = sweat.getSweatExercise(exercise);

    this.stepExerciseId = stepExerciseId;
    this.exerciseId = exercise;
    this.expandStepExercise = stepExerciseId;
    this.stepExerciseOrder = stepExerciseOrder;

    if (this.step.circuitTimer !== 'amrap') {
      this.initStepExercise(stepExercise);
      this.circuitExerciseTime = 0;
      this.initializeTimerWork();
    } else {
      // set exercise for rep count/details display
      this.stepExercise = stepExercise;
    }
  };

  @computed
  get isFirstExerciseInStep() {
    if (this.step) {
      if ((this.step.exercises || []).length > 0) {
        return this.step.exercises[0]!.id === this.stepExerciseId;
      }
    }
    return false;
  }

  @computed
  get isLastExerciseInStep() {
    if (this.step) {
      return last(this.step.exercises || [])!.id === this.stepExerciseId;
    }
    return false;
  }

  @computed
  get isLastExerciseInRound() {
    if (this.step?.specifyRounds && this.stepExercise) {
      return (
        last(this.step.exercises.filter((e) => e.round === this.stepExercise!.round)) ===
        this.stepExercise!.round
      );
    }
    return true;
  }

  @modelAction
  nextExercise = (sweat: SweatStore) => {
    if (!this.step || !this.stepExercise) {
      return;
    }
    let step: SweatStartSection;
    let stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise;

    let newStepExerciseOrder = 0;

    if (last(this.step.exercises)!.id === this.stepExerciseId) {
      step = this.step;
      stepExercise = step.exercises[0];
      newStepExerciseOrder = 0;
    } else {
      step = this.step;
      stepExercise = get(step, ['exercises', this.stepExerciseOrder + 1]);
      newStepExerciseOrder = this.stepExerciseOrder + 1;
    }

    const exercise = get(stepExercise, 'exercise');

    this.step = step;
    this.isGlobalStep = step.id < 0;
    this.exercise = sweat.getSweatExercise(exercise);
    this.stepId = step.id;
    this.stepExerciseId = stepExercise.id;
    this.stepExerciseOrder = newStepExerciseOrder;
    this.exerciseId = exercise;
    this.expandStepExercise = stepExercise.id;
    this.expandStep = step.id;

    // skipped for amrap, so timer won't reset
    if (this.step.circuitTimer !== 'amrap') {
      this.initStepExercise(stepExercise);
      if (this.enableReadyInBetween) {
        if (!this.isRestTimer) {
          this.showReady = true; // case when switching between global
        }
      }
      this.isRestTimer = false;
      this.circuitExerciseTime = 0;
      this.initializeTimerWork();
    } else {
      // set exercise for rep count/details display
      this.stepExercise = stepExercise;
    }
  };

  @modelAction
  nextRound = (sweat: SweatStore) => {
    if (!this.step) {
      return;
    }

    this.currentRound += 1;
    this.currentCircuitRound += 1;
    if (this.enableReadyInBetween) {
      if (!this.isRestTimer && this.step?.circuitTimer !== 'emom') {
        this.showReady = true;
      }
    }
    // reset exercise order
    this.stepExerciseOrder = 0;

    this.isRestTimer = false;
    this.readyIndex = 0;

    this.circuitExerciseTime = 0; // reset stopwatch every round
    this.countDown = this.step.circuitTimerRest || DEFAULT_REST_TABATA;

    const maxExerciseRound = this.step?.exercises[this.step?.exercises.length - 1].round;

    // this gets next round % max round value set on exercises in the step
    const nextExerciseRound = (this.currentRound - 1) % (maxExerciseRound + 1);

    const exerciseIndex = (this.step?.exercises || []).findIndex(
      (e) => e.round === nextExerciseRound,
    );

    // for EMOM only
    if (this.step.specifyRounds) {
      if (exerciseIndex || exerciseIndex === 0) {
        const stepExercise: SweatWorkoutStepExercise = this.step.exercises[exerciseIndex];
        const exercise = get(stepExercise, 'exercise');
        this.initStepExercise(stepExercise);
        this.stepExerciseId = stepExercise.id;
        this.exerciseId = exercise;
      }
    } else {
      const stepExercise: SweatWorkoutStepExercise = this.step.exercises[0];
      this.initStepExercise(stepExercise);
      const exercise = get(stepExercise, 'exercise');
      this.exercise = sweat.getSweatExercise(exercise);
      this.stepExerciseId = stepExercise.id;
      this.exerciseId = exercise;
      this.circuitSets[this.step.id] -= 1;
    }

    this.initializeTimerWork();
  };

  @modelAction
  repeatCircuit = (sweat: SweatStore) => {
    if (!this.step) {
      return;
    }

    this.currentRound = 1;
    this.currentCircuitRound = 1;
    this.isRestTimer = false;
    this.readyIndex = 0;
    if (!this.isRestTimer) {
      if (this.enableReadyInBetween) {
        this.showReady = true;
      }
    }

    this.initStepExercise(this.stepExercise);
    this.initializeTimerWork();
  };

  @modelAction
  prevRound = (sweat: SweatStore) => {
    if (!this.step) {
      return;
    }

    this.currentRound = Math.max(1, this.currentRound - 1);
    this.currentCircuitRound = Math.max(1, this.currentCircuitRound - 1);
    this.isRestTimer = false;
    this.readyIndex = 0;
    if (this.enableReadyInBetween) {
      this.showReady = true;
    }
    // reset to 0
    this.stepExerciseOrder = 0;

    if (this.step.specifyRounds) {
      const maxExerciseRound = this.step?.exercises[this.step?.exercises.length - 1].round;

      // this gets prev round % max round value set on exercises in the step
      const prevExerciseRound = (this.currentRound - 1) % (maxExerciseRound + 1);

      const exerciseIndex = (this.step?.exercises || []).findIndex(
        (e) => e.round === prevExerciseRound,
      );

      if (exerciseIndex > -1) {
        const stepExercise: SweatWorkoutStepExercise = this.step.exercises[exerciseIndex];
        const exercise = get(stepExercise, 'exercise');
        this.initStepExercise(stepExercise);
        this.stepExerciseId = stepExercise.id;
        this.exerciseId = exercise;
      }
    } else {
      const stepExercise: SweatWorkoutStepExercise = this.step.exercises[0];
      this.initStepExercise(stepExercise);
      const exercise = get(stepExercise, 'exercise');
      this.stepExerciseId = stepExercise.id;
      this.exerciseId = exercise;
      this.circuitSets[this.step.id] += 1;
    }

    this.initializeTimerWork();
  };

  @computed
  get stepExerciseRest(): number | null {
    if (!this.stepExercise || !this.step) {
      return null;
    }
    return this.stepExercise!.workoutTimerRestComputed || this.step!.circuitTimerRest;
  }

  @modelAction
  restPeriod = (sweat: SweatStore) => {
    if (!this.step && !this.stepExercise) {
      return;
    }
    if (
      this.isGlobalStep ||
      ['variable'].includes(this.step!.circuitTimer) ||
      !this.step!.circuitTimer
    ) {
      // rest for 0 work time global steps not working
      // does not become a countdown timer
      this.type = TIMER_TYPE.TIMER;
      const timerRest = this.stepExerciseRest;
      if (timerRest) {
        this.countDown = timerRest;
        this.showReady = false;
      } else {
        // this.countDown = DEFAULT_REST_GLOBAL; // between sets
        // this.showReady = false;
        if (this.enableReadyInBetween) {
          this.showReady = true;
        }
      }
    } else if (this.step!.circuitTimer === 'tabata') {
      this.countDown = DEFAULT_REST_TABATA;
      this.showReady = false;
    } else if (this.step!.circuitTimer !== 'emom') {
      if (this.enableReadyInBetween) {
        this.showReady = true;
      }
    }
    this.isRestTimer = true;
    this.readyIndex = 0;
  };

  @modelAction
  prevStep = (sweat: SweatStore) => {
    if (!this.step) {
      return;
    }

    this.readyIndex = 0;
    this.countDown = DEFAULT_TIME;
    this.showReady = true;
    this.currentRound = 1;
    this.currentCircuitRound = 1;
    this.isRestTimer = false;
    const step: SweatStartSection = get(this, ['steps', this.step.order - 1]);
    const stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise = step?.exercises[0];
    const exercise = get(stepExercise, 'exercise');
    this.step = step;
    this.isGlobalStep = step.id < 0;

    if (step.circuitTimer) {
      this.type = TIMER_TYPE.TIMER;
      if (step.circuitTimer === 'amrap') {
        this.currentCircuitRound = 0;
        this.countDown = step.circuitTimerWork || DEFAULT_TIME;
      } else if (step.circuitTimer === 'emom') {
        this.countDown = DEFAULT_TIME;
      } else if (step.circuitTimer === 'tabata') {
        this.countDown = DEFAULT_TIME_TABATA;
      } else if (step.circuitTimer === 'variable') {
        this.countDown = step.circuitTimerWork || DEFAULT_TIME_TABATA;
      } else {
        this.countDown = DEFAULT_TIME;
      }
    } else {
      this.type = TIMER_TYPE.STOPWATCH;
    }
    this.circuitSets[step.id] = step.circuitSets === 0 ? Infinity : step.circuitSets;
    this.initStepExercise(stepExercise);
    this.exercise = sweat.getSweatExercise(exercise);
    this.stepId = step.id;
    this.stepExerciseId = stepExercise.id;
    this.stepExerciseOrder = 0;
    this.exerciseId = exercise;
    this.expandStepExercise = stepExercise.id;
    this.expandStep = step.id;
    this.circuitExerciseTime = 0;
    this.initializeTimerWork();
  };

  @modelAction
  nextStep = (sweat: SweatStore) => {
    if (!this.step) {
      return;
    }

    if (this.step.circuitTimer === 'amrap') {
      // Send number of circuits done to API
      this.circuitBreakdownSets = {
        ...this.circuitBreakdownSets,
        [this.step.id]: this.currentCircuitRound,
      };
    }
    this.readyIndex = 0;
    this.countDown = DEFAULT_TIME;

    // should always show ready timer on next step
    this.showReady = true;

    this.currentRound = 1;
    this.currentCircuitRound = 1;
    this.isRestTimer = false;
    const step: SweatStartSection = get(this, ['steps', this.step.order + 1]);

    const stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise = step.exercises[0];

    const exercise = get(stepExercise, 'exercise');
    this.step = step;
    this.isGlobalStep = step.id < 0;

    if (step.circuitTimer) {
      this.type = TIMER_TYPE.TIMER;
      if (step.circuitTimer === 'amrap') {
        this.currentCircuitRound = 0;
        this.countDown = step.circuitTimerWork || DEFAULT_TIME;
      } else if (step.circuitTimer === 'emom') {
        this.countDown = DEFAULT_TIME;
      } else if (step.circuitTimer === 'tabata') {
        // pass
      } else if (step.circuitTimer === 'variable') {
        this.countDown = step.circuitTimerWork || DEFAULT_TIME_TABATA;
      } else {
        this.countDown = DEFAULT_TIME;
      }
    }
    this.circuitSets[step.id] = step.circuitSets === 0 ? Infinity : step.circuitSets;
    this.initStepExercise(stepExercise);
    this.exercise = sweat.getSweatExercise(exercise);
    this.stepId = step.id;
    this.stepExerciseId = stepExercise.id;
    this.stepExerciseOrder = 0;
    this.exerciseId = exercise;
    // this.expandStepExercise = stepExercise.id;
    this.expandStepExercise = null;
    this.expandStep = step.id;
    this.circuitExerciseTime = 0;
    this.initializeTimerWork();
  };

  @modelAction
  setExpandStepExercise(id: number | null) {
    this.expandStepExercise = id;
  }

  @modelAction
  setExpandStep(id: number | null) {
    this.expandStep = id;
  }

  @modelAction
  updateReadyIndex() {
    if (this.readyIndex >= READY_TIME.length - 1) {
      this.showReady = false;
      this.readyIndex = 0;
    } else {
      this.readyIndex += 1;
    }
  }

  @modelAction
  updateTime() {
    if (!this.stepExerciseId) {
      this.stopTimer();
      return;
    }

    if (this.showReady) {
      this.updateReadyIndex();
    } else {
      if (this.isGlobalStep) {
        this.globalBreakdownTime = {
          ...this.globalBreakdownTime,
          [this.stepExerciseId]: get(this.globalBreakdownTime, this.stepExerciseId, 0) + 1,
        };
      } else {
        this.totalTime += 1;
        this.breakdownTime = {
          ...this.breakdownTime,
          [this.stepExerciseId]: get(this.breakdownTime, this.stepExerciseId, 0) + 1,
        };
        if (this.isCircuitExercise) {
          this.circuitExerciseTime += 1;
        }
        if (this.workoutTimerRunning) {
          this.elapsedTime = {
            ...this.elapsedTime,
            [this.stepExerciseId]: get(this.elapsedTime, this.stepExerciseId, 0) + 1,
          };
        }
      }
      if (this.type === TIMER_TYPE.TIMER) {
        this.countDown = Math.max(this.countDown - 1, 0);
      }
    }

    if (this.shouldBeep) {
      this.playBeep();
    }
  }

  @modelAction
  resetElapsedTime = (id: number) => {
    this.elapsedTime = {
      ...this.elapsedTime,
      [id]: 0,
    };
  };

  @modelAction
  stopTimer = () => {
    this.active = false;
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  @modelAction
  startTimer = () => {
    if (!this.showReady && !this.isRestTimer) {
      this.readyIndex = 0;
      if (this.enableReadyInBetween) {
        this.showReady = true;
      }
    }
    this.active = true;
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timerUpdated = Date.now();
    this.timer = setInterval(() => {
      while (Date.now() - this.timerUpdated > 1000) {
        this.timerUpdated += 1000;
        InteractionManager.runAfterInteractions(() => {
          this.updateTime();
        });
      }
    }, 100);
  };

  @modelAction
  setRepeat = (repeat: boolean) => {
    this.repeat = repeat;
  };

  @modelAction
  setIsWorkoutGenerator = (isWorkoutGenerator: boolean) => {
    this.isWorkoutGenerator = isWorkoutGenerator;
  };

  incrementCircuitRounds = () => {
    this.currentCircuitRound += 1;
    // this.initializeTimerWork();
  };

  decrementCircuitRounds = () => {
    if (this.currentCircuitRound >= 1) {
      this.currentCircuitRound -= 1;
      // this.initializeTimerWork();
    }
  };

  initializeTimerWork = () => {
    const timerWork = this.stepExercise?.workoutTimerWork || this.step?.circuitTimerWork;

    if (this.step?.circuitTimer === 'variable' || !this.step?.circuitTimer) {
      if (timerWork) {
        this.type = TIMER_TYPE.TIMER;
        this.countDown = timerWork;
      } else {
        this.type = TIMER_TYPE.STOPWATCH;
        // considers multiple sets of exercises with no timerwork as 1 stopwatch.
        this.stepExerciseSet = 1;
      }
    } else if (this.isGlobalStep) {
      this.type = TIMER_TYPE.STOPWATCH;
      this.countDown = DEFAULT_TIME;
    } else if (this.step!.circuitTimer === 'tabata') {
      this.countDown = DEFAULT_TIME_TABATA;
    } else if (this.step!.circuitTimer === 'emom') {
      this.countDown = DEFAULT_TIME;
    } else if (this.step!.circuitTimer === 'amrap') {
      this.countDown = this.step?.circuitTimerWork || DEFAULT_TIME;
    } else {
      this.type = TIMER_TYPE.STOPWATCH;
      this.countDown = DEFAULT_TIME;
      this.stepExerciseSet = 1;
    }
  };

  initializeTimerRest = () => {
    if (this.isGlobalStep) {
      this.countDown = DEFAULT_REST_GLOBAL;
    } else if (this.step?.circuitTimer === 'emom') {
      this.countDown = DEFAULT_TIME;
    } else if (this.step?.circuitTimer === 'tabata') {
      this.countDown = DEFAULT_REST_TABATA;
    } else if (this.step?.circuitTimerRest) {
      this.countDown = this.step.circuitTimerRest;
    } else {
      this.countDown = DEFAULT_TIME;
    }
  };

  initializeBeep = async () => {
    if (this.beepSound) return;
    try {
      const { sound } = await Audio.Sound.createAsync(require('../assets/audios/beep.mp3'), {
        shouldPlay: false,
        volume: 0.5,
      });
      this.beepSound = sound;
      console.log('[DEBUG] initialized beepSound');
    } catch (error) {
      console.warn('[DEBUG] failed to initialize beepSound', error);
    }
  };

  playBeep = async () => {
    if (!this.beepSound) {
      try {
        const { sound } = await Audio.Sound.createAsync(require('../assets/audios/beep.mp3'), {
          shouldPlay: true,
          volume: 0.5,
        });
        this.beepSound = sound;
      } catch (error) {
        console.warn('[DEBUG] failed to play beepSound', error);
      }
    } else {
      await this.beepSound.setIsMutedAsync(false);
      await this.beepSound.replayAsync();
    }
  };
}
