import {
  Input,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  saveModalVisible: boolean;
  hideSaveModalVisible: () => void;
  templateName: string;
  setTemplateName: Function;
  saveTemplate: () => void;
};

const SaveModalTemplate: React.FC<Props> = (props) => {
  const {
    saveModalVisible,
    hideSaveModalVisible,
    templateName,
    setTemplateName,
    saveTemplate,
  } = props;
  const styles = useStyleSheet(themedStyles);

  return (
    <Modal
      visible={saveModalVisible}
      onBackdropPress={hideSaveModalVisible}
      backdropStyle={{
        backgroundColor: '#091C2D',
        opacity: 0.8,
      }}
    >
      <Layout
        style={{
          height: 50,
          width: 350,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Input
          size="small"
          style={{
            width: '80%',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          value={templateName}
          onChangeText={(text) => setTemplateName(text)}
          placeholder="Template Name"
        />
        <TouchableOpacity
          style={{
            marginBottom: 'auto',
            marginTop: 'auto',
            padding: 5,
          }}
          onPress={saveTemplate}
        >
          <Text
            style={{
              marginBottom: 'auto',
              marginTop: 'auto',
              alignSelf: 'center',
              fontSize: 14,
            }}
          >
            Done
          </Text>
        </TouchableOpacity>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    width: 200,
    marginRight: 20,
    marginBottom: 20,
  },
});

export default SaveModalTemplate;
