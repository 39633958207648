import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import Events from 'src/containers/Events';
import EventCheckin from 'src/containers/Events/EventCheckin';
import EventDetail from 'src/containers/Events/EventDetail';
import EventJoin from 'src/containers/Events/EventJoin';
import EventListEdit from 'src/containers/Events/EventListEdit';
import EventRelease from 'src/containers/Events/EventRelease';
import EventSignature from 'src/containers/Events/EventSignature';
import EventSpecialistSurvey from 'src/containers/Events/EventSpecialistSurvey';
import EventTerms from 'src/containers/Events/EventTerms';
import EventWaiver from 'src/containers/Events/EventWaiver';
import Header from '../../Header';
import NetPromoterStackModal from '../modals/NetPromoterStackModal';

type Props = {};

const Stack = createStackNavigator();

const EventStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Events" component={Events} />
    <Stack.Screen name="EventDetail" component={EventDetail} />
    <Stack.Screen name="EventCheckin" component={EventCheckin} />
    <Stack.Screen name="EventWaiver" component={EventWaiver} />
    <Stack.Screen name="EventRelease" component={EventRelease} />
    <Stack.Screen name="EventTerms" component={EventTerms} />
    <Stack.Screen name="EventJoin" component={EventJoin} />
    <Stack.Screen name="EventSignature" component={EventSignature} />
    <Stack.Screen name="EventListEdit" component={EventListEdit} />
    <Stack.Screen name="EventSpecialists" component={NetPromoterStackModal} />
    <Stack.Screen
      name="EventSpecialistSurvey"
      component={EventSpecialistSurvey}
    />
  </Stack.Navigator>
);

export default EventStack;
