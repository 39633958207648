import {
  FTEGroupBreathe,
  FTEGroupRecipeListModel,
  FTEGroupSweatProgramListModel,
  FTEGroupSweatWorkoutListModel,
  FTEGroupThriveExerciseListModel,
  FTEGroupThriveMeditateListModel,
  FTEGroupThriveProgramListModel,
  FTEGroupThriveSleepListModel,
  FTEOrganizationBreathe,
  FTEOrganizationRecipeListModel,
  FTEOrganizationSweatProgramListModel,
  FTEOrganizationSweatWorkoutListModel,
  FTEOrganizationThriveExerciseListModel,
  FTEOrganizationThriveMeditateListModel,
  FTEOrganizationThriveProgramListModel,
  FTEOrganizationThriveSleepListModel,
  FTESweatExercise,
  FTESweatProgram,
  FTESweatProgramLibraryModel,
  FTESweatWorkout,
  FTESweatWorkoutLibraryModel,
  FTESweatWorkoutStepExerciseSet,
  FTEUserBreathe,
  FTEUserRecipeListModel,
  FTEUserSweatProgramListModel,
  FTEUserSweatWorkoutListModel,
  FTEUserThriveExerciseListModel,
  FTEUserThriveMeditateListModel,
  FTEUserThriveProgramListModel,
  FTEUserThriveSleepListModel,
  OSSGroup,
} from 'o2x-store/src/models/FTELibrary';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchContent = async (token: string, url: string) =>
  callApiWithToken(url, token, 'GET');

export const fetchMyFTEOrganizations = async (token: string) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization/my_fte_organization/`,
    token,
    'GET',
  );

export const fetchOrganizationDetails = async (token: string) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization/my_organization_details/`,
    token,
    'GET',
  );

export const fetchOSSGroups = async (token: string) =>
  callApiWithToken(`${config.urls.ossGroups}`, token, 'GET');

export const createOSSGroup = async (token: string, data: OSSGroup) =>
  callApiWithToken(
    `${config.urls.ossGroups}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteOSSGroup = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.ossGroups}${id}/`, token, 'DELETE');

export const UpdateOSSGroup = async (token: string, data: OSSGroup) =>
  callApiWithToken(
    `${config.urls.ossGroups}${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );


export const searchFTEOrganizations = async (token: string, qs: string) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization/?search=${qs}`,
    token,
    'GET',
  );

export const fetchTargetUserRecipeList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-eat/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationRecipeList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-organization-eat/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupRecipeList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-eat/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserSweatProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-sweatprogram/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationSweatProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-sweatprogram/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupSweatProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-sweatprogram/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserSweatWorkoutList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-sweatworkout/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationSweatWorkoutList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-sweatworkout/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupSweatWorkoutList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-sweatworkout/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserThriveProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-thriveprogram/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationThriveProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-thriveprogram/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupThriveProgramList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-thriveprogram/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserThriveExerciseList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-thriveexercise/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationThriveExerciseList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-thriveexercise/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupThriveExerciseList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-thriveexercise/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserThriveSleepList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-thrivesleep/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationThriveSleepList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-thrivesleep/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupThriveSleepList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-thrivesleep/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserThriveMeditateList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-user-thrivemeditate/?user=${id}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationThriveMeditateList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url ||
      `${config.urls.fteLibrary}fte-organization-thrivemeditate/?organization=${id}`,
    token,
    'GET',
  );

export const fetchTargetGroupThriveMeditateList = async (
  token: string,
  id: number,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}fte-group-thrivemeditate/?group=${id}`,
    token,
    'GET',
  );

export const fetchTargetUserBreathe = async (
  token: string,
  fte: number,
  user: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-breathe/?user=${user}&fte=${fte}`,
    token,
    'GET',
  );

export const fetchTargetOrganizationBreathe = async (
  token: string,
  fte: number,
  org: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-breathe/?organization=${org}&fte=${fte}`,
    token,
    'GET',
  );

export const fetchTargetGroupBreathe = async (
  token: string,
  fte: number,
  group: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-breathe/?group=${group}&fte=${fte}`,
    token,
    'GET',
  );

export const fetchGroupBreatheContents = async (
  token: string,
  fte: number,
  user: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-breathe/get_content_list/?user=${user}&fte=${fte}`,
    token,
    'GET',
  );

export const updateTargetUserRecipeList = async (
  token: string,
  data: Partial<FTEUserRecipeListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-eat/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgRecipeList = async (
  token: string,
  data: Partial<FTEOrganizationRecipeListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-eat/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupRecipeList = async (
  token: string,
  data: Partial<FTEGroupRecipeListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-eat/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserSweatProgramList = async (
  token: string,
  data: Partial<FTEUserSweatProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-sweatprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgSweatProgramList = async (
  token: string,
  data: Partial<FTEOrganizationSweatProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-sweatprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupSweatProgramList = async (
  token: string,
  data: Partial<FTEGroupSweatProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-sweatprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserSweatWorkoutList = async (
  token: string,
  data: Partial<FTEUserSweatWorkoutListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-sweatworkout/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgSweatWorkoutList = async (
  token: string,
  data: Partial<FTEOrganizationSweatWorkoutListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-sweatworkout/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupSweatWorkoutList = async (
  token: string,
  data: Partial<FTEGroupSweatWorkoutListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-sweatworkout/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserThriveProgramList = async (
  token: string,
  data: Partial<FTEUserThriveProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-thriveprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgThriveProgramList = async (
  token: string,
  data: Partial<FTEOrganizationThriveProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-thriveprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupThriveProgramList = async (
  token: string,
  data: Partial<FTEGroupThriveProgramListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-thriveprogram/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserThriveExerciseList = async (
  token: string,
  data: Partial<FTEUserThriveExerciseListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-thriveexercise/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgThriveExerciseList = async (
  token: string,
  data: Partial<FTEOrganizationThriveExerciseListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-thriveexercise/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupThriveExerciseList = async (
  token: string,
  data: Partial<FTEGroupThriveExerciseListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-thriveexercise/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserThriveSleepList = async (
  token: string,
  data: Partial<FTEUserThriveSleepListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-thrivesleep/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgThriveSleepList = async (
  token: string,
  data: Partial<FTEOrganizationThriveSleepListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-thrivesleep/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupThriveSleepList = async (
  token: string,
  data: Partial<FTEGroupThriveSleepListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-thrivesleep/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetUserThriveMeditateList = async (
  token: string,
  data: Partial<FTEUserThriveMeditateListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-thrivemeditate/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetOrgThriveMeditateList = async (
  token: string,
  data: Partial<FTEOrganizationThriveMeditateListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-thrivemeditate/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateTargetGroupThriveMeditateList = async (
  token: string,
  data: Partial<FTEGroupThriveMeditateListModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-thrivemeditate/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateFteSweatProgramLibrary = async (
  token: string,
  data: Partial<FTESweatProgramLibraryModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-sweat-programs/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateFteSweatWorkoutLibrary = async (
  token: string,
  data: Partial<FTESweatWorkoutLibraryModel>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-sweat-workouts/${data.id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createFteSweatWorkout = async (
  token: string,
  data: FTESweatWorkout,
) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createFteSweatProgram = async (
  token: string,
  data: FTESweatProgram,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateFteSweatWorkout = async (
  token: string,
  id: number,
  data: FTESweatWorkout,
) =>
  callApiWithToken(
    `${config.urls.sweatWorkouts}${id}/`,
    token,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateFteSweatProgram = async (
  token: string,
  id: number,
  data: FTESweatProgram,
) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}${id}/`,
    token,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteSweatWorkout = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.sweatWorkouts}${id}/`, token, 'DELETE');

export const deleteFteSweatProgram = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.sweatPrograms}${id}/`, token, 'DELETE');

export const createFteUserBreathe = async (
  token: string,
  data: FTEUserBreathe,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-breathe/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createFteOrganizationBreathe = async (
  token: string,
  data: FTEOrganizationBreathe,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-breathe/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const createFteGroupBreathe = async (
  token: string,
  data: FTEGroupBreathe,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-breathe/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteUserBreathe = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-user-breathe/${id}/`,
    token,
    'DELETE',
  );

export const deleteFteOrganizationBreathe = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-organization-breathe/${id}/`,
    token,
    'DELETE',
  );

export const deleteFteGroupBreathe = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}fte-group-breathe/${id}/`,
    token,
    'DELETE',
  );

export const fetchFteUserSweatProgramProgress = async (
  token: string,
  user: number,
  programs: string,
) =>
  callApiWithToken(
    `${config.urls.sweatProgramProgress}?user=${user}&programs=${programs}`,
    token,
  );

export const fetchFteUserSweatWorkoutProgress = async (
  token: string,
  user: number,
  workouts?: string,
) =>
  callApiWithToken(
    workouts
      ? `${config.urls.sweatWorkoutProgress}?user=${user}&workouts=${workouts}`
      : `${config.urls.sweatWorkoutProgress}?user=${user}`,
    token,
  );

export const fetchFteUserSweatExerciseProgress = async (
  token: string,
  workoutProgress?: number,
  exercise?: number,
  user?: number,
) =>
  callApiWithToken(
    workoutProgress && exercise
      ? `${config.urls.sweatExerciseProgress}?workout_progress=${workoutProgress}&exercise=${exercise}`
      : `${config.urls.sweatExerciseProgress}?user=${user}`,
    token,
  );

export const createWeightInput = async (
  token: string,
  exerciseProgress?: number,
  weight?: string,
  exerciseSet?: number,
) =>
  callApiWithToken(
    config.urls.weightInput,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        exerciseProgress: exerciseProgress,
        weight: weight,
        exerciseSet: exerciseSet,
      }),
    ),
  );

export const fetchFteUserThriveProgramProgress = async (
  token: string,
  user: number,
  programs: string,
) =>
  callApiWithToken(
    `${config.urls.thriveProgramProgress}?user=${user}&programs=${programs}`,
    token,
  );

export const createFteSweatExercise = async (
  token: string,
  data: FTESweatExercise,
) =>
  callApiWithToken(
    `${config.urls.sweatExercises}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const updateFteSweatExercise = async (
  token: string,
  id: number,
  data: Partial<FTESweatExercise>,
) =>
  callApiWithToken(
    `${config.urls.sweatExercises}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const saveFteWorkoutStepTemplate = async (
  token: string,
  data: Partial<SweatWorkoutStep>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-step-templates/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteWorkoutStepTemplate = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-step-templates/${id}`,
    token,
    'DELETE',
  );

export const saveFteWorkoutTemplate = async (
  token: string,
  data: Partial<SweatWorkout>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-templates/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteWorkoutTemplate = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-templates/${id}`,
    token,
    'DELETE',
  );

export const saveFteWeekWorkoutTemplate = async (
  token: string,
  data: Partial<SweatProgram>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}week-workout-templates/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteWeekWorkoutTemplate = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.fteLibrary}week-workout-templates/${id}`,
    token,
    'DELETE',
  );

export const saveGlobalStepExerciseSetTemplate = async (
  token: string,
  data: Partial<FTESweatWorkoutStepExerciseSet>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}global-exercise-set-templates/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteGlobalStepExerciseSetTemplate = async (
  token: string,
  id: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}global-exercise-set-templates/${id}`,
    token,
    'DELETE',
  );

export const saveFteWorkoutStepExerciseSetTemplate = async (
  token: string,
  data: Partial<FTESweatWorkoutStepExerciseSet>,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-exercise-set-templates/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );

export const deleteFteWorkoutStepExerciseSetTemplate = async (
  token: string,
  id: number,
) =>
  callApiWithToken(
    `${config.urls.fteLibrary}workout-exercise-set-templates/${id}`,
    token,
    'DELETE',
  );

export const fetchFteWeekWorkoutTemplates = async (
  token: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}week-workout-templates/`,
    token,
  );

export const fetchFteWorkoutTemplates = async (token: string, url?: string) =>
  callApiWithToken(url || `${config.urls.fteLibrary}workout-templates/`, token);

export const fetchFteWorkoutStepTemplates = async (
  token: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}workout-step-templates/`,
    token,
  );

export const fetchGlobalStepExerciseSetTemplates = async (
  token: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}global-exercise-set-templates/`,
    token,
  );

export const fetchFteWorkoutStepExerciseSetTemplates = async (
  token: string,
  url?: string,
) =>
  callApiWithToken(
    url || `${config.urls.fteLibrary}workout-exercise-set-templates/`,
    token,
  );

export const fetchSweatProgramById = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.sweatPrograms}?programs=${id}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );
