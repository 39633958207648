import {
  Icon,
  Layout,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { FTE_UTILS } from 'o2x-store/src/utils/fteadmin';
import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Props = {
  item: FTE_UTILS | string;
  selected: boolean;
  onSelect: (type: FTE_UTILS | string) => void;
};

const IconMapping: { [key: string]: string } = {
  content: 'file-text-outline',
  calendar: 'calendar-outline',
  contact: 'message-square-outline',
  dashboard: 'grid-outline',
  library: 'book-open-outline',
  AthleteProfile: 'folder-outline',
};

const TopNavItem: React.FC<Props> = (props) => {
  const { item, selected, onSelect } = props;
  const styles = useStyleSheet(themedStyles);
  const onPress = useCallback(() => {
    onSelect(item);
  }, [item]);
  return (
    <Layout>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <Icon
          style={styles.icon}
          name={IconMapping[item.replace(/ /g, '')]}
          fill={selected ? 'white' : '#4E7B89'}
        />
        <Text style={[styles.text, selected && styles.selectedText]}>
          {item}
        </Text>
      </TouchableOpacity>
      {selected && <View style={styles.selected} />}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  selected: {
    borderBottomWidth: 4,
    borderStyle: 'solid',
    borderColor: 'olive',
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 18,
    color: 'blue-secondary',
  },
  selectedText: {
    color: 'white',
  },
  icon: {
    color: 'white',
    marginRight: 10,
    marginBottom: 0,
    height: 24,
    width: 24,
  },
});

export default TopNavItem;
