import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchOrganizations = async (token: string) =>
  callApiWithToken(config.urls.organizations, token, 'GET');

export const fetchOrganization = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.organizations}${id}`, token, 'GET');

export const joinOrganization = async (token: string, data: { inviteCode: string }) =>
  callApiWithToken(
    `${config.urls.organizations}join/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
    true,
  );
