import { capitalize } from 'lodash';
import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { TIMER_TYPE } from 'src/models/TimerStart';

export enum CTA_CHOICES {
  BREATHE = 'breathe',
  SLEEP = 'sleep',
  MEDITATE = 'meditate',
  DOWNLOAD = 'download',
}

export type CTAMeta = {
  duration: number | 'infinity';
};

@model('o2x-store/ThriveProgramExercise')
export default class ThriveProgramExercise extends Model({
  id: prop<number>(),
  order: prop<number>(),
  instructions: prop<string>(''),
  sets: prop<string>(''),
  reps: prop<string>(''),
  distance: prop<string>(''),
  time: prop<string>(''),
  rest: prop<string>(''),
  notes: prop<string>(''),
  difficulty: prop<string>(''),
  programDay: prop<number>(),
  exercise: prop<number>(),
  cta: prop<CTA_CHOICES | ''>(),
  ctaFile: prop<string | null>(null),
  ctaMeta: prop<CTAMeta | {}>(),
  timer: prop<TIMER_TYPE | ''>(''),
  timerLengthInSeconds: prop<number | null>(null),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<ThriveProgramExercise>) {
    Object.assign(this, data);
  }

  @computed
  get title(): string {
    switch (this.cta) {
      case CTA_CHOICES.BREATHE:
      case CTA_CHOICES.MEDITATE:
      case CTA_CHOICES.SLEEP:
        return capitalize(this.cta);
      default:
        return 'Exercise';
    }
  }

  @computed
  get label(): string {
    switch (this.cta) {
      case CTA_CHOICES.DOWNLOAD: {
        if (this.ctaFile) {
          return 'Download Exercise';
        }
        return '';
      }
      case CTA_CHOICES.BREATHE:
      case CTA_CHOICES.MEDITATE:
      case CTA_CHOICES.SLEEP:
        return 'Open Exercise';
      default:
        return '';
    }
  }

  @computed
  get detailLabel(): string {
    switch (this.cta) {
      case CTA_CHOICES.DOWNLOAD: {
        if (this.ctaFile) {
          return 'Download Exercise';
        }
        return '';
      }
      case CTA_CHOICES.BREATHE:
      case CTA_CHOICES.MEDITATE:
      case CTA_CHOICES.SLEEP:
        return `Open ${capitalize(this.cta)} Exercise`;
      default:
        return '';
    }
  }

  @computed
  get ctaDuration(): number | 'infinity' | null {
    console.log('Get ctaDuration');
    console.log('meta', this.ctaMeta);
    if (this.ctaMeta.duration) {
      return this.ctaMeta.duration;
    }
    return null;
  }

  @computed
  get subtitle(): string {
    const part1 = [];
    if (this.sets) {
      part1.push(`${this.sets} sets`);
    }
    if (this.reps) {
      part1.push(`${this.reps} reps`);
    }
    if (this.time && this.time !== '0') {
      part1.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      part1.push(this.distance);
    }

    const part2 = [];
    if (this.rest) {
      part2.push(`${this.rest} rest`);
    }

    const subtitle = [];
    if (part1.length > 0) {
      subtitle.push(part1.join(' '));
    }
    if (part2.length > 0) {
      subtitle.push(part2.join(' '));
    }

    return subtitle.join(', ');
  }
}
