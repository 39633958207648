import React, { useState } from 'react';
import {
  Icon,
  StyleService,
  useStyleSheet,
  Layout,
  Divider,
} from '@ui-kitten/components';

type Props = {
  id: string;
  iconName: string;
  accept?: string;
  onChange: (files: FileList | null) => void;
};

const UploadIcon: React.FC<Props> = ({ id, iconName, accept, onChange }) => {
  const styles = useStyleSheet(themedStyles);
  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.files);
  };

  return (
    <Layout>
      <label style={{ cursor: 'pointer', marginLeft: 3 }} htmlFor={id}>
        <Icon style={styles.send} name={iconName} fill="white" />
      </label>
      <input
        style={{ display: 'none' }}
        id={id}
        type="file"
        multiple
        accept={accept}
        onChange={onChangeFiles}
      />
    </Layout>
  );
};
const themedStyles = StyleService.create({
  send: {
    width: 20,
    height: 20,
    padding: 5,
    backgroundColor: 'olive',
  },
});

export default UploadIcon;
