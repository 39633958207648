import { get } from 'lodash';
import {
  getRoot,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { THRIVE_MEDIA_FILE_TYPE } from 'o2x-store/src/models/ThriveMediaFile';
import { Platform } from 'react-native';
import config from 'src/config';
import { NativeStore } from '../stores';
import DownloadedItem from './DownloadedItem';
import DownloadedMedia from './DownloadedMedia';

export type SecuredMedia = {
  id: number;
  name: string;
  content: string;
  description: string;
  isGuided: boolean;
  inMeditate: boolean;
  inSleep: boolean;
  type?: THRIVE_MEDIA_FILE_TYPE;
};

export enum DOWNLOAD_TYPE {
  RECIPE = 'recipe',
  SWEAT_PROGRAM = 'sweatProgram',
  SWEAT_WORKOUT = 'sweatWorkout',
  SWEAT_EXERCISE = 'sweatExercise',
  THRIVE_PROGRAM = 'thriveProgram',
  SECURED_MEDIA = 'securedMedia',
}

@model('o2x-native/DownloadedList')
export default class DownloadedList extends Model({
  recipes: prop_mapObject(() => new Map<string, DownloadedItem>()),
  sweatPrograms: prop_mapObject(() => new Map<string, DownloadedItem>()),
  sweatWorkouts: prop_mapObject(() => new Map<string, DownloadedItem>()),
  sweatExercises: prop_mapObject(() => new Map<string, DownloadedItem>()),
  thrivePrograms: prop_mapObject(() => new Map<string, DownloadedItem>()),
  downloadingSweatPrograms: prop_mapObject(() => new Map<string, boolean>()),
  downloadingSweatWorkouts: prop_mapObject(() => new Map<string, boolean>()),
  downloadingThrivePrograms: prop_mapObject(() => new Map<string, boolean>()),
  media: prop_mapObject(() => new Map<string, DownloadedMedia>()),
  securedMedia: prop_mapObject(() => new Map<string, SecuredMedia>()),
  thumbnails: prop_mapObject(() => new Map<string, DownloadedMedia>()),
}) {
  @modelFlow
  updateLocalStorage = _async(function* (this: DownloadedList, id: number) {
    const recipes = Array.from(this.recipes, ([_k, v]) => ({
      id: v.id,
      name: v.name,
      subtitle: v.subtitle,
      servings: v.servings,
      ingredients: v.ingredients,
      directions: v.directions,
      image: v.image,
      imageBanner: v.imageBanner,
    }));
    const sweatPrograms = Array.from(this.sweatPrograms, ([_k, v]) => ({
      id: v.id,
      name: v.name,
      subtitle: v.subtitle,
      image: v.image,
      imageBanner: v.imageBanner,
      difficulty: v.difficulty,
      description: v.description,
      author: v.author,
      equipment: v.equipment,
      sweatPreview: v.sweatPreview,
      workouts: Array.from(v.workouts, ([_k, w]) => ({
        id: w.id,
        dayDisplay: w.dayDisplay,
        sections: w.sections,
      })),
    }));
    const sweatWorkouts = Array.from(this.sweatWorkouts, ([_k, v]) => ({
      id: v.id,
      title: v.title,
      displayName: v.displayName,
      dayDisplay: v.dayDisplay,
      imageBanner: v.imageBanner,
      sections: v.sections,
    }));
    const sweatExercises = Array.from(this.sweatExercises, ([_k, v]) => ({
      id: v.id,
      sets: v.sets,
      reps: v.reps,
      exercise: v.exercise,
      isRepsEachSide: v.isRepsEachSide,
      timeDistanceSubtitle: v.timeDistanceSubtitle,
      instructions: v.instructions,
      subtitle: v.subtitle,
      name: v.name,
      description: v.description,
      video: v.video,
      equipment: v.equipment,
      timerWork: v.timerWork,
      timerRest: v.timerRest,
      isRestPercentage: v.isRestPercentage,
    }));
    const thrivePrograms = Array.from(this.thrivePrograms, ([_k, v]) => ({
      id: v.id,
      name: v.name,
      subtitle: v.subtitle,
      image: v.image,
      imageBanner: v.imageBanner,
      description: v.description,
      author: v.author,
      thrivePreview: v.thrivePreview,
      thriveExercises: Array.from(v.thriveExercises, ([_k, e]) => ({
        id: e.id,
        programDay: e.programDay,
        instructions: e.instructions,
        cta: e.cta,
        time: e.time,
        timer: e.timer,
        timerLengthInSeconds: e.timerLengthInSeconds,
      })),
    }));
    const userData = {
      recipes,
      sweatPrograms,
      sweatWorkouts,
      sweatExercises,
      thrivePrograms,
    };
    try {
      const root = getRoot<NativeStore>(this);
      const key = `${config.nativeStoreKey}:downloads`;
      const savedData = yield* _await(root.storageService.getItemAsync(key));
      let data = savedData ? JSON.parse(savedData) : {};
      data = { ...data, [id.toString()]: userData };
      yield* _await(root.storageService.setItemAsync(key, JSON.stringify(data)));
    } catch (e) {
      console.warn('[DEBUG]', e);
    }
  });

  @modelFlow
  updateSecuredMedia = _async(function* (this: DownloadedList) {
    const data = Array.from(this.securedMedia, ([_k, v]) => ({
      id: v.id,
      name: v.name,
      content: v.content,
      description: v.description,
      isGuided: v.isGuided,
      inMeditate: v.inMeditate,
      inSleep: v.inSleep,
      type: v.type,
    }));
    try {
      const root = getRoot<NativeStore>(this);
      const securedMediaKey = `${config.nativeStoreKey}:securedMedia`;
      yield* _await(root.storageService.setItemAsync(securedMediaKey, JSON.stringify(data)));
    } catch (e) {
      console.warn('[DEBUG]', e);
    }
  });

  getProgram = (type: DOWNLOAD_TYPE, id: number) => {
    if (type === DOWNLOAD_TYPE.RECIPE) {
      return this.recipes.get(`${id}`);
    }
    if (type === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
      return this.sweatPrograms.get(`${id}`);
    }
    if (type === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      return this.sweatWorkouts.get(`${id}`);
    }
    if (type === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
      return this.thrivePrograms.get(`${id}`);
    }
  };

  @modelAction
  add = (data: any, type: DOWNLOAD_TYPE, extra?: any) => {
    if (Platform.OS === 'web') return;

    if (type === DOWNLOAD_TYPE.RECIPE) {
      const item = new DownloadedItem({
        id: data.id,
        name: data.name,
        subtitle: data.subtitle,
        servings: data.servings,
        ingredients: data.ingredients,
        directions: data.directions,
        image: data.image,
        imageBanner: data.imageBanner,
      } as any);
      this.recipes.set(`${data.id}`, item);
      this.getOrCreateThumbnail({ uri: data.image })?.downloadMedia();
      this.getOrCreateThumbnail({ uri: data.imageBanner })?.downloadMedia();
      return;
    }
    if (type === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
      const item = new DownloadedItem({
        id: data.id,
        name: data.name,
        subtitle: data.subtitle,
        image: data.image,
        imageBanner: data.imageBanner,
        difficulty: data.difficulty,
        description: data.description,
        author: { ...(data.author ?? {}) },
        equipment: [...(data.equipment ?? [])],
        sweatPreview: extra ?? data.sweatPreview,
      } as any);
      this.sweatPrograms.set(`${data.id}`, item);
      this.getOrCreateThumbnail({ uri: data.image })?.downloadMedia();
      this.getOrCreateThumbnail({ uri: data.imageBanner })?.downloadMedia();
      return;
    }
    if (type === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      const item = new DownloadedItem({
        id: data.id,
        displayName: data.displayName,
        dayDisplay: data.dayDisplay,
        title: extra?.title ?? data.title,
        imageBanner: extra?.imageBanner ?? data.imageBanner,
        sections: extra?.sections ?? data.sections,
      } as any);
      this.sweatWorkouts.set(`${data.id}`, item);
      this.getOrCreateThumbnail({
        uri: extra?.imageBanner ?? data.imageBanner,
      })?.downloadMedia();
      return;
    }
    if (type === DOWNLOAD_TYPE.SWEAT_EXERCISE) {
      const item = new DownloadedItem({
        id: data.id,
        sets: data.sets,
        reps: data.reps,
        exercise: data.exercise,
        isRepsEachSide: data.isRepsEachSide,
        timeDistanceSubtitle: data.timeDistanceSubtitle,
        instructions: data.instructions,
        subtitle: data.subtitle,
        name: data.name,
        description: data.description,
        video: data.video,
        equipment: data.equipment,
        timerWork: data.timerWork,
        timerRest: data.timerRest,
        isRestPercentage: data.isRestPercentage,
      } as any);
      this.sweatExercises.set(`${data.id}`, item);
      return;
    }
    if (type === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
      const item = new DownloadedItem({
        id: data.id,
        name: data.name,
        subtitle: data.subtitle,
        image: data.image,
        imageBanner: data.imageBanner,
        description: data.description,
        author: { ...(data.author ?? {}) },
        thrivePreview: extra?.preview ?? data.thrivePreview,
      } as any);
      this.thrivePrograms.set(`${data.id}`, item);
      this.getOrCreateThumbnail({ uri: data.image })?.downloadMedia();
      this.getOrCreateThumbnail({ uri: data.imageBanner })?.downloadMedia();
      return;
    }
    if (type === DOWNLOAD_TYPE.SECURED_MEDIA) {
      this.securedMedia.set(`${data.type}${data.id}`, {
        id: data.id,
        name: data.name,
        content: data.content,
        description: data.description,
        isGuided: data.isGuided,
        inMeditate: data.inMeditate,
        inSleep: data.inSleep,
        type: data.type,
      });
    }
  };

  @modelAction
  remove = (id: number | string, type: DOWNLOAD_TYPE) => {
    if (Platform.OS === 'web') return;

    const list =
      type === DOWNLOAD_TYPE.RECIPE
        ? this.recipes
        : type === DOWNLOAD_TYPE.SWEAT_PROGRAM
        ? this.sweatPrograms
        : type === DOWNLOAD_TYPE.SWEAT_WORKOUT
        ? this.sweatWorkouts
        : type === DOWNLOAD_TYPE.SWEAT_EXERCISE
        ? this.sweatExercises
        : type === DOWNLOAD_TYPE.THRIVE_PROGRAM
        ? this.thrivePrograms
        : null;

    if (list && list.has(`${id}`)) {
      list.delete(`${id}`);
    }
  };

  @modelAction
  update = (data: DownloadedItem[], type: DOWNLOAD_TYPE) => {
    if (Platform.OS === 'web') return;

    if (type === DOWNLOAD_TYPE.RECIPE) {
      this.recipes.clear();
      data.forEach((i) => this.add(getSnapshot(i), type));
    } else if (type === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
      this.sweatPrograms.clear();
      data.forEach((i) => {
        this.add(getSnapshot(i), type);
        const workouts = Array.from(i.workouts.values());
        const item = this.sweatPrograms.get(`${i.id}`);
        if (item) workouts.forEach((w: any) => item.addWorkout(w));
      });
    } else if (type === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      this.sweatWorkouts.clear();
      data.forEach((i) => this.add(getSnapshot(i), type));
    } else if (type === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
      this.thrivePrograms.clear();
      data.forEach((i) => {
        this.add(getSnapshot(i), type);
        const thriveExercises = Array.from(i.thriveExercises.values());
        const item = this.sweatPrograms.get(`${i.id}`);
        if (item) thriveExercises.forEach((w: any) => item.addThriveExercises(w));
      });
    }
  };

  @modelAction
  startDownloading = (id: number, type: DOWNLOAD_TYPE) => {
    if (Platform.OS === 'web') return;

    if (type === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
      this.downloadingSweatPrograms.set(id.toString(), true);
    } else if (type === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      this.downloadingSweatWorkouts.set(id.toString(), true);
    } else if (type === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
      this.downloadingThrivePrograms.set(id.toString(), true);
    }
  };

  @modelAction
  stopDownloading = (id: number, type: DOWNLOAD_TYPE) => {
    if (Platform.OS === 'web') return;

    if (type === DOWNLOAD_TYPE.SWEAT_PROGRAM) {
      this.downloadingSweatPrograms.delete(id.toString());
    } else if (type === DOWNLOAD_TYPE.SWEAT_WORKOUT) {
      this.downloadingSweatWorkouts.delete(id.toString());
    } else if (type === DOWNLOAD_TYPE.THRIVE_PROGRAM) {
      this.downloadingThrivePrograms.delete(id.toString());
    }
  };

  @modelAction
  getOrCreateMediaFile = (data: any) => {
    if (!data || !data.video || !data.exercise) return;

    const key = `DownloadedSweatExercises/${data.exercise}`;
    const media = this.media.get(key);
    if (media && media.uri === data.video) {
      return media;
    }

    const file = new DownloadedMedia({ key, uri: data.video });
    this.media.set(key, file);
    return file;
  };

  @modelFlow
  downloadSimultaneously = _async(function* (this: DownloadedList, data: any) {
    try {
      yield* _await(
        Promise.all(
          data.map(async (d: any) => {
            const file = this.getOrCreateMediaFile(d);
            await file?.downloadMedia();
          }),
        ),
      );
      return true;
    } catch (e) {
      console.warn('[DEBUG]', e);
      return null;
    }
  });

  @modelAction
  getOrCreateSecuredMediaFile = (data: any) => {
    if (!data || !data.content || !data.id || !data.type) return;

    const key = `DownloadedSecuredMedia/${data.type}${data.id}`;
    const media = this.media.get(key);
    if (media && media.uri === data.content) {
      return media;
    }

    const file = new DownloadedMedia({
      key,
      uri: data.content,
      isSecured: true,
    });
    this.media.set(key, file);
    return file;
  };

  @modelAction
  getOrCreateThriveMediaFile = (data: any) => {
    if (!data || !data.video || !data.exercise) return;

    const key = `DownloadedThriveExercises/${data.exercise}`;
    const media = this.media.get(key);
    if (media && media.uri === data.video) {
      return media;
    }

    const file = new DownloadedMedia({ key, uri: data.video });
    this.media.set(key, file);
    return file;
  };

  @modelAction
  getOrCreateThumbnail = (data: any) => {
    if (!data || !data.uri) return;

    const key = `DownloadedThumbnails/${data.uri}`;
    const media = this.thumbnails.get(key);
    if (media && media.uri === data.uri) {
      return media;
    }

    const file = new DownloadedMedia({
      key,
      uri: data.uri,
      isSecured: true,
    });
    this.thumbnails.set(key, file);
    return file;
  };

  @modelFlow
  resetMedia = _async(function* (this: DownloadedList) {
    try {
      const root = getRoot<NativeStore>(this);
      const key = `${config.nativeStoreKey}:downloads`;
      const savedData = yield* _await(root.storageService.getItemAsync(key));

      const allData = savedData ? JSON.parse(savedData) : {};
      const uriList: string[] = [];

      Object.values(allData).forEach((data: any) => {
        (data.sweatExercises ?? []).forEach((e: any) => {
          if (e.video && !uriList.includes(e.video)) {
            uriList.push(e.video);
          }
        });
        (data.thrivePrograms ?? []).forEach((e: DownloadedItem) => {
          Array.from(e.thriveExercises.values()).forEach((t) => {
            if (t.video && !uriList.includes(t.video)) {
              uriList.push(t.video);
            }
          });
        });
      });

      yield* _await(
        Promise.all(
          Array.from(this.media.values()).map(async (f) => {
            if (!f.isSecured && !uriList.includes(f.uri)) {
              await f.deleteMedia();
            }
          }),
        ),
      );
    } catch (e) {
      console.warn('[DEBUG]', e);
    }
  });

  @modelFlow
  initialize = _async(function* (this: DownloadedList, id: number) {
    if (Platform.OS === 'web') return;

    this.recipes.clear();
    this.sweatPrograms.clear();
    this.sweatWorkouts.clear();
    this.thrivePrograms.clear();
    this.downloadingSweatPrograms.clear();
    this.downloadingSweatWorkouts.clear();
    this.downloadingThrivePrograms.clear();
    this.media.clear();
    this.securedMedia.clear();

    try {
      const root = getRoot<NativeStore>(this);
      const downloadsKey = `${config.nativeStoreKey}:downloads`;
      let savedData = yield* _await(root.storageService.getItemAsync(downloadsKey));

      let data = savedData ? JSON.parse(savedData) : {};
      const userData = get(data, id.toString(), {});

      (userData.recipes ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.RECIPE);
      });
      (userData.sweatPrograms ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.SWEAT_PROGRAM);
        const item = this.sweatPrograms.get(`${i.id}`);
        if (!item) return;
        (i.workouts ?? []).forEach((w: any) => item.addWorkout(w));
      });
      (userData.sweatWorkouts ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.SWEAT_WORKOUT);
      });
      (userData.sweatExercises ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.SWEAT_EXERCISE);
      });
      (userData.thrivePrograms ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.THRIVE_PROGRAM);
        const item = this.thrivePrograms.get(`${i.id}`);
        if (!item) return;
        (i.thriveExercises ?? []).forEach((w: any) => item.addThriveExercises(w));
      });
      (userData.thrivePrograms ?? []).forEach((i: any) => {
        this.add(i, DOWNLOAD_TYPE.THRIVE_PROGRAM);
        const item = this.thrivePrograms.get(`${i.id}`);
        if (!item) return;
        (i.thriveExercises ?? []).forEach((w: any) => item.addThriveExercises(w));
      });

      const securedMediaKey = `${config.nativeStoreKey}:securedMedia`;
      savedData = yield* _await(root.storageService.getItemAsync(securedMediaKey));

      data = savedData ? JSON.parse(savedData) : {};
      (data ?? []).forEach((i: SecuredMedia) => {
        this.securedMedia.set(`${i.type}${i.id}`, i);
      });
    } catch (e) {
      console.warn('[DEBUG]', e);
    }
  });
}
