import {
  Divider,
  Input,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React, { forwardRef, Ref, useCallback, useRef } from 'react';
import {
  StyleProp,
  TextInput,
  TextInputProps,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

type Props = TextInputProps & {
  divider?: boolean | false;
  style?: StyleProp<ViewStyle>;
  dividerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<ViewStyle>;
  label?: string;
  size?: string | 'medium';
  textStyle?: StyleProp<ViewStyle>;
};

const LabeledInput: React.FC<Props> = forwardRef(
  (props: Props, ref?: Ref<TextInput>) => {
    const styles = useStyleSheet(themedStyles);
    const {
      divider,
      style,
      dividerStyle,
      inputStyle,
      label,
      labelStyle,
      size,
      ...inputProps
    } = props;

    const inputRef = useRef<TextInput>(null);
    const onFocusInput = useCallback(() => {
      if (ref) {
        ref.current?.focus();
      } else {
        inputRef.current?.focus();
      }
    }, []);

    return (
      <Layout
        style={[
          divider ? styles.containerWithDivider : styles.container,
          style,
        ]}
      >
        {label && (
          <Text style={[styles.label, labelStyle]} category="c2">
            {label}
          </Text>
        )}
        <TouchableWithoutFeedback onPress={onFocusInput}>
          <View style={styles.inputContainer}>
            <Input
              ref={!ref ? inputRef : ref}
              style={[styles.input, inputStyle]}
              size={size}
              {...inputProps}
            />
            {divider && <Divider style={[styles.divider, dividerStyle]} />}
          </View>
        </TouchableWithoutFeedback>
      </Layout>
    );
  },
);

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    width: '100%',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 5,
  },
  containerWithDivider: {
    backgroundColor: 'transparent',
    marginBottom: 27,
    width: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  label: {
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  inputContainer: {
    width: '100%',
  },
});

export default LabeledInput;
