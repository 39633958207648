import { computed } from 'mobx';
import { getRoot, model, Model, modelAction, ModelInstanceCreationData, prop } from 'mobx-keystone';
import { RootStore } from '../stores';
import { asDate } from '../utils/models';
import { SCORE_TYPE } from '../utils/scores';
import Organization from './Organization';
import { SweatEquipment, SweatProfession, SweatRequirement } from './Sweat';
import { ThriveSkill } from './Thrive';

export interface ScoreMap {
  [key: string]: SCORE_TYPE | null;
}

export type ScoreLevelItem = {
  value: string;
  label: string;
};

export type ScoreLevel = {
  good: ScoreLevelItem;
  okay: ScoreLevelItem;
  improve: ScoreLevelItem;
};

export interface ScoreMapDisplay {
  [key: string]: ScoreLevel;
}

@model('o2x-store/UserDebriefProfile')
export class UserDebriefProfile extends Model({
  id: prop<number | null>(null),
  overallEat: prop<number>(0),
  waterOz: prop<number>(0),
  fruitsAndVegetablesPerDay: prop<number>(0),
  caffeinePerDay: prop<number>(0),
  doDrinkWater: prop<string>(''),
  alcoholPerDay: prop<number>(0),
  overallSweat: prop<number>(0),
  doPrepareAndRecover: prop<string>(''),
  physicalHealthLevel: prop<number>(0),
  doExercise: prop<string>(''),
  doChallenge: prop<string>(''),
  energyLevel: prop<number>(0),
  motivationLevel: prop<number>(0),
  overallThrive: prop<number>(0),
  hoursSleep: prop<number>(0),
  qualitySleep: prop<number>(1),
  stressLevel: prop<number>(1),
  balanceInLife: prop<string>(''),
  goalsAchieved: prop<string>(''),
  skillsToManageStress: prop<Array<number>>(() => [9]),
  skillsToManageStressDisplay: prop<Array<string>>(() => ['Nothing']),
  monitorFields: prop<Array<string>>(() => []),
  score: prop<ScoreMap | null>(null),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserDebriefProfile>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserDailyDebrief')
export class UserDailyDebrief extends Model({
  id: prop<number | null>(null),
  overallEat: prop<number>(0),
  waterOz: prop<number>(0),
  fruitsAndVegetablesPerDay: prop<number>(0),
  caffeinePerDay: prop<number>(0),
  doDrinkWater: prop<string>(''),
  alcoholPerDay: prop<number>(0),
  overallSweat: prop<number>(0),
  doPrepareAndRecover: prop<string>(''),
  physicalHealthLevel: prop<number>(0),
  doExercise: prop<string>(''),
  doChallenge: prop<string>(''),
  energyLevel: prop<number>(0),
  motivationLevel: prop<number>(0),
  overallThrive: prop<number>(0),
  hoursSleep: prop<number>(0),
  qualitySleep: prop<number>(1),
  stressLevel: prop<number>(1),
  balanceInLife: prop<string>('Too much focus work'),
  goalsAchieved: prop<string>(''),
  skillsToManageStress: prop<Array<number>>(() => [9]),
  skillsToManageStressDisplay: prop<Array<string>>(() => ['Nothing']),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserDailyDebrief>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserEatProfile')
export class UserEatProfile extends Model({
  id: prop<number | null>(null),
  weightLbs: prop<number | null>(null),
  heightIn: prop<number | null>(null),
  activityLevel: prop<string>(''),
  doDrinkWater: prop<string>(''),
  waterOz: prop<number | null>(null),
  treatsPerDay: prop<number>(),
  fruitsPerDay: prop<number>(),
  vegetablesPerDay: prop<number>(),
  doDrinkCaffeine: prop<string>(''),
  caffeinePerDay: prop<number>(),
  alcoholPerDay: prop<number>(),
  doPlanCaloricIntake: prop<string>(''),
  caloriesPerDay: prop<number | null>(null),
  energyLevel: prop<string>(''),
  energyLevelDisplay: prop<string>(''),
  weightGoal: prop<string>(''),
  weightGoalDisplay: prop<string>(''),
  monitorFields: prop<Array<string>>(() => [
    'waterOz',
    'treatsPerDay',
    'fruitsPerDay',
    'vegetablesPerDay',
    'caffeinePerDay',
    'alcoholPerDay',
    'caloriesPerDay',
    'energyLevel',
    'weightGoal',
  ]),
  score: prop<ScoreMap | null>(null),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserEatProfile>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserDailyEatHabit')
export class UserDailyEatHabit extends Model({
  id: prop<number | null>(null),
  weightLbs: prop<number | null>(null),
  heightIn: prop<number | null>(null),
  activityLevel: prop<string>(''),
  doDrinkWater: prop<string>(''),
  waterOz: prop<number | null>(null),
  treatsPerDay: prop<number>(),
  fruitsPerDay: prop<number>(),
  vegetablesPerDay: prop<number>(),
  doDrinkCaffeine: prop<string>(''),
  caffeinePerDay: prop<number>(),
  alcoholPerDay: prop<number>(),
  doPlanCaloricIntake: prop<string>(''),
  caloriesPerDay: prop<number | null>(null),
  energyLevel: prop<string>(''),
  energyLevelDisplay: prop<string>(''),
  weightGoal: prop<string>(''),
  weightGoalDisplay: prop<string>(''),
  monitorFields: prop<Array<string>>(Array),
  score: prop<ScoreMap | null>(null),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserDailyEatHabit>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserDailyThriveHabit')
export class UserDailyThriveHabit extends Model({
  id: prop<number | null>(null),
  hoursSleep: prop<number>(),
  qualitySleep: prop<number | null>(null),
  stressLevel: prop<number | null>(null),
  skillsToManageStress: prop<Array<ThriveSkill>>(() => new Array<ThriveSkill>()),
  skillsToManageStressDisplay: prop<Array<string>>(() => new Array<string>()),
  goalsAchieved: prop<string>(''),
  purposeLevel: prop<number | null>(null),
  score: prop<ScoreMap | null>(null),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserDailyThriveHabit>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserSweatProfile')
export class UserSweatProfile extends Model({
  id: prop<number | null>(null),
  user: prop<number | null>(null),
  trainingGoal: prop<string>(''),
  fitnessLevel: prop<string>(''),
  trainingHistory: prop<number | null>(null),
  equipment: prop<Array<SweatEquipment>>(() => new Array<SweatEquipment>()),
  sessionDuration: prop<string>(''),
  professions: prop<Array<SweatProfession>>(() => new Array<SweatProfession>()),
  requirements: prop<Array<SweatRequirement>>(() => new Array<SweatRequirement>()),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserSweatProfile>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserThriveProfile')
export class UserThriveProfile extends Model({
  id: prop<number | null>(null),
  user: prop<number | null>(null),
  howMotivated: prop<string>(''),
  morningStressLevel: prop<number | null>(null),
  eveningStressLevel: prop<number | null>(null),
  balanceInLife: prop<string>(''),
  timeToSleep: prop<number | null>(null),
  impactOnPerformance: prop<string>(''),
  belief: prop<string>(''),
  focusAbility: prop<string>(''),
  hoursSleep: prop<number | null>(null),
  qualitySleep: prop<number | null>(null),
  stressLevel: prop<number | null>(null),
  skillsToManageStress: prop<Array<ThriveSkill>>(() => new Array<ThriveSkill>()),
  skillsToManageStressDisplay: prop<Array<string>>(() => new Array<string>()),
  goalsAchieved: prop<string>(''),
  purposeLevel: prop<number | null>(null),
  score: prop<ScoreMap | null>(null),
  monitorFields: prop<Array<string>>(() => [
    'hoursSleep',
    'qualitySleep',
    'stressLevel',
    'skillsToManageStress',
    'goalsAchieved',
    'purposeLevel',
  ]),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserThriveProfile>) {
    Object.assign(this, data);
  }

  @modelAction
  set(key: string, value: number | string | null) {
    return Object.assign(this, { [key]: value });
  }
}

@model('o2x-store/UserSetting')
export class UserSetting extends Model({
  id: prop<number>(),
  user: prop<number>(),
  twentyFourHourClock: prop<boolean>(false),
  inAppSounds: prop<boolean>(false),
  vibration: prop<boolean>(false),
  useIntervalTimerForWorkouts: prop<boolean>(false),
  dailyPlanReminders: prop<boolean>(false),
  eventReminders: prop<boolean>(false),
  organizationNotifications: prop<boolean>(false),
  accountNotifications: prop<boolean>(false),
  promotions: prop<boolean>(false),
  shareEatActivity: prop<boolean>(false),
  shareSweatActivity: prop<boolean>(false),
  shareThriveActivity: prop<boolean>(false),
  shareAssessmentResults: prop<boolean>(false),
  autoLoadVideos: prop<boolean>(false),
  useInternalStorage: prop<boolean>(false),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<UserSetting>) {
    Object.assign(this, data);
  }
}

@model('o2x-store/User')
export default class User extends Model({
  id: prop<number>(),
  o2XId: prop<string>(),
  username: prop<string>(''),
  fullName: prop<string>(''),
  firstName: prop<string>(''),
  lastName: prop<string>(''),
  email: prop<string>(''),
  phone: prop<string>(''),
  profileImage: prop<string>(''),
  signatureImage: prop<string>(''),
  birthDate: prop<string>(''),
  age: prop<number | null>(null),
  biography: prop<string>(''),
  gender: prop<string>(''),
  licensedIssuedBody: prop<string>(''),
  height: prop<string>(''),
  weight: prop<string>(''),
  bmi: prop<string>(''),
  bmr: prop<string>(''),
  address: prop<string>(''),
  city: prop<string>(''),
  stateCode: prop<string>(''),
  countryCode: prop<string>(''),
  zipCode: prop<string>(''),
  profession: prop<string>(''),
  addressLatitude: prop<number>(),
  addressLongitude: prop<string>(''),
  companyName: prop<string>(''),
  eatProfile: prop<number>(),
  sweatProfile: prop<number>(),
  settings: prop<UserSetting>(),
  isSubscribed: prop<boolean>(false),
  isOnboarded: prop<boolean>(false),
  organization: prop<number | null>(null),
  isOrganizationFte: prop<boolean>(false),
  promoCode: prop<string>(''),
  userGroups: prop<number[]>(),
}) {
  @computed
  get organizationObj(): Organization | undefined {
    if (this.organization) {
      const { organization } = getRoot<RootStore>(this);
      return organization.organizations.get(`${this.organization}`);
    }
  }

  @modelAction
  update(data: ModelInstanceCreationData<User>) {
    Object.assign(this, data);
  }

  @asDate('birthDate')
  birthDateDate!: Date;
}
