import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  Input,
  Layout,
  Modal,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import SweatGlobalStep from 'o2x-store/src/models/SweatGlobalStep';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { NativeScrollEvent, ScrollView, TouchableOpacity } from 'react-native';

type Props = {
  hideStepModal: () => void;
  stepModalVisible: boolean;
  type: string;
  setPrepare: Function;
  setRecover: Function;
  setPrepName: Function;
  setRecName: Function;
  currentId: number | null;
  content: string;
  setAllPrepare: Function;
  setAllRecover: Function;
};

const AddExerciseModal: React.FC<Props> = (props) => {
  const {
    hideStepModal,
    stepModalVisible,
    type,
    setPrepare,
    setRecover,
    setPrepName,
    setRecName,
    currentId,
    content,
    setAllPrepare,
    setAllRecover,
  } = props;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);

  const [steps, setSteps] = useState<SweatGlobalStep[]>();
  const [nextRequest, setNextRequest] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(currentId);
  const [selectedName, setSelectedName] = useState('');
  const [search, setSearch] = useState('');

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (stepModalVisible) {
          setLoading(true);
          const fetchData = await store.sweat.fetchFTESweatGlobalSteps(store.auth.user?.id!, type);
          const defaultStep: Partial<SweatGlobalStep> = {
            id: null,
            name: `O2X Standard ${type === 'prepare' ? 'PREPARE' : 'RECOVER'} Step`,
          };
          const noneStep: Partial<SweatGlobalStep> = {
            id: 0,
            name: '---',
          };

          setNextRequest(fetchData.extra?.next);
          setSteps([noneStep, defaultStep, ...fetchData.extra?.results]);
          setLoading(false);
        }
      })();
    }, [stepModalVisible]),
  );

  const onSearch = async () => {
    setLoading(true);
    const url = `${config.urls.sweatGlobalSteps}?search=${search}&fte=${store.auth.user
      ?.id!}&step_type=${type}&limit=10`;
    const fetchData = await store.sweat.fetchFTESweatGlobalSteps(store.auth.user?.id!, type, url);
    setNextRequest(fetchData.extra?.next);
    setSteps(fetchData.extra?.results);
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (nextRequest) {
      const fetchData = await store.sweat.fetchFTESweatGlobalSteps(
        store.auth.user?.id!,
        type,
        nextRequest,
      );
      setSteps([...steps!, ...fetchData.extra?.results]);
      setNextRequest(fetchData.extra?.next);
    }
    setLoadingMore(false);
  };

  const onPressOk = (setAll: Boolean) => {
    if (selectedId && selectedId > 0) {
      if (type === 'prepare') {
        setPrepare(selectedId);
        setPrepName(selectedName);
        if (setAll) setAllPrepare(true);
      } else {
        setRecover(selectedId);
        setRecName(selectedName);
        if (setAll) setAllRecover(true);
      }
      hideStepModal();
    } else if (selectedId === 0) {
      if (type === 'prepare') {
        setPrepare(102); // prepare with no steps id - 102
        setPrepName('---');
        if (setAll) setAllPrepare(true);
      } else {
        setRecover(103); // recover with no steps id - 103
        setRecName('---');
        if (setAll) setAllRecover(true);
      }
      hideStepModal();
    } else {
      if (type === 'prepare') {
        setPrepare(null);
        setPrepName('O2X Standard Prepare Step');
        if (setAll) setAllPrepare(true);
      } else {
        setRecover(null);
        setRecName('O2X Standard Recover Step');
        if (setAll) setAllRecover(true);
      }
      hideStepModal();
    }
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  return (
    <Modal
      visible={stepModalVisible}
      onBackdropPress={hideStepModal}
      backdropStyle={styles.backdrop}>
      <Layout style={{ height: 350, width: 300 }}>
        <Layout style={{ height: '100%', width: '100%' }}>
          <Input
            size="small"
            style={styles.input}
            placeholder={`Search ${type === 'prepare' ? 'Prepare Step' : 'Recover Step'}`}
            accessoryLeft={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
            value={search}
            onChangeText={(text) => setSearch(text)}
            onKeyPress={(key) => (key.nativeEvent.key === 'Enter' ? onSearch() : null)}
          />
          {loading ? (
            <Layout style={styles.loading}>
              <Spinner />
            </Layout>
          ) : (
            <ScrollView
              style={styles.exerciseContainer}
              onScroll={({ nativeEvent }) => {
                if (isCloseToBottom(nativeEvent)) {
                  loadMore();
                }
              }}
              scrollEventThrottle={400}>
              {steps?.map((step, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.exercise,
                    step.id === selectedId && {
                      backgroundColor: '#767F6A',
                    },
                  ]}
                  onPress={() => {
                    setSelectedId(step.id);
                    setSelectedName(step.name);
                  }}>
                  <Text style={styles.exerciseText}>{step.name}</Text>
                </TouchableOpacity>
              ))}
              {!loading && loadingMore && (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              )}
            </ScrollView>
          )}
          <Layout style={{ flexDirection: 'row' }}>
            {content === 'program' && (
              <TouchableOpacity
                style={[styles.okButton, { marginRight: 0 }]}
                onPress={() => onPressOk(true)}>
                <Text style={{ fontSize: 12, letterSpacing: 0.2 }}>Apply for all Workouts</Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={[
                styles.okButton,
                content === 'program' && {
                  marginLeft: 10,
                  marginRight: 'auto',
                },
              ]}
              onPress={() => onPressOk(false)}>
              <Text style={{ fontSize: 12, letterSpacing: 0.2 }}>Apply for this Workout</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  createStyle: {
    marginTop: 5,
    marginRight: 'auto',
    marginLeft: 15,
    flexDirection: 'row',
  },
  createText: {
    fontSize: 14,
    color: '#8F9BB3',
  },
  input: {
    marginTop: 20,
    width: '90%',
    alignSelf: 'center',
    backgroundColor: '#1A3248',
  },
  okButton: {
    marginTop: 'auto',
    marginBottom: 15,
    marginLeft: 'auto',
    marginRight: 15,
    padding: 5,
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#767F6A',
  },
  exerciseContainer: {
    maxHeight: '75%',
    marginTop: 10,
    alignSelf: 'center',
    width: '100%',
  },
  exercise: {
    marginBottom: 5,
    alignSelf: 'center',
    width: '90%',
    padding: 2,
  },
  exerciseText: {
    fontSize: 15,
    letterSpacing: 1.2,
    marginLeft: 3,
  },
  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
});

export default observer(AddExerciseModal);
