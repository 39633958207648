import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import MediaFile from '../../models/MediaFile';

type Props = {
  mediaFiles: (MediaFile | undefined)[];
  simultaneousDownloads?: number;
};

const MediaFileDownloader: React.FC<Props> = (props) => {
  const { mediaFiles, simultaneousDownloads = 2 } = props;
  const toDownload = mediaFiles.filter((f) => f && !f.downloaded);
  const count = toDownload.length;
  const loadingCount = toDownload.filter((f) => f?.loading).length;
  useEffect(() => {
    console.log(
      `[DEBUG] Downloading: ${loadingCount}(${simultaneousDownloads})/${count}`,
    );
    if (count > loadingCount && loadingCount < simultaneousDownloads) {
      toDownload
        .filter((f) => f && !f.loading && !f.downloaded)
        .slice(0, simultaneousDownloads - loadingCount)
        .forEach((f) => f?.downloadMedia());
    }
  }, [count, loadingCount]);

  // Recheck downloads if they still exist
  const downloaded = mediaFiles.filter((f) => f?.downloaded);
  const downloadedCount = downloaded.length;
  useEffect(() => {
    if (!loadingCount) {
      downloaded.forEach((f) => f?.downloadMedia());
    }
  }, [loadingCount, downloadedCount]);

  return null;
};

export default observer(MediaFileDownloader);
