import { Modal, Text } from '@ui-kitten/components';
import { POPUP_CONTAINER_CHOICES } from 'o2x-store/src/models/Tutorial';
import { useStore } from 'o2x-store/src/stores';
import React, { forwardRef, Ref, useCallback, useEffect, useState } from 'react';
import {
  Image,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

type Props = TextInputProps & {
  style?: StyleProp<ViewStyle>;
  component: POPUP_CONTAINER_CHOICES;
  textStyle?: StyleProp<ViewStyle>;
  onOpen?: () => void;
};

type Popup = {
  id: number;
  image: string;
  label: string;
  order: number;
};

const PopupComponent: React.FC<Props> = forwardRef((props: Props, ref?: Ref<TextInput>) => {
  const { style, component, onOpen = () => {} } = props;

  const { tutorial: tutorialStore } = useStore();
  const [label, setLabel] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [popups, setPopups] = useState<Popup[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentPopupIndex, setCurrentPopupIndex] = useState<number>(0);

  if (!tutorialStore) {
    console.warn('[POPUP] No tutorial store imported');
    return null;
  }

  const getPopups = useCallback(() => {
    const popupContainer = tutorialStore.tutorials.get(`${component}`);
    if (popupContainer) {
      let popupsData = popupContainer?.popups;
      if (!popupsData) popupsData = [];
      if (popupsData.length > 0) {
        setPopups(popupsData);
        setIsVisible(true);
        onOpen();
      }
    }
  }, [component, setIsVisible, tutorialStore, tutorialStore.tutorials]);

  const onNext = () => {
    if (currentPopupIndex + 1 === popups.length) {
      handleMarkSeen(true);
      setIsVisible(false);
      console.log('[POPUP] Completed all popups for this screen.');
      return;
    }
    handleMarkSeen(false);
    setCurrentPopupIndex((prev) => prev + 1);
  };

  const handleMarkSeenForLater = useCallback(() => {
    setIsVisible(false);
    tutorialStore.markPopupAsSeen(popups[currentPopupIndex].id, true);
    // delete all popup containers downloaded
    tutorialStore.deletePopupContainers();
  }, [tutorialStore, setIsVisible, popups, currentPopupIndex]);

  const handleMarkSeen = useCallback(
    (done: boolean) => {
      tutorialStore.markPopupAsSeen(popups[currentPopupIndex].id, false);
      if (done) {
        tutorialStore.deletePopupContainer(`${component}`);
      }
    },
    [tutorialStore, popups, component, currentPopupIndex],
  );

  useEffect(() => {
    if (popups.length > 0 && currentPopupIndex < popups.length) {
      setImage(popups[currentPopupIndex].image);
      setLabel(popups[currentPopupIndex].label);
    }
  }, [setImage, setLabel, popups, currentPopupIndex]);

  useEffect(() => {
    setCurrentPopupIndex(0);
    getPopups();
  }, [component]);

  return isVisible ? (
    <Modal visible backdropStyle={styles.popupBackground} style={[style, styles.popupContent]}>
      {image ? (
        <View style={styles.imageContainer}>
          <Image style={styles.popupImage} source={{ uri: image }} />
        </View>
      ) : null}
      <View style={styles.popupTextBox}>
        <Text style={styles.popupText}>{label}</Text>
      </View>

      <View style={styles.actions}>
        <TouchableOpacity
          style={[styles.popupButton, styles.popupButtonBlue]}
          onPress={handleMarkSeenForLater}>
          <Text style={styles.popupButtonText}>Skip Tutorial</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.popupButton} onPress={onNext}>
          <Text style={styles.popupButtonText}>
            {currentPopupIndex + 1 === popups.length ? 'Got it!' : 'Next'}
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  ) : (
    <></>
  );
});

const styles = StyleSheet.create({
  popupBackground: {
    backgroundColor: '#000000BE',
  },
  popupContent: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',

    // shadows
    // ios
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    // android
    elevation: 5,
  },
  popupTextBox: {
    backgroundColor: '#767F6A',
    padding: 30,
    marginHorizontal: 30,
    marginBottom: 25,
    minHeight: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupText: {
    fontSize: 18,
    textAlign: 'center',
  },
  popupButton: {
    marginTop: 5,
    padding: 20,
    backgroundColor: '#767F6A',
    alignItems: 'center',
    width: '50%',
  },
  popupButtonBlue: {
    backgroundColor: '#203950',
  },
  popupButtonText: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
  popupImage: {
    height: '70%',
    width: '85%',
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  actions: {
    flexDirection: 'row',
  },
});

export default PopupComponent;
