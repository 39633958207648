import Humanize from 'humanize-plus';
import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';

export type SweatGlobalStepExerciseSet = {
  id: number | null;
  reps: string;
  isRepsEachSide: boolean;
  weight: string;
  distance: string;
  time: string;
  rest: string;
  rpe: string;
  exercise: number;
};

@model('o2x-store/SweatGlobalStepExercise')
export default class SweatGlobalStepExercise extends Model({
  id: prop<number>(),
  order: prop<number>(),
  sets: prop<string>(''),
  reps: prop<string>(''),
  isRepsEachSide: prop<boolean>(false),
  distance: prop<string>(''),
  time: prop<string>(''),
  workoutTimerWork: prop<number | null>(null),
  workoutTimerRest: prop<number | null>(null),
  rest: prop<string>(''),
  instructions: prop<string>(''),
  notes: prop<string>(''),
  difficulty: prop<string>(''),
  exercise: prop<number>(),
  exerciseName: prop<string>(),
  exerciseSets: prop<SweatGlobalStepExerciseSet[]>(),
  exerciseVideo: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<SweatGlobalStepExercise>) {
    Object.assign(this, data);
  }

  @computed
  get subtitle(): string {
    const part1 = [];
    if (this.sets) {
      part1.push(
        `${this.sets} ${Humanize.pluralize(parseInt(this.sets), 'set')}`,
      );
    }
    if (this.reps) {
      let repText = '';
      repText = `${this.reps} ${Humanize.pluralize(
        parseInt(this.reps),
        'rep',
      )}`;

      if (this.isRepsEachSide) {
        repText += ' each side';
      }
      part1.push(repText);
    }
    if (this.time && this.time !== '0') {
      part1.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      part1.push(this.distance);
    }

    return part1.join(' | ');
  }

  @computed
  get repTimeDistanceSubtitle(): string {
    const part1 = [];
    if (this.reps) {
      let repText = '';
      repText = `${this.reps} ${Humanize.pluralize(
        parseInt(this.reps),
        'rep',
      )}`;

      if (this.isRepsEachSide) {
        repText += ' each side';
      }
      part1.push(repText);
    }
    if (this.time && this.time !== '0') {
      part1.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      part1.push(this.distance);
    }

    return part1.join(' | ');
  }

  @computed
  get timeDistanceSubtitle(): string {
    const subtitle = [];
    if (this.time && this.time !== '0') {
      subtitle.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      subtitle.push(this.distance);
    }

    return subtitle.join(' | ');
  }

  @computed
  get hasRest(): boolean {
    return !!this.rest && this.rest.toUpperCase() !== 'NONE';
  }

  @computed
  get setsAsNumber(): number | null {
    return parseInt(this.sets, 10) || null;
  }

  @computed
  get repsAsNumber(): number | null {
    return parseInt(this.reps, 10) || null;
  }

  @computed
  get workoutTimerRestComputed(): number | null {
    return this.workoutTimerRest;
  }
}
