import * as FileSystem from 'expo-file-system';
import { computed, observable } from 'mobx';
import { model, Model, modelFlow, prop, _async, _await } from 'mobx-keystone';
import { Platform } from 'react-native';

@model('o2x-native/MediaFile')
export default class MediaFile extends Model({
  key: prop<string>(''),
  downloaded: prop<boolean>(false),
  uri: prop<string>(''),
}) {
  @observable
  loading: boolean = false;

  @computed
  get relativeFileUri(): string {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] FileSystem not supported on web');
      return '';
    }
    // https://stackoverflow.com/a/42210346/1486035
    const extension = this.uri.split('.').pop();
    const filename = `${this.key.replace(/[/\\?%*:|"<>]/g, '-')}.${extension}`;
    return filename;
  }

  @computed
  get localUri(): string {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] FileSystem not supported on web');
      return '';
    }
    // https://github.com/expo/expo/issues/1594
    return `${FileSystem.documentDirectory}${this.relativeFileUri}`;
  }

  @modelFlow
  downloadMedia = _async(function* (this: MediaFile) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] FileSystem not supported on web');
      return;
    }
    if (this.loading) {
      return;
    }

    const { localUri } = this;

    // check if file is downloaded
    try {
      const info = yield* _await(FileSystem.getInfoAsync(localUri));
      if (info.exists) {
        this.downloaded = true;
        return localUri;
      }
      this.downloaded = false;
    } catch (e) {
      console.warn('[DEBUG] failed to get media info', e.name, e.message, localUri);
      this.downloaded = false;
      return;
    }

    this.loading = true;
    console.log('[DEBUG] downloading media', this.key);

    // create directory if it doesnt exist
    const dir = localUri.substring(0, localUri.lastIndexOf('/') + 1);
    if (dir) {
      try {
        const dirInfo = yield* _await(FileSystem.getInfoAsync(dir));
        if (dirInfo.exists) {
          if (!dirInfo.isDirectory) {
            console.warn('[DEBUG] file with same name as directory exists. deleting', dir);
            yield* _await(FileSystem.deleteAsync(dir, { idempotent: true }));
            yield* _await(FileSystem.makeDirectoryAsync(dir, { intermediates: true }));
          }
        } else {
          yield* _await(FileSystem.makeDirectoryAsync(dir, { intermediates: true }));
        }
      } catch (e) {
        console.warn('[DEBUG] error creating directory:', e.name, e.message, dir, localUri);
        this.downloaded = false;
        this.loading = false;
        return;
      }
    }

    try {
      const result = yield* _await(FileSystem.downloadAsync(this.uri, localUri));
      if (result.status !== 200) {
        console.warn('[DEBUG] failed to download media', result, this.uri, this.localUri);
        this.downloaded = false;
        this.loading = false;
        return;
      }
    } catch (e) {
      console.warn('[DEBUG] error downloading media:', e.name, e.message, this.uri, localUri);
      this.downloaded = false;
      this.loading = false;
      return;
    }

    this.downloaded = true;
    this.loading = false;
    return localUri;
  });

  @modelFlow
  deleteMedia = _async(function* (this: MediaFile) {
    if (Platform.OS === 'web') {
      console.log('[DEBUG] FileSystem not supported on web');
      return;
    }
    console.log('[DEBUG] deleting media', this.key);
    yield* _await(FileSystem.deleteAsync(this.localUri, { idempotent: true }));
    this.downloaded = false;
  });
}
