import { useNavigation } from '@react-navigation/native';
import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components';
import { format } from 'date-fns';
import { FTEEventModel } from 'o2x-store/src/models/Event';
import User from 'o2x-store/src/models/User';
import React, { useCallback } from 'react';
import { Dimensions, Text, TouchableOpacity, View } from 'react-native';

type Props = {
  item: FTEEventModel;
  selectedFte: User;
  userId: number;
  checkPendingInvites?: (fte: any) => void;
};

const { width } = Dimensions.get('window');
const EventItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { item, selectedFte, userId, checkPendingInvites } = props;

  const { eventName, timeStart, timeEnd } = item;

  const onPress = useCallback(() => {
    navigation.navigate('FteEventDetails', {
      item,
      selectedFte,
      checkPendingInvites,
    });
  }, [item, selectedFte, checkPendingInvites]);

  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{eventName}</Text>
        <View style={styles.labelContainer}>
          <Icon style={styles.icon} fill="#4E7B89" name="clock-outline" />
          <Text style={styles.subtitle}>
            {format(new Date(`${new Date().toDateString()} ${timeStart}`), 'HH:mm a')} -{' '}
            {format(new Date(`${new Date().toDateString()} ${timeEnd}`), 'HH:mm a')}
            {item.guests.includes(userId) && ' - Going'}
            {item.declined.includes(userId) && ' - Not Going'}
          </Text>
        </View>
      </View>
      {(item.guests.includes(userId)
        ? true
        : item.pending.includes(userId)
        ? true
        : item.declined.includes(userId)
        ? true
        : item.maxNumberOfGuests
        ? item.guests.length < item.maxNumberOfGuests
        : !!item.allowGuests &&
          !item.guests.length &&
          !item.pending.length &&
          !item.declined.length) && (
        <Icon style={styles.guests} fill="#4E7B89" name="people-outline" />
      )}
      {item.pending.includes(userId) && <View style={styles.invited} />}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginHorizontal: 20,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
    borderLeftWidth: 5,
    borderColor: '#4E7B89',
    flexDirection: 'row',
  },
  titleContainer: {
    flex: 1,
    marginVertical: 8,
    marginHorizontal: 12,
  },
  bodyContainer: {
    marginVertical: 12,
    marginHorizontal: 10,
  },
  title: {
    flex: 1,
    fontSize: 18,
    fontWeight: '700',
    color: 'white',
  },
  subtitle: {
    flex: 1,
    fontSize: 12,
    color: 'blue-secondary',
    alignSelf: 'center',
  },
  author: {
    marginVertical: 8,
    fontSize: 14,
    color: '#4E7B89',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 12,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: 20,
    width: 20,
    alignSelf: 'center',
    marginRight: 5,
  },
  iconContainer: {
    height: 30,
    width: 30,
    marginRight: 12,
  },
  bodyStyle: {
    color: 'white',
    fontSize: 15,
    textAlign: 'justify',
  },
  image: {
    maxWidth: 0.9 * width,
    maxHeight: 300,
  },
  iframe: {
    width: 0.85 * width,
    maxHeight: 300,
  },
  body: {
    flex: 1,
    padding: 22,
  },
  labelContainer: {
    flexDirection: 'row',
    marginVertical: 2,
  },
  guests: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 20,
    height: 30,
    width: 30,
  },
  invited: {
    color: 'white',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 12,
    width: 12,
    borderRadius: 6,
    top: 15,
    right: 10,
    position: 'absolute',
  },
});

export default EventItem;
