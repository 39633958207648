import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import Task from 'o2x-store/src/models/Task';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import TaskIcon from '../TaskIcon';

export type Props = {
  task: Task;
  showIcon?: boolean;
  showEllipses?: boolean;
  showBookmark?: boolean;
  showRating?: boolean;
  isToday?: boolean;
  key: string;
  onPress?: () => void;
};

const TodayItem: React.FC<Props> = (props) => {
  const { task, showIcon = false, showEllipses = false, onPress } = props;
  const styles = useStyleSheet(themedStyles);

  return (
    <TouchableOpacity disabled={!onPress} onPress={onPress}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
        level="2"
      >
        <Layout style={styles.content} level="2">
          {showIcon && <TaskIcon type={task.type} />}
          <View>
            <Text>{task.title}</Text>
            {!!task.subtitle && (
              <Text style={[styles.subtitle, { color: task.color }]}>
                {task.subtitle}
              </Text>
            )}
          </View>
          {showEllipses && (
            <Icon style={styles.icon} fill="#FFFFFF" name="more-horizontal" />
          )}
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    marginTop: 12,
    justifyContent: 'space-between',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 12,
    marginRight: 16,
    marginVertical: 8,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  title: {
    fontSize: 18,
    lineHeight: 22,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 17,
    marginTop: 7,
    textTransform: 'uppercase',
  },
  titleWeb: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  subtitleWeb: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginTop: 3,
    textTransform: 'uppercase',
  },
  imageWrapper: {
    height: 90,
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'cover',
  },
  textContainer: {
    marginVertical: 5,
    marginHorizontal: 10,
    backgroundColor: 'tranparent',
    minHeight: 45,
  },
  textContainerWeb: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  textContainerFixed: {
    height: 40,
    backgroundColor: 'tranparent',
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
  },
  borderHighlightProfile: {
    borderColor: 'blue-secondary',
  },
  textColorProfile: {
    color: 'blue-secondary',
  },
});

export default TodayItem;
