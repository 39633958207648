import { useFocusEffect } from '@react-navigation/native';
import {
  CheckBox,
  Datepicker,
  Icon,
  IndexPath,
  Input,
  Layout,
  Modal,
  NativeDateService,
  Select,
  SelectItem,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { add, format, parseISO, startOfMonth, sub } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FTEEventModel } from 'o2x-store/src/models/Event';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Animated,
  Dimensions,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { default as CalendarView } from 'src/components/FTE/Calendar';
import EventInvitationModal from 'src/containers/FTEAdmin/Calendar/EventInvitationModal';
import { getErrors } from '../../../utils/errors';
import CalendarEvent from './CalendarEvent';
type Props = {
  route?: any | null;
};

const { width, height } = Dimensions.get('window');

const Calendar: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const { register, setValue, handleSubmit } = useForm();

  const [events, setEvents] = useState<Array<FTEEventModel>>();
  const [dayEvents, setDayEvents] = useState<Array<FTEEventModel>>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventDates, setEventDates] = useState<string[]>();
  const [isEditing, setIsEditing] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [loadingB, setLoadingB] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  /*FTE Events input data */
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [link, setLink] = useState('');
  const [allowGuests, setAllowGuests] = useState(false);
  const [numberOfGuests, setNumberOfGuests] = useState<number>();
  const [pending, setPending] = useState<number[]>();
  const [guests, setGuests] = useState<number[]>();
  const [declined, setDeclined] = useState<number[]>();
  const [invited, setInvited] = useState<User[]>();
  const [showGuests, setShowGuests] = useState(true);
  const [recurring, setRecurring] = useState(false);
  const [rrule, setRrule] = useState('');
  const [endRecurringDate, setEndRecurringDate] = useState('');

  const [baseDay, setBaseDay] = useState(new Date());
  // const [nextRequest, setNextRequest] = useState('');
  // const [loadingMore, setLoadingMore] = useState(false);

  const [id, setId] = useState(0);

  const [query, setQuery] = useState('');

  const [resultInfo, setResultInfo] = useState('');
  const [resultError, setResultError] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(0);
  const animation = useRef(new Animated.Value(0)).current;

  const [isModalVisible, setModalVisible] = useState(false);

  const [showToday, setShowToday] = useState(true);
  const [showUpcoming, setShowUpcoming] = useState(false);

  const [recurringModalVisible, setRecurringModalVisible] = useState(false);
  const [editChanges, setEditChanges] = useState<FTEEventModel>();
  const [modifySolo, setModifySolo] = useState(true);

  const frequencies: { [key: string]: string } = {
    '': 'Does not repeat',
    DAILY: 'Repeats Every Day',
    WEEKLY: 'Repeats Every Week',
    MONTHLY: 'Repeats Every Month',
    YEARLY: 'Repeats Every Year',
  };

  const today = new Date();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setPending([]);
        setGuests([]);
        setDeclined([]);
        setInvited([]);
        setLoadingA(true);
        setBaseDay(new Date());
        const fetchData = await store.fteEvent.fetchFTEEvents(
          store.auth.user?.id!,
          baseDay.getMonth() + 1,
          baseDay.getFullYear(),
        );
        setEvents(fetchData.extra?.results);
        setEventDates(
          fetchData.extra?.results.map((event: FTEEventModel) => event.date),
        );
        setDayEvents(
          fetchData.extra?.results?.filter(
            (event: FTEEventModel) =>
              parseISO(event.date).getDate() === selectedDate.getDate(),
          ),
        );
        setLoadingA(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoadingB(true);
        await store.fteLibrary.fetchFTEOrganization();
        setLoadingB(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoadingC(true);
        await store.fteLibrary.fetchOSSGroups();
        setLoadingC(false);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      register({ name: 'id' }, { required: true });
      register({ name: 'eventName' }, { required: true });
      register({ name: 'location' }, { required: false });
      register({ name: 'date' }, { required: true });
      register({ name: 'timeStart' }, { required: true });
      register({ name: 'timeEnd' }, { required: true });
      register({ name: 'link' }, { required: false });
      register({ name: 'allowGuests' }, { required: false });
      register({ name: 'maxNumberOfGuests' }, { required: false });
      register({ name: 'pending' }, { required: false });
      register({ name: 'guests' }, { required: false });
      register({ name: 'declined' }, { required: false });
      register({ name: 'recurring' }, { required: false });
      register({ name: 'rrule' }, { required: false });
      register({ name: 'endRecurringDate' }, { required: false });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('eventName', name);
    setValue('location', location);
    setValue('date', date);
    setValue('timeStart', timeStart);
    setValue('timeEnd', timeEnd);
    setValue('link', link);
    setValue('id', id);
    setValue('allowGuests', allowGuests);
    setValue('maxNumberOfGuests', numberOfGuests);
    setValue('pending', pending);
    setValue('guests', guests);
    setValue('declined', declined);
    setValue('recurring', recurring);
    setValue('rrule', rrule);
    setValue('endRecurringDate', endRecurringDate);
  }, [
    name,
    location,
    date,
    link,
    id,
    timeStart,
    timeEnd,
    allowGuests,
    numberOfGuests,
    pending,
    guests,
    declined,
    recurring,
    rrule,
    endRecurringDate,
  ]);

  const onSubmit = useCallback(
    async (data) => {
      if (data.endRecurringDate === '') data.endRecurringDate = undefined;
      const organization = store.fteLibrary.fteOrganization.find(
        (org) => org.id === store.auth.user?.organization,
      );
      if (data.maxNumberOfGuests && data.pending.length === 0) {
        const pending = organization?.users
          .filter((user) => user.id !== store.auth.user?.id)
          .map((user) => {
            return user.id;
          });
        data.pending = pending;
      }
      setLoadingSave(true);

      const result = await store.fteEvent.createFTEEvent(data);
      if (result.ok) {
        if (data.pending?.length > 0) {
          await store.fteEvent.sendNotification({
            recipientUsers: data.pending.toString(),
            title: 'Your On-site Specialist invited you to a new event',
            body: `New Event`,
            extra: {
              type: 'New Event',
              fte: {
                id: store.auth.user.id,
                email: store.auth.user?.email,
                fullName: store.auth.user?.fullName,
                firstName: store.auth.user?.firstName,
                lastName: store.auth.user?.lastName,
                o2xId: store.auth.user?.o2XId,
                organizations: store.auth.user?.organization,
                profileImage: store.auth.user?.profileImage,
              },
              event: data,
            },
          });
        }

        const fetchData = await store.fteEvent.fetchFTEEvents(
          store.auth.user?.id!,
          baseDay.getMonth() + 1,
          baseDay.getFullYear(),
        );
        setEvents(fetchData.extra?.results);
        setEventDates(
          fetchData.extra?.results.map((event: FTEEventModel) => event.date),
        );
        setDayEvents(
          fetchData.extra?.results?.filter(
            (event: FTEEventModel) =>
              parseISO(event.date).getDate() === selectedDate.getDate(),
          ),
        );
        // setNextRequest(fetchData.extra?.next);
        setName('');
        setLocation('');
        setLink('');
        setDate('');
        setTimeStart('');
        setTimeEnd('');
        setAllowGuests(false);
        setNumberOfGuests(undefined);
        setPending([]);
        setGuests([]);
        setDeclined([]);
        setInvited([]);
        setRrule('');
        setEndRecurringDate('');
        setRecurring(false);
        setHasError(false);
      } else {
        console.log(getErrors(result.errors));
      }
      setLoadingSave(false);
      setIsDeleting(false);
      setHasError(!result.ok);
      setResultInfo(result.ok ? 'ADDED SUCCESSFULLY' : 'ADDING FAILED');
      setResultError(result.ok ? '' : `${getErrors(result.errors)}`);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: true,
      }).start(() => animation.setValue(0));
    },
    [baseDay, rrule, endRecurringDate],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onDelete = useCallback((event: FTEEventModel) => {
    setName('');
    setLocation('');
    setLink('');
    setDate('');
    setTimeStart('');
    setTimeEnd('');
    setAllowGuests(false);
    setNumberOfGuests(undefined);
    setPending([]);
    setGuests([]);
    setDeclined([]);
    setInvited([]);
    setIsEditing(false);
    setIsDeleting(true);
    setRrule('');
    setEndRecurringDate('');
    setRecurring(false);
    setToBeDeletedId(event.id);
    if (event.recurring) {
      setRecurringModalVisible(true);
    } else {
      Animated.timing(animation, {
        toValue: 0.1,
        duration: 100,
        useNativeDriver: true,
      }).start();
    }
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsDeleting(false);
    setRecurringModalVisible(false);
    animation.setValue(0);
  }, []);

  const onConfirmDelete = useCallback(async () => {
    setRecurringModalVisible(false);
    setIsDeleting(false);
    animation.setValue(0);
    if (modifySolo) {
      const result = await store.fteEvent.deleteFTEEvent(toBeDeletedId, false);
    } else {
      const result = await store.fteEvent.deleteFTEEvent(toBeDeletedId, true);
    }
    const fetchData = await store.fteEvent.fetchFTEEvents(
      store.auth.user?.id!,
      baseDay.getMonth() + 1,
      baseDay.getFullYear(),
    );
    setEvents(fetchData.extra?.results);
    setEventDates(
      fetchData.extra?.results.map((event: FTEEventModel) => event.date),
    );
    setDayEvents(
      fetchData.extra?.results?.filter(
        (event: FTEEventModel) =>
          parseISO(event.date).getDate() === selectedDate.getDate(),
      ),
    );
    // setNextRequest(fetchData.extra?.next);
  }, [toBeDeletedId, setEvents, baseDay, modifySolo]);

  const onEdit = useCallback(
    (content: FTEEventModel) => {
      console.log('onEdit', [...content.pending]);
      setIsEditing(true);
      setId(content.id);
      setName(content.eventName);
      setLocation(content.location);
      setLink(content.link);
      setDate(content.date);
      setTimeStart(content.timeStart);
      setTimeEnd(content.timeEnd);
      setAllowGuests(content.allowGuests);
      setNumberOfGuests(content.maxNumberOfGuests);
      setPending([...content.pending]);
      setInvited([
        ...content.pendingDetails,
        ...content.declinedDetails,
        ...content.guestDetails,
      ]);
      setGuests(content.guests);
      setDeclined(content.declined);
      setRecurring(content.recurring);
      setRrule(content.rrule);
      setEndRecurringDate(content.endRecurringDate);
    },
    [modifySolo],
  );

  const continueSave = useCallback(
    async (data) => {
      console.log(data);
      setLoadingSave(true);
      setRecurringModalVisible(false);
      let result;
      if (modifySolo) {
        result = await store.fteEvent.updateFTEEvent(data, false);
      } else {
        result = await store.fteEvent.updateFTEEvent(data, true);
      }
      if (result.ok) {
        const fetchData = await store.fteEvent.fetchFTEEvents(
          store.auth.user?.id!,
          baseDay.getMonth() + 1,
          baseDay.getFullYear(),
        );
        setEvents(fetchData.extra?.results);
        setEventDates(
          fetchData.extra?.results.map((event: FTEEventModel) => event.date),
        );
        setDayEvents(
          fetchData.extra?.results?.filter(
            (event: FTEEventModel) =>
              parseISO(event.date).getDate() === selectedDate.getDate(),
          ),
        );
        // setNextRequest(fetchData.extra?.next);
        setName('');
        setLocation('');
        setLink('');
        setDate('');
        setTimeStart('');
        setTimeEnd('');
        setAllowGuests(false);
        setNumberOfGuests(undefined);
        setPending([]);
        setGuests([]);
        setDeclined([]);
        setInvited([]);
        setHasError(false);
        setIsEditing(false);
        setRrule('');
        setEndRecurringDate('');
        setRecurring(false);
      } else {
        console.log(getErrors(result.errors));
      }
      setLoadingSave(false);
      setIsDeleting(false);
      setHasError(!result.ok);
      setResultInfo(result.ok ? 'SAVED SUCCESSFULLY' : 'SAVING FAILED');
      setResultError(result.ok ? '' : `${getErrors(result.errors)}`);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: true,
      }).start(() => animation.setValue(0));
    },
    [modifySolo],
  );

  const onContinueEdit = handleSubmit(continueSave);

  const saveChanges = useCallback(async (data) => {
    if (data.maxNumberOfGuests === '' || pending?.length! > 0)
      data.maxNumberOfGuests = undefined;
    if (!!data.recurring) {
      setEditChanges(data);
      setRecurringModalVisible(true);
    } else {
      continueSave(data);
    }
  }, []);

  const onSaveWrapped = handleSubmit(saveChanges);

  const onCancel = useCallback(() => {
    setRecurringModalVisible(false);
    setIsEditing(false);
    setName('');
    setLocation('');
    setLink('');
    setDate('');
    setTimeStart('');
    setTimeEnd('');
    setNumberOfGuests(undefined);
    setPending([]);
    setGuests([]);
    setDeclined([]);
    setInvited([]);
    setAllowGuests(false);
  }, [setNumberOfGuests]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [numberOfGuests, setNumberOfGuests]);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [numberOfGuests, setNumberOfGuests]);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const quarterHours = ['00', '15', '30', '45'];
  const times: string[] = [];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < 4; j++) {
      times.push(
        i < 10 ? '0' + i + ':' + quarterHours[j] : i + ':' + quarterHours[j],
      );
    }
  }

  const onCheckUser = useCallback(
    (checked: boolean, user: User) => {
      if (checked) {
        if (pending) {
          setPending(
            [...pending!, user.id].filter(
              (userId) =>
                !guests?.includes(userId) && !declined?.includes(userId),
            ),
          );
          setInvited(
            [...invited!, user].filter(
              (user) =>
                !guests?.includes(user.id) && !declined?.includes(user.id),
            ),
          );
        } else {
          setPending([user.id]);
          setInvited([user]);
        }
      } else {
        const arr = [...pending!];
        const arr2 = [...invited!];
        const index = arr.indexOf(user?.id);
        const index2 = arr2.indexOf(user);
        arr.splice(index, 1);
        arr2.splice(index, 1);
        setPending([...arr]);
        setInvited([...arr2]);
        if (guests && guests.includes(user?.id)) {
          const arr = [...guests];
          const index = arr.indexOf(user?.id);
          arr.splice(index, 1);
          setGuests([...arr]);
        }
        if (declined && declined.includes(user?.id)) {
          const arr = [...declined];
          const index = arr.indexOf(user?.id);
          arr.splice(index, 1);
          setDeclined([...arr]);
        }
      }
    },
    [pending, guests, declined, numberOfGuests, invited],
  );

  const onCheckOrg = useCallback(
    (checked: boolean, users: User[]) => {
      if (checked) {
        setNumberOfGuests(undefined);
        if (pending) {
          setPending(
            [
              ...pending!,
              ...users
                .filter(
                  (user) =>
                    user.id !== store.auth.user?.id &&
                    !pending.includes(user.id),
                )
                .map((user) => user.id),
            ].filter(
              (userId) =>
                !guests?.includes(userId) && !declined?.includes(userId),
            ),
          );
          setInvited(
            [
              ...invited!,
              ...users.filter(
                (user) =>
                  user.id !== store.auth.user?.id && !pending.includes(user.id),
              ),
            ].filter(
              (user) =>
                !guests?.includes(user.id) && !declined?.includes(user.id),
            ),
          );
        } else {
          setPending([
            ...users
              .filter((user) => user.id !== store.auth.user?.id)
              .map((user) => user.id),
          ]);
          setInvited([
            ...users.filter((user) => user.id !== store.auth.user?.id),
          ]);
        }
      } else {
        var arr = [...pending!];
        const arr2 = users
          .filter((user) => user.id !== store.auth.user?.id)
          .map((user) => user.id);
        arr = arr.filter((val) => !arr2.includes(val));
        setPending([...arr]);
        var arr3 = [...invited!];
        const arr4 = users.filter((user) => user.id !== store.auth.user?.id);
        arr3 = arr3.filter((val) => !arr4.includes(val));
        setInvited([...arr3]);
        if (
          guests &&
          users.filter((user) => guests.includes(user.id)).length > 0
        ) {
          var arr = [...guests];
          const arr2 = users
            .filter((user) => user.id !== store.auth.user?.id)
            .map((user) => user.id);
          arr = arr.filter((val) => !arr2.includes(val));
          setGuests([...arr]);
        }
        if (
          declined &&
          users.filter((user) => declined.includes(user.id)).length > 0
        ) {
          var arr = [...declined];
          const arr2 = users
            .filter((user) => user.id !== store.auth.user?.id)
            .map((user) => user.id);
          arr = arr.filter((val) => !arr2.includes(val));
          setDeclined([...arr]);
        }
      }
    },
    [pending, guests, declined, numberOfGuests, invited],
  );

  const onPrevMonth = useCallback(async () => {
    const startDay = startOfMonth(baseDay);
    const prevMonthDay = sub(startDay, { months: 1 });
    setLoadingA(true);
    const fetchData = await store.fteEvent.fetchFTEEvents(
      store.auth.user?.id!,
      prevMonthDay.getMonth() + 1,
      prevMonthDay.getFullYear(),
    );
    setEvents(fetchData.extra?.results);
    setEventDates(
      fetchData.extra?.results.map((event: FTEEventModel) => event.date),
    );
    setShowToday(false);
    setShowUpcoming(true);
    setDayEvents(
      fetchData.extra?.results.filter((event: FTEEventModel) =>
        prevMonthDay.getMonth() < today.getMonth()
          ? parseISO(event.date).getMonth() === prevMonthDay.getMonth()
          : parseISO(event.date).getDate() >= today.getDate(),
      ),
    );
    setLoadingA(false);
    setBaseDay(prevMonthDay);
    if (prevMonthDay.getMonth() !== today.getMonth())
      setSelectedDate(prevMonthDay);
    else setSelectedDate(today);
  }, [baseDay]);

  const onNextMonth = useCallback(async () => {
    const startDay = startOfMonth(baseDay);
    const nextMonthDay = add(startDay, { months: 1 });
    setLoadingA(true);
    const fetchData = await store.fteEvent.fetchFTEEvents(
      store.auth.user?.id!,
      nextMonthDay.getMonth() + 1,
      nextMonthDay.getFullYear(),
    );
    setEvents(fetchData.extra?.results);
    setEventDates(
      fetchData.extra?.results.map((event: FTEEventModel) => event.date),
    );
    setShowToday(false);
    setShowUpcoming(true);
    setDayEvents(
      fetchData.extra?.results.filter((event: FTEEventModel) =>
        nextMonthDay.getMonth() > today.getMonth()
          ? parseISO(event.date).getMonth() === nextMonthDay.getMonth()
          : parseISO(event.date).getDate() >= today.getDate(),
      ),
    );
    setLoadingA(false);
    setBaseDay(nextMonthDay);
    if (nextMonthDay.getMonth() !== today.getMonth())
      setSelectedDate(nextMonthDay);
    else setSelectedDate(today);
  }, [baseDay]);

  const onSelect = useCallback(
    (date: Date) => {
      (() => {
        setShowToday(true);
        setShowUpcoming(false);
        setSelectedDate(date);
        setDayEvents(
          events?.filter(
            (event) => parseISO(event.date).getDate() === date.getDate(),
          ),
        );
      })();
    },
    [selectedDate, events],
  );

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  const onTodayChange = useCallback(
    (status: boolean) => {
      setShowToday(status);
      if (status) {
        setShowUpcoming(false);
        setDayEvents(
          events?.filter(
            (event) =>
              parseISO(event.date).getDate() === selectedDate.getDate(),
          ),
        );
      }
    },
    [showToday, showUpcoming, dayEvents, selectedDate],
  );

  const onUpcomingChange = useCallback(
    (status: boolean) => {
      setShowUpcoming(status);
      if (status) {
        setShowToday(false);
        setDayEvents(
          events?.filter((event) =>
            baseDay.getMonth() !== today.getMonth()
              ? parseISO(event.date).getMonth() === baseDay.getMonth()
              : parseISO(event.date).getDate() >= today.getDate(),
          ),
        );
      }
    },
    [showToday, showUpcoming, dayEvents],
  );

  const formatDateService = new NativeDateService('en', {
    format: 'MM/DD/YYYY',
  });

  const loading = loadingA || loadingB || loadingC;

  const recurringModal = () => (
    <Modal
      visible={recurringModalVisible}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => setRecurringModalVisible(false)}
    >
      <Layout style={{ width: 500, height: 200 }}>
        <Text style={{ marginTop: 25, marginLeft: 20, fontSize: 24 }}>
          {isDeleting ? 'DELETE' : 'SAVE CHANGES'}
        </Text>
        <CheckBox
          style={{ marginLeft: 20, marginTop: 10 }}
          checked={modifySolo}
          onChange={(status) => {
            if (!modifySolo) setModifySolo(status);
          }}
        >
          {() => (
            <Text
              style={{
                fontSize: 16,
                marginLeft: 5,
                opacity: 0.5,
              }}
            >
              For this event only
            </Text>
          )}
        </CheckBox>
        <CheckBox
          style={{ marginLeft: 20, marginTop: 5 }}
          checked={!modifySolo}
          onChange={(status) => {
            if (status && modifySolo) setModifySolo(false);
          }}
        >
          {() => (
            <Text
              style={{
                fontSize: 16,
                marginLeft: 5,
                opacity: 0.5,
              }}
            >
              For all recurring events
            </Text>
          )}
        </CheckBox>
        <Layout
          style={{
            flexDirection: 'row',
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 20,
            marginBottom: 20,
          }}
        >
          <TouchableOpacity
            style={[styles.cancelButton, { width: 150 }]}
            onPress={() => {
              if (isDeleting) onCancelDelete();
              else onCancel();
            }}
          >
            <Text style={styles.buttonText}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.editButton, { width: 150 }]}
            onPress={
              isDeleting
                ? () => onConfirmDelete()
                : () => continueSave(editChanges)
            }
          >
            <Text style={styles.buttonText}>Continue</Text>
          </TouchableOpacity>
        </Layout>
      </Layout>
    </Modal>
  );

  return (
    <Layout style={styles.container}>
      {recurringModal()}
      <ScrollView
        contentContainerStyle={
          !!isMobile ? styles.mobileBg : styles.bgContainer
        }
      >
        <EventInvitationModal
          isModalVisible={isModalVisible}
          hideModal={() => setModalVisible(false)}
          setQuery={setQuery}
          fteOrganizations={store.fteLibrary.fteOrganization}
          query={query}
          onCheckOrg={onCheckOrg}
          onCheckUser={onCheckUser}
          pending={pending}
          guests={guests}
          declined={declined}
          groups={store.fteLibrary.ossGroups}
          isMobile={isMobile}
        />
        <Layout
          style={
            !!isMobile ? styles.mobileInputContainer : styles.inputContainer
          }
        >
          <Input
            style={styles.firstInput}
            placeholder="Event Name"
            size="medium"
            value={name}
            onChangeText={(text) => setName(text)}
            accessoryRight={() => (
              <Icon
                style={styles.icon}
                fill="#4E7B89"
                name="checkmark-square-2-outline"
              />
            )}
          />
          <Input
            style={styles.input}
            placeholder="Location (optional)"
            size="medium"
            value={location}
            onChangeText={(text) => setLocation(text)}
            accessoryRight={() => (
              <Icon style={styles.icon} fill="#4E7B89" name="pin-outline" />
            )}
          />
          <Input
            style={styles.input}
            placeholder="Link (optional)"
            size="medium"
            value={link}
            onChangeText={(text) => setLink(text)}
            accessoryRight={() => (
              <Icon style={styles.icon} fill="#4E7B89" name="link-2-outline" />
            )}
          />
          <CheckBox
            style={styles.radioInput}
            checked={allowGuests}
            onChange={(checked) => setAllowGuests(checked)}
          >
            {() => (
              <Text style={{ fontSize: 12, marginLeft: 5, opacity: 0.4 }}>
                Allow Guests?
              </Text>
            )}
          </CheckBox>
          {!!allowGuests && (
            <>
              <Layout style={styles.timeContainer}>
                <Input
                  style={styles.guestInput}
                  placeholder="Number of guests"
                  size="medium"
                  value={numberOfGuests?.toString()}
                  onChangeText={(text) =>
                    isNaN(parseInt(text))
                      ? setNumberOfGuests(undefined)
                      : setNumberOfGuests(parseInt(text))
                  }
                />
                <TouchableOpacity
                  style={styles.cancelButton}
                  onPress={() => showModal()}
                  disabled={!allowGuests}
                >
                  <Text style={{ fontSize: 12, marginLeft: 5, opacity: 0.4 }}>
                    Invite Guests
                  </Text>
                </TouchableOpacity>
              </Layout>
              <TouchableOpacity
                style={{
                  backgroundColor: 'transparent',
                  marginLeft: '7.5%',
                  flexDirection: 'row',
                  marginTop: 8,
                  marginBottom: 8,
                }}
                onPress={() => setShowGuests(!showGuests)}
              >
                <Text style={{ fontSize: 14 }}>Invited Guests</Text>
                <Layout
                  style={{
                    backgroundColor: 'transparent',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                    marginLeft: 20,
                  }}
                >
                  <Icon
                    name={
                      showGuests
                        ? 'arrow-ios-upward-outline'
                        : 'arrow-ios-downward-outline'
                    }
                    fill="white"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </Layout>
              </TouchableOpacity>
              {showGuests && (
                <Layout
                  style={{
                    marginLeft: '7.5%',
                    width: '85%',
                    maxHeight: 250,
                    backgroundColor: '#0B1F35',
                  }}
                >
                  <ScrollView>
                    {invited?.map((user, index) => (
                      <Layout style={styles.userContent} key={index}>
                        <Image
                          style={styles.userProfile as StyleProp<ImageStyle>}
                          source={
                            user.profileImage
                              ? { uri: user.profileImage }
                              : require('../../../assets/images/user_placeholder.png')
                          }
                        />
                        <Text style={styles.orgText}>
                          {user.fullName ? user.fullName : user.email}
                        </Text>
                      </Layout>
                    ))}
                  </ScrollView>
                </Layout>
              )}
            </>
          )}

          <Datepicker
            style={styles.dateInput}
            placeholder="Date"
            date={date.length > 0 ? parseISO(date) : undefined}
            onSelect={(date: Date) => setDate(format(date, 'yyyy-MM-dd'))}
            size="medium"
            accessoryRight={() => (
              <Icon
                style={styles.icon}
                fill="#4E7B89"
                name="calendar-outline"
              />
            )}
            dateService={formatDateService}
            max={new Date(2050, 0, 0)}
            min={new Date(2000, 0, 0)}
          />
          <Layout style={styles.timeContainer}>
            <Select
              style={styles.timeInput}
              size="medium"
              accessoryRight={() => (
                <Icon style={styles.icon} fill="#4E7B89" name="clock-outline" />
              )}
              placeholder={() => (
                <Text style={{ fontSize: 12, marginLeft: 8, opacity: 0.4 }}>
                  Time Start
                </Text>
              )}
              value={
                timeStart.length > 0
                  ? () => (
                      <Text
                        style={{ fontSize: 12, marginLeft: 8, opacity: 0.4 }}
                      >
                        {timeStart}
                      </Text>
                    )
                  : timeStart
              }
              onSelect={(index: number) => {
                setTimeStart(times[index - 1]);
              }}
            >
              {times.map((time, index) => {
                return <SelectItem key={index} title={time} />;
              })}
            </Select>
            <Select
              style={styles.timeInput}
              size="medium"
              accessoryRight={() => (
                <Icon style={styles.icon} fill="#4E7B89" name="clock-outline" />
              )}
              placeholder={() => (
                <Text style={{ fontSize: 12, marginLeft: 8, opacity: 0.4 }}>
                  Time End
                </Text>
              )}
              value={
                timeEnd.length > 0
                  ? () => (
                      <Text
                        style={{ fontSize: 12, marginLeft: 8, opacity: 0.4 }}
                      >
                        {timeEnd}
                      </Text>
                    )
                  : timeEnd
              }
              onSelect={(index: number) => {
                setTimeEnd(times[index - 1]);
              }}
            >
              {times.map((time, index) => {
                return <SelectItem key={index} title={time} />;
              })}
            </Select>
          </Layout>
          <Layout style={styles.timeContainer}>
            <Select
              style={[styles.timeInput, { width: '35%' }]}
              size="medium"
              value={() => (
                <Text style={{ fontSize: 12, marginLeft: 8, opacity: 0.4 }}>
                  {frequencies[rrule]}
                </Text>
              )}
              onSelect={(index: IndexPath | IndexPath[]) => {
                index.row === 0 ? setRecurring(false) : setRecurring(true);
                setRrule(Object.keys(frequencies)[index.row]);
              }}
            >
              {Object.values(frequencies).map((freq, index) => {
                return <SelectItem key={index} title={freq} />;
              })}
            </Select>
            <Text
              style={{
                fontSize: 12,
                marginLeft: 8,
                opacity: 0.4,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              Ends on:
            </Text>
            <Datepicker
              disabled={!recurring}
              style={{
                width: '50%',
              }}
              placeholder="Date"
              date={
                endRecurringDate?.length > 0
                  ? parseISO(endRecurringDate)
                  : undefined
              }
              onSelect={(date: Date) =>
                setEndRecurringDate(format(date, 'yyyy-MM-dd'))
              }
              size="medium"
              accessoryRight={() => (
                <Icon
                  style={styles.icon}
                  fill="#4E7B89"
                  name="calendar-outline"
                />
              )}
              dateService={formatDateService}
              max={new Date(2050, 0, 0)}
              min={new Date(2000, 0, 0)}
            />
          </Layout>
          {loadingSave ? (
            <Layout
              style={[
                styles.loading,
                { backgroundColor: 'transparent', marginTop: 10 },
              ]}
            >
              <Spinner />
            </Layout>
          ) : isEditing ? (
            <Layout style={styles.buttonContainer}>
              <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
                <Text style={styles.buttonText}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.editButton}
                onPress={onSaveWrapped}
              >
                <Text style={styles.buttonText}>Save Changes</Text>
              </TouchableOpacity>
            </Layout>
          ) : (
            <TouchableOpacity
              style={styles.submitButton}
              onPress={onSubmitWrapped}
            >
              <Text style={styles.buttonText}>Add to Calendar</Text>
            </TouchableOpacity>
          )}
        </Layout>
        <Layout
          style={
            !!isMobile ? styles.mobileContentContainer : styles.contentContainer
          }
        >
          <CalendarView
            setSelectedDate={onSelect}
            eventDates={eventDates}
            baseDay={baseDay}
            onNextMonth={onNextMonth}
            onPrevMonth={onPrevMonth}
          />
          <>
            <Layout
              style={{
                flexDirection: !!isMobile ? 'column' : 'row',
                backgroundColor: 'transparent',
                alignSelf: 'center',
                marginTop: 10,
              }}
            >
              <CheckBox
                style={{ marginRight: 10 }}
                checked={showToday}
                onChange={(status) => onTodayChange(status)}
              >
                {() => (
                  <Text
                    style={{
                      fontSize: 14,
                      alignSelf: 'center',
                      marginLeft: 10,
                    }}
                  >
                    Show Today's Events Only
                  </Text>
                )}
              </CheckBox>
              <CheckBox
                checked={showUpcoming}
                onChange={(status) => onUpcomingChange(status)}
                style={!!isMobile ? { marginTop: 5 } : {}}
              >
                {() => (
                  <Text
                    style={{
                      fontSize: 14,
                      alignSelf: 'center',
                      marginLeft: 10,
                    }}
                  >
                    This Month's Upcoming Events
                  </Text>
                )}
              </CheckBox>
            </Layout>
            <ScrollView
              contentContainerStyle={
                !!isMobile ? styles.mobileEventList : styles.eventList
              }
              // onScroll={({ nativeEvent }) => {
              //   if (isCloseToBottom(nativeEvent)) {
              //     loadMore();
              //   }
              // }}
              scrollEventThrottle={400}
            >
              {loading ? (
                <Layout style={styles.loading}>
                  <Spinner />
                </Layout>
              ) : (
                dayEvents?.map((event, index) => {
                  return (
                    <CalendarEvent
                      key={index}
                      event={event}
                      onDelete={() => onDelete(event)}
                      onEdit={() => onEdit(event)}
                      user={store.auth.user!}
                    />
                  );
                })
              )}
            </ScrollView>
          </>
        </Layout>
      </ScrollView>
      <View
        style={!!isMobile ? styles.mobileCenter : styles.center}
        pointerEvents={isDeleting ? undefined : 'none'}
      >
        {isDeleting && (
          <TouchableOpacity
            activeOpacity={1}
            style={!!isMobile ? styles.mobileCenter : styles.center}
            onPress={onCancelDelete}
          />
        )}
        <Animated.View
          style={[
            styles.popUpContainer,
            {
              opacity,
              transform: [{ scale }, { translateY }],
            },
          ]}
        >
          <Icon
            style={styles.savedIcon}
            name={
              isDeleting
                ? 'question-mark-circle-outline'
                : hasError
                ? 'close-circle-outline'
                : 'checkmark-circle-2-outline'
            }
            fill={isDeleting ? 'orange' : hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.saveText}>
            {isDeleting ? 'Are you sure you want to' : resultInfo}
          </Text>
          {isDeleting && (
            <Text style={styles.saveText}>delete this event?</Text>
          )}
          {!isDeleting && !!resultError && (
            <Text style={styles.errorText}>{resultError}</Text>
          )}
          {isDeleting && (
            <View style={styles.buttons}>
              <TouchableOpacity style={styles.button} onPress={onCancelDelete}>
                <Text style={styles.deleteText}>NO</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={onConfirmDelete}>
                <Text style={styles.deleteText}>YES</Text>
              </TouchableOpacity>
            </View>
          )}
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    position: 'absolute',
    width: '95%',
    height: '90%',
    left: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'row',
    flex: 1,
  },
  mobileBg: {
    position: 'absolute',
    width: '90%',
    height: '90%',
    left: '26px',
    right: '26px',
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  inputContainer: {
    width: '40%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileInputContainer: {
    width: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
    marginBottom: 10,
  },
  contentContainer: {
    width: '60%',
    height: '100%',
    backgroundColor: '#203950',
    marginBottom: 10,
  },
  mobileContentContainer: {
    width: '100%',
    backgroundColor: '#1A3248',
  },
  headerText: {
    marginLeft: '32px',
    marginTop: '28px',
    color: 'white',
  },
  eventList: {
    marginTop: '20px',
    marginBottom: '20px',
    alignSelf: 'center',
    backgroundColor: '#10283E',
    width: '92%',
  },
  mobileEventList: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: '#10283E',
    width: '100%',
  },
  dateInput: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '85%',
    color: 'white',
  },
  firstInput: {
    marginTop: '7.5%',
    marginLeft: '7.5%',
    width: '85%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  input: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '85%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  radioInput: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '30%',
    // backgroundColor: '#0B1F35',
    color: 'white',
  },
  guestInput: {
    width: '48%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  timeContainer: {
    flexDirection: 'row',
    marginLeft: '7.5%',
    width: '85%',
    marginTop: '5px',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  timeInput: {
    width: '48%',
    color: 'white',
    fontSize: 14,
  },
  icon: {
    height: 20,
    width: 16,
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
    borderRadius: 5,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
    backgroundColor: 'transparent',
  },
  cancelButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'olive',
    height: 40,
    width: '48%',
    borderRadius: 5,
  },
  editButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '48%',
    marginLeft: '4%',
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  mobileCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: width,
    height: height,
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: 500,
    height: 500,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  invContainer: {
    marginLeft: '5%',
    width: '90%',
    backgroundColor: 'transparent',
  },
  orgContainer: {
    width: '100%',
    height: 50,
    backgroundColor: '#203950',
    flexDirection: 'row',
  },
  profile: {
    width: 40,
    height: 40,
    alignSelf: 'center',
    borderRadius: 20,
    marginHorizontal: 10,
  },
  orgText: {
    opacity: 0.6,
    fontSize: 14,
    alignSelf: 'center',
  },
  userContainer: {
    width: '100%',
    marginBottom: 15,
    backgroundColor: '#091C2D',
    maxHeight: 200,
  },
  userContent: {
    width: '100%',
    borderBottom: '1px solid  #1A3248',
    height: 40,
    flexDirection: 'row',
  },
  userProfile: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    borderRadius: 15,
    marginLeft: 60,
    marginRight: 10,
  },
  searchInput: {
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    marginBottom: 10,
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'gray',
  },
  okButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 30,
    width: '20%',
    marginLeft: 'auto',
    marginRight: '5%',
    marginTop: 'auto',
    marginBottom: 10,
  },
  addInvite: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 15,
  },
  selectorIcon: {
    height: 30,
    width: 30,
    marginHorizontal: 5,
    alignSelf: 'center',
  },
  selector: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 'auto',
    marginLeft: 15,
    marginTop: '20px',
  },
  selectorText: {
    alignSelf: 'center',
    color: 'white',
  },
});

export default observer(Calendar);
