import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import About from '../../About';
import Header from '../../Header';
import Settings from '../../Settings';
import SettingsAccount from '../../Settings/Account';
import SettingsData from '../../Settings/Data';
import SettingsDisplay from '../../Settings/Display';
import SettingsNotifications from '../../Settings/Notifications';
import SettingsTeam from '../../Settings/Team';

type Props = {};

const Stack = createStackNavigator();

const SettingsStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
  >
    <Stack.Screen name="Settings" component={Settings} />
    <Stack.Screen name="SettingsAccount" component={SettingsAccount} />
    <Stack.Screen name="SettingsDisplay" component={SettingsDisplay} />
    <Stack.Screen
      name="SettingsNotifications"
      component={SettingsNotifications}
    />
    <Stack.Screen name="SettingsTeam" component={SettingsTeam} />
    <Stack.Screen name="SettingsData" component={SettingsData} />
    <Stack.Screen name="About" component={About} />
  </Stack.Navigator>
);

export default SettingsStack;
