import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { get, isNull, isUndefined, min } from 'lodash';
import { observer } from 'mobx-react-lite';
import Recipe from 'o2x-store/src/models/Recipe';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { taskToListType } from 'o2x-store/src/utils/list';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  ScrollView,
  Share,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import ReadMore from 'react-native-read-more-text';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { WebView } from 'react-native-web-webview';
import { useMediaQuery } from 'react-responsive';
import YouTube from 'react-youtube';
import NavigationBar from 'src/components/NavigationBar';
import VideoPlayer from 'src/components/VideoPlayer';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useNativeStore } from 'src/stores';
import EquipmentIcon from '../../assets/images/equipment-icon.svg';
import ProgramDetailActions from '../../components/ProgramDetailActions';
import RatingDetail from '../../components/RatingDetail';
import UserDetail from '../../components/UserDetail';
import { useSaveList } from '../../hooks/list';
import { AppStackParamList } from '../AppContainer';
import LevelIcon from './LevelIcon';

type Props = {
  route: RouteProp<AppStackParamList, 'ProgramDetail'>;
};

const ProgramDetail: React.FC<Props> = (props) => {
  const { type, id } = props.route.params;
  const store = useStore();
  const { mediaStorage } = useNativeStore();
  const { navigateWithPayWall } = useIsSubscribed();
  const [program, setProgram] = useState(store.getProgram(type, id));

  let recipe: Recipe | undefined;
  if (type === TASK_TYPE.EAT) {
    recipe = store.eat.recipes.get(`${id}`);
  }
  const recipeVideoId = !!recipe && recipe.videoId;
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const color = get(program, 'color', 'white');
  const [savedList, addToList, removeFromList, errorMessage] = useSaveList(
    store,
    taskToListType(program ? program.type : 'all'),
  );
  const [width, setWidth] = useState(Dimensions.get('window').width);

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const initialVideoWidth = isMobile
    ? Dimensions.get('window').width * 0.8 // 80% of screen width
    : isDeviceMaxWidth600
    ? 220
    : isDeviceMaxWidth768
    ? 250
    : min([Dimensions.get('window').width * 0.5, 750]); // Maximum Width of Modal

  const opts = {
    width: initialVideoWidth,
    height: (initialVideoWidth / 4) * 3,
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
    },
  };

  useEffect(() => {
    if (program) analytics.logProgramOpen(program);
  }, [program]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        if (store.getProgram(type, id) === undefined) {
          if (type === TASK_TYPE.EAT) {
            await store.eat.fetchRecipesByIds([`${id}`]);
          } else if (type === TASK_TYPE.SWEAT) {
            await store.sweat.fetchSweatProgramsByIds([`${id}`]);
          } else if (type === TASK_TYPE.THRIVE) {
            await store.thrive.fetchThriveProgramsByIds([`${id}`]);
          }
          setProgram(store.getProgram(type, id));
        }
        setLoading(false);
      })();
    }, [type, id, store.eat.loading, store.sweat.loading, store.thrive.loading]),
  );

  const onStart = useCallback(() => {
    if (type === TASK_TYPE.EAT) {
      navigateWithPayWall('EatStart', { id });
    } else if (type === TASK_TYPE.SWEAT) {
      navigateWithPayWall('SweatStart', { id });
    } else if (type === TASK_TYPE.THRIVE) {
      navigateWithPayWall('ThriveStart', { id });
    }
  }, [type, id, navigateWithPayWall]);

  const onPreview = useCallback(() => {
    navigation.navigate('ProgramPreview', { type, id });
  }, [program]);

  const onShare = useCallback(async () => {
    if (program) {
      const shareData = {
        message: program.viewUrl,
        url: program.viewUrl,
        title: program.name,
      };
      try {
        const result = await Share.share(shareData);
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }, [program]);

  const onQuit = useCallback(() => {
    if (program?.type === TASK_TYPE.SWEAT) {
      navigation.navigate('SweatOptionsQuit', { programId: program?.id });
    } else if (program?.type === TASK_TYPE.THRIVE) {
      navigation.navigate('ThriveOptionsQuit', { programId: program?.id });
    }
  }, [program]);

  const renderTruncatedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={[styles.readMore, { color }]} category="p2">
          Read More
        </Text>
      </TouchableOpacity>
    ),
    [color],
  );

  const renderRevealedFooter = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={[styles.readMore, { color }]} category="p2">
          Show Less
        </Text>
      </TouchableOpacity>
    ),
    [color],
  );

  const toggleBookmark = useCallback(() => {
    if (program) {
      if (program.saveList.length === 0) {
        // Save
        if (program.type === TASK_TYPE.EAT) {
          navigation.navigate('SaveToList', {
            listType: taskToListType(program.type),
            itemId: program.id,
          });
        } else {
          addToList(savedList, String(program.id));
        }
      } else {
        // Remove
        removeFromList(savedList, String(program.id));
      }
    }
  }, [savedList, program]);

  const recipeVideoFile = useMemo(() => {
    if (type === TASK_TYPE.EAT) {
      return recipe && mediaStorage.getOrCreateMediaFile(recipe, 'video');
    }
  }, [type]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            {!program || loading ? (
              <View style={styles.loader}>
                <Spinner />
              </View>
            ) : (
              <>
                <Image
                  style={styles.cover as StyleProp<ImageStyle>}
                  source={{ uri: program.imageBanner }}
                />
                <ScrollView style={styles.scroll}>
                  <Layout style={isDeviceMaxWidth600 ? styles.contentMaxWidth600 : styles.content}>
                    <Text category="h2">{program.name}</Text>
                    <Text style={[styles.subtitle, { color }]} category="c2">
                      {program.subtitle}
                    </Text>
                    <ProgramDetailActions
                      style={styles.actions}
                      onStart={program.type !== TASK_TYPE.EAT ? onStart : null}
                      onPreview={program.type !== TASK_TYPE.EAT ? onPreview : null}
                      onSave={toggleBookmark}
                      onShare={onShare}
                      onQuit={program.type !== TASK_TYPE.EAT ? onQuit : null}
                      saved={program ? program.saveList.length > 0 : false}
                      hasProgress={
                        !isNull(program.userProgress?.user) &&
                        !isUndefined(program.userProgress) &&
                        !program.userProgress?.isComplete
                      }
                    />
                    <RatingDetail style={styles.rating} program={program} />
                    {!!program.equipment && (
                      <View style={styles.extraSection}>
                        <View style={styles.extraSectionLabel}>
                          <EquipmentIcon width={20} />
                          <Text style={styles.extraSectionLabelText}>EQUIPMENT</Text>
                        </View>
                        <Text style={styles.extraSectionText}>
                          {Humanize.oxford(program.equipment)}
                        </Text>
                      </View>
                    )}
                    {!!program.difficulty && (
                      <View style={[styles.extraSection]}>
                        <View style={styles.extraSectionLabel}>
                          <LevelIcon color={getTaskColor(program.type)} />
                          <Text style={styles.extraSectionLabelText}>LEVEL</Text>
                        </View>
                        <Text style={styles.extraSectionText}>
                          {Humanize.titleCase(program.difficulty)}
                        </Text>
                      </View>
                    )}
                    {!!program.author && (
                      <UserDetail style={styles.author} author={program.author} />
                    )}
                    {!!program.description && (
                      <View style={styles.description}>
                        <ReadMore
                          numberOfLines={5}
                          renderTruncatedFooter={renderTruncatedFooter}
                          renderRevealedFooter={renderRevealedFooter}>
                          <Text category="p2">{program.description}</Text>
                        </ReadMore>
                      </View>
                    )}
                    {program.type == TASK_TYPE.EAT && (
                      <View>
                        {!!recipeVideoFile && (
                          <View style={styles.videoContainer}>
                            <VideoPlayer
                              videoLink={recipeVideoFile.uri}
                              size={initialVideoWidth}
                              limitMaxHeight
                            />
                          </View>
                        )}
                        {!recipeVideoFile && !!recipeVideoId && (
                          <View style={styles.videoContainer}>
                            <YouTube videoId={recipeVideoId} opts={opts} />
                          </View>
                        )}
                        <Text style={styles.eatHeader}>Ingredients</Text>
                        <WebView
                          source={{
                            html: `
                                <head>
                                  <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css' />
                                </head>
                                <div style="color:white; font-size: 14px; font-family: 'Lato';">
                                ${recipe?.ingredients}
                                </div>`,
                          }}
                        />
                        <View style={styles.spacer} />

                        <Text style={styles.eatHeader}>Directions</Text>
                        <WebView
                          source={{
                            html: `
                              <head>
                                <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css' />
                              </head>
                              <div style="color:white; font-size: 14px; font-family: 'Lato';"">
                              ${recipe?.directions}
                              </div>`,
                          }}
                        />
                      </View>
                    )}
                  </Layout>
                </ScrollView>
                <NavigationBar
                  style={[styles.navigation, { top: insets.top }]}
                  showBack={false}
                  showClose
                  withBackground
                />
              </>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  actions: {
    marginTop: 20,
    marginBottom: 30,
  },
  author: {
    marginTop: 29,
    marginBottom: 6,
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: '25%',
  },
  contentMaxWidth600: {
    paddingVertical: 10,
    paddingHorizontal: '5%',
  },
  cover: {
    height: 169,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  rating: {
    marginVertical: 5,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
  },
  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    margiVertical: 20,
  },
  eatHeader: {
    weight: 700,
    fontSize: 16,
    lineHeight: 19,
  },
  htmlText: {
    color: 'white',
    fontSize: 14,
  },
  spacer: {
    padding: 5,
  },
  description: {
    paddingTop: 10,
  },
  videoContainer: {
    marginVertical: 10,
    alignSelf: 'center',
  },
});

export default observer(ProgramDetail);
