import {
  Icon,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import PlanIcon from '../../assets/images/action-plan.svg';
import QuitIcon from '../../assets/images/action-quit.svg';
import ShareIcon from '../../assets/images/action-share.svg';
import StartIcon from '../../assets/images/action-start.svg';
import MapIcon from '../../assets/images/map.svg';

type Props = {
  style?: StyleProp<ViewStyle>;
  onMap?: () => void;
  onStart?: () => void;
  onPlan?: () => void;
  onPreview?: () => void;
  onSave?: () => void;
  onShare?: () => void;
  onQuit?: () => void;
  saved: boolean;
  hasProgress?: boolean;
};

const ProgramDetailActions: React.FC<Props> = (props) => {
  const {
    style,
    onMap,
    onStart,
    onPlan,
    onPreview,
    onSave,
    onShare,
    onQuit,
    saved,
    hasProgress = false,
  } = props;
  const styles = useStyleSheet(themedStyles);
  return (
    <Layout style={[styles.container, style]}>
      {onMap && (
        <TouchableOpacity style={styles.button} onPress={onMap}>
          <MapIcon />
          <Text style={styles.buttonLabel} category="c2">
            Map
          </Text>
        </TouchableOpacity>
      )}
      {onStart && (
        <TouchableOpacity style={styles.button} onPress={onStart}>
          <StartIcon />
          <Text style={styles.buttonLabel} category="c2">
            {hasProgress ? 'Resume' : 'Start'}
          </Text>
        </TouchableOpacity>
      )}
      {onPlan && (
        <TouchableOpacity style={styles.button} onPress={onPlan}>
          <PlanIcon />
          <Text style={styles.buttonLabel} category="c2">
            Plan
          </Text>
        </TouchableOpacity>
      )}
      {onPreview && (
        <TouchableOpacity style={styles.button} onPress={onPreview}>
          <PlanIcon />
          <Text style={styles.buttonLabel} category="c2">
            Preview
          </Text>
        </TouchableOpacity>
      )}
      {onSave && (
        <TouchableOpacity style={styles.button} onPress={onSave}>
          <Icon
            style={styles.bookmark}
            fill="#FFFFFF"
            name={saved ? 'bookmark' : 'bookmark-outline'}
          />
          <Text style={styles.buttonLabel} category="c2">
            Save
          </Text>
        </TouchableOpacity>
      )}
      {onShare && (
        <TouchableOpacity style={styles.button} onPress={onShare}>
          <ShareIcon />
          <Text style={styles.buttonLabel} category="c2">
            Share
          </Text>
        </TouchableOpacity>
      )}
      {onQuit && !!hasProgress && (
        <TouchableOpacity style={styles.button} onPress={onQuit}>
          <QuitIcon />
          <Text style={styles.buttonLabel} category="c2">
            Quit
          </Text>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  button: {
    alignItems: 'center',
  },
  buttonLabel: {
    marginTop: 4,
    textTransform: 'uppercase',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bookmark: {
    height: 25,
    width: 25,
  },
});

export default ProgramDetailActions;
