export enum TASK_TYPE {
  EAT = 'eat',
  SWEAT = 'sweat',
  THRIVE = 'thrive',
}

export enum TASK_COMPONENT_TYPE {
  USER_PROFILE = 'user_profile',
  EAT_PROFILE = 'eat_profile',
  THRIVE_PROFILE = 'thrive_profile',
  DEBRIEF_PROFILE = 'debrief_profile',
  EAT_HABIT = 'eat_habit',
  THRIVE_HABIT = 'thrive_habit',
  WORKOUT_GENERATOR = 'workout_generator',
  DAILY_DEBRIEF = 'daily_debrief',
  SLEEP = 'sleep',
  MEDITATE = 'meditate',
  RECIPE_GENERATOR = 'recipe_generator',
  SWEAT_PROGRAM_GENERATOR = 'sweat_program_generator',
  THRIVE_PROGRAM_GENERATOR = 'thrive_program_generator',
  RECIPE_LIST = 'recipe_list',
  SWEAT_WORKOUT_LIST = 'sweat_workout_list',
  SWEAT_PROGRAM_LIST = 'sweat_program_list',
  THRIVE_PROGRAM_LIST = 'thrive_program_list',
  THRIVE_EXERCISE_LIST = 'thrive_exercise',
  SWEAT_WORKOUT = 'sweat_workout',
  SWEAT_PROGRAM = 'sweat_program',
  THRIVE_PROGRAM = 'thrive_program',
}

export const getTaskColor = (type: TASK_TYPE): string => {
  switch (type) {
    case TASK_TYPE.EAT:
      return '#91C300';
    case TASK_TYPE.SWEAT:
      return '#04B7D6';
    case TASK_TYPE.THRIVE:
      return '#FF7A00';
    default:
      return '#FFFFFF';
  }
};
