import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import {
  AssessmentPreviewItem,
  ASSESSMENT_TYPE,
  FORM_ASSESSMENT_SCORING_TYPE,
} from './Assessment';
import FormAssessmentSubmission from './FormAssessmentSubmission';

type FormAssessmentHistory = {
  id: number;
  created: string;
  modified: string;
  score: number | null;
};

export type FormAssessmentSummary = {
  month: number;
  averageScore: number;
  averageEatScore: number;
  averageSweatScore: number;
  averageThriveScore: number;
};

export type HPAssessmentSummary = {
  eatDistribution: Distribution;
  sweatDistribution: Distribution;
  thriveDistribution: Distribution;
};

export type Distribution = {
  ten: number;
  twenty: number;
  thirty: number;
  forty: number;
  fifty: number;
  sixty: number;
  seventy: number;
  eighty: number;
  ninety: number;
  hundred: number;
};

@model('o2x-store/FormAssessment')
export default class FormAssessment extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  label: prop<string>(''),
  assessmentType: prop<string>(''),
  description: prop<string>(''),
  preview: prop<string>(''),
  coverImage: prop<string | null>(null),
  coverImageThumbnail: prop<string | null>(null),
  items: prop<number[]>(() => []),
  maxScore: prop<number>(0),
  currentSubmission: prop<number | null>(null),
  previousSubmission: prop<number | null>(null),
  history: prop<FormAssessmentHistory[]>(() => []),
  summary: prop<FormAssessmentSummary[]>(() => []),
  allUserSummary: prop<HPAssessmentSummary>(),
  bestScore: prop<number | null>(null),
  averageScore: prop<number | null>(null),
  generatedPreview: prop<Array<AssessmentPreviewItem>>(() => []),
  eatMaxScore: prop<number>(0),
  sweatMaxScore: prop<number>(0),
  thriveMaxScore: prop<number>(0),
  eatQuestionCount: prop<number>(0),
  sweatQuestionCount: prop<number>(0),
  thriveQuestionCount: prop<number>(0),
  generalQuestionCount: prop<number>(0),
  eatAverageScore: prop<number | null>(null),
  sweatAverageScore: prop<number | null>(null),
  thriveAverageScore: prop<number | null>(null),
  scoringType: prop<FORM_ASSESSMENT_SCORING_TYPE | null>(null),
  enableAccessCode: prop<boolean>(false),
  accessCode: prop<string>(''),
  userAccess: prop<boolean>(false),
  sectionList: prop<string[]>(() => []),
  displayPerSection: prop<boolean>(false),
  showPreassessmentForm: prop<boolean>(false),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  type = ASSESSMENT_TYPE.FORM;

  get previousScore(): number | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    if (assessment) {
      const submission = assessment.formAssessmentSubmissions.get(
        `${this.previousSubmission}`,
      );
      if (submission) {
        return submission.score;
      }
    }
    return null;
  }

  get currentDate(): string | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    const submission = assessment.formAssessmentSubmissions.get(
      `${this.currentSubmission}`,
    );
    if (submission) {
      return submission.created;
    }
    return null;
  }

  @modelAction
  update(source: ModelInstanceCreationData<FormAssessment>) {
    const data = { ...source };
    const currentSubmission = data.currentSubmission as ModelInstanceCreationData<FormAssessmentSubmission> | null;
    delete data.currentSubmission;
    const previousSubmission = data.previousSubmission as ModelInstanceCreationData<FormAssessmentSubmission> | null;
    delete data.previousSubmission;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    if (assessment) {
      if (currentSubmission) {
        this.currentSubmission = currentSubmission.id;
        assessment.createOrUpdateFormAssessmentSubmission(currentSubmission);
      }
      if (previousSubmission) {
        this.previousSubmission = previousSubmission.id;
        assessment.createOrUpdateFormAssessmentSubmission(previousSubmission);
      }
    }
  }

  @modelFlow
  fetch = _async(function* (this: FormAssessment) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<FormAssessment>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchFormAssessment(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching form assessment', error);
      return getError(error);
    }
    this.update(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  setCurrent = _async(function* (this: FormAssessment, submissionId: number) {
    const rootStore = getRoot<RootStore>(this);

    this.loading = true;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }
    this.currentSubmission = submissionId;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  start = _async(function* (this: FormAssessment) {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<FormAssessmentSubmission>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.startFormAssessment(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error starting form assessment', error);
      return getError(error);
    }

    assessment.createOrUpdateFormAssessmentSubmission(entities);
    this.currentSubmission = entities.id;

    analytics.logAssessmentStart(this);

    this.loading = false;
    return getSuccess();
  });
}
