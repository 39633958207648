import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC_ELD } from 'expo-av/build/Audio';
import { get, isFinite, toNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import FormAssessment from 'o2x-store/src/models/FormAssessment';
import FormAssessmentItem from 'o2x-store/src/models/FormAssessmentItem';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import PhysicalAssessment from 'o2x-store/src/models/PhysicalAssessment';
import * as analytics from 'o2x-store/src/services/analytics';
import { QUESTION_TYPES } from 'o2x-store/src/utils/questions';
import React, { useCallback, useMemo, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Eat from '../../assets/images/eat-logo.svg';
import Next from '../../assets/images/next.svg';
import Previous from '../../assets/images/previous.svg';
import Logo from '../../assets/images/survey-logo.svg';
import Sweat from '../../assets/images/sweat-logo.svg';
import Thrive from '../../assets/images/thrive-logo.svg';
import UsersIcon from '../../assets/images/users.svg';
import Input from '../../components/Question/Input';
import ProgressBar from '../../components/Question/ProgressBar';

type Props = {
  formAssessmentItems: Map<string, FormAssessmentItem>;
  formAssessment?: FormAssessment | PhysicalAssessment;
  formAssessmentSubmission: FormAssessmentSubmission | undefined;
  onFinishedAssessment: () => void;
  navigation: Navigator;
  closeModal: () => void;
};

const FormAssessmentSurvey: React.FC<Props> = ({
  formAssessmentItems,
  formAssessment,
  formAssessmentSubmission,
  onFinishedAssessment,
  navigation,
  closeModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [isAssessmentDone, setAssessmentDone] = useState(false);
  const [scoreMapping, setScoreMapping] = useState<{ [key: number]: string }>({});

  const styles = useStyleSheet(themedStyles);

  const {
    previousScore,
    items,
    eatQuestionCount,
    sweatQuestionCount,
    thriveQuestionCount,
    generalQuestionCount,
  } = formAssessment;

  let formAssessmentItem: FormAssessmentItem | undefined;

  if (items && items[index]) {
    formAssessmentItem = formAssessmentItems.get(`${items[index]}`);
  }

  // const isDeviceMaxWidth768 = useMediaQuery({
  //   maxDeviceWidth: 768,
  // });

  // const isDeviceMaxWidth600 = useMediaQuery({
  //   maxDeviceWidth: 600,
  // });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const numBars = useMemo(() => items?.length || 0, []);

  const onPrev = useCallback(() => {
    setIndex((index) => index - 1);
  }, [index]);

  const onNext = useCallback(async () => {
    if (!formAssessmentItem || !formAssessmentSubmission) {
      return;
    }

    const score = getScoreValue(index);
    if (formAssessmentItem.isRequired && ((!score && score !== '0') || score.length === 0)) {
      alert('This field is required');
      return;
    }

    const result = await formAssessmentItem.answer(formAssessmentSubmission?.id, score);

    if (result.ok) {
      if (index + 1 === numBars) {
        (async () => {
          setLoading(true);
          await formAssessment?.fetch();
          setLoading(false);
        })();
        if (formAssessment) {
          analytics.logAssessmentComplete(formAssessment);
        }
        setAssessmentDone(true);
      } else {
        setIndex((index) => index + 1);
      }
    } else {
      RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC_ELD;
    }
  }, [index, scoreMapping, formAssessmentItem, formAssessmentSubmission]);

  const getScoreValue = useCallback(
    (index: number) => {
      const score = scoreMapping[index];
      if (score && formAssessmentItem && formAssessmentItem.questionType === QUESTION_TYPES.TIME) {
        const time = toNumber(score);
        if (isFinite(time)) {
          return `${time}`; // Assumed seconds.
        }
        return `${moment.duration(score).as('seconds')}`;
      }
      return score;
    },
    [scoreMapping, formAssessmentItem],
  );

  const onScoringUpdate = useCallback(
    (key: string, score: string | number) => {
      setScoreMapping((value) => ({
        ...value,
        [index]: `${score}`,
      }));
    },
    [index],
  );

  const getStatus = useCallback((): string => {
    switch (formAssessmentItem?.category) {
      case 'Eat':
        return 'basic-eat';
      case 'Sweat':
        return 'basic-sweat';
      case 'Thrive':
        return 'basic-thrive';
      default:
        return 'basic-plain';
    }
  }, [formAssessmentItem]);

  const renderHeader = (category: string) => {
    switch (category) {
      case 'Eat':
        return (
          <>
            <Eat width={50} height={50} viewBox="0 0 107 134" />
            <Text style={[styles.category, { color: '#91C300' }]}>{category}</Text>
          </>
        );
      case 'Sweat':
        return (
          <>
            <Sweat width={50} height={50} viewBox="0 0 145 89" />
            <Text style={[styles.category, { color: '#04B7D6' }]}>{category}</Text>
          </>
        );
      case 'Thrive':
        return (
          <>
            <Thrive width={50} height={50} viewBox="0 0 119 134" />
            <Text style={[styles.category, { color: '#FF7A00' }]}>{category}</Text>
          </>
        );
      default:
        return (
          <>
            <Logo />
            <Text style={[styles.category]}>HUMAN PERFORMANCE</Text>
          </>
        );
    }
  };

  const renderFooter = (category: string) => {
    switch (category) {
      case 'Eat':
        return <ProgressBar numBars={eatQuestionCount} currentBar={index} />;
      case 'Sweat':
        return <ProgressBar numBars={sweatQuestionCount} currentBar={index - eatQuestionCount} />;
      case 'Thrive':
        return (
          <ProgressBar
            numBars={thriveQuestionCount}
            currentBar={index - (eatQuestionCount + sweatQuestionCount)}
          />
        );
      default:
        return (
          <ProgressBar
            numBars={generalQuestionCount}
            currentBar={index - (eatQuestionCount + sweatQuestionCount + thriveQuestionCount)}
          />
        );
    }
  };

  const onAssessmentDone = useCallback(() => {
    setAssessmentDone(false);
    setScoreMapping({});
    setIndex(0);
    onFinishedAssessment();
  }, []);

  const onChallenge = useCallback(() => {
    if (formAssessment) {
      navigation.navigate('TeamChallenge', {
        name: formAssessment.name,
        formAssessmentId: formAssessment.id,
      });
      closeModal();
    }
  }, [navigation, formAssessment, closeModal]);

  if (!formAssessmentItem) {
    return null;
  }

  return (
    <>
      {isAssessmentDone ? (
        <Layout style={isMobile ? styles.asessmentDoneModalMobile : styles.asessmentDoneModal}>
          {loading ? (
            <View style={styles.loader}>
              <Spinner />
            </View>
          ) : (
            <>
              <Text style={styles.doneText}>You’ve made it!</Text>
              <Text style={styles.scoreText}>Score:</Text>
              <Text style={styles.doneBadge}>{previousScore}</Text>
              <View style={styles.actions}>
                <TouchableOpacity style={styles.actionItem} onPress={onChallenge}>
                  <UsersIcon style={styles.actionIcon} stroke="#C4C4C4" color="#C4C4C4" />
                  <Text style={styles.actionText}>Challenge</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity
                style={isMobile ? styles.finishButtonMobile : styles.finishButton}
                onPress={onAssessmentDone}>
                <Text style={styles.finish}>Finish</Text>
              </TouchableOpacity>
            </>
          )}
        </Layout>
      ) : (
        <Layout style={isMobile ? styles.surveyModalMobile : styles.surveyModal}>
          <Layout
            style={isMobile ? styles.modalSurveyContainerMobile : styles.modalSurveyContainer}>
            <ScrollView style={styles.scroll}>
              <Layout style={styles.hpaSurveyHeader}>
                {renderHeader(formAssessmentItem.category)}
              </Layout>
              <Layout style={isMobile ? styles.hpaModalContentMobile : styles.hpaModalContent}>
                <Layout style={isMobile ? styles.modalBodyMobile : styles.modalBody}>
                  <TouchableOpacity
                    style={isMobile ? styles.modalButtonMobile : styles.modalButton}
                    onPress={onPrev}>
                    {index > 0 && <Previous />}
                  </TouchableOpacity>
                  <Layout
                    style={isMobile ? styles.hpaSurveyContainerMobile : styles.hpaSurveyContainer}>
                    <Text style={styles.question}>
                      {formAssessmentItem.question}
                      {formAssessmentItem.isRequired ? ' *' : ''}
                    </Text>
                    <Input
                      status={getStatus()}
                      style={styles.input}
                      key={`input-${index}`}
                      question={{
                        question: formAssessmentItem.question,
                        type: formAssessmentItem.questionType,
                        key: `${index}`,
                        options: formAssessmentItem.options.map((o) => [o.value, o.label]),
                      }}
                      onChange={onScoringUpdate}
                      initial={get(scoreMapping, index) || ''}
                      extraData={formAssessmentItem.extraData}
                    />
                  </Layout>
                  <TouchableOpacity
                    style={isMobile ? styles.modalButtonMobile : styles.modalButton}
                    onPress={onNext}>
                    {index < numBars && <Next />}
                  </TouchableOpacity>
                </Layout>
              </Layout>
            </ScrollView>
          </Layout>
          <Layout style={isMobile ? styles.progressBarMobile : styles.progressBar}>
            {renderFooter(formAssessmentItem.category)}
          </Layout>
        </Layout>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  hpaSurveyHeader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    paddingTop: 5,
  },
  surveyModal: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  surveyModalMobile: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  modalSurveyContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalSurveyContainerMobile: {
    width: '100%',
    height: '87%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  scroll: {
    width: '100%',
  },
  category: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
    marginLeft: 23,
    marginTop: 6,
  },
  hpaSurveyContainer: {
    width: 370,
    height: 300,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  hpaSurveyContainerMobile: {
    width: '80%',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  hpaModalContent: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 28,
    marginBottom: 60,
  },
  hpaModalContentMobile: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginTop: 20,
  },
  question: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    marginBottom: 12,
    width: '90%',
  },
  input: {
    width: '100%',
  },
  scoreText: {
    fontSize: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  asessmentDoneModal: {
    width: '100%',
    height: 466,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  asessmentDoneModalMobile: {
    width: '100%',
    height: '94%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalBody: {
    width: '100%',
    height: 300,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  modalBodyMobile: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
  },
  survey: {
    paddingLeft: 35,
    width: 320,
    backgroundColor: 'transparent',
  },
  modalButton: {
    width: 10,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  modalButtonMobile: {
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  surveyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  progressBar: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  progressBarMobile: {
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    marginTop: 10,
  },
  doneText: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    fontWeight: '700',
    marginBottom: 50,
  },
  doneBadge: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#4E7B89',
  },
  actions: {
    marginTop: 30,
    marginVertical: 20,
  },
  actionIcon: {
    alignSelf: 'center',
    marginBottom: 7,
  },
  actionText: {
    textTransform: 'uppercase',
    fontSize: 14,
  },
  finishButton: {
    width: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finishButtonMobile: {
    width: '100%',
    maxWidth: 800,
    height: 48,
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#767F6A',
  },
  finish: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default observer(FormAssessmentSurvey);
