import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import { AssessmentPreviewItem, ASSESSMENT_TYPE } from './Assessment';
import PhysicalAssessmentSubmission, {
  PhysicalAssessmentSubmissionResult,
} from './PhysicalAssessmentSubmission';

type PhysicalAssessmentHistory = {
  id: number;
  created: string;
  score: number | null;
};

export type PhysicalAssessmentSummary = {
  month: number;
  averageScore: number;
};

@model('o2x-store/PhysicalAssessment')
export default class PhysicalAssessment extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  label: prop<string>(''),
  assessmentType: prop<string>(''),
  description: prop<string>(''),
  preview: prop<string>(''),
  coverImage: prop<string | null>(null),
  coverImageThumbnail: prop<string | null>(null),
  items: prop<number[]>(() => []),
  maxScore: prop<number>(0),
  currentSubmission: prop<number | null>(null),
  previousSubmission: prop<number | null>(null),
  history: prop<PhysicalAssessmentHistory[]>(() => []),
  summary: prop<PhysicalAssessmentSummary[]>(() => []),
  bestScore: prop<number | null>(null),
  bestScoreBadge: prop<string | null>(null),
  averageScore: prop<number | null>(null),
  generatedPreview: prop<Array<AssessmentPreviewItem>>(() => []),
  scoringType: prop<null>(null),
  showPreassessmentForm: prop<boolean>(false),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  type = ASSESSMENT_TYPE.PHYSICAL;

  get previousScore(): number | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    const submission = assessment.physicalAssessmentSubmissions.get(
      `${this.previousSubmission}`,
    );
    if (submission) {
      return submission.score;
    }
    return null;
  }

  get previousScoreBadge(): string | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    const submission = assessment.physicalAssessmentSubmissions.get(
      `${this.previousSubmission}`,
    );
    if (submission) {
      return submission.badge;
    }
    return null;
  }

  get currentDate(): string | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    const submission = assessment.physicalAssessmentSubmissions.get(
      `${this.currentSubmission}`,
    );
    if (submission) {
      return submission.created;
    }
    return null;
  }

  get previousResult(): PhysicalAssessmentSubmissionResult[] | null {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;
    const submission = assessment.physicalAssessmentSubmissions.get(
      `${this.previousSubmission}`,
    );
    if (submission) {
      return submission.result;
    }
    return null;
  }

  @modelAction
  update(source: ModelInstanceCreationData<PhysicalAssessment>) {
    const data = { ...source };
    const currentSubmission = data.currentSubmission as ModelInstanceCreationData<PhysicalAssessmentSubmission> | null;
    delete data.currentSubmission;
    const previousSubmission = data.previousSubmission as ModelInstanceCreationData<PhysicalAssessmentSubmission> | null;
    delete data.previousSubmission;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;

    if (currentSubmission) {
      this.currentSubmission = currentSubmission.id;
      assessment?.createOrUpdatePhysicalAssessmentSubmission(currentSubmission);
    }
    if (previousSubmission) {
      this.previousSubmission = previousSubmission.id;
      assessment?.createOrUpdatePhysicalAssessmentSubmission(
        previousSubmission,
      );
    }
  }

  @modelFlow
  fetch = _async(function* (
    this: PhysicalAssessment,
    clearCache: boolean = false,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<PhysicalAssessment>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchPhysicalAssessment(rootStore.auth.token, this.id, clearCache),
      ));
    } catch (error) {
      console.warn('[DEBUG] error answering a physical assessment item', error);
      return getError(error);
    }

    this.update(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  setCurrent = _async(function* (
    this: PhysicalAssessment,
    submissionId: number,
  ) {
    const rootStore = getRoot<RootStore>(this);

    this.loading = true;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }
    this.currentSubmission = submissionId;

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  start = _async(function* (this: PhysicalAssessment) {
    const rootStore = getRoot<RootStore>(this);
    const { assessment } = rootStore;

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<PhysicalAssessmentSubmission>;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.startPhysicalAssessment(rootStore.auth.token, this.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error answering a physical assessment item', error);
      return getError(error);
    }

    assessment.createOrUpdatePhysicalAssessmentSubmission(entities);
    this.currentSubmission = entities.id;

    analytics.logAssessmentStart(this);

    this.loading = false;
    return getSuccess();
  });
}
