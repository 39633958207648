import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import LabeledInput from '../../components/LabeledInput';
import { getErrors } from '../../utils/errors';

type Props = {
  onSuccess: () => void;
};

const EventJoinForm: React.FC<Props> = ({ onSuccess }) => {
  const store = useStore();
  const today = new Date();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeArea();
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register({ name: 'inviteCode' }, { required: true });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const result = await store.event.joinEvent(data.inviteCode);
      setLoading(false);
      if (result.ok) {
        onSuccess();
        setErrorMessage('');
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [onSuccess, setErrorMessage, setLoading],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  return (
    <Layout style={styles.container}>
      <Layout style={styles.content}>
        <Text style={styles.title}>Join an Event</Text>
        <View style={styles.form}>
          {!!errorMessage && (
            <Text style={styles.error} category="label">
              {errorMessage}
            </Text>
          )}
          <LabeledInput
            style={styles.inputContainer}
            autoCapitalize="none"
            returnKeyType="next"
            onChangeText={(text) => setValue('inviteCode', text)}
            label="Event code"
            divider
          />
        </View>
      </Layout>
      {loading ? (
        <View style={styles.loading}>
          <Spinner />
        </View>
      ) : (
        <TouchableOpacity style={styles.button} onPress={onSubmitWrapped}>
          <Text style={styles.buttonText}>Join event</Text>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {},
  inputContainer: {},
  form: {
    width: '100%',
    alignSelf: 'center',
  },
  scroll: {
    flex: 1,
    marginTop: -20,
  },
  title: {
    fontWeight: '700',
    fontSize: 18,
    marginBottom: 20,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'blue',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    textTransform: 'uppercase',
    width: 240,
  },
  scanner: {
    height: 240,
    marginTop: 8,
    marginBottom: 74,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'black',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
});

export default observer(EventJoinForm);
