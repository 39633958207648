import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import Resource from '../models/Resource';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/ResourceStore')
export default class ResourceStore extends Model({
  resources: prop_mapObject(() => new Map<string, Resource>()),
}) {
  @modelAction
  createOrUpdateResource(data: ModelInstanceCreationData<Resource>) {
    const id = `${data.name}${data.id}`;
    if (this.resources.has(id)) {
      this.resources.get(id)!.update(data);
    } else {
      const resource = new Resource(data);
      this.resources.set(id, resource);
      resource.update(data);
    }
  }

  @observable
  loading = false;

  @modelFlow
  fetchResources = _async(function* (this: ResourceStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Resource>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchResources(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching resources', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateResource(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchEatResources = _async(function* (this: ResourceStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Resource>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchEatResources(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching resources', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateResource(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatResources = _async(function* (this: ResourceStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Resource>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSweatResources(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching resources', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateResource(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchThriveResources = _async(function* (this: ResourceStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Resource>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchThriveResources(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching resources', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateResource(data));

    this.loading = false;
    return getSuccess();
  });
}
