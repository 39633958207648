import { useFocusEffect } from '@react-navigation/native';
import { Icon, Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UserReaction } from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import { EMOJI_NAME_TO_UNICODE } from 'o2x-store/src/utils/emoji';
import React, { useCallback, useState } from 'react';
import { FlatList, Image, ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import DividerIcon from '../../assets/images/divider.svg';

type Props = {
  teamId: string;
  activityId: string;
  isVisible: boolean;
  onClose: () => void;
  showDivider?: boolean;
};

const UserReactions: React.FC<Props> = (props) => {
  const insets = useSafeAreaInsets();
  const { isVisible = true, teamId, activityId, onClose, showDivider = false } = props;
  const store = useStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  const [reactions, setReactions] = useState<Array<UserReaction>>(new Array());

  const isTablet = useMediaQuery({
    maxDeviceWidth: 900,
  });

  useFocusEffect(
    useCallback(() => {
      let isSubscribed = true;
      (async () => {
        if (isVisible) {
          const result = await store.teams.getReactions(activityId, teamId);
          if (result && isSubscribed) {
            setReactions(result);
          }
        }
      })();
      return () => (isSubscribed = false);
    }, [isVisible, teamId, activityId, setReactions]),
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.reactionItem}>
        <View style={styles.user}>
          <Image
            style={styles.reactionImage as StyleProp<ImageStyle>}
            source={
              item.imageThumbnail
                ? { uri: item.imageThumbnail }
                : require('../../assets/images/user_placeholder.png')
            }
          />
          <Text style={styles.name}>{item.name}</Text>
        </View>
        <Text style={styles.emoji}>{get(EMOJI_NAME_TO_UNICODE, item.reaction)}</Text>
      </View>
    ),
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Layout
      style={[
        isTablet ? styles.containerTablet : styles.container,
        { marginBottom: insets.bottom },
      ]}>
      {showDivider && <DividerIcon style={styles.divider} width={79} height={3} />}
      <View style={isTablet ? styles.contentContainerTablet : styles.contentContainer}>
        <TouchableOpacity onPress={onClose} style={styles.close}>
          <Icon style={styles.closeIcon} fill={theme.white} name="close-outline" />
        </TouchableOpacity>
        <View style={styles.header}>
          <Text style={styles.label}>People who reacted</Text>
          <Text style={styles.count}>{reactions.length}</Text>
        </View>
        <View style={styles.selection}>
          <FlatList
            data={reactions}
            style={styles.reactionList}
            renderItem={renderItem}
            keyExtractor={(item, idx) => `reaction-${idx}`}
          />
        </View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
  },
  containerTablet: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
  },
  contentContainer: {
    top: 50,
    height: '90%',
    width: 375,
    alignSelf: 'center',
    paddingHorizontal: 27,
    paddingTop: 27,
    backgroundColor: 'black',
  },
  contentContainerTablet: {
    top: 20,
    height: '90%',
    width: '90%',
    alignSelf: 'center',
    paddingHorizontal: 15,
    paddingTop: 18,
    backgroundColor: 'black',
    shadowColor: '#000000',
    shadowOpacity: 0.5,
    shadowRadius: 150,
  },
  divider: {
    marginBottom: 5,
    alignSelf: 'center',
  },
  header: {
    flexDirection: 'row',
    marginBottom: 21,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: 'white',
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  count: {
    color: '#4E7B89',
    marginLeft: 10,
  },
  selection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    backgroundColor: 'wallpaper',
    width: 40,
    height: 40,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emoji: {},
  reactionList: {
    maxHeight: 540,
  },
  reactionItem: {
    backgroundColor: 'dark-blue',
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  user: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  reactionImage: {
    width: 40,
    height: 40,
    borderRadius: 40,
    marginRight: 10,
  },
  name: {
    fontSize: 16,
    color: 'white',
  },
  close: {
    flexDirection: 'row-reverse',
    marginTop: -10,
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
});

export default observer(UserReactions);
