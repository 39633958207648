import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FORM_ASSESSMENT_SCORING_TYPE } from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FlatList,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

type Props = {
  assessment: FormAssessment;
};

const ReadinessAssessment: React.FC<Props> = ({ assessment }) => {
  const { auth, assessment: assessmentStore } = useStore();
  console.log('READINESS ASSESSMENT assessment', assessment);

  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);
  const [popUp, setPopUp] = useState(-1);

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onPress = useCallback(
    (id) => {
      if (assessment.scoringType === FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_READINESS) {
        navigation.navigate('ReadinessAssessmentResult', {
          submissionId: id,
          assessmentId: assessment.id,
        });
      } else if (assessment.scoringType === FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_BODY_COMP) {
        navigation.navigate('BodyCompAssessmentResult', {
          submissionId: id,
          assessmentId: assessment.id,
        });
      } else if (assessment.scoringType === FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_HP_SURVEY) {
        navigation.navigate('HPSurveyAssessmentResult', {
          submissionId: id,
          assessmentId: assessment.id,
        });
      } else if (assessment.scoringType === FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_MOBILITY_CAPACITY) {
        navigation.navigate('MobilityCapacityAssessmentResult', {
          submissionId: id,
          assessmentId: assessment.id,
        });
      }
    },
    [assessment],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.item}>
        <View style={styles.itemContainer}>
          <Text style={styles.dateText}>{moment(item.created).format('MMM D, YYYY')}</Text>
          <TouchableOpacity
            style={{ flex: 1, justifyContent: 'flex-end' }}
            onPress={() => onPress(item.id)}>
            <Text style={styles.viewText}>View Result</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.divider} />
      </View>
    ),
    [popUp],
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        if (assessment) {
          await assessmentStore.fetchFormAssessmentSubmissions(assessment.id);
        }
        setLoading(false);
      })();
    }, [assessment]),
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const data = useMemo(() => {
    let data = Array<FormAssessmentSubmission>();
    if (assessment) {
      data = Array.from(assessmentStore.formAssessmentSubmissions.values()).filter(
        (v) => v.assessment == assessment.id,
      );
      return data.sort((a, b) => (moment(b.created).isAfter(a.created) ? -1 : 1));
    }
    return data;
  }, [assessment, assessmentStore.formAssessmentSubmissions.values(), loading, popUp]);
  console.log('ASSESSMENT STORE SUBMISSIONS', assessmentStore.formAssessmentSubmissions);
  console.log('READINESS ASSESSMENT SUBMISSIONS', data);

  return (
    <TouchableWithoutFeedback onPress={() => setPopUp(-1)}>
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout style={isMobile ? styles.containerMobile : styles.container}>
              <Layout style={isMobile ? styles.userContainerMobile : styles.userContainer}>
                <Layout style={styles.tableHeader}>
                  <Text style={styles.tableText}>All Reports</Text>
                </Layout>
                <FlatList
                  data={data}
                  renderItem={renderItem}
                  keyExtractor={(item) => `${item.id}`}
                />
              </Layout>
            </Layout>
          </ScrollView>
        </Layout>
      )}
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  categoryContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingVertical: 8,
  },
  categoryContainerMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  tableHeader: {
    width: '95%',
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 10,
    alignSelf: 'center',
    height: 40,
    backgroundColor: '#31576D',
  },
  tableText: {
    fontSize: 18,
    lineHeight: 22,
    paddingRight: 10,
  },
  item: {
    height: 48,
  },
  itemContainer: {
    flexDirection: 'row',
    width: '95%',
    paddingHorizontal: 25,
    marginVertical: 5,
    alignItems: 'center',
  },
  divider: {
    height: 2,
    backgroundColor: 'black',
    width: '95%',
  },
  dateText: {
    fontSize: 18,
    lineHeight: 22,
    color: 'blue-secondary',
    flex: 1,
  },
  dateTextMobile: {
    fontSize: 12,
    lineHeight: 14,
    color: 'blue-secondary',
    flex: 1,
  },
  viewText: {
    fontSize: 18,
    lineHeight: 22,
    flex: 1,
  },
  viewTextMobile: {
    fontSize: 12,
    lineHeight: 14,
    flex: 1,
  },
  musclesBar: {
    backgroundColor: 'blue-secondary',
    height: 10,
  },
  fatBar: {
    backgroundColor: 'red',
    height: 10,
  },
  graphContainer: {
    flex: 3,
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  popUpContainer: {
    backgroundColor: 'black',
    width: 150,
    padding: 8,
    position: 'absolute',
    top: 1,
    height: 40,
  },
  popUpText: {
    fontSize: 10,
    lineHeight: 12,
  },
});

export default observer(ReadinessAssessment);
