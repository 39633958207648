import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import {
  FlatList,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import ArrowRight from '../../assets/images/arrow-right-gray.svg';
import RightOutline from '../../assets/images/right-outline.svg';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentHistory'>;
};

const BodyCompositionAssessment: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const { auth, assessment: assessmentStore, sweat: sweatStore } = useStore();

  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);
  const [popUp, setPopUp] = useState(-1);

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onPress = useCallback((id) => {
    navigation.navigate('BodyCompositionItem', { assessmentId: id });
  }, []);

  const renderItem = useCallback(
    ({ item }) => {
      const percentageSmm = (item.smm * 100) / item.weight;
      const percentageBfm = (item.bfm * 100) / item.weight;
      return (
        <View style={styles.item}>
          <View style={styles.itemContainer}>
            <Text style={isMobile ? styles.dateTextMobile : styles.dateText}>
              {moment(item.testDate).format('MMM D, YYYY')}
            </Text>

            <TouchableOpacity onPress={() => setPopUp(item.id)} style={styles.graphContainer}>
              <View style={[styles.musclesBar, { width: `${percentageSmm}%` }]} />
              <View style={[styles.fatBar, { width: `${percentageBfm}%` }]} />
            </TouchableOpacity>

            {item.id === popUp && (
              <Layout style={{ position: 'absolute', bottom: 35, left: '30%' }}>
                <Layout style={styles.popUpContainer}>
                  <Text style={styles.popUpText}>
                    Skeletal Muscle Mass: {Math.round(percentageSmm)}%
                  </Text>
                  <Text style={styles.popUpText}>Body Fat Mass: {Math.round(percentageBfm)}%</Text>
                </Layout>
              </Layout>
            )}
            <TouchableOpacity
              style={{ flex: 1, justifyContent: 'flex-end' }}
              onPress={() => onPress(item.id)}>
              <ArrowRight />
            </TouchableOpacity>
          </View>
          <View style={styles.divider} />
        </View>
      );
    },
    [popUp],
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        await assessmentStore.fetchBodyComposition();
        setLoading(false);
      })();
    }, []),
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const data = useMemo(() => {
    const data = Array.from(assessmentStore.bodyComposition.values());
    return data.sort((a, b) => (moment(b.testDate).isAfter(a.testDate) ? -1 : 1));
  }, [loading, popUp]);

  return (
    <TouchableWithoutFeedback onPress={() => setPopUp(-1)}>
      {loading ? (
        <View style={styles.loader}>
          <Spinner />
        </View>
      ) : (
        <Layout style={styles.body}>
          <ScrollView>
            <Layout style={isMobile ? styles.containerMobile : styles.container}>
              <Layout style={styles.header}>
                <Layout style={styles.row}>
                  <TouchableOpacity onPress={goBack}>
                    <Text
                      style={
                        isDeviceMaxWidth900
                          ? styles.assessmentTexMaxWidth900
                          : styles.assessmentText
                      }>
                      Assessments
                    </Text>
                  </TouchableOpacity>
                  {isDeviceMaxWidth900 ? (
                    <RightOutline
                      style={styles.rightOutline}
                      width="6"
                      height="10"
                      viewBox="0 0 8 14"
                    />
                  ) : (
                    <RightOutline />
                  )}
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameHeaderMaxWidth900
                        : styles.assessmentNameHeader
                    }>
                    Body Composition Assessment
                  </Text>
                </Layout>
              </Layout>
              <Layout style={isMobile ? styles.userContainerMobile : styles.userContainer}>
                <Layout style={styles.user}>
                  <Image
                    style={styles.profile as StyleProp<ImageStyle>}
                    source={
                      currentUser?.profileImage
                        ? { uri: currentUser?.profileImage }
                        : require('../../assets/images/user_placeholder.png')
                    }
                  />
                  <Layout style={isMobile ? styles.userDataMobile : styles.userData}>
                    <Text
                      style={
                        isMobile
                          ? styles.fullNameMobile
                          : isDeviceMaxWidth900
                          ? styles.fullNameMaxWidth900
                          : styles.fullName
                      }>
                      {currentUser?.fullName}
                    </Text>
                    <Text
                      style={
                        isDeviceMaxWidth900 ? styles.professionMaxWidth900 : styles.profession
                      }>
                      {currentUser?.profession}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.assessmentContainer}>
                  <Text
                    style={
                      isDeviceMaxWidth900 ? styles.assessmentNameMaxWidth900 : styles.assessmentName
                    }>
                    Body Composition Assessment
                  </Text>
                </Layout>
                <Layout style={styles.tableHeader}>
                  <Text style={styles.tableText}>All Reports</Text>
                </Layout>

                <FlatList
                  data={data}
                  renderItem={renderItem}
                  keyExtractor={(item) => `${item.id}`}
                />
              </Layout>
            </Layout>
          </ScrollView>
        </Layout>
      )}
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  categoryContainer: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingVertical: 8,
  },
  categoryContainerMaxWidth600: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  tableHeader: {
    width: '95%',
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingVertical: 10,
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 10,
    alignSelf: 'center',
    height: 40,
    backgroundColor: '#31576D',
  },
  tableText: {
    fontSize: 18,
    lineHeight: 22,
    paddingRight: 10,
  },
  item: {
    height: 48,
  },
  itemContainer: {
    flexDirection: 'row',
    width: '95%',
    paddingHorizontal: 25,
    marginVertical: 5,
    alignItems: 'center',
  },
  divider: {
    height: 2,
    backgroundColor: 'black',
    width: '95%',
  },
  dateText: {
    fontSize: 18,
    lineHeight: 22,
    color: 'blue-secondary',
    flex: 1,
  },
  dateTextMobile: {
    fontSize: 12,
    lineHeight: 14,
    color: 'blue-secondary',
    flex: 1,
  },
  musclesBar: {
    backgroundColor: 'blue-secondary',
    height: 10,
  },
  fatBar: {
    backgroundColor: 'red',
    height: 10,
  },
  graphContainer: {
    flex: 3,
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  popUpContainer: {
    backgroundColor: 'black',
    width: 150,
    padding: 8,
    position: 'absolute',
    top: 1,
    height: 40,
  },
  popUpText: {
    fontSize: 10,
    lineHeight: 12,
  },
});

export default observer(BodyCompositionAssessment);
