import { useNavigation } from '@react-navigation/core';
import { Icon, Layout, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Props, themedStyles } from '.';
import Cover from '../../assets/images/daily-debrief-item.svg';

const DailyDebriefItem: React.FC<Props> = (props) => {
  const { task, showEllipses = false } = props;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const store = useStore();

  useEffect(() => {
    store.user.fetchDebriefProfile();
    store.question.fetchDailyDebriefQuestions();
  }, []);

  const onPress = useCallback(() => {
    navigation.navigate('DailyDebriefEat');
  }, []);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
        level="2"
      >
        <Layout style={styles.contentContainer} level="2">
          <Layout style={[styles.imageWrapper, { alignItems: 'center' }]}>
            <Cover style={styles.image} />
          </Layout>
          <Layout
            style={[
              styles.textContainer,
              Platform.OS !== 'web'
                ? styles.textContainerFixed
                : styles.textContainerWeb,
            ]}
          >
            <Text
              style={Platform.OS !== 'web' ? styles.title : styles.titleWeb}
            >
              {task.title}
            </Text>
            {!!task.subtitle && (
              <Text
                style={[
                  Platform.OS !== 'web' ? styles.subtitle : styles.subtitleWeb,
                  { color: task.color },
                ]}
              >
                {task.subtitle}
              </Text>
            )}
          </Layout>
          {showEllipses && (
            <Icon style={styles.icon} fill="#FFFFFF" name="more-horizontal" />
          )}
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export default observer(DailyDebriefItem);
