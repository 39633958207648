import { useFocusEffect } from '@react-navigation/core';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import {
  FTEGroupBreathe,
  FTEOrganizationBreathe,
  FTEUserBreathe,
} from 'o2x-store/src/models/FTELibrary';
import Recipe from 'o2x-store/src/models/Recipe';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import ThriveExercise from 'o2x-store/src/models/ThriveExercise';
import ThriveMediaFile from 'o2x-store/src/models/ThriveMediaFile';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Dimensions,
  FlatList,
  ImageBackground,
  ImageStyle,
  ScrollView,
  StyleProp,
  View,
} from 'react-native';
import { Icon as MaterialIcon } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import BreatheItem from 'src/components/FTE/BreatheItem';
import FTERecipeItem from 'src/components/FTE/FTERecipeItem';
import FTESweatProgramItem from 'src/components/FTE/FTESweatProgramItem';
import FTESweatWorkoutItem from 'src/components/FTE/FTESweatWorkout';
import FTEThriveExerciseItem from 'src/components/FTE/FTEThriveExerciseItem';
import FTEThriveMeditateItem from 'src/components/FTE/FTEThriveMeditateItem';
import FTEThriveProgramItem from 'src/components/FTE/FTEThriveProgramItem';
import FTEThriveSleepItem from 'src/components/FTE/FTEThriveSleepItem';
import TopNav from 'src/components/Question/TopNav';
import TaskIcon from 'src/components/TaskIcon';
import BreatheIcon from '../../assets/images/breathe.svg';
import MeditateIcon from '../../assets/images/meditate.svg';
import SleepIcon from '../../assets/images/sleep.svg';
import YogaIcon from '../../assets/images/yoga-icon.svg';

const categoriesMap = {
  eat: ['Recipes'],
  sweat: ['Sweat Programs', 'Sweat Workouts'],
  thrive: ['Thrive Programs', 'Breathing Exercises', 'Meditations', 'Sleep Sounds', 'Yoga'],
};

type Props = {
  route: any;
};

const { width } = Dimensions.get('window');

const AssignedTask: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const store = useStore();

  const { selectedFte, selectedTask = 'eat', currentUser } = props.route.params;

  const [showCategories, setShowCategories] = useState(selectedTask !== 'eat');

  const [task, setTask] = useState(selectedTask);
  const [categories, setCategories] = useState(get(categoriesMap, task, []));
  const [category, setCategory] = useState(categories[0]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [eatData, setEatData] = useState<Recipe[]>([]);
  const [sweatProgramData, setSweatProgramData] = useState<SweatProgram[]>([]);
  const [sweatWorkoutData, setSweatWorkoutData] = useState<SweatWorkout[]>([]);
  const [thriveProgramData, setThriveProgramData] = useState<ThriveProgram[]>([]);
  const [thriveExerciseData, setThriveExerciseData] = useState<ThriveExercise[]>([]);
  const [breatheData, setBreatheData] = useState<
    | FTEUserBreathe[]
    | FTEOrganizationBreathe[]
    | FTEGroupBreathe[]
    | (FTEUserBreathe | FTEGroupBreathe)[]
  >([]);
  const [thriveSleepData, setThriveSleepData] = useState<ThriveMediaFile[]>([]);
  const [thriveMeditateData, setThriveMeditateData] = useState<ThriveMediaFile[]>([]);

  const color = getTaskColor(task);

  const thumbnail: { [key: string]: { image: string; icon: any } } = {
    'Sweat Programs': {
      image: require('../../assets/images/fte-sweat-program-thumbnail.png'),
      icon: 'event-note',
    },
    'Sweat Workouts': {
      image: require('../../assets/images/fte-sweat-workout-thumbnail.png'),
      icon: 'fitness-center',
    },
    'Thrive Programs': {
      image: require('../../assets/images/fte-thrive-program-thumbnail.png'),
      icon: 'event-note',
    },
    'Breathing Exercises': {
      image: require('../../assets/images/fte-breathe-thumbnail.png'),
      icon: <BreatheIcon height={70} width={70} style={styles.icon} />,
    },
    Meditations: {
      image: require('../../assets/images/fte-meditate-thumbnail.png'),
      icon: <MeditateIcon height={70} width={70} style={styles.icon} />,
    },
    'Sleep Sounds': {
      image: require('../../assets/images/fte-sleep-thumbnail.png'),
      icon: <SleepIcon height={70} width={70} style={styles.icon} />,
    },
    Yoga: {
      image: require('../../assets/images/fte-yoga-thumbnail.png'),
      icon: <YogaIcon height={70} width={70} style={styles.icon} />,
    },
  };

  const source = useMemo(() => {
    if (category === 'Recipes') {
      return { data: eatData, Item: FTERecipeItem };
    }
    if (category === 'Sweat Programs') {
      return { data: sweatProgramData, Item: FTESweatProgramItem };
    }
    if (category === 'Sweat Workouts') {
      return { data: sweatWorkoutData, Item: FTESweatWorkoutItem };
    }
    if (category === 'Thrive Programs') {
      return { data: thriveProgramData, Item: FTEThriveProgramItem };
    }
    if (category === 'Breathing Exercises') {
      return { data: breatheData, Item: BreatheItem };
    }
    if (category === 'Yoga') {
      return { data: thriveExerciseData, Item: FTEThriveExerciseItem };
    }

    if (category === 'Sleep Sounds') {
      return { data: thriveSleepData, Item: FTEThriveSleepItem };
    }
    return { data: thriveMeditateData, Item: FTEThriveMeditateItem };
  }, [
    eatData,
    sweatProgramData,
    sweatWorkoutData,
    thriveProgramData,
    thriveExerciseData,
    thriveSleepData,
    thriveMeditateData,
    category,
  ]);

  const { data, Item } = source;

  const dataLength = {
    Recipes: eatData.length,
    'Sweat Programs': sweatProgramData.length,
    'Sweat Workouts': sweatWorkoutData.length,
    'Thrive Programs': thriveProgramData.length,
    'Breathing Exercises': breatheData.length,
    Meditations: thriveMeditateData.length,
    'Sleep Sounds': thriveSleepData.length,
    Yoga: thriveExerciseData.length,
  };
  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'eat') {
          setLoading(true);
          const url = `${config.urls.fteLibrary}fte-user-eat/get_content_list/?user=${store.auth
            .user?.id!}&fte=${selectedFte.id}`;
          const fetchEatData = currentUser
            ? await store.fteLibrary.fetchTargetUserRecipeList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgRecipeList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchEatData.ok && fetchEatData.extra?.results.length) {
            setEatData(fetchEatData.extra?.results[0].recipeList);
          } else if (!!currentUser && fetchEatData.ok && fetchEatData.extra?.length) {
            setEatData(fetchEatData.extra as Recipe[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'sweat') {
          setLoading(true);
          const url = `${config.urls.fteLibrary}fte-user-sweatprogram/get_content_list/?user=${store
            .auth.user?.id!}&fte=${selectedFte.id}`;
          const fetchSweatPrograms = currentUser
            ? await store.fteLibrary.fetchTargetUserSweatProgramList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgSweatProgramList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchSweatPrograms.ok && fetchSweatPrograms.extra?.results.length) {
            setSweatProgramData(fetchSweatPrograms.extra?.results[0].programList);
          } else if (!!currentUser && fetchSweatPrograms.ok && fetchSweatPrograms.extra?.length) {
            setSweatProgramData(fetchSweatPrograms.extra as SweatProgram[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'sweat') {
          const url = `${config.urls.fteLibrary}fte-user-sweatworkout/get_content_list/?user=${store
            .auth.user?.id!}&fte=${selectedFte.id}`;
          setLoading(true);
          const fetchSweatWorkouts = currentUser
            ? await store.fteLibrary.fetchTargetUserSweatWorkoutList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgSweatWorkoutList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchSweatWorkouts.ok && fetchSweatWorkouts.extra?.results.length) {
            setSweatWorkoutData(fetchSweatWorkouts.extra?.results[0].workoutList);
          } else if (!!currentUser && fetchSweatWorkouts.ok && fetchSweatWorkouts.extra?.length) {
            setSweatWorkoutData(fetchSweatWorkouts.extra as SweatWorkout[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'thrive') {
          const url = `${
            config.urls.fteLibrary
          }fte-user-thriveprogram/get_content_list/?user=${store.auth.user?.id!}&fte=${
            selectedFte.id
          }`;
          setLoading(true);
          const fetchThrivePrograms = currentUser
            ? await store.fteLibrary.fetchTargetUserThriveProgramList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgThriveProgramList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchThrivePrograms.ok && fetchThrivePrograms.extra?.results.length) {
            setThriveProgramData(fetchThrivePrograms.extra?.results[0].thriveProgramList);
          } else if (!!currentUser && fetchThrivePrograms.ok && fetchThrivePrograms.extra?.length) {
            setThriveProgramData(fetchThrivePrograms.extra as ThriveProgram[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'thrive') {
          const url = `${
            config.urls.fteLibrary
          }fte-user-thriveexercise/get_content_list/?user=${store.auth.user?.id!}&fte=${
            selectedFte.id
          }`;
          setLoading(true);
          const fetchThriveExercises = currentUser
            ? await store.fteLibrary.fetchTargetUserThriveExerciseList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgThriveExerciseList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (
            !currentUser &&
            fetchThriveExercises.ok &&
            fetchThriveExercises.extra?.results.length
          ) {
            setThriveExerciseData(fetchThriveExercises.extra?.results[0].thriveExerciseList);
          } else if (
            !!currentUser &&
            fetchThriveExercises.ok &&
            fetchThriveExercises.extra?.length
          ) {
            setThriveExerciseData(fetchThriveExercises.extra as ThriveExercise[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'thrive') {
          const url = `${config.urls.fteLibrary}fte-user-thrivesleep/get_content_list/?user=${store
            .auth.user?.id!}&fte=${selectedFte.id}`;
          setLoading(true);
          const fetchThriveSleep = currentUser
            ? await store.fteLibrary.fetchTargetUserThriveSleepList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgThriveSleepList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchThriveSleep.ok && fetchThriveSleep.extra?.results.length) {
            setThriveSleepData(fetchThriveSleep.extra?.results[0].thriveAudioList);
          } else if (!!currentUser && fetchThriveSleep.ok && fetchThriveSleep.extra?.length) {
            setThriveSleepData(fetchThriveSleep.extra as ThriveMediaFile[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'thrive') {
          const url = `${
            config.urls.fteLibrary
          }fte-user-thrivemeditate/get_content_list/?user=${store.auth.user?.id!}&fte=${
            selectedFte.id
          }`;
          setLoading(true);
          const fetchThriveMeditate = currentUser
            ? await store.fteLibrary.fetchTargetUserThriveMeditateList(
                store.auth.user?.id!,
                selectedFte.id,
                url,
              )
            : await store.fteLibrary.fetchTargetOrgThriveMeditateList(
                store.auth.user?.organization!,
                selectedFte.id,
              );
          if (!currentUser && fetchThriveMeditate.ok && fetchThriveMeditate.extra?.results.length) {
            setThriveMeditateData(fetchThriveMeditate.extra?.results[0].meditateAudioList);
          } else if (!!currentUser && fetchThriveMeditate.ok && fetchThriveMeditate.extra?.length) {
            setThriveMeditateData(fetchThriveMeditate.extra as ThriveMediaFile[]);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (task === 'thrive') {
          setLoading(true);
          let userContents = [] as FTEUserBreathe[];
          let groupContents = [] as FTEGroupBreathe[];
          const fetchBreathe = currentUser
            ? await store.fteLibrary.fetchTargetUserBreathe(selectedFte.id, store.auth.user?.id!)
            : await store.fteLibrary.fetchTargetOrganizationBreathe(
                selectedFte.id,
                store.auth.user?.organization!,
              );
          if (currentUser) {
            const groupBreatheContents = await store.fteLibrary.fetchGroupBreatheContents(
              selectedFte.id,
              store.auth.user?.id!,
            );
            if (fetchBreathe.ok && fetchBreathe.extra?.results.length)
              userContents = fetchBreathe.extra?.results;
            groupContents = groupBreatheContents.extra as FTEGroupBreathe[];
            setBreatheData([...userContents, ...groupContents]);
          }
          if (!currentUser && fetchBreathe.ok && fetchBreathe.extra?.results.length) {
            setBreatheData(fetchBreathe.extra?.results);
          }
          setLoading(false);
        }
      })();
    }, [task]),
  );

  const renderItem = useCallback(({ item }) => <Item item={item} />, [Item]);

  const renderTask = useCallback(
    (type) => (
      <TouchableOpacity
        style={[
          styles.task,
          {
            borderColor: task === type ? color : 'transparent',
            width: currentUser ? width / 3 : (width - 60) / 3,
          },
        ]}
        onPress={() => {
          const taskCategories = get(categoriesMap, type, []);
          if (type === 'sweat' || type === 'thrive') setShowCategories(true);
          else setShowCategories(false);
          setTask(type);
          setCategories(taskCategories);
          setCategory(taskCategories[0]);
        }}>
        <TaskIcon type={type} width={35} height={35} />
      </TouchableOpacity>
    ),
    [task, color, currentUser],
  );

  const keyExtractor = useCallback((item) => category + item.id, [category]);

  return (
    <Layout style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
      {currentUser && (
        <View style={styles.forYouHeader}>
          <Text style={styles.forYou} category="h1">
            For You
          </Text>
        </View>
      )}
      <View style={styles.header}>
        {!currentUser && <View style={styles.padding} />}
        {renderTask('eat')}
        {renderTask('sweat')}
        {renderTask('thrive')}
      </View>
      <View style={styles.navigationContainer}>
        <TopNav
          showClose={false}
          style={styles.topNav}
          onBack={task !== 'eat' && !showCategories ? () => setShowCategories(true) : undefined}
        />
      </View>
      {loading ? (
        <Layout style={styles.loading}>
          <Spinner />
        </Layout>
      ) : showCategories ? (
        <ScrollView style={styles.categoryContainer}>
          {categories.map((i: string) => (
            <TouchableOpacity
              onPress={() => {
                setCategory(i);
                setShowCategories(false);
              }}
              style={styles.category}
              key={i}>
              <Layout
                style={[
                  styles.container,
                  {
                    borderLeftWidth: 4,
                    borderColor: color,
                    borderStyle: 'solid',
                  },
                ]}
                level="2">
                <Layout style={styles.imageWrapper}>
                  <ImageBackground
                    style={styles.image as StyleProp<ImageStyle>}
                    source={thumbnail[i].image}>
                    {i === 'Sweat Programs' || i === 'Sweat Workouts' || i === 'Thrive Programs' ? (
                      <MaterialIcon
                        name={thumbnail[i].icon}
                        size={70}
                        type="material"
                        color="#C4C4C4"
                        style={styles.icon}
                      />
                    ) : (
                      thumbnail[i].icon
                    )}
                  </ImageBackground>
                </Layout>
                {/* <Layout style={styles.content} level="2"> */}
                <View style={styles.content}>
                  <Text>{i}</Text>
                  <Text style={{ color }}>
                    {`${dataLength[i]} ${
                      i.includes('Programs')
                        ? Humanize.pluralize(dataLength[i], 'PROGRAM').toUpperCase()
                        : i.includes('Sounds') || i.includes('Meditations')
                        ? Humanize.pluralize(dataLength[i], 'AUDIO FILE').toUpperCase()
                        : i.includes('Workouts')
                        ? Humanize.pluralize(dataLength[i], 'WORKOUT').toUpperCase()
                        : Humanize.pluralize(dataLength[i], 'EXERCISE').toUpperCase()
                    }`}
                  </Text>
                </View>
                {/* </Layout> */}
              </Layout>
            </TouchableOpacity>
          ))}
        </ScrollView>
      ) : (
        <FlatList
          data={data as any}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          style={styles.container}
        />
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  header: {
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'cover',
  },
  imageWrapper: {
    height: 90,
  },
  title: {
    marginLeft: 6,
    textTransform: 'uppercase',
  },
  content: {
    // flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center',
    flex: 1,
    marginLeft: 12,
    // marginRight: 16,
    marginVertical: 8,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 17,
    marginTop: 7,
    textTransform: 'uppercase',
  },
  item: {
    backgroundColor: 'red',
    height: 80,
  },
  listHeader: {
    height: 16,
  },
  task: {
    flex: 1,
    borderBottomWidth: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  padding: {
    width: 60,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 22,
    marginTop: 8,
    height: 40,
  },
  label: {
    opacity: 0.6,
    fontSize: 16,
    marginRight: 8,
  },
  category: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 22,
    marginVertical: 10,
    height: 150,
  },
  categoryLabel: {
    fontSize: 16,
    opacity: 0.8,
  },
  categoryContainer: {
    marginTop: 4,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTopWidth: 1,
    borderColor: 'rgba(255,255,255,0.1)',
  },
  forYouHeader: {
    height: 54,
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
  },
  forYou: {
    fontSize: 18,
    fontWeight: '700',
  },
  icon: {
    textAlignVertical: 'center',
    alignSelf: 'center',
    marginTop: 10,
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
});

export default observer(AssignedTask);
