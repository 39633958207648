import { Input, Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  programName: string;
  description: string;
  weekLength: number;
  dayWeek: string;
  setProgramName: Function;
  setDescription: Function;
  setWeekLength: Function;
  setDayWeek: Function;
  setChangeWeek: Function;
};

const ProgramDetails: React.FC<Props> = (props) => {
  const {
    programName,
    description,
    weekLength,
    dayWeek,
    setProgramName,
    setDescription,
    setWeekLength,
    setDayWeek,
    setChangeWeek,
  } = props;
  const styles = useStyleSheet(themedStyles);

  // const onChangeWeek = (text: string) => {
  //   let arr = [];
  //   isNaN(parseInt(text)) ? setWeekLength(0) : setWeekLength(parseInt(text));
  //   for (var i = 0; i < parseInt(text); i++) {
  //     for (var j = 0; j < 7; j++) {
  //       const workoutObj: Partial<FTESweatWorkout> = {
  //         week: i + 1,
  //         day: j + 1,
  //         program: null,
  //       };
  //       arr.push(workoutObj);
  //     }
  //   }
  //   setWorkoutList([...arr]);
  // };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 650,
  });

  return (
    <Layout style={styles.programDetailsContainer}>
      <Layout style={isMobile ? styles.programDetailsMobile : styles.programDetails}>
        <Input
          size="medium"
          value={programName || ''}
          onChangeText={(text) => {
            setProgramName(text);
          }}
          style={isMobile ? styles.nameInputMobile : styles.nameInput}
          label="Name of Program"
        />
        <Input
          size="medium"
          value={weekLength > 0 ? weekLength?.toString() || '' : ' '}
          onChangeText={(text) => {
            setWeekLength(parseInt(text));
            setChangeWeek(true);
          }}
          style={isMobile ? styles.nameInputMobile : styles.nameInput}
          label="Length in Weeks"
        />
      </Layout>
      <Layout style={isMobile ? styles.programDetailsMobile : styles.programDetails}>
        <Input
          size="large"
          multiline
          numberOfLines={3}
          value={description || ''}
          onChangeText={(text) => {
            setDescription(text);
          }}
          style={isMobile ? styles.nameInputMobile : styles.nameInput}
          label="Program Description"
        />
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  programDetailsContainer: {
    flexDirection: 'column',
    width: '100%',
    gap: 5,
    // justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  programDetails: {
    flexDirection: 'row',
    width: '100%',
    marginLeft: 5,
    // justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  programDetailsMobile: {
    width: '100%',
    marginLeft: 5,
    marginTop: 5,
    // justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  nameInput: {
    width: '30%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 20,
  },
  nameInputMobile: {
    width: '60%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 20,
  },
});

export default React.memo(ProgramDetails, (prevProps, nextProps) => {
  if (
    prevProps.programName.length !== nextProps.programName.length ||
    prevProps.description.length !== nextProps.description.length ||
    prevProps.weekLength.toString().length !== nextProps.weekLength.toString().length ||
    prevProps.dayWeek.length !== nextProps.dayWeek.length
  ) {
    return false;
  }
  let equal = true;
  if (
    prevProps.programName !== nextProps.programName ||
    prevProps.description !== nextProps.description ||
    prevProps.weekLength !== nextProps.weekLength ||
    prevProps.dayWeek !== nextProps.dayWeek
  )
    equal = false;
  return equal;
});
