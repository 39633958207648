import { useNavigation } from '@react-navigation/native';
import { Icon, Layout, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { Image, ImageStyle, StyleProp, TouchableOpacity } from 'react-native';
import { Props, themedStyles } from '.';

const UserProfileItem: React.FC<Props> = (props) => {
  const { task, showIcon = false, showEllipses = false } = props;
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const onPress = useCallback(() => {
    navigation.navigate('Profile');
  }, []);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          styles.borderHighlightProfile,
          {
            borderLeftWidth: 4,
            borderStyle: 'solid',
          },
        ]}
        level="2"
      >
        <Layout style={styles.contentContainer} level="2">
          <Layout style={styles.imageWrapper}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/complete_profile_thumbnail.png')}
            />
          </Layout>
          <Layout style={[styles.textContainerWeb, { height: 55 }]}>
            <Text style={styles.titleWeb}>{task.title}</Text>
            {!!task.subtitle && (
              <Text style={[styles.subtitleWeb, styles.textColorProfile]}>
                {task.subtitle}
              </Text>
            )}
          </Layout>
          {showEllipses && (
            <Icon style={styles.icon} fill="#FFFFFF" name="more-horizontal" />
          )}
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export default UserProfileItem;
