import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import JoinOrganization from 'src/containers/Organization/JoinOrganization';
import Header from '../../Header';

type Props = {};

const Stack = createStackNavigator();

const SettingsStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
  >
    <Stack.Screen name="JoinOrganization" component={JoinOrganization} />
  </Stack.Navigator>
);

export default SettingsStack;
