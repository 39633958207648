import { isFunction } from 'lodash';
import { computed } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';

export type Filters = { [key: string]: string | undefined };

@model('o2x-native/WorkoutFilter')
export default class WorkoutFilter extends Model({
  filters: prop<Filters>(() => ({})),
}) {
  @modelAction
  setFilters(filters: ((filters: Filters) => Filters) | Filters) {
    if (isFunction(filters)) {
      this.filters = filters(this.filters);
    } else {
      this.filters = filters;
    }
  }

  @computed
  get isFiltering(): boolean {
    return !!Object.values(this.filters).find((filter) => filter);
  }

  @modelAction
  reset() {
    this.filters = {};
  }
}
