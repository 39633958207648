import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React from 'react';
import { Image, ImageStyle, StyleProp } from 'react-native';

type Props = {};

const GetStarted: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  return (
    <>
      <Image
        style={styles.image as StyleProp<ImageStyle>}
        source={require('../../assets/images/start-onboarding.png')}
      />
      <Text style={styles.header} category="h1">
        What is your 1%?
      </Text>
    </>
  );
};

const themedStyles = StyleService.create({
  header: {
    paddingTop: 30,
  },
  image: {
    height: 210,
    width: 267,
    resizeMode: 'cover',
    marginBottom: 70,
  },
});

export default GetStarted;
