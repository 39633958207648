import Humanize from 'humanize-plus';
import { join } from 'lodash';
import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { FTESweatWorkoutStepExerciseSet } from './FTELibrary';

@model('o2x-store/SweatWorkoutStepExercise')
export default class SweatWorkoutStepExercise extends Model({
  id: prop<number>(),
  order: prop<number>(),
  round: prop<number>(),
  sets: prop<string>(''),
  reps: prop<string>(''),
  isRepsEachSide: prop<boolean>(false),
  distance: prop<string>(''),
  time: prop<string>(''),
  rest: prop<string>(''),
  workoutTimer: prop<string>(''),
  workoutTimerWork: prop<number | null>(null),
  workoutTimerRest: prop<number | null>(null),
  instructions: prop<string>(''),
  notes: prop<string>(''),
  difficulty: prop<string>(''),
  exercise: prop<number>(),
  exerciseName: prop<string>(''),
  bwOrEquipment: prop<string>(''),
  exerciseSets: prop<FTESweatWorkoutStepExerciseSet[]>(),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<SweatWorkoutStepExercise>) {
    Object.assign(this, data);
  }

  @computed
  get subtitle(): string {
    const part1 = [];
    if (this.sets) {
      part1.push(
        `${this.sets} ${Humanize.pluralize(parseInt(this.sets), 'set')}`,
      );
    }
    if (this.reps) {
      let repText = '';
      repText = `${this.reps} ${Humanize.pluralize(
        parseInt(this.reps),
        'rep',
      )}`;

      if (this.isRepsEachSide) {
        repText += ' each side';
      }
      part1.push(repText);
    }
    if (this.time && this.time !== '0') {
      part1.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      part1.push(this.distance);
    }

    return join(part1, ' | ');
  }

  @computed
  get repTimeDistanceSubtitle(): string {
    const part1 = [];
    if (this.reps) {
      let repText = '';
      repText = `${this.reps} ${Humanize.pluralize(
        parseInt(this.reps),
        'rep',
      )}`;

      if (this.isRepsEachSide) {
        repText += ' each side';
      }
      part1.push(repText);
    }
    if (this.time && this.time !== '0') {
      part1.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      part1.push(this.distance);
    }

    return part1.join(' | ');
  }

  @computed
  get timeDistanceSubtitle(): string {
    const subtitle = [];
    if (this.time && this.time !== '0') {
      subtitle.push(this.time);
    }
    if (this.distance && this.distance !== '0') {
      subtitle.push(this.distance);
    }
    return subtitle.join(' | ');
  }

  @computed
  get hasRest(): boolean {
    return !!this.rest && this.rest.toUpperCase() !== 'NONE';
  }

  @computed
  get setsAsNumber(): number | null {
    return parseInt(this.sets, 10) || null;
  }

  @computed
  get repsAsNumber(): number | null {
    return parseInt(this.reps, 10) || null;
  }

  @computed
  get workoutTimerRestComputed(): number | null {
    if (this.workoutTimerRest) {
      if (this.workoutTimer === 'percent_recovery' && this.workoutTimerWork) {
        return parseInt(
          `${(this.workoutTimerWork * this.workoutTimerRest) / 100}`,
          10,
        );
      }
      return this.workoutTimerRest;
    }
    return null;
  }
}
