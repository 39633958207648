import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SavedList from 'o2x-store/src/models/SavedList';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useState } from 'react';
import { FlatList, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'src/components/Question/TopNav';
import SavedListItem from 'src/components/Saved';
import NewListItem from '../../components/Saved/NewListItem';
import { getErrors } from '../../utils/errors';

type Props = {
  route?: any | null;
};

const SaveToList: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const {
    route: {
      params: { listType, itemId },
    },
  } = props;

  const [errorMessage, setErrorMessage] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  let savedList = new Map<string, SavedList>();
  if (listType === LIST_TYPE.RECIPE) {
    savedList = store.savedList.recipeList;
  } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
    savedList = store.savedList.sweatWorkoutList;
  } else if (listType === LIST_TYPE.THRIVE_EXERCISE) {
    savedList = store.savedList.thriveExerciseList;
  }

  useFocusEffect(
    useCallback(() => {
      store.savedList.fetchLists(listType);
    }, []),
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const createList = useCallback(() => {
    navigation.navigate('ListEdit', { listType });
  }, [listType]);

  const addToList = useCallback(
    (listId: string) => {
      (async () => {
        if (
          listType === LIST_TYPE.RECIPE ||
          listType === LIST_TYPE.SWEAT_WORKOUT ||
          listType === LIST_TYPE.THRIVE_EXERCISE
        ) {
          const result = await store.savedList.addItems([itemId], listType, listId);
          if (result.ok) {
            navigation.goBack();
          } else {
            setErrorMessage(getErrors(result.errors));
          }
        }
      })();
    },
    [listType, itemId],
  );

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <SavedListItem
          key={`saved-list-${item.id}`}
          list={item}
          showAdd
          onPress={() => addToList(item.id)}
        />
      ) : null,
    [listType, savedList],
  );

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onClose={goBack} showBack={false} />
            </View>
            <Layout style={styles.scrollWrapper}>
              <View style={!isDeviceMaxWidth600 && styles.itemsContainer}>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                <Layout>
                  <Text category="h2" style={styles.header}>
                    Save to List
                  </Text>
                </Layout>
              </View>
              <View style={isMobile ? styles.scrollMobile : styles.scroll}>
                {savedList && (
                  <View style={styles.listContainer}>
                    <FlatList
                      data={Array.from(savedList.values())}
                      renderItem={renderItem}
                      keyExtractor={(item) => item && `${item.id}`}
                    />
                  </View>
                )}
                <Layout style={isMobile ? styles.newItemMobile : styles.newItem}>
                  <NewListItem
                    key={listType === LIST_TYPE.RECIPE ? 'new-eat' : 'new-sweat'}
                    type={listType}
                    onPress={createList}
                  />
                </Layout>
              </View>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  scrollWrapper: {
    flex: 1,
    alignItems: 'center',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  header: {
    marginBottom: 12,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
  },
  contentContainer: {
    paddingHorizontal: 24,
    paddingVertical: 32,
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 18,
  },
  navIcon: {
    width: 30,
    height: 30,
  },
  topNavText: {
    color: 'white',
    fontSize: 18,
    lineHeight: 22,
    marginLeft: -26,
  },
  separator: {
    height: 16,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  taskBar: {
    flex: 3,
    display: 'flex',
  },
  itemsContainer: {
    paddingHorizontal: 24,
    paddingBottom: 20,
  },
  items: {
    marginBottom: 20,
  },
  section: {
    marginBottom: 30,
  },
  subSelection: {
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    paddingTop: 4,
    marginBottom: 24,
  },
  subSelectionContainer: {
    flex: 1,
  },
  subSelectionContainerSelected: {
    borderBottomWidth: 4,
    borderColor: 'cyan',
    borderStyle: 'solid',
  },
  selectionText: {
    color: 'white',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 9,
    alignSelf: 'center',
  },
  navigationContainer: {
    ppaddingHorizontal: 15,
    paddingVertical: 12,
  },
  scroll: {
    paddingHorizontal: 24,
    flex: 1,
    height: '90%',
  },
  scrollMobile: {
    paddingHorizontal: 15,
    width: '100%',
    height: '90%',
  },
  newItem: {
    paddingBottom: 10,
  },
  newItemMobile: {
    width: '100%',
    paddingBottom: 10,
  },
  listContainer: {
    maxHeight: '80%',
  },
});

export default observer(SaveToList);
