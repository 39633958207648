import { Icon, Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import React, { useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  rating: number;
  percentage?: number;
};

const RatingBar: React.FC<Props> = (props) => {
  const { style, rating, percentage } = props;
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const stars = useMemo(() => new Array(5).fill(0), []);

  return (
    <Layout style={[styles.container, style]}>
      {stars.map((_value, index) => (
        <Icon
          key={`${index}`}
          style={styles.icon}
          name={rating > index ? 'star' : 'star-outline'}
          fill={theme.white}
        />
      ))}
      {!!percentage && (
        <Layout style={styles.barContainer}>
          <Layout style={[styles.bar, { width: `${percentage}%` }]} />
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    backgroundColor: 'blue-secondary',
  },
  barContainer: {
    flex: 1,
    flexDirection: 'row',
    height: 4,
    marginHorizontal: 12,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 1,
    width: 10,
    height: 10,
  },
});

export default RatingBar;
