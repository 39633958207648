import { useNavigation } from '@react-navigation/core';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useMemo } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import DLThriveItem from 'src/components/Downloaded/DLThriveItem';
import { useNativeStore } from 'src/stores';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
};

const DownloadedThriveProgram: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { downloads } = useNativeStore();
  useKeepAwake();

  const { programTitle, title, id, programId } = props.route.params;

  const color = getTaskColor(TASK_TYPE.THRIVE);

  const program = downloads.thrivePrograms.get(`${programId}`);

  const data = useMemo(() => {
    if (!program) return [];

    return Array.from(program.thriveExercises.values())
      .filter((e) => e.programDay === id)
      .sort((a, b) => a.id - b.id);
  }, [program]);

  const renderItem = useCallback(
    ({ item, index }) => (
      <DLThriveItem item={item} index={index} color={color} />
    ),
    [],
  );

  const renderFooter = useCallback(() => <View style={styles.footer} />, []);

  const keyExtractor = useCallback((i, j) => `${j}${i.id}`, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  if (!program) return null;

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav
          style={styles.navigationStyle}
          onBack={goBack}
          showClose={false}
        />
      </View>
      <View style={styles.header}>
        <Text category="h2">{programTitle}</Text>
        <Text category="c1" style={[styles.subtitle, { color }]}>
          {title}
        </Text>
      </View>
      <View style={styles.content}>
        <FlatList
          renderItem={renderItem}
          ListFooterComponent={renderFooter}
          keyExtractor={keyExtractor}
          data={data}
        />
      </View>
      <TouchableOpacity style={styles.button} onPress={goBack}>
        <Text style={styles.label} category="c1">
          FINISH
        </Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  content: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigationStyle: {
    backgroundColor: 'transparent',
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingTop: 20,
    paddingBottom: 15,
    paddingHorizontal: 30,
  },
  subtitle: {
    marginTop: 10,
  },
  footer: {
    height: 30,
  },
  label: {
    textAlign: 'center',
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'olive',
  },
});

export default DownloadedThriveProgram;
