import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import SignatureCanvas from 'react-signature-canvas';
import BottomNav from '../../components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
  onSubmit: () => void;
};

const EventSignature: React.FC<Props> = (props) => {
  const { id, onSubmit } = props.route.params;

  const styles = useStyleSheet(themedStyles);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState();
  const ref = useRef();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const doOnSubmit = useCallback(() => {
    const sig = ref.current.toDataURL();
    onSubmit(sig);
  }, [data]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav />
            </View>
            <View style={styles.content}>
              {!!errorMessage && (
                <Text style={styles.error} category="label">
                  {errorMessage}
                </Text>
              )}
              <Text style={styles.label}>Signature:</Text>
              <View style={styles.canvasContainer}>
                <SignatureCanvas
                  ref={ref}
                  penColor="black"
                  canvasProps={{ className: styles.canvas }}
                  backgroundColor="white"
                />
              </View>
            </View>
            <BottomNav backLabel="Cancel" onNext={doOnSubmit} />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: '10%',
    paddingHorizontal: 24,
    flex: 1,
    alignItems: 'center',
  },
  scroll: {
    flex: 1,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    marginBottom: 14,
  },
  signature: {
    backgroundColor: 'white',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  canvasContainer: {
    width: 600,
    height: 600,
    paddingHorizontal: 24,
  },
  canvas: {
    width: '90%',
    height: '90%',
  },
});

export default observer(EventSignature);
