import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, PanResponder, View } from 'react-native';
import Svg, { Circle, G, Path } from 'react-native-svg';

interface Props {
  btnRadius?: number;
  dialRadius?: number;
  dialWidth?: number;
  meterColor?: string;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  initialValue?: number;
  min?: number;
  max?: number;
  xCenter?: number;
  yCenter?: number;
  onChangeValue?: (x: number) => void;
  disabled?: boolean;
}

const { width: screenWidth, height: screenHeight } = Dimensions.get('window');
const CircularSlider: FC<Props> = ({
  btnRadius = 15,
  dialRadius = 100,
  dialWidth = 10,
  meterColor = 'black',
  fillColor = 'none',
  strokeColor = 'black',
  strokeWidth = 10,
  initialValue = 0,
  min = 0,
  max = 359,
  xCenter = screenWidth / 2,
  yCenter = screenHeight / 2,
  onChangeValue = () => {},
  disabled,
}) => {
  const [angle, setAngle] = useState(initialValue);

  useEffect(() => {
    if (disabled) {
      setAngle(initialValue);
    }
  }, [initialValue]);

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => !disabled,
        onStartShouldSetPanResponderCapture: () => !disabled,
        onMoveShouldSetPanResponder: () => !disabled,
        onMoveShouldSetPanResponderCapture: () => !disabled,
        onPanResponderMove: (_e, gs) => {
          const xOrigin = xCenter - (dialRadius + btnRadius);
          const yOrigin = yCenter - (dialRadius + btnRadius);
          let a = cartesianToPolar(gs.moveX - xOrigin, gs.moveY - yOrigin);
          a = Math.floor(a);
          a = a <= min ? min : a >= max ? max : a;
          a = a < 90 && angle > 270 ? max : angle < 90 && a > 270 ? min : a;
          if (Math.abs(a - angle) < 90) {
            setAngle(a);
            onChangeValue(Math.floor((a / 359) * 100));
          }
        },
      }),
    [angle],
  );

  const polarToCartesian = useCallback(
    (angle) => {
      const r = dialRadius;
      const hC = dialRadius + btnRadius;
      const a = ((angle - 90) * Math.PI) / 180.0;

      const x = hC + r * Math.cos(a);
      const y = hC + r * Math.sin(a);
      return { x, y };
    },
    [dialRadius, btnRadius],
  );

  const cartesianToPolar = useCallback(
    (x, y) => {
      const hC = dialRadius + btnRadius;

      if (x === 0) {
        return y > hC ? 0 : 180;
      }
      if (y === 0) {
        return x > hC ? 90 : 270;
      }
      return Math.round((Math.atan((y - hC) / (x - hC)) * 180) / Math.PI) + (x > hC ? 90 : 270);
    },
    [dialRadius, btnRadius],
  );

  const width = (dialRadius + btnRadius) * 2;
  const bR = btnRadius;
  const dR = dialRadius;
  const startCoord = polarToCartesian(0);
  const endCoord = polarToCartesian(angle);

  return (
    <View
      style={{
        height: (dialRadius + btnRadius) * 2,
        width: (dialRadius + btnRadius) * 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Svg width={width} height={width}>
        <Circle
          r={dR}
          cx={width / 2}
          cy={width / 2}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill={fillColor}
        />
        <Path
          stroke={meterColor}
          strokeWidth={dialWidth}
          strokeLinecap="round"
          fill="none"
          d={`M${startCoord.x} ${startCoord.y} A ${dR} ${dR} 0 ${angle > 180 ? 1 : 0} 1 ${
            endCoord.x
          } ${endCoord.y}`}
        />
        <G x={endCoord.x - bR} y={endCoord.y - bR}>
          <Circle r={bR} cx={bR} cy={bR} fill={meterColor} {...panResponder.panHandlers} />
        </G>
      </Svg>
      <View
        style={{
          height: strokeWidth,
          width: strokeWidth,
          position: 'absolute',
          top: btnRadius - strokeWidth / 2,
          borderRadius: strokeWidth / 2,
          backgroundColor: meterColor,
        }}
      />
    </View>
  );
};

export default CircularSlider;
