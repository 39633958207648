import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import { get, indexOf, slice } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProgramPreview } from 'o2x-store/src/models/Program';
import { useStore } from 'o2x-store/src/stores';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { SectionList, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from 'src/components/NavigationBar';
import { useIsSubscribed } from 'src/hooks/subscription';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ProgramDetail'>;
};

const ITEMS_PER_DAY = 3;

const ProgramDetail: React.FC<Props> = (props) => {
  const { type, id } = props.route.params;
  const store = useStore();
  const program = store.getProgram(type, id);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const insets = useSafeArea();
  const color = get(program, 'color', 'white');
  const { navigateWithPayWall, isSubscribed } = useIsSubscribed();

  const [loading, setLoading] = useState(false);
  const [programPreview, setProgramPreview] = useState<ProgramPreview | null>(null);
  const [expanded, setExpanded] = useState<Array<string>>(new Array<string>());

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        let preview;
        if (program?.type === TASK_TYPE.SWEAT) {
          preview = await store.sweat.fetchSweatProgramPreview(program.id);
        } else if (program?.type === TASK_TYPE.THRIVE) {
          preview = await store.thrive.fetchThriveProgramPreview(program.id);
        }
        if (preview && !('errors' in preview)) {
          setProgramPreview(preview);
        }
        setLoading(false);
      })();
    }, [program, setProgramPreview, setLoading]),
  );

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const renderTruncatedExercises = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          See More
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const renderRevealedExercises = useCallback(
    (onPress) => (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.readMore} category="p2">
          See Less
        </Text>
      </TouchableOpacity>
    ),
    [],
  );

  const setExpandedItem = useCallback(
    (item, toExpand) => {
      if (!isSubscribed) {
        navigateWithPayWall('PayWall');
        return;
      }

      if (toExpand) {
        // Add to expand
        setExpanded((prevExpanded) => [...(prevExpanded || []), item.title]);
      } else {
        // Remove from expand
        setExpanded((prevExpanded) => prevExpanded.filter((e) => e !== item.title));
      }
    },
    [setExpanded],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.dayPreview}>
        <Text style={[styles.dayText, { color }]}>{item.title}</Text>
        {indexOf(expanded, item.title) !== -1 ? (
          <View style={styles.dayItems}>
            {item.data.map((d: string, index: number) => (
              <View style={styles.listItem} key={`item-${index}`}>
                <View style={[styles.marker, { backgroundColor: color }]} />
                <Text style={styles.listItemText}>{d}</Text>
              </View>
            ))}
            {renderRevealedExercises(() => setExpandedItem(item, false))}
          </View>
        ) : (
          <View style={styles.dayItems}>
            {slice(item.data, 0, ITEMS_PER_DAY).map((d: string, index: number) => (
              <View style={styles.listItem} key={`item-${index}`}>
                <View style={[styles.marker, { backgroundColor: color }]} />
                <Text style={styles.listItemText}>{d}</Text>
              </View>
            ))}
            {ITEMS_PER_DAY < item.data.length &&
              renderTruncatedExercises(() => setExpandedItem(item, true))}
          </View>
        )}
        {indexOf(expanded, item.title) === -1 && ITEMS_PER_DAY < item.data.length && (
          <LinearGradient
            colors={['rgba(16, 40, 62, 0)', 'rgba(16, 40, 62, 1)']}
            style={styles.gradient}
          />
        )}
      </View>
    ),
    [color, expanded],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  if (!program) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            {loading ? (
              <View style={styles.loading}>
                <Spinner />
              </View>
            ) : (
              <>
                <View style={styles.scroll}>
                  <Layout style={styles.content}>
                    <View style={styles.header}>
                      <Text style={styles.center} category="h2">
                        {program.name}
                      </Text>
                      <Text style={[styles.subtitle, { color }]} category="c2">
                        {programPreview?.weeksDisplay}
                      </Text>
                    </View>
                    {programPreview && (
                      <SectionList
                        style={
                          isDeviceMaxWidth600
                            ? styles.previewContentMaxWidth600
                            : styles.previewContent
                        }
                        renderItem={renderItem}
                        renderSectionHeader={({ section }) => (
                          <Text style={styles.weekText}>{section.title}</Text>
                        )}
                        sections={programPreview.preview || []}
                      />
                    )}
                  </Layout>
                </View>
                <NavigationBar
                  style={[styles.navigation, { top: insets.top }]}
                  showBack={false}
                  showClose
                  closeColor={theme['blue-secondary']}
                  withBackground={false}
                />
              </>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 28,
    height: '100%',
  },
  navigationContainer: {
    padding: 16,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingHorizontal: 26,
  },
  rating: {
    marginTop: 30,
  },
  readMore: {
    marginTop: 20,
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 14,
    color: 'gray',
  },
  scroll: {
    flex: 1,
    height: '100%',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    marginBottom: 20,
    textAlign: 'center',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  previewContent: {
    paddingHorizontal: '25%',
    height: 540,
  },
  previewContentMaxWidth600: {
    paddingHorizontal: '5%',
    height: '100%',
  },
  weekText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 25,
    textTransform: 'uppercase',
    paddingHorizontal: 26,
    textAlign: 'center',
  },
  dayPreview: {
    marginHorizontal: 22,
    marginBottom: 30,
    paddingHorizontal: 26,
  },
  dayText: {
    fontSize: 18,
    lineHeight: 22,
    textTransform: 'uppercase',
    marginBottom: 18,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 7,
  },
  marker: {
    width: 5,
    height: 5,
    alignSelf: 'flex-start',
    marginTop: 6,
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 20,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 120,
  },
  center: {
    textAlign: 'center',
  },
});

export default observer(ProgramDetail);
