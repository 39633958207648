import { observable } from 'mobx';
import { getRoot, model, Model, modelFlow, prop_mapObject, _async, _await } from 'mobx-keystone';
import { RootStore } from '.';
import config from '../config';
import { DashboardFile, DashboardModel } from '../models/Dashboard';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/DashboardStore')
export default class DashboardStore extends Model({
  dashboardContents: prop_mapObject(() => new Map<string, DashboardModel>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchDashboard = _async(function* (this: DashboardStore, userId?: number, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    const initialUrl = `${config.urls.dashboard}?limit=15&user=${userId}`;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMyDashboard(rootStore.auth.token, url || initialUrl)));
    } catch (error) {
      console.warn('[DEBUG] error fetching dashboard', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createDashboardContent = _async(function* (
    this: DashboardStore,
    data: DashboardModel,
    files?: any,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: DashboardModel;
    data.user = rootStore.auth.user.id;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createDashboardContent(rootStore.auth.token, data, files)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating dashboard content', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteDashboardContent = _async(function* (this: DashboardStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteDashboardContent(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting dashboard content', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateDashboardContent = _async(function* (
    this: DashboardStore,
    data: Partial<DashboardModel>,
    files?: any,
    attachedFiles?: any,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateDashboardContent(rootStore.auth.token, data, files, attachedFiles),
      ));
    } catch (error) {
      console.warn('[DEBUG] error updating dashboard content', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  uploadDashboardFile = _async(function* (this: DashboardStore, data: Partial<DashboardFile>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: DashboardFile;
    try {
      ({
        response: { entities },
      } = yield* _await(api.uploadDashboardFile(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating dashboard content', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  getDashboardFile = _async(function* (this: DashboardStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.getDashboardFile(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching dashboard', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });
}
