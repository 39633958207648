import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';

export enum THRIVE_MEDIA_FILE_TYPE {
  AUDIO = 'audios',
  VIDEO = 'videos',
  IMAGE = 'images',
  INVALID = 'invalid',
}

@model('o2x-store/ThriveMediaFile')
export default class ThriveMediaFile extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  content: prop<string>(''),
  description: prop<string>(''),
  inSleep: prop<boolean>(false),
  inMeditate: prop<boolean>(false),
  isGuided: prop<boolean>(false),
  type: prop<THRIVE_MEDIA_FILE_TYPE>(THRIVE_MEDIA_FILE_TYPE.INVALID),
}) {
  getRefId = () => `${this.type}-${this.id}`;

  @modelAction
  update(
    data: ModelInstanceCreationData<ThriveMediaFile>,
    type: THRIVE_MEDIA_FILE_TYPE,
  ) {
    Object.assign(this, data);
    this.type = type;
  }
}
