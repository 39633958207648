import { Icon, Layout, Popover, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { RenderProp } from '@ui-kitten/components/devsupport';
import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

type Props = {
  isOptionsVisible: boolean;
  renderToggleButton: RenderProp;
  setIsOptionsVisible: Function;
  removeItem: () => void;
  saveItem: () => void;
  loadItem: () => void;
  content?: string;
  onCopy?: () => void;
  onPaste?: () => void;
};

const MorePopover: React.FC<Props> = (props) => {
  const {
    isOptionsVisible,
    renderToggleButton,
    setIsOptionsVisible,
    removeItem,
    saveItem,
    loadItem,
    onCopy,
    onPaste,
    content,
  } = props;
  const styles = useStyleSheet(themedStyles);

  const remove = () => {
    removeItem();
    setIsOptionsVisible(false);
  };
  const save = () => {
    saveItem();
    setIsOptionsVisible(false);
  };
  const load = () => {
    loadItem();
    setIsOptionsVisible(false);
  };
  const copy = () => {
    onCopy();
    setIsOptionsVisible(false);
  };
  const paste = () => {
    onPaste();
    setIsOptionsVisible(false);
  };

  return (
    <Popover
      visible={isOptionsVisible}
      placement="bottom end"
      anchor={renderToggleButton}
      style={
        {
          borderWidth: 0.2,
          borderColor: 'black',
        } as StyleProp<ViewStyle>
      }
      onBackdropPress={() => setIsOptionsVisible(false)}>
      <Layout style={{ height: 180, width: 150 }}>
        <TouchableOpacity
          style={[
            {
              width: '100%',
              height: '33%',
              flexDirection: 'row',
              borderBottomWidth: 1.5,
              borderBottomColor: '#203950',
            },
            content === 'step' && { height: '25%' },
          ]}
          onPress={remove}>
          <Icon
            name="trash-2-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Remove
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            {
              width: '100%',
              height: '33%',
              flexDirection: 'row',
              borderBottomWidth: 1.5,
              borderBottomColor: '#203950',
            },
            content === 'step' && { height: '25%' },
          ]}
          onPress={save}>
          <Icon
            name="save-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Save Template
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            {
              width: '100%',
              height: '33%',
              flexDirection: 'row',
              borderBottomWidth: 1.5,
              borderBottomColor: '#203950',
            },
            content === 'step' && { height: '25%' },
          ]}
          onPress={load}>
          <Icon
            name="file-outline"
            fill="white"
            style={{
              marginLeft: 15,
              width: 20,
              height: 20,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Text
            style={{
              marginLeft: 5,
              marginTop: 'auto',
              marginBottom: 'auto',
              fontSize: 15,
            }}>
            Load Template
          </Text>
        </TouchableOpacity>
        {content === 'step' && (
          <TouchableOpacity
            style={[
              {
                width: '100%',
                height: '25%',
                flexDirection: 'row',
                borderBottomWidth: 1.5,
                borderBottomColor: '#203950',
              },
              // !!content && { height: '20%' },
            ]}
            onPress={copy}>
            <Icon
              name="clipboard-outline"
              fill="white"
              style={{
                marginLeft: 15,
                width: 20,
                height: 20,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <Text
              style={{
                marginLeft: 5,
                marginTop: 'auto',
                marginBottom: 'auto',
                fontSize: 15,
              }}>
              Copy
            </Text>
          </TouchableOpacity>
        )}

        {/* {!!content && (
          <TouchableOpacity
            style={{ width: '100%', height: '20%', flexDirection: 'row' }}
            onPress={paste}
          >
            <Icon
              name="copy-outline"
              fill="white"
              style={{
                marginLeft: 15,
                width: 20,
                height: 20,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <Text
              style={{
                marginLeft: 5,
                marginTop: 'auto',
                marginBottom: 'auto',
                fontSize: 15,
              }}
            >
              Paste
            </Text>
          </TouchableOpacity>
        )} */}
      </Layout>
    </Popover>
  );
};

const themedStyles = StyleService.create({});

export default MorePopover;
