import { useFocusEffect } from '@react-navigation/core';
import {
  Icon,
  Input,
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import config from 'o2x-store/src/config';
import { MessageModel } from 'o2x-store/src/models/Message';
import { useStore } from 'o2x-store/src/stores';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dimensions,
  Image,
  ImageStyle,
  NativeScrollEvent,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TopNav from 'src/components/Question/TopNav';
import Message from 'src/components/FTEuser/Message';
import { getErrors } from 'src/utils/errors';

type Props = {
  route: any;
};

const { height, width } = Dimensions.get('window');

const ContactFte: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const scrollViewRef = useRef<ScrollView>(null);

  const [nextRequest, setNextRequest] = useState('');
  const [conversation, setConversation] = useState<Array<MessageModel>>();
  const [showDate, setShowDate] = useState<boolean[]>();
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [body, setBody] = useState('');

  const store = useStore();

  const { message, selectedFte } = props.route.params;

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const url = message.group
          ? `${config.urls.conversationStaging}?group=${message.group}&limit=20`
          : `${config.urls.conversationStaging}?user1=${store.auth.user?.id}&user2=${selectedFte.id}&limit=20`;
        const fetchData = await store.contact.fetchConversation(undefined, url);
        if (fetchData.extra?.results.length > 0) {
          const arr = new Array(fetchData.extra?.results.length).fill(false);
          setShowDate(arr);
        }
        setConversation(fetchData.extra?.results);
        setNextRequest(fetchData.extra?.next);
        setLoading(false);
        setTimeout(() => scrollViewRef.current?.scrollToEnd({ animated: false }), 100);
      })();
    }, []),
  );

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (message.id) await store.contact.markReadMessage(message);
      })();
    }, [message]),
  );

  useFocusEffect(
    useCallback(() => {
      register({ name: 'body' }, { required: true });
      register({ name: 'sender' }, { required: true });
      register({ name: 'receiver' }, { required: false });
      register({ name: 'group' }, { required: false });
    }, [register, setValue]),
  );

  useEffect(() => {
    setValue('body', body);
    setValue('sender', store.auth.user?.id);
    setValue('receiver', message.group ? undefined : selectedFte.id);
    setValue('group', message.group ? message.group : undefined);
  }, [message, body, store.auth.user, selectedFte]);

  const onSubmit = useCallback(async (data) => {
    setIsSending(true);
    setBody('');
    const result = await store.contact.createMessage(data);
    if (result.ok) {
      store.contact.sendEmailMessage(data);
      const url = message.group
        ? `${config.urls.conversationStaging}?group=${message.group}&limit=20`
        : `${config.urls.conversationStaging}?user1=${store.auth.user?.id}&user2=${selectedFte.id}&limit=20`;
      const fetchData = await store.contact.fetchConversation(undefined, url);
      setConversation(fetchData.extra?.results);
      setNextRequest(fetchData.extra?.next);
      setTimeout(() => scrollViewRef.current?.scrollToEnd({ animated: true }), 100);
      setIsSending(false);
    } else {
      console.log(getErrors(result.errors));
    }
  }, []);
  const onSubmitWrapped = handleSubmit(onSubmit);

  const loadMore = async () => {
    setLoading(true);
    if (nextRequest) {
      const fetchData = await store.contact.fetchConversation(undefined, nextRequest);
      setConversation([...conversation!, ...fetchData.extra?.results]);
      setNextRequest(fetchData.extra?.next);
      setTimeout(
        () =>
          scrollViewRef.current?.scrollTo({
            x: 0,
            y: height,
            animated: false,
          }),
        100,
      );
    }
    setLoading(false);
  };

  const isCloseToTop = ({ contentOffset }: NativeScrollEvent) => contentOffset.y === 0;

  const onPressMessage = useCallback(
    (index: number) => {
      const arr = [...showDate!];
      arr[index] = !arr[index];
      setShowDate([...arr]);
    },
    [showDate],
  );

  const renderSend = () => (
    <TouchableOpacity style={styles.sendButton} onPress={onSubmitWrapped} disabled={isSending}>
      <Icon style={styles.send} name="paper-plane-outline" fill="white" />
    </TouchableOpacity>
  );

  return (
    <Layout style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
      <View style={styles.header}>
        <Image
          style={styles.icon as StyleProp<ImageStyle>}
          source={
            message.group
              ? require('../../assets/images/user_placeholder.png')
              : selectedFte.profileImage
              ? { uri: selectedFte.profileImage }
              : require('../../assets/images/user_placeholder.png')
          }
        />
        <Text style={{ maxWidth: '80%' }} numberOfLines={1}>
          {message.group ? message.groupDetails : selectedFte.fullName}
        </Text>
      </View>
      <ScrollView
        ref={scrollViewRef}
        style={styles.conversationContainer}
        showsVerticalScrollIndicator={false}
        onScroll={({ nativeEvent }) => {
          if (isCloseToTop(nativeEvent)) {
            loadMore();
          }
        }}
        scrollEventThrottle={16}>
        {loading && (
          <Layout style={styles.loading}>
            <Spinner />
          </Layout>
        )}
        {conversation
          ?.map((message, index) => (
            <Fragment key={index}>
              {!!message.body && (
                <Message
                  key={message.id}
                  message={message}
                  onPressMessage={() => onPressMessage(index)}
                  showDate={showDate?.[index]}
                />
              )}
              {message.files.map((file) => (
                <Message
                  key={`${message.id}-${file.id}`}
                  message={message}
                  file={file}
                  onPressMessage={() => onPressMessage(index)}
                  showDate={showDate?.[index]}
                />
              ))}
            </Fragment>
          ))
          .reverse()}
      </ScrollView>
      <Input
        value={body}
        onChangeText={(text) => setBody(text)}
        style={styles.input}
        multiline
        numberOfLines={3}
        placeholder="Write Message"
        accessoryRight={renderSend}
      />
      <View style={styles.navigationContainer}>
        <TopNav showClose={false} style={styles.topNav} />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  header: {
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    paddingLeft: 60,
    paddingRight: 20,
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginHorizontal: 10,
  },
  receiverMessage: {
    color: 'white',
    padding: 10,
    // width: '80%',
    backgroundColor: 'olive',
    fontSize: 14,
    borderRadius: 4,
  },
  senderMessage: {
    marginLeft: 'auto',
    color: 'white',
    padding: 10,
    maxWidth: width * 0.65,
    backgroundColor: '#091C2D',
    fontSize: 14,
    borderRadius: 4,
  },
  loading: {
    margin: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  conversationContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  messageContainer: {
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  receiverDate: {
    marginRight: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  senderDate: {
    marginLeft: 'auto',
    marginTop: 8,
    marginBottom: 8,
    color: '#8F9BB3',
    fontSize: 9,
  },
  send: {
    width: 24,
    height: 24,
  },
  input: {
    backgroundColor: '#0B1F35',
    color: 'white',
    maxHeight: 70,
    borderColor: 'transparent',
  },
  sendButton: {
    height: 40,
    width: 40,
    backgroundColor: 'olive',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
});

export default observer(ContactFte);
