import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import MoreVertical from '../../assets/images/more-vertical.svg';
import { useNativeStore } from '../../stores';

type Props = {
  program?: SweatProgram;
  workout?: SweatWorkout;
};

const SweatStartHeaderSidebar: React.FC<Props> = (props) => {
  const { program, workout } = props;
  const { sweatStart } = useNativeStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  if (!program || !workout) {
    return null;
  }

  const onOptions = useCallback(async () => {
    if (program) {
      navigation.navigate('SweatOptions', {
        programId: program.id,
        workoutId: workout.id,
      });
    } else if (workout) {
      await workout.startWorkout();
      await workout.markComplete({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
      });
    }
  }, [program, workout]);

  return (
    <Layout style={styles.container}>
      <Layout style={styles.header}>
        <Text category="h2" style={styles.headerText}>
          {program.name || workout.title}
        </Text>
        <TouchableOpacity style={styles.completeButton} onPress={onOptions}>
          <MoreVertical />
        </TouchableOpacity>
      </Layout>
      <Layout style={styles.subheader}>
        <Text style={styles.subheaderText} category="c1">
          {workout.dayDisplay}
        </Text>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 24,
  },
  completeButton: {},
  completeIcon: {
    marginRight: 8,
  },
  completeText: {
    color: 'white',
    textTransform: 'uppercase',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  headerText: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: '700',
  },
  subheader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 24,
  },
  subheaderText: {
    flex: 1,
    color: 'cyan',
    textTransform: 'uppercase',
  },
});

export default SweatStartHeaderSidebar;
