import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { RECOVER_ID } from 'o2x-store/src/constants/sweat';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout, { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Platform, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import SweatStartItem from 'src/components/SweatStart/SweatStartItem';
import { DEFAULT_REST_TABATA } from 'src/models/SweatStart';
import { getTimeDisplay } from 'src/utils/timeDisplay';
import Back from '../../assets/images/back.svg';
import PlayIcon from '../../assets/images/play.svg';
import { useNativeStore } from '../../stores';

type Props = {};

const SweatStartDetailInstructions: React.FC<Props> = (props) => {
  const { programId } = props.route.params;
  const navigation = useNavigation();
  const { sweatStart, notification } = useNativeStore();
  const { sweat, auth } = useStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const dailyPlanReminders = auth.user?.settings.dailyPlanReminders;
  let program: SweatProgram | undefined;
  if (programId) {
    program = sweat.sweatPrograms.get(`${programId}`);
  }
  const [nextStep, setNextStep] = useState<SweatWorkoutStep | undefined>();
  let workout: SweatWorkout | undefined;
  let step: SweatWorkoutStep | undefined;
  let stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise | undefined;
  let exercise: SweatExercise | undefined;
  let nextStepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise | undefined;

  ({ workout, step, stepExercise, exercise } = sweatStart);

  let nextIsCircuit = false;
  if (nextStep) {
    nextStepExercise = sweatStart.getNextStepExercise(true);
    nextIsCircuit = nextStep.circuitSets !== 1;
  }

  const [loading, setLoading] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      sweatStart.setExpandStepExercise(null);
    }, []),
  );

  const handleStart = useCallback(() => {
    sweatStart.startTimer();
    navigation.navigate('SweatStartDetail', { programId });
  }, [sweatStart, programId, nextStep, sweat]);

  const handleSkip = useCallback(() => {
    if (step?.id === RECOVER_ID) {
      return onEndWorkout();
    }

    if (sweatStart.active) {
      sweatStart.stopTimer();
    }

    setLoading(true);
    sweatStart.nextStep(sweat);
    setLoading(false);

    const next = sweatStart.getNextStep;
    if (
      next?.circuitInstructions ||
      next?.circuitTimer ||
      (!!next?.circuitSets && next?.circuitSets !== 1)
    ) {
      return navigation.navigate('SweatStartDetailInstruction', {
        programId,
      });
    }

    sweatStart.startTimer();
    return navigation.navigate('SweatStartDetail', { programId });
  }, [sweat, sweatStart, step, nextStep]);

  const renderEmpty = useCallback(() => {
    if (loading || workout?.loading) {
      return (
        <View style={styles.loading}>
          <Spinner />
        </View>
      );
    }
    return null;
  }, [workout, loading]);

  const onEndWorkout = useCallback(async () => {
    let result;
    if (program && !sweatStart.repeat) {
      if (dailyPlanReminders) {
        const currentWorkoutId = get(program, 'userProgress.currentWorkout', null);
        const nextWorkoutId = get(program, 'userProgress.nextWorkout', null);

        const currentWorkout = currentWorkoutId && sweat.sweatWorkouts.get(`${currentWorkoutId}`);
        const nextWorkout = nextWorkoutId && sweat.sweatWorkouts.get(`${nextWorkoutId}`);

        if (nextWorkout && currentWorkout) {
          const numberOfDays =
            (nextWorkout.week - currentWorkout.week) * 7 + (nextWorkout.day - currentWorkout.day);
          await notification.setSweatNotification(program.name, `${program.id}`, numberOfDays);
        }
      }

      result = await program.markCompleteCurrentWorkout(
        {
          totalTime: sweatStart.totalTime,
          stepExerciseTimeBreakdown: sweatStart.breakdownTime,
          globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
          circuitBreakdownSets: sweatStart.circuitBreakdownSets,
        },
        workout.id,
      );
    } else if (workout) {
      result = await workout.markComplete({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
        circuitBreakdownSets: sweatStart.circuitBreakdownSets,
      });
    }
    if (!result || !result.ok) {
      console.log('[DEBUG] result', result);
      return;
    }

    await sweatStart.endWorkout();
    await sweatStart.reset();
    navigation.navigate('SweatWorkoutComplete', {
      programId: program ? program.id : undefined,
      workoutId: workout!.id,
    });
  }, [program, workout, sweatStart, sweatStart.repeat, dailyPlanReminders]);

  const onEndWorkoutAlert = useCallback(() => {
    if (Platform.OS === 'web') {
      const doEnd = confirm('Are you sure you want to end your workout?');
      if (doEnd) {
        onEndWorkout();
      }
    }
  }, [onEndWorkout]);

  const renderItem = useCallback(
    ({ item }) => {
      const exerciseIndex = (step?.exercises || []).findIndex((e) => e.id === item!.id);

      let isFirst = true;
      let isLast = true;
      if (exerciseIndex) {
        isFirst = step?.exercises[exerciseIndex - 1].round !== item.round;
      }

      if (exerciseIndex < step!.exercises.length - 1) {
        isLast = step?.exercises[exerciseIndex + 1].round !== item.round;
      }

      return (
        <>
          {step?.specifyRounds && isFirst && (
            <View style={styles.roundHeader}>
              <Text style={[styles.roundText, styles.cyanText]}>ROUND {item.round + 1}</Text>
            </View>
          )}
          <SweatStartItem workout={workout} step={step} stepExercise={item} showVideo inDetail />
          {((step?.specifyRounds && isLast && step.circuitTimerRest) ||
            step?.circuitTimer === 'tabata') && (
            <View style={styles.restHeader}>
              <Text style={[styles.tabataText]}>Rest </Text>
              <Text style={styles.restTimeText}>
                {step?.circuitTimer === 'tabata'
                  ? getTimeDisplay(DEFAULT_REST_TABATA)
                  : getTimeDisplay(step.circuitTimerRest)}
              </Text>
            </View>
          )}
        </>
      );
    },
    [workout, step],
  );

  const keyExtractor = useCallback((item) => `${item.id}`, []);

  if (!workout || !step || !stepExercise || !exercise) {
    return null;
  }

  const onBack = useCallback(() => {
    const prevStep = sweatStart.getPrevStep;

    if (
      prevStep?.circuitInstructions ||
      !!prevStep?.circuitTimer ||
      (!!prevStep?.circuitSets && prevStep?.circuitSets !== 1)
    ) {
      sweatStart.prevStep(sweat);
    } else {
      sweatStart.prevStep(sweat);
      navigation.navigate('SweatStartDetail', {
        programId,
      });
    }
  }, [sweatStart.active, step, sweatStart]);

  const circuitTimerDescription = useMemo(() => {
    if (step) {
      if (step?.circuitTimer === 'amrap') {
        return 'Athlete is asked to complete as many rounds of the circuit as they can in the allotted time';
      }
      if (step?.circuitTimer === 'emom') {
        return 'Athlete is asked to perform a certain amount of reps of an exercise beginning at the start of every minute. Once they have finished the specified reps for an exercise, their rest time is the remaining time left before the next minute.';
      }
      if (step?.circuitTimer === 'tabata') {
        return 'Defined as a circuit with exercises that are 20 seconds of work, followed by 10 seconds of rest for 8 rounds (totaling 4 minutes of work)';
      }
    }
    return '';
  }, [step]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <Layout style={styles.container}>
            <View style={[styles.header, { marginTop: insets.top }]}>
              <TouchableOpacity style={styles.backButton} onPress={onBack}>
                <Back />
              </TouchableOpacity>
              <View style={styles.headerTitleContainer}>
                <Text style={styles.headerText}>
                  {!step.circuitTimer && step.circuitSets === 1
                    ? 'Perform in order'
                    : !!step.circuitTimer && step.circuitTimer !== 'variable'
                    ? step.circuitTimer
                    : 'Circuit'}
                </Text>
              </View>
            </View>

            <View style={styles.instructionContainer}>
              {!!circuitTimerDescription && (
                <Text style={styles.subHeaderText}>{circuitTimerDescription}</Text>
              )}
              {step.circuitTimer !== 'amrap' && step.circuitSets > 1 && (
                <Text style={styles.instructionText} category="c1">
                  {`Complete ${step.circuitSets} ${Humanize.pluralize(step.circuitSets, 'time')} `}
                </Text>
              )}
              <Text style={styles.instructionText} category="c1">
                {step.circuitInstructions}
              </Text>
            </View>

            <View style={styles.listContainer}>
              {loading ? (
                <View style={styles.loading}>
                  <Spinner />
                </View>
              ) : (
                <FlatList
                  data={step.exercises}
                  renderItem={renderItem}
                  extraData={{ program, workout }}
                  keyExtractor={keyExtractor}
                  ListEmptyComponent={renderEmpty}
                  style={styles.listItem}
                  initialNumToRender={30}
                  scrollEventThrottle={500}
                />
              )}
            </View>
            <Layout style={[styles.actionContainer, { marginBottom: insets.bottom }]}>
              {/* <TouchableOpacity
                style={styles.action}
                onPress={onEndWorkoutAlert}
              >
                <View style={styles.buttonContainer}>
                  <Text style={styles.buttonText}>Stop</Text>
                </View>
              </TouchableOpacity> */}

              <TouchableOpacity style={styles.action} onPress={handleStart}>
                <View style={[styles.buttonContainer, styles.playButtonContainer]}>
                  <PlayIcon fill="white" />
                </View>
              </TouchableOpacity>

              {/* <TouchableOpacity style={styles.action} onPress={handleSkip}>
                <View style={styles.buttonContainer}>
                  <Text style={styles.buttonText}>Skip</Text>
                </View>
              </TouchableOpacity> */}
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },

  header: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  headerText: {
    color: 'cyan',
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subHeaderText: {
    color: 'cyan',
    fontSize: 12,
    textAlign: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 20,
  },

  headerTitleContainer: {
    flex: 1,
    marginTop: 10,
    marginRight: 24,
  },

  listContainer: {
    flex: 2,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },

  listItem: {
    flex: 1,
    backgroundColor: 'black',
  },
  listHeaderContainer: {
    height: 40,
  },
  listHeaderText: {
    fontSize: 18,
    lineHeight: 22,
    color: '#04B7D6',
    padding: 5,
    textTransform: 'uppercase',
  },
  instructionContainer: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  instructionText: {
    paddingHorizontal: 10,
  },
  roundText: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: '700',
  },
  tabataText: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  cyanText: {
    color: 'cyan',
  },
  restTimeText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'cyan',
  },
  backButton: {
    marginTop: 10,
  },
  roundHeader: {
    height: 40,
    backgroundColor: 'wallpaper',
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  restHeader: {
    height: 50,
    backgroundColor: 'darker-blue',
    justifyContent: 'center',
    paddingHorizontal: 9,
    marginBottom: 2,
  },
  action: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 24,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: 'black',
    paddingTop: 15,
  },
  buttonContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 3,
    borderColor: 'blue-secondary',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButtonContainer: {
    borderWidth: 0,
    backgroundColor: 'cyan',
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'blue-secondary',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export default observer(SweatStartDetailInstructions);
