import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useMemo } from 'react';
import { useNativeStore } from '../../stores';
import MediaFileDownloader from './MediaFileDownloader';

type Props = {};

const ThriveMediaStorageObserver: React.FC<Props> = (props) => {
  const { mediaStorage } = useNativeStore();
  const { thrive } = useStore();

  const mediaFiles = useMemo(
    () => [
      ...Array.from(thrive.thriveExercises.values()).map((exercise) =>
        mediaStorage.getOrCreateMediaFile(exercise, 'video'),
      ),
    ],
    [thrive.thriveExercises.size],
  );

  return <MediaFileDownloader mediaFiles={mediaFiles} />;
};

export default observer(ThriveMediaStorageObserver);
