import { Icon, Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Image, ImageStyle, Linking, StyleProp, Text, View, TouchableOpacity } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { Pressable } from 'react-native-web-hover';
import { MessageModel, MessageFileModel } from '../../../o2x-store/src/models/Message';
import config from '../../../o2x-store/src/config';
import { useStore } from '../../../o2x-store/src/stores';

type Props = {
  message: MessageModel;
  file?: MessageFileModel;
};

const Message: React.FC<Props> = ({ message, file }) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const authUserId = store.auth.user?.id;
  const onLinkPress = async (url: string) => {
    if (await Linking.canOpenURL(url)) window.open(url, '_blank')?.focus();
  };
  const onDownloadFile = useCallback(async (id: number) => {
    window.open(`${config.urls.api}${config.urls.contactFiles}download/${id}`);
  }, []);
  return (
    <Layout style={styles.messageContainer}>
      <Pressable>
        {({ hovered }) => (
          <>
            <Text style={message.sender === authUserId ? styles.senderDate : styles.receiverDate}>
              {message.group && (message.senderDetails.fullName || message.senderDetails.email)}
              {hovered && `\n${format(parseISO(message.created), 'yyyy-MM-dd H:mma')}`}
            </Text>
            {message.sender === authUserId ? (
              file ? (
                <TouchableOpacity
                  style={styles.senderFile}
                  onPress={async () => {
                    await onDownloadFile(file.id);
                  }}>
                  <Icon style={styles.downloadIcon} name="download-outline" fill="olive" />
                  <Text style={styles.fileName}>{file.name}</Text>
                </TouchableOpacity>
              ) : (
                <Hyperlink onPress={(url) => onLinkPress(url)} linkStyle={styles.linkStyle}>
                  <Text style={styles.senderMessage}>{message.body}</Text>
                </Hyperlink>
              )
            ) : (
              <Layout style={styles.messageContent}>
                <Image
                  style={styles.profileHeader as StyleProp<ImageStyle>}
                  source={
                    message.senderDetails?.profileImage &&
                    message.senderDetails?.profileImage.length > 0
                      ? { uri: message.senderDetails?.profileImage }
                      : require('../../assets/images/user_placeholder.png')
                  }
                />
                {file ? (
                  <TouchableOpacity
                    style={styles.receiverFile}
                    onPress={async () => {
                      await onDownloadFile(file.id);
                    }}>
                    <Text style={{ color: 'white' }}>{file.name}</Text>
                    <Icon style={styles.downloadIcon} name="download-outline" fill="#091C2D" />
                  </TouchableOpacity>
                ) : (
                  <View style={{ flex: 1 }}>
                    <Hyperlink onPress={(url) => onLinkPress(url)} linkStyle={styles.linkStyle}>
                      <Text style={styles.receiverMessage}>{message.body}</Text>
                    </Hyperlink>
                  </View>
                )}
              </Layout>
            )}
          </>
        )}
      </Pressable>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  receiverMessage: {
    marginRight: 'auto',
    color: 'white',
    padding: 10,
    backgroundColor: 'olive',
    borderRadius: 5,
    flexDirection: 'row',
  },
  receiverFile: {
    flexDirection: 'row',
    gap: 5,
    marginRight: 'auto',
    padding: 10,
    backgroundColor: 'olive',
    borderRadius: 5,
  },
  receiverDate: {
    textAlign: 'left',
    marginTop: '2%',
    marginBottom: 2,
    color: '#8F9BB3',
    fontSize: 12,
  },
  senderMessage: {
    marginLeft: 'auto',
    marginBottom: '2%',
    marginRight: 4,
    color: 'white',
    padding: 10,
    backgroundColor: '#091C2D',
    borderRadius: 5,
  },
  senderFile: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginBottom: '2%',
    marginRight: 4,
    padding: 10,
    backgroundColor: '#091C2D',
    borderRadius: 5,
  },
  fileName: {
    color: 'olive',
  },
  senderDate: {
    textAlign: 'right',
    marginTop: '2%',
    marginBottom: 2,
    color: '#8F9BB3',
    fontSize: 12,
  },
  linkStyle: {
    padding: 0,
    fontSize: 16,
    color: '#ADD8E6',
  },
  messageContainer: {
    flexDirection: 'column',
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  profileHeader: {
    height: 20,
    width: 20,
    borderRadius: 20,
    alignSelf: 'flex-end',
  },
  messageContent: {
    gap: 3,
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  downloadIcon: {
    width: 15,
    height: 15,
    padding: 1,
    marginRight: 5,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    justifyContent: 'center',
  },
});

export default observer(Message);
