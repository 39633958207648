import { getStateFromPath, NavigationContainer } from '@react-navigation/native';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import * as Localization from 'expo-localization';
import { observer } from 'mobx-react-lite';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { TASK_COMPONENT_TYPE, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import DatePicker from 'src/components/DatePicker';
import ReadinessAssessmentResult from 'src/containers/ReadinessAssessment/ReadinessAssessmentResult';
import Loading from '../../components/Loading';
import config from '../../config';
import ActiveList from '../ActiveList';
import AskAnExpert from '../AskAnExpert';
import AskAnExpertDrawer from '../AskAnExpert/AskAnExpertDrawer';
import AssessmentSectionMenu from '../AssessmentDetail/AssessmentSectionMenu';
import FormAssessmentDetail from '../AssessmentDetail/FormAssessmentDetail';
import FormAssessmentSectionDetail from '../AssessmentDetail/FormAssessmentSectionDetail';
import PhysicalAssessmentDetail from '../AssessmentDetail/PhysicalAssessmentDetail';
import PreassessmentForm from '../AssessmentDetail/PreassessmentForm';
import AssessmentHistory from '../AssessmentHistory';
import AssessmentPreview from '../AssessmentHistory/AssessmentPreview';
import AssessmentScoringDisplay from '../AssessmentHistory/AssessmentScoringDisplay';
import AssessmentSubmission from '../AssessmentSubmission';
import AssessmentSummary from '../AssessmentSummary';
import DownloadedBreathe from '../Downloaded/DownloadedBreathe';
import DownloadEdit from '../Downloaded/DownloadEdit';
import DownloadedMeditate from '../Downloaded/DownloadedMeditate';
import DownloadedPreview from '../Downloaded/DownloadedPreview';
import DownloadedProgram from '../Downloaded/DownloadedProgram';
import DownloadedSleep from '../Downloaded/DownloadedSleep';
import DownloadedSweatProgram from '../Downloaded/DownloadedSweatProgram';
import DownloadedThriveProgram from '../Downloaded/DownloadedThriveProgram';
import DownloadedWorkout from '../Downloaded/DownloadedWorkout';
import DownloadList from '../Downloaded/DownloadList';
import EatStart from '../EatStart';
import EventCheckin from '../Events/EventCheckin';
import EventDetail from '../Events/EventDetail';
import EventJoin from '../Events/EventJoin';
import EventListEdit from '../Events/EventListEdit';
import EventRelease from '../Events/EventRelease';
import EventSignature from '../Events/EventSignature';
import EventSpecialistSurvey from '../Events/EventSpecialistSurvey';
import EventTerms from '../Events/EventTerms';
import EventWaiver from '../Events/EventWaiver';
import ExploreFilter from '../ExploreFilter';
import AssignedTask from '../FTEUser/AssignedTask';
import CalendarFte from '../FTEUser/CalendarFte';
import ContactFte from '../FTEUser/ContactFte';
import DashboardFte from '../FTEUser/DashboardFte';
import FteEventDetails from '../FTEUser/FteEventDetails';
import MessageListFte from '../FTEUser/MessageListFte';
import ResetPassword from '../Landing/ResetPassword';
import ResetPasswordConfirm from '../Landing/ResetPasswordConfirm';
import ProfileEdit from '../More/ProfileEdit';
import Organization from '../Organization';
import JoinOrganization from '../Organization/JoinOrganization';
import PayWall from '../PayWall';
import BodyCompAssessmentResult from '../ReadinessAssessment/BodyCompAssessmentResult';
import HPSurveyAssessmentResult from '../ReadinessAssessment/HPSurveyAssessmentResult';
import MobilityCapacityAssessmentResult from '../ReadinessAssessment/MobilityCapacityAssessmentResult';
import Saved from '../Saved';
import ListDelete from '../Saved/ListDelete';
import ListDetail from '../Saved/ListDetail';
import ListEdit from '../Saved/ListEdit';
import PlansEdit from '../Saved/PlansEdit';
import SaveToList from '../Saved/SaveToList';
import SweatContinue from '../SweatContinue';
import SweatOptions from '../SweatOptions';
import SweatOptionsJump from '../SweatOptions/SweatOptionsJump';
import SweatOptionsQuit from '../SweatOptions/SweatOptionsQuit';
import SweatStart from '../SweatStart';
import SweatStartDetail from '../SweatStart/SweatStartDetail';
import SweatStartDetailInstruction from '../SweatStart/SweatStartDetailInstruction';
import SweatStartFTEComment from '../SweatStart/SweatStartFTEComment';
import SweatStartFTEVideo from '../SweatStart/SweatStartFTEVideo';
import SweatStartWorkoutDetails from '../SweatStart/SweatStartWorkoutDetails';
import SweatWorkoutComplete from '../SweatWorkoutComplete';
import TeamActivity from '../Teams/TeamActivity';
import TeamAssessment from '../Teams/TeamAssessment';
import TeamChallenge from '../Teams/TeamChallenge';
import TeamDelete from '../Teams/TeamDelete';
import TeamDetail from '../Teams/TeamDetail';
import TeamDetailInvite from '../Teams/TeamDetailInvite';
import TeamEdit from '../Teams/TeamEdit';
import TeamInvite from '../Teams/TeamInvite';
import ThriveActivityComplete from '../ThriveActivityComplete';
import ThriveBreathe from '../ThriveBreathe';
import ThriveMeditate from '../ThriveMeditate';
import ThriveOptions from '../ThriveOption';
import ThriveOptionsJump from '../ThriveOption/ThriveOptionsJump';
import ThriveOptionsQuit from '../ThriveOption/ThriveOptionsQuit';
import ThriveProgramDayComplete from '../ThriveProgramDayComplete';
import ThriveSetTime, { THRIVE_ACTIVITY } from '../ThriveSetTime';
import ThriveSleep from '../ThriveSleep';
import ThriveStart from '../ThriveStart';
import ThriveYoga from '../ThriveYoga';
import ThriveYogaFilter from '../ThriveYoga/ThriveYogaFilter';
import ThriveYogaDetail from '../ThriveYogaDetail';
import AddHabitEat from '../Today/AddHabitEat';
import AddHabitSweat from '../Today/AddHabitSweat';
import AddHabitThrive from '../Today/AddHabitThrive';
import DailyDebriefEat from '../Today/DailyDebriefEat';
import DailyDebriefSweat from '../Today/DailyDebriefSweat';
import DailyDebriefThrive from '../Today/DailyDebriefThrive';
import EatHabit from '../Today/EatHabit';
import EatHabitQuestion from '../Today/EatHabitQuestion';
import EatHabitSummary from '../Today/EatHabitSummary';
import EatProfile from '../Today/EatProfile';
import EatProfileQuestion from '../Today/EatProfileQuestion';
import EatProfileSummary from '../Today/EatProfileSummary';
import FindSweatPlanQuestion from '../Today/FindSweatPlanQuestion';
import FindSweatPlanSummary from '../Today/FindSweatPlanSummary';
import FindThrivePlanQuestion from '../Today/FindThrivePlanQuestion';
import FindThrivePlanSummary from '../Today/FindThrivePlanSummary';
import QuestionEat from '../Today/QuestionEat';
import QuestionSweat from '../Today/QuestionSweat';
import QuestionThrive from '../Today/QuestionThrive';
import ThriveHabitQuestion from '../Today/ThriveHabitQuestion';
import ThriveHabitSummary from '../Today/ThriveHabitSummary';
import ThriveProfile from '../Today/ThriveProfile';
import ThriveProfileQuestion from '../Today/ThriveProfileQuestion';
import ThriveProfileSummary from '../Today/ThriveProfileSummary';
import SummaryDetail from '../User/SummaryDetail';
import Welcome from '../Welcome';
import WorkoutGenerator from '../WorkoutGenerator';
import Workout from '../WorkoutGenerator/Workout';
import WorkoutGeneratorEquipmentsFilter from '../WorkoutGenerator/WorkoutGeneratorEquipmentsFilter';
import WorkoutGeneratorFilter from '../WorkoutGenerator/WorkoutGeneratorFilter';
import WorkoutGeneratorResult from '../WorkoutGenerator/WorkoutGeneratorResult';
import {
  getStateForCreateProfile,
  getStateForEventJoin,
  getStateForEventView,
  getStateForProgramView,
} from './DeepLink';
import NetPromoterStackModal from './modals/NetPromoterStackModal';
import RatingStackModal from './modals/RatingStackModal';
import SweatWorkoutSummaryEditModal from './modals/SweatWorkoutSummaryEditModal';
import AuthStack from './stacks/AuthStack';
import HomeTab from './tabs/HomeTab';

export type AppStackParamList = {
  ReadinessAssessmentResult: { submissionId: number; assessmentId: number };
  AssessmentHistory: { assessmentId: number; type: ASSESSMENT_TYPE };
  AssessmentDetail: { assessmentId: number };
  AssessmentSectionDetail: { assessmentId: number; section: string };
  AssessmentSummary: {
    assessmentId: number;
    currentSubmission?: number;
    type: ASSESSMENT_TYPE;
  };
  AssessmentSectionMenu: { assessmentId: number; type: ASSESSMENT_TYPE };
  PreassessmentForm: { assessmentId: number; type: ASSESSMENT_TYPE };
  Explore: { selected: TASK_TYPE; exploreType?: TASK_COMPONENT_TYPE };
  ExploreFilter: { selected: TASK_TYPE };
  ProgramDetail: { type: TASK_TYPE; id: number };
  ProgramPreview: { type: TASK_TYPE; id: number };
  EatStart: { id: number };
  SweatStart: {
    id: number;
    jumpWorkoutId?: number;
    isFte: boolean;
    fteOwner: number;
  };
  ThriveStart: { id: number; jumpDayId?: number };
  ThriveSetTime: { activity: THRIVE_ACTIVITY };
  WorkoutGeneratorResult: { category?: string; difficulty: string };
  EventJoin: { eventCode?: string };
  SweatContinue: { programId?: number; workoutId: number };
  SweatWorkoutComplete: { programId?: number; workoutId: number };
  ThriveProgramDayComplete: { programId?: number; dayId: number };
  PayWall: { route?: string; params?: { [key: string]: any } };
  SweatOptions: { programId?: number; workoutId: number };
  ThriveOptions: { programId: number; dayId?: number };
  AskAnExpert: { initial?: number };
  VideoModal: { resourceId: number };
  ThriveYogaDetail: { id: number };
  ThriveYogaFilter: { selected: THRIVE_ACTIVITY_TYPE };
  ThriveActivityComplete: { activity: THRIVE_ACTIVITY };
  ThriveApp: { initialDuration?: number | string; item: any };
  SweatWorkoutSummary: { summaryId: number; editingMode?: boolean };
  SweatWorkoutDetail: { id: number };
  SweatWorkoutFTEComment: {
    exercise: SweatExercise;
    step: SweatWorkoutStep;
    stepExercise: SweatWorkoutStepExercise;
    workoutTitle: string;
    workoutId: number;
  };
  SweatWorkoutFTEVideo: {
    exercise: SweatExercise;
    step: SweatWorkoutStep;
    stepExercise: SweatWorkoutStepExercise;
  };
  DailyDebriefQuestion: {
    task?: TASK_TYPE;
    habits?: String[];
    overall?: number;
    debriefData?: any;
  };
};

type Props = {};
const Stack = createStackNavigator();

const getActiveRouteName = (navigationState: any): string | undefined => {
  if (!navigationState) return;
  const { name, state } = navigationState.routes[navigationState.index];
  if (state) {
    return `${name}/${getActiveRouteName(state)}`;
  }
  return name;
};

const AppContainer: React.FC<Props> = (props) => {
  const { auth, user } = useStore();
  console.log('[DEBUG] token', auth && auth.token);
  console.log('[DEBUG] timezone', Localization.timezone);

  if (Platform.OS === 'web') {
    if (!auth.token && window.location.href.includes('user=')) {
      const passedToken = window.location.href.split('user=')[1];
      auth.storeToken(passedToken);
    }
  }

  const onNavigationStateChange = useCallback((state) => {
    analytics.setCurrentScreen(getActiveRouteName(state));
  }, []);

  const linking = {
    prefixes: config.deepLinkPrefixes,
    getStateFromPath: (path: string) => {
      if (path.includes(config.deepLinkUrls.events)) {
        const eventCode = path.split(config.deepLinkUrls.events)[1];
        return getStateForEventJoin(eventCode);
      }
      if (path.includes(config.deepLinkUrls.register)) {
        const registrationCode = path.split(config.deepLinkUrls.register)[1];
        return getStateForCreateProfile(registrationCode);
      }
      if (path.includes(config.deepLinkUrls.eventsView)) {
        const eventId = path.split(config.deepLinkUrls.eventsView)[1].split('/')[1];
        return getStateForEventView(eventId);
      }
      if (path.includes(config.deepLinkUrls.sweatView)) {
        const programId = path.split(config.deepLinkUrls.sweatView)[1].split('/')[1];
        return getStateForProgramView(TASK_TYPE.SWEAT, programId);
      }
      if (path.includes(config.deepLinkUrls.thriveView)) {
        const programId = path.split(config.deepLinkUrls.thriveView)[1].split('/')[1];
        return getStateForProgramView(TASK_TYPE.THRIVE, programId);
      }
      if (path.includes(config.deepLinkUrls.eatView)) {
        const programId = path.split(config.deepLinkUrls.eatView)[1].split('/')[1];
        console.log(programId);
        return getStateForProgramView(TASK_TYPE.EAT, programId);
      }
      return getStateFromPath(path);
    },
  };

  if (!auth || !auth.token) {
    return (
      <NavigationContainer
        linking={linking}
        fallback={<Loading />}
        onStateChange={onNavigationStateChange}>
        <AuthStack />
      </NavigationContainer>
    );
  }

  return (
    <NavigationContainer
      linking={linking}
      fallback={<Loading />}
      onStateChange={onNavigationStateChange}>
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="Home" component={HomeTab} />
        <Stack.Screen
          name="ExploreFilter"
          component={ExploreFilter}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forFadeFromBottomAndroid,
          }}
        />
        <Stack.Screen name="DownloadList" component={DownloadList} />
        <Stack.Screen name="DownloadEdit" component={DownloadEdit} />
        <Stack.Screen name="DownloadedThriveProgram" component={DownloadedThriveProgram} />
        <Stack.Screen name="DownloadedSweatProgram" component={DownloadedSweatProgram} />
        <Stack.Screen name="DownloadedProgram" component={DownloadedProgram} />
        <Stack.Screen name="DownloadedPreview" component={DownloadedPreview} />
        <Stack.Screen name="DownloadedSleep" component={DownloadedSleep} />
        <Stack.Screen name="DownloadedMeditate" component={DownloadedMeditate} />
        <Stack.Screen name="DownloadedBreathe" component={DownloadedBreathe} />
        <Stack.Screen name="DownloadedWorkout" component={DownloadedWorkout} />
        <Stack.Screen name="EatStart" component={EatStart} />
        <Stack.Screen name="SweatStart" component={SweatStart} />
        <Stack.Screen name="SweatStartDetail" component={SweatStartDetail} />
        <Stack.Screen name="SweatStartFTEComment" component={SweatStartFTEComment} />
        <Stack.Screen name="SweatStartFTEVideo" component={SweatStartFTEVideo} />
        <Stack.Screen name="SweatStartDetailInstruction" component={SweatStartDetailInstruction} />
        <Stack.Screen name="SweatStartWorkoutDetails" component={SweatStartWorkoutDetails} />
        <Stack.Screen name="SweatOptions" component={SweatOptions} />
        <Stack.Screen name="SweatOptionsQuit" component={SweatOptionsQuit} />
        <Stack.Screen name="SweatOptionsJump" component={SweatOptionsJump} />
        <Stack.Screen name="SweatWorkoutSummary" component={SweatWorkoutSummaryEditModal} />
        <Stack.Screen name="Welcome" component={Welcome} />
        <Stack.Screen name="DailyDebriefEat" component={DailyDebriefEat} />
        <Stack.Screen name="DailyDebriefSweat" component={DailyDebriefSweat} />
        <Stack.Screen name="DailyDebriefThrive" component={DailyDebriefThrive} />
        <Stack.Screen name="AddHabitEat" component={AddHabitEat} />
        <Stack.Screen name="AddHabitSweat" component={AddHabitSweat} />
        <Stack.Screen name="AddHabitThrive" component={AddHabitThrive} />
        <Stack.Screen name="QuestionEat" component={QuestionEat} />
        <Stack.Screen name="QuestionSweat" component={QuestionSweat} />
        <Stack.Screen name="QuestionThrive" component={QuestionThrive} />
        <Stack.Screen name="EatProfile" component={EatProfile} />
        <Stack.Screen name="EatProfileQuestion" component={EatProfileQuestion} />
        <Stack.Screen
          name="DatePicker"
          component={DatePicker}
          options={{
            animationEnabled: false,
            cardStyle: { backgroundColor: 'transparent' },
          }}
        />

        <Stack.Screen name="EatProfileSummary" component={EatProfileSummary} />
        <Stack.Screen name="EatHabit" component={EatHabit} />
        <Stack.Screen name="EatHabitQuestion" component={EatHabitQuestion} />
        <Stack.Screen name="EatHabitSummary" component={EatHabitSummary} />
        <Stack.Screen name="ThriveProfile" component={ThriveProfile} />
        <Stack.Screen name="ThriveProfileQuestion" component={ThriveProfileQuestion} />
        <Stack.Screen name="ThriveProfileSummary" component={ThriveProfileSummary} />
        <Stack.Screen name="ThriveHabitQuestion" component={ThriveHabitQuestion} />
        <Stack.Screen name="ThriveHabitSummary" component={ThriveHabitSummary} />
        <Stack.Screen name="FindSweatPlanQuestion" component={FindSweatPlanQuestion} />
        <Stack.Screen name="FindSweatPlanSummary" component={FindSweatPlanSummary} />
        <Stack.Screen name="FindThrivePlanQuestion" component={FindThrivePlanQuestion} />
        <Stack.Screen name="FindThrivePlanSummary" component={FindThrivePlanSummary} />

        <Stack.Screen name="AssignedTask" component={AssignedTask} />
        <Stack.Screen name="ContactFte" component={ContactFte} />
        <Stack.Screen name="MessageListFte" component={MessageListFte} />
        <Stack.Screen name="DashboardFte" component={DashboardFte} />
        <Stack.Screen name="CalendarFte" component={CalendarFte} />
        <Stack.Screen name="FteEventDetails" component={FteEventDetails} />

        <Stack.Screen name="ProgramDetail" component={RatingStackModal} />
        <Stack.Screen name="ListEdit" component={ListEdit} />
        <Stack.Screen name="ListDetail" component={ListDetail} />
        <Stack.Screen name="ListDelete" component={ListDelete} />
        <Stack.Screen name="SaveToList" component={SaveToList} />
        <Stack.Screen name="PlansEdit" component={PlansEdit} />
        <Stack.Screen name="ThriveStart" component={ThriveStart} />
        <Stack.Screen name="ThriveSleep" component={ThriveSleep} />
        <Stack.Screen name="ThriveBreathe" component={ThriveBreathe} />
        <Stack.Screen name="ThriveYoga" component={ThriveYoga} />
        <Stack.Screen name="ThriveYogaFilter" component={ThriveYogaFilter} />
        <Stack.Screen name="ThriveYogaDetail" component={ThriveYogaDetail} />
        <Stack.Screen name="ThriveMeditate" component={ThriveMeditate} />
        <Stack.Screen name="ThriveActivityComplete" component={ThriveActivityComplete} />
        <Stack.Screen name="ThriveSetTime" component={ThriveSetTime} />
        <Stack.Screen name="ThriveOptions" component={ThriveOptions} />
        <Stack.Screen name="ThriveOptionsJump" component={ThriveOptionsJump} />
        <Stack.Screen name="ThriveOptionsQuit" component={ThriveOptionsQuit} />
        <Stack.Screen name="SummaryDetail" component={SummaryDetail} />
        <Stack.Screen name="ReadinessAssessmentResult" component={ReadinessAssessmentResult} />
        <Stack.Screen name="BodyCompAssessmentResult" component={BodyCompAssessmentResult} />
        <Stack.Screen name="HPSurveyAssessmentResult" component={HPSurveyAssessmentResult} />
        <Stack.Screen
          name="MobilityCapacityAssessmentResult"
          component={MobilityCapacityAssessmentResult}
        />
        <Stack.Screen name="AssessmentHistory" component={AssessmentHistory} />
        <Stack.Screen name="AssessmentPreview" component={AssessmentPreview} />
        <Stack.Screen name="AssessmentScoringDisplay" component={AssessmentScoringDisplay} />
        <Stack.Screen name="AssessmentSubmission" component={AssessmentSubmission} />
        <Stack.Screen name="AssessmentSectionMenu" component={AssessmentSectionMenu} />
        <Stack.Screen name="PreassessmentForm" component={PreassessmentForm} />
        <Stack.Screen name="FormAssessmentDetail" component={FormAssessmentDetail} />
        <Stack.Screen name="FormAssessmentSectionDetail" component={FormAssessmentSectionDetail} />
        <Stack.Screen name="PhysicalAssessmentDetail" component={PhysicalAssessmentDetail} />
        <Stack.Screen name="AssessmentSummary" component={AssessmentSummary} />
        <Stack.Screen name="ResetPassword" component={ResetPassword} />
        <Stack.Screen name="ResetPasswordConfirm" component={ResetPasswordConfirm} />
        <Stack.Screen name="AskAnExpert" component={AskAnExpert} />
        <Stack.Screen name="EventDetail" component={EventDetail} />
        <Stack.Screen name="EventCheckin" component={EventCheckin} />
        <Stack.Screen name="EventWaiver" component={EventWaiver} />
        <Stack.Screen name="EventRelease" component={EventRelease} />
        <Stack.Screen name="EventTerms" component={EventTerms} />
        <Stack.Screen name="EventJoin" component={EventJoin} />
        <Stack.Screen name="EventSignature" component={EventSignature} />
        <Stack.Screen name="EventListEdit" component={EventListEdit} />
        <Stack.Screen name="EventSpecialists" component={NetPromoterStackModal} />
        <Stack.Screen name="EventSpecialistSurvey" component={EventSpecialistSurvey} />
        <Stack.Screen name="WorkoutGenerator" component={WorkoutGenerator} />
        <Stack.Screen name="WorkoutGeneratorFilter" component={WorkoutGeneratorFilter} />
        <Stack.Screen
          name="WorkoutGeneratorEquipmentsFilter"
          component={WorkoutGeneratorEquipmentsFilter}
        />
        <Stack.Screen name="WorkoutGeneratorResult" component={WorkoutGeneratorResult} />
        <Stack.Screen name="Workout" component={Workout} />
        <Stack.Screen name="SweatContinue" component={SweatContinue} />
        <Stack.Screen name="SweatWorkoutComplete" component={SweatWorkoutComplete} />
        <Stack.Screen name="ThriveProgramDayComplete" component={ThriveProgramDayComplete} />
        <Stack.Screen name="TeamChallenge" component={TeamChallenge} />
        <Stack.Screen name="Organization" component={Organization} />
        <Stack.Screen name="JoinOrganization" component={JoinOrganization} />
        <Stack.Screen name="ProfileEdit" component={ProfileEdit} />
        <Stack.Screen name="Saved" component={Saved} />
        <Stack.Screen name="ActiveList" component={ActiveList} />
        <Stack.Screen name="PayWall" component={PayWall} />
        <Stack.Screen name="TeamDetail" component={TeamDetail} />
        <Stack.Screen name="TeamActivity" component={TeamActivity} />
        <Stack.Screen name="TeamAssessment" component={TeamAssessment} />
        <Stack.Screen name="TeamEdit" component={TeamEdit} />
        <Stack.Screen name="TeamDelete" component={TeamDelete} />
        <Stack.Screen name="TeamInvite" component={TeamInvite} />
        <Stack.Screen name="TeamDetailInvite" component={TeamDetailInvite} />
        <Stack.Screen name="AskAnExpertDrawer" component={AskAnExpertDrawer} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default observer(AppContainer);
