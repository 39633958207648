import { useNavigation } from '@react-navigation/native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get, isUndefined, omitBy, set } from 'lodash';
import { observer } from 'mobx-react-lite';
import User, { UserSetting } from 'o2x-store/src/models/User';
import { clearCache } from 'o2x-store/src/services/api/base';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import CheckBox from '../../components/CheckBox';
import { useNativeStore } from '../../stores';
import { getErrors } from '../../utils/errors';
import SettingsContainer from './SettingsContainer';

type UserData = {
  user: User | undefined;
  values: Partial<UserSetting>;
};

type Field = {
  label: string;
  key: string;
  checked: boolean;
};

const EDITABLE_FIELDS = [
  // 'autoLoadVideos',
  // 'useInternalStorage',
];

type Props = {};

const Data: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { auth, user: userStore } = useStore();
  const { mediaStorage } = useNativeStore();
  const navigation = useNavigation();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [userData, setUserData] = useState<UserData>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const insets = useSafeArea();

  const editableFields = [
    // {
    //  label: 'Auto-load Videos',
    //  key: 'autoLoadVideos',
    //  checked: userData?.values.autoLoadVideos,
    // },
    // {
    //  label: 'Use internal storage',
    //  key: 'useInternalStorage',
    //  checked: userData?.values.useInternalStorage,
    // },
  ];

  useEffect(() => {
    const storedUser = auth.user;
    if (storedUser) {
      const storedUserValues: Partial<UserSetting> = {};
      editableFields.forEach((f) => set(storedUserValues, f.key, get(storedUser.settings, f.key)));
      setUserData({ user: storedUser, values: storedUserValues });
    }
  }, [auth.user]);

  useEffect(() => {
    EDITABLE_FIELDS.forEach((f: string) => {
      register({ name: f }, { required: false });
    });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      if (data) {
        const result = await userStore.updateSettings(omitBy(data, isUndefined));
        setLoading(false);
        if (result.ok) {
        } else {
          setErrorMessage(getErrors(result.errors));
          // Revert
          const storedUserValues: Partial<UserSetting> = {};
          editableFields.forEach((f) =>
            set(storedUserValues, f.key, get(userData?.user?.settings, f.key)),
          );
          setUserData({ user: userData?.user, values: storedUserValues });
        }
      }
    },
    [userData],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const clearResponseCache = useCallback(async () => {
    await mediaStorage.clear();
    clearCache();
    setErrorMessage('');
    setSuccessMessage('Response cache successfully cleared!');
  }, [setSuccessMessage]);

  return (
    <SettingsContainer>
      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      {!!successMessage && (
        <Text style={styles.success} category="label">
          {successMessage}
        </Text>
      )}
      {editableFields.map((f) => (
        <View style={styles.inputContainer} key={f.key}>
          <Text style={styles.inputLabel}>{f.label}</Text>
          <CheckBox
            fill="white"
            key={f.key}
            checked={f.checked || false}
            onChange={(k: string, v: boolean) => {
              setUserData({
                user: userData?.user,
                values: { ...userData?.values, [f.key]: v },
              });
              onSubmit({ [f.key]: v });
            }}
          />
        </View>
      ))}
      <TouchableOpacity onPress={clearResponseCache}>
        <Text style={styles.itemText}>Clear Cache</Text>
      </TouchableOpacity>
    </SettingsContainer>
  );
};

const themedStyles = StyleService.create({
  inputLabel: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  itemText: {
    fontSize: 18,
    letterSpacing: 1,
    color: 'white',
  },
  error: {
    color: 'danger',
    letterSpacing: 1,
    marginBottom: 15,
  },
  success: {
    color: 'blue',
    letterSpacing: 1,
    marginBottom: 15,
  },
});

export default observer(Data);
