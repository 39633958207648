import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import { ImageStyle, StyleProp, TouchableOpacity } from 'react-native';
import ImageLoad from 'react-native-image-placeholder';
import { useMediaQuery } from 'react-responsive';

export type Props = {
  type: TASK_TYPE;
  selected: boolean;
  onSelect: (type: TASK_TYPE) => void;
};

const ExploreTaskTab: React.FC<Props> = (props) => {
  const { type, onSelect, selected = false } = props;
  const styles = useStyleSheet(themedStyles);

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const onPress = useCallback(() => {
    onSelect(type);
  }, [type]);

  return (
    <Layout style={[styles.container, !selected && styles.blur]}>
      <TouchableOpacity
        style={[
          isMobile
            ? styles.exploreTaskMobile
            : isDeviceMaxWidth768
            ? styles.exploreTaskMaxWidth768
            : styles.exploreTask,
          { borderColor: getTaskColor(type) },
        ]}
        onPress={onPress}>
        <Layout
          style={
            isMobile
              ? styles.imageWrapperMobile
              : isDeviceMaxWidth768
              ? styles.imageWrapperMaxWidth768
              : styles.imageWrapper
          }>
          {type === TASK_TYPE.EAT && (
            <ImageLoad
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/eat-explore-taskbar.png')}
              loadingStyle={{ size: 'small', color: '#091C2D' }}
            />
          )}
          {type === TASK_TYPE.SWEAT && (
            <ImageLoad
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/sweat-explore-taskbar.png')}
              loadingStyle={{ size: 'small', color: '#091C2D' }}
            />
          )}
          {type === TASK_TYPE.THRIVE && (
            <ImageLoad
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/thrive-explore-taskbar.png')}
              loadingStyle={{ size: 'small', color: '#091C2D' }}
            />
          )}
        </Layout>
        <Layout style={styles.content} level="2">
          <Text
            numberOfLines={2}
            style={[
              isMobile
                ? styles.exploreTaskTextMobile
                : isDeviceMaxWidth768
                ? styles.exploreTaskTextMaxWidth768
                : styles.exploreTaskText,
              { color: getTaskColor(type) },
            ]}>
            {type}
          </Text>
        </Layout>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
  },
  blur: {
    opacity: 0.65,
  },
  content: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
    marginRight: 4,
    marginVertical: 8,
  },
  exploreTask: {
    height: 160,
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
  },
  exploreTaskMaxWidth768: {
    height: 120,
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
  },
  exploreTaskMobile: {
    height: 80,
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
  },
  imageWrapper: {
    height: 116,
    backgroundColor: 'transparent',
  },
  imageWrapperMaxWidth768: {
    height: 78,
    backgroundColor: 'transparent',
  },
  imageWrapperMobile: {
    height: 50,
    backgroundColor: 'transparent',
  },
  exploreTaskText: {
    fontSize: 24,
    lineHeight: 29,
    textTransform: 'uppercase',
  },
  exploreTaskTextMaxWidth768: {
    fontSize: 20,
    textTransform: 'uppercase',
  },
  exploreTaskTextMobile: {
    fontSize: 12,
    textTransform: 'uppercase',
  },
});

export default observer(ExploreTaskTab);
