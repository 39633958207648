import '@expo/match-media';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Account from '../Account';

type Props = {};

const ResetPasswordConfirm: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { auth } = useStore();
  const navigation = useNavigation();

  const onSignIn = useCallback((listType) => {
    (async () => {
      if (!auth.user) {
        navigation.navigate('SignIn');
      } else {
        navigation.navigate('SettingsAccount');
      }
    })();
  }, []);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Account>
      {isTabletOrMobile ? (
        <Text style={styles.portalTextMobile} category="label">
          Reset Password
        </Text>
      ) : (
        <Text style={styles.portalText} category="h1">
          Reset Password
        </Text>
      )}
      <Layout
        style={
          isMobile
            ? styles.resetPasswordContainerMobile
            : styles.resetPasswordContainer
        }
      >
        <Text style={styles.message}>
          Password reset email sent. Please check your email to reset your
          password.
        </Text>
        <TouchableOpacity onPress={onSignIn} style={styles.button}>
          <Text style={styles.buttonLabel} category="s2">
            Sign in
          </Text>
        </TouchableOpacity>
      </Layout>
    </Account>
  );
};

const themedStyles = StyleService.create({
  resetPasswordContainer: {
    backgroundColor: 'transparent',
    width: '50%',
  },
  resetPasswordContainerMobile: {
    backgroundColor: 'transparent',
    width: '70%',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  button: {
    justifyContent: 'center',
    height: 40,
    marginBottom: 30,
    marginTop: 62,
    backgroundColor: 'dark-blue',
  },
  message: {
    textAlign: 'center',
  },
  portalText: {
    textAlign: 'center',
    letterSpacing: 1,
    marginBottom: '10%',
  },
  portalTextMobile: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 40,
  },
});

export default ResetPasswordConfirm;
