import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Layout, Spinner, StyleService, useStyleSheet } from '@ui-kitten/components';
import { indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import Team from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import TopNav from 'src/components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';
import TeamItem from '../Teams/TeamItem';

type Props = {
  route: RouteProp<AppStackParamList, 'TeamChallenge'>;
  navigation: StackNavigationProp<AppStackParamList, 'TeamChallenge'>;
};

const TeamChallenge: React.FC<Props> = (props) => {
  const {
    route: {
      params: {
        sweatProgramId,
        thriveProgramId,
        sweatWorkoutId,
        physicalAssessmentId,
        formAssessmentId,
        name,
      },
    },
  } = props;
  const store = useStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const [challengedTeams, setChallengedTeams] = useState<Map<string, number>>(new Map());
  const [loading, setLoading] = useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      store.teams.fetchTeams();
      setChallengedTeams(new Map());
    }, [setChallengedTeams]),
  );

  const teamArray = useMemo(
    () => Array.from(store.teams.teams.values()),
    [store.teams.teams.values()],
  );

  const isChallenged = useCallback(
    (item) => indexOf(Array.from(challengedTeams.keys()), `${item.id}`) > -1,
    [challengedTeams],
  );

  const addToChallengedTeams = useCallback(
    (teamId: number, actionId: number) => {
      setChallengedTeams((prev) => new Map(prev.set(`${teamId}`, actionId)));
    },
    [setChallengedTeams],
  );

  const removeFromChallengedTeams = useCallback(
    (teamId: number) => {
      setChallengedTeams((prev) => {
        prev.delete(`${teamId}`);
        return new Map(prev);
      });
    },
    [setChallengedTeams],
  );

  const challengeOrCancel = useCallback(
    async (item) => {
      if (!isChallenged(item)) {
        // Send challenge over api
        const result = await store.teams.sendChallenge(
          item.id,
          sweatProgramId,
          thriveProgramId,
          sweatWorkoutId,
          physicalAssessmentId,
          formAssessmentId,
        );
        if (result.actionId) {
          addToChallengedTeams(item.id, result.actionId);
        }
      } else {
        // Cancel challenge over api
        const result = await store.teams.undoChallenge(item.id, challengedTeams.get(`${item.id}`));
        if (result.ok) {
          removeFromChallengedTeams(item.id);
        }
      }
    },
    [
      addToChallengedTeams,
      removeFromChallengedTeams,
      challengedTeams,
      sweatProgramId,
      thriveProgramId,
      sweatWorkoutId,
      physicalAssessmentId,
      formAssessmentId,
    ],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <Layout style={styles.item}>
        <View style={styles.teamItemContainer}>
          <TeamItem
            team={item}
            onPress={() => {}}
            showCaret={false}
            accessoryRight={
              <TouchableOpacity
                style={styles.actionButton}
                onPress={() => {
                  challengeOrCancel(item);
                }}>
                {isChallenged(item) ? (
                  <Text style={styles.actionButtonText}>Undo</Text>
                ) : (
                  <Text style={styles.actionButtonText}>Challenge</Text>
                )}
              </TouchableOpacity>
            }
          />
        </View>
      </Layout>
    ),
    [challengedTeams, isChallenged],
  );

  const keyExtractor = useCallback((item: Team) => `${item.id}`, []);

  const onClose = useCallback(() => {
    navigation.popToTop();
  }, []);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={styles.modal}>
          <Layout style={styles.container}>
            <Layout style={[styles.navigationContainer, styles.transparentBackground]}>
              <TopNav
                showBack
                showClose={false}
                onBack={onBack}
                style={styles.transparentBackground}
              />
            </Layout>
            <Layout style={styles.headerContainer}>
              <Text style={styles.header} category="h2">
                Challenge Teams
              </Text>
              <Text style={styles.subHeader} category="h3">
                {name}
              </Text>
            </Layout>
            {loading ? (
              <Layout style={styles.loader}>
                <Spinner />
              </Layout>
            ) : (
              <Layout style={styles.items}>
                <FlatList
                  data={teamArray}
                  renderItem={renderItem}
                  ListEmptyComponent={null}
                  keyExtractor={keyExtractor}
                />
              </Layout>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  transparentBackground: {
    backgroundColor: 'transparent',
  },
  challengeButton: {
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 20,
    alignItems: 'center',
  },
  challengeText: {
    color: 'light-gray',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 17,
    textTransform: 'uppercase',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginTop: 30,
  },
  headerContainer: {
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  header: {
    fontSize: 24,
    lineHeight: 28,
    color: 'white',
    textAlign: 'center',
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'blue-secondary',
    lineHeight: 22,
    marginTop: 7,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  teamItemContainer: {
    flex: 2,
  },
  actionButton: {
    paddingVertical: 4,
    paddingHorizontal: 5,
    backgroundColor: 'blue-secondary',
    marginRight: 12,
  },
  actionButtonText: {
    color: 'white',
    textTransform: 'uppercase',
  },
  items: {
    marginBottom: 140,
    height: 480,
  },
});

export default observer(TeamChallenge);
