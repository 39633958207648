import {
  Divider,
  Input,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useRef, useState } from 'react';
import {
  ScrollView,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import WeightPointer from '../../assets/images/weight-pointer.svg';
import InputAccessory from './InputAccessory';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  disabled?: boolean;
}

const MAX_POUNDS = 1000;
const POUND_WIDTH = 7;

const WeightInput: React.FC<Props> = (props) => {
  const { question, onChange, initial, required, disabled = false } = props;

  const styles = useStyleSheet(themedStyles);
  const [value, setValue] = useState(initial ? String(initial) : '');
  const data = [...Array(MAX_POUNDS + 1).keys()].map((i) => i);
  const inputRef = useRef<TextInput>(null);
  const [scalePadding, setScalePadding] = useState(0);

  const onFocusInput = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onChangeText = useCallback((v: string) => {
    setValue(v);
    if (parseFloat(v)) {
      onChange(question.key, parseFloat(v));
    }
  }, []);

  const handleScroll = (event: any) => {
    setValue((event.nativeEvent.contentOffset.x / POUND_WIDTH).toFixed(2));
    if (parseFloat(value)) {
      onChange(question.key, parseFloat(value));
    }
  };

  return (
    <View style={styles.weight}>
      <TouchableWithoutFeedback onPress={onFocusInput}>
        <View style={styles.inputContainer}>
          <Input
            ref={inputRef}
            style={styles.input}
            keyboardType="decimal-pad"
            value={value}
            onChangeText={(value) => onChangeText(value)}
            accessoryRight={
              question.label
                ? () => <InputAccessory label={question.label!} />
                : undefined
            }
          />
          <Divider style={styles.divider} />
        </View>
      </TouchableWithoutFeedback>
      <View style={styles.weightScale}>
        <View
          onLayout={(event) => {
            setScalePadding(event.nativeEvent.layout.width / 2 - 1);
          }}
          style={styles.scale}
        >
          <ScrollView
            horizontal
            contentContainerStyle={{
              justifyContent: 'flex-end',
            }}
            bounces={false}
            onScroll={handleScroll}
            scrollEventThrottle={16}
          >
            <View style={{ width: scalePadding }} />
            {data.map((i) => {
              return (
                <View
                  key={i}
                  style={[
                    i % 10 === 0
                      ? styles.scaleBig
                      : i % 5 === 0
                      ? styles.scaleMedium
                      : styles.scaleSmall,
                    i === MAX_POUNDS && { marginRight: 0 },
                  ]}
                />
              );
            })}
            <View style={{ width: scalePadding }} />
          </ScrollView>
        </View>
        <View style={styles.weightPointer}>
          <WeightPointer />
        </View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  weight: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
  },
  weightScale: {
    width: '100%',
    height: 109,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  weightPointer: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  inputContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginBottom: 24,
  },
  scale: {
    width: '100%',
    position: 'absolute',
    top: 1,
    left: 0,
    right: 0,
    bottom: 0,
  },
  scaleBig: {
    width: 1,
    height: 109,
    marginRight: 6,
    backgroundColor: 'white',
  },
  scaleMedium: {
    width: 1,
    height: 71,
    marginRight: 6,
    backgroundColor: 'white',
  },
  scaleSmall: {
    width: 1,
    height: 55,
    marginRight: 6,
    backgroundColor: 'white',
  },
  divider: {
    backgroundColor: 'gray',
    width: '100%',
    height: 1,
    marginTop: 0,
  },
  input: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
});

export default WeightInput;
