import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TeamAssessment } from 'o2x-store/src/models/Team';
import React from 'react';
import { Image, ImageStyle, StyleProp, View } from 'react-native';
import DefaultAssessmentImage from '../../assets/images/default-assessment.svg';

type Props = {
  assessment: TeamAssessment;
};

const TeamAssessmentItem: React.FC<Props> = (props) => {
  const { assessment } = props;
  const styles = useStyleSheet(themedStyles);
  return (
    <Layout style={styles.container}>
      {assessment.assessmentImageUrl ? (
        <Layout style={styles.thumbnailWrapper}>
          <Image
            style={styles.thumbnail as StyleProp<ImageStyle>}
            source={{ uri: assessment.assessmentImageUrl }}
          />
        </Layout>
      ) : (
        <Layout style={styles.thumbnailWrapper}>
          <DefaultAssessmentImage style={styles.thumbnail} />
        </Layout>
      )}
      <View style={styles.content}>
        <Text style={styles.title} numberOfLines={2}>
          {assessment.assessmentName}
        </Text>
        <Layout style={styles.scoreContainer}>
          <Text style={styles.score}>{assessment.score || ''}</Text>
          <Text style={styles.maxScore}>/{assessment.maxScore || 'N/A'}</Text>
        </Layout>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 100,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
    marginBottom: 12,
  },
  content: {
    flex: 1,
    height: 100,
    paddingHorizontal: 12,
    backgroundColor: 'transparent',
    justifyContent: 'space-evenly',
  },
  maxScore: {
    fontSize: 18,
    lineHeight: 22,
    color: 'blue-secondary',
  },
  score: {
    fontSize: 36,
    lineHeight: 43,
    color: 'blue-secondary',
  },
  scoreContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
  },
  thumbnailWrapper: {
    width: 100,
    height: 100,
  },
  title: {},
  actionText: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    color: 'blue-secondary',
  },
});

export default TeamAssessmentItem;
