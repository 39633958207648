import { createStackNavigator } from '@react-navigation/stack';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React from 'react';
import ProgramDetail from '../../ProgramDetail';
import ProgramPreview from '../../ProgramDetail/Preview';
import RatingModal from '../../RatingModal';

export type RatingModalParamList = {
  RatingModal: { type: TASK_TYPE; id: number };
};

type Props = {};

const Stack = createStackNavigator();

const RatingStackModal: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
    }}
    headerMode="none"
    mode="modal"
  >
    <Stack.Screen name="ProgramDetail" component={ProgramDetail} />
    <Stack.Screen name="ProgramPreview" component={ProgramPreview} />
    <Stack.Screen name="RatingModal" component={RatingModal} />
  </Stack.Navigator>
);

export default RatingStackModal;
