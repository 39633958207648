import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { isFinite, isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Pause from '../../assets/images/pause.svg';
import Play from '../../assets/images/play.svg';
import Stop from '../../assets/images/stop.svg';
import TimerStart, { TIMER_TYPE } from '../../models/TimerStart';
import { getTimeDisplay } from '../../utils/timeDisplay';

type Props = {
  type: TIMER_TYPE;
  timeInSeconds?: number;
  onStopTimer: (timeSpent: number) => void;
  timerStart?: TimerStart;
};

const Timer: React.FC<Props> = (props) => {
  const { type, timeInSeconds, onStopTimer, timerStart } = props;
  const styles = useStyleSheet(themedStyles);

  if (!timerStart) {
    return null;
  }

  useEffect(() => {
    timerStart.reset();
    timerStart.setType(type);
    if (type === TIMER_TYPE.TIMER && !isUndefined(timeInSeconds)) {
      timerStart.setDefaultTime(timeInSeconds);
    }
  }, [type, timeInSeconds]);

  const onStop = useCallback(() => {
    timerStart.stop();
    onStopTimer(timerStart.timeDisplay);
    timerStart.reset();
  }, [timerStart, onStopTimer]);

  const onStart = useCallback(() => {
    timerStart.start();
  }, [timerStart]);

  const onPause = useCallback(() => {
    timerStart.pause();
  }, [timerStart]);

  console.log('Rendering Timer');

  return (
    <Layout style={styles.container}>
      {isFinite(timerStart.countDown) ? (
        <Layout style={styles.timerContainer}>
          <View style={styles.timerButton}>
            <Text style={styles.timer}>
              {getTimeDisplay(timerStart.timeDisplay)}
            </Text>
          </View>
        </Layout>
      ) : (
        <Layout style={styles.timerContainer}>
          <Text style={styles.timer}>∞</Text>
        </Layout>
      )}
      {timerStart.loading ? (
        <Layout style={styles.actionContainer}>
          <Layout style={styles.actionButton}>
            <Spinner />
          </Layout>
        </Layout>
      ) : (
        <Layout style={styles.actionContainer}>
          <TouchableOpacity style={styles.actionButton} onPress={onStop}>
            <Stop style={styles.actionIcon} />
          </TouchableOpacity>
          {timerStart.playing ? (
            <TouchableOpacity style={styles.actionButton} onPress={onPause}>
              <Pause style={styles.actionIcon} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[styles.actionButton, styles.playButton]}
              onPress={onStart}
            >
              <Play style={styles.actionIcon} />
            </TouchableOpacity>
          )}
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actionButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#203950',
  },
  actionContainer: {
    flexDirection: 'row',
    height: 48,
    backgroundColor: '#203950',
  },
  actionIcon: {
    height: 24,
    width: 24,
  },
  addTimeButton: {
    alignItems: 'center',
    padding: 8,
  },
  container: {
    alignSelf: 'stretch',
    width: '100%',
    backgroundColor: '#203950',
  },
  playButton: {
    backgroundColor: '#203950',
  },
  spacer: {
    width: 62.5,
  },
  timer: {
    fontSize: 56,
    lineHeight: 67,
    textAlign: 'center',
  },
  timerButton: {
    flex: 1,
  },
  timerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#203950',
  },
});

export default observer(Timer);
