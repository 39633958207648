import { decamelizeKeys } from 'humps';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchTutorialsForToday = async (token: string) =>
  callApiWithToken(`${config.urls.tutorials}popup-containers/for_today/`, token, 'GET');

export const fetchPopups = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.tutorials}popups/`, token, 'GET');

export const markPopupAsSeen = async (token: string, popupId: number, forLater: boolean) =>
  callApiWithToken(
    `${config.urls.tutorials}popups/${popupId}/mark_seen/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(decamelizeKeys({ forLater })),
  );

export const resetTutorial = async (token: string) =>
  callApiWithToken(`${config.urls.tutorials}popup-containers/reset_tutorial/`, token, 'POST');
