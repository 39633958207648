import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get, isNumber } from 'lodash';
import moment from 'moment';
import { ACTIVITY_CTA_TYPE, TeamActivity } from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import { getActivityColor } from 'o2x-store/src/utils/teams';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Image,
  ImageStyle,
  LayoutRectangle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useIsSubscribed } from 'src/hooks/subscription';
import ReactionIcon from '../../assets/images/reaction.svg';
import RatingBar from '../RatingDetail/RatingBar';

type Props = {
  activity: TeamActivity;
  onSelectEmoji: (measurements: LayoutRectangle) => void;
  onViewReactions: () => void;
};

// measurements from
// https://github.com/browniefed/examples/blob/general/measure/measure/index.android.js

const TeamActivityItem: React.FC<Props> = (props) => {
  const { activity, onSelectEmoji, onViewReactions } = props;
  const activityData = activity.data;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const { navigateWithPayWall } = useIsSubscribed();

  const [measurements, setMeasurements] = useState<LayoutRectangle>();
  const reactionRef = useRef<TouchableOpacity>(null);

  const isTablet = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const humanizedTimestamp = useMemo(
    () => moment.duration(moment(activity.timestamp) - moment()).humanize(),
    [activity.timestamp],
  );

  const reactionCount = useMemo(() => {
    const reactions = get(activityData, 'reactions', {});
    if (reactions) {
      return get(reactions, 'total', 0);
    }
    return 0;
  }, [activityData]);

  const onStartSweatProgram = useCallback(
    async (programId: string | null) => {
      if (programId) {
        const result = await store.sweat.fetchSweatProgramsByIds([programId]);
        if (result.ok) {
          navigateWithPayWall('SweatStart', { id: programId });
        }
      }
    },
    [navigateWithPayWall],
  );

  const onStartThriveProgram = useCallback(
    async (programId: string | null) => {
      if (programId) {
        const result = await store.thrive.fetchThriveProgramsByIds([programId]);
        if (result.ok) {
          navigateWithPayWall('ThriveStart', { id: programId });
        }
      }
    },
    [navigateWithPayWall],
  );

  const onStartSweatWorkout = useCallback(
    async (workoutId: string | null) => {
      if (workoutId) {
        const result = await store.sweat.fetchSweatWorkoutByIds([workoutId]);
        if (result.ok) {
          navigateWithPayWall('SweatContinue', { workoutId });
        }
      }
    },
    [navigateWithPayWall],
  );

  const onStartPhysicalAssessment = useCallback(
    async (assessmentId: string | null) => {
      if (assessmentId) {
        const result = await store.assessment.fetchPhysicalAssessmentByIds([assessmentId]);
        if (result.ok) {
          navigateWithPayWall('Plan', {
            screen: 'AssessmentHistory',
            params: {
              assessmentId,
              type: 'physical',
            },
          });
        }
      }
    },
    [navigateWithPayWall],
  );

  const onStartFormAssessment = useCallback(
    async (assessmentId: string | null) => {
      if (assessmentId) {
        const result = await store.assessment.fetchFormAssessmentByIds([assessmentId]);
        if (result.ok) {
          navigateWithPayWall('Plan', {
            screen: 'AssessmentHistory',
            params: {
              assessmentId,
              type: 'form',
            },
          });
        }
      }
    },
    [navigateWithPayWall],
  );

  const renderCta = useCallback(() => {
    const cta = get(activityData, 'cta');
    if (cta) {
      switch (cta) {
        case ACTIVITY_CTA_TYPE.START_SWEAT_PROGRAM:
          return (
            <View style={styles.ctaContainer}>
              <TouchableOpacity
                style={styles.ctaButton}
                onPress={() => onStartSweatProgram(activity.actionObjectObjectId)}>
                <Text style={styles.ctaText}>Accept</Text>
              </TouchableOpacity>
            </View>
          );
        case ACTIVITY_CTA_TYPE.START_THRIVE_PROGRAM:
          return (
            <View style={styles.ctaContainer}>
              <TouchableOpacity
                style={styles.ctaButton}
                onPress={() => onStartThriveProgram(activity.actionObjectObjectId)}>
                <Text style={styles.ctaText}>Accept</Text>
              </TouchableOpacity>
            </View>
          );
        case ACTIVITY_CTA_TYPE.START_SWEAT_WORKOUT:
          return (
            <View style={styles.ctaContainer}>
              <TouchableOpacity
                style={styles.ctaButton}
                onPress={() => onStartSweatWorkout(activity.actionObjectObjectId)}>
                <Text style={styles.ctaText}>Accept</Text>
              </TouchableOpacity>
            </View>
          );
        case ACTIVITY_CTA_TYPE.START_PHYSICAL_ASSESSMENT:
          return (
            <View style={styles.ctaContainer}>
              <TouchableOpacity
                style={styles.ctaButton}
                onPress={() => onStartPhysicalAssessment(activity.actionObjectObjectId)}>
                <Text style={styles.ctaText}>Accept</Text>
              </TouchableOpacity>
            </View>
          );
        case ACTIVITY_CTA_TYPE.START_FORM_ASSESSMENT:
          return (
            <View style={styles.ctaContainer}>
              <TouchableOpacity
                style={styles.ctaButton}
                onPress={() => onStartFormAssessment(activity.actionObjectObjectId)}>
                <Text style={styles.ctaText}>Accept</Text>
              </TouchableOpacity>
            </View>
          );
        default:
          return null;
      }
    }
  }, [activityData]);

  return (
    <TouchableOpacity
      onPress={() => onSelectEmoji(measurements)}
      style={[
        isTablet ? styles.activityItemTablet : styles.activityItem,
        !!activity.data && {
          borderColor: getActivityColor(get(activityData, 'type')),
        },
      ]}>
      <View style={styles.content}>
        <Image
          style={styles.activityImage as StyleProp<ImageStyle>}
          source={
            activity.actorAvatarUrl
              ? { uri: activity.actorAvatarUrl }
              : require('../../assets/images/user_placeholder.png')
          }
        />
        <View style={styles.contentText}>
          <Text style={styles.activityText}>{activity.description}</Text>
          {get(activityData, 'badge') && (
            <Text style={styles.badge}>{get(activityData, 'badge')}</Text>
          )}
          {isNumber(get(activityData, 'rating', null)) && (
            <RatingBar
              rating={get(activityData, 'rating')}
              style={{ backgroundColor: 'none', marginTop: 4 }}
            />
          )}
        </View>
      </View>
      <View style={styles.timeContainer}>
        <TouchableOpacity
          onPress={onViewReactions}
          style={styles.reactionOverview}
          ref={reactionRef}
          onLayout={({ nativeEvent }) => {
            setMeasurements(nativeEvent.layout);
          }}>
          <ReactionIcon style={styles.reactionIcon} />
          <Text style={styles.reactionText}>{reactionCount}</Text>
        </TouchableOpacity>
        <Text style={styles.time}>{`${humanizedTimestamp} ago`}</Text>
      </View>
      {renderCta()}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  activityItem: {
    backgroundColor: 'dark-blue',
    paddingHorizontal: 20,
    paddingVertical: 20,
    marginHorizontal: 24,
    marginBottom: 12,
    borderLeftWidth: 4,
  },
  activityItemTablet: {
    backgroundColor: 'dark-blue',
    padding: 15,
    marginBottom: 12,
    borderLeftWidth: 4,
  },
  content: {
    flexDirection: 'row',
  },
  contentText: {
    alignItems: 'flex-start',
    flex: 1,
  },
  activityImage: {
    width: 50,
    height: 50,
    borderRadius: 30,
    marginRight: 10,
  },
  activityText: {
    fontSize: 14,
    lineHeight: 17,
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 14,
  },
  time: {
    flex: 1,
    fontSize: 16,
    textAlign: 'right',
  },
  reactionOverview: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 10,
    marginLeft: -10,
  },
  reactionIcon: {
    marginRight: 5,
    textAlign: 'center',
  },
  reactionText: {
    fontSize: 16,
    color: '#E0E0E0',
  },
  badge: {
    backgroundColor: 'olive',
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 20,
    marginTop: 5,
    textAlign: 'center',
  },
  ctaContainer: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: -20,
    marginBottom: -20,
    marginTop: 10,
  },
  ctaButton: {
    flex: 1,
    backgroundColor: 'olive',
    paddingHorizontal: 35,
    paddingVertical: 8,
    height: 33,
  },
  ctaText: {
    textTransform: 'uppercase',
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
});

export default TeamActivityItem;
