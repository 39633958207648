import { useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import RatingBackground from '../../assets/images/rating-background.svg';
import TopNav from '../../components/Question/TopNav';
import Rating from '../../components/Rating';

type Props = {
  route?: any | null;
};

const FindThrivePlanSummary: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const [numBars, setNumBars] = useState<number>(0);
  const [matches, setMatches] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await store.thrive.findThrivePrograms();
      if (!('errors' in results)) {
        setMatches(results);
      }
      setLoading(false);
    })();
    const keys = [...store.question.findThrivePlanQuestions.keys()];
    setNumBars(keys.length);
  }, [setMatches]);

  const goToDetail = useCallback((program) => {
    navigation.navigate('ExploreStack', {
      screen: 'ProgramDetail',
      params: {
        type: TASK_TYPE.THRIVE,
        id: program.id,
      },
    });
  }, []);

  const goToExplore = useCallback(() => {
    navigation.navigate('ExploreStack', {
      screen: 'Explore',
      params: { selected: TASK_TYPE.THRIVE },
    });
  }, []);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onClose={goBack} showClose showBack={false} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <Text style={styles.title}>Find a THRIVE Plan</Text>
              <Text style={styles.description}>
                These are the programs O2X recommends for you. Select one or more to add to your
                plan.
              </Text>
              {loading ? (
                <View style={styles.loader}>
                  <Spinner />
                </View>
              ) : (
                <ScrollView>
                  {matches?.map((match) => (
                    <TouchableOpacity
                      key={`match-${match.id}`}
                      style={styles.inputView}
                      onPress={() => goToDetail(match)}>
                      <View style={styles.inputLabel}>
                        <Image
                          style={styles.image as StyleProp<ImageStyle>}
                          source={{ uri: match.coverImage }}
                        />
                        <View>
                          <Text style={styles.inputName}>{match.name}</Text>
                          <Text style={styles.inputFrequency}>{match.durationDisplay}</Text>
                        </View>
                        {get(
                          store.thrive.thrivePrograms.get(`${match.id}`)?.ratingsBreakdown,
                          'totalRatings',
                          0,
                        ) >= 10 && (
                          <View style={styles.rating}>
                            <RatingBackground style={styles.ratingBackground} />
                            <Rating rating={match.rating} size={8} />
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              )}
            </View>
            <TouchableOpacity style={styles.more} onPress={goToExplore}>
              <Text style={styles.moreText}>Don't see what you like?</Text>
              <Text style={styles.moreTextSub}>Tap here to view more.</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '25%',
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 25,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 25,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: 60,
    width: 60,
    marginRight: 5,
  },
  input: {
    paddingTop: 20,
  },
  inputView: {
    flexDirection: 'row',
    paddingBottom: 9,
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    flex: 1,
    alignItems: 'center',
  },
  inputName: {
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    maxWidth: '90%',
  },
  inputFrequency: {
    fontSize: 10,
    lineHeight: 12,
    color: 'blue-secondary',
  },
  rating: {
    position: 'absolute',
    height: 14,
    width: 90,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  ratingBackground: {
    position: 'absolute',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  more: {
    alignSelf: 'center',
    backgroundColor: 'dark-blue',
    paddingHorizontal: 40,
    paddingVertical: 10,
    alignItems: 'center',
    height: 60,
    width: 245,
    marginBottom: 40,
    marginTop: 40,
  },
  moreText: {
    flex: 1,
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
  moreTextSub: {
    flex: 1,
    fontSize: 14,
    color: 'orange',
    textAlign: 'center',
  },
});

export default observer(FindThrivePlanSummary);
