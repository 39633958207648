import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { range } from 'lodash';
import moment from 'moment';
import { FormAssessmentSummary } from 'o2x-store/src/models/FormAssesment';
import { PhysicalAssessmentSummary } from 'o2x-store/src/models/PhysicalAssessment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  eatMaxScore: number;
  sweatMaxScore: number;
  thriveMaxScore: number;
  summary: FormAssessmentSummary[] | PhysicalAssessmentSummary[];
  isEat?: boolean;
  isSweat?: boolean;
  isThrive?: boolean;
};

// !!!: Month in moment is zero indexed while month in Django is not.
const BarGraph: React.FC<Props> = ({
  eatMaxScore,
  sweatMaxScore,
  thriveMaxScore,
  summary,
  isEat,
  isThrive,
  isSweat,
}) => {
  const styles = useStyleSheet(themedStyles);
  // Add 2 since it's zero indexed and we want to include this month.
  const end = moment().month() + 2;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const barHeight = isMobile ? 200 : 240;

  const isFormAssessment = (
    item: FormAssessmentSummary | PhysicalAssessmentSummary,
  ): item is FormAssessmentSummary => {
    return (item as FormAssessmentSummary) !== undefined;
  };

  return (
    <Layout style={isMobile ? styles.containerMobile : styles.container}>
      {range(end - 5, end).map((month) => {
        const item = summary.find((i) => i.month === month);
        let eatPercentage = 0;
        let sweatPercentage = 0;
        let thrivePercentage = 0;

        if (item && isFormAssessment(item)) {
          const {
            averageEatScore,
            averageSweatScore,
            averageThriveScore,
          } = item;
          eatPercentage = averageEatScore / eatMaxScore;
          sweatPercentage = averageSweatScore / sweatMaxScore;
          thrivePercentage = averageThriveScore / thriveMaxScore;

          if (!isFinite(eatPercentage)) {
            eatPercentage = 0;
          }
          if (!isFinite(sweatPercentage)) {
            sweatPercentage = 0;
          }
          if (!isFinite(thrivePercentage)) {
            thrivePercentage = 0;
          }
        }

        const color = (percent: number): string => {
          if (percent <= 0.49) {
            return '#C64141';
          }
          if (percent <= 0.74) {
            return '#CFAC42';
          }

          if (percent <= 0.89) {
            return '#4E7B89';
          }

          return '#767F6A';
        };

        return (
          <Layout style={styles.item} key={month}>
            <Layout
              style={isMobile ? styles.barContainerMobile : styles.barContainer}
            >
              <Layout
                style={[
                  isMobile ? styles.barMobile : styles.bar,
                  {
                    backgroundColor: isEat ? color(eatPercentage) : '#91C300',
                    height: barHeight * eatPercentage,
                    opacity: isSweat || isThrive ? 0.1 : 1,
                  },
                ]}
              />
              <Layout
                style={[
                  isMobile ? styles.barMobile : styles.bar,
                  {
                    backgroundColor: isSweat
                      ? color(sweatPercentage)
                      : '#04B7D6',
                    height: barHeight * sweatPercentage,
                    opacity: isEat || isThrive ? 0.1 : 1,
                  },
                ]}
              />
              <Layout
                style={[
                  isMobile ? styles.barMobile : styles.bar,
                  {
                    backgroundColor: isThrive
                      ? color(thrivePercentage)
                      : '#FF7A00',
                    height: barHeight * thrivePercentage,
                    opacity: isEat || isSweat ? 0.1 : 1,
                  },
                ]}
              />
            </Layout>
            <Layout style={styles.divider} />
            <Text
              style={[styles.label, isMobile && { marginTop: 14 }]}
              category="c2"
            >
              {/* Subtract 1 since moment is zero indexed. */}
              {moment()
                .month(month - 1)
                .format('MMM')}
            </Text>
          </Layout>
        );
      })}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    backgroundColor: 'blue-secondary',
    width: 12.3,
    marginHorizontal: 2,
  },
  barMobile: {
    backgroundColor: 'blue-secondary',
    width: 8,
    marginHorizontal: 2,
  },
  barContainer: {
    height: 240,
    width: 49,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  barContainerMobile: {
    height: 200,
    width: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    backgroundColor: 'transparent',
  },
  container: {
    height: 310,
    marginTop: 40,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  containerMobile: {
    marginTop: 20,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  divider: {
    height: 2.7,
    alignSelf: 'stretch',
    backgroundColor: '#C4C4C4',
  },
  item: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  label: {
    marginTop: 18,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#C4C4C4',
  },
  spacer: {
    backgroundColor: 'transparent',
  },
});

export default BarGraph;
