import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import SettingsDrawer from '../AppContainer/tabs/SettingsDrawer';

type Props = {};

const Settings: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const openAccount = useCallback(() => {
    navigation.navigate('SettingsAccount');
  }, []);
  const openDisplay = useCallback(() => {
    navigation.navigate('SettingsDisplay');
  }, []);
  const openTeam = useCallback(() => {
    navigation.navigate('SettingsTeam');
  }, []);
  const openData = useCallback(() => {
    navigation.navigate('SettingsData');
  }, []);
  const openNotifications = useCallback(() => {
    navigation.navigate('SettingsNotifications');
  }, []);

  const openAbout = useCallback(() => {
    navigation.navigate('About');
  }, []);

  const renderItem = useCallback(
    ({ item }) => (
      <TouchableOpacity
        style={styles.menuItem}
        key={item.key}
        onPress={item.onPress}
      >
        <Text style={styles.menuText}>{item.name}</Text>
      </TouchableOpacity>
    ),
    [],
  );

  const menuItems = [
    {
      name: 'Account & Security',
      key: 'account',
      onPress: openAccount,
    },
    {
      name: 'Display & Sounds',
      key: 'events',
      onPress: openDisplay,
    },
    {
      name: 'Notifications',
      key: 'notifications',
      onPress: openNotifications,
    },
    {
      name: 'Team Settings',
      key: 'team',
      onPress: openTeam,
    },
    {
      name: 'Data Usage',
      key: 'data',
      onPress: openData,
    },
    {
      name: 'About O2X',
      key: 'about',
      onPress: openAbout,
    },
  ];

  return (
    <>
      {isTabletOrMobile ? (
        <Layout style={styles.container}>
          <Layout style={styles.contentContainer}>
            <View style={styles.header}>
              <Text style={styles.headerText}>Settings</Text>
            </View>
            <FlatList
              style={styles.menu}
              data={menuItems}
              renderItem={renderItem}
              keyExtractor={(item) => `${item.key}`}
            />
          </Layout>
        </Layout>
      ) : (
        <SettingsDrawer />
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  header: {
    marginBottom: 20,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  contentContainer: {
    paddingHorizontal: 15,
    paddingTop: 20,
    flex: 1,
  },
  menuItem: {
    flexDirection: 'row',
    paddingVertical: 15,
    alignItems: 'center',
  },
  menuText: {
    color: 'white',
    fontSize: 16,
  },
  disabledForNow: {
    color: 'gray',
  },
});

export default observer(Settings);
