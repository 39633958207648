import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { Image, ImageStyle, StyleProp, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveOptions'>;
  navigation: StackNavigationProp<AppStackParamList, 'ThriveOptions'>;
};

const ThriveOptionsQuit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { programId },
    },
    navigation,
  } = props;
  const { thrive } = useStore();
  const program = thrive.thrivePrograms.get(`${programId}`);
  const dayId = get(program, 'userProgress.currentDay', null);
  const day = dayId && thrive.thriveProgramDays.get(`${dayId}`);
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  if (!program) {
    return null;
  }
  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onQuit = useCallback(async () => {
    await day?.markComplete();
    await program.markComplete();
    await program.fetch();

    navigation.popToTop();
  }, [program, day]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav style={styles.navigationStyle} onBack={goBack} onClose={goBack} />
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.header}>
                <Text category="h2">Quit Plan</Text>
              </View>
              <Text style={styles.subHeaderText}>Are you sure you want to quit this plan?</Text>
              {false ? (
                <View style={styles.loading}>
                  <Spinner />
                </View>
              ) : (
                <View style={styles.inputView}>
                  <View style={styles.inputLabel}>
                    <Layout style={styles.indicator} />
                    <Image
                      style={styles.image as StyleProp<ImageStyle>}
                      source={{ uri: program.coverImage }}
                    />
                    <View>
                      <Text style={styles.inputName}>{program.name}</Text>
                      <Text style={styles.inputDay}>{day?.dayDisplay}</Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
            <BottomNav
              onBack={goBack}
              backLabel="Cancel"
              nextColor={theme.red}
              onNext={onQuit}
              nextLabel="Quit"
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  actions: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cancelButton: {
    backgroundColor: 'dark-blue',
  },
  quitButton: {
    backgroundColor: 'red',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  navigationStyle: {
    backgroundColor: 'black',
  },
  contentContainer: {
    flex: 1,
    padding: 24,
  },
  completeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completeIcon: {
    marginRight: 12,
  },
  completeText: {
    color: 'gray',
    textTransform: 'uppercase',
  },
  header: {
    marginTop: -24,
    marginBottom: 20,
  },
  optionItem: {
    marginVertical: 20,
  },
  optionText: {
    fontSize: 18,
    lineHeight: 22,
  },
  inputView: {
    flexDirection: 'row',
    paddingVertical: 15,
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    flex: 1,
    alignItems: 'center',
  },
  inputName: {
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
    maxWidth: '100%',
    marginVertical: 5,
  },
  inputDay: {
    fontSize: 10,
    lineHeight: 12,
    color: 'orange',
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 12,
  },
  subHeaderText: {
    fontSize: 18,
    lineHeight: 22,
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'orange',
    width: 4,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
});

export default ThriveOptionsQuit;
