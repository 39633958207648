import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import SweatWorkoutSummaryEdit from 'src/containers/SweatWorkoutSummary';
import DurationPickerModal from 'src/containers/SweatWorkoutSummary/DurationPickerModal';
import SweatWorkoutDetail from 'src/containers/SweatWorkoutSummary/SweatWorkoutDetail';

export type SweatWorkoutSummaryEditModalParamList = {
  DurationPickerModal: { summaryId: number };
};

type Props = {};

const Stack = createStackNavigator();

const SweatWorkoutSummaryEditModal: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
    }}
    headerMode="none"
    mode="modal"
  >
    <Stack.Screen
      name="SweatWorkoutSummaryEdit"
      component={SweatWorkoutSummaryEdit}
    />
    <Stack.Screen name="SweatWorkoutDetail" component={SweatWorkoutDetail} />
    <Stack.Screen name="DurationPickerModal" component={DurationPickerModal} />
  </Stack.Navigator>
);

export default SweatWorkoutSummaryEditModal;
