import { get } from 'lodash';
import {
  AnyModel,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import MediaFile from './MediaFile';

@model('o2x-native/MediaStorage')
export default class MediaStorage extends Model({
  mediaFiles: prop_mapObject(() => new Map<string, MediaFile>()),
}) {
  _getKey = (object: AnyModel) => `${object.$modelType}/${object.getRefId()}`;

  _getUri = (object: AnyModel, propKey: string) => get(object, propKey, null);

  @modelAction
  getOrCreateMediaFile = (object: AnyModel | undefined, propKey: string) => {
    if (!object) {
      return;
    }

    const key = this._getKey(object);
    const uri = this._getUri(object, propKey);

    if (uri === undefined) {
      console.warn(
        '[DEBUG] Invalid prop key passed. Must be a property of the object.',
        getSnapshot(object),
        propKey,
      );
      return;
    }
    if (uri === null) {
      console.log('[DEBUG] object has no media yet', key, propKey);
      return;
    }

    const currentMediaFile = this.mediaFiles.get(key);
    if (currentMediaFile && currentMediaFile.uri === uri) {
      return currentMediaFile;
    }

    const mediaFile = new MediaFile({ key, uri });
    this.mediaFiles.set(key, mediaFile);
    return mediaFile;
  };

  @modelFlow
  downloadStepVideos = _async(function* (this: MediaStorage, steps: any) {
    try {
      yield* _await(
        Promise.all(
          steps.map(async (step: any) => {
            const file = this.getOrCreateMediaFile(step, 'video');
            await file?.downloadMedia();
          }),
        ),
      );
      return true;
    } catch (e) {
      console.warn('[DEBUG]', e);
      return null;
    }
  });

  @modelFlow
  clear = _async(function* (this: MediaStorage) {
    const promises = Array.from(this.mediaFiles.values()).map((f) => f.deleteMedia());
    yield* _await(Promise.all(promises));
  });
}
