import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';

export const ThriveActivityFilterSection = {
  [THRIVE_ACTIVITY_TYPE.YOGA]: [
    {
      key: 'difficulty',
      data: [
        {
          key: 'intermediate',
          label: 'Intermediate',
        },
        {
          key: 'advanced',
          label: 'Advanced',
        },
        {
          key: 'beginner',
          label: 'Beginner',
        },
      ],
    },
  ],
};
