import * as eva from '@eva-design/eva';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { observer } from 'mobx-react-lite';
import { StoreProvider } from 'o2x-store/src/stores';
import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
} from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Loading from './src/components/Loading';
import AppContainer from './src/containers/AppContainer';
import MediaStorageObserver from './src/containers/MediaStorageObserver';
import { useInit } from './src/hooks';
import { NativeStoreProvider } from './src/stores';
import { dark, mapping } from './src/themes';
// console.disableYellowBox = true;

type Props = {};

const App: React.FC<Props> = (props) => {
  const { store, nativeStore, ready } = useInit();

  // Disable font scaling https://stackoverflow.com/a/51414341
  Text.defaultProps = Text.defaultProps || {};
  Text.defaultProps.allowFontScaling = false;
  TextInput.defaultProps = TextInput.defaultProps || {};
  TextInput.defaultProps.allowFontScaling = false;

  if (!ready) {
    return <Loading ready={ready} />;
  }

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <StatusBar barStyle="light-content" />
      <ApplicationProvider {...eva} customMapping={mapping} theme={dark}>
        <SafeAreaProvider>
          <StoreProvider value={store}>
            <NativeStoreProvider value={nativeStore}>
              {Platform.OS !== 'web' && <MediaStorageObserver />}
              <KeyboardAvoidingView
                style={styles.container}
                behavior={Platform.OS === 'ios' ? 'padding' : undefined}
              >
                <ActionSheetProvider>
                  <AppContainer />
                </ActionSheetProvider>
              </KeyboardAvoidingView>
            </NativeStoreProvider>
          </StoreProvider>
        </SafeAreaProvider>
      </ApplicationProvider>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default observer(App);
