import { observable, computed } from 'mobx';
import { getRoot, model, Model, modelFlow, prop, _async, _await, modelAction } from 'mobx-keystone';
import { RootStore } from '.';
import { GroupConversation, MessageModel } from '../models/Message';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';
import config from '../config';

@model('o2x-store/ContactPortalStore')
export default class ContactPortalStore extends Model({
  usersMessages: prop<Array<MessageModel>>(() => []),
  conversation: prop<Array<MessageModel>>(() => []),
  currentUserMessage: prop<MessageModel>(() => ({} as MessageModel)),
  files: prop<File[]>(() => []),
}) {
  @observable
  loading = false;

  @observable
  loadingConversation = false;

  @observable
  _conversationNextURL = '';

  @modelAction
  setUsersMessages = (messages: Array<MessageModel>) => {
    this.usersMessages = [...messages];
  };

  @modelAction
  setConversation = (
    conversation: Array<MessageModel>,
    conversationNextURL: string,
    loadMore?: boolean,
  ) => {
    if (loadMore) this.conversation = [...this.conversation, ...conversation];
    else this.conversation = [...conversation];
    this._conversationNextURL = conversationNextURL;
  };

  @modelAction
  resetConversation = () => {
    this.conversation = [];
    this._conversationNextURL = '';
  };

  @modelAction
  setCurrentUserMessage(userMessage: MessageModel) {
    this.currentUserMessage = userMessage;
  }

  @computed
  get conversationURL() {
    return this.currentUserMessage.group
      ? `${config.urls.conversationStaging}?group=${this.currentUserMessage.group}&limit=20`
      : undefined;
  }

  @computed
  get selectedUserMessageUsername() {
    const rootStore = getRoot<RootStore>(this);
    const userDetails =
      this.currentUserMessage.receiver == rootStore.auth.user?.id
        ? this.currentUserMessage.senderDetails
        : this.currentUserMessage.receiverDetails;
    return this.currentUserMessage.group
      ? this.currentUserMessage.groupDetails
      : userDetails.firstName && userDetails.lastName
      ? `${userDetails.firstName} ${userDetails.lastName}`
      : userDetails.email;
  }

  @computed
  get selectedUserMessageProfileImage() {
    const rootStore = getRoot<RootStore>(this);
    const userDetails =
      this.currentUserMessage.receiver == rootStore.auth.user?.id
        ? this.currentUserMessage.senderDetails
        : this.currentUserMessage.receiverDetails;
    return userDetails.profileImage ? userDetails.profileImage : '';
  }

  @modelFlow
  checkUnread = _async(function* (this: ContactPortalStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.checkUnread(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchMessages = _async(function* (this: ContactPortalStore, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchMyMessages(rootStore.auth.token, url)));
      this.setUsersMessages(entities);
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchConversation = _async(function* (
    this: ContactPortalStore,
    loadMore?: boolean,
    reset?: boolean,
  ) {
    if (loadMore && !this._conversationNextURL) return;
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }
    this.loadingConversation = true;
    if (reset) this.resetConversation();
    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchConversation(
          rootStore.auth.token,
          this.currentUserMessage,
          loadMore ? this._conversationNextURL : this.conversationURL,
        ),
      ));
      this.setConversation(entities.results, entities.next, loadMore);
    } catch (error) {
      console.warn('[DEBUG] error fetching messages', error);
      return getError(error as Error);
    }

    this.loadingConversation = false;
    return getSuccess(entities);
  });

  @modelFlow
  createMessage = _async(function* (this: ContactPortalStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: MessageModel;
    data.sender = rootStore.auth.user?.id!;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createMessages(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createGroupConversation = _async(function* (
    this: ContactPortalStore,
    data: Partial<GroupConversation>,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: GroupConversation;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createGroupConversation(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating group conversation', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  sendEmailMessage = _async(function* (this: ContactPortalStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;
    data.sender = rootStore.auth.user?.id!;
    try {
      yield* _await(api.sendEmailMessage(rootStore.auth.token, data));
    } catch (error) {
      console.warn('[DEBUG] error sending email', error);
      return getError(error as Error);
    }
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markReadMessage = _async(function* (this: ContactPortalStore, data: MessageModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: MessageModel;
    data.isUnread = false;
    try {
      ({
        response: { entities },
      } = yield* _await(api.markReadMessage(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteConversation = _async(function* (
    this: ContactPortalStore,
    data: { user: number; sender: number; receiver: number; group: number },
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: {};
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteConversation(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating message', error);
      return getError(error as Error);
    }

    this.loading = false;
    return getSuccess(entities);
  });
}
