import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const EventRelease: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const today = new Date();
  const event = store.event.events.get(`${id}`);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signatureUri, setSignatureUri] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    register({ name: 'releaseSignature' }, { required: true });
  }, [register]);

  useFocusEffect(
    useCallback(() => {
      store.question.fetchStates();
    }, []),
  );

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const result = await store.event.updateCheckin(id, {
        ...data,
        id: event?.checkin ? event?.checkin.id : null,
      });
      setLoading(false);
      if (result.ok) {
        navigation.goBack();
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [event],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const updateSignature = useCallback((data: string) => {
    setValue('releaseSignature', data);
    navigation.navigate('EventRelease', { id });
  }, []);

  const openSignature = useCallback(() => {
    navigation.navigate('EventSignature', { id, onSubmit: updateSignature });
  }, [event]);

  if (!event) {
    return null;
  }

  const stateOptions = useMemo(
    () => store.question.states.map((state) => [state.value, state.label]),
    [store.question.states],
  );

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack onBack={goToEvent} showClose={false} />
            </View>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isMobile
                    ? styles.contentMobile
                    : isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }>
                <Text style={styles.title}>O2X Image and Video Release</Text>
                <View style={styles.message}>
                  <Text style={styles.textContent}>
                    O2X HUMAN PERFORMANCE, LLC WORKSHOP (“Workshop”), Participant consent to use of
                    image release (the “Release”)
                  </Text>
                  <Text style={styles.textContent}>
                    I hereby grant to O2X Human Performance, LLC (“O2X”) permission to use and
                    publish, reuse and re-publish, photographs or videos taken of me
                    ____________________________, or photographs or videos in which I may be
                    included with others, to copyright them in whole or in part, individually or in
                    conjunction with other photographs or videos, and in conjunction with any
                    printed matter, in its journals, publications, books, promotional material, and
                    in any other media for illustration, promotion, art, or advertising.
                  </Text>
                  <Text style={styles.textContent}>
                    I hereby release and discharge O2X from any and all claims and demands arising
                    out of or in connection with the use of the photographs or videos, including
                    without limitation any and all claims for libel or invasion of privacy.
                  </Text>
                  <Text style={styles.textContent}>
                    This authorization and release shall also inure to the benefit of the legal
                    representatives, licensees, and assigns of O2X.
                  </Text>
                  <Text style={styles.textContent}>
                    I confirm that I am of legal age and have the right to contract in my own name.
                  </Text>
                  <Text style={styles.textContent}>
                    I have read the foregoing and fully understand the contents thereof.
                  </Text>
                  <Text style={styles.textContent}>
                    The release shall be binding upon me and my heirs, legal representatives, and
                    assigns.
                  </Text>
                </View>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                <Text style={styles.label}>Signature:</Text>
                {event?.checkin?.releaseSignature || getValues('releaseSignature') ? (
                  <Image
                    style={styles.signature as StyleProp<ImageStyle>}
                    source={{
                      uri: event?.checkin?.releaseSignature
                        ? event?.checkin?.releaseSignature
                        : getValues('releaseSignature'),
                    }}
                    resizeMode="contain"
                  />
                ) : (
                  <TouchableOpacity style={styles.signatureContainer} onPress={openSignature}>
                    <Text> Signature </Text>
                  </TouchableOpacity>
                )}
                <Text style={styles.today}>Date: {format(today, 'MM/dd/yyyy')}</Text>
              </Layout>
            </ScrollView>
            <BottomNav showBack={false} onNext={onSubmitWrapped} nextLabel="Submit" />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  scroll: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  message: {
    marginBottom: 30,
  },
  textContent: {
    fontSize: 16,
    lineHeight: 19,
    marginTop: 30,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
    marginBottom: 36,
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    marginBottom: 14,
  },
  signatureContainer: {
    backgroundColor: 'white',
    height: 66,
    flex: 1,
    marginBottom: 8,
  },
  signature: {
    width: '100%',
    height: 200,
  },
  input: {
    marginBottom: 32,
  },
  inputLabel: {
    color: 'white',
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: 8,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
});

export default observer(EventRelease);
