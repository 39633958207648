import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { isNumber } from 'lodash';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useNativeStore } from 'src/stores';
import ClockBlue from '../../assets/images/clock-dark-blue.svg';
import PauseIcon from '../../assets/images/pause.svg';
import PlayIcon from '../../assets/images/play.svg';
import ReplayButton from '../../assets/images/replay.svg';
import StopCircle from '../../assets/images/stop-circle.svg';

const readyHighlights = ['READY', '3', '2', '1'];
const timerHighlights = ['00:03', '00:02', '00:01'];

enum WORKOUT_TYPE {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

enum SIDE {
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = {
  workoutType: WORKOUT_TYPE;
  onNext?: () => void;
  onBack?: () => void;
  onSkip?: () => void;
  onRestart?: () => void;
  onStop?: () => void;
  onPlayOrPause?: () => void;
  onIncreaseRound?: () => void;
  onDecreaseRound?: () => void;
  isPlaying?: boolean;
  isResting?: boolean;
  isCountingDown?: boolean;
  time: number;
  totalTime: number;
  rounds: number | null;
  showRounds?: boolean;
  isRoundsComplete?: boolean;
  side: SIDE | null;
  currentSet: number;
};

const DLWorkoutBottomSheet: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { sweatStart } = useNativeStore();

  const color = getTaskColor(TASK_TYPE.SWEAT);

  const {
    workoutType,
    isPlaying,
    isResting,
    time,
    totalTime,
    rounds,
    isCountingDown,
    showRounds,
    isRoundsComplete,
    side,
    currentSet,
    onNext,
    onBack,
    onSkip,
    onRestart,
    onStop,
    onPlayOrPause,
    onIncreaseRound,
    onDecreaseRound,
  } = props;

  const timeString = useMemo(() => {
    if (isRoundsComplete) {
      return `${rounds}`;
    }
    if (time < 0) {
      if (time < -9) return 'READY';
      if (time > -4) sweatStart.playBeep();
      return `${-time}`;
    }
    const seconds = time % 60;
    const minutes = Math.floor(time / 60);
    const minutesString = `${minutes > 9 ? '' : '0'}${minutes}`;
    const secondsString = `${seconds > 9 ? '' : '0'}${seconds}`;
    const timeString = `${minutesString}:${secondsString}`;
    if (isCountingDown && timerHighlights.includes(timeString)) {
      sweatStart.playBeep();
    }
    return timeString;
  }, [time, isCountingDown, isRoundsComplete, rounds]);

  const totalTimeString = useMemo(() => {
    const seconds = totalTime % 60;
    const minutes = Math.floor(totalTime / 60);
    const minutesString = `${minutes > 9 ? '' : '0'}${minutes}`;
    const secondsString = `${seconds > 9 ? '' : '0'}${seconds}`;
    return `${minutesString}:${secondsString}`;
  }, [totalTime]);

  return (
    <View style={styles.container}>
      {showRounds && (
        <Text style={[styles.rounds, isRoundsComplete ? {} : { color }]} category="c2">
          {isRoundsComplete
            ? 'rounds complete'
            : isNumber(rounds)
            ? `${rounds} round${rounds !== 1 ? 's' : ''}`
            : ''}
        </Text>
      )}
      <Text style={[styles.rounds, { color }]} category="c2">
        {`SET ${currentSet}${side ? ` - ${side}` : ''}`}
      </Text>
      <View style={styles.buttons}>
        <TouchableOpacity style={styles.smallButton} onPress={onStop}>
          <StopCircle />
          <Text style={styles.smallLabel}>STOP</Text>
        </TouchableOpacity>
        <View style={styles.timer}>
          <View style={styles.timerContainer}>
            <Text
              numberOfLines={1}
              adjustsFontSizeToFit
              style={[
                styles.time,
                isResting ? styles.rest : {},
                isRoundsComplete
                  ? {}
                  : readyHighlights.includes(timeString) ||
                    (isCountingDown && timerHighlights.includes(timeString))
                  ? { color }
                  : {},
              ]}>
              {timeString}
            </Text>
          </View>
          {workoutType !== WORKOUT_TYPE.SWEAT ? (
            <Text style={styles.type1} category="c1">
              {workoutType}
            </Text>
          ) : (
            <View style={styles.total}>
              <ClockBlue />
              <Text style={styles.type2} category="c1">
                {totalTimeString}
              </Text>
            </View>
          )}
        </View>
        <TouchableOpacity style={styles.smallButton} onPress={onRestart}>
          <ReplayButton style={styles.replay} />
          <Text style={styles.smallLabel}>RESTART</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.buttons}>
        <TouchableOpacity style={styles.circle} onPress={onBack}>
          <Text style={styles.label}>BACK</Text>
        </TouchableOpacity>
        {showRounds && isNumber(rounds) && (
          <TouchableOpacity style={styles.circle} onPress={onDecreaseRound}>
            <Text style={styles.operation}>-</Text>
            <Text style={styles.label}>RND</Text>
          </TouchableOpacity>
        )}
        {!isRoundsComplete && (
          <TouchableOpacity style={styles.action} onPress={onPlayOrPause}>
            {isPlaying ? <PauseIcon fill="white" /> : <PlayIcon fill="white" />}
          </TouchableOpacity>
        )}
        {showRounds && isNumber(rounds) && (
          <TouchableOpacity style={styles.circle} onPress={onIncreaseRound}>
            <Text style={styles.operation}>+</Text>
            <Text style={styles.label}>RND</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity style={styles.circle} onPress={onNext}>
          <Text style={styles.label}>NEXT</Text>
        </TouchableOpacity>
        {workoutType !== WORKOUT_TYPE.SWEAT && (
          <TouchableOpacity style={styles.circle} onPress={onSkip}>
            <Text style={styles.label}>SKIP</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'black',
    paddingBottom: 40,
    paddingTop: 10,
  },
  set: {
    fontSize: 12,
    marginTop: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  circle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    borderWidth: 3,
    borderColor: 'blue-secondary',
    alignItems: 'center',
    justifyContent: 'center',
  },
  operation: {
    fontSize: 12,
    color: 'blue-secondary',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: -4,
  },
  label: {
    fontSize: 12,
    color: 'blue-secondary',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  buttons: {
    marginVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  action: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: 'blue-secondary',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallButton: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallLabel: {
    marginTop: 4,
    fontSize: 10,
    fontWeight: 'bold',
    color: 'light-gray',
    textTransform: 'uppercase',
  },
  type1: {
    fontSize: 12,
    textAlign: 'center',
    color: 'blue-secondary',
    textTransform: 'uppercase',
  },
  type2: {
    fontSize: 12,
    marginLeft: 6,
    textAlign: 'center',
    color: 'blue-secondary',
    textTransform: 'uppercase',
  },
  time: {
    fontSize: 58,
    textAlign: 'center',
    fontWeight: '700',
  },
  timer: {
    width: 180,
  },
  total: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  timerContainer: {
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replay: {
    opacity: 0.85,
  },
  rest: {
    opacity: 0.6,
  },
  rounds: {
    paddingTop: 10,
    marginBottom: -8,
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
});

export default DLWorkoutBottomSheet;
