import { Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { FTESweatWorkoutStepExercise } from '../../../../o2x-store/src/models/FTELibrary';

type Props = {
  exercise: {
    exercise: FTESweatWorkoutStepExercise;
    date: string;
    userNotes: string;
    weightInputs: string[];
    userExerciseSetInputs: string[];
  };
};

const setParams = ['set', 'reps', 'weight', 'distance', 'time', 'rest', 'rpe'];

const ExerciseDetail: React.FC<Props> = ({ exercise }) => {
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Layout>
      <TouchableOpacity style={styles.exerciseHeader} onPress={() => setShowDetails(!showDetails)}>
        <Text style={[styles.textStyle]}>
          Date Completed:
          {exercise.date}
        </Text>
        <TouchableOpacity
          style={{
            marginLeft: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          onPress={() => setShowDetails(!showDetails)}>
          <Icon
            name={showDetails ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}
            style={{ width: 20, height: 20 }}
            fill="white"
          />
        </TouchableOpacity>
      </TouchableOpacity>
      {!!showDetails && (
        <Layout
          style={{
            width: '97%',
            marginLeft: 10,
            backgroundColor: 'white',
            flexDirection: 'row',
          }}>
          <Layout style={{ width: '80%' }}>
            <Layout
              style={{
                width: '98%',
                height: 30,
                flexDirection: 'row',
              }}>
              {[...setParams, 'Weight used', 'Results'].map((param, index) => {
                return (
                  <Layout
                    key={index}
                    style={{
                      width: param === 'Results' ? '15%' : '11%',
                      borderColor: '#1A3248',
                      borderWidth: 1,
                    }}>
                    <Text
                      style={{
                        alignSelf: 'center',
                        fontSize: 12,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                      {param}
                    </Text>
                  </Layout>
                );
              })}
            </Layout>
            {exercise.exercise.exerciseSets.map((set, index) => (
              <Layout
                key={index}
                style={{
                  width: '98%',
                  height: 30,
                  backgroundColor: 'white',
                  flexDirection: 'row',
                }}>
                {setParams.map((param, index) => (
                  <Layout
                    key={index}
                    style={{
                      width: '11%',
                      borderColor: '#1A3248',
                      borderWidth: 1,
                      backgroundColor: '#091C2D',
                    }}>
                    <Text
                      style={{
                        alignSelf: 'center',
                        fontSize: 12,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                      {param === 'set' ? index + 1 : set[param]}
                    </Text>
                  </Layout>
                ))}
                <Layout
                  style={{
                    width: '11%',
                    borderColor: '#1A3248',
                    borderWidth: 1,
                    backgroundColor: '#091C2D',
                  }}>
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: 12,
                      textAlign: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}>
                    {exercise.weightInputs?.find((weight) => weight.exerciseSet === set.id)
                      ? exercise.weightInputs?.find((weight) => weight.exerciseSet === set.id)
                          .weight
                      : ''}
                  </Text>
                </Layout>

                <Layout
                  style={{
                    width: '15%',
                    borderColor: '#1A3248',
                    borderWidth: 1,
                    backgroundColor: '#091C2D',
                  }}>
                  <ScrollView
                    contentContainerStyle={{
                      flexGrow: 1,
                      justifyContent: 'center',
                    }}>
                    <Text
                      style={{
                        alignSelf: 'center',
                        fontSize: 12,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                      {exercise.userExerciseSetInputs?.find(
                        (setInput) => setInput.exerciseSet === set.id,
                      )?.result ?? ''}
                    </Text>
                  </ScrollView>
                </Layout>
              </Layout>
            ))}
          </Layout>

          <Layout
            style={{
              width: '20%',
              minHeight: 30,
              borderColor: '#1A3248',
              borderWidth: 1,
              backgroundColor: '#091C2D',
            }}>
            <Layout
              style={{
                width: '100%',
                height: 30,
                borderColor: '#1A3248',
                borderWidth: 1,
              }}>
              <Text
                style={{
                  alignSelf: 'center',
                  fontSize: 12,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}>
                User Notes
              </Text>
            </Layout>
            <ScrollView>
              <Text style={{ padding: 10, fontSize: 14 }}>{exercise.userNotes}</Text>
            </ScrollView>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};
const themedStyles = StyleService.create({
  setsContainer: {
    marginLeft: '80px',
  },
  setContainer: {
    marginTop: '10px',
  },
  exerciseHeader: {
    width: '97%',
    height: 36,
    backgroundColor: '#203950',
    marginTop: 15,
    marginLeft: 10,
    flexDirection: 'row',
  },
  textStyle: {
    color: 'white',
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 1,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

export default observer(ExerciseDetail);
