export enum QUESTION_TYPES {
  NUMBER = 'number',
  TEXT = 'text',
  RADIO = 'radio',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  MULTISELECT_OTHER = 'multiselect_other',
  SLIDER = 'slider',
  HEIGHT = 'height',
  RATING = 'rating',
  WEIGHT = 'weight',
  TOGGLE = 'toggle',

  // Addtional questions for FormAssessments.
  CHECKBOX = 'checkbox',
  TIME = 'time',
  OPTION_RATING = 'option_rating',
}
