import { Icon, Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { Share, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useIsSubscribed } from 'src/hooks/subscription';
import { useNativeStore } from '../../stores';

type Props = {
  hideWorkoutPreview?: any;
  program?: SweatProgram;
  workout: SweatWorkout;
  onStart?: () => void;
};

const SweatStartActions: React.FC<Props> = (props) => {
  const { hideWorkoutPreview, program, workout, onStart: onStartProp } = props;
  const { sweatStart } = useNativeStore();
  const { sweat, userQuestion, auth } = useStore();
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const { navigateWithPayWall } = useIsSubscribed();

  const { user } = auth;

  const onAskAnExpert = useCallback(async () => {
    let initialTopic;
    await userQuestion.fetchTopics();
    userQuestion.topics.forEach((topic) => {
      if (topic.name === 'SWEAT') {
        initialTopic = topic.id;
      }
    });
    navigateWithPayWall('AskAnExpert', { initial: initialTopic });
  }, [workout, program]);

  const onShare = useCallback(async () => {
    if (program) {
      const shareData = {
        message: program.viewUrl,
        url: program.viewUrl,
        title: program.name,
      };
      try {
        const result = await Share.share(shareData);
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }, [program]);

  const onStart = useCallback(async () => {
    if (!program) {
      workout.startWorkout();
    } else {
      program.startWorkout(workout.id);
    }
    if (!workout.isExercisesReady) {
      await workout.fetch();
    }
    sweatStart.startWorkout(workout, sweat);
    if (onStartProp) {
      onStartProp();
    }
    if (hideWorkoutPreview) {
      hideWorkoutPreview();
    }
  }, [sweat, sweatStart, workout, onStartProp]);

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      {user?.organization && (
        <TouchableOpacity style={styles.button} onPress={onAskAnExpert}>
          <Icon name="question-mark-circle" fill={theme.white} width={24} height={24} />
        </TouchableOpacity>
      )}
      {!!program && (
        <TouchableOpacity style={styles.button} onPress={onShare}>
          <Icon name="share" fill={theme.white} width={24} height={24} />
        </TouchableOpacity>
      )}
      {!hideWorkoutPreview && (
        <TouchableOpacity style={[styles.button, styles.startButton]} onPress={onStart}>
          <Text style={styles.buttonText} category="c1">
            Start Workout
          </Text>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    backgroundColor: 'dark-blue',
  },
  buttonText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  startButton: {
    flex: 2,
    backgroundColor: 'olive',
  },
  startButtonNext: {
    flex: 2,
  },
});

export default SweatStartActions;
