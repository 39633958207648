import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { AssessmentPreviewItem, ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentPreview'>;
};

const AssessmentPreview: React.FC<Props> = (props) => {
  const { assessmentId, type } = props.route.params;
  const { auth, assessment: assessmentStore } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const insets = useSafeArea();

  const [loading, setLoading] = useState(false);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  if (!assessment) {
    return null;
  }

  const renderItem = useCallback(
    ({ item }) => (
      <View style={styles.dayPreview}>
        <Text style={[styles.dayText, type === ASSESSMENT_TYPE.PHYSICAL && styles.dayTextUpper]}>
          {item.title}
        </Text>
        {!!item.description && (
          <View style={styles.listItem}>
            <View style={styles.marker} />
            <Text style={styles.listItemText}>{item.description}</Text>
          </View>
        )}
      </View>
    ),
    [type],
  );

  return (
    <Layout style={styles.container}>
      <View style={styles.navigationContainer}>
        <TopNav style={styles.nav} showClose={false} />
      </View>
      {loading ? (
        <View style={styles.loading}>
          <Spinner />
        </View>
      ) : (
        <View style={styles.scroll}>
          <Layout style={styles.content}>
            <View style={styles.header}>
              <Text category="h2">{assessment.name}</Text>
              <Text style={styles.subtitle} category="c2">
                {`${assessment.generatedPreview.length} ${
                  type === ASSESSMENT_TYPE.FORM
                    ? Humanize.pluralize(assessment.generatedPreview.length, 'Item')
                    : Humanize.pluralize(assessment.generatedPreview.length, 'Exercise')
                }`}
              </Text>
            </View>
            {assessment.generatedPreview && (
              <FlatList
                data={assessment.generatedPreview}
                style={styles.previewContent}
                renderItem={renderItem}
                keyExtractor={(item: AssessmentPreviewItem, idx: number) => `preview-${idx}`}
              />
            )}
          </Layout>
        </View>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    paddingVertical: 0,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingHorizontal: 26,
  },
  rating: {
    marginTop: 30,
  },
  readMore: {
    marginTop: 20,
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 14,
    color: 'gray',
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    marginBottom: 20,
    color: 'blue-secondary',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  previewContent: {
    marginBottom: 30,
  },
  weekText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 25,
    textTransform: 'uppercase',
    paddingHorizontal: 26,
  },
  dayPreview: {
    marginHorizontal: 22,
    marginBottom: 30,
    paddingHorizontal: 26,
  },
  dayText: {
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
  },
  dayTextUpper: {
    fontSize: 18,
    lineHeight: 22,
    color: 'blue-secondary',
    textTransform: 'uppercase',
    marginBottom: 18,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 4,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 7,
  },
  marker: {
    width: 5,
    height: 5,
    backgroundColor: 'blue-secondary',
    top: 6,
  },
  nav: {
    backgroundColor: 'black',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 20,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 120,
  },
});

export default observer(AssessmentPreview);
