import {
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useState } from 'react';
import { Dimensions, ScrollView, Text } from 'react-native';
import RightOutline from '../../assets/images/right-outline.svg';

type Props = {};

const AccountSettings: React.FC<Props> = () => {
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);
  const { user: userStore, task, assessment } = useStore();

  return (
    <>
      {loading ? (
        <Layout style={styles.loader}>
          <Spinner />
        </Layout>
      ) : (
        <ScrollView style={styles.scroll}>
          <Layout style={styles.body}>
            <Layout style={styles.header}>
              <Text style={styles.home}>Home</Text>
              <RightOutline width={8} height={17} viewBox="0 0 8 14" />
              <Text style={styles.title}>Account Settings</Text>
            </Layout>
          </Layout>
        </ScrollView>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  scroll: {
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
    paddingTop: 18,
    paddingHorizontal: 25,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    height: Dimensions.get('window').height,
  },
  header: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
  },
  home: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    paddingRight: 20,
    color: '#697484',
  },
  title: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    paddingLeft: 20,
    color: 'white',
  },
});

export default observer(AccountSettings);
