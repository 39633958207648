import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import EventModel from 'o2x-store/src/models/Event';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import CheckBox from '../../components/CheckBox';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
};

const isWaiverChecked = (event: EventModel): boolean => {
  if (event.checkin && event.checkin.waiverSignature) {
    return true;
  }
  return false;
};

const isReleaseChecked = (event: EventModel): boolean => {
  if (event.checkin && event.checkin.releaseSignature) {
    return true;
  }
  return false;
};

const EventCheckin: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const [event, setEvent] = useState<EventModel | null>();
  const [errorMessage, setErrorMessage] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    // We add event to a state here to reflect changes when checkins are created
    setEvent(store.event.events.get(`${id}`));
  }, [store.event.events]);

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const goToWaiver = useCallback(() => {
    navigation.navigate('EventWaiver', { id });
  }, [event]);

  const goToRelease = useCallback(() => {
    navigation.navigate('EventRelease', { id });
  }, [event]);

  const onSubmit = useCallback(() => {
    if (!event || !isWaiverChecked(event) || !isReleaseChecked(event)) {
      setErrorMessage('Please fill up all necessary requirements');
      return;
    }
    goToEvent();
  }, [event]);

  if (!event) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack showClose={false} />
            </View>
            <Layout
              style={
                isMobile
                  ? styles.contentMobile
                  : isDeviceMaxWidth600
                  ? styles.contentMaxWidth600
                  : styles.content
              }>
              {!!errorMessage && (
                <Text style={styles.error} category="label">
                  {errorMessage}
                </Text>
              )}
              <View style={styles.item}>
                <TouchableOpacity onPress={goToWaiver} style={styles.itemCaption}>
                  <CheckBox
                    style={styles.checkbox}
                    fill="white"
                    key="check-waiver"
                    checked={isWaiverChecked(event)}
                    onChange={() => {}}
                  />
                  <Text style={styles.itemText}>
                    I have read, understand and agree to the terms of the O2X WAIVER.
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.item}>
                <TouchableOpacity onPress={goToRelease} style={styles.itemCaption}>
                  <CheckBox
                    style={styles.checkbox}
                    fill="white"
                    key="check-release"
                    checked={isReleaseChecked(event)}
                    onChange={() => {}}
                  />
                  <Text style={styles.itemText}>
                    I have read, understand and agree to the terms of the O2X IMAGE AND VIDEO
                    RELEASE.
                  </Text>
                </TouchableOpacity>
              </View>
            </Layout>
            <BottomNav showBack={false} onNext={onSubmit} nextLabel="Submit" />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: '25%',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  contentMobile: {
    flexGrow: 1,
    paddingHorizontal: '5%',
    justifyContent: 'center',
  },
  contentMaxWidth600: {
    flexGrow: 1,
    paddingHorizontal: '15%',
    justifyContent: 'center',
  },
  item: {},
  link: {
    fontSize: 14,
    borderBottomWidth: 1,
    borderColor: 'white',
    borderStyle: 'solid',
  },
  itemCaption: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  itemText: {
    marginLeft: 13,
    fontSize: 14,
    lineHeight: 17,
  },
  checkbox: {
    marginTop: 4,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
});

export default observer(EventCheckin);
