import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { FormAssessmentItemExtraData } from 'o2x-store/src/models/FormAssessmentItem';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import { QUESTION_TYPES } from 'o2x-store/src/utils/questions';
import React from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';
import HeightInput from './HeightInput';
import MultiSelectCheckInput from './MultiSelectCheckInput';
import MultiSelectCheckInputOther from './MultiSelectCheckInputOther';
import NumberInput from './NumberInput';
import OptionRatingInput from './OptionRatingInput';
import RadioInput from './RadioInput';
import RatingInput from './RatingInput';
import SelectInput from './SelectInput';
import SliderInput from './SliderInput';
import TextInput from './TextInput';
import TimeInput from './TimeInput';

interface Props {
  style?: StyleProp<ViewStyle>;
  question?: InputQuestionProp;
  onChange: (k: string, v: string | number) => void;
  initial: string | number | null;
  required?: boolean;
  status?: string;
  divider?: boolean;
  subtitle?: boolean;
  fill?: string;
  extraData?: FormAssessmentItemExtraData | null;
}

const Input: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    onChange,
    initial,
    style,
    question,
    required = true,
    status = '',
    divider,
    subtitle = true,
    fill,
    extraData,
  } = props;
  let Component: any;

  if (question) {
    switch (question.type) {
      case QUESTION_TYPES.HEIGHT:
        Component = HeightInput;
        break;
      case QUESTION_TYPES.RADIO:
        Component = RadioInput;
        break;
      case QUESTION_TYPES.TEXT:
        Component = TextInput;
        break;
      case QUESTION_TYPES.NUMBER:
        Component = NumberInput;
        break;
      case QUESTION_TYPES.SLIDER:
        Component = SliderInput;
        break;
      case QUESTION_TYPES.SELECT:
        Component = SelectInput;
        break;
      case QUESTION_TYPES.RATING:
        Component = RatingInput;
        break;
      case QUESTION_TYPES.OPTION_RATING:
        Component = OptionRatingInput;
        break;
      case QUESTION_TYPES.MULTISELECT:
        Component = MultiSelectCheckInput;
        break;
      case QUESTION_TYPES.MULTISELECT_OTHER:
        Component = MultiSelectCheckInputOther;
        break;
      case QUESTION_TYPES.CHECKBOX:
        Component = MultiSelectCheckInput;
        break;
      case QUESTION_TYPES.TIME:
        Component = TimeInput;
        break;
      default:
        console.warn(
          '[DEBUG] question type might not be handled properly. It will default to text input',
          question.type,
        );
        Component = TextInput;
        break;
    }
  }

  return (
    <ScrollView style={[styles.input, style]}>
      {Component && (
        <Component
          question={question}
          onChange={onChange}
          initial={initial}
          required={required}
          status={status}
          divider={divider}
          subtitle={subtitle}
          fill={fill}
          extraData={extraData}
        />
      )}
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  input: { flex: 1, paddingHorizontal: 20 },
});

export default Input;
