import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import OptionRatingInputItem from './OptionRatingInputItem';

type Props = {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  minRating?: number;
  maxRating?: number;
};

const OptionRatingInput: React.FC<Props> = (props) => {
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { minRating = 1, maxRating = 5, onChange, question } = props;
  const stars = Array.from(Array(maxRating), (_, i) => i + 1);
  const [ratingMap, setRatingMap] = useState({});

  useEffect(() => {
    onChange(question.key, JSON.stringify(ratingMap));
  }, [ratingMap, onChange]);

  const onRate = useCallback(
    (optionValue: string, rate: number) => {
      setRatingMap((prevState) => ({ ...prevState, [optionValue]: rate }));
    },
    [setRatingMap],
  );

  return (
    <>
      <ScrollView style={styles.container} contentContainerStyle={styles.container}>
        <Layout style={styles.content}>
          {!!question.label && (
            <Text style={styles.header} category="c1">
              {question.label}
            </Text>
          )}
          {question.options.map((option: any, index: number) => (
            <OptionRatingInputItem key={index} questionOption={option} onChange={onRate} required />
          ))}
        </Layout>
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    flex: 1,
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  header: {
    marginBottom: 12,
  },
});

export default OptionRatingInput;
