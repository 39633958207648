import { CheckBox, Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { FTEOrganizationModel, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useState } from 'react';
import { Image, ImageStyle, ScrollView, StyleProp, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BreatheContent from './BreatheContent';
import EatContent from './EatContent';
import SweatProgramContent from './SweatProgramContent';
import SweatWorkoutContent from './SweatWorkoutContent';
import ThriveExerciseContent from './ThriveExerciseContent';
import ThriveMeditateContent from './ThriveMeditateContent';
import ThriveProgramContent from './ThriveProgramContent';
import ThriveSleepContent from './ThriveSleepContent';

type Props = {
  org?: FTEOrganizationModel;
  user?: User;
  group?: OSSGroup;
  onClose: () => void;
};

const CreateContent: React.FC<Props> = (props) => {
  const { org, user, group, onClose } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [showContent, setShowContent] = useState('');
  const [onNotification, setOnNotification] = useState(true);

  const showCreateContent = (content: string) => {
    setShowContent(content);
  };

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  console.log(org, group, user);
  return (
    <CreateContentWrapper>
      <Layout style={styles.header}>
        <Image
          style={styles.logo as StyleProp<ImageStyle>}
          source={
            org?.logo
              ? { uri: org.logo }
              : user?.profileImage
              ? { uri: user.profileImage }
              : require('../../../assets/images/user_placeholder.png')
          }
        />
        <Text style={styles.headerText}>
          {org ? org.name : group ? group.groupName : user?.fullName ? user.fullName : user?.email}
        </Text>
        {/* disable user progress for now */}
        {/* {!!showProgressButton && (
            <TouchableOpacity
              style={styles.progress}
              onPress={() => setShowProgress(true)}
            >
              <Icon
                name="trending-up-outline"
                fill="#8F9BB3"
                style={styles.icon}
              />
              <Text style={styles.textProgress}>View Progress</Text>
            </TouchableOpacity>
          )} */}
        <CheckBox
          checked={onNotification}
          onChange={(status) => setOnNotification(status)}
          style={{ marginLeft: 'auto' }}>
          {() => (
            <Text
              style={{
                marginLeft: 5,
                marginRight: 5,
                fontSize: 13,
                opacity: 0.8,
              }}>
              Notify {org ? 'Organization' : user ? 'User' : 'Group'}
            </Text>
          )}
        </CheckBox>
        <TouchableOpacity onPress={onClose} style={[styles.close]}>
          <Icon name="close-outline" fill="white" style={styles.icon} />
        </TouchableOpacity>
      </Layout>
      <Layout style={isMobile ? styles.bodyContainerMobile : styles.bodyContainer}>
        <Layout style={isMobile ? styles.selectContainerMobile : styles.selectContainer}>
          <ScrollView style={styles.scrollSelect}>
            <TouchableOpacity style={styles.eatButton} onPress={() => showCreateContent('eat')}>
              <Text style={styles.createText}>EAT</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.sweatButton}
              onPress={() => showCreateContent('sweat-program')}>
              <Text style={styles.createText}>SWEAT PROGRAM</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.sweatButton}
              onPress={() => showCreateContent('sweat-workout')}>
              <Text style={styles.createText}>SWEAT WORKOUT</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.thriveButton}
              onPress={() => showCreateContent('thrive-program')}>
              <Text style={styles.createText}>THRIVE PROGRAM</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.thriveButton}
              onPress={() => showCreateContent('thrive-exercise')}>
              <Text style={styles.createText}>YOGA</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.thriveButton}
              onPress={() => showCreateContent('breathe')}>
              <Text style={styles.createText}>BREATHE</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.thriveButton}
              onPress={() => showCreateContent('thrive-sleep')}>
              <Text style={styles.createText}>THRIVE SLEEP</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.thriveButton}
              onPress={() => showCreateContent('thrive-meditate')}>
              <Text style={styles.createText}>THRIVE MEDITATE</Text>
            </TouchableOpacity>
          </ScrollView>
        </Layout>
        {showContent === 'eat' && (
          <EatContent org={org} user={user} group={group} onNotification={onNotification} />
        )}
        {showContent === 'thrive-program' && (
          <ThriveProgramContent
            org={org}
            user={user}
            group={group}
            onNotification={onNotification}
          />
        )}
        {showContent === 'thrive-exercise' && (
          <ThriveExerciseContent
            org={org}
            user={user}
            group={group}
            onNotification={onNotification}
          />
        )}
        {showContent === 'thrive-sleep' && (
          <ThriveSleepContent org={org} user={user} group={group} onNotification={onNotification} />
        )}
        {showContent === 'thrive-meditate' && (
          <ThriveMeditateContent
            org={org}
            user={user}
            group={group}
            onNotification={onNotification}
          />
        )}
        {showContent === 'sweat-program' && (
          <SweatProgramContent
            org={org}
            user={user}
            group={group}
            onNotification={onNotification}
          />
        )}
        {showContent === 'sweat-workout' && (
          <SweatWorkoutContent
            org={org}
            user={user}
            group={group}
            onNotification={onNotification}
          />
        )}
        {showContent === 'breathe' && (
          <BreatheContent org={org} user={user} group={group} onNotification={onNotification} />
        )}
      </Layout>
    </CreateContentWrapper>
  );
};

const CreateContentWrapper: React.FC = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });
  return (
    <Layout style={styles.container}>
      {isMobile ? (
        <ScrollView style={styles.mobileBg}>{children}</ScrollView>
      ) : (
        <Layout style={styles.bgContainer}>{children}</Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    width: '95%',
    height: '95%',
    marginLeft: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  mobileBg: {
    position: 'absolute',
    width: '87%',
    height: '75%',
    left: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  header: {
    width: '94%',
    height: '8%',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  logo: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 10,
  },
  close: {
    alignSelf: 'center',
    marginRight: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
  bodyContainer: {
    width: '94%',
    height: '90%',
    marginLeft: '20px',
    marginRight: '20px',
    flexDirection: 'row',
  },
  bodyContainerMobile: {
    width: '94%',
    height: '90%',
    marginLeft: '20px',
    marginRight: '20px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectContainer: {
    backgroundColor: '#1A3248',
    width: '20%',
  },
  selectContainerMobile: {
    backgroundColor: '#1A3248',
    width: '100%',
    marginBottom: 15,
  },
  scrollSelect: {
    flexDirection: 'column',
    width: '100%',
  },
  createText: {
    color: 'white',
    fontSize: 14,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 20,
  },
  eatButton: {
    marginTop: 10,
    width: '92%',
    borderLeft: '2px solid #91C300',
    height: '40px',
    marginRight: '20px',
    backgroundColor: '#091C2D',
  },
  sweatButton: {
    marginTop: 10,
    width: '92%',
    borderLeft: '2px solid #04B7D6',
    height: '40px',
    marginRight: '20px',
    backgroundColor: '#091C2D',
  },
  thriveButton: {
    marginTop: 10,
    width: '92%',
    borderLeft: '2px solid #FF7A00',
    height: '40px',
    marginRight: '20px',
    backgroundColor: '#091C2D',
  },
  progress: {
    backgroundColor: '#0B1F35',
    height: 32,
    padding: 5,
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 10,
  },
  textProgress: {
    color: '#8F9BB3',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 16,
    marginLeft: 5,
  },
});

export default observer(CreateContent);
