import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout, { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ReactPlayer from 'react-player';
import MaximizeIcon from '../../assets/images/maximize.svg';
import { useNativeStore } from '../../stores';

type Props = {
  program?: SweatProgram;
};

const SweatStartCurrentMinimized: React.FC<Props> = (props) => {
  const { program } = props;
  const { sweatStart, mediaStorage } = useNativeStore();
  const { sweat } = useStore();
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  let workout: SweatWorkout | undefined;
  let step: SweatWorkoutStep | undefined;
  let stepExercise: SweatWorkoutStepExercise | SweatGlobalStepExercise | undefined;
  let exercise: SweatExercise | undefined;

  ({ workout, step, stepExercise, exercise } = sweatStart);
  const isCircuit = step?.circuitSets !== 1;
  // const exerciseOrder = (step?.exercises || []).findIndex(
  //  (e) => e.id === stepExercise!.id,
  // );
  const exerciseVideoFile = useMemo(
    () => exercise && mediaStorage.getOrCreateMediaFile(exercise, 'video'),
    [exercise],
  );

  const [localUri, setLocalUri] = useState<string | null>(null);
  if (!exercise) {
    return null;
  }

  const onMaximize = useCallback(() => {
    navigation.navigate('SweatStartDetail', {
      programId: program?.id,
    });
  }, [program]);

  useEffect(() => {
    (async () => {
      if (exerciseVideoFile && !exerciseVideoFile.loading) {
        const localUri =
          Platform.OS !== 'web' ? await exerciseVideoFile.downloadMedia() : exerciseVideoFile.uri;
        if (localUri) {
          setLocalUri(localUri);
        }
      }
    })();
  }, [exerciseVideoFile, exerciseVideoFile?.loading]);

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      <Layout style={styles.contentContainer}>
        <Layout style={styles.content}>
          <Layout style={styles.contentItem}>
            {exercise.video ? (
              <>
                {localUri && (
                  <Layout style={styles.thumbnailWrapper}>
                    {/* <Video
                      style={styles.thumbnail}
                      source={{ uri: localUri }}
                      resizeMode="cover"
                    /> */}
                    <ReactPlayer
                      url={localUri}
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      height={60}
                      width={60}
                    />
                  </Layout>
                )}
                <Layout style={styles.contentTextItem}>
                  <View style={styles.title}>
                    <>
                      {isCircuit && (
                        <Text style={styles.circuitValue}>
                          {`Set ${sweatStart.currentCircuitSet}`}
                        </Text>
                      )}
                      {!!stepExercise?.subtitle && (
                        <Text style={styles.reps}>{stepExercise?.subtitle}</Text>
                      )}
                    </>
                    <Text style={styles.name} numberOfLines={1}>
                      {exercise.name}
                    </Text>
                  </View>
                  {stepExercise && (
                    <Layout style={styles.timer}>
                      <Text style={styles.exerciseTime} category="c1">
                        {sweatStart.exerciseTimeDisplay(stepExercise?.id)}
                      </Text>
                      <Text style={styles.totalTime}>{sweatStart.totalTimeDisplay}</Text>
                    </Layout>
                  )}
                </Layout>
              </>
            ) : (
              <Layout style={styles.contentTextItem2}>
                <View style={styles.title2}>
                  <Text style={styles.name2} numberOfLines={1}>
                    {exercise.name}
                  </Text>
                  <>
                    {isCircuit && (
                      <Text style={styles.circuitValue}>
                        {`Set ${sweatStart.currentCircuitSet}`}
                      </Text>
                    )}
                    {!!stepExercise?.subtitle && (
                      <Text style={styles.reps}>{stepExercise?.subtitle}</Text>
                    )}
                  </>
                </View>
                {stepExercise && (
                  <Layout style={[exercise.video ? styles.timer : styles.timer2]}>
                    <Text style={styles.exerciseTime} category="c1">
                      {sweatStart.exerciseTimeDisplay(stepExercise.id)}
                    </Text>
                    <Text style={styles.totalTime}>{sweatStart.totalTimeDisplay}</Text>
                  </Layout>
                )}
              </Layout>
            )}
            <TouchableOpacity style={styles.minimizeIconContainer} onPress={onMaximize}>
              <MaximizeIcon style={styles.minimizeIcon} />
            </TouchableOpacity>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    minHeight: 70,
  },
  description: {
    margin: 12,
  },
  label: {
    flex: 1,
    textAlign: 'center',
  },
  thumbnail: {
    width: 70,
    height: 70,
  },
  thumbnailWrapper: {
    width: 70,
    height: 70,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
  },
  iconPlaceholder: {
    width: 70,
    height: 70,
    backgroundColor: 'black',
  },
  contentItem: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentTextItem: {
    flex: 2,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  title: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  circuitValue: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    marginRight: 10,
    color: 'cyan',
    textTransform: 'uppercase',
  },
  reps: {
    fontSize: 14,
    lineHeight: 17,
    color: 'cyan',
    marginRight: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  name: {
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    marginTop: 4,
    flexDirection: 'row',
  },
  rest: {
    fontSize: 12,
    lineHeight: 14,
    color: 'light-gray',
    textTransform: 'uppercase',
    marginRight: 6,
  },
  restLabel: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: '500',
    color: 'white',
    textTransform: 'uppercase',
  },
  contentTextItem2: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingHorizontal: 12,
    paddingLeft: 20,
  },
  title2: {},
  name2: {
    fontSize: 18,
    lineHeight: 22,
    color: 'white',
  },
  minimizeIconContainer: {
    padding: 20,
  },
  minimizeIcon: {
    height: 24,
    width: 24,
  },
  timer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    backgroundColor: 'black',
    paddingRight: 60,
  },
  timer2: {
    backgroundColor: 'black',
    alignItems: 'flex-start',
    paddingRight: 60,
  },
  exerciseTime: {
    fontSize: 32,
    lineHeight: 38,
    fontWeight: '500',
    marginRight: 8,
  },
  totalTime: {
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
    bottom: 2,
  },
});

export default observer(SweatStartCurrentMinimized);
