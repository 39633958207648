import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SavedList from 'o2x-store/src/models/SavedList';
import Team from 'o2x-store/src/models/Team';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';
import TeamItem from './TeamItem';

type Props = {
  route: any;
};

const TeamDelete: React.FC<Props> = (props) => {
  const {
    route: {
      params: { teamId },
    },
  } = props;

  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const [selectedList, setSelectedList] = useState<SavedList>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const insets = useSafeArea();

  let selectedTeam: Team | undefined;
  if (teamId) {
    selectedTeam = store.teams.teams.get(`${teamId}`);
  }

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    if (selectedTeam) {
      const result = await store.teams.deleteTeam(`${selectedTeam.id}`);
      setLoading(false);
      if (result.ok) {
        navigation.navigate('Teams');
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    }
  }, [selectedTeam]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={[styles.navigationContainer, { marginTop: insets.top }]}>
              <TopNav onBack={goBack} showClose={false} />
            </View>
            <ScrollView
              style={[
                isMobile
                  ? styles.scrollMobile
                  : isDeviceMaxWidth600
                  ? styles.scrollMaxWidth600
                  : styles.scroll,
                { marginTop: insets.top },
              ]}
              contentContainerStyle={styles.scrollContainer}>
              <Layout style={styles.scrollWrapper}>
                <Text style={styles.header}>Delete Team</Text>
                <Text style={styles.subHeader}>Are you sure you want to delete this team?</Text>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                {selectedTeam && (
                  <View style={styles.teamContainer}>
                    <TeamItem onPress={() => {}} team={selectedTeam} showCaret={false} />
                  </View>
                )}
              </Layout>
            </ScrollView>
            <Layout style={styles.actions}>
              <TouchableOpacity
                style={[styles.button, styles.buttonCancel]}
                disabled={loading}
                onPress={goBack}>
                <Text style={styles.buttonLabel} category="c1">
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} disabled={loading} onPress={onSubmit}>
                <Text style={styles.buttonLabel} category="c1">
                  Delete
                </Text>
              </TouchableOpacity>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    marginTop: -20,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    marginBottom: 24,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center',
  },
  subHeader: {
    color: 'white',
    fontSize: 16,
    marginBottom: 24,
  },
  inputContainer: {},
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 25,
    paddingHorizontal: '25%',
  },
  scrollMobile: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 10,
    paddingHorizontal: '5%',
  },
  scrollMaxWidth600: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
    paddingHorizontal: '15%',
  },
  scrollContainer: {
    flexGrow: 1,
  },
  scrollWrapper: {},
  deleteText: {
    color: 'red',
    fontSize: 18,
  },
  teamContainer: {
    marginHorizontal: -36,
  },
  navigationContainer: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
});

export default observer(TeamDelete);
