import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import ChevronDown from '../../assets/images/chevron-down.svg';

type Props = {
  color: string;
  title: string;
  item: { id: number; workoutId: number; title: string; data: string[] };
  onPress?: (data: any) => void;
};

const DLPreviewItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const { color, item, title, onPress } = props;

  const [showData, setShowData] = useState(false);

  const onToggle = useCallback(() => {
    setShowData(!showData);
  }, [showData]);

  const onPressWrapped = useCallback(() => {
    if (onPress) {
      onPress({
        id: item.id,
        workoutId: item.workoutId,
        title: `${title} ${item.title}`,
      });
    }
  }, [onPress]);

  return (
    <View>
      <TouchableOpacity style={styles.button} onPress={onPressWrapped}>
        <Layout style={[styles.container, { borderColor: color }]} level="2">
          <Text numberOfLines={1} style={styles.title}>
            {item.title}
          </Text>
          <TouchableOpacity style={styles.toggle} onPress={onToggle}>
            <ChevronDown style={showData ? styles.up : {}} />
          </TouchableOpacity>
        </Layout>
      </TouchableOpacity>
      {showData && (
        <View style={styles.items}>
          {item.data.map((exercise: string, index: number) => {
            if (!exercise) return null;
            return (
              <View style={styles.item} key={`${item.id}-${index}`}>
                <View style={[styles.box, { backgroundColor: color }]} />
                <Text style={styles.label}>{exercise.trim()}</Text>
              </View>
            );
          })}
        </View>
      )}
    </View>
  );
};

export const themedStyles = StyleService.create({
  container: {
    height: 40,
    marginTop: 14,
    borderLeftWidth: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  title: {
    flex: 1,
  },
  button: {
    marginHorizontal: 30,
  },
  up: {
    transform: [{ rotateZ: '180deg' }],
  },
  toggle: {
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  items: {
    backgroundColor: 'darker-blue',
    marginHorizontal: 30,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  label: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 7,
  },
  box: {
    width: 5,
    height: 5,
  },
});

export default DLPreviewItem;
