import { SnapshotOutOf } from 'mobx-keystone';
import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';
import SavedList from '../../models/SavedList';
import { LIST_TYPE } from '../../utils/list';
import { callApiWithToken } from './base';

const getListUrl = (listType: LIST_TYPE) => {
  let url;
  switch (listType) {
    case LIST_TYPE.RECIPE:
      url = config.urls.recipeList;
      break;
    case LIST_TYPE.SWEAT_PROGRAM:
      url = config.urls.sweatProgramList;
      break;
    case LIST_TYPE.SWEAT_WORKOUT:
      url = config.urls.sweatWorkoutList;
      break;
    case LIST_TYPE.THRIVE_PROGRAM:
      url = config.urls.thriveProgramList;
      break;
    case LIST_TYPE.THRIVE_EXERCISE:
      url = config.urls.thriveExerciseList;
      break;
    case LIST_TYPE.EVENT:
      url = config.urls.eventList;
      break;
    default:
      url = config.urls.recipeList;
  }
  return url;
};

export const fetchLists = async (token: string, listType: LIST_TYPE, clearCache: boolean = false) =>
  callApiWithToken(getListUrl(listType), token, 'GET', {}, undefined, clearCache);

export const createList = async (
  token: string,
  data: Partial<SnapshotOutOf<SavedList>>,
  listType: LIST_TYPE,
) =>
  callApiWithToken(
    getListUrl(listType),
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const getList = async (
  token: string,
  listType: LIST_TYPE,
  id: string,
  clearCache: boolean = false,
) => callApiWithToken(`${getListUrl(listType)}${id}/`, token, 'GET', {}, undefined, clearCache);

export const updateList = async (
  token: string,
  data: Partial<SnapshotOutOf<SavedList>>,
  listType: LIST_TYPE,
  id: string,
) =>
  callApiWithToken(
    `${getListUrl(listType)}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deleteList = async (token: string, listType: LIST_TYPE, id: string) =>
  callApiWithToken(`${getListUrl(listType)}${id}/`, token, 'DELETE');

export const fetchItems = async (token: string, listType: LIST_TYPE, id: string) =>
  callApiWithToken(`${getListUrl(listType)}${id}/items/`, token, 'GET');

export const addItems = async (
  token: string,
  items: Array<String>,
  listType: LIST_TYPE,
  id: string,
) =>
  callApiWithToken(
    `${getListUrl(listType)}${id}/add/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ items: items.map((i) => Number(i)) })),
  );

export const removeItems = async (token: string, items: Array<String>, listType: LIST_TYPE) =>
  callApiWithToken(
    `${getListUrl(listType)}remove/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ items: items.map((i) => Number(i)) })),
  );
