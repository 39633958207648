import {
  Icon,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  questionOption: string[];
  onChange: (k: string, v: any) => void;
  required: boolean;
  minRating?: number;
  maxRating?: number;
};
const OptionRatingInputItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { minRating = 1, maxRating = 5, onChange, questionOption } = props;
  const stars = Array.from(Array(maxRating), (_, i) => i + 1);
  const [rating, setRating] = useState(0);

  const onRate = useCallback(
    (rate) => {
      setRating(rate);
      // Each Question Option should have its own key
      onChange(questionOption[0], rate);
    },
    [setRating],
  );

  return (
    <View style={styles.content}>
      {!!questionOption[1] && (
        <Text style={styles.header}>{questionOption[1]}</Text>
      )}
      <View style={styles.ratingContainer}>
        {stars.map((rate: number) => (
          <TouchableOpacity
            style={styles.ratingButton}
            key={`${rate}`}
            onPress={() => onRate(rate)}
          >
            <Icon
              fill={rating >= rate ? 'white' : '#697484'}
              width={24}
              height={24}
              name="star"
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  content: {
    width: '100%',
    borderRadius: 4,
    margin: 10,
  },
  header: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
    marginBottom: 12,
    textTransform: 'uppercase',
    color: '#C4C4C4',
  },
  ratingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 40,
    backgroundColor: '#203950',
  },
  ratingButton: {
    padding: 4,
  },
});

export default OptionRatingInputItem;
