import { Layout, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { EventCtaUrl } from 'o2x-store/src/models/Event';
import React, { useCallback } from 'react';
import {
  Linking,
  Platform,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

type Props = {
  styleCtaButton?: StyleProp<ViewStyle>;
  styleCtaLabel?: StyleProp<ViewStyle>;
  eventCta: EventCtaUrl;
};

const EventCtaLink: React.FC<Props> = (props) => {
  const { styleCtaButton, styleCtaLabel, eventCta } = props;

  const openNewEventCtaUrl = useCallback(() => {
    if (Platform.OS === 'web') {
      return window.open(eventCta.ctaUrl, '_blank');
    }
    return Linking.openURL(eventCta.ctaUrl);
  }, [eventCta]);

  return (
    <Layout>
      <TouchableOpacity style={styleCtaButton} onPress={openNewEventCtaUrl}>
        <Text style={styleCtaLabel} category="label">
          {eventCta.ctaLabel || 'Open External Link'}
        </Text>
      </TouchableOpacity>
    </Layout>
  );
};

export default observer(EventCtaLink);
