import { useFocusEffect } from '@react-navigation/native';
import {
  Divider,
  Icon,
  Input,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { FTEOrganizationModel, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Animated, Dimensions, TouchableOpacity, View } from 'react-native';
import User from '../../../o2x-store/src/models/User';
import OrgUserList from './OrgUserList';

type Props = {
  isModalVisible: boolean;
  hideModal: () => void;
  setQuery: Function;
  fteOrganizations?: FTEOrganizationModel[];
  query: string;
  reloadGroups: () => void;
  edit: boolean;
  selectedGroup: OSSGroup | undefined;
  setSelectedGroup: React.Dispatch<React.SetStateAction<OSSGroup | undefined>>;
  isMobile: boolean;
};
const { width, height } = Dimensions.get('window');

const CreateGroupModal: React.FC<Props> = (props) => {
  const {
    isModalVisible,
    hideModal,
    setQuery,
    fteOrganizations,
    query,
    reloadGroups,
    edit,
    selectedGroup,
    setSelectedGroup,
    isMobile,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const { register, setValue, getValues, handleSubmit } = useForm();
  const [groupName, setGroupName] = useState('');
  const [addedUsers, setAddedUsers] = useState<number[]>();
  const [isDeletingGroup, setIsDeletingGroup] = useState(false);

  const animation = useRef(new Animated.Value(0)).current;

  useFocusEffect(
    useCallback(() => {
      register({ name: 'groupName' }, { required: true });
      register({ name: 'organization' }, { required: true });
      register({ name: 'users' }, { required: true });
    }, [register, setValue]),
  );

  useEffect(() => {
    if (edit && selectedGroup) {
      setGroupName(selectedGroup.groupName!);
      setAddedUsers(selectedGroup.users);
    }
  }, [edit, selectedGroup]);

  useEffect(() => {
    setValue('groupName', groupName);
    setValue('organization', fteOrganizations?.[0].id);
    setValue('users', addedUsers && addedUsers.length > 0 ? addedUsers : undefined);
  }, [groupName, addedUsers, fteOrganizations]);

  const onSubmit = useCallback(async (data) => {
    if (edit) {
      selectedGroup &&
        (await store.fteLibrary.updateOSSGroup({
          ...data,
          id: selectedGroup.id,
        }));
      setSelectedGroup(undefined);
    } else {
      await store.fteLibrary.createOSSGroup(data);
    }
    setGroupName('');
    setAddedUsers && setAddedUsers([]);
    reloadGroups();
    hideModal();
  }, []);
  const onSubmitWrapped = handleSubmit(onSubmit);

  const onCheckUser = useCallback(
    (checked: boolean, user: User) => {
      if (checked) {
        if (addedUsers) {
          setAddedUsers([...addedUsers!, user.id]);
        } else {
          setAddedUsers([user.id]);
        }
      } else {
        const arr = [...addedUsers!];
        const index = arr.indexOf(user?.id);
        arr.splice(index, 1);
        setAddedUsers([...arr]);
      }
    },
    [addedUsers],
  );

  const onCheckOrg = useCallback(
    (checked: boolean, users: User[]) => {
      if (checked) {
        if (addedUsers) {
          setAddedUsers([
            ...addedUsers!,
            ...users
              .filter((user) => user.id !== store.auth.user?.id && !addedUsers.includes(user.id))
              .map((user) => user.id),
          ]);
        } else {
          setAddedUsers([
            ...users.filter((user) => user.id !== store.auth.user?.id).map((user) => user.id),
          ]);
        }
      } else {
        let arr = [...addedUsers!];
        const arr2 = users.filter((user) => user.id !== store.auth.user?.id).map((user) => user.id);
        arr = arr.filter((val) => !arr2.includes(val));
        setAddedUsers([...arr]);
      }
    },
    [addedUsers],
  );

  const onPressDelete = useCallback(() => {
    setIsDeletingGroup(true);
    Animated.timing(animation, {
      toValue: 0.1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsDeletingGroup(false);
    animation.setValue(0);
  }, []);

  const onDeleteGroup = useCallback(() => {
    (async () => {
      if (selectedGroup && selectedGroup.id) {
        hideModal();
        await store.fteLibrary.deleteOSSGroup(selectedGroup.id);
        setSelectedGroup(undefined);
        await reloadGroups();
      }
    })();
  }, [store, selectedGroup]);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  return (
    <Modal visible={isModalVisible} backdropStyle={styles.backdrop} onBackdropPress={hideModal}>
      <Layout style={isMobile ? styles.modalMobile : styles.modal}>
        <Input
          size="medium"
          style={styles.groupNameInput}
          onChangeText={(text) => setGroupName(text)}
          placeholder="Group Name"
          value={groupName}
        />
        <Input
          size="small"
          style={styles.searchInput}
          onChangeText={(text) => setQuery(text)}
          placeholder="Search user"
          accessoryRight={() => <Icon style={styles.icon} name="search-outline" fill="#4E7B89" />}
        />
        <Divider style={styles.divider} />
        <OrgUserList
          fteOrganizations={fteOrganizations}
          onCheckOrg={onCheckOrg}
          onCheckUser={onCheckUser}
          query={query}
          addedUsers={addedUsers}
          store={store}
        />
        <Layout style={styles.buttonsContainer}>
          <TouchableOpacity style={styles.okButton} onPress={onSubmitWrapped}>
            <Text style={styles.buttonText}>{edit ? 'Save changes' : 'Create Group'}</Text>
          </TouchableOpacity>
          {edit && (
            <TouchableOpacity style={styles.deleteButton} onPress={onPressDelete}>
              <Text style={styles.buttonText}>Delete Group</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={[styles.cancelButton, { marginLeft: 10, marginRight: '5%' }]}
            onPress={hideModal}>
            <Text style={styles.buttonText}>Cancel</Text>
          </TouchableOpacity>
        </Layout>
        <View style={styles.center} pointerEvents={isDeletingGroup ? 'auto' : 'none'}>
          <Animated.View
            style={[
              styles.popUpContainer,
              {
                opacity,
                transform: [{ scale }, { translateY }],
              },
            ]}>
            <Icon style={styles.savedIcon} name="question-mark-circle-outline" fill="orange" />
            <Text style={styles.saveText}>Are you sure you want to delete this group?</Text>
            <View style={styles.buttons}>
              <TouchableOpacity style={styles.button} onPress={onCancelDelete}>
                <Text style={styles.deleteText}>NO</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={onDeleteGroup}>
                <Text style={styles.deleteText}>YES</Text>
              </TouchableOpacity>
            </View>
          </Animated.View>
        </View>
      </Layout>
    </Modal>
  );
};
const themedStyles = StyleService.create({
  groupNameInput: {
    marginLeft: '5%',
    width: '50%',
    marginBottom: 10,
    backgroundColor: 'transparent',
  },
  icon: {
    height: 20,
    width: 16,
  },
  cancelButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'olive',
    height: 30,
    width: '20%',
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: width / 2.5,
    height: height / 2,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  modalMobile: {
    width: width / 1.1,
    height: height / 1.8,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  searchInput: {
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    marginBottom: 10,
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'gray',
  },
  okButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 30,
    width: '20%',
    marginLeft: 'auto',
  },
  deleteButton: {
    justifyContent: 'center',
    backgroundColor: 'red',
    height: 30,
    width: '20%',
    marginLeft: 10,
  },
  buttonsContainer: {
    marginTop: 'auto',
    marginBottom: 10,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    width: '100%',
  },
});

export default React.memo(CreateGroupModal, (prevProps, nextProps) => {
  if (prevProps.query !== nextProps.query || prevProps.query.length !== nextProps.query.length) {
    return false;
  }
  return true;
});
