import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { PREPARE_ID } from 'o2x-store/src/constants/sweat';
import { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import React from 'react';
import { View } from 'react-native';

type Props = {
  step: SweatWorkoutStep;
};

const SweatStartSectionFooter: React.FC<Props> = (props) => {
  const { step } = props;
  const styles = useStyleSheet(themedStyles);

  if (step.exercises.length <= 1 && step.id > PREPARE_ID) {
    return null;
  }

  return <View style={styles.spacer} />;
};

const themedStyles = StyleService.create({
  spacer: {},
});

export default SweatStartSectionFooter;
