import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import BodyComposition from 'o2x-store/src/models/BodyComposition';
import React from 'react';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import HumanBody from '../../assets/images/assessment-body.svg';
import LeftArmLine from '../../assets/images/assessment-left-arm-line.svg';
import RightLine from '../../assets/images/assessment-right-line.svg';
import StraightLine from '../../assets/images/assessment-straight-line.svg';

type Props = {
  assessment: BodyComposition;
};

const BODY_HEIGHT = 350;
const BODY_WIDTH = 100;

const MuscleStatistics: React.FC<Props> = (props) => {
  const { assessment } = props;
  const styles = useStyleSheet(themedStyles);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout style={styles.muscleStatsContainer}>
      {isMobile ? (
        <>
          <View style={styles.mobileTextContainer}>
            <Text style={styles.muscleName}>LEFT ARM: </Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmLeftArm} lbs /{assessment.percentLbmLeftArm} %
            </Text>
          </View>
          <View style={styles.mobileTextContainer}>
            <Text style={styles.muscleName}>TRUNK: </Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmTrunk} lbs /{assessment.percentLbmTrunk} %
            </Text>
          </View>
          <View style={styles.mobileTextContainer}>
            <Text style={styles.muscleName}>LEFT LEG: </Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmLeftLeg} lbs /{assessment.percentLbmLeftLeg} %
            </Text>
          </View>
          <View style={styles.mobileTextContainer}>
            <Text style={styles.muscleName}>RIGHT ARM: </Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmRightArm} lbs /{assessment.percentLbmRightArm} %
            </Text>
          </View>
          <View style={styles.mobileTextContainer}>
            <Text style={styles.muscleName}>RIGHT LEG: </Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmRightLeg} lbs /{assessment.percentLbmRightLeg} %
            </Text>
          </View>
        </>
      ) : (
        <>
          <HumanBody style={styles.humanBody} />
          <View style={styles.leftArmContainer}>
            <Text style={styles.muscleName}>LEFT ARM:</Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmLeftArm} lbs /{assessment.percentLbmLeftArm} %
            </Text>
            <View style={styles.leftArmPointer}>
              <View style={[styles.bubble, styles.bottomBubble, styles.rightBubble]} />
              <LeftArmLine />
              <View style={[styles.bubble, styles.topBubble]} />
            </View>
          </View>

          <View style={styles.trunkContainer}>
            <Text style={styles.muscleName}>TRUNK:</Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmTrunk} lbs /{assessment.percentLbmTrunk} %
            </Text>

            <View style={styles.trunkPointer}>
              <View style={[styles.bubble, styles.rightBubble]} />
              <StraightLine width={100} />
              <View style={[styles.bubble, styles.leftBubble]} />
            </View>
          </View>

          <View style={styles.leftLegContainer}>
            <Text style={styles.muscleName}>LEFT LEG:</Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmLeftLeg} lbs /{assessment.percentLbmLeftLeg} %
            </Text>
            <View style={styles.leftLegPointer}>
              <View style={[styles.bubble, styles.rightBubble]} />
              <StraightLine width={100} />
              <View style={[styles.bubble, styles.leftBubble]} />
            </View>
          </View>

          <View style={styles.rightArmContainer}>
            <View style={styles.rightArmPointer}>
              <View style={[styles.bubble, styles.topBubble, styles.rightBubble]} />
              <RightLine />
              <View style={[styles.bubble, styles.bottomBubble]} />
            </View>
            <Text style={styles.muscleName}>RIGHT ARM:</Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmRightArm} lbs /{assessment.percentLbmRightArm} %
            </Text>
          </View>
          <View style={styles.rightLegContianer}>
            <View style={styles.rightLegPointer}>
              <View style={[styles.bubble, styles.rightBubble]} />
              <StraightLine width={100} />
              <View style={[styles.bubble, styles.leftBubble]} />
            </View>
            <Text style={styles.muscleName}>RIGHT LEG:</Text>
            <Text style={styles.muscleValue} numberOfLines={1}>
              {assessment.lbmRightLeg} lbs /{assessment.percentLbmRightLeg} %
            </Text>
          </View>
        </>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  muscleStatsContainer: {
    paddingVertical: 20,
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
  },
  humanBody: {
    alignSelf: 'center',
    position: 'relative',
    zIndex: 0,
    paddingTop: 40,
  },
  muscleName: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '400',
  },
  muscleValue: {
    fontWeight: '700',
    size: 18,
    lineHeight: 22,
    color: 'blue-secondary',
  },
  bubble: {
    borderRadius: 3,
    height: 6,
    width: 6,
    backgroundColor: 'white',
  },
  mobileTextContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
  },
  leftArmContainer: {
    position: 'absolute',
    zIndex: 1,
    bottom: BODY_HEIGHT - 90,
    right: BODY_WIDTH + 85,
  },
  leftArmPointer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 35,
    left: 100,
    zIndex: 2,
  },
  trunkContainer: {
    position: 'absolute',
    right: BODY_WIDTH + 10,
    bottom: BODY_HEIGHT - 180,
    zIndex: 1,
  },
  trunkPointer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 35,
    left: 75,
    zIndex: 2,
  },
  leftLegContainer: {
    position: 'absolute',
    right: BODY_WIDTH + 20,
    zIndex: 1,
    bottom: 60,
  },
  leftLegPointer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 35,
    left: 85,
    zIndex: 2,
  },
  rightArmContainer: {
    position: 'absolute',
    bottom: BODY_HEIGHT - 4,
    left: BODY_WIDTH + 125,
    zIndex: 4,
  },
  rightArmPointer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 2,
    top: 95,
    right: 140,
  },
  rightLegContianer: {
    position: 'absolute',
    left: BODY_WIDTH + 102,
    bottom: 65,
    zIndex: 1,
  },
  rightLegPointer: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 2,
    top: 15,
    right: 130,
  },
  rightBubble: {
    marginRight: 5,
    zIndex: 3,
  },
  leftBubble: {
    marginLeft: 5,
    zIndex: 3,
  },
  topBubble: {
    marginTop: 78,
    zIndex: 3,
  },
  bottomBubble: {
    marginBottom: 78,
    zIndex: 3,
  },
});

export default observer(MuscleStatistics);
