import { format, getDate, parseISO } from 'date-fns';
import { filter, isNull, uniq } from 'lodash';
import { computed } from 'mobx';
import { model, Model, modelAction, ModelInstanceCreationData, prop } from 'mobx-keystone';
import User from './User';

export type FTEEventModel = {
  id: number;
  fteUser: number;
  eventName: string;
  link: string;
  date: string;
  timeStart: string;
  timeEnd: string;
  location: string;
  allowGuests: boolean;
  maxNumberOfGuests: number;
  guests: number[];
  guestDetails: User[];
  pending: number[];
  pendingDetails: User[];
  declined: number[];
  declinedDetails: User[];
  // recurring details
  recurring: boolean;
  rrule: string;
  endRecurringDate: string;
};

export type EventsByRange = {
  title: string;
  data: Array<EventModel>;
};

@model('o2x-store/EventModel')
export default class EventModel extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  slug: prop<string>(''),
  description: prop<string>(''),
  timeStart: prop<string>(''),
  timeEnd: prop<string>(''),
  coverImageThumbnail: prop<string>(''),
  coverImageBanner: prop<string>(''),
  coverImageCard: prop<string>(''),
  address: prop<string>(''),
  city: prop<string>(''),
  stateCode: prop<string>(''),
  countryCode: prop<string>(''),
  zipCode: prop<string>(''),
  location: prop<string>(''),
  checkin: prop<EventCheckin | null>(null),
  saveList: prop<Array<number>>(() => new Array<number>()),
  specialists: prop<Array<number>>(() => new Array<number>()),
  usersWithSurvey: prop<Array<number>>(() => new Array<number>()),
  usersWithoutSurvey: prop<Array<number>>(() => new Array<number>()),
  viewUrl: prop<string>(''),
  eventCtaUrls: prop<Array<EventCtaUrl> | null>(null),
  physicalAssessment: prop<number | null>(null),
  formAssessment: prop<number | null>(null),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: ModelInstanceCreationData<EventModel>) {
    Object.assign(this, data);
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @modelAction
  timeDisplayStr(isTwentyFourHourFormat: boolean = true): string {
    const timeStart = parseISO(this.timeStart);
    const timeEnd = parseISO(this.timeEnd);
    let formattedTime;
    if (getDate(timeStart) === getDate(timeEnd)) {
      if (isTwentyFourHourFormat) {
        formattedTime = `${format(timeStart, 'LLLL d, yyyy HHmm')}-${format(timeEnd, 'HHmm')}`;
      } else {
        formattedTime = `${format(timeStart, 'LLLL d, yyyy hh:mm aaaa')}-${format(
          timeEnd,
          'hh:mm aaaa',
        )}`;
      }
    } else if (isTwentyFourHourFormat) {
      formattedTime = `${format(timeStart, 'LLLL d, yyyy HHmm')} - ${format(
        timeEnd,
        'LLLL d, yyyy HHmm',
      )}`;
    } else {
      formattedTime = `${format(timeStart, 'LLLL d, yyyy hh:mm aaaa')} - ${format(
        timeEnd,
        'LLLL d, yyyy hh:mm aaaa',
      )}`;
    }
    return formattedTime;
  }

  @computed
  get hasAssessment(): boolean {
    return !isNull(this.physicalAssessment || this.formAssessment);
  }
}

@model('o2x-store/EventCheckin')
export class EventCheckin extends Model({
  id: prop<number>(),
  event: prop<number>(),
  user: prop<number>(),
  participantName: prop<string>(''),
  unit: prop<string>(''),
  waiverSignature: prop<string>(''),
  address: prop<string>(''),
  city: prop<string>(''),
  stateCode: prop<string>(''),
  countryCode: prop<string>(''),
  zipCode: prop<string>(''),
  releaseSignature: prop<string>(''),
  readTerms: prop<boolean>(false),
  isComplete: prop<boolean>(false),
  promoterScore: prop<number>(),
  promoterFeedback: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;
}

@model('o2x-store/EventSurvey')
export class EventSurvey extends Model({
  id: prop<number>(),
  event: prop<number>(),
  user: prop<number>(),
  specialist: prop<number>(),
  rating: prop<number>(),
  specialistSkills: prop<number>(),
  specialistCommunication: prop<number>(),
  specialistEngagement: prop<number>(),
  location: prop<string>(),
  specialistComments: prop<string>(),
  sessionComments: prop<string>(),
}) {
  getRefId = () => `${this.id}`;
}

@model('o2x-store/EventCtaUrl')
export class EventCtaUrl extends Model({
  id: prop<number>(),
  event: prop<number>(),
  ctaLabel: prop<string>(''),
  ctaUrl: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;
}
