import { InputQuestionProp } from 'o2x-store/src/models/Question';
import { QUESTION_TYPES } from 'o2x-store/src/utils/questions';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import DailyLogHeightInput from './DailyLogHeightInput';
import DailyLogRatingInput from './DailyLogRatingInput';
import DailyLogSliderInput from './DailyLogSliderInput';
import DailyLogTextInput from './DailyLogTextInput';
import MultiSelectCheckInput from './MultiSelectCheckInput';
import RadioInput from './RadioInput';
import ToggleInput from './ToggleInput';
import WeightInput from './WeightInput';

interface Props {
  style?: StyleProp<ViewStyle>;
  question?: InputQuestionProp;
  onChange: (k: string, v: string | number) => void;
  initial: string | number | null;
  required?: boolean;
  status?: string;
  divider?: boolean;
  subtitle?: boolean;
  fill?: string;
}

const DailyLogInput: React.FC<Props> = (props) => {
  const {
    style,
    question,
    required = true,
    status = '',
    divider,
    subtitle = true,
    fill,
  } = props;
  let Component: any;

  if (question) {
    switch (question.type) {
      case QUESTION_TYPES.HEIGHT:
        Component = DailyLogHeightInput;
        break;
      case QUESTION_TYPES.WEIGHT:
        Component = WeightInput;
        break;
      case QUESTION_TYPES.SLIDER:
        Component = DailyLogSliderInput;
        break;
      case QUESTION_TYPES.TOGGLE:
        Component = ToggleInput;
        break;
      case QUESTION_TYPES.RATING:
        Component = DailyLogRatingInput;
        break;
      case QUESTION_TYPES.TEXT:
        Component = DailyLogTextInput;
        break;
      case QUESTION_TYPES.MULTISELECT:
        Component = MultiSelectCheckInput;
        break;
      case QUESTION_TYPES.RADIO:
        Component = RadioInput;
        break;
      default:
        console.warn(
          '[DEBUG] question type might not be handled properly. It will default to text input',
          question.type,
        );
        Component = DailyLogTextInput;
        break;
    }
  }

  return (
    <>
      {Component && (
        <Component
          question={props.question}
          onChange={props.onChange}
          initial={props.initial}
          required={required}
          status={status}
          divider={divider}
          subtitle={subtitle}
          fill={fill}
        />
      )}
    </>
  );
};

export default DailyLogInput;
