import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const EventWaiver: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const today = new Date();
  const event = store.event.events.get(`${id}`);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    register({ name: 'waiverSignature' }, { required: true });
  }, [register]);

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const updateData = {
        ...data,
        id: event?.checkin ? event?.checkin.id : null,
      };
      const result = await store.event.updateCheckin(id, updateData);
      setLoading(false);
      if (result.ok) {
        navigation.goBack();
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [event],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const updateSignature = useCallback((data: string) => {
    setValue('waiverSignature', data);
    navigation.navigate('EventWaiver', { id });
  }, []);

  const openSignature = useCallback(() => {
    navigation.navigate('EventSignature', { id, onSubmit: updateSignature });
  }, [event]);

  if (!event) {
    return null;
  }

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack onBack={goToEvent} showClose={false} />
            </View>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isMobile
                    ? styles.contentMobile
                    : isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }>
                <Text style={styles.title}>O2X Waiver</Text>
                <View style={styles.message}>
                  <Text style={styles.textContent}>
                    O2X HUMAN PERFORMANCE, LLC WORKSHOP (“Workshop”), Participant waiver, assumption
                    of risk, release of liability, and covenant not to sue agreement (the
                    “Agreement”)
                  </Text>
                  <Text style={styles.textContent}>
                    You __________________ (the “Participant”) have voluntarily elected to
                    participate in the Workshop and all of the activities, events and programs
                    related thereto (collectively the “Event”). In consideration of O2X Human
                    Performance, LLC (“O2X” or the “Company”) accepting your request to participate
                    in the Event, You as follows:
                  </Text>
                  <Text style={styles.textContent}>
                    1. I, on behalf of myself, my heirs, personal representatives and/or assigns,
                    understand and acknowledge that there are certain inherent risks and dangers
                    associated with the Event. I understand and acknowledge that some of these risks
                    cannot be eliminated regardless of the care taken by me and/or the Company to
                    avoid injuries. I further acknowledge that specific risks vary from one activity
                    to another but can range from (1) minor injuries, such as scratches, bruises,
                    strains and sprains; (2) major injuries, such as fractures, tears, eye injury,
                    loss of sight, joint or back injuries, heart attacks and concussions, and (3)
                    catastrophic injuries including but not limited to paralysis and death. I have
                    reviewed and thoroughly understand the Event safety instructions, a hard copy of
                    which has been provided to me by the Company.
                  </Text>
                  <Text style={styles.textContent}>
                    2. I hereby (1) agree to assume full responsibility for any and all injuries or
                    damage which are sustained or aggravated or exacerbated by me in relation to the
                    Event; (2) release, indemnify and hold harmless the Company, its subsidiaries,
                    affiliates, assigns, directors, officers, members, employees, representatives
                    and agents and each of their respective successors and assigns and all others
                    (the “Releasees”), from any and all responsibility, claims, actions, suits,
                    procedures, costs, expenses, damages and liabilities to the fullest extent
                    allowed by law arising out of or in any way related to participation in the
                    Event, and (3) represent that I (a) have no medical or physical condition that
                    would prevent me from participating in the Event, (b) do not have a physical or
                    mental condition that would place me in any physical or medical danger while
                    participating in the Event, and (c) have not been instructed by a physician not
                    to participate in the Event or similar activities or programs. Further, I
                    acknowledge that to the extent I do have any known disability(ies) or
                    condition(s); I am at risk in my participation in the Event and should not do
                    so.
                  </Text>
                  <Text style={styles.textContent}>
                    3. I agree to comply with the Company’s stated rules and regulations for
                    participation, a copy of which has been provided to me. If, however, I observe
                    any unusual and/or significant hazard during the Event, I will remove myself
                    from participation and bring such hazard to the Company’s attention;
                  </Text>
                  <Text style={styles.textContent}>
                    4. I certify and represent that on the date of the Event, I will possess and be
                    covered by medical/health insurance, individually or as part of an organization.
                  </Text>
                  <Text style={styles.textContent}>
                    5. I consent to administration of first aid and other medical treatment in the
                    event of injury or illness and hereby release and indemnify Releasees from any
                    and all liability or claims arising out of such treatment.
                  </Text>
                  <Text style={styles.textContent}>
                    6. I understand and acknowledge that the term of this waiver and Agreement is
                    indefinite
                  </Text>
                  <Text style={styles.textContent}>
                    I HAVE READ THIS PARTICIPANT WAIVER, RELEASE OF LIABILITY, ASSUMPTION OF RISK,
                    COVENANT NOT TO SUE AND CONSENT TO IMAGE USE AGREEMENT AND FULLY UNDERSTAND ITS
                    TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT, AND
                    SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT.
                  </Text>
                </View>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                <Text style={styles.label}>Signature:</Text>
                {event?.checkin?.waiverSignature || getValues('waiverSignature') ? (
                  <Image
                    style={styles.signature as StyleProp<ImageStyle>}
                    source={{
                      uri: event?.checkin?.waiverSignature
                        ? event?.checkin?.waiverSignature
                        : getValues('waiverSignature'),
                    }}
                    resizeMode="contain"
                  />
                ) : (
                  <TouchableOpacity style={styles.signatureContainer} onPress={openSignature}>
                    <Text>Signature</Text>
                  </TouchableOpacity>
                )}
                <Text style={styles.today}>Date: {format(today, 'MM/dd/yyyy')}</Text>
              </Layout>
            </ScrollView>
            <BottomNav showBack={false} onNext={onSubmitWrapped} nextLabel="Submit" />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  scroll: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
  },
  message: {
    marginBottom: 30,
  },
  textContent: {
    fontSize: 16,
    lineHeight: 19,
    marginTop: 30,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    marginBottom: 14,
  },
  signatureContainer: {
    backgroundColor: 'white',
    height: 66,
    flex: 1,
    marginBottom: 8,
  },
  signature: {
    width: '100%',
    height: 200,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
});

export default observer(EventWaiver);
