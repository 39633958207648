import '@expo/match-media';
import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LabeledInput from '../../components/LabeledInput';
import { getErrors } from '../../utils/errors';
import Account from '../Account';

type Props = {};

const ResetPassword: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { auth } = useStore();
  const navigation = useNavigation();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const emailRef = useRef<TextInput>(null);

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    const result = await auth.passwordReset(data);
    setLoading(false);
    if (result.ok) {
      navigation.navigate('ResetPasswordConfirm');
    } else {
      setErrorMessage(getErrors(result.errors));
    }
  }, []);

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSignIn = useCallback(() => {
    navigation.navigate('SignIn');
  }, []);

  useEffect(() => {
    register({ name: 'email' }, { required: true });
  }, [register]);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Account>
      {isTabletOrMobile ? (
        <Text style={styles.portalTextMobile} category="label">
          Reset Password
        </Text>
      ) : (
        <Text style={styles.portalText} category="h1">
          Reset Password
        </Text>
      )}

      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      <Layout
        style={
          isMobile
            ? styles.resetPasswordContainerMobile
            : styles.resetPasswordContainer
        }
      >
        <LabeledInput
          label="Email"
          keyboardType="email-address"
          returnKeyType="go"
          onChangeText={(text) => setValue('email', text)}
          ref={emailRef}
          divider
        />
        <TouchableOpacity onPress={onSubmitWrapped} style={styles.button}>
          {loading ? (
            <View style={styles.spinner}>
              <Spinner status="basic" />
            </View>
          ) : (
            <Text style={styles.buttonLabel} category="s2">
              Send password reset email
            </Text>
          )}
        </TouchableOpacity>
        <TouchableOpacity onPress={onSignIn}>
          <Text category="label" style={styles.signin}>
            Back to Sign In
          </Text>
        </TouchableOpacity>
      </Layout>
    </Account>
  );
};

const themedStyles = StyleService.create({
  resetPasswordContainer: {
    backgroundColor: 'transparent',
    width: '50%',
  },
  resetPasswordContainerMobile: {
    backgroundColor: 'transparent',
    width: '70%',
  },
  button: {
    justifyContent: 'center',
    height: 40,
    width: '100%',
    marginBottom: '10%',
    backgroundColor: 'olive',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  portalText: {
    textAlign: 'center',
    letterSpacing: 1,
    marginBottom: '10%',
  },
  error: {
    textAlign: 'center',
    color: 'danger',
    marginBottom: 15,
  },
  inputContainer: {
    width: '100%',
  },
  portalTextMobile: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 40,
  },
  spinner: {
    alignSelf: 'center',
  },
  signin: {
    marginTop: 20,
    fontWeight: 'normal',
    textDecorationLine: 'underline',
    alignSelf: 'center',
  },
});

export default ResetPassword;
