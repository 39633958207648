import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Props = {
  type: TASK_TYPE | string;
  selected: boolean;
  onSelect: (type: TASK_TYPE | string) => void;
};

const ListTab: React.FC<Props> = (props) => {
  const { type, selected, onSelect } = props;
  const styles = useStyleSheet(themedStyles);
  const onPress = useCallback(() => {
    onSelect(type);
  }, [type]);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={[styles.text, selected && styles.selectedText]}>{type}</Text>
      {selected && <View style={styles.selected} />}
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginRight: 20,
  },
  selected: {
    borderBottomWidth: 4,
    borderStyle: 'solid',
    borderColor: 'olive',
  },
  text: {
    textTransform: 'uppercase',
    fontSize: 18,
    color: 'blue-secondary',
  },
  selectedText: {
    color: 'white',
  },
});

export default ListTab;
