import { useNavigation } from '@react-navigation/native';
import { Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  BackHandler,
  Dimensions,
  Keyboard,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CustomCalendar from './CustomCalendar';

type Props = {
  route: any;
};

const { width, height } = Dimensions.get('screen');

const DatePicker: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  const { screen, startDay, startMonth, startYear } = props?.route.params ?? {};

  const [day, setDay] = useState(startDay);
  const [month, setMonth] = useState(startMonth);
  const [year, setYear] = useState(startYear);

  const animation = useRef(new Animated.Value(0)).current;

  const action = useCallback((callBack?: () => void) => {
    Keyboard.dismiss();

    Animated.timing(animation, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      if (callBack) {
        callBack();
      } else {
        navigation.goBack();
      }
    });
  }, []);

  useEffect(() => {
    Keyboard.dismiss();

    Animated.timing(animation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();

    const backAction = () => {
      action();
      return true;
    };
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    return () => {
      backHandler.remove();
    };
  }, []);

  const onSubmit = useCallback(() => {
    const strMonth = `00${month.toString()}`.substring(month.toString().length);
    const strDay = `00${day.toString()}`.substring(day.toString().length);
    const dateString = `${year}-${strMonth}-${strDay}`;

    if (screen) {
      navigation.navigate(screen, { dateString });
    } else {
      navigation.goBack();
    }
  }, [month, day, year]);

  const opacity = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0.48],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [height, 0],
  });

  return (
    <>
      <Animated.View style={[styles.overlay, { opacity }]} />
      <Animated.View style={[styles.container, { transform: [{ translateY }] }]}>
        <TouchableWithoutFeedback testID="background" onPress={() => action()}>
          <View style={styles.container} />
        </TouchableWithoutFeedback>
        <Layout style={[styles.content, { paddingBottom: insets.bottom }]}>
          <Text category="h2" style={styles.title}>
            PICK A DATE
          </Text>
          <Divider />
          <CustomCalendar
            day={day}
            setDay={setDay}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
          />
          <TouchableOpacity style={styles.button} onPress={() => action(onSubmit)}>
            <Text category="h2" style={styles.title}>
              SAVE
            </Text>
          </TouchableOpacity>
        </Layout>
      </Animated.View>
    </>
  );
};

const themedStyles = StyleService.create({
  overlay: {
    backgroundColor: 'black',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  container: {
    flex: 1,
  },
  content: {
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
  },
  title: {
    opacity: 0.6,
    fontSize: 14,
    paddingVertical: 16,
    alignSelf: 'center',
  },
  button: {
    height: 40,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 6,
    right: 6,
  },
  icon: {
    height: 25,
    width: 25,
  },
});

export default DatePicker;
