import { RouteProp, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../../components/Question/TopNav';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'SweatOptions'>;
};

const SweatOptions: React.FC<Props> = (props) => {
  const { programId, workoutId } = props.route.params;
  const { sweatStart } = useNativeStore();
  const { sweat } = useStore();

  const program = programId && sweat.sweatPrograms.get(`${programId}`);
  const workout = workoutId && sweat.sweatWorkouts.get(`${workoutId}`);
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  if (!program && !workout) {
    return null;
  }
  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onMarkComplete = useCallback(async () => {
    if (program && !sweatStart.repeat) {
      if (!sweatStart.workout) {
        await program.startWorkout();
      }
      await program.markCompleteCurrentWorkout({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
      });
    } else if (workout) {
      if (!sweatStart.workout) {
        await workout.startWorkout();
      }
      await workout.markComplete({
        totalTime: sweatStart.totalTime,
        stepExerciseTimeBreakdown: sweatStart.breakdownTime,
        globalStepExerciseTimeBreakdown: sweatStart.globalBreakdownTime,
      });
    } else {
      return null;
    }

    sweatStart.endWorkout();
    sweatStart.reset();

    navigation.navigate('SweatWorkoutComplete', {
      programId,
      workoutId,
    });
  }, [program, workout, sweatStart.repeat, sweatStart]);

  const onQuit = useCallback(() => {
    navigation.navigate('SweatOptionsQuit', {
      programId,
      workoutId,
    });
  }, [program]);

  const onJump = useCallback(() => {
    navigation.navigate('SweatOptionsJump', {
      programId,
      workoutId,
    });
  }, [program]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onBack={goBack} showClose={false} style={styles.navigationStyle} />
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.header}>
                {program ? (
                  <Text category="h2"> {program.name} </Text>
                ) : !!workout?.name || !workout?.program ? (
                  <Text category="h2">{workout?.displayName}</Text>
                ) : (
                  <>
                    <Text category="h2">{workout?.displayName.split(': Week ')[0]}</Text>
                    <Text style={styles.subtitle}> {workout.dayDisplay} </Text>
                  </>
                )}
              </View>
              <TouchableOpacity style={styles.optionItem} onPress={onMarkComplete}>
                <Text style={styles.optionText}>Mark today's workout as complete</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.optionItem} onPress={onJump}>
                <Text style={styles.optionText}>Jump to day...</Text>
              </TouchableOpacity>

              {!!program && (
                <TouchableOpacity style={styles.optionItem} onPress={onQuit}>
                  <Text style={styles.optionText}>Quit plan</Text>
                </TouchableOpacity>
              )}
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  navigationStyle: {
    backgroundColor: 'black',
  },
  contentContainer: {
    padding: 24,
    marginTop: 16,
  },
  completeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completeIcon: {
    marginRight: 12,
  },
  completeText: {
    color: 'gray',
    textTransform: 'uppercase',
  },
  header: {
    marginTop: -24,
    marginBottom: 20,
  },
  optionItem: {
    marginVertical: 20,
  },
  optionText: {
    fontSize: 18,
    lineHeight: 22,
  },
  subtitle: {
    color: 'white',
    marginTop: 4,
    fontSize: 14,
    textTransform: 'uppercase',
  },
});

export default SweatOptions;
