import { useNavigation } from '@react-navigation/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import User from '../../../../o2x-store/src/models/User';
import OSSOrganization from '../Content/OSSOrganization';
import UserProgramsAndExercises from './UserProgramsAndExercises';

type Props = {};

const AthleteProfile: React.FC<Props> = () => {
  const navigation = useNavigation();
  const [showPrograms, setShowPrograms] = useState(false);
  const [targetUser, setTargetUser] = useState<User>();
  return showPrograms ? (
    <UserProgramsAndExercises
      user={targetUser}
      onClose={() => setShowPrograms(false)}
    />
  ) : (
    <OSSOrganization
      handlePressOnOrg={(org) => {}}
      handlePressOnUser={(user) => {
        setTargetUser(user);
        setShowPrograms(true);
      }}
      handlePressOnGroup={(group) => {}}
    />
  );
};

export default observer(AthleteProfile);
