import Slider from '@react-native-community/slider';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

interface Props {
  onChange: (k: string, v: any) => void;
  question: InputQuestionProp;
  initial: number;
  style?: any;
  status?: string;
}

const SliderInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { onChange, initial, question, style, status = '' } = props;
  const [value, setValue] = useState(initial);

  let color = 'white';
  if (status === 'basic-eat') {
    color = 'green';
  } else if (status === 'basic-sweat') {
    color = 'cyan';
  } else if (status === 'basic-thrive') {
    color = 'orange';
  }

  const {
    minimumValue = 0,
    minimumValueLabel = '',
    maximumValue = 0,
    maximumValueLabel = '',
    step = 0,
  } = question;
  const ticks = Array.from(Array(maximumValue / step - 2).keys());

  return (
    <View style={style ? style : styles.container}>
      <Slider
        style={{ width: '100%', height: 60 }}
        value={value || 1}
        minimumValue={minimumValue}
        maximumValue={maximumValue}
        step={step}
        minimumTrackTintColor={color}
        maximumTrackTintColor="#697484"
        thumbTintColor="#FFFFFF"
        onSlidingComplete={(value: any) => {
          onChange(question.key, value);
        }}
      />
      <View style={styles.legend}>
        <View
          style={[
            styles.legendStart,
            { width: `${100 / maximumValue - minimumValue}%` },
          ]}
        >
          <Text style={styles.legendText}>|</Text>
          <Text style={styles.legendText}>
            {minimumValueLabel || `${minimumValue}`}
          </Text>
        </View>
        {ticks.map((item) => (
          <Text
            key={`tick-${item}`}
            style={[
              styles.legendTick,
              { width: `${100 / (ticks.length + 2)}%` },
            ]}
          >
            |
          </Text>
        ))}
        <View
          style={[
            styles.legendEnd,
            { width: `${100 / maximumValue - minimumValue}%` },
          ]}
        >
          <Text style={styles.legendText}>|</Text>
          <Text style={styles.legendText}>
            {maximumValueLabel || `${maximumValue}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: -14,
  },
  legendStart: {
    display: 'flex',
    flexDirection: 'column',
  },
  legendEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  legendText: {
    color: 'white',
    fontSize: 14,
  },
  legendTick: {
    color: '#697484',
    fontSize: 10,
    paddingLeft: 5,
    textAlign: 'center',
  },
});

export default SliderInput;
