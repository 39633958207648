import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Program } from 'o2x-store/src/models/Program';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useState } from 'react';
import {
  FlatList,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import ActiveListItem from 'src/components/Active';
import { useIsSubscribed } from 'src/hooks/subscription';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route?: any | null;
};

const ActiveList: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const navigation = useNavigation();
  const {
    route: {
      params: { type },
    },
  } = props;
  const { navigateWithPayWall } = useIsSubscribed();

  const insets = useSafeAreaInsets();

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const [selected, setSelected] = useState<LIST_TYPE>(type);
  const [activeSweatPrograms, setActiveSweatPrograms] = useState<
    Map<string, Program>
  >();
  const [activeSweatWorkouts, setActiveSweatWorkouts] = useState<
    Map<string, Program>
  >();
  const [activeThrivePrograms, setActiveThrivePrograms] = useState<
    Map<string, Program>
  >();
  const [loading, setLoading] = useState(true);

  const activeSweatProgramIds = store.sweat.activeSweatProgramIds;
  const activeSweatWorkoutIds = store.sweat.activeSweatWorkoutIds;
  const activeThriveProgramIds = store.thrive.activeThriveProgramIds;

  const loadedTypes: Array<LIST_TYPE> = [];

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        if (type) {
          setSelected(type);
        }

        let results;
        if (type === LIST_TYPE.SWEAT_PROGRAM) {
          results = await store.sweat.fetchActiveSweatPrograms();
        } else if (type === LIST_TYPE.SWEAT_WORKOUT) {
          results = await store.sweat.fetchActiveSweatWorkouts();
        } else if (type === LIST_TYPE.THRIVE_PROGRAM) {
          results = await store.thrive.fetchActiveThrivePrograms();
        }

        if (results && !('errors' in results)) {
          loadedTypes.push(type);
        }
        setLoading(false);
      })();
    }, [type]),
  );

  useFocusEffect(
    useCallback(() => {
      if (selected === LIST_TYPE.SWEAT_PROGRAM) {
        const programs = new Map<string, Program>();
        (activeSweatProgramIds || []).forEach((i: number) => {
          const program = store.sweat.sweatPrograms.get(`${i}`);
          if (program && !program.userProgress?.isComplete) {
            programs.set(`${i}`, program);
          }
        });
        setActiveSweatPrograms(programs);
      } else if (selected === LIST_TYPE.SWEAT_WORKOUT) {
        const programs = new Map<string, Program>();
        (activeSweatWorkoutIds || []).forEach((i: number) => {
          const program = store.sweat.sweatWorkouts.get(`${i}`);
          if (program && !program.userProgress?.isComplete) {
            programs.set(`${i}`, program);
          }
        });
        setActiveSweatWorkouts(programs);
      } else if (selected === LIST_TYPE.THRIVE_PROGRAM) {
        const programs = new Map<string, Program>();
        (activeThriveProgramIds || []).forEach((i: number) => {
          const program = store.thrive.thrivePrograms.get(`${i}`);
          if (program && !program.userProgress?.isComplete) {
            programs.set(`${i}`, program);
          }
        });
        setActiveThrivePrograms(programs);
      }
    }, [
      selected,
      activeSweatProgramIds,
      activeSweatWorkoutIds,
      activeThriveProgramIds,
    ]),
  );

  const reloadLists = useCallback((listType) => {
    (async () => {
      setLoading(true);
      setSelected(listType);
      if (indexOf(loadedTypes, listType) == -1) {
        // Only load if not previously loaded

        let results = { error: '' };
        if (listType === LIST_TYPE.SWEAT_PROGRAM) {
          results = await store.sweat.fetchActiveSweatPrograms();
        } else if (listType === LIST_TYPE.SWEAT_WORKOUT) {
          results = await store.sweat.fetchActiveSweatWorkouts();
        } else if (listType === LIST_TYPE.THRIVE_PROGRAM) {
          results = await store.thrive.fetchActiveThrivePrograms();
        }

        if (!('errors' in results)) {
          loadedTypes.push(listType);
        }
      }
      setLoading(false);
    })();
  }, []);

  const gotoDetail = useCallback(
    (item) => {
      if (selected === LIST_TYPE.SWEAT_WORKOUT) {
        navigateWithPayWall('SweatContinue', { workoutId: item.id });
      } else {
        navigation.navigate('ProgramDetail', {
          type: item.type,
          id: item.id,
        });
      }
    },
    [selected],
  );

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <ActiveListItem
          key={`active-${item.type}-${item.id}`}
          list={item}
          onPress={() => gotoDetail(item)}
        />
      ) : null,
    [selected],
  );

  const onCancel = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback onPress={onCancel}>
      <Layout style={styles.container}>
        <Layout style={styles.modal}>
          <View style={styles.navContainer}>
            <TopNav showBack={false} showClose={true} onClose={onCancel} />
          </View>
          {loading ? (
            <View style={styles.loading}>
              <Spinner />
            </View>
          ) : (
            <>
              {(selected === LIST_TYPE.SWEAT_PROGRAM ||
                selected === LIST_TYPE.SWEAT_WORKOUT) && (
                <>
                  <View
                    style={
                      isMobile ? styles.subSelectionMobile : styles.subSelection
                    }
                  >
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.SWEAT_PROGRAM);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.SWEAT_PROGRAM &&
                          styles.subSelectionContainerSelected,
                      ]}
                    >
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.SWEAT_PROGRAM &&
                            styles.selectionTextSelected,
                        ]}
                      >
                        Plans
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        reloadLists(LIST_TYPE.SWEAT_WORKOUT);
                      }}
                      style={[
                        styles.subSelectionContainer,
                        selected === LIST_TYPE.SWEAT_WORKOUT &&
                          styles.subSelectionContainerSelected,
                      ]}
                    >
                      <Text
                        style={[
                          styles.selectionText,
                          selected === LIST_TYPE.SWEAT_WORKOUT &&
                            styles.selectionTextSelected,
                        ]}
                      >
                        Workouts
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {selected === LIST_TYPE.SWEAT_PROGRAM && (
                    <View
                      style={
                        isMobile ? styles.actionLinksMobile : styles.actionLinks
                      }
                    >
                      <Text style={styles.sweatText}>
                        {activeSweatPrograms?.size} Plans
                      </Text>
                    </View>
                  )}
                  <View style={styles.items}>
                    <View style={styles.section}>
                      {selected === LIST_TYPE.SWEAT_PROGRAM &&
                        activeSweatPrograms && (
                          <FlatList
                            data={Array.from(activeSweatPrograms.values())}
                            renderItem={renderItem}
                            keyExtractor={(item) => `${item.id}`}
                            style={
                              isMobile ? styles.listItemMobile : styles.listItem
                            }
                          />
                        )}
                      {selected === LIST_TYPE.SWEAT_WORKOUT &&
                        activeSweatWorkouts && (
                          <FlatList
                            data={Array.from(activeSweatWorkouts.values())}
                            renderItem={renderItem}
                            keyExtractor={(item) => `${item.id}`}
                            style={
                              isMobile ? styles.listItemMobile : styles.listItem
                            }
                          />
                        )}
                    </View>
                  </View>
                </>
              )}
              {selected === LIST_TYPE.THRIVE_PROGRAM && (
                <>
                  <View
                    style={
                      isMobile ? styles.actionLinksMobile : styles.actionLinks
                    }
                  >
                    <Text style={styles.thriveText}>
                      {activeThrivePrograms?.size} Plans
                    </Text>
                  </View>
                  <View style={styles.items}>
                    <View style={styles.section}>
                      {activeThrivePrograms && (
                        <FlatList
                          data={Array.from(activeThrivePrograms.values())}
                          renderItem={renderItem}
                          keyExtractor={(item) => `${item.id}`}
                          style={
                            isMobile ? styles.listItemMobile : styles.listItem
                          }
                        />
                      )}
                    </View>
                  </View>
                </>
              )}
            </>
          )}
        </Layout>
      </Layout>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: '#00000080',
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 16,
  },
  navIcon: {
    width: 30,
    height: 30,
  },
  topNavText: {
    color: 'white',
    fontSize: 18,
    lineHeight: 22,
    marginLeft: -26,
  },
  separator: {
    height: 16,
  },
  navContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  taskBar: {
    flex: 3,
    display: 'flex',
  },
  items: {
    marginTop: 10,
    marginBottom: 20,
    flex: 1,
  },
  section: {
    marginBottom: 30,
    flex: 1,
  },
  subSelection: {
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    marginBottom: 5,
    marginTop: 19,
    marginHorizontal: 24,
  },
  subSelectionMobile: {
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    marginBottom: 5,
    marginTop: 10,
    marginHorizontal: 15,
  },
  subSelectionContainer: {
    flex: 1,
  },
  subSelectionContainerSelected: {
    borderBottomWidth: 4,
    borderColor: 'cyan',
    borderStyle: 'solid',
  },
  selectionText: {
    color: 'white',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    paddingVertical: 9,
    alignSelf: 'center',
  },
  selectionTextSelected: {
    color: 'cyan',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 24,
    marginTop: 10,
  },
  actionLinksMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 15,
    marginTop: 10,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  sweatText: {
    color: '#04B7D6',
  },
  thriveText: {
    color: '#FF7A00',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  listItem: {
    paddingHorizontal: 24,
    flex: 1,
  },
  listItemMobile: {
    paddingHorizontal: 15,
    flex: 1,
  },
  modal: {
    maxWidth: 600,
    height: '100%',
  },
});

export default observer(ActiveList);
