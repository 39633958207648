/* eslint-disable no-console */

import { bestfetch, responseCache } from 'bestfetch';
import camelCaseKeys from 'camelcase-keys';
import * as Localization from 'expo-localization';
import config from '../../config';

function offlineResponse() {
  console.log('[DEBUG: API]', 'You are offline.');
  return 'You are offline';
}

export type TokenType = string;
export type APIResponse = { response: { entities?: any } };

export function getFullUrl(endpoint: string): string {
  if (endpoint.indexOf('https://') >= 0 || endpoint.indexOf('http://') >= 0) {
    return endpoint;
  }
  return endpoint.indexOf(config.urls.api) === -1 ? `${config.urls.api}${endpoint}` : endpoint;
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export async function callApi(
  endpoint: string,
  method: string = 'GET',
  headers: HeadersInit = {},
  body: BodyInit = '',
  clearCache: boolean = false,
): Promise<APIResponse> {
  const fullUrl = getFullUrl(endpoint);

  const headersWithTimezone = new Headers(headers);
  const { timezone } = Localization;
  if (timezone) {
    headersWithTimezone.append('X-Timezone', timezone);
  }

  const params: RequestInit = {
    method,
    credentials: 'include',
    headers: headersWithTimezone,
  };

  if (method === 'PATCH' || method === 'POST' || method === 'PUT' || method === 'DELETE') {
    if (body) {
      params.body = body;
    }
  }

  const fetchParams: RequestInit & { cachePolicy?: string } = { ...params };
  fetchParams.cachePolicy = 'network-only'; // Force always clear cache

  console.log('[DEBUG:API]', fullUrl, method, headersWithTimezone, body, fetchParams);

  const response = await bestfetch(fullUrl, fetchParams);

  if (response.status < 100 || response.status >= 500) {
    throw new Error(offlineResponse());
  }

  if (response.status === 204) {
    return { response: {} };
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = camelCaseKeys(response.data, { deep: true });

    if (!response.ok) {
      throw new Error(JSON.stringify(json));
    }

    return {
      response: {
        entities: json,
      },
    };
  }
  // For non-JSON type responses, just return the data.
  return response.data;
}

export async function callApiWithToken(
  endpoint: string,
  token: TokenType,
  method = 'GET',
  headers: HeadersInit = {},
  body: BodyInit = '',
  clearCache: boolean = false,
) {
  const headersWithAuthorization: HeadersInit = new Headers(headers);
  if (token) {
    headersWithAuthorization.append('Authorization', `Token ${token}`);
  }
  return callApi(endpoint, method, headersWithAuthorization, body, clearCache);
}

export async function prepareFileForUpload(file: any) {
  if (file.uri?.startsWith('data:')) {
    const response = await fetch(file.uri);
    const blob = await response.blob();
    return new File([blob], file.name || 'file');
  }
  return file;
}

export function clearCache(): void {
  console.log('Clearing cache....');
  return responseCache.clear();
}
