import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useState } from 'react';
import { View } from 'react-native';
import CheckInput from './CheckInput';

type Props = {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: Array<string | number>;
  checkStyle?: any;
  optionStyle?: any;
  optionStyleSelected?: any;
  required: boolean;
  status?: string;
  fill?: string;
};

const MultiSelectCheckInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    status = '',
    checkStyle,
    optionStyle,
    optionStyleSelected,
    initial,
    fill,
  } = props;

  let initialChecked: { [key: string]: string | number } = {};
  if (initial) {
    question.options.map((option) => {
      const value = option[0];
      const label = option[1];
      if (initial.includes(value)) {
        initialChecked[label] = value;
      } else {
        delete initialChecked[label];
      }
    });
  }

  const [checked, setChecked] = useState<{ [key: string]: string | number }>(
    initialChecked,
  );

  return (
    <View style={styles.container}>
      {question.options.map((option) => {
        const value = option[0];
        const label = option[1] as string;
        return (
          <CheckInput
            key={label}
            field={label}
            label={label}
            initial={initial.includes(value)}
            status={status || 'basic-plain'}
            style={checkStyle}
            optionStyle={optionStyle}
            optionStyleSelected={optionStyleSelected}
            onChange={(k: string, v: boolean) => {
              setChecked((prev) => {
                const next = { ...prev };
                if (v) {
                  next[k] = value;
                } else {
                  delete next[k];
                }
                onChange(question.key, Array.from(Object.values(next)));
                return next;
              });
            }}
            fill={fill}
            labelStyle={styles.label}
          />
        );
      })}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 20,
  },
  label: {
    fontSize: 18,
    lineHeight: 22,
  },
});

export default MultiSelectCheckInput;
