import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageService } from 'o2x-store/src/services/storage';

export class NativeStorageService extends StorageService {
  getItemAsync = (key: string) => AsyncStorage.getItem(key);

  setItemAsync = (key: string, item: string) => AsyncStorage.setItem(key, item);

  removeItemAsync = (key: string) => AsyncStorage.removeItem(key);

  getSecureItemAsync = (key: string) =>
    // secure storage not available for web
    AsyncStorage.getItem(key);

  setSecureItemAsync = (key: string, item: string) =>
    // secure storage not available for web
    AsyncStorage.setItem(key, item);

  removeSecureItemAsync = (key: string) =>
    // secure storage not available for web
    AsyncStorage.removeItem(key);
}
