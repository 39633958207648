import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useState } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import Purpose from '../../assets/images/purpose.svg';
import Stress from '../../assets/images/stress-rating.svg';

type Props = {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  maxRating?: number;
  fill?: string;
  emptyIconName?: string;
  filledIconName?: string;
};

const DailyLogRatingInput: React.FC<Props> = (props) => {
  const {
    onChange,
    initial,
    question,
    maxRating = 5,
    fill,
    emptyIconName = 'star-outline',
    filledIconName = 'star',
  } = props;
  const styles = useStyleSheet(themedStyles);
  const stars = Array.from(Array(maxRating), (_, i) => i + 1);
  const [rating, setRating] = useState(
    typeof initial === 'number' ? initial : 0,
  );

  const onRate = useCallback(
    (rate) => {
      setRating(rate);
      onChange(question.key, rate);
    },
    [setRating],
  );

  const renderIcon = useCallback(
    (rate: number) => {
      if (question) {
        switch (question.key) {
          case 'stressLevel':
            return (
              <View style={styles.ratingIcon}>
                <Stress
                  stroke={rating >= rate ? fill : '#C4C4C4'}
                  strokeWidth={3}
                />
              </View>
            );
          case 'purposeLevel':
            return (
              <View style={styles.ratingIcon}>
                <Purpose color={rating >= rate ? fill : '#C4C4C4'} />
              </View>
            );

          default:
            return (
              <Icon
                fill={rating >= rate ? fill : '#C4C4C4'}
                width={40}
                height={40}
                name={rating >= rate ? filledIconName : emptyIconName}
                style={styles.ratingIcon}
              />
            );
        }
      }
    },
    [question, rating, emptyIconName, filledIconName],
  );

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.ratingContainer}>
          {stars.map((rate: number) => (
            <TouchableWithoutFeedback
              key={`${rate}`}
              onPress={() => onRate(rate)}
            >
              {renderIcon(rate)}
            </TouchableWithoutFeedback>
          ))}
        </View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginTop: 130,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  content: {
    maxWidth: 240,
  },
  header: {
    marginBottom: 12,
    backgroundColor: 'red',
  },
  ratingContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ratingIcon: {
    marginHorizontal: 6,
  },
});

export default DailyLogRatingInput;
