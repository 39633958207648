import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Divider,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlatList, Image, ImageStyle, StyleProp, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  onSuccess: () => void;
};

const Organization: React.FC<Props> = ({ onSuccess }) => {
  const store = useStore();
  const today = new Date();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const organization = store.organization.organizations.get(
    `${store.auth.user?.organization}`,
  );

  useFocusEffect(
    useCallback(() => {
      const org = store.auth.user?.organization;
      if (org) {
        store.organization.fetchOrganization(org);
      }
    }, [store.auth.user]),
  );

  const renderItem = useCallback(
    ({ item }) => {
      const value = get(organization, item.key, '');
      return (
        <Layout style={styles.field}>
          {item.label && (
            <Text style={styles.fieldLabel} category="c2">
              {item.label}
            </Text>
          )}
          <Text style={styles.fieldValue}>{value}</Text>
          <Divider style={styles.divider} />
        </Layout>
      );
    },
    [organization],
  );

  const fields = [
    { key: 'address', label: 'Address' },
    { key: 'contactPerson', label: 'Contact Person' },
    { key: 'contactNumber', label: 'Contact Number' },
  ];

  return (
    <Layout style={styles.container}>
      <Layout style={styles.content}>
        {organization?.logoCard && (
          <Image
            style={styles.thumbnail as StyleProp<ImageStyle>}
            source={{ uri: organization?.logoCard }}
          />
        )}
        <Text style={styles.title}>{organization?.name}</Text>
        <View style={styles.data}>
          <FlatList
            data={fields}
            renderItem={renderItem}
            keyExtractor={(item) => `${item.key}`}
          />
        </View>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 40,
  },
  navigationContainer: {
    padding: 16,
  },
  content: {
    paddingHorizontal: 24,
    flex: 1,
  },
  inputContainer: {},
  form: {
    marginTop: 48,
    width: 237,
    alignSelf: 'center',
  },
  scroll: {
    flex: 1,
    marginTop: -20,
  },
  title: {
    fontWeight: '600',
    fontSize: 24,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 54,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  thumbnail: {
    width: '30%',
    height: '20%',
    marginBottom: 34,
    alignSelf: 'center',
  },
  field: {
    backgroundColor: 'transparent',
  },
  fieldLabel: {
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: 11,
  },
  fieldValue: {
    color: 'light-gray',
    fontSize: 18,
    marginBottom: 8,
    flexWrap: 'wrap',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
});

export default observer(Organization);
