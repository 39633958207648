import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, Image, ImageStyle, ScrollView, StyleProp, View } from 'react-native';
import DownloadedItem from 'src/models/DownloadedItem';
import { useNativeStore } from 'src/stores';
import SweatIcon from '../../assets/images/sweat-icon.svg';
import TopNav from '../Question/TopNav';
import VideoPlayer from '../VideoPlayer';

type Props = {
  index: number;
  items: DownloadedItem[];
  hidden: boolean;
  setIsVisible: (value: boolean) => void;
};

const { width } = Dimensions.get('window');

const DLWorkoutVideo: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { downloads } = useNativeStore();

  const { items, index, hidden, setIsVisible } = props;

  const currentItem = items.length > index ? items[index] : null;
  const nextItem = items.length > index + 1 ? items[index + 1] : null;
  const color = getTaskColor(TASK_TYPE.SWEAT);

  const [thumbnail, setThumbnail] = useState('');

  const currentVideoFile = useMemo(
    () => (currentItem ? downloads.getOrCreateMediaFile(currentItem) : null),
    [currentItem, index],
  );

  const nextVideoFile = useMemo(
    () => (nextItem ? downloads.getOrCreateMediaFile(nextItem) : null),
    [nextItem, index],
  );

  useEffect(() => {
    if (currentVideoFile) currentVideoFile.downloadMedia();
  }, [currentVideoFile]);

  useEffect(() => {
    (async () => {
      if (nextVideoFile) {
        const uri = await nextVideoFile.downloadMedia();
        if (uri) {
          const result = await nextVideoFile.getThumbnail();
          setThumbnail(result);
        }
      }
    })();
  }, [nextVideoFile]);

  if (hidden || !currentItem) return null;

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {currentVideoFile && <VideoPlayer videoFile={currentVideoFile} size={width} muted />}
        <View style={styles.center}>
          {!!currentItem.subtitle && (
            <Text category="c1" style={[styles.subtitle, { color }]}>
              {currentItem.subtitle}
            </Text>
          )}
          <Text style={styles.title} numberOfLines={1}>
            {currentItem.name}
          </Text>
          {!!currentItem.instructions && (
            <Text style={styles.description} category="c1">
              {currentItem.instructions}
            </Text>
          )}
        </View>
        {nextItem && (
          <View style={styles.nextContainer}>
            <Text style={styles.next}>NEXT</Text>
            <View style={styles.nextDetails}>
              {thumbnail ? (
                <View style={styles.thumbnailWrapper}>
                  <Image
                    style={styles.thumbnail as StyleProp<ImageStyle>}
                    source={{ uri: thumbnail }}
                  />
                </View>
              ) : (
                <View style={styles.iconWrapper}>
                  <SweatIcon height={30} width={45} />
                </View>
              )}
              <Text style={styles.nextTitle} numberOfLines={1}>
                {nextItem.name}
              </Text>
            </View>
          </View>
        )}
      </ScrollView>
      <View style={styles.navigationContainer}>
        <TopNav
          style={styles.navigationStyle}
          onBack={() => setIsVisible(false)}
          onClose={() => setIsVisible(false)}
          showBack={false}
        />
      </View>
    </View>
  );
};

export const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'dark-blue',
  },
  description: {
    padding: 12,
    marginBottom: 15,
    textAlign: 'center',
  },
  title: {
    marginTop: 4,
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 20,
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'light-gray',
    textAlign: 'center',
  },
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  navigationContainer: {
    position: 'absolute',
    height: 100,
    width: '100%',
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigationStyle: {
    backgroundColor: 'transparent',
    paddingTop: 0,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
    width: 60,
    height: 60,
  },
  thumbnailWrapper: {
    width: 60,
    height: 60,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: 'black',
  },
  next: {
    fontSize: 14,
    marginLeft: 8,
  },
  nextDetails: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'center',
  },
  nextContainer: {
    backgroundColor: 'black',
    paddingBottom: 12,
    paddingTop: 8,
  },
  nextTitle: {
    marginLeft: 16,
    fontSize: 14,
    textTransform: 'uppercase',
  },
});

export default DLWorkoutVideo;
