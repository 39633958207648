import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  Modal,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Close from '../../assets/images/close-modal.svg';
import LabeledInput from '../../components/LabeledInput';
import { getErrors } from '../../utils/errors';
import SettingsContainer from './SettingsContainer';

type Props = {};

const Account: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const navigation = useNavigation();
  const { auth, user: userStore } = store;
  const { register, setValue, getValues, handleSubmit, errors } = useForm();

  const [user, setUser] = useState<User | null>();
  const [errorMessage, setErrorMessage] = useState('');
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState(
    '',
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [isChangePasswordSuccessful, setChangePasswordSuccessful] = useState(
    false,
  );

  const [loading, setLoading] = useState(false);
  const emailRef = useRef<TextInput>(null);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    const result = await auth.passwordReset(data);
    setLoading(false);
    if (result.ok) {
      setChangePasswordSuccessful(true);
    } else {
      setChangePasswordErrorMessage(getErrors(result.errors));
    }
  }, []);

  const onSubmitWrapped = handleSubmit(onSubmit);

  useEffect(() => {
    register({ name: 'email' }, { required: true });
  }, [register]);

  useEffect(
    useCallback(() => {
      const storedUser = auth.user;
      if (storedUser) {
        setUser(storedUser);
      }
    }, []),
  );

  const onFinish = useCallback(() => {
    setModalVisible(false);
    setChangePasswordSuccessful(false);
  }, []);

  const logOut = useCallback(() => {
    auth.logOut();
  }, []);

  return (
    <>
      <SettingsContainer>
        {!!errorMessage && (
          <Text style={styles.error} category="label">
            {errorMessage}
          </Text>
        )}
        <LabeledInput
          style={styles.inputContainer}
          autoCapitalize="none"
          labelStyle={styles.inputLabel}
          inputStyle={styles.inputField}
          textStyle={
            isTabletOrMobile
              ? styles.inputFieldTextTabletOrMobile
              : styles.inputFieldText
          }
          onChangeText={() => {}}
          label="Email"
          disabled={true}
          defaultValue={user?.email}
          divider
        />
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <Text style={styles.menuItemText}>Reset Password</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={logOut}>
          <Text style={styles.menuItemText}>Log Out</Text>
        </TouchableOpacity>
      </SettingsContainer>

      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        style={styles.modal}
        onBackdropPress={onFinish}
      >
        <Layout
          style={isTabletOrMobile ? styles.cardTabletOrMobile : styles.card}
        >
          <TouchableOpacity style={styles.closeModal} onPress={onFinish}>
            <Close />
          </TouchableOpacity>
          <Layout style={styles.resetPasswordContainer}>
            <Text
              style={
                isTabletOrMobile ? styles.titleTabletOrMobile : styles.title
              }
            >
              Reset Password
            </Text>
            {isChangePasswordSuccessful ? (
              <>
                <Text
                  style={
                    isTabletOrMobile
                      ? styles.messageTabletOrMobile
                      : styles.message
                  }
                >
                  Password reset email sent. Please check your email to reset
                  your password.
                </Text>
                <TouchableOpacity onPress={onFinish} style={styles.button}>
                  <Text
                    style={
                      isTabletOrMobile
                        ? styles.buttonLabelTabletOrMobile
                        : styles.buttonLabel
                    }
                  >
                    Finish
                  </Text>
                </TouchableOpacity>
              </>
            ) : (
              <>
                <Layout style={styles.inputContainer}>
                  {!!changePasswordErrorMessage && (
                    <Text style={styles.error} category="label">
                      {changePasswordErrorMessage}
                    </Text>
                  )}
                  <LabeledInput
                    label="Email"
                    keyboardType="email-address"
                    returnKeyType="go"
                    onChangeText={(text) => setValue('email', text)}
                    ref={emailRef}
                    divider
                  />
                </Layout>
                <TouchableOpacity
                  onPress={onSubmitWrapped}
                  style={styles.button}
                >
                  {loading ? (
                    <View style={styles.spinner}>
                      <Spinner status="basic" />
                    </View>
                  ) : (
                    <Text
                      style={
                        isTabletOrMobile
                          ? styles.buttonLabelTabletOrMobile
                          : styles.buttonLabel
                      }
                    >
                      Send password reset email
                    </Text>
                  )}
                </TouchableOpacity>
              </>
            )}
          </Layout>
        </Layout>
      </Modal>
    </>
  );
};

const themedStyles = StyleService.create({
  inputLabel: {
    fontSize: 12,
    letterSpacing: 1,
    color: 'white',
  },
  inputContainer: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  inputField: {
    width: '100%',
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: 0,
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
  },
  inputFieldText: {
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  inputFieldTextTabletOrMobile: {
    fontSize: 14,
    lineHeight: 19,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
  },
  menuItemText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    marginTop: 40,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
    letterSpacing: 1,
  },
  modal: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  card: {
    backgroundColor: '#1A3248',
    width: 500,
    height: 250,
  },
  cardTabletOrMobile: {
    backgroundColor: '#1A3248',
    width: '90%',
  },
  closeModal: {
    marginBottom: 3,
    paddingHorizontal: 20,
    paddingTop: 20,
    alignSelf: 'flex-end',
  },
  resetPasswordContainer: {
    width: '100%',
    height: 212,
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
    marginBottom: 30,
  },
  titleTabletOrMobile: {
    fontSize: 20,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '700',
    marginBottom: 30,
  },
  buttonLabel: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '400',
  },
  buttonLabelTabletOrMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: '400',
  },
  button: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    margin: 0,
    padding: 0,
    bottom: 0,
    backgroundColor: 'olive',
  },
  message: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    textAlign: 'center',
  },
  messageTabletOrMobile: {
    fontSize: 14,
    letterSpacing: 1,
    fontWeight: '400',
    color: 'white',
    textAlign: 'center',
  },
  spinner: {
    alignSelf: 'center',
  },
});

export default observer(Account);
