import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { AssessmentScoringDisplayData } from 'o2x-store/src/models/Assessment';
import React from 'react';
import { View } from 'react-native';

type Props = {
  data: AssessmentScoringDisplayData;
};

const AssessmentScoringTable: React.FC<Props> = (props) => {
  const { data } = props;
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);

  return (
    <Layout style={styles.container}>
      <View>
        {!!data.label && <Text style={styles.itemLabel}>{data.label}</Text>}
        <View style={styles.table}>
          {!!data.headers && (
            <View style={styles.header}>
              <View style={styles.row}>
                {data.headers.map((header: string, idx: number) => (
                  <Text
                    key={`header-${idx}`}
                    style={[
                      styles.cell,
                      styles.headerCell,
                      {
                        width: `${100 / data.headers.length}%`,
                      },
                    ]}
                  >
                    {header}
                  </Text>
                ))}
              </View>
            </View>
          )}
          {!!data.values && (
            <View style={styles.body}>
              {data.values.map((arrValue: Array<string>, idx: number) => (
                <View style={styles.row}>
                  {arrValue.map((value: string, idx2: number) => (
                    <Text
                      key={`cell-${idx}-${idx2}`}
                      style={[
                        styles.cell,
                        {
                          width: `${100 / arrValue.length}%`,
                        },
                        idx2 === 0 && styles.emp,
                      ]}
                    >
                      {value}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'darker-blue',
  },
  header: {},
  body: {},
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerCell: {
    fontSize: 14,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  cell: {
    padding: 10,
    borderWidth: 1,
    borderColor: 'white',
    fontSize: 12,
    textAlign: 'center',
  },
  displayItem: {
    marginTop: 20,
  },
  itemTitle: {
    textAlign: 'center',
    color: '#4E7B89',
    fontWeight: 'bold',
  },
  itemLabel: {
    marginTop: 6,
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'blue-secondary',
    fontWeight: 'bold',
  },
  table: {
    marginTop: 6,
  },
  emp: {
    color: 'blue-secondary',
  },
});

export default AssessmentScoringTable;
