import { useNavigation } from '@react-navigation/native';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

enum WORKOUT_TYPE {
  PREPARE = 'prepare',
  SWEAT = 'sweat',
  RECOVER = 'recover',
}

type Props = {
  type: WORKOUT_TYPE;
  setType: (type: WORKOUT_TYPE) => void;
};

const DLSweatTabs: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const { type, setType } = props;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => setType(WORKOUT_TYPE.PREPARE)}
        style={[
          styles.button,
          type === WORKOUT_TYPE.PREPARE ? styles.pressed : {},
        ]}
      >
        <Text
          style={[
            styles.label,
            type === WORKOUT_TYPE.PREPARE ? styles.blue : {},
          ]}
        >
          Prepare
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => setType(WORKOUT_TYPE.SWEAT)}
        style={[
          styles.button,
          type === WORKOUT_TYPE.SWEAT ? styles.pressed : {},
        ]}
      >
        <Text
          style={[styles.label, type === WORKOUT_TYPE.SWEAT ? styles.blue : {}]}
        >
          Sweat
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => setType(WORKOUT_TYPE.RECOVER)}
        style={[
          styles.button,
          type === WORKOUT_TYPE.RECOVER ? styles.pressed : {},
        ]}
      >
        <Text
          style={[
            styles.label,
            type === WORKOUT_TYPE.RECOVER ? styles.blue : {},
          ]}
        >
          Recover
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    height: 44,
    backgroundColor: 'darker-blue',
  },
  button: {
    flex: 1,
    alignItems: 'center',
  },
  pressed: {
    borderBottomWidth: 4,
    borderColor: 'cyan',
  },
  blue: {
    color: 'cyan',
  },
  label: {
    marginTop: 13,
    fontSize: 14,
    color: 'light-gray',
    textTransform: 'uppercase',
  },
});

export default DLSweatTabs;
