import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { Linking, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { default as AskAnExpertDrawer, default as FTEAdmin } from '../../assets/images/ask.svg';
import Plan from '../../assets/images/assessments.svg';
import Resources from '../../assets/images/book-open.svg';
import Organization from '../../assets/images/briefcase.svg';
import ExploreStack from '../../assets/images/drawer-explore.svg';
import Events from '../../assets/images/events.svg';
import Today from '../../assets/images/home.svg';
import SavedStack from '../../assets/images/saved.svg';
import Settings from '../../assets/images/settings.svg';
import Shop from '../../assets/images/shopping.svg';
import Teams from '../../assets/images/team.svg';
import JoinOrganization from '../../assets/images/use-code.svg';
import config from '../../config';

type Props = {
  style?: StyleProp<ViewStyle>;
  title: string;
  onNavigate: (route: string) => void;
  selected: boolean;
  hasNotification?: boolean;
};

const IconMapping: { [key: string]: React.FC<SvgProps> } = {
  Today,
  ExploreStack,
  SavedStack,
  Plan,
  AskAnExpertDrawer,
  Events,
  Organization,
  Shop,
  Settings,
  JoinOrganization,
  Teams,
  Resources,
  FTEAdmin,
};

const Content: React.FC<Props> = (props) => {
  const { style, title, onNavigate, selected, hasNotification = false } = props;
  const styles = useStyleSheet(themedStyles);
  const onPress = useCallback(() => {
    onNavigate(title);
  }, []);

  const openShop = useCallback(() => {
    const url = config.urls.externalShop;
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.warn(`[DEBUG] Cannot open URI: ${url}`);
      }
    });
  }, []);

  const Icon = IconMapping[title];
  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.selected, style]}
      onPress={title === 'Shop' ? openShop : onPress}>
      <Icon style={styles.icon} stroke="white" />
      {hasNotification && <View style={[styles.notification, { backgroundColor: 'white' }]} />}
      <Text style={styles.title}>
        {title === 'Today'
          ? 'Home'
          : title === 'Plan'
          ? 'Assessments'
          : title === 'ExploreStack'
          ? 'Explore'
          : title === 'SavedStack'
          ? 'Saved'
          : title === 'JoinOrganization'
          ? 'Use Registration Code'
          : title === 'AskAnExpertDrawer'
          ? 'Ask An Expert'
          : title === 'FTEAdmin'
          ? 'OSS Admin'
          : title}
      </Text>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    height: 60,
    paddingLeft: '12%',
    flexDirection: 'row',
  },
  icon: {
    height: 30,
    width: 30,
  },
  selected: {
    backgroundColor: 'olive',
  },
  title: {
    textAlignVertical: 'center',
    marginHorizontal: '5%',
  },
  notification: {
    height: 8,
    width: 8,
    borderRadius: 8,
    position: 'absolute',
    top: '25%',
    left: '9%',
  },
});

export default Content;
