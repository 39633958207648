import { model, Model, modelAction, prop, prop_mapObject } from 'mobx-keystone';
import { CTA_CHOICES } from 'o2x-store/src/models/ThriveProgramExercise';
import { TIMER_TYPE } from './TimerStart';

type Author = {
  id: number;
  fullName: string;
  biography: string;
  picture: string | null;
  pictureThumbnail: string | null;
};

type SweatData = {
  workoutId: number;
  title: string;
  data: string[];
};

type SweatSection = {
  title: string;
  data: SweatData[];
};

type SweatPreview = {
  id: number;
  name: string;
  preview: SweatSection[];
  weeksDisplay: string;
};

type ThriveData = {
  id: number;
  title: string;
  data: string[];
};

type ThriveSection = {
  title: string;
  data: ThriveData[];
};

type ThrivePreview = {
  id: number;
  name: string;
  preview: ThriveSection[];
};

type WorkoutSection = {
  id: string;
  circuitSets: number;
  circuitInstructions: string;
  order: number;
  exercises: number[];
  timer?: string | null;
  timerWork?: number | null;
  timerRest?: number | null;
  specifyRounds: boolean;
};

type Workout = {
  id: number;
  title: string;
  displayName: string;
  dayDisplay: string;
  sections: WorkoutSection[];
  imageBanner: string;
};

type ThriveExercise = {
  id: number;
  programDay: number;
  instructions: string;
  time: string;
  cta: CTA_CHOICES;
  timer: TIMER_TYPE;
  timerLengthInSeconds: number;
  description?: string;
  video?: string | null;
};

@model('o2x-native/DownloadedItem')
export default class DownloadedItem extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  title: prop<string>(''),
  subtitle: prop<string>(''),
  servings: prop<number>(0),
  ingredients: prop<string>(''),
  directions: prop<string>(''),
  image: prop<string>(''),
  imageBanner: prop<string>(''),

  equipment: prop<string[]>(),
  difficulty: prop<string>(''),
  author: prop<Author>(),
  description: prop<string>(''),

  exercises: prop<string[]>(() => []),

  dayDisplay: prop<string>(),
  displayName: prop<string>(),
  thrivePreview: prop<ThrivePreview>(),
  sweatPreview: prop<SweatPreview>(),

  sets: prop<string>(''),
  reps: prop<string>(''),
  exercise: prop<number>(),
  isRepsEachSide: prop<boolean>(),
  timeDistanceSubtitle: prop<string>(''),
  instructions: prop<string>(''),

  sections: prop<WorkoutSection[]>(() => []),
  video: prop<string | null>(null),

  timerRest: prop<number | null>(null),
  timerWork: prop<number | null>(null),
  isRestPercentage: prop<boolean>(false),

  workouts: prop_mapObject(() => new Map<string, Workout>()),
  thriveExercises: prop_mapObject(() => new Map<string, ThriveExercise>()),
}) {
  @modelAction
  addWorkout = (data: Workout) => {
    this.workouts.set(`${data.id}`, {
      id: data.id,
      dayDisplay: data.dayDisplay,
      sections: data.sections,
      displayName: '',
      imageBanner: '',
      title: '',
    });
  };

  @modelAction
  addThriveExercises = (data: ThriveExercise) => {
    this.thriveExercises.set(`${data.id}`, {
      id: data.id,
      programDay: data.programDay,
      instructions: data.instructions,
      cta: data.cta,
      time: data.time,
      timer: data.timer,
      timerLengthInSeconds: data.timerLengthInSeconds,
      description: data.description,
      video: data.video,
    });
  };
}
