import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import { useNavigation } from '@react-navigation/core';
import { StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { Video } from 'expo-av';
import config from 'o2x-store/src/config';
import { DashboardModel } from 'o2x-store/src/models/Dashboard';
import React, { useCallback, useState } from 'react';
import {
  Dimensions,
  Image,
  Linking,
  Modal,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import ImageZoom from 'react-native-image-pan-zoom';
import RenderHTML, { useInternalRenderer } from 'react-native-render-html';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import WebView from 'react-native-webview';
import { useNativeStore } from 'src/stores';
import clip from 'text-clipper';
import Back from '../../assets/images/back.svg';

type Props = {
  item: DashboardModel;
};

const { width, height } = Dimensions.get('window');
const DashboardItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { mediaStorage } = useNativeStore();

  const [isOpen, setIsOpen] = useState(false);

  const { item } = props;
  const { title, body, created } = item;

  const customHTMLElementModels = {
    iframe: iframeModel,
  };

  const clippedHtml = body.length > 0 ? clip(body, 1000, { html: true, maxLines: 10 }) : '';

  const onPress = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onPressAttachment = (id: number) =>
    Linking.openURL(`${config.urls.api}${config.urls.dashboardFiles}download/${id}`);

  const CustomImageRenderer = (props) => {
    const { Renderer, rendererProps } = useInternalRenderer('img', props);
    const insets = useSafeAreaInsets();
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onPress = () => setIsModalOpen(true);
    const onModalClose = () => setIsModalOpen(false);
    const { uri } = rendererProps.source;
    const thumbnailSource = {
      ...rendererProps.source,
      // You could change the uri here, for example to provide a thumbnail.
      uri,
    };

    return (
      <View style={{ alignItems: 'center', flex: 1 }}>
        <Renderer {...rendererProps} source={thumbnailSource} onPress={onPress} />
        <Modal visible={isModalOpen} onRequestClose={onModalClose}>
          {/* <Renderer {...rendererProps} style={{ flex: 1 }} /> */}
          <View
            style={{
              flex: 1,
              paddingTop: insets.top,
              backgroundColor: '#10283E',
            }}>
            <View
              style={{
                backgroundColor: '#10283E',
                width: '100%',
                // paddingTop: insets.top,
                height: 40,
              }}>
              <TouchableOpacity
                onPress={onModalClose}
                style={{
                  alignSelf: 'flex-start',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 15,
                }}>
                <Back
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </TouchableOpacity>
            </View>
            <ImageZoom
              cropHeight={height}
              cropWidth={width}
              imageHeight={height}
              imageWidth={width}
              minScale={1}
              maxScale={3}>
              <Image
                source={rendererProps.source}
                style={{
                  width: '100%',
                  height: '100%',
                  alignSelf: 'center',
                  resizeMode: 'contain',
                }}
              />
            </ImageZoom>
          </View>
        </Modal>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{format(parseISO(created), 'yyyy-MM-dd hh:mm a')}</Text>
      </View>
      {(body.length > 0 || item.files.length > 0) && (
        <View style={styles.bodyContainer}>
          <RenderHTML
            renderers={{
              iframe: IframeRenderer,
              img: CustomImageRenderer,
            }}
            tagsStyles={{
              p: styles.bodyStyle,
              h1: styles.bodyStyle,
              h2: styles.bodyStyle,
              blockquote: styles.bodyStyle,
              ul: styles.bodyStyle,
              ol: styles.bodyStyle,
              li: styles.bodyStyle,
              img: styles.image,
              iframe: styles.iframe,
            }}
            WebView={WebView}
            customHTMLElementModels={customHTMLElementModels}
            source={{
              html: isOpen ? body : clippedHtml,
            }}
            enableExperimentalBRCollapsing
            enableExperimentalGhostLinesPrevention
            renderersProps={{
              img: {
                enableExperimentalPercentWidth: true,
              },
            }}
            contentWidth={useWindowDimensions().width}
          />
          {/* <HTML
            tagsStyles={{
              p: styles.bodyStyle,
              h1: styles.bodyStyle,
              h2: styles.bodyStyle,
              blockquote: styles.bodyStyle,
              li: styles.bodyStyle,
              img: styles.image,
              iframe: styles.iframe,
            }}
            source={{ html: isOpen ? body : clippedHtml }}
            renderers={{ iframe }}
            WebView={WebView}
            {...defaultRenderer}
          /> */}
          {(body.length === clippedHtml.length || (body.length !== clippedHtml.length && isOpen)) &&
            item.files
              .filter((file) => file.type === 'mp4' || file.type === 'quicktime')
              .map((file, index) => (
                <View
                  key={index}
                  style={{
                    width: width / 1.2,
                    height: height / 3,
                    marginBottom: 10,
                  }}>
                  {/* VIDEOS WONT SHOW ON LOCAL TESTS FOR IOS */}
                  <Video
                    source={{
                      uri: file.file,
                    }}
                    resizeMode="contain"
                    isLooping
                    useNativeControls
                    style={{ height: '90%', width: '100%' }}
                    onError={(e) => console.log(e)}
                  />
                  <Text style={{ fontSize: 12, marginTop: 3 }} numberOfLines={2}>
                    {file.name}
                  </Text>
                </View>
              ))}
        </View>
      )}
      {body.length !== clippedHtml.length && (
        <TouchableOpacity style={styles.seeMore} onPress={onPress}>
          <Text style={styles.seeMoreText}>{isOpen ? 'Show Less' : 'Show More'}</Text>
        </TouchableOpacity>
      )}
      {item.files?.length > 0 && (
        <ScrollView
          horizontal
          style={{
            height: 50,
            borderTopWidth: 1,
            borderTopColor: 'grey',
          }}
          showsHorizontalScrollIndicator={false}>
          {item.files?.map((file, index) => (
            <TouchableOpacity
              key={index}
              style={{
                height: 40,
                marginVertical: 5,
                marginLeft: 5,
                width: 100,
                backgroundColor: '#1A3248',
              }}
              onPress={() => onPressAttachment(file.id)}>
              <Text style={{ fontSize: 12, marginLeft: 4 }} numberOfLines={2}>
                {file.name}
              </Text>
              <Text style={{ fontSize: 8, marginLeft: 4, color: 'grey' }} numberOfLines={2}>
                {(file.fileSize / (1024 * 1024)).toFixed(2)} MB
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginHorizontal: 20,
    marginVertical: 8,
    backgroundColor: 'dark-blue',
    borderBottomWidth: 5,
    borderColor: 'olive',
  },
  titleContainer: {
    flex: 1,
    marginVertical: 8,
    marginHorizontal: 12,
  },
  bodyContainer: {
    marginVertical: 12,
    marginHorizontal: 10,
  },
  title: {
    flex: 1,
    fontSize: 18,
    fontWeight: '700',
  },
  subtitle: {
    flex: 1,
    fontSize: 12,
    marginTop: 8,
    color: 'blue-secondary',
  },
  seeMore: {
    flex: 1,
    marginBottom: 8,
    marginHorizontal: 12,
  },
  seeMoreText: {
    flex: 1,
    fontSize: 14,
    color: 'blue-secondary',
  },
  author: {
    marginVertical: 8,
    fontSize: 14,
    color: '#4E7B89',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 12,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: 30,
    width: 30,
  },
  iconContainer: {
    height: 30,
    width: 30,
    marginRight: 12,
  },
  bodyStyle: {
    color: 'white',
    fontSize: 15,
    marginTop: 0,
    marginBottom: 0,
  },
  image: {
    maxWidth: 0.9 * width,
    maxHeight: 300,
  },
  iframe: {
    width: 0.85 * width,
    maxHeight: 300,
  },
  body: {
    flex: 1,
    padding: 22,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 12,
    position: 'absolute',
  },
  topNav: {
    backgroundColor: 'transparent',
  },
});

export default DashboardItem;
