import { useNavigation } from '@react-navigation/native';
import { Icon, Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getWeeksInMonth,
  getYear,
  isEqual,
  isSameWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

type Props = {
  onChangeDate: (startDate: Date | undefined, endDate: Date | undefined) => void;
  range: DateRange;
};

type DateRange = {
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
};

const DAY_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const DAYS_IN_WEEK = 7;

const EventCalendar: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const { onChangeDate, range } = props;

  const today = new Date();
  const dateStart = range.dateStart || startOfWeek(today);
  const dateEnd = range.dateEnd || endOfWeek(today);
  const [baseDay, setBaseDay] = useState<Date>(today);

  const getMark = (d: Date | null) => ({});

  const hasMark = (d: Date | null) => false;

  const getDays = () => {
    const startDay = startOfMonth(baseDay);
    const startDayOfWeek = getDay(startDay);
    const endDay = endOfMonth(baseDay);
    const endDayOfWeek = getDay(endDay);
    const allDays = eachDayOfInterval({ start: startDay, end: endDay });

    // Group by week
    const currentMonth = getMonth(startDay);
    const currentYear = getYear(startDay);
    const weeksInMonth = getWeeksInMonth(currentMonth);
    const daysInMonth = getDaysInMonth(new Date(currentYear, currentMonth));
    const daysPerWeek = Array<Array<Date | null>>();

    let weeks = weeksInMonth;
    if (DAYS_IN_WEEK - startDayOfWeek + (weeks - 1) * DAYS_IN_WEEK < daysInMonth) {
      weeks += 1;
    }

    let i: number;
    let currentIndex: number = 0;
    for (i = 0; i < weeks; i++) {
      let days = new Array<Date | null>();
      if (i === 0) {
        // First iteration
        days = [
          ...Array.from(Array(startDayOfWeek).keys()).map((l) => null),
          ...allDays.slice(0, DAYS_IN_WEEK - startDayOfWeek),
        ];
        currentIndex = DAYS_IN_WEEK - startDayOfWeek;
      } else {
        if (i === weeks - 1) {
          // Last iteration
          days = [
            ...allDays.slice(currentIndex, daysInMonth),
            ...Array.from(Array(DAYS_IN_WEEK - (daysInMonth - currentIndex)).keys()).map(
              (l) => null,
            ),
          ];
        } else {
          days = allDays.slice(currentIndex, currentIndex + DAYS_IN_WEEK);
        }
        currentIndex += DAYS_IN_WEEK;
      }
      daysPerWeek.push(days);
    }
    return daysPerWeek;
  };

  const changeRange = useCallback(
    (d: Date) => {
      setBaseDay((prevBaseDay) => (isSameWeek(d, prevBaseDay) ? today : d));
      if (
        isEqual(dateStart, startOfWeek(d)) &&
        range.dateStart &&
        isEqual(dateEnd, endOfWeek(d)) &&
        range.dateEnd
      ) {
        onChangeDate(undefined, undefined); // Remove selection
      } else {
        onChangeDate(startOfWeek(d), endOfWeek(d));
      }
    },
    [setBaseDay, dateStart, dateEnd, range, onChangeDate],
  );

  const onPrevMonth = useCallback(() => {
    const startDay = startOfMonth(baseDay);
    const prevMonthDay = sub(startDay, { months: 1 });
    setBaseDay(prevMonthDay);
    onChangeDate(startOfWeek(prevMonthDay), endOfWeek(prevMonthDay));
  }, [setBaseDay, onChangeDate, baseDay]);

  const onNextMonth = useCallback(() => {
    const startDay = startOfMonth(baseDay);
    const nextMonthDay = add(startDay, { months: 1 });
    setBaseDay(nextMonthDay);
    onChangeDate(startOfWeek(nextMonthDay), endOfWeek(nextMonthDay));
  }, [setBaseDay, onChangeDate, baseDay]);

  const daysPerWeek = useMemo(() => getDays(), [baseDay]);

  return (
    <Layout style={styles.container}>
      <View style={styles.selector}>
        <TouchableOpacity onPress={onPrevMonth}>
          <Icon
            style={[styles.expandIcon, styles.selectorIconLeft]}
            fill="white"
            name="chevron-left"
          />
        </TouchableOpacity>
        <Text style={styles.selectorText}>{format(baseDay, 'MMMM yyyy')}</Text>
        <TouchableOpacity onPress={onNextMonth}>
          <Icon
            style={[styles.expandIcon, styles.selectorIconRight]}
            fill="white"
            name="chevron-right"
          />
        </TouchableOpacity>
      </View>
      <View style={styles.content}>
        <View style={styles.row}>
          {DAY_LABELS.map((d: string, idx: number) => (
            <Text key={`${d}-${idx}`} style={styles.dayLabel}>
              {d}
            </Text>
          ))}
        </View>
        {daysPerWeek.map((value: (Date | null)[], index: number) => (
          <View key={`week-${index}`} style={styles.row}>
            {value.map((day: Date | null, dayIdx: number) => {
              const dayMarks = getMark(day);
              return (
                <TouchableOpacity
                  key={`day-${dayIdx}`}
                  onPress={() => {
                    day && changeRange(day);
                  }}
                  style={[styles.day, day && isSameWeek(day, dateStart) && styles.today]}>
                  <Text
                    style={[
                      styles.label,
                      day && hasMark(day) && styles.active,
                      isEqual(startOfDay(day), startOfDay(today)) && styles.todayText,
                    ]}>
                    {day && getDate(day)}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>
        ))}
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: 'darker-blue',
    paddingVertical: 15,
  },
  content: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2,
    marginHorizontal: 9,
    alignSelf: 'center',
  },
  day: {
    width: 44,
    textAlign: 'center',
    alignSelf: 'center',
    paddingVertical: 4,
    paddingHorizontal: 11,
  },
  dayLabel: {
    fontSize: 12,
    color: 'white',
    width: 44,
    textAlign: 'center',
    alignSelf: 'center',
    paddingHorizontal: 4,
    marginBottom: 8,
  },
  label: {
    textTransform: 'uppercase',
    color: 'gray',
    fontSize: 12,
    lineHeight: 17,
    textAlign: 'center',
  },
  active: {
    color: 'white',
  },
  marked: {
    borderBottomWidth: 3,
    borderStyle: 'solid',
  },
  markedText: {
    color: 'white',
  },
  today: {
    backgroundColor: 'rgba(105, 116, 132, 0.25)',
  },
  todayText: {
    color: 'white',
  },
  MonthText: {
    fontSize: 12,
    color: 'gray',
    textTransform: 'uppercase',
    textAlign: 'right',
    width: 260,
  },
  expandContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  expandIcon: {
    width: 30,
    height: 30,
  },
  expandText: {
    color: 'gray',
    textTransform: 'uppercase',
  },
  marks: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markIcon: {
    width: 9,
    height: 3,
  },
  selector: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  selectorText: {
    fontSize: 18,
    textTransform: 'uppercase',
    color: 'white',
  },
  selectorIconLeft: {
    marginRight: 36,
  },
  selectorIconRight: {
    marginLeft: 36,
  },
  loader: {
    alignSelf: 'center',
  },
});

export default EventCalendar;
