import camelCaseKeys from 'camelcase-keys';
import { keys } from 'lodash';
import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import config from '../config';
import { PREPARE_ID, RECOVER_ID, sweatExercise } from '../constants/sweat';
import { ProgramPreview } from '../models/Program';
import { SweatCategory, SweatEquipment, SweatImageFile, SweatProfession } from '../models/Sweat';
import SweatExercise from '../models/SweatExercise';
import SweatGlobalStep from '../models/SweatGlobalStep';
import SweatProgram from '../models/SweatProgram';
import SweatWorkout, { WorkoutDetail } from '../models/SweatWorkout';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/SweatStore')
export default class SweatStore extends Model({
  sweatEquipments: prop_mapObject(() => new Map<string, SweatEquipment>()),
  sweatProfessions: prop_mapObject(() => new Map<string, SweatProfession>()),
  sweatExercises: prop_mapObject(() => new Map<string, SweatExercise>()),
  sweatWorkouts: prop_mapObject(() => new Map<string, SweatWorkout>()),
  sweatWorkoutsExactMatch: prop_mapObject(() => new Map<string, SweatWorkout>()),
  sweatWorkoutsSomeMatch: prop_mapObject(() => new Map<string, SweatWorkout>()),
  sweatWorkoutsPopular: prop_mapObject(() => new Map<string, SweatWorkout>()),

  sweatPrograms: prop_mapObject(() => new Map<string, SweatProgram>()),
  sweatGlobalStep: prop_mapObject(() => new Map<string, SweatGlobalStep>()),
  activeSweatProgramIds: prop<Array<number>>(() => new Array<number>()),
  activeSweatWorkoutIds: prop<Array<number>>(() => new Array<number>()),
  sweatCategories: prop_mapObject(() => new Map<string, SweatCategory>()),
}) {
  _sweatProgramsLoading = '';

  _sweatWorkoutsLoading = '';

  _sweatWorkoutsExactMatchLoading = '';

  _sweatWorkoutsSomeMatchLoading = '';

  _sweatWorkoutsPopularLoading = '';

  _sweatEquipmentsNextURL = '';

  _sweatWorkoutsExactMatchNextURL = '';

  _sweatWorkoutsSomeMatchNextURL = '';

  _sweatWorkoutsPopularNextURL = '';

  @observable
  loading = false;

  getSweatExercise = (id: number | undefined): SweatExercise | undefined => {
    if (id === undefined) {
      return undefined;
    }
    if (id === PREPARE_ID) {
      return sweatExercise.prepare;
    }
    if (id === RECOVER_ID) {
      return sweatExercise.recover;
    }
    return this.sweatExercises.get(`${id}`);
  };

  @modelAction
  resetSweatEquipments() {
    this._sweatEquipmentsNextURL = `${config.urls.sweatEquipments}?limit=10`;
    this.sweatEquipments.clear();
  }

  @modelAction
  resetSweatWorkoutExactMatch() {
    this._sweatWorkoutsExactMatchNextURL = `${config.urls.sweatWorkouts}workouts_filter_exact_match/?limit=10`;
    this.sweatWorkoutsExactMatch.clear();
  }

  @modelAction
  resetSweatWorkoutSomeMatch() {
    this._sweatWorkoutsSomeMatchNextURL = `${config.urls.sweatWorkouts}workouts_filter_some_match/?limit=10`;
    this.sweatWorkoutsSomeMatch.clear();
  }

  @modelAction
  resetSweatWorkoutPopular() {
    this._sweatWorkoutsPopularNextURL = `${config.urls.sweatWorkouts}workouts_filter_popular/?limit=10`;
    this.sweatWorkoutsPopular.clear();
  }

  @modelAction
  createOrUpdateSweatExercise(data: ModelInstanceCreationData<SweatExercise>) {
    const id = `${data.id}`;
    const sweatExercise = new SweatExercise(data);
    if (this.sweatExercises.has(id)) {
      this.sweatExercises.get(id)!.update(data);
    } else {
      this.sweatExercises.set(id, sweatExercise);
      sweatExercise.update(data);
    }
    return sweatExercise;
  }

  @modelAction
  createOrUpdateSweatWorkout(data: ModelInstanceCreationData<SweatWorkout>) {
    const id = `${data.id}`;
    if (this.sweatWorkouts.has(id)) {
      this.sweatWorkouts.get(id)!.update(data);
    } else {
      const sweatWorkout = new SweatWorkout(data);
      this.sweatWorkouts.set(id, sweatWorkout);
      sweatWorkout.update(data);
    }
  }

  @modelAction
  createOrUpdateSweatWorkoutExactMatch(data: ModelInstanceCreationData<SweatWorkout>) {
    const id = `${data.id}`;
    if (this.sweatWorkoutsExactMatch.has(id)) {
      this.sweatWorkoutsExactMatch.get(id)!.update(data);
    } else {
      const sweatWorkout = new SweatWorkout(data);
      this.sweatWorkoutsExactMatch.set(id, sweatWorkout);
      sweatWorkout.update(data);
    }
  }

  @modelAction
  createOrUpdateSweatWorkoutSomeMatch(data: ModelInstanceCreationData<SweatWorkout>) {
    const id = `${data.id}`;
    if (this.sweatWorkoutsSomeMatch.has(id)) {
      this.sweatWorkoutsSomeMatch.get(id)!.update(data);
    } else {
      const sweatWorkout = new SweatWorkout(data);
      this.sweatWorkoutsSomeMatch.set(id, sweatWorkout);
      sweatWorkout.update(data);
    }
  }

  @modelAction
  createOrUpdateSweatWorkoutPopular(data: ModelInstanceCreationData<SweatWorkout>) {
    const id = `${data.id}`;
    if (this.sweatWorkoutsPopular.has(id)) {
      this.sweatWorkoutsPopular.get(id)!.update(data);
    } else {
      const sweatWorkout = new SweatWorkout(data);
      this.sweatWorkoutsPopular.set(id, sweatWorkout);
      sweatWorkout.update(data);
    }
  }

  @modelAction
  createOrUpdateSweatProgram(data: ModelInstanceCreationData<SweatProgram>) {
    const id = `${data.id}`;
    if (this.sweatPrograms.has(id)) {
      this.sweatPrograms.get(id)!.update(data);
    } else {
      const sweatProgram = new SweatProgram(data);
      this.sweatPrograms.set(id, sweatProgram);
      sweatProgram.update(data);
    }
  }

  @modelAction
  createOrUpdateSweatGlobalStep(
    data: ModelInstanceCreationData<SweatGlobalStep>,
    useStepType: boolean = false,
  ) {
    const id = useStepType ? `${data.stepType}` : `${data.id}`;
    if (this.sweatGlobalStep.has(id)) {
      this.sweatGlobalStep.get(id)!.update(data);
    } else {
      const sweatGlobalStep = new SweatGlobalStep(data);
      this.sweatGlobalStep.set(id, sweatGlobalStep);
      sweatGlobalStep.update(data);
    }
  }

  // @modelFlow
  // fetchSweatPrograms = _async(function* (
  //   this: SweatStore,
  //   recommended?: boolean,
  // ) {
  //   const rootStore = getRoot<RootStore>(this);

  //   if (!rootStore.auth || !rootStore.auth.token) {
  //     return getSuccess();
  //   }

  //   const runId = Math.random().toString(36).slice(-6);
  //   this._sweatProgramsLoading = runId;
  //   rootStore.fetchId = runId;
  //   this.loading = true;
  //   this.sweatPrograms.clear();

  //   let url = `${config.urls.sweatPrograms}?limit=10${
  //     recommended ? '&r=1' : ''
  //   }`;
  //   while (url) {
  //     let results: [ModelInstanceCreationData<SweatProgram>];
  //     try {
  //       ({
  //         response: {
  //           entities: { results, next: url },
  //         },
  //       } = yield* _await(api.fetchSweatPrograms(rootStore.auth.token, url)));
  //     } catch (error) {
  //       console.warn('[DEBUG] error fetching sweat programs', error);
  //       return getError(error);
  //     }
  //     if (runId !== this._sweatProgramsLoading || runId !== rootStore.fetchId) {
  //       return getSuccess();
  //     }
  //     results.forEach((data) => this.createOrUpdateSweatProgram(data));
  //   }

  //   this._sweatProgramsLoading = '';
  //   this.loading = false;
  //   return getSuccess();
  // });

  @modelFlow
  fetchSweatPrograms = _async(function* (
    this: SweatStore,
    recommended?: boolean,
    nextUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._sweatProgramsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    if (!nextUrl) this.sweatPrograms.clear();

    let url = nextUrl || `${config.urls.sweatPrograms}?limit=10${recommended ? '&r=1' : ''}`;
    let results: [ModelInstanceCreationData<SweatProgram>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(api.fetchSweatPrograms(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error);
    }
    if (runId !== this._sweatProgramsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }
    results.forEach((data) => this.createOrUpdateSweatProgram(data));
    this._sweatProgramsLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  fetchFullTextSearchSweatPrograms = _async(function* (
    this: SweatStore,
    query: string,
    nextPage?: number,
    type?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._sweatProgramsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    if (!nextPage) {
      this.sweatPrograms.clear();
    }
    const url = `${config.urls.sweatPrograms}full_text_search/?limit=3&query=${query || ''}&page=${
      nextPage || ''
    }&type=${type || ''}`;
    let entities: { [key: string]: { [key: string]: any } };
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatPrograms(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error);
    }
    if (runId !== this._sweatProgramsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }

    const results: any = {};
    const sections = keys(entities);
    sections.forEach((key) => {
      // Manipulating camel case (reversal)
      const newKey = key.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      results[newKey] = entities[key];
      results[newKey].entities.forEach((program: any) => {
        this.createOrUpdateSweatProgram(program);
      });
    });
    this._sweatProgramsLoading = '';
    this.loading = false;
    return getSuccess(results);
  });

  @modelFlow
  fetchFullTextSearchSweatWorkouts = _async(function* (
    this: SweatStore,
    query: string,
    nextUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._sweatWorkoutsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    if (!nextUrl) {
      this.sweatWorkouts.clear();
    }
    const url =
      nextUrl || `${config.urls.sweatWorkouts}full_text_search/?limit=10&query=${query || ''}`;
    let entities: { [key: string]: { [key: string]: any } };
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatWorkouts(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workouts', error);
      return getError(error);
    }
    if (runId !== this._sweatWorkoutsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }

    entities.results?.forEach((result: any) => {
      this.createOrUpdateSweatWorkout(result);
    });
    this._sweatWorkoutsLoading = '';
    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchActiveSweatGlobalSteps = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<SweatGlobalStep>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchActiveSweatGlobalSteps(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching active sweat programs', error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateSweatGlobalStep(data, true));
    this.loading = false;
  });

  @modelFlow
  fetchFTESweatGlobalSteps = _async(function* (
    this: SweatStore,
    fte: number,
    type: string,
    url?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchFTESweatGlobalSteps(rootStore.auth.token, fte, type, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching active sweat global steps', error);
      return getError(error);
    }
    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createSweatGlobalStep = _async(function* (this: SweatStore, data: SweatGlobalStep) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createSweatGlobalSteps(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating sweat global step', error);
      return getError(error);
    }
    this.loading = false;

    return getSuccess(entities);
  });

  @modelFlow
  updateSweatGlobalStep = _async(function* (this: SweatStore, id: number, data: SweatGlobalStep) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateSweatGlobalStep(rootStore.auth.token, id, data)));
    } catch (error) {
      console.warn('[DEBUG] error updating sweat global step', error);
      return getError(error);
    }
    this.loading = false;

    return getSuccess(entities);
  });

  @modelFlow
  deleteSweatGlobalStep = _async(function* (this: SweatStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteSweatGlobalStep(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error deleting sweat global step', error);
      return getError(error);
    }
    this.loading = false;

    return getSuccess(entities);
  });

  @modelFlow
  fetchActiveSweatPrograms = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<SweatProgram>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchActiveSweatPrograms(rootStore.auth.token, rootStore.auth.user?.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching active sweat programs', error);
      return getError(error);
    }

    this.activeSweatProgramIds = new Array<number>();
    results.forEach((data) => {
      this.createOrUpdateSweatProgram(data);
      this.activeSweatProgramIds.push(data.id);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatProgramsByIds = _async(function* (this: SweatStore, ids: Array<string>, url?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<SweatProgram>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSweatProgramsByIds(rootStore.auth.token, ids.toString(), url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateSweatProgram(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  searchSweatPrograms = _async(function* (
    this: SweatStore,
    filterString: string,
    nextUrl?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._sweatProgramsLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    this.sweatPrograms.clear();

    let url = `${config.urls.sweatPrograms}?limit=10&${filterString}`;
    let results: [ModelInstanceCreationData<SweatProgram>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(api.searchSweatPrograms(rootStore.auth.token, filterString, url)));
    } catch (error) {
      console.warn('[DEBUG] error searching sweat programs', error);
      return getError(error);
    }
    if (runId !== this._sweatProgramsLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }
    results.forEach((data) => this.createOrUpdateSweatProgram(data));

    this._sweatProgramsLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  fetchSweatWorkout = _async(function* (this: SweatStore, workoutId: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let result: ModelInstanceCreationData<SweatWorkout>;
    try {
      ({
        response: { entities: result },
      } = yield* _await(api.fetchSweatWorkout(rootStore.auth.token, workoutId)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workout', error);
      return getError(error);
    }

    this.createOrUpdateSweatWorkout(result);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatWorkoutByIds = _async(function* (this: SweatStore, ids: Array<string>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<SweatWorkout>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSweatWorkoutByIds(rootStore.auth.token, ids.toString())));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat programs', error);
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateSweatWorkout(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchActiveSweatWorkouts = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<SweatWorkout>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchActiveSweatWorkouts(rootStore.auth.token, rootStore.auth.user?.id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching active sweat workouts', error);
      return getError(error);
    }

    this.activeSweatWorkoutIds = new Array<number>();
    results.forEach((data) => {
      this.createOrUpdateSweatWorkout(data);
      this.activeSweatWorkoutIds.push(data.id);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatExercise = _async(function* (this: SweatStore, exerciseId: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let result: ModelInstanceCreationData<SweatExercise>;
    try {
      ({
        response: { entities: result },
      } = yield* _await(api.fetchSweatExercise(rootStore.auth.token, exerciseId)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat exercise', error);
      return getError(error);
    }

    this.createOrUpdateSweatExercise(result);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatExercises = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let url = `${config.urls.sweatExercises}?limit=20`;
    while (url) {
      let results: [ModelInstanceCreationData<SweatWorkout>];
      try {
        ({
          response: {
            entities: { results, next: url },
          },
        } = yield* _await(api.fetchSweatExercises(rootStore.auth.token, url)));
      } catch (error) {
        console.warn('[DEBUG] error fetching sweat exercise', error);
        return getError(error);
      }
      results.forEach((data) => this.createOrUpdateSweatExercise(data));
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  findSweatPrograms = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.findSweatPrograms(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error finding sweat programs', error);
      return getError(error);
    }

    entities.results.forEach((data: ModelInstanceCreationData<SweatProgram>) =>
      this.createOrUpdateSweatProgram(data),
    );

    this.loading = false;
    return entities.results;
  });

  @modelFlow
  batchStartSweatPrograms = _async(function* (this: SweatStore, data: Object) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response;
    try {
      ({ response } = yield* _await(api.batchStartSweatPrograms(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error batch starting sweat programs', error);
      return getError(error);
    }

    this.loading = false;
  });

  @modelFlow
  generateSweatWorkout = _async(function* (this: SweatStore, params: Object) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<SweatWorkout>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.generateSweatWorkout(rootStore.auth.token, params)));
    } catch (error) {
      console.warn('[DEBUG] error generating sweat workout', error);
      return getError(error);
    }

    this.createOrUpdateSweatWorkout(entities);

    this.loading = false;
    return getSuccess({ workoutId: entities.id });
  });

  @modelFlow
  filterSweatWorkoutsExactMatch = _async(function* (this: SweatStore, params: Object) {
    const rootStore = getRoot<RootStore>(this);
    const runId = Math.random().toString(36).slice(-6);
    this._sweatWorkoutsExactMatchLoading = runId;
    this.loading = true;

    if (!rootStore.auth || !rootStore.auth.token || !this._sweatWorkoutsExactMatchNextURL) {
      return getSuccess();
    }

    let count: number;
    let results: [ModelInstanceCreationData<SweatWorkout>];
    try {
      ({
        response: {
          entities: { results, next: this._sweatWorkoutsExactMatchNextURL, count },
        },
      } = yield* _await(
        api.filterSweatWorkoutsExactMatch(
          rootStore.auth.token,
          params,
          this._sweatWorkoutsExactMatchNextURL,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error filtering sweat workouts (exact match)', error);
      return getError(error);
    }
    if (runId !== this._sweatWorkoutsExactMatchLoading) {
      return getSuccess();
    }
    results.forEach((data) => {
      this.createOrUpdateSweatWorkout(data);
      this.createOrUpdateSweatWorkoutExactMatch(data);
    });

    this._sweatWorkoutsLoading = '';
    this.loading = false;
    console.log(this._sweatWorkoutsSomeMatchNextURL);
    return getSuccess({ count });
  });

  @modelFlow
  filterSweatWorkoutsSomeMatch = _async(function* (this: SweatStore, params: Object) {
    const rootStore = getRoot<RootStore>(this);
    const runId = Math.random().toString(36).slice(-6);
    this._sweatWorkoutsSomeMatchLoading = runId;
    this.loading = true;

    if (!rootStore.auth || !rootStore.auth.token || !this._sweatWorkoutsSomeMatchNextURL) {
      return getSuccess();
    }
    let count: number;
    let results: [ModelInstanceCreationData<SweatWorkout>];
    try {
      ({
        response: {
          entities: { results, next: this._sweatWorkoutsSomeMatchNextURL, count },
        },
      } = yield* _await(
        api.filterSweatWorkoutsSomeMatch(
          rootStore.auth.token,
          params,
          this._sweatWorkoutsSomeMatchNextURL,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error filtering sweat workouts (some match)', error);
      return getError(error);
    }
    if (runId !== this._sweatWorkoutsSomeMatchLoading) {
      return getSuccess();
    }
    results.forEach((data) => {
      this.createOrUpdateSweatWorkout(data);
      this.createOrUpdateSweatWorkoutSomeMatch(data);
    });

    this._sweatWorkoutsLoading = '';
    this.loading = false;
    return getSuccess({ count });
  });

  @modelFlow
  filterSweatWorkoutsPopular = _async(function* (this: SweatStore, params: Object) {
    const rootStore = getRoot<RootStore>(this);
    const runId = Math.random().toString(36).slice(-6);
    this._sweatWorkoutsPopularLoading = runId;
    this.loading = true;
    console.log(this._sweatWorkoutsPopularNextURL);
    if (!rootStore.auth || !rootStore.auth.token || !this._sweatWorkoutsPopularNextURL) {
      return getSuccess();
    }

    let count: number;
    let results: [ModelInstanceCreationData<SweatWorkout>];
    try {
      ({
        response: {
          entities: { results, next: this._sweatWorkoutsPopularNextURL, count },
        },
      } = yield* _await(
        api.filterSweatWorkoutsPopular(
          rootStore.auth.token,
          params,
          this._sweatWorkoutsPopularNextURL,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error filtering sweat workouts (popular)', error);
      return getError(error);
    }
    if (runId !== this._sweatWorkoutsPopularLoading) {
      return getSuccess();
    }
    results.forEach((data) => {
      this.createOrUpdateSweatWorkout(data);
      this.createOrUpdateSweatWorkoutPopular(data);
    });

    this._sweatWorkoutsLoading = '';
    this.loading = false;
    return getSuccess({ count });
  });

  @modelFlow
  generateSweatWorkoutCount = _async(function* (this: SweatStore, params: Object) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.generateSweatWorkoutCount(rootStore.auth.token, params)));
    } catch (error) {
      console.warn('[DEBUG] error generating sweat workouts', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess({ count: entities.count });
  });

  @modelFlow
  fetchSweatEquipments = _async(function* (this: SweatStore, paginated?: boolean) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results = null;
    try {
      paginated
        ? ({
            response: {
              entities: { results, next: this._sweatEquipmentsNextURL },
            },
          } = yield* _await(
            api.fetchSweatEquipments(this._sweatEquipmentsNextURL, rootStore.auth.token),
          ))
        : ({
            response: { entities: results },
          } = yield* _await(api.fetchSweatEquipments('', rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat equipments', error);
      return getError(error);
    }

    if (!paginated) {
      this.sweatEquipments.clear();
    }
    results?.forEach((data: SweatEquipment) => {
      this.sweatEquipments.set(`${data.id}`, data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatProfessions = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results;
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSweatProfessions(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat professions', error);
      return getError(error);
    }

    this.sweatProfessions.clear();
    results.forEach((data: SweatProfession) => {
      this.sweatProfessions.set(`${data.id}`, data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatProgramPreview = _async(function* (this: SweatStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ProgramPreview;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatProgramPreview(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat program preview', error);
      return getError(error);
    }

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchRandomSweatImageFile = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let response: SweatImageFile | {};
    try {
      ({ response } = yield* _await(api.fetchRandomSweatImageFile(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching random sweat image file', error);
      this.loading = false;
      return getError(error);
    }

    this.loading = false;
    return camelCaseKeys(response, { deep: true });
  });

  @modelFlow
  fetchSweatCategories = _async(function* (this: SweatStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatCategories(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workout categories', error);
      return getError(error);
    }

    this.sweatCategories.clear();
    entities.forEach((data: SweatCategory) => {
      this.sweatCategories.set(`${data.id}`, data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchSweatWorkoutDetail = _async(function* (this: SweatStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: WorkoutDetail;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchSweatWorkoutDetail(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sweat workout detail', error);
      return getError(error);
    }

    this.loading = false;
    return entities;
  });

  @modelFlow
  fetchAllStepsUsingProgramId = _async(function* (this: SweatStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) return null;

    try {
      const preview = yield* _await(this.fetchSweatProgramPreview(id));
      if ('errors' in preview) return null;

      const workoutIds: string[] = [];
      (preview.preview as any).forEach((week: any) => {
        week.data.forEach((day: any) => {
          const id = day.workoutId.toString();
          if (!workoutIds.includes(id)) workoutIds.push(id);
        });
      });

      const result = yield* _await(this.fetchSweatWorkoutByIds(workoutIds));
      if (!result.ok) return null;

      const workouts: {
        workout: SweatWorkout;
        sections: any[];
        exercises: any;
      }[] = [];

      yield* _await(
        Promise.all(
          workoutIds.map(async (id) => {
            const workout = this.sweatWorkouts.get(id);
            if (!workout) return;
            await workout.fetch();

            const sections: any[] = [];
            const exercises: any[] = [];

            workout.sweatStartSections.forEach((section) => {
              const sectionExercises: number[] = [];
              section.exercises.forEach((e) => {
                const step = this.getSweatExercise(e.exercise);
                if (step) {
                  sectionExercises.push(e.id);
                  exercises.push({
                    id: e.id,
                    sets: e.sets,
                    reps: e.reps,
                    timerWork: e.workoutTimerWork,
                    timerRest: e.workoutTimerRest,
                    exercise: e.exercise,
                    isRepsEachSide: e.isRepsEachSide,
                    timeDistanceSubtitle: e.timeDistanceSubtitle,
                    instructions: e.instructions,
                    subtitle: e.subtitle,
                    name: step.name,
                    description: step.description,
                    video: step.video,
                    equipments: step.equipments,
                    isRestPercentage: e.workoutTimer === 'percent_recovery',
                  });
                }
              });
              sections.push({
                id: section.id,
                circuitSets: section.circuitSets,
                circuitInstructions: section.circuitInstructions,
                order: section.order,
                timer: section.circuitTimer,
                timerWork: section.circuitTimerWork,
                timerRest: section.circuitTimerRest,
                specifyRounds: section.specifyRounds,
                exercises: sectionExercises,
              });
            });

            workouts.push({ workout, sections, exercises });
          }),
        ),
      );

      return { workouts, preview };
    } catch (error) {
      console.warn('[DEBUG] error fetching all step exercises', error);
      return null;
    }
  });

  @modelFlow
  fetchAllStepsUsingWorkoutId = _async(function* (this: SweatStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) return null;

    try {
      const result: any = yield* _await(this.fetchSweatWorkout(id));
      if (!result.ok) return null;

      const workout = this.sweatWorkouts.get(`${id}`);
      if (!workout) return null;

      yield* _await(workout.fetch());

      const sections: any[] = [];
      const exercises: any[] = [];

      workout.sweatStartSections.forEach((section) => {
        const sectionExercises: number[] = [];
        section.exercises.forEach((e) => {
          const step = this.getSweatExercise(e.exercise);
          if (step) {
            sectionExercises.push(e.id);
            exercises.push({
              id: e.id,
              sets: e.sets,
              reps: e.reps,
              timerWork: e.workoutTimerWork,
              timerRest: e.workoutTimerRest,
              exercise: e.exercise,
              isRepsEachSide: e.isRepsEachSide,
              timeDistanceSubtitle: e.timeDistanceSubtitle,
              instructions: e.instructions,
              subtitle: e.subtitle,
              name: step.name,
              description: step.description,
              video: step.video,
              equipments: step.equipments,
              isRestPercentage: e.workoutTimer === 'percent_recovery',
            });
          }
        });
        sections.push({
          id: section.id,
          circuitSets: section.circuitSets,
          circuitInstructions: section.circuitInstructions,
          order: section.order,
          timer: section.circuitTimer,
          timerWork: section.circuitTimerWork,
          timerRest: section.circuitTimerRest,
          specifyRounds: section.specifyRounds,
          exercises: sectionExercises,
        });
      });

      return { exercises, sections };
    } catch (error) {
      console.warn('[DEBUG] error fetching all step exercises', error);
      return null;
    }
  });

  @modelFlow
  clearSweatPrograms = _async(function* (this: SweatStore) {
    // console.log(this.)
    this.sweatPrograms.clear();
  });

  @modelFlow
  clearSweatWorkouts = _async(function* (this: SweatStore) {
    this.sweatWorkouts.clear();
  });
}
