import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Layout, Spinner, StyleService, useStyleSheet } from '@ui-kitten/components';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import ThriveProgramExercise from 'o2x-store/src/models/ThriveProgramExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, FlatList, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Timer from 'src/components/Timer';
import config from 'src/config';
import { TIMER_TYPE } from 'src/models/TimerStart';
import { useNativeStore } from 'src/stores';
import NavigationBar from '../../components/NavigationBar';
import ThriveStartActions from '../../components/ThriveStart/ThriveStartActions';
import ThriveStartHeader from '../../components/ThriveStart/ThriveStartHeader';
import ThriveStartItem from '../../components/ThriveStart/ThriveStartItem';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveStart'>;
  navigation: StackNavigationProp<AppStackParamList, 'ThriveStart'>;
};

const ThriveStart: React.FC<Props> = (props) => {
  const {
    route: {
      params: { id, jumpDayId },
    },
    navigation,
  } = props;

  const { thrive } = useStore();
  const { timerStart } = useNativeStore();
  const program = thrive.thrivePrograms.get(`${id}`);
  const dayId = jumpDayId || get(program, 'userProgress.currentDay', null);
  const day = dayId && thrive.thriveProgramDays.get(`${dayId}`);
  const nextDayId = get(program, 'userProgress.nextDay', null);
  const nextDay = nextDayId && thrive.thriveProgramDays.get(`${nextDayId}`);
  const data = day?.thriveStartSections;
  const titleOpacity = useRef(new Animated.Value(0));

  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const initialTimerData = {
    visible: false,
    timerType: TIMER_TYPE.TIMER,
    lengthInSeconds: 0,
  };
  const [timerData, setTimerData] = useState(initialTimerData);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });
  const keyExtractor = useCallback((item) => `${item.id}`, []);

  const onExpandItem = useCallback(
    (item: ThriveProgramExercise, isExpanded: boolean) => {
      const data = isExpanded
        ? item.timer
          ? {
              visible: true,
              timerType: item.timer,
              lengthInSeconds: item.timerLengthInSeconds,
            }
          : initialTimerData
        : initialTimerData;
      setTimerData(data);
    },
    [setTimerData],
  );

  const renderItem = useCallback(
    ({ item }) => (
      <ThriveStartItem
        item={item}
        onExpand={(isExpanded: boolean) => onExpandItem(item, isExpanded)}
      />
    ),
    [program, day, jumpDayId],
  );

  const renderHeader = useCallback(
    () => <ThriveStartHeader program={program} day={day} />,
    [program, day, jumpDayId],
  );

  const renderSeparator = useCallback(() => <View style={styles.spacer} />, []);

  useFocusEffect(
    useCallback(() => {
      thrive.fetchThriveProgramsByIds([`${id}`]);
    }, [id, thrive]),
  );

  useEffect(() => {
    if (program) {
      if (!program.userProgress?.program || program.userProgress.isComplete) {
        program.startProgram();
      } else if (!day) {
        program.fetchProgramDays();
      }
    }
  }, [program, day]);

  useEffect(() => {
    if (day) {
      // && day.thriveStartSections.length === 0) {
      day.fetch(); // Always fetch to refresh `cta_meta`
    }
  }, [day, jumpDayId]);

  const goBack = useCallback(() => {
    navigation.popToTop();
  }, []);

  console.log('timerData visible', timerData.visible);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar
                title={program?.name}
                showBack={false}
                showClose
                onClose={goBack}
                withBackground={false}
                titleOpacity={titleOpacity.current.interpolate({
                  inputRange: [0, config.titleDisplayOffsetOnScroll],
                  outputRange: [0, 1],
                })}
              />
            </Layout>
            {program?.loading || day?.loading ? (
              <View style={styles.loader}>
                <Spinner />
              </View>
            ) : (
              <>
                <FlatList
                  style={isDeviceMaxWidth600 ? styles.listMaxWidth600 : styles.list}
                  contentContainerStyle={styles.listContainer}
                  data={data}
                  keyExtractor={keyExtractor}
                  renderItem={renderItem}
                  ListHeaderComponent={renderHeader}
                  ItemSeparatorComponent={renderSeparator}
                />
                {timerData.visible && (
                  <Timer
                    type={timerData.timerType}
                    timeInSeconds={
                      timerData.lengthInSeconds ? timerData.lengthInSeconds : undefined
                    }
                    onStopTimer={() => {}}
                    timerStart={timerStart}
                  />
                )}
                <ThriveStartActions program={program} dayId={dayId} nextDay={nextDay} />
              </>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingHorizontal: '25%',
  },
  listMaxWidth600: {
    flex: 1,
    paddingHorizontal: '5%',
  },
  listContainer: {
    padding: 24,
  },
  spacer: {
    height: 18,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
});

export default observer(ThriveStart);
