import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  Linking,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import Logo from '../../assets/images/logo.svg';
import Wallpaper from '../../assets/images/wallpaper-default.svg';
import config from '../../config';
import { AppStackParamList } from '../AppContainer';

type Props = {
  navigation: StackNavigationProp<AppStackParamList, 'PayWall'>;
  route: RouteProp<AppStackParamList, 'PayWall'>;
};

export enum PRODUCT_IDS {
  MONTHLY = 'com.o2x.app.monthly',
  YEARLY = 'com.o2x.app.yearly',
}

const PayWall: React.FC<Props> = (props) => {
  const {
    navigation,
    route: {
      params: { route, params },
    },
  } = props;
  const styles = useStyleSheet(themedStyles);
  const { isSubscribed } = useIsSubscribed();

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const openTerms = useCallback(() => {
    Linking.openURL(config.urls.terms);
  }, []);

  const openPrivacy = useCallback(() => {
    Linking.openURL(config.urls.privacy);
  }, []);

  const onGetApp = useCallback(() => {
    Linking.openURL(config.urls.app);
  }, []);

  useEffect(() => {
    if (isSubscribed) {
      if (route) {
        // @ts-ignore
        navigation.replace(route, params);
      } else {
        navigation.goBack();
      }
    }
  }, [isSubscribed, route, params]);

  return (
    <Layout style={styles.container}>
      <Wallpaper style={styles.wallpaper} />
      <Image
        style={styles.coverPhoto as StyleProp<ImageStyle>}
        source={require('../../assets/images/cover-photo-pay-wall.png')}
      />
      <Layout style={styles.content}>
        <Logo style={styles.logo} />
        <Text style={styles.logoText} category="label">
          Tactical Performance
        </Text>
        <TouchableOpacity onPress={onGetApp} style={styles.button}>
          <Text style={styles.buttonLabel} category="label">
            Get the App
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.goBackButton} onPress={goBack}>
          <Text style={styles.goBackLabel} category="p2">
            Go back.
          </Text>
        </TouchableOpacity>
        <View style={styles.spacer} />
        <View style={styles.footer}>
          <Text style={styles.terms} category="c1" onPress={openTerms}>
            Terms of Service
          </Text>
          <Text style={styles.divider} category="c1">
            |
          </Text>
          <Text style={styles.terms} category="c1" onPress={openPrivacy}>
            Privacy Policy
          </Text>
        </View>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  button: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    height: 40,
    marginBottom: 18,
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    backgroundColor: '#000000',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent',
    width: 260,
  },
  coverPhoto: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '31%',
    width: '100%',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  goBackButton: {},
  goBackLabel: {
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  logo: {
    alignSelf: 'center',
    marginTop: Dimensions.get('window').height * 0.22,
    marginBottom: 8,
  },
  logoText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: Dimensions.get('window').height * 0.05,
  },
  spacer: {
    flex: 1,
  },
  terms: {
    textDecorationLine: 'underline',
  },
  divider: {
    marginHorizontal: 8,
  },
  wallpaper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});

export default observer(PayWall);
