import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';
import AthleteProfile from 'src/containers/FTEAdmin/AthleteProfile/AthleteProfile';
import Calendar from 'src/containers/FTEAdmin/Calendar';
import Contact from 'src/containers/FTEAdmin/Contact';
import Content from 'src/containers/FTEAdmin/Content';
import Dashboard from 'src/containers/FTEAdmin/Dashboard';
import Library from 'src/containers/FTEAdmin/Library';
import TopNavBar from 'src/containers/FTEAdmin/TopNavBar';
type Props = {};

const Tab = createMaterialTopTabNavigator();
const FTEAdminStack: React.FC<Props> = () => {
  return (
    <Tab.Navigator
      lazy
      swipeEnabled={false}
      timingConfig={{ duration: 0.01 }}
      tabBar={(props) => <TopNavBar {...props} />}
    >
      <Tab.Screen name="Content" component={Content} />
      <Tab.Screen name="Calendar" component={Calendar} />
      <Tab.Screen name="Contact" component={Contact} />
      <Tab.Screen name="Dashboard" component={Dashboard} />
      <Tab.Screen name="Library" component={Library} />
      <Tab.Screen name="AthleteProfile" component={AthleteProfile} />
    </Tab.Navigator>
  );
};

export default FTEAdminStack;
