import {
  Divider,
  Input,
  Layout,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleProp, TouchableWithoutFeedback, ViewStyle } from 'react-native';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  divider?: boolean;
  dividerStyle?: StyleProp<ViewStyle>;
  subtitle?: boolean;
}

const TextInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    initial,
    required,
    divider = true,
    dividerStyle,
    subtitle = false,
  } = props;
  const [value, setValue] = useState(initial as string);
  const [status, setStatus] = useState('basic');
  const inputRef = useRef<Input>(null);
  //   const inputRef = useRef<TextInputComponent>(null);

  const onFocus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    onFocus();
  }, []);

  return (
    <>
      <TouchableWithoutFeedback onPress={onFocus}>
        <Layout style={styles.container}>
          <Input
            ref={inputRef}
            style={styles.weightInput}
            value={value}
            status={status}
            onChangeText={(nextValue) => {
              setStatus('basic');
              setValue(nextValue);
              onChange(question.key, nextValue);
              if (!nextValue && required) setStatus('danger');
            }}
            blurOnSubmit={false}
            underlineColorAndroid="transparent"
            multiline
          />
        </Layout>
      </TouchableWithoutFeedback>
      {divider && <Divider style={[styles.divider, dividerStyle]} />}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  weightInput: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
  label: {
    color: '#C4C4C4',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
});

export default TextInput;
