import { filter, uniq } from 'lodash';
import { computed } from 'mobx';
import { model, Model, modelAction, prop, SnapshotOutOf } from 'mobx-keystone';
import { getListColor, LIST_TYPE } from '../utils/list';

@model('o2x-store/SavedList')
export default class SavedList extends Model({
  id: prop<string>(),
  name: prop<string>(''),
  subtitle: prop<string>(''),
  type: prop<LIST_TYPE>(),
  image: prop<string>(''),
  items: prop<Array<number>>(),
}) {
  getRefId = () => `${this.id}`;

  @modelAction
  update(data: SnapshotOutOf<SavedList>) {
    Object.assign(this, data);
  }

  @modelAction
  addItems(newItems: Array<number>) {
    Object.assign(this.items, uniq([...this.items, ...newItems]));
  }

  @modelAction
  removeItems(oldItems: Array<number>) {
    const filtered = filter(this.items, (i) => oldItems.indexOf(i) === -1);
    this.items = filtered;
  }

  @computed
  get title(): string {
    return this.name;
  }

  @computed
  get color(): string {
    return getListColor(this.type);
  }
}
