import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Layout,
  Radio,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get, indexOf } from 'lodash';
import { ProgramPreview } from 'o2x-store/src/models/Program';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { SectionList, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useNativeStore } from 'src/stores';
import ChevronDown from '../../assets/images/chevron-down.svg';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveOptions'>;
  navigation: StackNavigationProp<AppStackParamList, 'ThriveOptions'>;
};

const ThriveOptionsJump: React.FC<Props> = (props) => {
  const {
    route: {
      params: { programId, dayId },
    },
    navigation,
  } = props;
  const { thrive } = useStore();
  const theme = useTheme();
  const { thriveStart } = useNativeStore();
  const program = thrive.thrivePrograms.get(`${programId}`);
  const day = thrive.thriveProgramDays.get(`${dayId}`)!;
  const [loading, setLoading] = useState(false);
  const [programPreview, setProgramPreview] = useState<ProgramPreview | null>(null);
  const [expanded, setExpanded] = useState<Array<string>>(new Array<string>());
  const styles = useStyleSheet(themedStyles);
  const color = get(program, 'color', 'white');
  const [selected, setSelected] = useState(day.dayDisplay);
  const [selectedId, setSelectedId] = useState(day.id);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  if (!program) {
    return null;
  }

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        const preview = await thrive.fetchThriveProgramPreview(program.id);
        if (preview && !('errors' in preview)) {
          setProgramPreview(preview);
        }
        setLoading(false);
      })();
    }, [program, setProgramPreview, setLoading]),
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onJump = useCallback(async () => {
    if (selected === day.dayDisplay) {
      navigation.navigate('ThriveStart', { id: programId });
      return;
    }
    const doJump = confirm(`Are you sure you want to skip? You will skip to ${selected}`);
    if (doJump) {
      await program.fetchProgramDays();
      navigation.navigate('ThriveStart', {
        id: programId,
        jumpDayId: selectedId,
      });
    }
  }, [selected, selectedId, day]);

  const setExpandedItem = useCallback(
    (itemkey, toExpand) => {
      if (toExpand) {
        setExpanded((prevExpanded) => [...(prevExpanded || []), itemkey]);
      } else {
        setExpanded((prevExpanded) => prevExpanded.filter((e) => e !== itemkey));
      }
    },
    [setExpanded],
  );

  const renderItem = useCallback(
    ({ section, item }) => {
      const itemkey = `${section.title} ${item.title}`;
      const isExpand = indexOf(expanded, itemkey) !== -1;
      return (
        <View style={styles.dayPreview} key={`item-${item.key}`}>
          <Radio
            key={itemkey}
            status="basic-sweat"
            checked={itemkey === selected}
            onChange={() => {
              setSelected(itemkey);
              setSelectedId(item.id);
            }}>
            {(evaProps) => (
              <View style={styles.container}>
                <View style={styles.item}>
                  <View style={styles.indicator} />
                  <View style={styles.content}>
                    <Text style={[styles.dayText, { color }]}>{item.title}</Text>
                  </View>
                  <TouchableOpacity
                    style={styles.expandButton}
                    onPress={() => setExpandedItem(itemkey, !isExpand)}>
                    <ChevronDown />
                  </TouchableOpacity>
                </View>
                {isExpand ? (
                  <View style={styles.dayItems}>
                    {item.data.map((d: string, index: number) => (
                      <View style={styles.listItem} key={`item-${index}`}>
                        <View style={[styles.marker, { backgroundColor: color }]} />
                        <Text style={styles.listItemText}>{d}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}
              </View>
            )}
          </Radio>
        </View>
      );
    },
    [expanded, selected, selectedId],
  );

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav style={styles.navigationStyle} onBack={goBack} />
            </View>
            {loading ? (
              <View style={styles.loading}>
                <Spinner />
              </View>
            ) : (
              <>
                <View style={styles.header}>
                  <Text category="h2">Jump to day</Text>
                </View>
                <View style={styles.scroll}>
                  <View style={styles.content}>
                    {programPreview && (
                      <SectionList
                        style={styles.previewContent}
                        renderItem={renderItem}
                        renderSectionHeader={({ section }) => (
                          <Text style={styles.weekText}>{section.title}</Text>
                        )}
                        sections={programPreview.preview || []}
                      />
                    )}
                  </View>
                </View>
                <Layout style={styles.actions}>
                  <TouchableOpacity style={[styles.button, styles.cancelButton]} onPress={goBack}>
                    <Text style={styles.buttonLabel} category="c1">
                      Cancel
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={[styles.button, styles.quitButton]} onPress={onJump}>
                    <Text style={styles.buttonLabel} category="c1">
                      Jump to
                    </Text>
                    <Text style={styles.buttonLabel} category="c1">
                      {selected}
                    </Text>
                  </TouchableOpacity>
                </Layout>
              </>
            )}
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cancelButton: {
    backgroundColor: 'dark-blue',
  },
  quitButton: {
    backgroundColor: 'olive',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  navigationStyle: {
    backgroundColor: 'black',
  },
  cover: {
    height: 150,
  },
  navigation: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  header: {
    paddingHorizontal: 24,
    alignSelf: 'center',
  },
  rating: {
    marginTop: 30,
  },
  readMore: {
    marginTop: 10,
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 14,
    color: 'gray',
  },
  scroll: {
    flex: 1,
    paddingHorizontal: 40,
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    marginTop: 6,
    marginBottom: 20,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  previewContent: {
    marginBottom: 30,
  },
  weekText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 22,
    marginTop: 26,
    marginBottom: 12,
    textTransform: 'uppercase',
    paddingHorizontal: 26,
  },
  dayPreview: {
    marginBottom: 16,
    paddingLeft: 26,
    paddingRight: 16,
  },
  dayText: {
    fontSize: 18,
    lineHeight: 22,
    textTransform: 'uppercase',
  },
  item: {
    flexDirection: 'row',
    flexGrow: 1,
    height: 35,
    alignItems: 'center',
    marginHorizontal: 10,
    backgroundColor: 'darker-blue',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  listItemText: {
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 7,
  },
  marker: {
    width: 5,
    height: 5,
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 20,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 120,
  },
  indicator: {
    alignSelf: 'stretch',
    backgroundColor: 'orange',
    width: 4,
    marginRight: 10,
  },
  expandButton: { paddingHorizontal: 6 },
  dayItems: {
    flex: 1,
    marginHorizontal: 10,
    backgroundColor: 'darker-blue',
  },
  changeToUp: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
});

export default ThriveOptionsJump;
