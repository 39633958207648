import { observable } from 'mobx';
import { getRoot, model, Model, modelFlow, prop_mapObject, _async, _await } from 'mobx-keystone';
import { RootStore } from '.';
import { Topic, UserQuestion } from '../models/UserQuestion';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/UserQuestionStore')
export default class UserQuestionStore extends Model({
  topics: prop_mapObject(() => new Map<string, Topic>()),
}) {
  @observable
  loading = false;

  @modelFlow
  fetchTopics = _async(function* (this: UserQuestionStore) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: Array<Topic>;
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchTopics(rootStore.auth.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching question topics', error);
      return getError(error);
    }

    this.topics.clear();
    results.forEach((data) => {
      this.topics.set(`${data.id}`, data);
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createQuestion = _async(function* (this: UserQuestionStore, data: UserQuestion) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: UserQuestion;
    let response;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createUserQuestion(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating user question', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
