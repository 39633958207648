import SweatWorkout, {
  SweatWorkoutStep,
} from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';

export const getPrepareSection = (workout: SweatWorkout | undefined) => {
  const sections = workout ? workout.sweatStartSections : [];

  if (workout && workout.ftePrepare && workout.ftePrepare.id) {
    const prepareSection = {
      id: -1,
      circuitSets: 1,
      circuitTime: '',
      circuitRest: '',
      order: 0,
      exercises: workout.ftePrepare.exercises.map(
        (exercise) => exercise as unknown as SweatWorkoutStepExercise,
      ),
    } as SweatWorkoutStep;
    return [prepareSection].map((step, index) => ({
      key: `${step.id}`,
      data: step.exercises.map((exercise) => exercise),
      ...step,
      order: index,
    }))[0];
  }
  return sections.length ? sections[0] : null;
};

export const getRecoverSection = (workout: SweatWorkout | undefined) => {
  const sections = workout ? workout.sweatStartSections : [];
  if (workout && workout.fteRecover && workout.fteRecover.id) {
    const recoverSection = {
      id: -2,
      circuitSets: 1,
      circuitTime: '',
      circuitRest: '',
      order: 0,
      exercises: workout.fteRecover.exercises.map(
        (exercise) => exercise as unknown as SweatWorkoutStepExercise,
      ),
    } as SweatWorkoutStep;

    return [recoverSection].map((step, index) => ({
      key: `${step.id}`,
      data: step.exercises.map((exercise) => exercise),
      ...step,
      order: index,
    }))[0];
  }

  return sections.length ? sections[sections.length - 1] : null;
};

const formatSweatStepExercises = (exercises: any) =>
  exercises.map(({ id, ...rest }: any) =>
    id && id < 1 ? rest : { id, ...rest },
  );

const formatSweatSteps = (steps: any) =>
  steps.map(({ id, exercises, ...rest }: any) => {
    const newExercises = formatSweatStepExercises(exercises);
    return id && id < 1
      ? { exercises: newExercises, ...rest }
      : { id, exercises: newExercises, ...rest };
  });

export const formatWorkout = (workout: any) => {
  if (!!workout.steps) {
    const { steps, ...rest } = workout;
    const newSteps = formatSweatSteps(steps);
    return { steps: newSteps, ...rest };
  }
  return workout;
};
