import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import CreateProfile from '../../CreateProfile';
import Landing from '../../Landing';
import ResetPassword from '../../Landing/ResetPassword';
import ResetPasswordConfirm from '../../Landing/ResetPasswordConfirm';
import SignIn from '../../SignIn';
import SignUpCode from '../../SignUpCode';

export type AuthStackParamList = {
  CreateProfile: { registrationCode: string };
};

type Props = {};

const Stack = createStackNavigator();

const AuthStack: React.FC<Props> = () => (
  <Stack.Navigator headerMode="none">
    {Platform.OS !== 'web' && (
      <Stack.Screen name="Landing" component={Landing} />
    )}
    <Stack.Screen name="SignIn" component={SignIn} />
    <Stack.Screen name="SignUpCode" component={SignUpCode} />
    <Stack.Screen name="CreateProfile" component={CreateProfile} />
    <Stack.Screen name="ResetPassword" component={ResetPassword} />
    <Stack.Screen
      name="ResetPasswordConfirm"
      component={ResetPasswordConfirm}
    />
  </Stack.Navigator>
);

export default AuthStack;
