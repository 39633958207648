import { observable } from 'mobx';
import { getRoot, model, Model, modelFlow, _async, _await } from 'mobx-keystone';
import { RootStore } from '.';
import { FTEEventModel } from '../models/Event';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/FTEEventStore')
export default class FTEEventStore extends Model({}) {
  @observable
  loading = false;

  @modelFlow
  fetchFTEEvents = _async(function* (
    this: FTEEventStore,
    id: number,
    month?: number,
    year?: number,
    limit?: number,
    url?: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchFTEEvents(rootStore.auth.token, id, month, year, limit, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching fte events', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  fetchPendingInvites = _async(function* (this: FTEEventStore, id: number) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.fetchPendingInvites(rootStore.auth.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching fte events', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  createFTEEvent = _async(function* (this: FTEEventStore, data: FTEEventModel) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: FTEEventModel;
    data.fteUser = rootStore.auth.user.id;
    try {
      ({
        response: { entities },
      } = yield* _await(api.createFTEEvent(rootStore.auth.token, data)));
      if (entities) {
      }
    } catch (error) {
      console.warn('[DEBUG] error creating event', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  deleteFTEEvent = _async(function* (this: FTEEventStore, id: number, all: boolean) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.deleteFTEEvent(rootStore.auth.token, id, all)));
    } catch (error) {
      console.warn('[DEBUG] error deleting event', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  updateFTEEvent = _async(function* (
    this: FTEEventStore,
    data: Partial<FTEEventModel>,
    all: boolean,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateFTEEvent(rootStore.auth.token, data, all)));
    } catch (error) {
      console.warn('[DEBUG] error updating event', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  sendNotification = _async(function* (
    this: FTEEventStore,
    data: {
      recipientUsers: string;
      title: string;
      body: string;
      extra?: {};
    },
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(api.sendNotification(rootStore.auth.token, data)));
    } catch (error) {
      console.warn('[DEBUG] error sending event invitation', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });
}
