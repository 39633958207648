import { debounce } from 'lodash';
import { onSnapshot, registerRootStore } from 'mobx-keystone';
import { StorageService } from 'o2x-store/src/services/storage';
import React from 'react';
import WorkoutFilter from 'src/models/WorkoutFilter';
import ExploreFilter from '../models/ExploreFilter';
import MediaStorage from '../models/MediaStorage';
import Notification from '../models/Notification';
import Settings from '../models/Settings';
import SweatStart from '../models/SweatStart';
import ThriveStart from '../models/ThriveStart';
import TimerStart from '../models/TimerStart';
import NativeStore from './NativeStore';

const StoreContext = React.createContext<NativeStore>({} as NativeStore);

const useNativeStore = () => React.useContext(StoreContext);
const { Provider: NativeStoreProvider } = StoreContext;

const createNativeStore = (storageService: StorageService) => {
  const nativeStore = new NativeStore({
    exploreFilter: new ExploreFilter({ search: '', filters: {} }),
    workoutFilter: new WorkoutFilter({ filters: {} }),
    sweatStart: new SweatStart({}),
    thriveStart: new ThriveStart({}),
    timerStart: new TimerStart({}),
    mediaStorage: new MediaStorage({}),
    settings: new Settings({}),
    notification: new Notification({}),
  });
  nativeStore.storageService = storageService;
  registerRootStore(nativeStore);
  onSnapshot(
    nativeStore,
    debounce(() => {
      nativeStore.save();
    }, 3000),
  );

  return nativeStore;
};

export { NativeStore, NativeStoreProvider, createNativeStore, useNativeStore };
