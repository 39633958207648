import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { range } from 'lodash';
import { Distribution } from 'o2x-store/src/models/FormAssesment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  distribution: Distribution;
  backgroundColor: string;
  scoreColor: string;
  // score is the percentage score of the user (ex. 85)
  score: number | null;
};

const HPAGraph: React.FC<Props> = ({ distribution, backgroundColor, scoreColor, score }) => {
  if (!distribution) return <></>;

  const styles = useStyleSheet(themedStyles);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const barHeight = isMobile ? 200 : 240;

  const list = Object.values(distribution);
  const total = list.reduce((a, b) => a + b, 0);

  let score_index = -1;

  console.log(`score: ${score}`);

  if (score) score_index = Math.ceil(score / 10.0) - 1;
  if (score_index < 0) score_index = 0;

  console.log(`score index: ${score_index}`);

  return (
    <Layout style={isMobile ? styles.containerMobile : styles.container}>
      {range(0, 10).map((index) => {
        let distPercentage = 0;

        distPercentage = list[index] / total;

        return (
          <Layout style={styles.item} key={index}>
            <Layout style={isMobile ? styles.barContainerMobile : styles.barContainer}>
              <Text style={styles.label} category="c2">
                {isMobile
                  ? `${(distPercentage * 100).toFixed(0)}%`
                  : `${(distPercentage * 100).toFixed(1)}%`}
              </Text>
              <Layout
                style={[
                  isMobile ? styles.barMobile : styles.bar,
                  {
                    backgroundColor: index === score_index ? scoreColor : backgroundColor,
                    height: barHeight * distPercentage,
                  },
                ]}
              />
            </Layout>
            <Layout style={styles.divider} />
            <Text style={[styles.label, isMobile && { marginTop: 14 }]} category="c2">
              {(index + 1) * 10}%
            </Text>
          </Layout>
        );
      })}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bar: {
    backgroundColor: 'blue-secondary',
    width: 50,
    marginHorizontal: 2,
  },
  barMobile: {
    backgroundColor: 'blue-secondary',
    width: 30,
    marginHorizontal: 2,
  },
  barContainer: {
    height: 240,
    width: 50,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  barContainerMobile: {
    height: 200,
    width: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  container: {
    height: 310,
    width: '50%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  divider: {
    height: 2.7,
    alignSelf: 'stretch',
    backgroundColor: '#C4C4C4',
  },
  item: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  label: {
    marginTop: 18,
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    textTransform: 'uppercase',
    color: '#C4C4C4',
  },
  spacer: {
    backgroundColor: 'transparent',
  },
});

export default HPAGraph;
