import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelFlow,
  ModelInstanceCreationData,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { RootStore } from '.';
import config from '../config';
import Recipe from '../models/Recipe';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('o2x-store/EatStore')
export default class EatStore extends Model({
  recipes: prop_mapObject(() => new Map<string, Recipe>()),
}) {
  _recipesLoading = '';

  @observable
  loading = false;

  @modelFlow
  fetchRecipes = _async(function* (this: EatStore, nextUrl?: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._recipesLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    if (!nextUrl) this.recipes.clear();

    let url = nextUrl || `${config.urls.recipes}?limit=10`;
    let results: [ModelInstanceCreationData<Recipe>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(api.fetchRecipes(rootStore.auth.token, url)));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error);
    }
    if (runId !== this._recipesLoading || runId !== rootStore.fetchId) {
      console.log('CURRENT FETCH ID', rootStore.fetchId, runId);
      return getSuccess();
    }
    results.forEach((recipeData) => {
      const id = `${recipeData.id}`;
      const recipe = new Recipe(recipeData);
      this.recipes.set(id, recipe);
    });

    this._recipesLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  fetchRecipesByIds = _async(function* (this: EatStore, ids: Array<string>) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    this.loading = true;

    let results: [ModelInstanceCreationData<Recipe>];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchRecipesByIds(rootStore.auth.token, ids.toString())));
    } catch (error) {
      console.warn('[DEBUG] error fetching recipes', error);
      return getError(error);
    }

    results.forEach((recipeData) => {
      const id = `${recipeData.id}`;
      if (this.recipes.has(id)) {
        this.recipes.get(id)!.update(recipeData);
      } else {
        const recipe = new Recipe(recipeData);
        this.recipes.set(id, recipe);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  searchRecipes = _async(function* (this: EatStore, filterString: string) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth || !rootStore.auth.token) {
      return getSuccess();
    }

    const runId = Math.random().toString(36).slice(-6);
    this._recipesLoading = runId;
    rootStore.fetchId = runId;
    this.loading = true;
    this.recipes.clear();

    let url = `${config.urls.recipes}?limit=10&${filterString}`;
    // while (url) {
    let results: [ModelInstanceCreationData<Recipe>];
    try {
      ({
        response: {
          entities: { results, next: url },
        },
      } = yield* _await(api.searchRecipes(rootStore.auth.token, filterString, url)));
    } catch (error) {
      console.warn('[DEBUG] error searching recipes', error);
      return getError(error);
    }
    if (runId !== this._recipesLoading || runId !== rootStore.fetchId) {
      return getSuccess();
    }
    results.forEach((recipeData) => {
      const id = `${recipeData.id}`;
      const recipe = new Recipe(recipeData);
      this.recipes.set(id, recipe);
    });
    // }

    this._recipesLoading = '';
    this.loading = false;
    return getSuccess({ next: url });
  });

  @modelFlow
  clearRecipes = _async(function* (this: EatStore) {
    this.recipes.clear();
  });
}
