import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useMemo } from 'react';
import { useNativeStore } from '../../stores';
import MediaFileDownloader from './MediaFileDownloader';

type Props = {};

const SweatVideoStorageObserver: React.FC<Props> = (props) => {
  const { mediaStorage } = useNativeStore();
  const { sweat } = useStore();

  // Create an ordered list of sweat video files to download
  const globalExerciseIds = useMemo(
    () =>
      Array.from(sweat.sweatGlobalStep.values(), (globalStep) =>
        globalStep.exercises.map((globalExercise) => globalExercise.exercise),
      ).reduce((a, b) => [...a, ...b], []),
    [sweat.sweatGlobalStep.size],
  );
  const activeWorkoutExerciseIds = useMemo(
    () =>
      sweat.activeSweatWorkoutIds
        .map(
          (id) =>
            (sweat.sweatWorkouts &&
              sweat.sweatWorkouts
                .get(`${id}`)
                ?.steps?.map(
                  (step) => step.exercises?.map((stepExercise) => stepExercise.exercise) || [],
                )
                .reduce((a, b) => [...a, ...b], [])) ||
            [],
        )
        .reduce((a, b) => [...a, ...b], [])
        .filter((id) => !globalExerciseIds.includes(id)),
    [sweat.activeSweatWorkoutIds.length],
  );
  const exercises = useMemo(
    () =>
      Array.from(sweat.sweatExercises.values()).filter(
        (exercise) =>
          !globalExerciseIds.includes(exercise.id) &&
          !activeWorkoutExerciseIds.includes(exercise.id),
      ),
    [globalExerciseIds, activeWorkoutExerciseIds, sweat.sweatExercises.size],
  );
  const mediaFiles = useMemo(
    () => [
      ...globalExerciseIds.map((id) =>
        mediaStorage.getOrCreateMediaFile(sweat.sweatExercises.get(`${id}`), 'video'),
      ),
      ...activeWorkoutExerciseIds.map((id) =>
        mediaStorage.getOrCreateMediaFile(sweat.sweatExercises.get(`${id}`), 'video'),
      ),
      ...exercises.map((exercise) => mediaStorage.getOrCreateMediaFile(exercise, 'video')),
    ],
    [globalExerciseIds, activeWorkoutExerciseIds, exercises],
  );

  return <MediaFileDownloader mediaFiles={mediaFiles} />;
};

export default observer(SweatVideoStorageObserver);
