import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  title: string;
  name: string;
  onNavigate: (route: string) => void;
  selected: boolean;
};

const SettingsContent: React.FC<Props> = (props) => {
  const { style, title, onNavigate, selected } = props;
  const styles = useStyleSheet(themedStyles);
  const onPress = useCallback(() => {
    onNavigate(name);
  }, []);

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Text style={[styles.title, selected && styles.selected]}>{title}</Text>
    </TouchableOpacity>
  );
};

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    paddingLeft: 25,
    flexDirection: 'row',
    marginBottom: 40,
  },
  icon: {
    height: 30,
    width: 30,
  },
  selected: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: 'white',
  },
  title: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '400',
    color: '#C4C4C4',
  },
  notification: {
    height: 8,
    width: 8,
    borderRadius: 8,
    position: 'absolute',
    top: '25%',
    left: '9%',
  },
});

export default SettingsContent;
