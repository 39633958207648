import { filter, get, isArray, isNumber, last, transform, uniq } from 'lodash';
import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import { sweatWorkoutStep } from '../constants/sweat';
import * as analytics from '../services/analytics';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { LIST_TYPE } from '../utils/list';
import { getError, getSuccess } from '../utils/models';
import { getTaskColor, TASK_TYPE } from '../utils/tasks';
import { DayPreview } from './Program';
import SweatGlobalStep from './SweatGlobalStep';
import SweatWorkoutStepExercise from './SweatWorkoutStepExercise';
import UserSweatWorkoutProgress from './UserSweatWorkoutProgress';

export type SweatWorkoutStep = {
  id: number;
  circuitSets: number;
  circuitTime: string;
  circuitRest: string;
  circuitTimer: string;
  circuitTimerWork: number | null;
  circuitTimerRest: number | null;
  specifyRounds: boolean;
  circuitInstructions: string;
  order: number;
  exercises: SweatWorkoutStepExercise[];
};

export type SweatStartSection = SweatWorkoutStep & {
  key: string;
  data: SweatWorkoutStepExercise[];
};

export type WorkoutDetail = {
  id: number;
  name: string;
  detail: DayPreview;
};

// Rating related props are no-op.
@model('o2x-store/SweatWorkout')
export default class SweatWorkout extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  notes: prop<string>(''),
  week: prop<number>(),
  difficulty: prop<string>(''),
  category: prop<string>(''),
  day: prop<number>(),
  program: prop<number>(),
  instructions: prop<string>(''),
  steps: prop<SweatWorkoutStep[] | null>(null),
  userProgress: prop<UserSweatWorkoutProgress | null>(null),
  displayName: prop<string>(''),
  dayDisplay: prop<string>(''),
  subtitle: prop<string>(''),
  rating: prop<number>(0),
  userRating: prop<number>(0),
  fteOwner: prop<number>(),
  ftePrepare: prop<number | undefined>(),
  fteRecover: prop<number | undefined>(),
  ossVisible: prop<boolean>(),
  isVisible: prop<boolean>(),
  included: prop<boolean>(true),
  saveList: prop<Array<number>>(() => new Array<number>()),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(source: ModelInstanceCreationData<SweatWorkout>) {
    const data = { ...source };
    const { steps } = data;
    delete data.steps;

    Object.assign(this, data);

    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    if (isArray(steps) && steps.length > 0 && !isNumber(steps[0])) {
      this.steps = steps.map((step) =>
        transform(
          step,
          (result: any, value: any, key) => {
            if (key === 'exercises') {
              result[key] = value.map((source: any) => {
                const data = { ...source };
                const { exercise } = data;
                delete data.exercise;

                const workoutExercise = new SweatWorkoutStepExercise(data);
                workoutExercise.exercise = exercise.id;

                sweat.createOrUpdateSweatExercise(exercise);

                return workoutExercise;
              });
            } else {
              result[key] = value;
            }
          },
          {},
        ),
      );
    }

    const ftePrepare = {
      ...(data.ftePrepare as any as ModelInstanceCreationData<SweatGlobalStep>),
    };
    this.ftePrepare = ftePrepare.id;
    if (ftePrepare && ftePrepare.id) {
      sweat.createOrUpdateSweatGlobalStep(ftePrepare);
    }
    const fteRecover = {
      ...(data.fteRecover as any as ModelInstanceCreationData<SweatGlobalStep>),
    };
    this.fteRecover = fteRecover.id;
    if (fteRecover && fteRecover.id) {
      this.fteRecover = fteRecover.id;
      sweat.createOrUpdateSweatGlobalStep(fteRecover);
    }
  }

  @modelFlow
  fetch = _async(function* (this: SweatWorkout) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<SweatWorkout>;
    if (this.program) {
      try {
        ({
          response: { entities },
        } = yield* _await(
          api.fetchSweatProgramWorkout(rootStore.auth.token, this.program, this.id),
        ));
      } catch (error) {
        console.warn('[DEBUG] error fetching sweat workout', error);
        return getError(error);
      }
    } else {
      try {
        ({
          response: { entities },
        } = yield* _await(api.fetchSweatWorkout(rootStore.auth.token, this.id)));
      } catch (error) {
        console.warn('[DEBUG] error fetching sweat workout', error);
        return getError(error);
      }
    }

    this.update(entities);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  startWorkout = _async(function* (this: SweatWorkout) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities: ModelInstanceCreationData<SweatWorkout>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.startSweatWorkout(rootStore.auth.token, this.id)));
    } catch (error) {
      console.warn('[DEBUG] error starting sweat workout', error);
      return getError(error);
    }

    this.update(entities);

    analytics.logSweatWorkoutStart(this);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  markComplete = _async(function* (
    this: SweatWorkout,
    data: {
      totalTime: number;
      stepExerciseTimeBreakdown: { [key: number]: number };
      globalStepExerciseTimeBreakdown: { [key: number]: number };
      circuitBreakdownSets?: { [key: number]: number };
    },
  ) {
    const rootStore = getRoot<RootStore>(this);
    if (!rootStore.auth?.token) {
      return getSuccess();
    }
    this.loading = true;
    let entities: ModelInstanceCreationData<SweatWorkout>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.markCompleteSweatWorkout(rootStore.auth.token, this.id, data)));
    } catch (error) {
      console.warn('[DEBUG] error marking complete sweat workout', error);
      return getError(error);
    }
    this.update(entities);
    analytics.logSweatWorkoutComplete(this);
    this.loading = false;
    return getSuccess();
  });

  get firstExercise(): number | null {
    return get(this, 'steps[0].exercises[0].exercise', null);
  }

  get lastExercise(): number | null {
    if (!this.steps || this.steps.length === 0) {
      return null;
    }
    const { exercises } = last(this.steps) as SweatWorkoutStep;
    if (!exercises || exercises.length === 0) {
      return null;
    }
    return last(exercises)!.exercise;
  }

  @modelAction
  setSaveList(listId: number) {
    this.saveList = uniq([...this.saveList, listId]);
  }

  @modelAction
  removeSaveList(listId: number) {
    const filtered = filter(this.saveList, (l: number) => l !== listId);
    this.saveList = filtered;
  }

  @modelAction
  clearSaveList() {
    this.saveList = [];
  }

  @computed
  get title(): string {
    return this.displayName;
  }

  @computed
  get image(): string {
    return '';
  }

  @computed
  get imageBanner(): string {
    return '';
  }

  @computed
  get imageCard(): string {
    return '';
  }

  @computed
  get imageThumbnail(): string {
    return '';
  }

  @computed
  get type(): TASK_TYPE {
    return TASK_TYPE.SWEAT;
  }

  @computed
  get sweat_list_type(): LIST_TYPE {
    return LIST_TYPE.SWEAT_WORKOUT;
  }

  @computed
  get color(): string {
    return getTaskColor(TASK_TYPE.SWEAT);
  }

  @computed
  get isExercisesReady(): boolean {
    const step = get(this, 'steps[0]');
    const stepExercise = get(step, 'exercises[0]');
    const exercise = get(stepExercise, 'exercise');
    return step && stepExercise && exercise;
  }

  @computed
  get sweatStartSections(): SweatStartSection[] {
    const step = get(this, 'steps[0]');
    if (!this.steps || isNumber(step)) {
      return [];
    }
    const rootStore = getRoot<RootStore>(this);
    const { sweat } = rootStore;

    const prepare = sweat.sweatGlobalStep.get(
      this.ftePrepare !== undefined ? `${this.ftePrepare}` : 'prepare',
    );
    const recover = sweat.sweatGlobalStep.get(
      this.fteRecover !== undefined ? `${this.fteRecover}` : 'recover',
    );

    let prepareSection;
    let recoverSection;

    if (prepare) {
      prepareSection = {
        id: -1,
        circuitSets: 1,
        circuitTime: '',
        circuitRest: '',
        order: 0,
        exercises: prepare.exercises.map((exercise) => exercise as SweatWorkoutStepExercise),
      } as SweatWorkoutStep;
    }

    if (recover) {
      recoverSection = {
        id: -2,
        circuitSets: 1,
        circuitTime: '',
        circuitRest: '',
        order: 0,
        exercises: recover.exercises.map((exercise) => exercise as SweatWorkoutStepExercise),
      } as SweatWorkoutStep;
    }

    // Insert prepare and recover.

    const steps = [
      prepareSection || sweatWorkoutStep.prepare,
      ...this.steps,
      recoverSection || sweatWorkoutStep.recover,
    ];

    return steps.map((step, index) => ({
      key: `${step.id}`,
      data: step.exercises.map((exercise) => exercise),
      ...step,
      order: index,
    }));
  }

  // Rating related functions are no-op.
  fetchRatings = () => Promise.resolve(getSuccess());

  postRate = (rate: number) => Promise.resolve(getSuccess());
}
