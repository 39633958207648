import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get, isUndefined } from 'lodash';
import { EMOJI_NAMES, EMOJI_NAME_TO_UNICODE } from 'o2x-store/src/utils/emoji';
import React from 'react';
import { LayoutRectangle, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import DividerIcon from '../../assets/images/divider.svg';

type Props = {
  onSelect: (key: EMOJI_NAMES) => void;
  isVisible: boolean;
  showLabel?: boolean;
  onClose: () => void;
  showDivider?: boolean;
  tapCoords?: LayoutRectangle;
};

const EmojiSelector: React.FC<Props> = (props) => {
  const insets = useSafeAreaInsets();
  const {
    onSelect,
    isVisible = true,
    showLabel = true,
    onClose,
    showDivider = false,
    tapCoords,
  } = props;
  const styles = useStyleSheet(themedStyles);

  if (!isVisible) {
    return null;
  }

  const isTablet = useMediaQuery({
    maxDeviceWidth: 900,
  });

  return (
    <Layout
      style={[
        isTablet ? styles.containerTablet : styles.container,
        { marginBottom: insets.bottom },
        !isUndefined(tapCoords) && isTablet
          ? {
              top: tapCoords.top - 120,
              left: 0,
              right: 0,
            }
          : {
              top: tapCoords.top + 20,
              left: 100,
            },
      ]}>
      {showDivider && <DividerIcon style={styles.divider} width={79} height={3} />}
      <View style={styles.contentContainer}>
        <View style={styles.selection}>
          {Object.keys(EMOJI_NAME_TO_UNICODE).map((k: string, idx: number) => (
            <TouchableOpacity
              onPress={() => onSelect(k)}
              style={isTablet ? styles.iconTablet : styles.icon}
              key={`emoji-${idx}`}>
              <Text style={styles.emoji}>{get(EMOJI_NAME_TO_UNICODE, k)}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    height: 58,
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'black',
  },
  containerTablet: {
    position: 'absolute',
    bottom: 0,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {},
  divider: {
    marginBottom: 5,
    alignSelf: 'center',
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 21,
  },
  selection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  icon: {
    width: 50,
    height: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
  },
  iconTablet: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: 'black',
  },
  emoji: {},
});

export default EmojiSelector;
