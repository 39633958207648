import { Radio, RadioGroup, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
}

const RadioInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { question, onChange, initial, status = '' } = props;
  let initialIndex;
  (question.options || []).forEach((option: Array<number | string>, index: number) => {
    if (option[0] === initial) {
      initialIndex = index;
    }
  });
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(initialIndex);
  return (
    <View style={styles.container}>
      <RadioGroup
        style={styles.optionGroup}
        selectedIndex={selectedIndex}
        onChange={(index) => {
          setSelectedIndex(index);
          onChange(question.key, question.options[index][0]);
        }}>
        {question.options.map((option: any, index: number) => (
          <Radio
            status={status}
            style={selectedIndex === index ? styles.optionSelected : styles.option}
            key={option[0]}>
            {(evaProps) => <Text {...evaProps}>{option[1]}</Text>}
          </Radio>
        ))}
      </RadioGroup>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  weightInput: {
    fontSize: 20,
    width: '100%',
    outline: 'none',
    borderWidth: 0,
  },
  label: {
    color: '#C4C4C4',
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  },
  optionGroup: {
    width: '100%',
  },
  option: {
    width: '100%',
    backgroundColor: 'dark-blue',
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginBottom: 0,
  },
  optionSelected: {
    width: '100%',
    backgroundColor: 'dark-blue',
    paddingVertical: 9,
    paddingHorizontal: 12,
    marginBottom: 0,
  },
  optionText: {
    color: 'dark-blue',
    fontSize: 18,
    lineHeight: 22,
  },
  optionTextSelected: {
    color: 'green',
    fontSize: 18,
    lineHeight: 22,
  },
});

export default RadioInput;
