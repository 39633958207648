import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { get } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserEatProfile } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import CheckBox from '../../components/CheckBox';
import BottomNav from '../../components/Question/BottomNav';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';
import ScoreBadge from '../../components/ScoreBadge';

type Props = {
  route?: any | null;
};

const EatProfileSummary: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const [eatProfile, setEatProfile] = useState<UserEatProfile | null>(null);
  const [answers, setAnswers] = useState<Array<string>>([]);
  const numBars = store.question.eatProfileQuestions.size;

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    const { eatProfile } = store.user;
    if (eatProfile) {
      setEatProfile(eatProfile);
      setAnswers(eatProfile?.monitorFields);
    }
  }, [eatProfile, store.question.eatProfileQuestions]);

  const onNext = useCallback(() => {
    store.user.updateEatProfile({
      ...getSnapshot(eatProfile),
      id: eatProfile ? eatProfile.id : null,
      monitorFields: answers,
    });
    navigation.popToTop();
  }, [answers, store]);

  const onChange = useCallback(
    (key: string, value: boolean) => {
      let updatedAnswers: string[] = answers;
      if (value) {
        updatedAnswers = [...answers, key];
      } else {
        updatedAnswers = answers.filter((a) => a !== key);
      }
      setAnswers(updatedAnswers);
    },
    [answers, setAnswers],
  );

  const goBack = useCallback(() => {
    navigation.popToTop();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose onClose={goBack} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <Text style={styles.title}>Great! Choose which statistics to track</Text>
              <Text style={styles.description}>
                We will check in on you daily to monitor the selected items
              </Text>
              <ScrollView style={styles.listContainer}>
                {eatProfile && (
                  <View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="waterOz"
                        fill={theme.green}
                        checked={answers.indexOf('waterOz') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('waterOz', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Water Intake</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.waterOz}</Text>
                            <Text style={styles.fieldUnit}> ounces a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'waterOz')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="treatsPerDay"
                        fill={theme.green}
                        checked={answers.indexOf('treatsPerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('treatsPerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Indulgences</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.treatsPerDay}</Text>
                            <Text style={styles.fieldUnit}> servings a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'treatsPerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="fruitsPerDay"
                        fill={theme.green}
                        checked={answers.indexOf('fruitsPerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('fruitsPerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Fruit Consumption</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.fruitsPerDay}</Text>
                            <Text style={styles.fieldUnit}> servings a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'fruitsPerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="vegetablesPerDay"
                        fill={theme.green}
                        checked={answers.indexOf('vegetablesPerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('vegetablesPerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Vegetable Consumption</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.fruitsPerDay}</Text>
                            <Text style={styles.fieldUnit}> servings a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'vegetablesPerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="caffeinePerDay"
                        fill={theme.green}
                        checked={answers.indexOf('caffeinePerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('caffeinePerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Caffeine Intake</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.caffeinePerDay}</Text>
                            <Text style={styles.fieldUnit}> drinks a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'caffeinePerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="alcoholPerDay"
                        fill={theme.green}
                        checked={answers.indexOf('alcoholPerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('alcoholPerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Alcohol Intake</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.alcoholPerDay}</Text>
                            <Text style={styles.fieldUnit}> drinks a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'alcoholPerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="caloriesPerDay"
                        fill={theme.green}
                        checked={answers.indexOf('caloriesPerDay') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('caloriesPerDay', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Calories Intake</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>
                              {eatProfile.caloriesPerDay || 'N/A'}
                            </Text>
                            <Text style={styles.fieldUnit}> calories a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'caloriesPerDay')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="energyLevel"
                        fill={theme.green}
                        checked={answers.indexOf('energyLevel') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('energyLevel', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Energy Level</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.energyLevelDisplay}</Text>
                            <Text style={styles.fieldUnit}> energy level a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(eatProfile.score, 'energyLevel')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="weightGoal"
                        fill={theme.green}
                        checked={answers.indexOf('weightGoal') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('weightGoal', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Weight Goal</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{eatProfile.weightGoalDisplay}</Text>
                            <Text style={styles.fieldUnit}> goal a day</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={numBars} />
              <BottomNav
                showBack={false}
                onNext={onNext}
                nextLabel="Finish"
                nextColor={theme.olive}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '25%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 25,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 25,
  },
  inputText: {
    backgroundColor: 'transparent',
  },
  input: {
    paddingTop: 20,
  },
  inputView: {
    flexDirection: 'row',
    paddingBottom: 9,
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    marginLeft: 18,
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 9,
    justifyContent: 'space-between',
  },
  fieldLabel: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'white',
  },
  fieldValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  fieldNumber: {
    fontSize: 24,
    color: 'white',
  },
  fieldUnit: {
    fontSize: 10,
    lineHeight: 20,
    color: 'blue-secondary',
    textTransform: 'uppercase',
    marginLeft: 8,
  },
  listContainer: {
    flex: 1,
  },
});

export default observer(EatProfileSummary);
