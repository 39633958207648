import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useRef, useState } from 'react';
import { Animated, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DOWNLOAD_TYPE } from 'src/models/DownloadedList';
import CheckBox from '../../components/CheckBox';
import DownloadListItem from '../../components/Downloaded/DownloadListItem';
import NavigationBar from '../../components/NavigationBar';
import config from '../../config';
import { useNativeStore } from '../../stores';

type Props = {
  route: any;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const DownloadEdit: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const { downloads } = useNativeStore();
  const { auth } = useStore();

  const { recipes, sweatPrograms, sweatWorkouts, thrivePrograms } = downloads;
  const { title, downloadType, type } = props.route.params;

  const opacity = useRef(new Animated.Value(0)).current;

  const color = getTaskColor(type);
  const list =
    downloadType === DOWNLOAD_TYPE.RECIPE
      ? recipes
      : downloadType === DOWNLOAD_TYPE.SWEAT_PROGRAM
      ? sweatPrograms
      : downloadType === DOWNLOAD_TYPE.SWEAT_WORKOUT
      ? sweatWorkouts
      : downloadType === DOWNLOAD_TYPE.THRIVE_PROGRAM
      ? thrivePrograms
      : null;

  const [currentList, setCurrentList] = useState(list && Array.from(list.values()));
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const onSubmit = useCallback(async () => {
    if (!currentList || !auth.user) return null;
    downloads.update(currentList, downloadType);
    downloads.updateLocalStorage(auth.user.id);
    navigation.goBack();
  }, [currentList]);

  const onChange = useCallback(
    (item) => {
      if (selectedItems.includes(item.id)) {
        const updatedItems = selectedItems.filter((i) => item.id !== i);
        setSelectedItems(updatedItems);
      } else {
        const updatedItems = [...selectedItems, item.id];
        setSelectedItems(updatedItems);
      }
    },
    [selectedItems],
  );

  const onRemove = useCallback(() => {
    if (!currentList) return;

    const updatedList = currentList.filter((item: any) => !selectedItems.includes(item.id));
    setCurrentList(updatedList);
    setSelectedItems([]);
  }, [currentList, selectedItems]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  const onScroll = useCallback((e) => {
    opacity.setValue(e.nativeEvent.contentOffset.y);
  }, []);

  if (!currentList || !selectedItems) return null;

  const titleOpacity = opacity.interpolate({
    inputRange: [0, config.titleDisplayOffsetOnScroll],
    outputRange: [0, 1],
  });

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      <AnimatedScrollView
        style={[styles.scrollContainer, { paddingTop: insets.top }]}
        onScroll={onScroll}>
        <View style={styles.scroll}>
          <Layout style={styles.scrollWrapper}>
            <View style={styles.headerContainer}>
              <Text category="h2" style={styles.header}>
                {`Downloaded ${title}s`}
              </Text>
            </View>
            <View style={styles.listContainer}>
              <View style={styles.actionLinks}>
                <Text style={[styles.selected, { color }]}>{selectedItems.length} Selected</Text>
                <TouchableOpacity onPress={onRemove}>
                  <Text style={styles.link}>Remove</Text>
                </TouchableOpacity>
              </View>
              <View style={styles.container}>
                {currentList.map((item: any) => (
                  <TouchableOpacity
                    key={item.id}
                    onPress={() => onChange(item)}
                    style={styles.itemContainer}>
                    <CheckBox
                      fill={color}
                      field={item.id.toString()}
                      checked={selectedItems.includes(item.id)}
                      style={styles.input}
                      disabled
                    />
                    <View style={styles.inputLabel}>
                      <DownloadListItem item={item} type={type} disabled />
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </Layout>
        </View>
      </AnimatedScrollView>
      <NavigationBar
        style={[styles.navigation, { top: insets.top }]}
        titleOpacity={titleOpacity}
        title=" Downloaded Recipes"
      />
      <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
        <TouchableOpacity style={[styles.button, styles.buttonCancel]} onPress={goBack}>
          <Text style={styles.buttonLabel} category="c1">
            Cancel
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={onSubmit}>
          <Text style={styles.buttonLabel} category="c1">
            Save
          </Text>
        </TouchableOpacity>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'olive',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  scrollContainer: {
    flex: 1,
  },
  selected: {
    fontSize: 14,
  },
  header: {
    marginVertical: 30,
    fontSize: 24,
  },
  scroll: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 60,
  },
  scrollWrapper: {
    flex: 1,
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  inputLabel: {
    flex: 1,
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  listContainer: {
    flex: 1,
  },
  input: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 6,
    marginLeft: 16,
  },
});

export default observer(DownloadEdit);
