import '@expo/match-media';
import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LabeledInput from '../../components/LabeledInput';
import Account from '../Account';

type Props = {};

const SignUpCode: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const [registrationCode, setRegistrationCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmitCode = useCallback(() => {
    if (registrationCode.length === 0) {
      setErrorMessage('Please provide access code');
      return;
    }

    navigation.navigate('CreateProfile', {
      registrationCode,
    });
  }, [registrationCode]);

  const onSkip = useCallback(() => {
    navigation.navigate('CreateProfile', { registrationCode: '' });
  }, []);

  const onSignIn = useCallback(() => {
    navigation.navigate('SignIn');
  }, []);

  const isTabletOrMobile = useMediaQuery({
    maxDeviceWidth: 1024,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Account>
      {isTabletOrMobile && (
        <Text style={styles.portalText} category="label">
          CREATE AN ACCOUNT
        </Text>
      )}
      {!!errorMessage && (
        <Text style={styles.error} category="label">
          {errorMessage}
        </Text>
      )}
      <Layout style={isMobile ? styles.signUpCodeContainerMobile : styles.signUpCodeContainer}>
        <LabeledInput
          style={styles.inputContainer}
          label="Access Code"
          onChangeText={(text) => setRegistrationCode(text)}
          autoCapitalize="none"
          returnKeyType="next"
          divider
          defaultValue={registrationCode}
        />

        <TouchableOpacity style={styles.button} onPress={onSubmitCode}>
          <Text style={styles.buttonLabel} category="s2">
            Continue
          </Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.skipButton} onPress={onSkip}>
          <Text style={styles.skipLabel} category="label">
            I don't have a code
          </Text>
        </TouchableOpacity>

        <Layout style={[styles.row, { marginTop: 15 }]}>
          <Text category="label" style={styles.normal}>
            Already have an Account?{' '}
          </Text>
          <TouchableOpacity onPress={onSignIn}>
            <Text category="label" style={[styles.normal, styles.link]}>
              Sign In
            </Text>
          </TouchableOpacity>
        </Layout>
      </Layout>
    </Account>
  );
};

const themedStyles = StyleService.create({
  signUpCodeContainer: {
    backgroundColor: 'transparent',
    width: '50%',
  },
  signUpCodeContainerMobile: {
    backgroundColor: 'transparent',
    width: '70%',
  },
  button: {
    justifyContent: 'center',
    height: 40,
    width: '100%',
    marginBottom: '10%',
    backgroundColor: 'olive',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  error: {
    textAlign: 'center',
    color: 'danger',
    marginBottom: 15,
  },
  inputContainer: {
    width: '100%',
  },
  label: {
    textTransform: 'uppercase',
    width: 240,
    marginBottom: 8,
  },
  link: {
    textDecorationLine: 'underline',
  },
  registrationCode: {
    backgroundColor: 'transparent',
  },
  skipButton: {
    marginTop: 20,
  },
  skipLabel: {
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  portalText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 40,
  },
  row: {
    width: '100%',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  normal: {
    fontWeight: 'normal',
  },
});

export default SignUpCode;
