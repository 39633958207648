import { useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { last, split } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LabeledInput from '../../components/LabeledInput';
import { getErrors } from '../../utils/errors';

type Props = {
  onSuccess: () => void;
};

const JoinOrganization: React.FC<Props> = ({ onSuccess }) => {
  const store = useStore();
  const today = new Date();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register({ name: 'inviteCode' }, { required: true });
  }, [register]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const result = await store.organization.joinOrganization(data.inviteCode);
      setLoading(false);
      if (result.ok) {
        await store.user.fetchMe();
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [onSuccess, setErrorMessage, setLoading],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const populateCode = useCallback(
    (code: string) => {
      const deconstructed = split(code, '/');
      const inviteCode = last(deconstructed);
      if (inviteCode) {
        setInviteCode(inviteCode);
        setValue('inviteCode', inviteCode);
      }
    },
    [setInviteCode, setValue],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll}>
        <Layout style={styles.content}>
          <Text style={styles.title}>Enter Access Code</Text>
          <View style={styles.form}>
            {!!errorMessage && (
              <Text style={styles.error} category="label">
                {errorMessage}
              </Text>
            )}
            <LabeledInput
              style={styles.inputContainer}
              autoCapitalize="none"
              returnKeyType="next"
              onChangeText={(text) => populateCode(text)}
              label="Access code"
              divider
              defaultValue={inviteCode}
            />
            <TouchableOpacity style={styles.button} onPress={onSubmitWrapped}>
              <Text style={styles.buttonText}>Join</Text>
            </TouchableOpacity>
          </View>
        </Layout>
      </ScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 40,
  },
  navigationContainer: {
    padding: 16,
  },
  content: {
    paddingHorizontal: 24,
  },
  inputContainer: {},
  form: {
    marginTop: 48,
    maxWidth: 400,
  },
  scroll: {
    flex: 1,
    marginTop: -20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    textTransform: 'uppercase',
    width: 240,
    marginBottom: 9,
  },
  scanner: {
    height: 240,
    marginTop: 8,
    marginBottom: 74,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'black',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
});

export default observer(JoinOrganization);
