import {
  CheckBox,
  Icon,
  Layout,
  Select,
  SelectItem,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import {
  FTESweatProgram,
  FTESweatWorkout,
  FTESweatWorkoutStep,
} from 'o2x-store/src/models/FTELibrary';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import SweatWorkout, { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Animated,
  Dimensions,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import LoadModalTemplate from 'src/components/FTE/LoadModalTemplate';
import MorePopover from 'src/components/FTE/MorePopover';
import ProgramDetails from 'src/components/FTE/ProgramDetails';
import SaveModalTemplate from 'src/components/FTE/SaveModalTemplate';
import WorkoutItem from 'src/components/FTE/WorkoutItem';
import { formatWorkout } from 'src/utils/sweatstart';

type Props = {
  style?: StyleProp<ViewStyle>;
  onClose: () => void;
  onSave: () => void;
  program?: SweatProgram;
  programId?: number | undefined;
  programLibrary?: Number[];
};

const { width, height } = Dimensions.get('window');
const CreateSweatProgram: React.FC<Props> = (props) => {
  const { onClose, onSave, programId, programLibrary, program, style } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const [programName, setProgramName] = useState(program ? program.name : '');
  const [description, setDescription] = useState(program ? program.description : '');
  const [weekLength, setWeekLength] = useState<number>(program ? program.lengthInWeeks : 1);
  const [dayWeek, setDayWeek] = useState<string>(program ? program.daysPerWeek : '');
  const [workoutList, setWorkoutList] = useState<(FTESweatWorkout | SweatWorkout)[]>(
    program?.workouts!,
  );
  const [visible, setVisible] = useState(program ? program.ossVisible : false);

  const [selectedWeek, setSelectedWeek] = useState(1);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [loadModalVisible, setLoadModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [weekTemplate, setWeekTemplate] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [resultError, setResultError] = useState('');
  const [hasError, setHasError] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number>();
  const [flag, setFlag] = useState(Math.random());
  const [changeWeek, setChangeWeek] = useState(true);

  const animation = useRef(new Animated.Value(0)).current;
  const [toRender, setToRender] = useState(0);

  const [stepClipboard, setStepClipboard] = useState<FTESweatWorkoutStep | SweatWorkoutStep>();

  const data: FTESweatProgram = {
    id: program ? program.id : null,
    name: programName,
    description,
    lengthInWeeks: weekLength,
    daysPerWeek: dayWeek,
    fteOwner: store.auth.user?.id,
    isVisible: false,
    workouts: workoutList,
    ossVisible: visible,
  };

  const getProgram = useCallback(async () => {
    if (program) {
      setLoading(true);
      const prog = await store.fteLibrary.fetchSweatProgramById(program.id);
      // setWorkoutList(prog.extra?.results[0].workouts);
      const programWorkouts: FTESweatWorkout[] = prog.extra?.results[0].workouts;
      const arr = [];
      if (programWorkouts && programWorkouts.length > 0) {
        for (var i = 0; i < weekLength; i++) {
          const weeklyArr = programWorkouts.filter((workout) => workout.week === i + 1);
          weeklyArr.forEach((workout) => (workout.included = true));
          for (var j = 0; j < 7; j++) {
            if (weeklyArr.filter((workout) => workout.day === j + 1).length > 0) continue;
            const workoutObj: Partial<FTESweatWorkout> = {
              week: i + 1,
              day: j + 1,
              program: program ? program.id : null,
              ftePrepare: null,
              fteRecover: null,
              included: false,
            };
            weeklyArr.push(workoutObj);
          }
          weeklyArr.sort((x, y) => (x.day > y.day ? 1 : -1));
          arr.push(weeklyArr);
        }
        setWorkoutList([...arr.flat()]);
      } else {
        for (var i = 0; i < weekLength; i++) {
          for (var j = 0; j < 7; j++) {
            const workoutObj: Partial<FTESweatWorkout> = {
              week: i + 1,
              day: j + 1,
              program: program ? program.id : null,
              ftePrepare: null,
              fteRecover: null,
              included: false,
            };
            arr.push(workoutObj);
          }
        }
        setWorkoutList([...arr]);
      }
      setLoading(false);
    }
  }, [program]);

  useEffect(() => {
    getProgram();
  }, [getProgram]);

  useEffect(() => {
    setFlag(Math.random());
  }, [toRender, workoutList]);

  useEffect(() => {
    if (removeIndex! >= 0) {
      const arr = workoutList.filter((workout) => workout.week === selectedWeek);
      const arr2 = [...workoutList];
      const workoutObj: Partial<FTESweatWorkout> = {
        week: selectedWeek,
        day: workoutList[removeIndex!].day,
        program: program ? program.id : null,
        ftePrepare: null,
        fteRecover: null,
        steps: [],
      };
      arr.splice(removeIndex, 1, workoutObj);

      arr2.splice((selectedWeek - 1) * 7, 7, arr);
      setWorkoutList(arr2.flat());
      setFlag(Math.random());
      setRemoveIndex(undefined);
    }
  }, [removeIndex, workoutList]);

  useEffect(() => {
    const arr = [];
    if (weekLength > 0 && changeWeek) {
      if (workoutList && workoutList.length > 0) {
        for (var i = 0; i < weekLength; i++) {
          const weeklyArr = workoutList.filter((workout) => workout.week === i + 1);
          for (var j = weeklyArr.length; j < 7; j++) {
            const workoutObj: Partial<FTESweatWorkout> = {
              week: i + 1,
              day: j + 1,
              program: program ? program.id : null,
              ftePrepare: null,
              fteRecover: null,
              included: true,
            };
            weeklyArr.push(workoutObj);
          }
          arr.push(weeklyArr);
        }
        setWorkoutList([...arr.flat()]);
      } else {
        for (var i = 0; i < weekLength; i++) {
          for (var j = 0; j < 7; j++) {
            const workoutObj: Partial<FTESweatWorkout> = {
              week: i + 1,
              day: j + 1,
              program: program ? program.id : null,
              ftePrepare: null,
              fteRecover: null,
              included: true,
            };
            arr.push(workoutObj);
          }
        }
        setWorkoutList([...arr]);
      }
    }
  }, [weekLength, changeWeek]);

  useEffect(() => {
    if (weekTemplate) {
      const workouts = weekTemplate.workoutDays;
      const workoutListCopy = workoutList;
      const weekWorkouts = workoutList.filter((workout) => workout.week === selectedWeek);
      for (let i = 0; i < 7; i++) {
        weekWorkouts[i].week = selectedWeek;
        weekWorkouts[i].day = i + 1;
        weekWorkouts[i].steps = workouts[i].steps;
        weekWorkouts[i].ftePrepare = workouts[i].ftePrepare.id ? workouts[i].ftePrepare.id : null;
        weekWorkouts[i].fteRecover = workouts[i].fteRecover.id ? workouts[i].fteRecover.id : null;
        weekWorkouts[i].prepName = workouts[i].ftePrepare.name ? workouts[i].ftePrepare.name : null;
        weekWorkouts[i].recName = workouts[i].fteRecover.name ? workouts[i].fteRecover.name : null;
      }
      weekWorkouts.forEach((workout) => {
        workout.id = null;
        workout.steps?.forEach((step) => {
          step.id = null;
          step.exercises?.forEach((exercise) => {
            exercise.id = null;
            exercise.exercise = exercise.exercise.id;
          });
        });
      });
      workoutListCopy.splice((selectedWeek - 1) * 7, 7, ...weekWorkouts);
      setWorkoutList([...workoutListCopy]);
    }
  }, [weekTemplate]);

  const onSubmit = useCallback(
    async (data) => {
      const tempWorkouts: FTESweatWorkout[] | SweatWorkout[] = [];
      data.workouts.forEach(
        (workout: FTESweatWorkout, index: number, obj: FTESweatWorkout[] | SweatWorkout[]) => {
          workout.program = null;
          workout.isVisible = false;
          if (isNaN(workout.ftePrepare)) workout.ftePrepare = null;
          if (isNaN(workout.fteRecover)) workout.fteRecover = null;
          if (workout.included) tempWorkouts.push(workout);
          workout?.steps?.map((step) => {
            step.exercises?.map((exercise) => {
              Number.isInteger(exercise.exercise)
                ? null
                : (exercise.exercise = exercise.exercise.id);
            });
          });
        },
      );

      const newWorkouts = tempWorkouts.map((tempWorkout) => {
        return formatWorkout(tempWorkout) as FTESweatWorkout | SweatWorkout;
      });
      data.workouts = newWorkouts;
      setLoadingSave(true);
      const result = await store.fteLibrary.createFteSweatProgram(data);
      if (result.ok) {
        const update = {
          id: programId,
          programs: programLibrary ? [...programLibrary!, result.extra?.id] : [result.extra?.id],
        };
        const updateLibrary = await store.fteLibrary.updateFteSweatProgramLibrary(update);
      } else {
        Object.values(result.errors).map((error) => setResultError(JSON.stringify(error)));
      }
      setLoadingSave(false);
      setHasError(!result.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => {
        animation.setValue(0);
        if (result.ok) {
          onSave();
        }
      });
    },
    [onSave],
  );

  const onSubmitEdit = useCallback(
    async (data) => {
      const tempWorkouts: FTESweatWorkout[] | SweatWorkout[] = [];
      data.workouts.forEach((workout: FTESweatWorkout) => {
        workout.program = null;
        if (isNaN(workout.ftePrepare)) workout.ftePrepare = null;
        if (isNaN(workout.fteRecover)) workout.fteRecover = null;
        if (workout.included) tempWorkouts.push(workout);
        workout?.steps?.map((step) => {
          step.exercises?.map((exercise) => {
            Number.isInteger(exercise.exercise) ? null : (exercise.exercise = exercise.exercise.id);
          });
        });
        if (!workout.id) workout.id = null;
      });

      const newWorkouts = tempWorkouts.map((tempWorkout) => {
        return formatWorkout(tempWorkout) as FTESweatWorkout | SweatWorkout;
      });
      data.workouts = newWorkouts;
      setLoadingSave(true);
      const result = await store.fteLibrary.updateFteSweatProgram(program?.id, data);
      setLoadingSave(false);
      setHasError(!result.ok);
      Animated.timing(animation, {
        toValue: 3,
        duration: 2000,
        useNativeDriver: false,
      }).start(() => {
        animation.setValue(0);
        if (result.ok) {
          onSave();
        }
      });
    },
    [onSave, workoutList],
  );

  const renderToggleButton = () => (
    <TouchableOpacity
      style={{
        marginLeft: 10,
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
      onPress={() => setIsOptionsVisible(true)}>
      <Icon
        name="more-vertical-outline"
        fill="white"
        style={{
          width: 15,
          height: 15,
        }}
      />
    </TouchableOpacity>
  );

  const removeWeek = useCallback(() => {
    const workoutArr = workoutList;
    workoutArr.splice((selectedWeek - 1) * 7, 7);
    workoutArr.forEach((workout) => {
      if (workout.week > selectedWeek) workout.week -= 1;
    });
    setWorkoutList([...workoutArr]);
    setChangeWeek(false);
    if (selectedWeek === weekLength) setSelectedWeek(selectedWeek - 1);
    setWeekLength(weekLength - 1);
  }, [workoutList, selectedWeek, weekLength]);

  const saveTemplate = useCallback(async () => {
    const data: any = {};
    data.workoutDays = workoutList.filter((workout) => workout.week === selectedWeek);
    data.name = templateName;
    data.fte = store.auth.user?.id;
    data.workoutDays.forEach((workout) => {
      workout?.steps?.map((step) => {
        step.exercises?.map((exercise) => {
          Number.isInteger(exercise.exercise) ? null : (exercise.exercise = exercise.exercise.id);
        });
      });
    });
    await store.fteLibrary.saveFteWeekWorkoutTemplate(data);
    setSaveModalVisible(false);
  }, [templateName]);

  const scale = animation.interpolate({
    inputRange: [0, 0.1, 3],
    outputRange: [0, 1, 1],
  });

  const opacity = animation.interpolate({
    inputRange: [0, 0.1, 2.5, 3],
    outputRange: [0, 1, 1, 0],
  });

  const translateY = animation.interpolate({
    inputRange: [0, 2.5, 3],
    outputRange: [0, 0, 3],
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 650,
  });

  return (
    <Layout style={[styles.container, style]}>
      <ScrollView
        style={styles.bgContainer}
        contentContainerStyle={
          isMobile ? {} : { height: height < width ? height * 0.8 : width * 0.8 }
        }>
        {!!saveModalVisible && (
          <SaveModalTemplate
            saveModalVisible={saveModalVisible}
            hideSaveModalVisible={() => setSaveModalVisible(false)}
            templateName={templateName}
            setTemplateName={setTemplateName}
            saveTemplate={saveTemplate}
          />
        )}
        {!!loadModalVisible && (
          <LoadModalTemplate
            loadModalVisible={loadModalVisible}
            content="week-workout"
            hideLoadModalVisible={() => setLoadModalVisible(false)}
            setWeekTemplate={setWeekTemplate}
            store={store}
          />
        )}
        <Layout style={isMobile ? styles.headerMobile : styles.header}>
          <TouchableOpacity onPress={onClose} style={styles.close}>
            <Icon name="arrow-back-outline" fill="#4E7B89" style={styles.icon} />
          </TouchableOpacity>
          <Text style={isMobile ? styles.headerTextMobile : styles.headerText}>
            Create Sweat Program
          </Text>
          <CheckBox
            checked={visible}
            onChange={(status) => setVisible(status)}
            style={isMobile ? styles.shareMobile : styles.shareCheck}>
            {() => (
              <Text style={isMobile ? styles.shareTextMobile : styles.shareText}>
                Share with other OSS
              </Text>
            )}
          </CheckBox>
          {loadingSave ? (
            <Layout style={styles.loadingSave}>
              <Spinner />
            </Layout>
          ) : (
            <TouchableOpacity
              style={[
                isMobile ? styles.saveButtonMobile : styles.saveButton,
                program && program.fteOwner
                  ? program?.fteOwner.id !== store.auth.user?.id && {
                      opacity: 0.3,
                    }
                  : {
                      opacity: 0.5,
                    },
              ]}
              onPress={() => onSubmitEdit(data)}
              disabled={
                !program || (program && program.fteOwner)
                  ? program?.fteOwner.id !== store.auth.user?.id
                  : true
              }>
              <Text style={styles.saveText}>Save</Text>
            </TouchableOpacity>
          )}
          {loadingSave ? (
            <Layout style={styles.loadingSave}>
              <Spinner />
            </Layout>
          ) : (
            <TouchableOpacity
              style={isMobile ? styles.saveButtonMobile : styles.saveButton}
              onPress={() => onSubmit(data)}>
              <Text style={[styles.saveText]} numberOfLines={2}>
                Save New Program
              </Text>
            </TouchableOpacity>
          )}
        </Layout>
        <Layout style={styles.bodyContainer}>
          <ProgramDetails
            programName={programName}
            description={description}
            weekLength={weekLength}
            dayWeek={dayWeek}
            setProgramName={setProgramName}
            setDescription={setDescription}
            setWeekLength={setWeekLength}
            setDayWeek={setDayWeek}
            setChangeWeek={setChangeWeek}
            isMobile={isMobile}
          />
          <Layout
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              flexDirection: 'row',
            }}>
            <Text style={styles.titleText}>Workouts</Text>
            <Select
              size="small"
              placeholder={`Week ${selectedWeek} of ${weekLength || 1}`}
              style={{ marginLeft: 10 }}
              onSelect={(index) => {
                // setFlag(Math.random());
                setSelectedWeek(Array.from({ length: weekLength }, (_, i) => i + 1)[index.row]);
              }}>
              {Array.from({ length: weekLength }, (_, i) => i + 1).map((week, index) => (
                <SelectItem key={index} title={`Week ${week} of ${weekLength}`} />
              ))}
            </Select>
            <MorePopover
              isOptionsVisible={isOptionsVisible}
              renderToggleButton={renderToggleButton}
              setIsOptionsVisible={setIsOptionsVisible}
              removeItem={removeWeek}
              saveItem={() => setSaveModalVisible(true)}
              loadItem={() => setLoadModalVisible(true)}
            />
          </Layout>
          <ScrollView
            style={{ marginLeft: 10, height: '100%' }}
            contentContainerStyle={{ height: '100%' }}
            horizontal>
            {loading ? (
              <Layout style={styles.loading}>
                <Spinner />
              </Layout>
            ) : (
              workoutList
                ?.filter((workout) => workout.week === selectedWeek)
                .map((workout, workoutIndex) => (
                  <WorkoutItem
                    key={`${workout.week} - ${workout.day} - ${workoutIndex} - ${workout.steps?.length}`}
                    workoutIndex={workoutIndex}
                    workout={workout}
                    workoutList={workoutList}
                    setWorkoutList={setWorkoutList}
                    workoutJSON={JSON.stringify(workout)}
                    onRemoveWorkout={() => setRemoveIndex(workoutIndex)}
                    flag={flag}
                    setToRender={setToRender}
                    stepClipboard={stepClipboard}
                    setStepClipboard={setStepClipboard}
                  />
                ))
            )}
          </ScrollView>
        </Layout>
      </ScrollView>
      <View style={styles.center} pointerEvents="none">
        <Animated.View
          style={[styles.popUpContainer, { opacity, transform: [{ translateY }, { scale }] }]}
          pointerEvents="none">
          <Icon
            style={styles.savedIcon}
            name={hasError ? 'close-circle-outline' : 'checkmark-circle-2-outline'}
            fill={hasError ? 'warning' : 'olive'}
          />
          <Text style={styles.savedText}>{hasError ? 'SAVING FAILED' : 'SAVED SUCCESSFULLY'}</Text>
          {hasError && <Text style={styles.errorText}>{resultError}</Text>}
        </Animated.View>
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    // flex: 1,
  },
  bgContainer: {
    width: '95%',
    marginLeft: '5px',
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  header: {
    width: '96%',
    height: '50px',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerMobile: {
    width: '96%',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'transparent',
  },
  headerText: {
    color: 'white',
    fontSize: 18,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  headerTextMobile: {
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  titleText: {
    color: 'white',
    fontSize: 18,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  close: {
    marginLeft: 10,
    marginTop: 10,
  },
  icon: {
    width: 30,
    height: 30,
  },
  bodyContainer: {
    width: '96%',
    height: '85%',
    flexDirection: 'column',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '20px',
    backgroundColor: 'transparent',
  },
  saveButton: {
    width: 140,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 20,
    marginRight: 10,
    marginTop: 15,
    alignItems: 'center',
  },
  saveButtonMobile: {
    width: 140,
    height: 30,
    backgroundColor: 'olive',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    alignItems: 'center',
  },
  loadingSave: {
    position: 'absolute',
    padding: 8,
    right: 30,
    top: 12,
    backgroundColor: 'transparent',
  },
  loading: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
    alignSelf: 'center',
  },
  savedText: {
    color: 'white',
    fontSize: 14,
    margin: 'auto',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  shareCheck: {
    marginLeft: 'auto',
    marginRight: 5,
    alignSelf: 'center',
    marginTop: 10,
  },
  shareMobile: {
    marginTop: 4,
    marginLeft: 10,
  },
  shareText: {
    marginLeft: 5,
    fontSize: 14,
  },
  shareTextMobile: {
    marginLeft: 5,
    fontSize: 12,
  },
});

export default observer(CreateSweatProgram);
