import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { Image, ImageStyle, StyleProp, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route: any;
};

const TeamDetailInvite: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const team = store.teams.pending_teams.get(`${id}`);
  const userId = store.auth.user?.id;
  const memberIds = new Array<number>();
  const memberPendingIds = new Array<number>();
  const teamOwner = !!team && store.users.users.get(`${team.user}`);
  const theme = useTheme();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      if (team) {
        store.users.fetchUser(team.user);
      }
    }, [team]),
  );

  useFocusEffect(
    useCallback(() => {
      if (team) {
        const { members, pendingMembers } = team;
        pendingMembers.forEach((member) => {
          memberPendingIds.push(member.id);
        });

        members.forEach((member) => {
          memberIds.push(member.id);
        });
      }
    }, [team]),
  );

  const onAccept = useCallback(async () => {
    memberPendingIds.filter((id) => userId != id);
    memberIds.push(userId);
    const updateData = {
      members: memberIds,
      pendingMembers: memberPendingIds.filter((id) => userId != id),
    };
    const result = await store.teams.updateTeam(updateData, `${team.id}`);
    if (result.ok) {
      navigation.goBack();
    }
  }, [team]);

  const onDecline = useCallback(async () => {
    memberPendingIds.filter((id) => userId != id);
    const updateData = {
      members: memberIds,
      pendingMembers: memberPendingIds.filter((id) => userId != id),
    };
    const result = await store.teams.updateTeam(updateData, `${team.id}`);
    if (result.ok) {
      navigation.goBack();
    }
  }, [team]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout
            style={[styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
            <View style={styles.navigationContainer}>
              <TopNav showClose={false} />
            </View>
            <View style={styles.content}>
              <View style={styles.headerContainer}>
                <View style={styles.header}>
                  <Image
                    style={styles.image as StyleProp<ImageStyle>}
                    source={
                      team?.logoThumbnail
                        ? { uri: team.logoThumbnail }
                        : require('../../assets/images/icon.png')
                    }
                  />
                  <View style={styles.titleContainer}>
                    <Text style={styles.title}>{team?.name}</Text>
                    <Text style={styles.subtitle}>
                      {team?.members.length} {Humanize.pluralize(team?.members.length, 'member')}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.listHeader}>
                <Text style={styles.subtitle}>TEAM OWNER</Text>
              </View>
              <View style={styles.item}>
                <Image
                  style={styles.itemImage as StyleProp<ImageStyle>}
                  source={
                    teamOwner?.profileImage
                      ? { uri: teamOwner.profileImage }
                      : require('../../assets/images/user_placeholder.png')
                  }
                />
                <Text style={styles.itemName}>{teamOwner?.fullName}</Text>
              </View>
            </View>
            <BottomNav
              onBack={onDecline}
              onNext={onAccept}
              backLabel="Decline"
              nextLabel="Accept"
              backColor={theme.red}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    marginBottom: 26,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 1000,
  },
  container: {
    flex: 1,
  },
  content: {
    padding: 30,
    flex: 1,
  },
  subtitle: {
    fontSize: 14,
    color: 'blue-secondary',
    lineHeight: 17,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: 29,
  },
  titleContainer: {
    paddingHorizontal: 12,
  },
  seeMore: {
    textAlign: 'right',
    color: 'light-gray',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  activityList: {
    marginVertical: 12,
    marginHorizontal: -24,
    flex: 1,
  },
  assessmentList: {
    marginTop: 12,
    flex: 1,
  },
  settingsContainer: {
    bottom: 20,
  },
  settingsIcon: {
    width: 22,
    height: 22,
  },
  item: {
    backgroundColor: 'dark-blue',
    height: 60,
    paddingHorizontal: 12,
    marginLeft: 12,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  itemImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
  },
  itemName: {
    paddingHorizontal: 12,
    fontSize: 14,
    lineHeight: 17,
  },
});

export default observer(TeamDetailInvite);
