import { RouteProp, useNavigation } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import LabeledInput from '../../components/LabeledInput';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'EventJoin'>;
};

const EventJoin: React.FC<Props> = (props) => {
  const eventCode = props.route.params?.eventCode;
  const store = useStore();
  const today = new Date();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeArea();
  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [inviteCode, setInviteCode] = useState<string>(eventCode || '');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    register({ name: 'inviteCode' }, { required: true });
  }, [register]);

  const goToEvents = useCallback(() => {
    navigation.navigate('Events');
  }, []);

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    const result = await store.event.joinEvent(data.inviteCode);
    setLoading(false);
    if (result.ok) {
      navigation.goBack();
    } else {
      setErrorMessage(getErrors(result.errors));
    }
  }, []);

  const onSubmitWrapped = handleSubmit(onSubmit);

  return (
    <Layout style={styles.container}>
      <Layout style={[styles.navigationContainer, { marginTop: insets.top }]}>
        <TopNav onClose={goToEvents} showClose={false} />
      </Layout>
      <ScrollView style={styles.scroll}>
        <Layout style={styles.content}>
          <Text style={styles.title}>Join an Event</Text>
          <View style={styles.form}>
            {!!errorMessage && (
              <Text style={styles.error} category="label">
                {errorMessage}
              </Text>
            )}
            <LabeledInput
              style={styles.inputContainer}
              autoCapitalize="none"
              returnKeyType="next"
              onChangeText={(text) => setValue('inviteCode', text)}
              label="Event code"
              divider
              defaultValue={inviteCode}
            />
          </View>
        </Layout>
      </ScrollView>
      <TouchableOpacity style={styles.button} onPress={onSubmitWrapped}>
        <Text style={styles.buttonText}>Join event</Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  navigationContainer: {
    padding: 16,
  },
  content: {
    paddingHorizontal: 24,
  },
  inputContainer: {},
  form: {
    marginTop: 48,
    width: 237,
    alignSelf: 'center',
  },
  scroll: {
    flex: 1,
    marginTop: -20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    textTransform: 'uppercase',
    width: 240,
  },
  scanner: {
    height: 240,
    marginTop: 8,
    marginBottom: 74,
    borderWidth: 1,
    borderColor: 'white',
    backgroundColor: 'black',
  },
});

export default observer(EventJoin);
