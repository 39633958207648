import { DrawerContentComponentProps } from '@react-navigation/drawer';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { useSafeArea } from 'react-native-safe-area-context';
import SettingsContent from './SettingsContent';

type Props = DrawerContentComponentProps & {};

const Settings: React.FC<Props> = (props) => {
  const { state, navigation } = props;
  const insets = useSafeArea();
  const styles = useStyleSheet(themedStyles);

  const onNavigate = useCallback((route) => {
    navigation.navigate(route.name);
  }, []);

  const style = {
    paddingBottom: insets.bottom,
    height: 72 + insets.bottom,
  };

  const title = [
    'Account & Security',
    'Display & Sounds',
    'Notifications',
    'Team Settings',
    'Data Usage',
    'About O2X',
  ];

  return (
    <Layout style={[styles.container, style]}>
      <Text style={styles.settings}>Settings</Text>
      <Layout>
        {state.routes.map((route, index) => (
          <SettingsContent
            key={route.key}
            title={title[index]}
            name={route.name}
            selected={state.index === index}
            onNavigate={() => onNavigate(route)}
          />
        ))}
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  header: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  settings: {
    fontSize: 32,
    lineHeight: 38,
    letterSpacing: 1,
    fontWeight: 700,
    color: 'white',
    paddingBottom: 46,
    paddingLeft: 25,
    paddingTop: 20,
  },
});

export default Settings;
