import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { getTaskColor, TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Image, ImageStyle, StyleProp } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import DownloadedItem from 'src/models/DownloadedItem';
import { useNativeStore } from 'src/stores';
import SweatIcon from '../../assets/images/sweat-icon.svg';

type Props = {
  index: number;
  item: DownloadedItem;
  setCurrentExercise: (value: number) => void;
  header: string;
  showDetails?: boolean;
  timer?: string | null;
  rest?: number | null;
  work?: number | null;
};

const DLWorkoutItem: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { downloads } = useNativeStore();

  const { item, index, setCurrentExercise, showDetails, header, rest, work, timer } = props;

  const [thumbnail, setThumbnail] = useState('');

  const videoFile = useMemo(() => downloads.getOrCreateMediaFile(item), []);

  useEffect(() => {
    (async () => {
      if (videoFile) {
        const uri = await videoFile.downloadMedia();
        if (uri) {
          const result = await videoFile.getThumbnail();
          setThumbnail(result);
        }
      }
    })();
  }, [videoFile]);

  const parseTime = useCallback((time) => {
    if (time) {
      const secs = time % 60;
      const mins = Math.floor(time / 60);
      return `${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? `0${secs}` : secs}`;
    }
    return '';
  }, []);

  const timerWork = item.timerWork || work;
  const timerRest = item.timerRest || rest;

  return (
    <>
      {!!header && (
        <Text category="h2" style={[styles.header, { color: getTaskColor(TASK_TYPE.SWEAT) }]}>
          {header}
        </Text>
      )}
      <TouchableOpacity onPress={() => setCurrentExercise(index)}>
        <Layout style={[styles.container, showDetails ? {} : styles.blur]}>
          <Layout style={styles.contentItem}>
            {thumbnail ? (
              <Layout style={styles.thumbnailWrapper}>
                <Image
                  style={styles.thumbnail as StyleProp<ImageStyle>}
                  source={{ uri: thumbnail }}
                />
              </Layout>
            ) : (
              <Layout style={styles.iconWrapper}>
                <SweatIcon height={30} width={45} />
              </Layout>
            )}
            <Layout style={styles.contentTextItem}>
              <Text style={styles.title} numberOfLines={1}>
                {item.name}
              </Text>
              <Layout style={styles.subtitleContainer}>
                {!!item.subtitle && <Text style={styles.subtitle}>{item.subtitle}</Text>}
                {(!!timerWork || !!timerRest) && (
                  <Text style={styles.timers}>
                    {timerWork && timerRest
                      ? `${parseTime(timerWork)} | ${parseTime(timerRest)}`
                      : timerWork
                      ? `${parseTime(timerWork)}`
                      : `${parseTime(timerRest)}`}
                  </Text>
                )}
              </Layout>
            </Layout>
          </Layout>
          {showDetails && !!item.instructions && (
            <Text category="c1" style={styles.instructions}>
              {item.instructions}
            </Text>
          )}
        </Layout>
      </TouchableOpacity>
      {timer === 'tabata' && (
        <Layout style={[styles.tabata, showDetails ? {} : styles.blur]}>
          <Layout style={styles.contentItem}>
            <Layout style={styles.iconWrapper}>
              <SweatIcon height={30} width={45} />
            </Layout>
            <Layout style={styles.contentTextItem}>
              <Text style={styles.title} numberOfLines={1}>
                REST
              </Text>
              <Layout style={styles.subtitleContainer}>
                <Text style={styles.subtitle}>00:10</Text>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 2,
    backgroundColor: 'darker-blue',
    paddingRight: 8,
  },
  tabata: {
    flex: 1,
    marginBottom: 2,
    backgroundColor: 'black',
    paddingRight: 8,
  },
  title: {
    fontSize: 14,
    lineHeight: 17,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  contentItem: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  contentTextItem: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  thumbnail: {
    flex: 1,
    resizeMode: 'cover',
    width: 60,
    height: 60,
  },
  thumbnailWrapper: {
    width: 60,
    height: 60,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: 'black',
  },
  subtitleContainer: {
    backgroundColor: 'transparent',
    paddingVertical: 2,
  },
  timers: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: getTaskColor(TASK_TYPE.SWEAT),
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'light-gray',
  },
  instructions: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  blur: {
    opacity: 0.5,
  },
  header: {
    fontSize: 14,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
});

export default DLWorkoutItem;
