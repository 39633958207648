import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import BodyCompositionItem from 'src/containers/BodyCompositionAssessment/BodyCompositionItem';
import BodyCompAssessmentResult from 'src/containers/ReadinessAssessment/BodyCompAssessmentResult';
import HPSurveyAssessmentResult from 'src/containers/ReadinessAssessment/HPSurveyAssessmentResult';
import MobilityCapacityAssessmentResult from 'src/containers/ReadinessAssessment/MobilityCapacityAssessmentResult';
import ReadinessAssessmentResult from 'src/containers/ReadinessAssessment/ReadinessAssessmentResult';
import TeamChallenge from 'src/containers/Teams/TeamChallenge';
import AssessmentHistory from '../../AssessmentHistory';
import AssessmentList from '../../AssessmentList';
import BodyCompositionAssessment from '../../BodyCompositionAssessment';
import Header from '../../Header';

type Props = {};

const Stack = createStackNavigator();

const AssessmentStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Plan" component={AssessmentList} />
    <Stack.Screen name="AssessmentHistory" component={AssessmentHistory} />
    <Stack.Screen
      name="BodyCompositionAssessment"
      component={BodyCompositionAssessment}
    />
    <Stack.Screen name="BodyCompositionItem" component={BodyCompositionItem} />
    <Stack.Screen
      name="ReadinessAssessmentResult"
      component={ReadinessAssessmentResult}
    />
    <Stack.Screen
      name="BodyCompAssessmentResult"
      component={BodyCompAssessmentResult}
    />
    <Stack.Screen
      name="HPSurveyAssessmentResult"
      component={HPSurveyAssessmentResult}
    />
    <Stack.Screen
      name="MobilityCapacityAssessmentResult"
      component={MobilityCapacityAssessmentResult}
    />
    <Stack.Screen name="TeamChallenge" component={TeamChallenge} />
  </Stack.Navigator>
);

export default AssessmentStack;
