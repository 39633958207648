import { Input, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { InputQuestionProp } from 'o2x-store/src/models/Question';
import React, { useEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

const INCHES_TO_FOOT = 12;

interface Props {
  question: InputQuestionProp;
  onChange: (k: string, v: any) => void;
  initial: string | number | null;
  required: boolean;
  status?: string;
  divider?: boolean;
  dividerStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  disableFocus?: boolean;
}

const HeightInput: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const {
    question,
    onChange,
    initial,
    required,
    divider = true,
    dividerStyle,
    disabled = false,
    inputStyle,
    textStyle,
  } = props;

  const [valueFt, setValueFt] = useState(
    initial ? String(Math.floor((initial as number) / INCHES_TO_FOOT)) : '',
  );
  const [valueIn, setValueIn] = useState(
    initial ? String((initial as number) % INCHES_TO_FOOT) : '',
  );
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (initial && !isFocused) {
      setValueFt(String(Math.floor((initial as number) / INCHES_TO_FOOT)));
      setValueIn(String((initial as number) % INCHES_TO_FOOT));
    }
  }, [initial]);

  useEffect(() => {
    setValueFt(valueFt);
    setValueIn(valueIn);

    if (valueFt) {
      onChange(question.key, parseFloat(valueFt) * INCHES_TO_FOOT + parseFloat(valueIn || '0'));
    } else {
      onChange(question.key, null);
    }
  }, [valueFt, valueIn]);

  return (
    <Layout style={styles.container}>
      <Input
        style={[styles.input, inputStyle]}
        textStyle={textStyle}
        value={valueFt}
        onChangeText={(nextValue) => {
          setValueFt(nextValue);
        }}
        keyboardType="decimal-pad"
        disabled={disabled}
      />
      <Text style={styles.label}>ft</Text>
      <Input
        style={[styles.input, inputStyle]}
        textStyle={textStyle}
        value={valueIn}
        onChangeText={(nextValue) => {
          setValueIn(nextValue);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        keyboardType="decimal-pad"
        disabled={disabled}
      />
      <Text style={styles.label}>in</Text>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  input: {
    fontSize: 20,
    outline: 'none',
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  divider: {
    backgroundColor: 'gray',
    marginBottom: 32,
    marginTop: 0,
  },
  label: {
    color: '#4E7B89',
    fontSize: 14,
  },
});

export default HeightInput;
