import SweatExercise from '../models/SweatExercise';
import { SweatWorkoutStep } from '../models/SweatWorkout';
import SweatWorkoutStepExercise from '../models/SweatWorkoutStepExercise';

export const PREPARE_ID = -1;
export const RECOVER_ID = -2;

export const sweatWorkoutStep: { [key: string]: SweatWorkoutStep } = {
  prepare: {
    id: PREPARE_ID,
    circuitSets: 1,
    circuitTime: '',
    circuitRest: '',
    order: 0,
    exercises: [
      {
        id: PREPARE_ID,
        order: 0,
        sets: '',
        reps: '',
        distance: '',
        time: '5 mins',
        rest: '',
        notes: '',
        difficulty: '',
        exercise: PREPARE_ID,
        bwOrEquipment: '',

        // Computed properties.
        subtitle: '5 mins',
      } as SweatWorkoutStepExercise,
    ],
  },
  recover: {
    id: RECOVER_ID,
    circuitSets: 1,
    circuitTime: '',
    circuitRest: '',
    order: 0,
    exercises: [
      {
        id: RECOVER_ID,
        order: 0,
        sets: '',
        reps: '',
        distance: '',
        time: '10 mins',
        rest: '',
        notes: '',
        difficulty: '',
        exercise: RECOVER_ID,
        bwOrEquipment: '',

        // Computed properties.
        subtitle: '10 mins',
      } as SweatWorkoutStepExercise,
    ],
  },
};

export const sweatExercise: { [key: string]: SweatExercise } = {
  prepare: ({
    id: PREPARE_ID,
    name: 'Prepare',
    description: '',
    video: null,
    equipments: [],
    getRefId: () => `${PREPARE_ID}`,
    $modelType: 'o2x-store/SweatExercise',
  } as unknown) as SweatExercise,
  recover: ({
    id: RECOVER_ID,
    name: 'Recover',
    description: '',
    video: null,
    equipments: [],
    getRefId: () => `${RECOVER_ID}`,
    $modelType: 'o2x-store/SweatExercise',
  } as unknown) as SweatExercise,
};
