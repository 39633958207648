import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import SweatExercise from 'o2x-store/src/models/SweatExercise';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import { SweatWorkoutStep } from 'o2x-store/src/models/SweatWorkout';
import SweatWorkoutStepExercise from 'o2x-store/src/models/SweatWorkoutStepExercise';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform, View } from 'react-native';
import ReactPlayer from 'react-player';
import MediaFile from 'src/models/MediaFile';
import { getTimeDisplay } from 'src/utils/timeDisplay';
import { useNativeStore } from '../../stores';

type Props = {
  step?: SweatWorkoutStep;
  stepExercise?: SweatWorkoutStepExercise | SweatGlobalStepExercise;
  showMinimize?: boolean;
  showNext?: boolean;
};

const SweatStartNextMinimized: React.FC<Props> = (props) => {
  const { step, stepExercise, showMinimize = false, showNext = true } = props;
  const { sweatStart, mediaStorage } = useNativeStore();
  const { sweat } = useStore();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  let exercise: SweatExercise | undefined;
  let exerciseVideoFile: MediaFile | undefined;
  let isCircuit: boolean = false;
  const [localUri, setLocalUri] = useState<string | null>(null);

  if (step) {
    isCircuit = step && step.circuitSets !== 1;
  }
  if (stepExercise) {
    exercise = sweat.getSweatExercise(stepExercise?.exercise);
    if (exercise) {
      if (exercise.video) {
        exerciseVideoFile = mediaStorage.getOrCreateMediaFile(exercise, 'video');
      }
    }
  }

  const workRestTimerDisplay = useMemo(() => {
    const timerDisplay = [];

    if (step && stepExercise) {
      if (stepExercise.workoutTimerWork) {
        timerDisplay.push(getTimeDisplay(stepExercise.workoutTimerWork || undefined));
      } else if (!!step.circuitTimerWork && step.circuitTimer === 'variable') {
        timerDisplay.push(getTimeDisplay(step.circuitTimerWork || undefined));
      }

      if (stepExercise.workoutTimerRestComputed) {
        timerDisplay.push(getTimeDisplay(stepExercise.workoutTimerRestComputed));
      } else if (!!step.circuitTimerRest && step.circuitTimer === 'variable') {
        timerDisplay.push(getTimeDisplay(step.circuitTimerRest));
      }
    }

    return timerDisplay.join(' | ');
  }, [step, stepExercise]);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  useEffect(() => {
    (async () => {
      if (exerciseVideoFile && !exerciseVideoFile.loading) {
        const localUri =
          Platform.OS !== 'web' ? await exerciseVideoFile.downloadMedia() : exerciseVideoFile.uri;
        if (localUri) {
          setLocalUri(localUri);
        }
      }
    })();
  }, [exerciseVideoFile, exerciseVideoFile?.loading, setLocalUri]);

  return (
    <Layout style={styles.container}>
      <Layout style={styles.contentContainer}>
        <Layout style={styles.content}>
          {showNext && <Text style={styles.nextLabel}>Next</Text>}
          <Layout style={styles.contentItem}>
            {!(step && stepExercise && exercise) && (
              <Text style={styles.endLabel}>End of Workout</Text>
            )}
            {!!(step && stepExercise && exercise) && (
              <>
                {!!exercise.video && localUri && (
                  <Layout style={styles.thumbnailWrapper}>
                    {/* <Video
                      style={styles.thumbnail}
                      source={{ uri: localUri }}
                      resizeMode="cover"
                    /> */}
                    <ReactPlayer
                      url={localUri}
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      height={60}
                      width={60}
                    />
                  </Layout>
                )}
                <Layout style={styles.contentTextItem}>
                  <View style={styles.title}>
                    {/*
                    {isCircuit && !!sweatStart.nextCircuitSet && (
                      <Text style={styles.circuitValue}>
                        {`Set ${sweatStart.nextCircuitSet}`}
                      </Text>
                    )}
                    {!!stepExercise.subtitle && (
                      <Text style={styles.reps}>{stepExercise.subtitle}</Text>
                    )}
                    */}
                    <Text style={styles.name} numberOfLines={1}>
                      {exercise.name}
                    </Text>
                  </View>
                  <Layout style={styles.subtitleTextContainer}>
                    {/* Work/Rest time */}
                    <Text style={styles.timerText}>{workRestTimerDisplay}</Text>
                  </Layout>
                </Layout>
              </>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'black',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    minHeight: 70,
  },
  description: {
    margin: 12,
  },
  label: {
    flex: 1,
    textAlign: 'center',
  },
  thumbnail: {
    width: 70,
    height: 70,
  },
  thumbnailWrapper: {
    width: 70,
    height: 70,
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
  },
  iconPlaceholder: {
    width: 70,
    height: 70,
    backgroundColor: 'black',
  },
  contentItem: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentTextItem: {
    flex: 2,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    paddingHorizontal: 12,
    // paddingVertical: 16,
  },
  title: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  circuitValue: {
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    marginRight: 10,
    color: 'cyan',
    textTransform: 'uppercase',
  },
  reps: {
    fontSize: 14,
    lineHeight: 17,
    color: 'cyan',
    marginRight: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  name: {
    fontSize: 14,
    lineHeight: 17,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    marginTop: 4,
    flexDirection: 'row',
  },
  rest: {
    fontSize: 12,
    lineHeight: 14,
    color: 'light-gray',
    textTransform: 'uppercase',
    marginRight: 6,
  },
  restLabel: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: '500',
    color: 'white',
    textTransform: 'uppercase',
  },
  minimizeIconContainer: {
    padding: 20,
  },
  minimizeIcon: {
    height: 24,
    width: 24,
  },
  endLabel: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 20,
    fontWeight: 'bold',
  },
  nextLabel: {
    textTransform: 'uppercase',
    fontSize: 14,
    lineHeight: 17,
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 4,
    marginLeft: 4,
  },
  subtitleTextContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    paddingVertical: 2,
  },
  timerText: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    color: 'cyan',
  },
});

export default observer(SweatStartNextMinimized);
