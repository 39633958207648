import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get, toLower } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import config from 'o2x-store/src/config';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import FormAssessmentSubmission from 'o2x-store/src/models/FormAssessmentSubmission';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import ExportPDFButton from 'src/components/PDF';
import RightOutline from '../../assets/images/right-outline.svg';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ReadinessAssessmentResult'>;
};

const ReadinessAssessmentResult: React.FC<Props> = (props) => {
  const { submissionId, assessmentId } = props.route.params;
  const { auth, assessment: assessmentStore, sweat: sweatStore } = useStore();

  const [assessment, setAssessment] = useState<FormAssessment>();
  const [submission, setSubmission] = useState<FormAssessmentSubmission>();
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});

  const currentUser = auth.user;
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation();

  const isDeviceMaxWidth900 = useMediaQuery({
    maxDeviceWidth: 900,
  });

  const isDeviceMaxWidth768 = useMediaQuery({
    maxDeviceWidth: 768,
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);

        let tempAssessment = assessmentStore.formAssessments.get(`${assessmentId}`);
        let tempSubmission = assessmentStore.formAssessmentSubmissions.get(`${submissionId}`);

        // check if they exist in store
        if (!tempAssessment) {
          await assessmentStore.fetchFormAssessmentByIds([`${assessmentId}`]);
        }
        if (!tempSubmission) {
          await assessmentStore.fetchFormAssessmentSubmission(submissionId);
        }
        await assessmentStore.fetchFormAssessmentSubmissionResult(submissionId);

        // get values after fetching
        tempAssessment = assessmentStore.formAssessments.get(`${assessmentId}`);
        tempSubmission = assessmentStore.formAssessmentSubmissions.get(`${submissionId}`);

        setAssessment(tempAssessment);
        setSubmission(tempSubmission);
        setSummary(tempSubmission!.result || {});
        setLoading(false);
      })();
    }, [assessmentId, submissionId]),
  );

  const goToAssessment = useCallback(() => {
    navigation.navigate('Plan');
  }, []);

  const goBack = useCallback(() => {
    navigation.navigate('AssessmentHistory', {
      assessmentId,
      type: ASSESSMENT_TYPE.FORM,
    });
  }, [assessmentId]);

  const onOpenExternalLink = useCallback((url: string) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS == 'web') {
          window.open(url, '_blank');
        } else {
          Linking.openURL(url);
        }
      } else {
        console.warn(`[DEBUG] Cannot open URI: ${url}`);
      }
    });
  }, []);

  const onDownload = useCallback(async () => {
    window.open(`${config.urls.api}${config.urls.formAssessmentDownload}${submissionId}`);
  }, [submissionId]);

  const getColor = (label: string) => {
    if (toLower(label) === 'yellow') {
      return '#FBBC04'; // Can change shade here
    }
    if (toLower(label) === 'green') {
      return '#34A853';
    }
    if (toLower(label) === 'red') {
      return '#EA4335';
    }
    return 'transparent';
  };

  if (!submission || !currentUser) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={() => {}}>
      <Layout style={styles.body}>
        <ScrollView>
          <Layout style={isMobile ? styles.containerMobile : styles.container}>
            <Layout style={styles.header}>
              <Layout style={styles.row}>
                <TouchableOpacity onPress={goToAssessment}>
                  <Text
                    style={
                      isDeviceMaxWidth900 ? styles.assessmentTexMaxWidth900 : styles.assessmentText
                    }>
                    Assessments
                  </Text>
                </TouchableOpacity>
                {isDeviceMaxWidth900 ? (
                  <RightOutline
                    style={styles.rightOutline}
                    width="6"
                    height="10"
                    viewBox="0 0 8 14"
                  />
                ) : (
                  <RightOutline />
                )}
                <TouchableOpacity onPress={goBack}>
                  <Text
                    style={
                      isDeviceMaxWidth900
                        ? styles.assessmentNameHeaderMaxWidth900
                        : styles.assessmentNameHeader
                    }>
                    {assessment!.name}
                  </Text>
                </TouchableOpacity>
                {isDeviceMaxWidth900 ? (
                  <RightOutline
                    style={styles.rightOutline}
                    width="6"
                    height="10"
                    viewBox="0 0 8 14"
                  />
                ) : (
                  <RightOutline />
                )}
                <Text
                  style={
                    isDeviceMaxWidth900
                      ? styles.assessmentNameHeaderMaxWidth900
                      : styles.assessmentNameHeader
                  }>
                  {moment(submission.created).format('MMM D, YYYY')}
                </Text>
              </Layout>
            </Layout>
            <Layout style={isMobile ? styles.userContainerMobile : styles.userContainer}>
              <Layout style={styles.user}>
                <Image
                  style={styles.profile as StyleProp<ImageStyle>}
                  source={
                    currentUser?.profileImage
                      ? { uri: currentUser?.profileImage }
                      : require('../../assets/images/user_placeholder.png')
                  }
                />
                <Layout style={isMobile ? styles.userDataMobile : styles.userData}>
                  <Text
                    style={
                      isMobile
                        ? styles.fullNameMobile
                        : isDeviceMaxWidth900
                        ? styles.fullNameMaxWidth900
                        : styles.fullName
                    }>
                    {currentUser?.fullName}
                  </Text>
                  <Text
                    style={isDeviceMaxWidth900 ? styles.professionMaxWidth900 : styles.profession}>
                    {`O2X ID #${currentUser?.o2XId}`}
                  </Text>
                </Layout>
              </Layout>
              <Layout style={styles.assessmentContainer}>
                <Text
                  style={
                    isDeviceMaxWidth900 ? styles.assessmentNameMaxWidth900 : styles.assessmentName
                  }>
                  {assessment!.name}
                </Text>
                <Text
                  style={
                    isDeviceMaxWidth900 ? styles.assessmentDateMaxWidth900 : styles.assessmentDate
                  }>
                  {submission.created === null
                    ? ''
                    : `date taken: ${moment(submission.created).format('MMM DD, YYYY')}`}
                </Text>
              </Layout>
              <View style={styles.exportButton}>
                <ExportPDFButton onPress={onDownload} />
              </View>
            </Layout>
            {!!summary && (
              <Layout style={styles.tableContainerWrapper}>
                <Layout
                  style={[
                    styles.tableContainer,
                    !!isDeviceMaxWidth768 && {
                      flexDirection: 'column-reverse',
                    },
                  ]}>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Name</Text>
                      <Text style={[styles.tableValue, { flex: 3, paddingLeft: -8 }]}>
                        {currentUser!.fullName}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>O2X ID#</Text>
                      <Text style={[styles.tableValue, { flex: 3, paddingLeft: -8 }]}>
                        {currentUser!.o2XId}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Test Date</Text>
                      <Text style={styles.tableValue}>
                        {submission.created === null
                          ? ''
                          : `${moment(submission.created).format('MMM DD, YYYY')}`}
                      </Text>
                      <Text style={styles.tableLabel}>Age</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'age', '')}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Gender</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'gender', '')}
                      </Text>
                      <Text style={styles.tableLabel}>Height (in)</Text>
                      <Text style={styles.tableValue}>
                        {get(get(summary, 'answers', {}), 'height', '')}
                      </Text>
                    </Layout>
                  </Layout>
                  <Layout style={[styles.tableColumn, styles.bordered, { flex: 1 }]}>
                    <Layout style={styles.tableColumn}>
                      <Text style={styles.overallLabel}>Overall Readiness Score</Text>
                      <Text style={[styles.question, styles.textCentered]}>
                        (out of {assessment?.maxScore} pts)
                      </Text>
                    </Layout>
                    <Layout style={styles.tableColumn}>
                      {loading ? (
                        <View style={styles.loader}>
                          <Spinner />
                        </View>
                      ) : (
                        <Text
                          style={[
                            styles.overallValue,
                            {
                              backgroundColor: getColor(get(summary.overallScore, 'label')),
                            },
                          ]}>
                          {get(summary.overallScore, 'value', '?')}
                        </Text>
                      )}
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerHeader}>
                  <Text style={styles.tableContainerHeaderText}>
                    Human Performance Survey Responses
                  </Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text style={styles.categoryLabel}>EAT Related Questions</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      {
                        flex: 1,
                        backgroundColor: getColor(get(get(summary.eat, 'score'), 'label')),
                      },
                    ]}>
                    <Text style={[styles.categoryValue]}>
                      {get(get(summary.eat, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    {(get(summary.eat, 'questions') || []).map((q) => (
                      <Layout style={[styles.tableRow, { flex: 1 }]}>
                        <Text style={isMobile ? styles.questionMobile : styles.question}>
                          {q.question}
                        </Text>
                        <Text style={isMobile ? styles.answerMobile : styles.answer}>
                          {q.answer}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text style={styles.categoryLabel}>
                      O2X Injury History &amp; Strength Training
                    </Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      {
                        flex: 1,
                        backgroundColor: getColor(get(get(summary.o2XSurvey, 'score'), 'label')),
                      },
                    ]}>
                    <Text style={[styles.categoryValue]}>
                      {get(get(summary.o2XSurvey, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    {(get(summary.o2XSurvey, 'questions') || []).map((q) => (
                      <Layout style={[styles.tableRow, { flex: 1 }]}>
                        <Text style={isMobile ? styles.questionMobile : styles.question}>
                          {q.question}
                        </Text>
                        <Text style={isMobile ? styles.answerMobile : styles.answer}>
                          {q.answer}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text style={styles.categoryLabel}>SWEAT Related Questions</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      {
                        flex: 1,
                        backgroundColor: getColor(get(get(summary.sweat, 'score'), 'label')),
                      },
                    ]}>
                    <Text style={[styles.categoryValue]}>
                      {get(get(summary.sweat, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    {(get(summary.sweat, 'questions') || []).map((q) => (
                      <Layout style={[styles.tableRow, { flex: 1 }]}>
                        <Text style={isMobile ? styles.questionMobile : styles.question}>
                          {q.question}
                        </Text>
                        <Text style={isMobile ? styles.answerMobile : styles.answer}>
                          {q.answer}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text style={styles.categoryLabel}>THRIVE Related Questions</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      {
                        flex: 1,
                        backgroundColor: getColor(get(get(summary.thrive, 'score'), 'label')),
                      },
                    ]}>
                    <Text style={[styles.categoryValue]}>
                      {get(get(summary.thrive, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    {(get(summary.thrive, 'questions') || []).map((q) => (
                      <Layout style={[styles.tableRow, { flex: 1 }]}>
                        <Text style={isMobile ? styles.questionMobile : styles.question}>
                          {q.question}
                        </Text>
                        <Text style={isMobile ? styles.answerMobile : styles.answer}>
                          {q.answer}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text style={styles.categoryLabel}>Overall Health Questions</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      {
                        flex: 1,
                        backgroundColor: getColor(get(get(summary.vr12, 'score'), 'label')),
                      },
                    ]}>
                    <Text style={[styles.categoryValue]}>
                      {get(get(summary.vr12, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    {(get(summary.vr12, 'questions') || []).map((q) => (
                      <Layout style={[styles.tableRow, { flex: 1 }]}>
                        <Text style={isMobile ? styles.questionMobile : styles.question}>
                          {q.question}
                        </Text>
                        <Text style={isMobile ? styles.answerMobile : styles.answer}>
                          {q.answer}
                        </Text>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerHeader}>
                  <Text style={styles.tableContainerHeaderText}>Body Composition Analysis</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Weight (lbs)</Text>
                      <Text style={[styles.tableValue, { flex: 2, paddingLeft: -8 }]}>
                        {get(get(summary, 'answers', {}), 'weight', '')}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Skeletal Muscle Mass (lbs)</Text>
                      <Text style={[styles.tableValue, { flex: 2, paddingLeft: -8 }]}>
                        {Number(
                          get(get(get(summary, 'answers', {}), 'skeletalMuscleMass', {}), 'answer'),
                        ).toFixed(2)}
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text style={styles.tableLabel}>Percent Body Fat</Text>
                      <Text style={styles.tableValue}>
                        {Number(
                          get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'answer'),
                        ).toFixed(2)}
                      </Text>
                      <Text
                        style={[
                          styles.categoryValue,
                          styles.textCentered,
                          {
                            padding: 10,
                            flex: 1,
                            backgroundColor: getColor(
                              get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'label'),
                            ),
                          },
                        ]}>
                        {get(get(get(summary, 'answers', {}), 'percentBodyFat', {}), 'label')}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for body fat percentage based on the World Health
                      Organization (WHO) classifications
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout
                    style={[
                      styles.tableColumn,
                      styles.bordered,
                      { flex: 3, justifyContent: 'space-around' },
                    ]}>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'< 24.9 %'}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'< 25.0 - 29.9 %'}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow]}>
                      <Text
                        style={
                          isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                        }>
                        {'> 30.0 %'}
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerHeader}>
                  <Text style={styles.tableContainerHeaderText}>Movement Protocols</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                        styles.textCentered,
                      ]}>
                      Leg length (cm)
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Right
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(
                        get(get(get(summary, 'answers', {}), 'legLengthRightLeg', {}), 'answer'),
                      ).toFixed(2)}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Left
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(
                        get(get(get(summary, 'answers', {}), 'legLengthLeftLeg', {}), 'answer'),
                      ).toFixed(2)}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                        styles.textCentered,
                      ]}>
                      Knee to Wall
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          flex: 1,
                          padding: 8,
                          backgroundColor: getColor(get(get(summary.kneeToWall, 'score'), 'label')),
                        },
                      ]}>
                      {get(get(summary.kneeToWall, 'score'), 'label')}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Right Ankle (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(
                        get(get(get(summary, 'answers', {}), 'kneeToWallRightLeg', {}), 'answer'),
                      ).toFixed(2)}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Left Ankle (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(
                        get(get(get(summary, 'answers', {}), 'kneeToWallLeftLeg', {}), 'answer'),
                      ).toFixed(2)}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Asymmetry (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.kneeToWall, 'score'), 'asymmetry')).toFixed(2)}
                    </Text>
                  </Layout>
                </Layout>

                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for Knee to Wall asymmetry
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={[styles.tableRow, styles.bordered, { flex: 1 }]}>
                      <Layout
                        style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 1.80 cm'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          1.80 - 3.40 cm
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 3.40 cm'}
                        </Text>
                      </Layout>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginBottom: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1.5 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Exercise Recommendation
                    </Text>
                    {/* add smaller font for mobile */}
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      <TouchableOpacity
                        style={styles.link}
                        onPress={() => {
                          onOpenExternalLink(
                            get(get(summary.kneeToWall, 'recommendation'), 'exerciseLink'),
                          );
                        }}>
                        {get(get(summary.kneeToWall, 'recommendation'), 'exercise')}
                      </TouchableOpacity>
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Days per Week
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.kneeToWall, 'recommendation'), 'daysPerWeek')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Sets
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.kneeToWall, 'recommendation'), 'sets')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Reps
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.kneeToWall, 'recommendation'), 'reps')}
                    </Text>
                  </Layout>
                </Layout>

                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text
                        style={[
                          isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                          styles.textCentered,
                        ]}>
                        Grip Strength
                      </Text>
                    </Layout>
                    <Layout style={styles.tableRow}>
                      <Text
                        style={[
                          styles.categoryValue,
                          {
                            flex: 1,
                            padding: 8,
                            backgroundColor: getColor(
                              get(get(summary.gripStrength, 'score'), 'label'),
                            ),
                          },
                        ]}>
                        {get(get(summary.gripStrength, 'score'), 'label')}
                      </Text>
                    </Layout>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Average Right (kg)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.gripStrength, 'score'), 'averageRight')).toFixed(2)}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Average Left (kg)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.gripStrength, 'score'), 'averageLeft')).toFixed(2)}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Average (kg)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.gripStrength, 'score'), 'average')).toFixed(2)}
                    </Text>
                  </Layout>
                </Layout>

                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for Grip Strength (kg)
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={styles.tableColumn} />
                      <Layout style={styles.tableColumn} />
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          Age
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          Male
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          Female
                        </Text>
                      </Layout>
                    </Layout>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 29'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          30 - 39
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          40 - 49
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          50 - 59
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          60+
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 52 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 52 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 49 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 46 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 45 kg'}
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 32 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 32 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 31 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 28 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 24 kg'}
                        </Text>
                      </Layout>
                    </Layout>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 29'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          30 - 39
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          40 - 49
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          50 - 59
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          60+
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          43 - 51 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          43 - 51 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          40 - 48 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          38 - 45 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          38 - 44 kg
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          26 - 31 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          26 - 31 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          24 - 30 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          22 - 27 kg
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          20 - 23 kg
                        </Text>
                      </Layout>
                    </Layout>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 29'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          30 - 39
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          40 - 49
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          50 - 59
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          60+
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 43 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 43 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 40 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 38 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 38 kg'}
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 26 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 26 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 24 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 22 kg'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 20 kg'}
                        </Text>
                      </Layout>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginBottom: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1.5 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Exercise Recommendation
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      <TouchableOpacity
                        style={styles.link}
                        onPress={() => {
                          onOpenExternalLink(
                            get(get(summary.gripStrength, 'recommendation'), 'exerciseLink'),
                          );
                        }}>
                        {get(get(summary.gripStrength, 'recommendation'), 'exercise')}
                      </TouchableOpacity>
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Days per Week
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.gripStrength, 'recommendation'), 'daysPerWeek')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Sets
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.gripStrength, 'recommendation'), 'sets')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Reps
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.gripStrength, 'recommendation'), 'reps')}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                        styles.textCentered,
                      ]}>
                      Hand Taps
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          flex: 1,
                          padding: 8,
                          backgroundColor: getColor(
                            get(get(summary.shoulderHandTap, 'score'), 'label'),
                          ),
                        },
                      ]}>
                      {get(get(summary.shoulderHandTap, 'score'), 'label')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Score (reps)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(get(summary, 'answers', {}), 'handTaps', {}), 'answer')}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for Hand Taps
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Layout style={[styles.tableRow, { marginTop: 20 }]} />
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={styles.tableColumn} />
                      <Layout style={styles.tableColumn} />
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          Male
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          Female
                        </Text>
                      </Layout>
                    </Layout>
                    <Layout style={[styles.tableRow, styles.bordered]}>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 23 reps'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          18 - 22 reps
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 17 reps'}
                        </Text>
                      </Layout>
                      <Layout style={[styles.tableColumn, { flex: 1 }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 21 reps'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          17 - 20 reps
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 16 reps'}
                        </Text>
                      </Layout>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginBottom: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1.5 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Exercise Recommendation
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      <TouchableOpacity
                        style={styles.link}
                        onPress={() => {
                          onOpenExternalLink(
                            get(get(summary.shoulderHandTap, 'recommendation'), 'exerciseLink'),
                          );
                        }}>
                        {get(get(summary.shoulderHandTap, 'recommendation'), 'exercise')}
                      </TouchableOpacity>
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Days per Week
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.shoulderHandTap, 'recommendation'), 'daysPerWeek')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Sets
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.shoulderHandTap, 'recommendation'), 'sets')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Reps
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.shoulderHandTap, 'recommendation'), 'reps')}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                        styles.textCentered,
                      ]}>
                      Y-balance
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          flex: 1,
                          padding: 8,
                          backgroundColor: getColor(get(get(summary.ybalance, 'score'), 'label')),
                        },
                      ]}>
                      {get(get(summary.ybalance, 'score'), 'label')}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Right Anterior Average (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.ybalance, 'score'), 'lowerRightAnteriorAvg')).toFixed(
                        2,
                      )}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Left Anterior Average (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.ybalance, 'score'), 'lowerLeftAnteriorAvg')).toFixed(
                        2,
                      )}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Anterior Asymmetry (cm)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {Number(get(get(summary.ybalance, 'score'), 'asymmetry')).toFixed(2)}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for Y-balance Anterior Reach asymmetry
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={[styles.tableRow, styles.bordered, { flex: 1 }]}>
                      <Layout
                        style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'<= 4 cm AND < 10% difference in composite'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 4 cm OR > 10% difference in composite'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 4 cm AND > 10% difference in composite'}
                        </Text>
                      </Layout>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginBottom: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1.5 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Exercise Recommendation
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      <TouchableOpacity
                        style={styles.link}
                        onPress={() => {
                          onOpenExternalLink(
                            get(get(summary.ybalance, 'recommendation'), 'exerciseLink'),
                          );
                        }}>
                        {get(get(summary.ybalance, 'recommendation'), 'exercise')}
                      </TouchableOpacity>
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Days per Week
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.ybalance, 'recommendation'), 'daysPerWeek')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Sets
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.ybalance, 'recommendation'), 'sets')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Reps
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.ybalance, 'recommendation'), 'reps')}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelHeaderMobile : styles.tableLabelHeader,
                        styles.textCentered,
                      ]}>
                      Lunges
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          flex: 1,
                          padding: 8,
                          backgroundColor: getColor(get(get(summary.lunge, 'score'), 'label')),
                        },
                      ]}>
                      {get(get(summary.lunge, 'score'), 'label')}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Right Leg (reps)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(
                        get(get(summary, 'answers', {}), 'weightedForwardLungeRightLeg', {}),
                        'answer',
                      )}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Left Leg (reps)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(
                        get(get(summary, 'answers', {}), 'weightedForwardLungeLeftLeg', {}),
                        'answer',
                      )}
                    </Text>
                  </Layout>

                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Total (reps)
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.lunge, 'score'), 'totalLunge') || ''}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Normative ranges for Weighted Lunge Total
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.categoryValue, { color: getColor('green') }]}>GREEN</Text>
                    <Text style={[styles.categoryValue, { color: getColor('yellow') }]}>
                      YELLOW
                    </Text>
                    <Text style={[styles.categoryValue, { color: getColor('red') }]}>RED</Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 3 }]}>
                    <Layout style={[styles.tableRow, styles.bordered, { flex: 1 }]}>
                      <Layout
                        style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'> 50 reps'}
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          38 - 49 reps
                        </Text>
                        <Text
                          style={
                            isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller
                          }>
                          {'< 37 reps'}
                        </Text>
                      </Layout>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={[styles.tableContainer, { marginBottom: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1.5 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Exercise Recommendation
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      <TouchableOpacity
                        style={styles.link}
                        onPress={() => {
                          onOpenExternalLink(
                            get(get(summary.lunge, 'recommendation'), 'exerciseLink'),
                          );
                        }}>
                        {get(get(summary.lunge, 'recommendation'), 'exercise')}
                      </TouchableOpacity>
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Days per Week
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.lunge, 'recommendation'), 'daysPerWeek')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Sets
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.lunge, 'recommendation'), 'sets')}
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Text
                      style={[
                        isMobile ? styles.tableLabelMobile : styles.tableLabel,
                        styles.textCentered,
                      ]}>
                      Reps
                    </Text>
                    <Text
                      style={[
                        isMobile ? styles.tableValueMobile : styles.tableValue,
                        styles.textCentered,
                      ]}>
                      {get(get(summary.lunge, 'recommendation'), 'reps')}
                    </Text>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerHeader}>
                  <Text style={styles.tableContainerHeaderText}>Recommendations</Text>
                </Layout>
                <Layout style={styles.tableContainerSubHeader}>
                  <Text style={styles.tableContainerSubHeaderText}>Eat</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={isMobile ? styles.questionMobile : styles.question}>
                        {get(get(summary.eat, 'recommendation'), 'description')}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow, { flex: 1 }]}>
                      <Text
                        style={[
                          isMobile ? styles.tableValueMobile : styles.tableValue,
                          { flex: 2 },
                        ]}>
                        <TouchableOpacity
                          style={styles.link}
                          onPress={() => {
                            onOpenExternalLink(get(get(summary.eat, 'recommendation'), 'link'));
                          }}>
                          {get(get(summary.eat, 'recommendation'), 'title')}
                        </TouchableOpacity>
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerSubHeader}>
                  <Text style={styles.tableContainerSubHeaderText}>Sweat</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={isMobile ? styles.questionMobile : styles.question}>
                        {get(get(summary.sweat, 'recommendation'), 'description')}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow, { flex: 1 }]}>
                      <Text
                        style={[
                          isMobile ? styles.tableValueMobile : styles.tableValue,
                          { flex: 2 },
                        ]}>
                        <TouchableOpacity
                          style={styles.link}
                          onPress={() => {
                            onOpenExternalLink(
                              'https://portal.o2x.com/Home/ExploreStack/Explore/Explore?selected=sweat',
                            );
                          }}>
                          {get(get(summary.sweat, 'recommendation'), 'title')}
                        </TouchableOpacity>
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>
                <Layout style={styles.tableContainerSubHeader}>
                  <Text style={styles.tableContainerSubHeaderText}>Thrive</Text>
                </Layout>
                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1 }]}>
                    <Layout style={styles.tableRow}>
                      <Text style={isMobile ? styles.questionMobile : styles.question}>
                        {get(get(summary.thrive, 'recommendation'), 'description')}
                      </Text>
                    </Layout>
                    <Layout style={[styles.tableRow, { flex: 1 }]}>
                      <Text
                        style={[
                          isMobile ? styles.tableValueMobile : styles.tableValue,
                          { flex: 2 },
                        ]}>
                        <TouchableOpacity
                          style={styles.link}
                          onPress={() => {
                            onOpenExternalLink(get(get(summary.thrive, 'recommendation'), 'link'));
                          }}>
                          {get(get(summary.thrive, 'recommendation'), 'title')}
                        </TouchableOpacity>
                      </Text>
                    </Layout>
                  </Layout>
                </Layout>

                <Layout style={[styles.tableContainer, { marginTop: 20 }]}>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      {'<'}
                    </Text>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Less than
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      {'>'}
                    </Text>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Greater than
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      =
                    </Text>
                    <Text
                      style={isMobile ? styles.tableLabelSmallerMobile : styles.tableLabelSmaller}>
                      Equal to
                    </Text>
                  </Layout>
                </Layout>

                <Layout style={styles.tableContainer}>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('green'),
                          padding: 10,
                          marginHorizontal: 20,
                        },
                      ]}>
                      Green
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('yellow'),
                          padding: 10,
                          marginHorizontal: 20,
                        },
                      ]}>
                      Yellow
                    </Text>
                    <Text
                      style={[
                        styles.categoryValue,
                        {
                          backgroundColor: getColor('red'),
                          padding: 10,
                          marginHorizontal: 20,
                        },
                      ]}>
                      Red
                    </Text>
                  </Layout>
                  <Layout style={[styles.tableColumn, { flex: 1, justifyContent: 'space-around' }]}>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Keep up the hard work. Green is great - but doesn't mean perfect.{' '}
                    </Text>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Still room for improvement. Work on these sections to get in the Green.{' '}
                    </Text>
                    <Text style={[styles.tableLabelSmaller, { padding: 8 }]}>
                      Focus is needed in this area to help mitigate the risk of illness or injury.{' '}
                    </Text>
                  </Layout>
                </Layout>
                <Text style={styles.disclaimer}>
                  Disclaimer: It is impossible to properly diagnose and recommend a course of action
                  from a distance. The information provided by our O2X specialists is intended to be
                  educational and general in nature, and should not replace the personal advice and
                  recommendations of your own health care professionals. To make sure you're doing
                  the right thing, you should consult with your health care providers before taking
                  any course of action.
                </Text>
              </Layout>
            )}
          </Layout>
        </ScrollView>
      </Layout>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  body: {
    flex: 1,
    width: '100%',
    backgroundColor: 'wallpaper',
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    paddingTop: 20,
  },
  containerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: 15,
    paddingTop: 10,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'wallpaper',
  },
  image: {
    height: 150,
    marginBottom: 24,
  },
  maxScore: {
    marginBottom: 8,
  },
  name: {
    marginBottom: 24,
    marginHorizontal: 24,
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  assessmentText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
  },
  assessmentTexMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    paddingRight: 10,
    color: '#4E7B89',
    marginBottom: 8,
  },
  assessmentContainer: {
    marginTop: 20,
    paddingRight: 50,
  },
  assessmentNameHeader: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    paddingLeft: 12,
  },
  assessmentNameHeaderMaxWidth900: {
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 8,
  },
  userContainer: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userContainerMobile: {
    width: '100%',
  },
  profile: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  user: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  userData: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  userDataMobile: {
    paddingLeft: 10,
    width: '100%',
  },
  fullName: {
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMaxWidth900: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
  },
  fullNameMobile: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 4,
    width: '80%',
  },
  profession: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  professionMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    color: '#4E7B89',
    textTransform: 'uppercase',
  },
  assessmentName: {
    fontSize: 24,
    lineHeight: 28,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentNameMaxWidth900: {
    fontSize: 18,
    letterSpacing: 1,
    marginBottom: 4,
    flexWrap: 'wrap',
  },
  assessmentDate: {
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  assessmentDateMaxWidth900: {
    fontSize: 10,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  overallText: {
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#C4C4C4',
    textTransform: 'uppercase',
  },
  rightOutline: {
    marginBottom: 8,
    marginRight: 12,
  },
  tableContainerWrapper: {
    paddingVertical: 15,
    justifyContent: 'center',
  },
  tableContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#697484',
    paddingHorizontal: 10,
    paddingVertical: 17,
    // marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  tableColumn: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableLabelHeader: {
    fontSize: 16,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableLabelHeaderMobile: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableLabel: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableLabelMobile: {
    fontSize: 10,
    lineHeight: 12,
    textTransform: 'uppercase',
    flex: 1,
    padding: 2,
  },
  tableValue: {
    fontSize: 16,
    lineHeight: 19,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  tableValueMobile: {
    fontSize: 10,
    lineHeight: 12,
    textTransform: 'uppercase',
    flex: 1,
    padding: 2,
  },
  overallLabel: {
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: 8,
  },
  overallValue: {
    fontSize: 40,
    textAlign: 'center',
    color: 'white',
    margin: 8,
  },
  bordered: {
    borderWidth: 1,
    borderColor: '#697484',
  },
  tableContainerHeader: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    backgroundColor: '#31576D',
  },
  tableContainerHeaderText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: 22,
  },
  tableContainerSubHeader: {
    paddingHorizontal: 25,
    paddingVertical: 2,
    backgroundColor: '#767f6a',
  },
  tableContainerSubHeaderText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 22,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  categoryLabel: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tableLabelSmaller: {
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'center',
    padding: 10,
  },
  tableLabelSmallerMobile: {
    fontSize: 10,
    lineHeight: 12,
    textAlign: 'center',
    padding: 6,
  },
  categoryValue: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  question: {
    fontSize: 14,
    lineHeight: 16,
    // textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  questionMobile: {
    fontSize: 10,
    lineHeight: 12,
    // textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  answer: {
    fontSize: 16,
    lineHeight: 19,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  answerMobile: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    flex: 1,
    padding: 8,
  },
  textCentered: {
    textAlign: 'center',
  },
  link: {
    borderBottomWidth: 1,
    // prev color: #4e7b89
    borderColor: '#79d6d9',
    color: '#79d6d9',
  },
  disclaimer: {
    fontStyle: 'italic',
    fontSize: 12,
    fontWeight: '400',
    marginTop: 20,
  },
  exportButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingHorizontal: 20,
  },
});

export default observer(ReadinessAssessmentResult);
