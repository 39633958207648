import { Picker } from '@react-native-picker/picker';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { FTE_UTILS } from 'o2x-store/src/utils/fteadmin';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Header from '../Header';
import TopNavItem from './TopNavItem';

type Props = MaterialTopTabBarProps & {
  height?: number;
};

const TopNavBar: React.FC<Props> = (props) => {
  const { height = 52, navigation, state } = props;
  const { type: item } = { type: FTE_UTILS.CONTENT };
  const [selected, setSelected] = useState<FTE_UTILS>(item);
  const styles = useStyleSheet(themedStyles);
  const navItems = [
    FTE_UTILS.CONTENT,
    FTE_UTILS.CALENDAR,
    FTE_UTILS.CONTACT,
    FTE_UTILS.DASHBOARD,
    FTE_UTILS.LIBRARY,
    FTE_UTILS.ATHLETE_PROFILE,
  ];

  const onNavigate = useCallback((route) => {
    navigation.navigate(route.name);
  }, []);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 812,
  });

  return (
    <View>
      <Layout style={styles.headerStyle}>
        <Header />
      </Layout>
      {isMobile ? (
        <Layout style={styles.dropdown}>
          <Picker
            itemStyle={{
              backgroundColor: '#091C2D',
              color: 'white',
            }}
            style={styles.data}
            selectedValue={state.routes[state.index].name}
            onValueChange={(itemValue, itemindex) => onNavigate(state.routes[itemindex])}>
            {state.routes.map((route, index) => (
              <Picker.Item key={index} label={route.name} value={route.name} color="white" />
            ))}
          </Picker>
        </Layout>
      ) : (
        <Layout style={[styles.container, { height }]}>
          {state.routes.map((route, index) => (
            <Layout key={index} style={styles.navItem}>
              <TopNavItem
                key={route.key}
                item={navItems[index]}
                selected={state.index === index}
                onSelect={() => onNavigate(route)}
              />
            </Layout>
          ))}
        </Layout>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    paddingLeft: 26,
    paddingTop: 10,
  },
  headerStyle: {
    backgroundColor: '#10283E',
    paddingLeft: 16,
    paddingTop: 13,
    paddingRight: 16,
  },
  navItem: {
    marginRight: 20,
  },
  data: {
    width: '40%',
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#10283E',
    color: 'white',
  },
  dropdown: {
    paddingLeft: 26,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#10283E',
  },
});

export default TopNavBar;
