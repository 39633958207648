import { computed } from 'mobx';
import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import Url from 'url-parse';

@model('o2x-store/Resource')
export default class Resource extends Model({
  id: prop<number>(),
  name: prop<string>(''),
  isEat: prop<boolean>(),
  isSweat: prop<boolean>(),
  isThrive: prop<boolean>(),
  imageThumbnail: prop<string>(''),
  videoLink: prop<string>(''),
  fileUpload: prop<string>(''),
  externalUrl: prop<string>(''),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<Resource>) {
    Object.assign(this, data);
  }

  @computed
  get videoId(): string | undefined {
    // Assumes that the videoLink is youtube.
    const url = new Url(this.videoLink, true);

    if (url.host === 'youtu.be') {
      return url.pathname.replace('/', '');
    }

    return url.query.v;
  }
}
