import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import Organization from 'src/containers/Organization';
import Header from '../../Header';

type Props = {};

const Stack = createStackNavigator();

const SettingsStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
  >
    <Stack.Screen name="Organization" component={Organization} />
  </Stack.Navigator>
);

export default SettingsStack;
