import { Layout, Spinner, Text as KText } from '@ui-kitten/components';
import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';

interface Props {
  text?: string;
  ready?: boolean;
}

const Loading: React.FC<Props> = ({ text = 'Loading...', ready = true }) =>
  ready ? (
    <Layout style={styles.container}>
      <Spinner />
      <KText style={styles.text}>{text}</KText>
    </Layout>
  ) : (
    <View style={styles.container}>
      <ActivityIndicator />
      <Text style={styles.text}>{text}</Text>
    </View>
  );

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#1A3248',
  },
  text: {
    color: 'white',
    marginLeft: 8,
  },
});

export default Loading;
