import { RouteProp, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get } from 'lodash';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import TopNav from '../../components/Question/TopNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveOptions'>;
};

const ThriveOptions: React.FC<Props> = (props) => {
  const { programId, dayId } = props.route.params;
  const { thrive } = useStore();
  const program = programId && thrive.thrivePrograms.get(`${programId}`);
  const currentDayId = dayId || get(program, 'userProgress.currentDay', null);
  const day = currentDayId && thrive.thriveProgramDays.get(`${currentDayId}`);
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  if (!program) {
    return null;
  }

  const onMarkComplete = useCallback(async () => {
    if (day) {
      await day.markComplete();
      await program.fetch();
    }
    const nextDayId = get(program, 'userProgress.currentDay', null);
    const nextDay = nextDayId && thrive.thriveProgramDays.get(`${nextDayId}`);

    if (!nextDay) {
      await program.markComplete();
      navigation.goBack();
    }

    navigation.navigate('ThriveProgramDayComplete', {
      programId: program ? program.id : undefined,
      dayId: currentDayId,
    });
  }, [program, currentDayId]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [program, day]);

  const onQuit = useCallback(() => {
    navigation.navigate('ThriveOptionsQuit', {
      programId,
    });
  }, [program]);

  const onJump = useCallback(() => {
    navigation.navigate('ThriveOptionsJump', {
      programId,
      dayId: currentDayId,
    });
  }, [program, day, dayId]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onBack={goBack} showClose={false} style={styles.navigationStyle} />
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.header}>
                <Text category="h2">{program.name}</Text>
              </View>
              <TouchableOpacity style={styles.optionItem} onPress={onMarkComplete}>
                <Text style={styles.optionText}>Mark today's exercise as complete</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.optionItem} onPress={onJump}>
                <Text style={styles.optionText}>Jump to day...</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.optionItem} onPress={onQuit}>
                <Text style={styles.optionText}>Quit plan</Text>
              </TouchableOpacity>
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  navigationStyle: {
    backgroundColor: 'black',
  },
  contentContainer: {
    padding: 24,
    marginTop: 16,
  },
  completeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completeIcon: {
    marginRight: 12,
  },
  completeText: {
    color: 'gray',
    textTransform: 'uppercase',
  },
  header: {
    marginTop: -24,
    marginBottom: 20,
  },
  optionItem: {
    marginVertical: 20,
  },
  optionText: {
    fontSize: 18,
    lineHeight: 22,
  },
});

export default ThriveOptions;
