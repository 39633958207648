import { computed, observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import { TIMER_TYPE } from 'src/models/TimerStart';
import Url from 'url-parse';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';

export type PhysicalAssessmentScoreMapping = {
  id: number;
  minValue: number;
  maxValue: number;
  unit: string;
  gender: string;
  minAge: number;
  maxAge: number;
  score: number;
  badge: string;
};

export type PhysicalAssessmentScoring = {
  id: number;
  mappings: PhysicalAssessmentScoreMapping[];
};

@model('o2x-store/PhysicalAssessmentItem')
export default class PhysicalAssessmentItem extends Model({
  id: prop<number>(),
  assessment: prop<number>(),
  scoring: prop<PhysicalAssessmentScoring>(),
  title: prop<string>(''),
  label: prop<string>(''),
  shortDescription: prop<string>(''),
  longDescription: prop<string>(''),
  notes: prop<string>(''),
  image: prop<string | null>(null),
  imageThumbnail: prop<string | null>(null),
  imageBanner: prop<string | null>(null),
  timeOrAttempts: prop<number>(0),
  exercises: prop<number[]>(() => []),
  rest: prop<string>(''),
  unit: prop<string>(''),
  unitDisplay: prop<string>(''),
  timer: prop<TIMER_TYPE | ''>(''),
  timerLengthInSeconds: prop<number | null>(null),
  equipment: prop<string>(''),
  itemType: prop<string>(''),
  videoLink: prop<string>(''),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(data: ModelInstanceCreationData<PhysicalAssessmentItem>) {
    Object.assign(this, data);
  }

  @modelFlow
  answer = _async(function* (
    this: PhysicalAssessmentItem,
    physicalAssessmentSubmissionId: number,
    value: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(
        api.answerPhysicalAssessmentItem(
          rootStore.auth.token,
          this.assessment,
          this.id,
          physicalAssessmentSubmissionId,
          value,
        ),
      );
    } catch (error) {
      console.warn('[DEBUG] error answering a physical assessment item', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @computed
  get videoId(): string | undefined {
    // Assumes that the videoLink is youtube.
    const url = new Url(this.videoLink, true);
    return url.query.v;
  }
}
