import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { filter, indexOf } from 'lodash';
import { observer } from 'mobx-react-lite';
import EventModel from 'o2x-store/src/models/Event';
import { Program } from 'o2x-store/src/models/Program';
import SavedList from 'o2x-store/src/models/SavedList';
import { useStore } from 'o2x-store/src/stores';
import { getListByType, getSource } from 'o2x-store/src/utils/list';
import { getTaskColor } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'src/components/Question/TopNav';
import CheckBox from '../../components/CheckBox';
import SavedProgramItem from '../../components/Saved/SavedProgramItem';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const PlansEdit: React.FC<Props> = (props) => {
  const {
    route: {
      params: { listType, listIds },
    },
  } = props;

  const root = useStore();
  const { savedList } = root;
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  const [selectedList, setSelectedList] = useState<SavedList>();
  const [items, setItems] = useState<Map<string, Program | EventModel>>();
  const [selectedItems, setSelectedItems] = useState<Array<number>>(new Array<number>());

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    if (listIds.length > 0) {
      const listId = listIds[0];
      const newList = getListByType(savedList, listType, listId);
      setSelectedList(newList);
      const items = new Map<string, Program | EventModel>();
      const source = getSource(root, listType);
      newList?.items.forEach((itemId: number) => {
        const item = source.get(`${itemId}`);
        if (item) {
          items.set(`${itemId}`, item);
        }
      });
      setItems(items);
    }
  }, [listType, listIds]);

  const onSubmit = useCallback(
    async (data) => {
      if (selectedList && selectedItems.length > 0) {
        setLoading(true);
        const updateData = {
          items: filter(selectedList?.items, (i) => indexOf(selectedItems, i) === -1),
        };
        const result = await savedList.updateList(updateData, listType, selectedList.id);
        setLoading(false);
        if (result.ok) {
          navigation.navigate('Saved', { type: listType });
        } else {
          setErrorMessage(getErrors(result.errors));
        }
      }
    },
    [listType, selectedList, selectedItems],
  );

  const onToggleSelected = (checked: boolean, itemId: number) => {
    if (checked) {
      const newSelectedItems = [...selectedItems, itemId];
      setSelectedItems(newSelectedItems);
    } else {
      const filteredSelectedItems = filter(selectedItems, (i) => i !== itemId);
      setSelectedItems(filteredSelectedItems);
    }
  };

  const removeSelectedFromDisplay = useCallback(() => {
    const filteredItems = new Map<string, Program | EventModel>();
    items?.forEach((item: Program | EventModel) => {
      filteredItems.set(`${item.id}`, item);
    });
    selectedItems.forEach((itemId: number) => {
      filteredItems?.delete(`${itemId}`);
    });
    setItems(filteredItems);
  }, [selectedItems, items]);

  const renderEmptyList = useCallback(
    () => <View style={isMobile || isDeviceMaxWidth600 ? styles.spacerMobile : styles.spacer} />,
    [],
  );

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <View style={styles.itemContainer}>
          <CheckBox
            key={item.id}
            fill={getTaskColor(item.type)}
            checked={indexOf(selectedItems, item.id) > -1}
            onChange={(key: string, checked: boolean) => {
              onToggleSelected(checked, item.id);
            }}
            style={styles.input}
          />
          <View style={styles.inputLabel}>
            <SavedProgramItem program={item} showHandle={false} key={item.id} />
          </View>
        </View>
      ) : null,
    [selectedItems],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback onPress={goBack}>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onClose={goBack} showBack={false} />
            </View>
            <View style={styles.scroll}>
              <Layout
                style={
                  isMobile || isDeviceMaxWidth600
                    ? styles.scrollWrapperMobile
                    : styles.scrollWrapper
                }>
                <Layout style={styles.headerContainer}>
                  {!selectedList ? (
                    <Text category="h2" style={styles.header}>
                      New List
                    </Text>
                  ) : (
                    <Text category="h2" style={styles.header}>
                      Edit List
                    </Text>
                  )}
                </Layout>
                {!!errorMessage && (
                  <Text style={styles.error} category="label">
                    {errorMessage}
                  </Text>
                )}
                <View
                  style={
                    isMobile || isDeviceMaxWidth600
                      ? styles.listContainerMobile
                      : styles.listContainer
                  }>
                  {selectedList && (
                    <View style={styles.actionLinks}>
                      <Text style={{ color: selectedList.color, fontSize: 14 }}>
                        {selectedItems.length} Selected
                      </Text>
                      <TouchableOpacity onPress={removeSelectedFromDisplay}>
                        <Text style={styles.link}>Remove</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {items && (
                    <FlatList
                      data={Array.from(items.values())}
                      renderItem={renderItem}
                      keyExtractor={(item) => `${item.id}`}
                      style={isMobile ? styles.listItemMobile : styles.listItem}
                      ListEmptyComponent={renderEmptyList}
                    />
                  )}
                </View>
              </Layout>
            </View>
            <Layout style={[styles.actions, { paddingBottom: insets.bottom }]}>
              <TouchableOpacity
                style={[styles.button, styles.buttonCancel]}
                disabled={loading}
                onPress={goBack}>
                <Text style={styles.buttonLabel} category="c1">
                  Cancel
                </Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} disabled={loading} onPress={onSubmit}>
                <Text style={styles.buttonLabel} category="c1">
                  Save
                </Text>
              </TouchableOpacity>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  actions: {
    flexDirection: 'row',
  },
  button: {
    flex: 1,
    height: 48,
    justifyContent: 'center',
    backgroundColor: 'olive',
  },
  buttonCancel: {
    backgroundColor: 'dark-blue',
  },
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  container: {
    flex: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  header: {
    marginVertical: 30,
    marginTop: 0,
    fontSize: 24,
    lineHeight: 29,
    textAlign: 'center',
  },
  inputContainer: {
    flex: 1,
  },
  label: {
    textTransform: 'uppercase',
  },
  scroll: {
    flex: 1,
    alignItems: 'center',
  },
  scrollWrapper: {
    flex: 1,
  },
  scrollWrapperMobile: {
    flex: 1,
    width: '100%',
  },
  delete: {
    width: 24,
    marginLeft: 10,
  },
  deleteText: {
    color: 'red',
    fontSize: 18,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    marginBottom: 20,
  },
  inlineInput: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionLinks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 24,
    paddingBottom: 10,
  },
  link: {
    fontSize: 14,
    color: 'light-gray',
    borderBottomWidth: 1,
    borderColor: 'light-gray',
    borderStyle: 'solid',
  },
  input: {
    paddingTop: 30,
  },
  itemContainer: {
    flexDirection: 'row',
  },
  inputLabel: {
    marginLeft: 24,
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  headerContainer: {
    paddingHorizontal: 24,
  },
  listContainer: {
    flex: 1,
  },
  listContainerMobile: {
    flex: 1,
    width: '100%',
  },
  listItem: {
    paddingHorizontal: 24,
  },
  listItemMobile: {
    paddingHorizontal: 15,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  spacer: {
    width: 450,
  },
  spacerMobile: {
    width: '90%',
  },
});

export default observer(PlansEdit);
