import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import { observer } from 'mobx-react-lite';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import { LIST_TYPE } from 'o2x-store/src/utils/list';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Animated, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import NavigationBar from '../../components/NavigationBar';
import SweatStartWorkout from '../../components/SweatStart/SweatStartWorkout';
import { WorkoutGeneratorHeader } from '../../components/WorkoutGenerator';
import { useNativeStore } from '../../stores';

type Props = {
  route?: any | null;
};

const Workout: React.FC<Props> = (props) => {
  const {
    route: {
      params: { workoutId },
    },
  } = props;

  const { sweatStart, workoutFilter } = useNativeStore();
  const { filters } = workoutFilter;
  const { sweat } = useStore();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const [workout, setWorkout] = useState<SweatWorkout>();
  const titleOpacity = useRef(new Animated.Value(0));

  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  useKeepAwake();

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const [collapseFilter, setCollapseFilter] = useState<boolean>(false);

  useFocusEffect(
    useCallback(() => {
      sweatStart.setIsWorkoutGenerator(true);
    }, [sweatStart]),
  );
  const showFilter = useCallback(() => {
    setCollapseFilter(false);
  }, [setCollapseFilter]);

  const hideFilter = useCallback(() => {
    setCollapseFilter(true);
  }, [setCollapseFilter]);

  const goBack = useCallback(() => {
    if (sweatStart.active) {
      const doEnd = confirm('Are you sure you want to go back?');
      if (doEnd) {
        sweatStart.endWorkout();
        sweatStart.reset();
        workoutFilter.reset();
        sweatStart.setIsWorkoutGenerator(false);
        navigation.goBack();
      }
    } else {
      workoutFilter.reset();
      sweatStart.reset();
      sweatStart.setIsWorkoutGenerator(false);
      navigation.goBack();
    }
  }, [sweatStart.active]);

  const generateWorkout = useCallback(() => {
    setLoading(true);
    const workout = sweat.sweatWorkouts.get(`${workoutId}`);
    if (workout) {
      setWorkout(workout);
    }
    setLoading(false);
  }, [filters, workoutFilter]);

  const onGenerateWorkout = useCallback(() => {
    if (sweatStart.active) {
      Alert.alert('Are you sure you want to regenerate?', 'This will stop the ongoing workout', [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Regenerate',
          onPress: () => {
            sweatStart.endWorkout();
            sweatStart.reset();
          },
        },
      ]);
    } else {
      sweatStart.reset();
      generateWorkout();
    }
  }, [sweatStart.active]);

  const onRegenerate = useCallback(() => {
    const items = Array.from(sweat.sweatWorkouts.values());
    const randomWorkoutId = items[Math.floor(Math.random() * items.length)].id;
    const workout = sweat.sweatWorkouts.get(`${randomWorkoutId}`);
    if (workout) {
      setWorkout(workout);
    }
  }, [sweat.sweatWorkouts.values(), setWorkout]);

  const onSave = useCallback(() => {
    if (workout && workout.saveList.length === 0) {
      navigation.navigate('SaveToList', {
        listType: LIST_TYPE.SWEAT_WORKOUT,
        itemId: workout.id,
      });
    }
  }, [workout]);

  const onFilterWorkout = useCallback(() => {
    navigation.navigate('WorkoutGeneratorFilter');
  }, []);

  const renderListHeader = useCallback(
    () => (
      <WorkoutGeneratorHeader
        workoutId={workout?.id}
        isSaved={!!workout && workout.saveList.length > 0}
        onSave={onSave}
        onGenerate={onRegenerate}
        onFilter={onFilterWorkout}
        collapseFilter={collapseFilter}
      />
    ),
    [workout, onSave, onGenerateWorkout, collapseFilter],
  );

  useEffect(() => {
    onGenerateWorkout();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <Layout style={styles.container}>
            <SweatStartWorkout
              loading={loading}
              workout={workout}
              titleOpacity={titleOpacity.current}
              renderListHeader={renderListHeader}
              onScrollBegin={hideFilter}
              onScrollToTop={showFilter}
            />
          </Layout>
          <NavigationBar
            style={[styles.navigation, { paddingTop: insets.top }]}
            title="Workout Generator"
            titleOpacity={titleOpacity.current.interpolate({
              inputRange: [0, 60, 80],
              outputRange: [0, 0, 1],
            })}
            showBack={false}
            showClose
            onClose={goBack}
            withBackground={false}
          />
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
});

export default observer(Workout);
