import Slider from '@react-native-community/slider';
import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  IndexPath,
  Layout,
  Select,
  SelectGroup,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { Audio } from 'expo-av';
import {
  INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  INTERRUPTION_MODE_IOS_DO_NOT_MIX,
} from 'expo-av/build/Audio';
import { sortBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { THRIVE_MEDIA_FILE_TYPE } from 'o2x-store/src/models/ThriveMediaFile';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import { THRIVE_ACTIVITY_TYPE } from 'o2x-store/src/utils/thrive';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  AppState,
  AppStateStatus,
  Platform,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import Sleep from '../../assets/images/sleep.svg';
import NavigationBar from '../../components/NavigationBar';
import ThriveStartTimer from '../../components/ThriveStart/ThriveStartTimer';
import { useNativeStore } from '../../stores';
import { AppStackParamList } from '../AppContainer';
import { THRIVE_ACTIVITY } from '../ThriveSetTime';

type Props = {
  route: RouteProp<AppStackParamList, 'ThriveApp'>;
};

const ThriveSleep: React.FC<Props> = (props) => {
  const { params } = props.route;
  const { initialDuration } = params || {};
  const { thrive } = useStore();
  const { thriveStart, mediaStorage } = useNativeStore();
  thriveStart.setActivityType(THRIVE_ACTIVITY_TYPE.SLEEP);
  const navigation = useNavigation();
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  const files = Array.from(thrive.thriveMediaFiles.values());
  const audios = [
    {
      title: 'Guided Audio',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.AUDIO && f.inSleep && f.isGuided),
        (a) => a.name,
      ),
    },
    {
      title: 'Audio',
      data: sortBy(
        files.filter((f) => f.type === THRIVE_MEDIA_FILE_TYPE.AUDIO && f.inSleep && !f.isGuided),
        (a) => a.name,
      ),
    },
  ].filter((s) => s.data.length > 0);

  const [volume, setVolume] = useState(1);
  const [selected, setSelected] = useState(new IndexPath(0, 0));
  const [allowed, setAllowed] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const appState = useRef(AppState.currentState);

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useEffect(() => {
    analytics.logThriveActivityOpen(THRIVE_ACTIVITY_TYPE.SLEEP);
  }, []);

  const goBack = useCallback(() => {
    if (thriveStart.playing) {
      if (Platform.OS === 'web') {
        const doEnd = confirm('Are you sure you want to go back?');
        if (doEnd) {
          thriveStart.stop();
          navigation.goBack();
        }
      } else {
        Alert.alert('Are you sure you want to go back?', undefined, [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Go Back',
            onPress: () => {
              thriveStart.stop();
              navigation.goBack();
            },
          },
        ]);
      }
    } else {
      navigation.goBack();
    }
  }, [thriveStart.playing]);

  const onSelect = useCallback(
    (index) => {
      setSelected(index);
      thriveStart.setAudio(audios[index.section!]?.data[index.row]);
    },
    [thriveStart],
  );

  const onChangeVolume = useCallback((volume) => {
    setVolume(volume);
    thriveStart.setVolume(volume);
  }, []);
  useEffect(() => {
    if (audios.length > 1) {
      const defaultAudio = initialDuration ? audios[1].data[0] : audios[0].data[0];
      const mediaFile = mediaStorage.mediaFiles.get(mediaStorage._getKey(defaultAudio));
      if (mediaFile && mediaFile?.downloaded) {
        setSelected(initialDuration ? new IndexPath(0, 1) : new IndexPath(0, 0));
        thriveStart.setAudio(defaultAudio);
      } else {
        thriveStart.setAudio(null);
        setSelected(undefined);
      }
    }
  }, []);

  useFocusEffect(
    useCallback(() => {
      Audio.setAudioModeAsync({
        playsInSilentModeIOS: true,
        allowsRecordingIOS: false,
        staysActiveInBackground: true,
        interruptionModeIOS: INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        shouldDuckAndroid: true,
        interruptionModeAndroid: INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false,
      });
    }, [allowed]),
  );

  useEffect(() => {
    AppState.addEventListener('change', _handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', _handleAppStateChange);
    };
  }, []);

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      // foreground
      thriveStart.updateCountDownFromStart();
    }
    appState.current = nextAppState;
  };

  const onTimerPlay = useCallback(() => {
    setStartTime((prevStartTime) => prevStartTime || moment().toDate());
  }, [setStartTime]);

  useFocusEffect(
    useCallback(() => {
      const logActivity = () => {
        if (startTime !== null) {
          thrive.logThriveActivity(THRIVE_ACTIVITY_TYPE.SLEEP, startTime, moment().toDate());
        }
      };
      return logActivity;
    }, [startTime]),
  );

  useEffect(() => {
    /* Only needed if recording audio
    (async () => {
      let permission: PermissionResponse = await Audio.getPermissionsAsync();
      if (
        permission.status !== Audio.PermissionStatus.GRANTED &&
        permission.canAskAgain
      ) {
        permission = await Audio.requestPermissionsAsync();
      }
      if (permission.status === Audio.PermissionStatus.GRANTED) {
        setAllowed(true);
      }
    })();
    */
    thrive.fetchThriveMediaFiles();
    thriveStart.setVolume(1);
    if (!initialDuration) {
      thriveStart.setDefaultTime(15 * 60); // 15 minute.
    } else if (initialDuration !== 'infinity') {
      thriveStart.setDefaultTime(initialDuration);
    } else if (initialDuration === 'infinity') {
      thriveStart.setDefaultTime(Infinity);
    }

    thriveStart.setVideo(null);
  }, [initialDuration]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <Layout style={{ paddingTop: insets.top }}>
              <NavigationBar onClose={goBack} showBack={false} showClose withBackground={false} />
            </Layout>
            <Layout style={isDeviceMaxWidth600 ? styles.contentMaxWidth600 : styles.content}>
              <Layout style={styles.headerContainer}>
                <Sleep style={styles.icon} />
                <Text style={styles.header} category="h2">
                  Sleep
                </Text>
              </Layout>
              <Text style={styles.label} category="c2">
                Sound
              </Text>
              <Select
                placeholder="Select an option"
                style={styles.select}
                value={thriveStart.audioObject?.name}
                selectedIndex={selected}
                onSelect={onSelect}>
                {audios.map((s) => (
                  <SelectGroup key={s.title} title={s.title}>
                    {s.data.map((f) => (
                      <SelectItem key={`${f.id}`} title={f.name} />
                    ))}
                  </SelectGroup>
                ))}
              </Select>
              <Text style={styles.label} category="c2">
                Volume
              </Text>
              <Slider
                style={styles.slider}
                minimumValue={0}
                minimumTrackTintColor={theme.orange}
                maximumValue={1}
                maximumTrackTintColor={theme.gray}
                thumbTintColor={theme.white}
                value={volume}
                onSlidingComplete={onChangeVolume}
              />
            </Layout>
            <ThriveStartTimer
              activity={THRIVE_ACTIVITY.SLEEP}
              addTime={15 * 60}
              onTimerPlay={onTimerPlay}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: '25%',
    paddingBottom: 24,
  },
  contentMaxWidth600: {
    flex: 1,
    marginTop: -20,
    paddingHorizontal: '10%',
    justifyContent: 'flex-start',
  },
  icon: {
    marginRight: 16,
  },
  header: {},
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
  },
  label: {
    marginBottom: 8,
    textTransform: 'uppercase',
  },
  select: {
    marginBottom: 24,
  },
  slider: {},
});

export default observer(ThriveSleep);
