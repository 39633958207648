import { RouteProp, useFocusEffect } from '@react-navigation/native';
import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as analytics from 'o2x-store/src/services/analytics';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import NavigationBar from '../../components/NavigationBar';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'EatStart'>;
};

const EatStart: React.FC<Props> = (props) => {
  const { id } = props.route.params;
  const { eat } = useStore();
  const recipe = eat.recipes.get(`${id}`);
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();

  if (!recipe) {
    return null;
  }

  useEffect(() => {
    if (recipe) analytics.logProgramStart(recipe);
  }, [recipe]);

  useFocusEffect(
    useCallback(() => {
      eat.fetchRecipesByIds([`${id}`]);
    }, [id, eat]),
  );

  const { color } = recipe;

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar />
      </Layout>
      <ScrollView style={styles.scroll}>
        <Layout style={styles.content}>
          <Text category="h2">{recipe.name}</Text>
          <Text style={[styles.subtitle, { color }]} category="c2">
            {recipe.subtitle}
          </Text>
          <Text style={styles.subheader}>Ingredients</Text>
          {/* HTMLView currently not supported in web */}
          {/* <HTMLView
            value={recipe.ingredients}
            textComponentProps={{ style: styles.text }}
          />
          <Text style={styles.subheader}>Directions</Text>
          <HTMLView
            value={recipe.directions}
            textComponentProps={{ style: styles.text }}
          /> */}
        </Layout>
      </ScrollView>
    </Layout>
  );
};

const htmlStyles = StyleService.create({
  li: {
    fontSize: 16,
    color: 'white',
  },
  p: {
    fontSize: 16,
    color: 'white',
  },
});

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  scroll: {
    flex: 1,
  },
  subtitle: {
    marginTop: 4,
    marginBottom: 8,
  },
  subheader: {
    fontFamily: 'Lato-Bold',
    marginVertical: 20,
  },
  text: {
    color: 'white',
    fontSize: 16,
  },
});

export default EatStart;
