import { CheckBox, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { FTEOrganizationModel } from 'o2x-store/src/models/FTELibrary';
import { RootStore } from 'o2x-store/src/stores';
import React from 'react';
import { Image, ImageStyle, ScrollView, StyleProp } from 'react-native';

type Props = {
  fteOrganizations?: FTEOrganizationModel[];
  query: string;
  onCheckOrg: Function;
  onCheckUser: Function;
  addedUsers?: number[];
  store: RootStore;
};

const OrgUserList: React.FC<Props> = (props) => {
  const { fteOrganizations, query, onCheckOrg, onCheckUser, addedUsers, store } = props;
  const styles = useStyleSheet(themedStyles);

  return (
    <ScrollView>
      {fteOrganizations
        ?.filter((org) =>
          query.trim().length > 0
            ? org.users.length > 0 &&
              org.users.filter(
                (user) =>
                  (user.id !== store?.auth.user?.id &&
                    (user.email.toLowerCase().includes(query.trim().toLowerCase()) ||
                      user.fullName.toLowerCase().includes(query.trim().toLowerCase()) ||
                      user.o2XId.includes(query.trim()))) ||
                  user.promoCode?.toLowerCase().includes(query.trim().toLowerCase()) ||
                  store.fteLibrary.ossGroups
                    .filter(
                      (group) =>
                        group.groupName !== 'All' && group.groupName.includes(query.trim()),
                    )
                    .some((group) => group.users.includes(user.id)),
              ).length > 0
            : org.users.length > 0,
        )
        .map((org, index) => (
          <Layout style={styles.invContainer} key={index}>
            <Layout style={styles.orgContainer}>
              <Image
                style={styles.profile as StyleProp<ImageStyle>}
                source={
                  org.logo ? { uri: org.logo } : require('../../assets/images/user_placeholder.png')
                }
              />
              <Text style={styles.orgText}>{org.name}</Text>
              <CheckBox
                style={styles.addInvite}
                onChange={(checked) => onCheckOrg(checked, org.users)}
                checked={org.users
                  .filter((user) => user.id !== store?.auth.user?.id)
                  .map((user) => user.id)
                  .every((item) => addedUsers?.includes(item))}
              />
            </Layout>
            <ScrollView style={styles.userContainer}>
              {org.users
                .filter((user) =>
                  query.trim().length > 0
                    ? (user.id !== store?.auth.user?.id &&
                        (user.email.toLowerCase().includes(query.trim().toLowerCase()) ||
                          user.fullName.toLowerCase().includes(query.trim().toLowerCase()) ||
                          user.o2XId.includes(query.trim()))) ||
                      user.promoCode?.toLowerCase().includes(query.trim().toLowerCase()) ||
                      store.fteLibrary.ossGroups
                        .filter(
                          (group) =>
                            group.groupName !== 'All' && group.groupName.includes(query.trim()),
                        )
                        .some((group) => group.users.includes(user.id))
                    : user.id !== store?.auth.user?.id,
                )
                .map((user, index) => (
                  <Layout style={styles.userContent} key={index}>
                    <Image
                      style={styles.userProfile as StyleProp<ImageStyle>}
                      source={
                        user.profileImage
                          ? { uri: user.profileImage }
                          : require('../../assets/images/user_placeholder.png')
                      }
                    />
                    <Text style={styles.orgText}>{user.fullName ? user.fullName : user.email}</Text>
                    <CheckBox
                      style={styles.addInvite}
                      onChange={(checked) => onCheckUser(checked, user)}
                      checked={addedUsers?.includes(user.id)}
                    />
                  </Layout>
                ))}
            </ScrollView>
          </Layout>
        ))}
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bgContainer: {
    position: 'absolute',
    width: '988px',
    height: '430px',
    left: '26px',
    marginTop: 10,
    backgroundColor: '#1A3248',
    flexDirection: 'row',
  },
  inputContainer: {
    width: '40%',
    height: '100%',
    backgroundColor: '#1A3248',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '60%',
    height: '100%',
    backgroundColor: '#203950',
    flexDirection: 'column',
  },
  headerText: {
    marginLeft: '32px',
    marginTop: '28px',
    color: 'white',
  },
  eventList: {
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '32px',
    backgroundColor: '#10283E',
    width: '530px',
  },
  dateInput: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '85%',
    color: 'white',
  },
  firstInput: {
    marginTop: '7.5%',
    marginLeft: '7.5%',
    width: '85%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  input: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '85%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  radioInput: {
    marginTop: '5px',
    marginLeft: '7.5%',
    width: '30%',
    // backgroundColor: '#0B1F35',
    color: 'white',
  },
  guestInput: {
    width: '48%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  timeContainer: {
    flexDirection: 'row',
    marginLeft: '7.5%',
    width: '85%',
    marginTop: '5px',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  timeInput: {
    width: '48%',
    color: 'white',
    fontSize: 14,
  },
  icon: {
    height: 20,
    width: 16,
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
    borderRadius: 5,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    marginLeft: '7.5%',
    marginTop: '7.5px',
    backgroundColor: 'transparent',
  },
  cancelButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'olive',
    height: 40,
    width: '48%',
    borderRadius: 5,
  },
  editButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 40,
    width: '48%',
    marginLeft: '4%',
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveText: {
    color: 'white',
    fontSize: 14,
    textTransform: 'uppercase',
    margin: 'auto',
  },
  errorText: {
    color: 'white',
    fontSize: 10,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  savedIcon: {
    width: 30,
    height: 30,
    marginBottom: 5,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '988px',
    height: '430px',
    backgroundColor: 'transparent',
  },
  popUpContainer: {
    backgroundColor: 'dark-blue',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    shadowColor: 'black',
    shadowOffset: { width: -1, height: 1 },
    shadowRadius: 10,
    shadowOpacity: 0.5,
  },
  buttons: {
    flexDirection: 'row',
  },
  button: {
    width: 80,
    height: 30,
    margin: 10,
    marginTop: 20,
    borderWidth: 2,
    borderColor: 'rgba(255,255,255,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteText: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: 500,
    height: 500,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  invContainer: {
    marginLeft: '5%',
    width: '90%',
    backgroundColor: 'transparent',
  },
  orgContainer: {
    width: '100%',
    height: 50,
    backgroundColor: '#203950',
    flexDirection: 'row',
  },
  profile: {
    width: 40,
    height: 40,
    alignSelf: 'center',
    borderRadius: 20,
    marginHorizontal: 10,
  },
  orgText: {
    opacity: 0.6,
    fontSize: 14,
    alignSelf: 'center',
  },
  userContainer: {
    width: '100%',
    marginBottom: 15,
    backgroundColor: '#091C2D',
    maxHeight: 200,
  },
  userContent: {
    width: '100%',
    borderBottom: '1px solid  #1A3248',
    height: 40,
    flexDirection: 'row',
  },
  userProfile: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    borderRadius: 15,
    marginLeft: 60,
    marginRight: 10,
  },
  searchInput: {
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    marginBottom: 10,
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'gray',
  },
  okButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 30,
    width: '20%',
    marginLeft: 'auto',
    marginRight: '5%',
    marginTop: 'auto',
    marginBottom: 10,
  },
  addInvite: {
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 15,
  },
  selectorIcon: {
    height: 30,
    width: 30,
    marginHorizontal: 5,
    alignSelf: 'center',
  },
  selector: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    margin: 'auto',
    marginTop: '20px',
  },
  selectorText: {
    alignSelf: 'center',
    color: 'white',
  },
});

export default React.memo(OrgUserList, (prevProps, nextProps) => {
  if (
    prevProps.addedUsers !== nextProps.addedUsers ||
    prevProps.query !== nextProps.query ||
    prevProps.query.length !== nextProps.query.length
  ) {
    return false;
  }
  return true;
});
