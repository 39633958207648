import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { StyleProp, Text, TouchableOpacity, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface Props {
  onBack?: () => void;
  onNext?: () => void;
  backLabel?: string;
  nextLabel?: string;
  showBack?: boolean;
  showNext?: boolean;
  nextColor?: string;
  nextStyle?: StyleProp<ViewStyle>;
  backColor?: string;
  nextAccessory?: () => JSX.Element;
  showNextAccessory?: boolean;
}

const BottomNav: React.FC<Props> = (props) => {
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const {
    backLabel = 'Back',
    nextLabel = 'Next',
    showBack = true,
    showNext = true,
    onNext = () => {},
    nextColor = theme.olive,
    nextStyle,
    backColor = theme['dark-blue'],
    nextAccessory,
    showNextAccessory = true,
  } = props;

  const onBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <Layout style={[styles.bottomNav, { paddingBottom: insets.bottom }]}>
      {showBack && (
        <TouchableOpacity
          style={[styles.button, { backgroundColor: backColor }]}
          onPress={props.onBack || onBack}>
          <Text style={styles.buttonText}>{backLabel}</Text>
        </TouchableOpacity>
      )}
      {showNext && (
        <TouchableOpacity
          style={[styles.nextButton, { backgroundColor: nextColor }, nextStyle]}
          onPress={onNext}>
          {showNextAccessory && nextAccessory && nextAccessory()}
          <Text style={styles.buttonText}>{nextLabel}</Text>
        </TouchableOpacity>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  bottomNav: {
    flexDirection: 'row',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: 'dark-blue',
    flex: 1,
    height: 48,
  },
  nextButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    flex: 1,
    height: 48,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export default BottomNav;
