import { useNavigation } from '@react-navigation/native';
import { get } from 'lodash';
import { useStore } from 'o2x-store/src/stores';
import { useCallback, useEffect, useState } from 'react';

// !!!: ⛔️ Parent component should be an `observer` ⛔️
// Not sure if we need to store the value in a useState.
// Or if we can just compute for isSubscribed.
export const useIsSubscribed = () => {
  const navigation = useNavigation();
  const { auth, users } = useStore();
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(
    !!auth.user && auth.user.isSubscribed,
  );

  const navigateWithPayWall = useCallback(
    async (route: string, params?: { [key: string]: any }) => {
      if (isSubscribed) {
        navigation.navigate(route, params);
      } else {
        // TODO: improve workflow here.
        // The problem with the current workflow is that it blocks the navigation
        // and there's no feedback on the user interface while we're fetching data
        setLoading(true);
        const result = await users.fetchMe();
        setLoading(false);
        if (get(result, 'extra.user.isSubscribed', false)) {
          navigation.navigate(route, params);
        } else {
          navigation.navigate('PayWall', { route, params });
        }
      }
    },
    [isSubscribed],
  );

  useEffect(() => {
    setIsSubscribed(!!auth.user && auth.user.isSubscribed);
  }, [!!auth.user && auth.user.isSubscribed]);

  return {
    loading,
    isSubscribed,
    navigateWithPayWall,
  };
};
