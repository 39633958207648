import { TASK_TYPE } from './tasks';

export const getActivityColor = (type: TASK_TYPE | 'general'): string => {
  switch (type) {
    case TASK_TYPE.EAT:
      return '#91C300';
    case TASK_TYPE.SWEAT:
      return '#04B7D6';
    case TASK_TYPE.THRIVE:
      return '#FF7A00';
    default:
      return '#4E7B89';
  }
};
