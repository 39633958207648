import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { get } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserThriveProfile } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from '../../components/Question/BottomNav';
import Input from '../../components/Question/Input';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route?: any | null;
};

const FindThrivePlanQuestion: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();

  const [index, setIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const question = store.question.findThrivePlanQuestions.has(`${index}`)
    ? store.question.findThrivePlanQuestions.get(`${index}`)
    : null;
  const numBars = store.question.findThrivePlanQuestions.size;

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const [
    thriveProfile,
    setThriveProfile,
  ] = useState<UserThriveProfile | null>();
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoading(true);
        await store.question.fetchFindThrivePlanQuestions();
        await store.user.fetchThriveProfile();
        setLoading(false);
      })();
    }, []),
  );

  useEffect(() => {
    const profile = store.user.thriveProfile;
    if (profile) {
      setThriveProfile(profile);
    } else {
      setThriveProfile(new UserThriveProfile({}));
    }
  }, [store.user.thriveProfile, loading]);

  const validateAnswer = useCallback(
    (question) => {
      // Check if has answer because field is required by default
      const isRequired =
        question && question.required !== undefined ? question.required : true;
      console.log(get(thriveProfile, question.key));
      return (
        (isRequired &&
          question &&
          thriveProfile &&
          get(thriveProfile, question.key) !== null &&
          get(thriveProfile, question.key) !== '' &&
          get(thriveProfile, question.key) !== undefined) ||
        !isRequired
      );
    },
    [question, thriveProfile],
  );

  const onBack = useCallback(() => {
    const newIndex = index - 1;
    const id = String(newIndex);
    if (store.question.findThrivePlanQuestions.has(id)) {
      setIndex(newIndex);
    } else {
      navigation.goBack();
    }
  }, [index, setIndex, question, store]);

  const onNext = useCallback(async () => {
    const newIndex = index + 1;
    const id = String(newIndex);
    if (store.question.findThrivePlanQuestions.has(id)) {
      if (validateAnswer(question)) {
        setIndex(newIndex);
      }
    } else {
      const lastQuestion = store.question.findThrivePlanQuestions.get(
        `${index}`,
      );
      if (!validateAnswer(lastQuestion)) {
        return;
      }
      const updateResult = await store.user.updateThriveProfile({
        ...getSnapshot(thriveProfile),
        id: thriveProfile?.id,
      });
      if (updateResult.ok) {
        navigation.navigate('FindThrivePlanSummary');
      } else {
        setErrorMessage(updateResult.errors.detail);
      }
    }
  }, [index, setIndex, question, thriveProfile]);

  const onChange = useCallback(
    (key: string, value: any) => {
      thriveProfile?.set(key, value);
    },
    [setThriveProfile, thriveProfile],
  );

  const goBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}
        >
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose={true} onClose={goBack} />
            </View>
            <View
              style={
                isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }
            >
              <Text style={styles.title}>Find a THRIVE Plan</Text>
              {!!errorMessage && (
                <Text style={styles.error} category="label">
                  {errorMessage}
                </Text>
              )}
              {loading ? (
                <View style={styles.loader}>
                  <Spinner />
                </View>
              ) : (
                <View style={styles.questionContainer}>
                  <Text style={styles.description}>{question?.question}</Text>
                  {question && (
                    <Input
                      key={question.key}
                      question={question}
                      onChange={onChange}
                      initial={get(thriveProfile, question.key) || ''}
                      status="basic-thrive"
                      fill={theme['orange']}
                    />
                  )}
                </View>
              )}
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={index} />
              <BottomNav
                onBack={onBack}
                onNext={onNext}
                nextColor={theme['olive']}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
    marginTop: -20,
    paddingHorizontal: '25%',
    justifyContent: 'flex-start',
  },
  contentContainerMaxWidth600: {
    flex: 1,
    marginTop: -20,
    paddingHorizontal: '10%',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 700,
    marginBottom: 24,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    color: 'white',
    lineHeight: 22,
    marginBottom: 12,
    marginHorizontal: 20,
  },
  topNav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  navIcon: {
    width: 30,
    height: 30,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  error: {
    color: 'danger',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  questionContainer: {
    flex: 1,
    marginBottom: 10,
  },
});

export default observer(FindThrivePlanQuestion);
