import snakeCaseKeys from 'snakecase-keys';
import config from '../../config';
import { callApiWithToken } from './base';

export const fetchFormAssessments = async (token: string) =>
  callApiWithToken(config.urls.formAssessments, token, 'GET');

export const fetchFormAssessment = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.formAssessments}${id}/`, token, 'GET');

export const fetchFormAssessmentByIds = async (token: string, ids: string) =>
  callApiWithToken(
    `${config.urls.formAssessments}?id__in=${ids}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const startFormAssessment = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.formAssessments}${assessmentId}/start/`, token, 'POST');

export const fetchFormAssessmentItems = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.formAssessments}${assessmentId}/items/`, token, 'GET');

export const answerFormAssessmentItem = async (
  token: string,
  assessmentId: number,
  assessmentItemId: number,
  formAssessmentSubmissionId: number,
  value: string,
) =>
  callApiWithToken(
    `${config.urls.formAssessments}${assessmentId}/items/${assessmentItemId}/answer/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        value,
        userAssessment: formAssessmentSubmissionId,
      }),
    ),
  );

export const fetchFormAssessmentSubmission = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.formAssessmentSubmissions}${id}/`, token, 'GET');

export const fetchFormAssessmentSubmissionResult = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.formAssessmentSubmissions}${id}/results/`, token, 'GET');

export const fetchFormAssessmentSubmissions = async (token: string, assessmentId?: number) =>
  callApiWithToken(
    `${config.urls.formAssessmentSubmissions}?assessment_id=${assessmentId || ''}`,
    token,
    'GET',
  );

export const deleteFormAssessmentSubmission = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.formAssessmentSubmissions}${assessmentId}/`, token, 'DELETE');

export const fetchPhysicalAssessments = async (token: string) =>
  callApiWithToken(config.urls.physicalAssessments, token, 'GET');

export const fetchPhysicalAssessment = async (token: string, id: number, clearCache: boolean) =>
  callApiWithToken(
    `${config.urls.physicalAssessments}${id}/`,
    token,
    'GET',
    {},
    undefined,
    clearCache,
  );

export const fetchPhysicalAssessmentByIds = async (token: string, ids: string) =>
  callApiWithToken(
    `${config.urls.physicalAssessments}?id__in=${ids}`,
    token,
    'GET',
    {},
    undefined,
    true,
  );

export const startPhysicalAssessment = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.physicalAssessments}${assessmentId}/start/`, token, 'POST');

export const fetchPhysicalAssessmentScoringDisplay = async (token: string, id: number) =>
  callApiWithToken(
    `${config.urls.physicalAssessments}${id}/scoring_display/`,
    token,
    'GET',
    {},
    undefined,
  );

export const fetchPhysicalAssessmentItems = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.physicalAssessments}${assessmentId}/items/`, token, 'GET');

export const answerPhysicalAssessmentItem = async (
  token: string,
  assessmentId: number,
  assessmentItemId: number,
  physicalAssessmentSubmissionId: number,
  value: string,
) =>
  callApiWithToken(
    `${config.urls.physicalAssessments}${assessmentId}/items/${assessmentItemId}/answer/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(
      snakeCaseKeys({
        value,
        userAssessment: physicalAssessmentSubmissionId,
      }),
    ),
  );

export const fetchPhysicalAssessmentSubmission = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.physicalAssessmentSubmissions}${id}/`, token, 'GET');

export const fetchPhysicalAssessmentSubmissions = async (token: string) =>
  callApiWithToken(`${config.urls.physicalAssessmentSubmissions}`, token, 'GET');

export const fetchBodyComposition = async (token: string) =>
  callApiWithToken(`${config.urls.bodyComposition}`, token, 'GET');

export const fetchBodyCompositionHistory = async (token: string) =>
  callApiWithToken(`${config.urls.bodyComposition}history`, token, 'GET');

export const fetchBodyCompositionSummary = async (token: string) =>
  callApiWithToken(`${config.urls.bodyComposition}summary`, token, 'GET');

export const deletePhysicalAssessmentSubmission = async (token: string, assessmentId: number) =>
  callApiWithToken(`${config.urls.physicalAssessmentSubmissions}${assessmentId}/`, token, 'DELETE');
