import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import React, { useCallback } from 'react';
import {
  Dimensions,
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import Logo from '../../assets/images/logo.svg';
import Wallpaper from '../../assets/images/wallpaper-default.svg';
import config from '../../config';

type Props = {};

const Landing: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  let version = config.buildVersion;
  if (config.debug) {
    version = `${version}\n${config.urls.api}\n${Application.applicationId}\n${
      Constants.manifest?.releaseChannel || Updates.manifest?.releaseChannel || ''
    }`;
  }

  const onSignUp = useCallback(() => {
    navigation.navigate('SignUpCode');
  }, []);

  const onSignIn = useCallback(() => {
    navigation.navigate('SignIn');
  }, []);

  return (
    <Layout style={styles.container}>
      <Wallpaper style={styles.wallpaper} />
      <Image
        style={styles.coverPhoto as StyleProp<ImageStyle>}
        source={require('../../assets/images/cover-photo-landing.png')}
      />
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scrollContainer}>
        <Layout style={styles.content}>
          <Logo style={styles.logo} />
          <Text style={styles.logoText} category="label">
            Tactical Performance
          </Text>
          <Text>New user?</Text>
          <TouchableOpacity onPress={onSignUp} style={[styles.button, styles.buttonSignUp]}>
            <Text style={styles.buttonLabel} category="label">
              Sign up
            </Text>
          </TouchableOpacity>
          <Text>Existing user?</Text>
          <TouchableOpacity onPress={onSignIn} style={[styles.button, styles.buttonSignIn]}>
            <Text style={styles.buttonLabel} category="label">
              Sign in
            </Text>
          </TouchableOpacity>
          <Text style={styles.version} category="c2">
            {version}
          </Text>
        </Layout>
      </ScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  buttonLabel: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  button: {
    justifyContent: 'center',
    height: 40,
    marginBottom: 30,
    marginTop: 7,
  },
  buttonSignUp: {
    backgroundColor: 'olive',
  },
  buttonSignIn: {
    backgroundColor: 'dark-blue',
    marginBottom: 16,
  },
  buttonBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    backgroundColor: '#000000',
    flex: 1,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent',
    width: 260,
  },
  coverPhoto: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '45%',
    width: '100%',
  },
  logo: {
    alignSelf: 'center',
    marginTop: Dimensions.get('window').height * 0.4,
    marginBottom: 8,
  },
  logoText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: Dimensions.get('window').height * 0.04,
  },
  signUp: {
    backgroundColor: '#767F6A',
    textTransform: 'uppercase',
  },
  version: {
    color: 'gray',
  },
  wallpaper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  scroll: {
    width: '100%',
  },
  scrollContainer: {
    alignItems: 'center',
  },
});

export default Landing;
