import { Icon, Layout, Popover, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import UserSweatWorkoutProgress from 'o2x-store/src/models/UserSweatWorkoutProgress';
import React, { useState } from 'react';
import { ScrollView, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import {
  FTESweatWorkout,
  FTESweatWorkoutStepExercise,
} from '../../../../o2x-store/src/models/FTELibrary';
import Polygon from '../../../assets/images/polygon.svg';

type ExerciseProps = {
  exercise: FTESweatWorkoutStepExercise;
  onPressExercise: (exercise: FTESweatWorkoutStepExercise) => void;
  userNotes: string | undefined;
  day: FTESweatWorkout;
  workoutMap: Map<number, UserSweatWorkoutProgress> | undefined;
  exercisesWeightInputs: Map<number, string[]> | undefined;
};

const WorkoutStepExercise: React.FC<ExerciseProps> = ({
  exercise,
  onPressExercise,
  userNotes,
  day,
  workoutMap,
  exercisesWeightInputs,
}) => {
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const styles = useStyleSheet(themedStyles);

  const setParams = ['set', 'reps', 'weight', 'distance', 'time', 'rest', 'rpe'];

  const weights = () => {
    if (day.id && workoutMap && workoutMap.has(day.id)) {
      const exerciseProgress = workoutMap
        .get(day.id)
        ?.userSweatExerciseProgress.find((x) => x.workoutStepExercise == exercise.id);
      return exercisesWeightInputs?.get(exerciseProgress.id);
    }
  };
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          onPressExercise(exercise);
        }}>
        <Layout style={styles.exerciseContainer}>
          <Text style={[styles.textStyle, { fontSize: 16 }]}>
            {exercise.exercise ? exercise.exerciseName : 'Empty'}
          </Text>
          {exercise.instructions ? (
            <Layout style={[styles.message, { marginLeft: 'auto' }]}>
              <Popover
                visible={isMessageVisible}
                placement="bottom end"
                anchor={() => (
                  <TouchableOpacity onPress={() => setMessageVisible(!isMessageVisible)}>
                    <Layout style={styles.redCircle} />
                    <Icon name="message-square-outline" fill="white" style={styles.messageIcon} />
                  </TouchableOpacity>
                )}
                style={styles.popover as StyleProp<ViewStyle>}
                onBackdropPress={() => setMessageVisible(false)}>
                <Layout style={styles.messageDropDown}>
                  <Layout style={styles.polygon}>
                    <Polygon />
                  </Layout>
                  <Layout style={styles.dropDownContent}>
                    <Text style={styles.messageText}>{exercise.instructions}</Text>
                  </Layout>
                </Layout>
              </Popover>
            </Layout>
          ) : (
            <Layout style={[styles.message, { marginLeft: 'auto' }]}>
              <Icon name="message-square-outline" fill="white" style={styles.messageIcon} />
            </Layout>
          )}
          <TouchableOpacity
            style={{
              marginRight: 10,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            onPress={() => setShowDetails(!showDetails)}>
            <Icon
              name={showDetails ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}
              style={{ width: 20, height: 20 }}
              fill="white"
            />
          </TouchableOpacity>
        </Layout>
      </TouchableOpacity>

      {!!showDetails && (
        <Layout
          style={{
            width: '95%',
            marginLeft: 'auto',
            flexDirection: 'row',
          }}>
          <Layout style={{ width: '75%' }}>
            <Layout
              style={{
                width: '100%',
                height: 30,
                flexDirection: 'row',
              }}>
              {[...setParams, 'Weight used'].map((param) => (
                <Layout
                  style={{
                    width: '12.5%',
                    borderColor: '#1A3248',
                    borderWidth: 1,
                  }}>
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: 12,
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}>
                    {param}
                  </Text>
                </Layout>
              ))}
            </Layout>
            {exercise.exerciseSets.map((set, index) => (
              <Layout
                style={{
                  width: '100%',
                  height: 30,
                  backgroundColor: 'white',
                  flexDirection: 'row',
                }}>
                {setParams.map((param) => (
                  <Layout
                    style={{
                      width: '12.5%',
                      borderColor: '#1A3248',
                      borderWidth: 1,
                      backgroundColor: '#091C2D',
                    }}>
                    <Text
                      style={{
                        alignSelf: 'center',
                        fontSize: 12,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}>
                      {param === 'set' ? index + 1 : set[param]}
                    </Text>
                  </Layout>
                ))}
                <Layout
                  style={{
                    width: '12.5%',
                    borderColor: '#1A3248',
                    borderWidth: 1,
                    backgroundColor: '#091C2D',
                  }}>
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontSize: 12,
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}>
                    {weights() && weights()?.find((weight) => weight.exerciseSet === set.id)
                      ? weights()?.find((weight) => weight.exerciseSet === set.id)?.weight
                      : ''}
                  </Text>
                </Layout>
              </Layout>
            ))}
          </Layout>
          <Layout
            style={{
              width: '25%',
              maxHeight: 150,
              borderColor: '#1A3248',
              borderWidth: 1,
              backgroundColor: '#091C2D',
            }}>
            <Layout
              style={{
                width: '100%',
                height: 30,
                borderColor: '#1A3248',
                borderWidth: 1,
              }}>
              <Text
                style={{
                  alignSelf: 'center',
                  fontSize: 12,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}>
                User Notes
              </Text>
            </Layout>
            <ScrollView>
              <Text style={{ padding: 10, fontSize: 14 }}>{userNotes}</Text>
            </ScrollView>
          </Layout>
        </Layout>
      )}
      {/* <Layout style={styles.setsContainer}>
        {exercise.exerciseSets.map((set, index) => (
          <Layout style={styles.setContainer} key={index}>
            <Text style={[styles.textStyle, { fontSize: 14 }]}>
              {'Set ' + (index + 1) + ': '}
              {set.reps ? set.reps + ' reps  ' : ''}
              {set.weight ? set.weight + ' lbs  ' : ''}
              {set.rpe ? set.rpe + ' %  ' : ''}
              {set.rest ? set.rest + ' rest  ' : ''}
            </Text>
          </Layout>
        ))}
        {userNotes ? (
          <Layout style={styles.userNotes}>
            <Text style={[styles.textStyle, { fontSize: 16 }]}>
              {userNotes}
            </Text>
          </Layout>
        ) : null}
      </Layout> */}
    </>
  );
};

const themedStyles = StyleService.create({
  setsContainer: {
    marginLeft: '80px',
  },
  setContainer: {
    marginTop: '10px',
  },
  userNotes: {
    marginTop: '20px',
  },
  exerciseContainer: {
    width: '95%',
    height: '40px',
    backgroundColor: '#203950',
    marginTop: 10,
    marginLeft: 'auto',
    flexDirection: 'row',
  },
  textStyle: {
    color: '#ffffff',
    lineHeight: 22,
    letterSpacing: 0.05,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  message: {
    // alignSelf: 'center',
    marginRight: '5px',
    marginTop: '5px',
    backgroundColor: '#203950',
  },
  messageIcon: {
    width: 20,
    height: 20,
  },
  redCircle: {
    width: 5,
    height: 5,
    marginLeft: '15px',
    marginBottom: 0,
    borderRadius: 44 / 2,
    backgroundColor: 'red',
    position: 'relative',
  },
  popover: {
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
  messageDropDown: {
    width: 240,
    backgroundColor: 'transparent',
    marginLeft: '20px',
  },
  polygon: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  dropDownContent: {
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 7,
    paddingHorizontal: 7,
    backgroundColor: '#203950',
  },
  messageText: {
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: '400',
  },
});

export default observer(WorkoutStepExercise);
