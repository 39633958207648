import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import DangerBadge from '../../assets/images/danger-badge.svg';
import GoodBadge from '../../assets/images/good-badge.svg';
import WarningBadge from '../../assets/images/warning-badge.svg';
import TopNav from '../../components/Question/TopNav';

type Props = {
  route?: any | null;
};

const SummaryDetail: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const {
    route: {
      params: { key, title, preText, value, label, levels },
    },
  } = props;
  return (
    <Layout style={styles.container}>
      <TopNav />
      <ScrollView style={styles.scrollContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.label}>{preText}</Text>
        <Text
          style={String(value).length > 12 ? styles.valueSmaller : styles.value}
        >
          {value}
        </Text>
        <Text style={styles.label}>{label}</Text>
        {levels && (
          <View style={styles.levelContainer}>
            {levels.good && (
              <View style={styles.levelItem}>
                <GoodBadge style={styles.levelIcon} />
                <View style={styles.levelContent}>
                  <Text style={styles.levelValue}>
                    {Humanize.capitalize(levels.good.value)}
                  </Text>
                  <Text style={styles.levelDescription}>
                    {levels.good.label}
                  </Text>
                </View>
              </View>
            )}
            {levels.okay && (
              <View style={styles.levelItem}>
                <WarningBadge style={styles.levelIcon} />
                <View style={styles.levelContent}>
                  <Text style={styles.levelValue}>
                    {Humanize.capitalize(levels.okay.value)}
                  </Text>
                  <Text style={styles.levelDescription}>
                    {levels.okay.label}
                  </Text>
                </View>
              </View>
            )}
            {levels.improve && (
              <View style={styles.levelItem}>
                <DangerBadge style={styles.levelIcon} />
                <View style={styles.levelContent}>
                  <Text style={styles.levelValue}>
                    {Humanize.capitalize(levels.improve.value)}
                  </Text>
                  <Text style={styles.levelDescription}>
                    {levels.improve.label}
                  </Text>
                </View>
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {},
  title: {
    color: 'white',
    fontSize: 24,
    marginBottom: 28,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 12,
    color: 'blue-secondary',
  },
  value: {
    fontSize: 36,
    lineHeight: 43,
    color: 'white',
    textTransform: 'uppercase',
  },
  valueSmaller: {
    fontSize: 24,
    lineHeight: 27,
    color: 'white',
    textTransform: 'uppercase',
  },
  levelContainer: {
    marginTop: 25,
  },
  levelIcon: {
    height: 20,
    width: 20,
  },
  levelItem: {
    flexDirection: 'row',
  },
  levelContent: {
    marginLeft: 15,
    marginBottom: 26,
  },
  levelValue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 13,
  },
  levelDescription: {
    fontSize: 16,
    color: 'white',
    maxWidth: '95%',
  },
});

export default observer(SummaryDetail);
