import Constants from 'expo-constants';
import { action, observable } from 'mobx';
import {
  applySnapshot,
  getSnapshot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  prop_mapObject,
  _async,
  _await,
} from 'mobx-keystone';
import { Platform } from 'react-native';
import config from '../config';
import { Program, RatingsBreakdown } from '../models/Program';
import User from '../models/User';
import * as api from '../services/api';
import { StorageService } from '../services/storage';
import { TASK_TYPE } from '../utils/tasks';
import AssessmentStore from './AssessmentStore';
import AuthStore from './AuthStore';
import ContactStore from './ContactStore';
import DashboardStore from './DashboardStore';
import EatStore from './EatStore';
import EventStore from './EventStore';
import FTEEventStore from './FTEEventStore';
import FTELibraryStore from './FTELibraryStore';
import OrganizationStore from './OrganizationStore';
import QuestionStore from './QuestionStore';
import ResourceStore from './ResourceStore';
import SavedListStore from './SavedListStore';
import SweatStore from './SweatStore';
import TagStore from './TagStore';
import TaskStore from './TaskStore';
import TeamStore from './TeamStore';
import ThriveStore from './ThriveStore';
import TutorialStore from './TutorialStore';
import UserQuestionStore from './UserQuestionStore';
import UserStore from './UserStore';
import ContactPortalStore from './ContactPortalStore';

let InAppPurchases: any;

@model('o2x-store/RootStore')
export default class RootStore extends Model({
  assessment: prop<AssessmentStore>(),
  auth: prop<AuthStore>(),
  eat: prop<EatStore>(),
  event: prop<EventStore>(),
  sweat: prop<SweatStore>(),
  thrive: prop<ThriveStore>(),
  tags: prop<TagStore>(),
  task: prop<TaskStore>(),
  users: prop<UserStore>(),
  question: prop<QuestionStore>(),
  user: prop<UserStore>(),
  savedList: prop<SavedListStore>(),
  tutorial: prop<TutorialStore>(),
  userQuestion: prop<UserQuestionStore>(),
  teams: prop<TeamStore>(),
  organization: prop<OrganizationStore>(),
  resources: prop<ResourceStore>(),
  dashboard: prop<DashboardStore>(),
  contact: prop<ContactStore>(),
  contactPortal: prop<ContactPortalStore>(),
  fteEvent: prop<FTEEventStore>(),
  fteLibrary: prop<FTELibraryStore>(),
  ratings: prop_mapObject(() => new Map<string, RatingsBreakdown>()),
}) {
  storageService!: StorageService;

  @observable
  loading = false;

  @observable
  purchasing = false;

  @observable
  fetchId = '';

  @action
  setPurchasing = (purchasing: boolean) => {
    this.purchasing = purchasing;
  };

  @modelFlow
  load = _async(function* (this: RootStore) {
    this.loading = true;

    let data: any | string | null;
    try {
      data = yield* _await(this.storageService.getItemAsync(config.storeKey));
    } catch (e) {
      console.warn('[DEBUG] fetching store failed', e);
      data = null;
    }
    if (data) {
      data = JSON.parse(data);

      try {
        applySnapshot(this, {
          ...getSnapshot(this),
          ...data,
          $modelId: this.$modelId,
        });
      } catch (error) {
        console.warn('[DEBUG] error while loading from stored data', error);
      }

      // Re-instantiate auth store.
      this.auth.storageService = this.storageService;
    }

    // Load auth store separately.
    yield* _await(this.auth.load());

    // Try to fetch me and force logout if token is invalid.
    const result = yield* _await(this.user.fetchMe());
    if (!result.ok) {
      console.warn('[DEBUG] failed to fetch me', result);
      if (result.errors.detail === 'Invalid token.') {
        yield* _await(this.auth.logOut());
      }
    }

    this.loading = false;
  });

  @modelFlow
  reset = _async(function* (this: RootStore) {
    yield* _await(this.auth.reset());
    yield* _await(this.storageService.removeItemAsync(config.storeKey));

    this.assessment = new AssessmentStore({});
    this.eat = new EatStore({});
    this.event = new EventStore({});
    this.sweat = new SweatStore({});
    this.thrive = new ThriveStore({});
    this.task = new TaskStore({});
    this.users = new UserStore({});
    this.question = new QuestionStore({});
    this.user = new UserStore({});
    this.savedList = new SavedListStore({});
    this.tutorial = new TutorialStore({});
    this.userQuestion = new UserQuestionStore({});
    this.teams = new TeamStore({});
    this.organization = new OrganizationStore({});
    this.resources = new ResourceStore({});
    this.dashboard = new DashboardStore({});
    this.contact = new ContactStore({});
    this.fteEvent = new FTEEventStore({});
    this.fteLibrary = new FTELibraryStore({});
    this.ratings = new Map();
  });

  save = () => {
    const data = { ...getSnapshot(this) };

    // Exclude auth token from storing in unsecured storage.
    if (Platform.OS === 'web') {
      delete (data as any).auth;
    } else {
      data.auth.token = '';
    }

    this.storageService.setItemAsync(config.storeKey, JSON.stringify(data));
  };

  getProgram = (type: TASK_TYPE, id: number): Program | undefined => {
    if (type === TASK_TYPE.EAT) {
      return this.eat.recipes.get(`${id}`);
    }
    if (type === TASK_TYPE.SWEAT) {
      return this.sweat.sweatPrograms.get(`${id}`);
    }
    if (type === TASK_TYPE.THRIVE) {
      return this.thrive.thrivePrograms.get(`${id}`);
    }
  };

  getProgramRatings = (type: TASK_TYPE, id: number): RatingsBreakdown | undefined =>
    this.ratings.get(`${type}/${id}`);

  @modelAction
  setProgramRatings = (type: TASK_TYPE, id: number, ratingsBreakdown: RatingsBreakdown) => {
    this.ratings.set(`${type}/${id}`, ratingsBreakdown);
  };

  // TODO: Consider moving to NativeStore.
  registerDevice = (
    deviceId: string,
    pushToken: string,
    expoPushToken: string,
    appVersion: string,
    deviceName?: string,
  ) => {
    (async () => {
      const { token } = this.auth;
      if (token) {
        try {
          await api.registerDevice(
            token,
            deviceId,
            deviceName || '',
            pushToken,
            expoPushToken,
            appVersion,
          );
        } catch (error) {
          console.warn('[DEBUG] failed to register device: ', error);
        }
      }
    })();
  };

  // TODO: Consider moving to NativeStore.
  purchaseProduct = (response: { responseCode: any; results?: any[] }) => {
    if (Constants.appOwnership === 'expo') return;
    console.log('[DEBUG] purchase listener', response);
    if (!InAppPurchases) {
      try {
        InAppPurchases = require('expo-in-app-purchases');
      } catch {
        console.warn(
          'Unable to load expo-in-app-purchases.  This is expected if running in Expo Go.',
        );
      }
    }
    this.setPurchasing(false);
    const { token } = this.auth;
    if (!token) {
      return;
    }

    if (response.responseCode === InAppPurchases.IAPResponseCode.OK) {
      response.results?.forEach(async (purchase) => {
        if (Constants.appOwnership === 'expo') return;
        if (!InAppPurchases) {
          try {
            InAppPurchases = require('expo-in-app-purchases');
          } catch {
            console.warn(
              'Unable to load expo-in-app-purchases.  This is expected if running in Expo Go.',
            );
          }
        }
        // Skip acknowledged purchases.
        if (purchase.acknowledged) {
          return;
        }

        if (purchase.purchaseState === InAppPurchases.InAppPurchaseState.PURCHASED) {
          this.setPurchasing(true);
          let entities: ModelInstanceCreationData<User> | undefined;
          if (Platform.OS === 'ios' && purchase.transactionReceipt) {
            try {
              ({
                response: { entities },
              } = await api.validatePurchaseIOS(token, purchase.transactionReceipt));
            } catch (error) {
              console.warn('[DEBUG] failed to validate purchase IOS: ', error);
              this.setPurchasing(false);
              return;
            }
          }
          if (Platform.OS === 'android' && purchase.purchaseToken) {
            try {
              ({
                response: { entities },
              } = await api.validatePurchaseAndroid(
                token,
                purchase.productId,
                purchase.purchaseToken,
              ));
            } catch (error) {
              console.warn('[DEBUG] failed to validate purchase Android: ', error);
              this.setPurchasing(false);
              return;
            }
          }

          if (entities) {
            if (this.auth.user) {
              this.auth.user.update(entities);
            } else {
              this.auth.setUser(new User(entities));
            }

            if (this.auth.user?.isSubscribed) {
              InAppPurchases.finishTransactionAsync(purchase, false);
            }
          }
          this.setPurchasing(false);
        }
      });
    }
  };
}
