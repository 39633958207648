import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { Program } from 'o2x-store/src/models/Program';
import SweatProgram from 'o2x-store/src/models/SweatProgram';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import React from 'react';
import { Image, ImageStyle, StyleProp, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import Handle from '../../assets/images/handle-web.svg';

export type Props = {
  program: SweatProgram | ThriveProgram | Program;
  showHandle?: boolean;
  key: string;
};

const SavedProgramItem: React.FC<Props> = (props) => {
  const { program, showHandle = false } = props;
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  return (
    <Layout
      style={[
        isMobile ? styles.containerMobile : styles.container,
        styles.borderStyle,
        !!program.color && {
          borderColor: program.color,
        },
      ]}
      level="2"
    >
      {!!program.image && (
        <Image
          style={styles.image as StyleProp<ImageStyle>}
          source={{ uri: program.image }}
        />
      )}
      <Layout
        style={isMobile ? styles.contentMobile : styles.content}
        level="2"
      >
        <View style={styles.titleContainer}>
          <Text numberOfLines={2}>{program.name || program.title}</Text>
        </View>
        {showHandle && <Handle fill="#FFFFFF" />}
      </Layout>
    </Layout>
  );
};

export const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    width: 410,
    marginTop: 12,
    justifyContent: 'space-between',
    height: 60,
  },
  containerMobile: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 12,
    justifyContent: 'space-between',
    height: 60,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 5,
    marginRight: 16,
    marginVertical: 8,
  },
  contentMobile: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginVertical: 5,
  },
  icon: {
    color: 'white',
    width: 24,
    height: 30,
  },
  label: {
    flexDirection: 'row',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    marginTop: 7,
  },
  image: {
    height: 60,
    width: 60,
  },
  titleContainer: {
    flex: 1,
    paddingRight: 5,
  },
  borderStyle: {
    borderLeftWidth: 4,
    borderStyle: 'solid',
    borderColor: 'blue-secondary',
  },
});

export default SavedProgramItem;
