import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get, isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlatList, ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import BottomNav from 'src/components/Question/BottomNav';
import Input from '../../components/Question/Input';
import TopNav from '../../components/Question/TopNav';
import UserDetail from '../../components/UserDetail';
import { getErrors } from '../../utils/errors';

type Props = {
  route: any;
};

const EventSpecialistSurvey: React.FC<Props> = (props) => {
  const { id, specialistId } = props.route.params;
  const store = useStore();
  const event = store.event.events.get(`${id}`);
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const { register, setValue, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const initialValues = {
    rating: 1,
    specialist: specialistId,
    specialistSkills: 1,
    specialistCommunication: 1,
    specialistEngagement: 1,
    location: '',
    specialistComments: '',
    sessionComments: '',
  };

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useFocusEffect(
    useCallback(() => {
      store.question.fetchEventSurveyQuestions(id);
    }, []),
  );

  useEffect(() => {
    register({ name: 'rating' }, { required: false });
    register({ name: 'specialistSkills' }, { required: false });
    register({ name: 'specialistCommunication' }, { required: false });
    register({ name: 'specialistEngagement' }, { required: false });
    register({ name: 'location' }, { required: true });
    register({ name: 'specialistComments' }, { required: false });
    register({ name: 'sessionComments' }, { required: false });
  }, [register]);

  const goToEvent = useCallback(() => {
    navigation.navigate('EventDetail', { id });
  }, [event]);

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const updateData = {
        ...data,
        rating: isUndefined(data.rating) ? initialValues.rating : data.rating,
        specialistSkills: isUndefined(data.specialistSkills)
          ? initialValues.specialistSkills
          : data.specialistSkills,
        specialistCommunication: isUndefined(data.specialistCommunication)
          ? initialValues.specialistCommunication
          : data.specialistCommunication,
        specialistEngagement: isUndefined(data.specialistEngagement)
          ? initialValues.specialistEngagement
          : data.specialistEngagement,
        event: id,
        specialist: specialistId,
      };
      const result = await store.event.createSurvey(id, updateData);
      setLoading(false);
      if (result.ok) {
        navigation.goBack();
      } else {
        setErrorMessage(getErrors(result.errors));
      }
    },
    [event],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const keyExtractor = useCallback((item) => `${item.key}`, []);

  const changeValues = useCallback((k: string, v: string | number) => {
    setValue(k, v);
  }, []);

  const renderItem = useCallback(
    ({ item }) =>
      item ? (
        <View style={styles.question}>
          <Text style={styles.questionLabel}>{item.question}</Text>
          <Input
            key={item.key}
            question={item}
            onChange={changeValues}
            initial={get(initialValues, item.key)}
            status="basic-plain"
            divider={false}
            subtitle={false}
          />
        </View>
      ) : null,
    [initialValues],
  );

  if (!event) {
    return null;
  }

  const questions = Array.from(store.question.eventSurveyQuestions.values());

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav onBack={goToEvent} showBack showClose={false} />
            </View>
            <Text style={styles.title}>O2X Human Performance Specialist Critique 2020</Text>
            <ScrollView style={styles.scroll}>
              <Layout
                style={
                  isMobile
                    ? styles.contentMobile
                    : isDeviceMaxWidth600
                    ? styles.contentMaxWidth600
                    : styles.content
                }>
                <View style={styles.padded}>
                  <UserDetail style={styles.author} id={specialistId} label="Host" />
                  {!!errorMessage && (
                    <Text style={styles.error} category="label">
                      {errorMessage}
                    </Text>
                  )}
                </View>
                <FlatList
                  data={questions}
                  keyExtractor={keyExtractor}
                  renderItem={renderItem}
                  style={styles.padded}
                />
              </Layout>
            </ScrollView>
            <BottomNav showBack={false} onNext={onSubmitWrapped} nextLabel="Submit" />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  content: {
    paddingBottom: 20,
    paddingHorizontal: '25%',
  },
  contentMobile: {
    paddingBottom: 20,
    paddingHorizontal: '5%',
  },
  contentMaxWidth600: {
    paddingBottom: 20,
    paddingHorizontal: '15%',
  },
  scroll: {
    flex: 1,
    height: 540,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 20,
  },
  message: {
    marginBottom: 30,
  },
  textContent: {
    fontSize: 16,
    lineHeight: 19,
    marginTop: 30,
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'olive',
    height: 48,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  today: {
    fontSize: 12,
    color: 'olive',
    textTransform: 'uppercase',
  },
  error: {
    color: 'danger',
    marginVertical: 15,
  },
  label: {
    fontSize: 12,
    lineHeight: 14,
    textTransform: 'uppercase',
    marginBottom: 14,
  },
  signatureContainer: {
    backgroundColor: 'white',
    height: 66,
    flex: 1,
    marginBottom: 8,
  },
  signature: {
    width: '100%',
    height: 300,
  },
  question: {
    marginTop: 20,
  },
  questionLabel: {
    fontSize: 12,
    color: 'white',
    marginBottom: 12,
    textTransform: 'uppercase',
  },
  author: {
    marginVertical: 20,
  },
  padded: {
    paddingHorizontal: 20,
  },
});

export default observer(EventSpecialistSurvey);
