import {
  Divider,
  Icon,
  Input,
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { FTEOrganizationModel, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import { useStore } from 'o2x-store/src/stores';
import React, { useState } from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';
import SelectUserList from '../../../components/FTE/SelectUserList';

type Props = {
  isModalVisible: boolean;
  hideModal: () => void;
  setQuery: Function;
  fteOrganizations?: FTEOrganizationModel[];
  query: string;
  onCheckOrg: Function;
  onCheckUser: Function;
  pending?: number[];
  guests?: number[];
  declined?: number[];
  groups: OSSGroup[];
  isMobile: boolean;
};

const { width, height } = Dimensions.get('window');

const EventInvitationModal: React.FC<Props> = (props) => {
  const {
    isModalVisible,
    hideModal,
    setQuery,
    fteOrganizations,
    query,
    onCheckOrg,
    onCheckUser,
    pending,
    guests,
    declined,
    groups,
    isMobile,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [selectedGroup, setSelectedGroup] = useState<OSSGroup | undefined>();

  return (
    <Modal visible={isModalVisible} backdropStyle={styles.backdrop} onBackdropPress={hideModal}>
      <Layout style={isMobile ? styles.modalMobile : styles.modal}>
        <Input
          size="small"
          style={styles.searchInput}
          onChangeText={(text) => setQuery(text)}
          placeholder="Search user"
          accessoryRight={() => <Icon style={styles.icon} name="search-outline" fill="#4E7B89" />}
        />
        <Divider style={styles.divider} />
        <SelectUserList
          fteOrganizations={fteOrganizations}
          store={store}
          query={query}
          groups={groups}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          onCheckOrg={onCheckOrg}
          onCheckUser={onCheckUser}
          addedUsers={pending?.concat(guests!, declined!)!}
        />
        <TouchableOpacity style={styles.okButton} onPress={() => hideModal()}>
          <Text style={styles.buttonText}>Okay</Text>
        </TouchableOpacity>
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: width / 2.5,
    height: height / 2,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  modalMobile: {
    width: width / 1.1,
    height: height / 1.8,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  searchInput: {
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  icon: {
    height: 20,
    width: 16,
  },
  divider: {
    marginBottom: 10,
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'gray',
  },
  okButton: {
    justifyContent: 'center',
    backgroundColor: 'olive',
    height: 30,
    width: '20%',
    marginLeft: 'auto',
    marginRight: '5%',
    marginTop: 'auto',
    marginBottom: 10,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
});

export default observer(EventInvitationModal);
