import { dark as base } from '@eva-design/eva';

export const dark = {
  ...base,

  // Colors from Figma.
  wallpaper: '#10283E',
  olive: '#767F6A',
  'dark-blue': '#203950',
  white: '#FFFFFF',
  black: '#091C2D',
  gray: '#697484',
  cyan: '#04B7D6',
  green: '#91C300',
  orange: '#FF7A00',
  'light-gray': '#C4C4C4',
  'lighter-gray': '#E5E5E5',
  blue: '#31576D',
  'blue-secondary': '#4E7B89',
  'darker-blue': '#1A3248',
  warning: '#CFAC42',
  danger: '#C64141',
  red: '#C64141',

  // Overrides
  'background-basic-color-1': '$wallpaper',
  'background-basic-color-2': '$dark-blue',
  'background-basic-color-3': '$blue',
};
