import { useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useKeepAwake } from 'expo-keep-awake';
import Humanize from 'humanize-plus';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Animated,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ScrollView,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import TopNav from 'src/components/Question/TopNav';
import SwipeableList from 'src/components/SwipeableList';
import { useNativeStore } from '../../stores';

type Props = {
  route?: any | null;
};

const WorkoutGeneratorResult: React.FC<Props> = (props) => {
  const {
    route: {
      params: { selectedFilters },
    },
  } = props;

  const { sweatStart, workoutFilter } = useNativeStore();
  const { filters } = workoutFilter;
  const { sweat } = useStore();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(true);
  const titleOpacity = useRef(new Animated.Value(0));
  const [workoutsCountExactMatch, setWorkoutsCountExactMatch] = useState(0);
  const [workoutsCountSomeMatch, setWorkoutsCountSomeMatch] = useState(0);
  const [workoutsCountPopular, setWorkoutsCountPopular] = useState(0);

  const styles = useStyleSheet(themedStyles);
  useKeepAwake();
  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  const loadMoreWorkoutsExactMatch = async () => {
    const res = await sweat.filterSweatWorkoutsExactMatch(selectedFilters);
    if (res.extra?.count) setWorkoutsCountExactMatch(res.extra?.count);
    return res;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      sweat.resetSweatWorkoutExactMatch();
      await loadMoreWorkoutsExactMatch();
      setLoading(false);
    })();
  }, [sweat, selectedFilters]);

  const loadMoreWorkoutsSomeMatch = async () => {
    const res = await sweat.filterSweatWorkoutsSomeMatch(selectedFilters);
    if (res.extra?.count) setWorkoutsCountSomeMatch(res.extra?.count);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      sweat.resetSweatWorkoutSomeMatch();
      await loadMoreWorkoutsSomeMatch();
      setLoading(false);
    })();
  }, [sweat, selectedFilters]);

  const loadMoreWorkoutsPopular = async () => {
    const res = await sweat.filterSweatWorkoutsPopular(selectedFilters);
    if (res.extra?.count) setWorkoutsCountPopular(res.extra?.count);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      sweat.resetSweatWorkoutPopular();
      await loadMoreWorkoutsPopular();
      setLoading(false);
    })();
  }, [sweat, selectedFilters]);

  const exactMatch = useMemo(
    () => Array.from(sweat.sweatWorkoutsExactMatch.values()),
    [sweat.sweatWorkoutsExactMatch.values()],
  );

  const someMatch = useMemo(
    () => Array.from(sweat.sweatWorkoutsSomeMatch.values()),
    [sweat.sweatWorkoutsSomeMatch.values()],
  );

  const popular = useMemo(
    () => Array.from(sweat.sweatWorkoutsPopular.values()),
    [sweat.sweatWorkoutsPopular.values()],
  );

  const generateWorkout = useCallback(
    (id) => {
      navigation.navigate('Workout', { workoutId: id });
    },
    [filters, workoutFilter],
  );

  const onFilterWorkout = useCallback(() => {
    navigation.navigate('WorkoutGeneratorFilter');
  }, [filters]);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isMobile ? styles.modalMobile : styles.modal}>
          <Layout style={styles.container}>
            <Layout style={styles.container}>
              <Layout style={styles.navigationContainer}>
                <TopNav showClose={false} />
              </Layout>
              <Layout style={styles.contentContainer}>
                <Text category="h2" style={styles.title}>
                  Workout Generator
                </Text>
                <View style={styles.actions}>
                  <Text style={styles.actionHeader}>Your Search</Text>
                  <TouchableOpacity onPress={onFilterWorkout}>
                    <Text style={styles.filterButton}>Edit Filter</Text>
                  </TouchableOpacity>
                </View>
                <ScrollView style={styles.results}>
                  <View style={styles.listContainer}>
                    {exactMatch.length > 0 && (
                      <>
                        <Text style={styles.resultTitle}>Exact Matches...</Text>
                        <SwipeableList
                          showNumbers
                          onPress={(id: number) => generateWorkout(id)}
                          data={exactMatch}
                          loadMoreWorkouts={loadMoreWorkoutsExactMatch}
                          workoutsCount={workoutsCountExactMatch}
                        />
                      </>
                    )}
                    {someMatch.length > 0 && (
                      <>
                        <Text style={styles.resultTitle}>similar matches...</Text>
                        <SwipeableList
                          showNumbers
                          onPress={(id: number) => generateWorkout(id)}
                          data={someMatch}
                          loadMoreWorkouts={loadMoreWorkoutsSomeMatch}
                          workoutsCount={workoutsCountSomeMatch}
                        />
                        )
                      </>
                    )}
                    {popular.length > 0 && (
                      <>
                        <Text style={styles.resultTitle}>Workouts You May Also Like...</Text>
                        <SwipeableList
                          showNumbers
                          onPress={(id: number) => generateWorkout(id)}
                          data={popular}
                          loadMoreWorkouts={loadMoreWorkoutsPopular}
                          workoutsCount={workoutsCountPopular}
                        />
                      </>
                    )}
                    {loading && (
                      <View style={styles.loader}>
                        <Spinner />
                      </View>
                    )}
                  </View>
                </ScrollView>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: '#10283E',
  },
  navigation: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  navigationContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginHorizontal: 0,
    paddingHorizontal: 24,
  },
  filterSection: {
    marginVertical: 5,
    marginHorizontal: 24,
    marginBottom: 32,
  },
  extraSection: {
    borderBottomWidth: 1,
    borderColor: 'gray',
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  extraSectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  extraSectionLabelText: {
    fontSize: 10,
    lineHeight: 12,
    color: 'light-gray',
    marginLeft: 5,
  },
  extraSectionText: {
    fontSize: 12,
    lineHeight: 14,
    color: 'white',
    flex: 2,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 29,
    marginBottom: 17,
    paddingHorizontal: 24,
  },
  actionHeader: {
    fontWeight: '700',
    fontSize: 18,
    fontColor: 'white',
  },
  filterButton: {
    fontWeight: '400',
    fontSize: 12,
    color: 'light-gray',
    textTransform: 'uppercase',
  },
  results: {
    flex: 1,
  },
  resultHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 24,
    marginBottom: 17,
  },
  resultDisplay: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  resultCount: {
    fontSize: 24,
    fontWeight: '700',
    color: 'cyan',
    marginRight: 8,
  },
  resultCountLabel: {
    fontSize: 16,
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  resultTitle: {
    paddingHorizontal: 24,
    textTransform: 'uppercase',
    marginVertical: 5,
  },
  generateButton: {
    backgroundColor: 'black',
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  generateButtonText: {
    fontSize: 12,
    color: 'light-gray',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  listContainer: {
    flex: 1,
  },
  workoutButton: {
    marginBottom: 10,
  },
  workoutContainer: {
    flexDirection: 'row',
    height: 60,
    borderLeftWidth: 4,
    borderStyle: 'solid',
    borderColor: '#04B7D6',
    backgroundColor: '#203950',
  },
  workoutContent: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 12,
    marginRight: 4,
    marginVertical: 8,
  },
  subtitle: {
    color: '#04B7D6',
  },
});

export default observer(WorkoutGeneratorResult);
