import { Divider, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';

const FILLERS = ['filler', 'filler'];
const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const DAYS = Array(28).fill(0);
const currentYear = new Date().getFullYear();
const YEARS = Array(currentYear - 1899).fill(0);
const OFFSETS = YEARS.map((_y, i) => i * 44);

type Props = {
  day: number;
  setDay: (value: number) => void;
  month: number;
  setMonth: (value: number) => void;
  year: number;
  setYear: (value: number) => void;
};

const CustomCalendar: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const { day, month, year, setDay, setMonth, setYear } = props;

  const [validDays, setValidDays] = useState<number[]>([]);

  console.log(year, month, day);

  useEffect(() => {
    const strMonth = `00${month.toString()}`.substring(month.toString().length);
    const strYear = year.toString();
    const toBeCheckedDays = [29, 30, 31];
    const newValidDays: number[] = [];
    for (const toBeCheckedDay of toBeCheckedDays) {
      const strDay = `00${toBeCheckedDay.toString()}`.substring(toBeCheckedDay.toString().length);
      const dateString = `${strYear}-${strMonth}-${strDay}`;
      const checkedDate = new Date(dateString);
      if (checkedDate.toDateString() === 'Invalid Date') {
        break;
      }
      newValidDays.push(toBeCheckedDay);
    }
    if (newValidDays.length > 0) {
      if (day > newValidDays[newValidDays.length - 1]) {
        setDay(newValidDays[newValidDays.length - 1]);
      }
    } else if (day > 28) {
      setDay(28);
    }
    setValidDays(newValidDays);
  }, [month, year]);

  const renderMonth = useCallback(({ item }) => {
    if (item === 'filler') {
      return <View style={styles.item} />;
    }
    return (
      <View style={styles.item}>
        <Text category="c1" style={styles.label}>
          {item}
        </Text>
      </View>
    );
  }, []);

  const renderDay = useCallback(({ item, index }) => {
    if (item === 'filler') {
      return <View style={styles.item} />;
    }
    return (
      <View style={styles.item}>
        <Text category="c1" style={styles.label}>
          {`00${(index - 1).toString()}`.substring((index - 1).toString().length)}
        </Text>
      </View>
    );
  }, []);

  const renderYear = useCallback(({ item, index }) => {
    if (item === 'filler') {
      return <View style={styles.item} />;
    }
    return (
      <View style={styles.item}>
        <Text category="c1" style={styles.label}>
          {index + 1898}
        </Text>
      </View>
    );
  }, []);

  const getItemLayout = useCallback(
    (_data, index) => ({
      length: 44,
      offset: 44 * index,
      index,
    }),
    [],
  );

  const onScrollEndMonth = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    setMonth(currentIndex + 1);
  }, []);

  const onScrollEndDay = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    setDay(currentIndex + 1);
  }, []);

  const onScrollEndYear = useCallback((e) => {
    const { y } = e.nativeEvent.contentOffset;
    const currentIndex = Math.round(y / 44);
    setYear(currentIndex + 1900);
  }, []);

  return (
    <Layout style={styles.container}>
      <View style={styles.column}>
        <View style={styles.item}>
          <Text category="c1" style={styles.title}>
            MONTH
          </Text>
        </View>
        <Divider />
        <View style={styles.center} />
        <FlatList
          data={[...FILLERS, ...MONTHS, ...FILLERS]}
          renderItem={renderMonth}
          keyExtractor={(item, index) => `${item}:${index}`}
          showsVerticalScrollIndicator={false}
          bounces={false}
          snapToAlignment="center"
          snapToOffsets={OFFSETS}
          decelerationRate="fast"
          initialScrollIndex={month - 1}
          getItemLayout={getItemLayout}
          onMomentumScrollEnd={onScrollEndMonth}
        />
      </View>
      <View style={styles.divider} />
      <View style={styles.column}>
        <View style={styles.item}>
          <Text category="c1" style={styles.title}>
            DAY
          </Text>
        </View>
        <Divider />
        <View style={styles.center} />
        <FlatList
          data={[...FILLERS, ...DAYS, ...validDays, ...FILLERS]}
          renderItem={renderDay}
          keyExtractor={(item, index) => `${item}:${index}`}
          showsVerticalScrollIndicator={false}
          bounces={false}
          snapToAlignment="center"
          snapToOffsets={OFFSETS}
          decelerationRate="fast"
          initialScrollIndex={day - 1}
          getItemLayout={getItemLayout}
          onMomentumScrollEnd={onScrollEndDay}
        />
      </View>
      <View style={styles.divider} />
      <View style={styles.column}>
        <View style={styles.item}>
          <Text category="c1" style={styles.title}>
            YEAR
          </Text>
        </View>
        <Divider />
        <View style={styles.center} />
        <FlatList
          data={[...FILLERS, ...YEARS, ...FILLERS]}
          renderItem={renderYear}
          keyExtractor={(item, index) => `${item}:${index}`}
          showsVerticalScrollIndicator={false}
          bounces={false}
          snapToAlignment="center"
          snapToOffsets={OFFSETS}
          decelerationRate="fast"
          initialScrollIndex={year - 1900}
          getItemLayout={getItemLayout}
          onMomentumScrollEnd={onScrollEndYear}
        />
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  column: {
    flex: 1,
  },
  container: {
    height: 264,
    flexDirection: 'row',
  },
  divider: {
    width: 1,
    backgroundColor: '#E5E5E5',
    opacity: 0.3,
    marginTop: 44,
  },
  item: {
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: 'color-basic-600',
  },
  title: {
    color: 'color-basic-600',
    textTransform: 'uppercase',
  },
  center: {
    top: 132,
    height: 44,
    width: '100%',
    backgroundColor: 'dark-blue',
    position: 'absolute',
  },
});

export default CustomCalendar;
