import { isFunction } from 'lodash';
import { computed, observable } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';

export type Filters = { [key: string]: boolean };

@model('o2x-native/ExploreFilter')
export default class ExploreFilter extends Model({
  search: prop<string>(''),
  filters: prop<Filters>(() => ({})),
}) {
  @observable hasChanged = false;

  @modelAction
  setSearch(search: string) {
    this.hasChanged = true;
    this.search = search;
  }

  @modelAction
  setFilters(filters: ((filters: Filters) => Filters) | Filters) {
    this.hasChanged = true;
    if (isFunction(filters)) {
      this.filters = filters(this.filters);
    } else {
      this.filters = filters;
    }
  }

  @computed
  get isFiltering(): boolean {
    return this.search.length > 0 || !!Object.values(this.filters).find((filter) => filter);
  }

  @modelAction
  reset() {
    this.search = '';
    this.filters = {};
  }
}
