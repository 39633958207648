import { useFocusEffect } from '@react-navigation/native';
import {
  Icon,
  IndexPath,
  Input,
  Layout,
  Select,
  SelectItem,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { FTEOrganizationModel, OSSGroup } from 'o2x-store/src/models/FTELibrary';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { Image, ImageStyle, ScrollView, StyleProp, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import CreateGroupModal from 'src/components/FTE/CreateGroupModal';

type Props = {
  handlePressOnOrg: (org: FTEOrganizationModel) => void;
  handlePressOnUser: (user: User) => void;
  handlePressOnGroup: (group: OSSGroup) => void;
  targetGroup?: OSSGroup | undefined;
};

const OSSOrganization: React.FC<Props> = (props) => {
  const {
    handlePressOnOrg,
    handlePressOnUser,
    handlePressOnGroup,
    targetGroup = undefined,
  } = props;
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const [organization, setOrganization] = useState<FTEOrganizationModel>();
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingOrganization, setLoadingOrganization] = useState(false);
  const [showUsers, setShowUsers] = useState<boolean>();
  const [query, setQuery] = useState('');
  const [groupQuery, setGroupQuery] = useState('');
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditingGroup, setEditingGroup] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<OSSGroup | undefined>(targetGroup);

  const reloadGroups = useCallback(async () => {
    setLoadingGroups(true);
    await store.fteLibrary.fetchOSSGroups();
    setLoadingGroups(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        setLoadingOrganization(true);
        const fetchData = await store.fteLibrary.fetchFTEOrganization();
        setShowUsers(true);
        if (fetchData.extra && fetchData.extra.length) {
          setOrganization(fetchData.extra[0] as FTEOrganizationModel);
        }
        await reloadGroups();
        setLoadingOrganization(false);
      })();
    }, []),
  );

  const showCollapsible = () => {
    setShowUsers((prev) => !prev);
  };

  const loading = loadingGroups || loadingOrganization;

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Layout style={styles.container}>
      <Layout style={[styles.bgContainer, isMobile && { flexDirection: 'column' }]}>
        <Input
          size="small"
          style={styles.search}
          value={query}
          onChangeText={(text) => setQuery(text)}
          accessoryRight={() => <Icon style={styles.icon} name="search-outline" fill="white" />}
          placeholder="Search user"
        />
        <TouchableOpacity
          style={isMobile ? styles.createButtonMobile : styles.createButton}
          onPress={() => setModalVisible(true)}>
          <Text style={styles.createText}>+ Create New Group</Text>
        </TouchableOpacity>
      </Layout>
      {isModalVisible && (
        <CreateGroupModal
          isModalVisible={isModalVisible}
          hideModal={() => {
            setModalVisible(false);
            setEditingGroup(false);
          }}
          setQuery={setGroupQuery}
          fteOrganizations={organization ? [organization] : []}
          query={groupQuery}
          reloadGroups={reloadGroups}
          edit={isEditingGroup}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          isMobile={isMobile}
        />
      )}

      <View style={styles.contentContainer}>
        {loading ? (
          <Layout style={styles.loading}>
            <Spinner />
          </Layout>
        ) : (
          <Layout style={[styles.tableData, { height: '100%' }]}>
            {organization && (
              <Layout style={styles.orgView}>
                <TouchableOpacity
                  style={styles.orgContainer}
                  onPress={() =>
                    selectedGroup
                      ? handlePressOnGroup(selectedGroup!)
                      : handlePressOnOrg(organization)
                  }>
                  <Image
                    style={styles.logo as StyleProp<ImageStyle>}
                    source={
                      organization.logo
                        ? { uri: organization.logo }
                        : require('../../../assets/images/user_placeholder.png')
                    }
                  />
                  <Text style={styles.orgText} numberOfLines={1}>
                    {selectedGroup ? selectedGroup.groupName : organization.name}
                  </Text>
                  <Select
                    size="small"
                    style={isMobile ? styles.selectMobile : styles.select}
                    value={
                      selectedGroup
                        ? store.fteLibrary.getOOSGroupById(selectedGroup.id).groupName
                        : 'All'
                    }
                    onSelect={(index: IndexPath | IndexPath[]) => {
                      setSelectedGroup(
                        index.row ? store.fteLibrary.ossGroups[index.row] : undefined,
                      );
                    }}>
                    {store.fteLibrary.ossGroups.map((group, index) => (
                      <SelectItem key={index} title={group.groupName} />
                    ))}
                  </Select>
                  <Layout style={styles.buttonsContainer}>
                    {selectedGroup && selectedGroup.groupName != 'all' && (
                      <TouchableOpacity
                        style={[styles.editGroupButton, isMobile && { marginLeft: 2 }]}
                        onPress={() => {
                          setModalVisible(true);
                          setEditingGroup(true);
                        }}>
                        <Text style={styles.editGroupText}>{isMobile ? 'Edit' : 'Edit Group'}</Text>
                      </TouchableOpacity>
                    )}
                    <TouchableOpacity
                      style={[styles.expandButton, isMobile && { marginLeft: 5 }]}
                      onPress={() => showCollapsible()}>
                      <Text style={styles.showText}>{showUsers ? 'HIDE' : 'SHOW'}</Text>
                    </TouchableOpacity>
                  </Layout>
                </TouchableOpacity>
                <ScrollView style={styles.userScroll} stickyHeaderIndices={[0]}>
                  {showUsers ? (
                    organization.users.length > 0 ? (
                      <Layout style={styles.tableHeader}>
                        <View style={styles.headerContainer}>
                          <Text style={styles.textHeader}>Email</Text>
                        </View>
                        <View style={styles.headerContainer}>
                          <Text style={styles.textHeader}>First Name</Text>
                        </View>
                        <View style={styles.headerContainer}>
                          <Text style={styles.textHeader}>Last Name</Text>
                        </View>
                        <View style={styles.headerContainer}>
                          <Text style={styles.textHeader}>O2X ID</Text>
                        </View>
                      </Layout>
                    ) : null
                  ) : null}

                  {showUsers
                    ? organization.users
                        .filter((user) =>
                          selectedGroup ? selectedGroup?.users?.includes(user.id) : user,
                        )
                        .filter((user) =>
                          query.trim().length > 0
                            ? (user.id !== store.auth.user?.id &&
                                (user.email.toLowerCase().includes(query.trim().toLowerCase()) ||
                                  user.fullName
                                    .toLowerCase()
                                    .includes(query.trim().toLowerCase()) ||
                                  user.o2XId.includes(query.trim()))) ||
                              user.promoCode?.toLowerCase().includes(query.trim().toLowerCase())
                            : // ||
                              // groups[query.trim()]?.users?.includes(user.id)
                              user.id !== store.auth.user?.id,
                        )
                        .map((user, index) => (
                          <Layout key={index} style={styles.tableData}>
                            <Layout style={styles.dataContainer}>
                              <TouchableOpacity
                                style={styles.headerContainer}
                                onPress={() => handlePressOnUser(user)}>
                                <Text style={styles.dataText} numberOfLines={1}>
                                  {user.email}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={styles.headerContainer}
                                onPress={() => handlePressOnUser(user)}>
                                <Text style={styles.dataText}>
                                  {user.firstName ? user.firstName : '-'}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={styles.headerContainer}
                                onPress={() => handlePressOnUser(user)}>
                                <Text style={styles.dataText}>
                                  {user.lastName ? user.lastName : '-'}
                                </Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                style={styles.headerContainer}
                                onPress={() => handlePressOnUser(user)}>
                                <Text style={styles.dataText}>{user.o2XId}</Text>
                              </TouchableOpacity>
                            </Layout>
                          </Layout>
                        ))
                    : null}
                </ScrollView>
              </Layout>
            )}
          </Layout>
        )}
      </View>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  tableHeader: {
    width: '100%',
    height: '40px',
    backgroundColor: '#091C2D',
    flexDirection: 'row',
  },
  textHeader: {
    color: 'white',
    fontSize: 14,
  },
  headerContainer: {
    width: '25%',
    border: '1px solid #1A3248',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableData: {
    width: '100%',
    flexDirection: 'column',
  },
  orgContainer: {
    width: '100%',
    height: '40px',
    backgroundColor: '#203950',
    marginTop: 10,
    flexDirection: 'row',
  },
  contentContainer: {
    width: '90%', // '988px'
    height: '85%',
    marginLeft: '26px',
    marginBottom: '40px',
  },
  userScroll: {
    // height: '90%',
    flex: 1,
  },
  search: {
    width: '312px',
    marginTop: 10,
    // marginLeft: '26px',
    marginBottom: 10,
    backgroundColor: '#1A3248',
  },
  icon: {
    width: 20,
    height: 20,
  },
  dataContainer: {
    backgroundColor: '#091C2D',
    width: '100%', // 988px
    height: '40px',
    flexDirection: 'row',
  },
  loading: {
    margin: 'auto',
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  orgText: {
    color: '#4E7B89',
    fontSize: 18,
    lineHeight: 22,
    letterSpacing: 0.05,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  dataText: {
    color: '#4E7B89',
    fontSize: 14,
    maxWidth: '80%',
  },
  logo: {
    height: 25,
    width: 25,
    marginLeft: 10,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  buttonsContainer: {
    backgroundColor: 'transparent',
    margin: 'auto',
    marginRight: 20,
    flexDirection: 'row',
  },
  expandButton: {
    backgroundColor: 'transparent',
    marginLeft: 20,
  },
  editGroupButton: {
    backgroundColor: 'transparent',
    borderColor: '#4E7B89',
    borderWidth: 1,
    paddingHorizontal: 10,
  },
  editGroupText: {
    color: '#4E7B89',
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.05,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  showText: {
    color: '#4E7B89',
    fontSize: 12,
    lineHeight: 22,
    letterSpacing: 0.05,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  createButton: {
    marginLeft: 'auto',
    marginTop: 10,
    marginBottom: 10,
    height: '32px',
    borderColor: '#1A3248',
    borderWidth: 2.5,
  },
  createButtonMobile: {
    marginTop: 4,
    marginBottom: 4,
    height: '32px',
    width: '312px',
    borderColor: '#1A3248',
    borderWidth: 2.5,
  },
  createText: {
    marginVertical: 'auto',
    marginHorizontal: '10px',
    fontSize: 14,
  },
  bgContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: '90%',
    marginLeft: '26px',
  },
  orgView: {
    width: '100%',
    backgroundColor: 'transparent',
    height: '100%',
  },
  select: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 10,
    borderColor: 'transparent',
    width: '10%',
  },
  selectMobile: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 10,
    borderColor: 'transparent',
    width: '30%',
  },
});

export default observer(OSSOrganization);
