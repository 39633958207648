import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { get } from 'lodash';
import ThriveProgram from 'o2x-store/src/models/ThriveProgram';
import ThriveProgramDay from 'o2x-store/src/models/ThriveProgramDay';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNativeStore } from 'src/stores';

type Props = {
  program?: ThriveProgram;
  dayId: number;
  nextDay?: ThriveProgramDay;
};

const ThriveStartActions: React.FC<Props> = (props) => {
  const { program, dayId } = props;
  const { auth, thrive } = useStore();
  const day = thrive.thriveProgramDays.get(`${dayId}`);
  const { notification } = useNativeStore();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);

  const onPress = useCallback(async () => {
    if (program) {
      if (day) {
        await day.markComplete();
        await program.fetch();
      }
      const nextDayId = get(program, 'userProgress.currentDay', null);
      const nextDay = nextDayId && thrive.thriveProgramDays.get(`${nextDayId}`);

      if (nextDay) {
        if (Platform.OS !== 'web') {
          if (auth.user?.settings.dailyPlanReminders) {
            const numberOfdays = (nextDay.week - day!.week) * 7 + (nextDay.day - day!.day);
            await notification.setThriveNotification(program.name, `${program.id}`, numberOfdays);
          }
        }

        await nextDay.start();
      } else {
        await program.markComplete();
        navigation.goBack();
      }
    }
    navigation.navigate('ThriveProgramDayComplete', {
      programId: program ? program.id : undefined,
      dayId,
    });
  }, [program, day]);

  if (!program) {
    return null;
  }

  return (
    <Layout style={[styles.container, { marginBottom: insets.bottom }]}>
      <TouchableOpacity style={styles.finishButton} onPress={onPress}>
        <Text style={styles.finishButtonText}>Finish</Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    height: 48,
  },
  finishButton: {
    flex: 1,
    backgroundColor: 'olive',
    padding: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  finishButtonText: {
    flex: 1,
    textTransform: 'uppercase',
  },
});

export default ThriveStartActions;
