import { Icon, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import {
  FTESweatWorkout,
  FTESweatWorkoutStep,
  FTESweatWorkoutStepExercise,
  FTESweatWorkoutStepExerciseSet,
} from 'o2x-store/src/models/FTELibrary';
import SweatGlobalStepExercise from 'o2x-store/src/models/SweatGlobalStepExercise';
import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import SweatWorkout, { SweatWorkoutStep } from '../../../o2x-store/src/models/SweatWorkout';

type Props = {
  index: number;
  stepIndex?: number;
  setStepIndex?: Function;
  setExerIndex: Function;
  setExercise: Function;
  setParamModalVisible: Function;
  exercise: SweatGlobalStepExercise | FTESweatWorkoutStepExercise;
  circuitSets?: number;
  style: StyleService;
  exerciseData: string;
  stepList?: (FTESweatWorkoutStep | SweatWorkoutStep)[];
  workoutList?: (FTESweatWorkout | SweatWorkout)[];
  exerciseList?: SweatGlobalStepExercise[];
  content: string;
  onRemoveExercise: () => void;
  drag: () => void;
};

const paramsSelect: { [key: string]: string } = {
  reps: 'Reps',
  weight: 'Weight',
  distance: 'Distance',
  time: 'Time',
  rest: 'Rest',
  rpe: 'RPE',
};

const ExerciseItem: React.FC<Props> = (props) => {
  const {
    index,
    setExerIndex,
    setExercise,
    setParamModalVisible,
    exercise,
    circuitSets,
    style,
    onRemoveExercise,
    setStepIndex,
    stepIndex,
    stepList,
    workoutList,
    content,
    exerciseList,
    drag,
  } = props;
  const styles = useStyleSheet(themedStyles);

  const [exerciseParam, setParamList] = useState<string[]>(
    exercise.exerciseSets
      .map((obj) =>
        Object.keys(obj)
          .filter((key) => obj[key] && obj[key].length && key !== 'name')
          .flatMap((key) => {
            if (key === 'reps') {
              return [paramsSelect[key], paramsSelect.isRepsEachSide];
            }
            return paramsSelect[key];
          }),
      )
      .flat()
      .filter((x, i, a) => a.indexOf(x) === i),
  );
  const [showSets, setShowSets] = useState(false);

  const textWidth =
    exerciseParam && exerciseParam.length > 0 && !exerciseParam.includes('Reps')
      ? `${100 / exerciseParam.length}%`
      : exerciseParam && exerciseParam.length > 0 && exerciseParam.includes('Reps')
      ? `${100 / (exerciseParam.length - 1)}%`
      : '0';

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  useEffect(() => {
    setParamList(
      exercise.exerciseSets
        .map((obj) =>
          Object.keys(obj)
            .filter((key) => obj[key] && obj[key].length && key !== 'name')
            .flatMap((key) => {
              if (key === 'reps') {
                return [paramsSelect[key], paramsSelect.isRepsEachSide];
              }
              return paramsSelect[key];
            }),
        )
        .flat()
        .filter((x, i, a) => a.indexOf(x) === i),
    );
  }, [stepList, workoutList, exerciseList]);

  useEffect(() => {
    const sets = [];
    if ((!!circuitSets || !!exercise.sets) && !exercise.exerciseSets.length) {
      for (let i = 0; i < (exercise.sets ? parseInt(exercise.sets) : circuitSets)!; i++) {
        const setObject: Partial<FTESweatWorkoutStepExerciseSet> = {
          reps: exercise.reps,
          isRepsEachSide: exercise.isRepsEachSide,
          weight: '',
          distance: exercise.distance,
          time: exercise.workoutTimerWork?.toString(),
          rest: exercise.workoutTimerRest?.toString(),
          rpe: '',
        };
        sets.push(setObject);
      }
      exercise.exerciseSets = sets;
      setParamList(
        exercise.exerciseSets
          .map((obj) =>
            Object.keys(obj)
              .filter((key) => obj[key] && obj[key].length && key !== 'name')
              .flatMap((key) => {
                if (key === 'reps') {
                  return [paramsSelect[key], paramsSelect.isRepsEachSide];
                }
                return paramsSelect[key];
              }),
          )
          .flat()
          .filter((x, i, a) => a.indexOf(x) === i),
      );
    }
  }, [exercise.exerciseSets, exerciseParam]);

  return (
    <>
      <Layout
        style={[
          { width: '100%', flexDirection: 'row', height: 40 },
          content === 'global' && { alignSelf: 'center' },
        ]}>
        <TouchableOpacity
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginRight: 5,
            marginLeft: 'auto',
          }}
          onLongPress={drag}
          delayLongPress={50}>
          <Icon name="menu-outline" fill="white" style={{ width: 20, height: 20 }} />
        </TouchableOpacity>

        <TouchableOpacity
          key={index}
          style={[style, { flexDirection: 'row' }, content === 'global' && { marginRight: 'auto' }]}
          onPress={() => {
            setStepIndex && setStepIndex(stepIndex);
            setExerIndex(index);
            setExercise(exercise);
            setParamModalVisible(true);
          }}>
          <Text style={styles.exerciseText} numberOfLines={1}>
            {exercise.exerciseName}
          </Text>
          <TouchableOpacity
            style={{
              marginLeft: 'auto',
              marginRight: 10,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            onPress={() => setShowSets(!showSets)}>
            <Icon
              name={showSets ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}
              fill="white"
              style={{
                width: 15,
                height: 15,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginRight: 10,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            onPress={onRemoveExercise}>
            <Icon
              name="close-outline"
              fill="white"
              style={{
                width: 15,
                height: 15,
              }}
            />
          </TouchableOpacity>
        </TouchableOpacity>
      </Layout>
      {showSets && exerciseParam && exerciseParam.length > 0 && !isMobile && (
        <ScrollView
          style={[
            styles.userScroll,
            content === 'global' && {
              marginTop: 5,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          ]}>
          <Layout style={styles.tableHeader}>
            {exerciseParam.find((x) => x == 'Reps') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>Reps</Text>
              </View>
            )}
            {exerciseParam.find((x) => x == 'Weight') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>lbs</Text>
              </View>
            )}
            {exerciseParam.find((x) => x == 'Distance') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>Dist</Text>
              </View>
            )}
            {exerciseParam.find((x) => x == 'Time') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>Time</Text>
              </View>
            )}
            {exerciseParam.find((x) => x == 'Rest') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>Rest</Text>
              </View>
            )}
            {exerciseParam.find((x) => x == 'RPE') && (
              <View style={[styles.headerContainer, { width: textWidth }]}>
                <Text style={styles.textHeader}>RPE</Text>
              </View>
            )}
          </Layout>
          {exercise.exerciseSets.map((set, index) => (
            <Layout key={index} style={styles.tableData}>
              <Layout style={styles.dataContainer}>
                {exerciseParam.find((x) => x == 'Reps') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {`${set.reps} ${set.isRepsEachSide ? 'each side' : ''}`}
                    </Text>
                  </View>
                )}
                {exerciseParam.find((x) => x == 'Weight') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {set.weight}
                    </Text>
                  </View>
                )}
                {exerciseParam.find((x) => x == 'Distance') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {set.distance}
                    </Text>
                  </View>
                )}
                {exerciseParam.find((x) => x == 'Time') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {set.time}
                    </Text>
                  </View>
                )}
                {exerciseParam.find((x) => x == 'Rest') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {set.rest}
                    </Text>
                  </View>
                )}
                {exerciseParam.find((x) => x == 'RPE') && (
                  <View style={[styles.headerContainer, { width: textWidth }]}>
                    <Text style={styles.dataText} numberOfLines={1}>
                      {set.rpe}
                    </Text>
                  </View>
                )}
              </Layout>
            </Layout>
          ))}
        </ScrollView>
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  exerciseText: {
    fontSize: 16,
    letterSpacing: 1,
    marginLeft: 15,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  tableHeader: {
    width: '100%',
    height: '40px',
    backgroundColor: '#091C2D',
    flexDirection: 'row',
  },
  textHeader: {
    color: 'white',
    fontSize: 12,
  },
  headerContainer: {
    padding: 4,
    border: '1px solid #1A3248',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableData: {
    width: '100%',
    height: '40px',
    flexDirection: 'column',
  },
  dataContainer: {
    backgroundColor: '#091C2D',
    width: '100%',
    height: '40px',
    flexDirection: 'row',
  },
  dataText: {
    color: '#4E7B89',
    fontSize: 12,
    textAlign: 'center',
    width: '100%',
    padding: 0,
  },
  userScroll: {
    maxHeight: '150px',
    backgroundColor: '#203950',
    width: '82%',
    marginLeft: '16%',
    marginRight: 0,
    marginTop: 5,
  },
});

export default React.memo(ExerciseItem, (prevProps, nextProps) => {
  if (
    prevProps.exerciseData !== nextProps.exerciseData ||
    prevProps.exercise.exerciseSets !== nextProps.exercise.exerciseSets
  )
    return false;
  return true;
});
