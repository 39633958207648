import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import AskAnExpert from 'src/containers/AskAnExpert';
import EatStart from 'src/containers/EatStart';
import EventCheckin from 'src/containers/Events/EventCheckin';
import EventDetail from 'src/containers/Events/EventDetail';
import EventListEdit from 'src/containers/Events/EventListEdit';
import EventRelease from 'src/containers/Events/EventRelease';
import EventSignature from 'src/containers/Events/EventSignature';
import EventTerms from 'src/containers/Events/EventTerms';
import EventWaiver from 'src/containers/Events/EventWaiver';
import ProgramDetail from 'src/containers/ProgramDetail';
import RatingModal from 'src/containers/RatingModal';
import Saved from 'src/containers/Saved';
import ListDelete from 'src/containers/Saved/ListDelete';
import ListDetail from 'src/containers/Saved/ListDetail';
import ListEdit from 'src/containers/Saved/ListEdit';
import PlansEdit from 'src/containers/Saved/PlansEdit';
import SaveToList from 'src/containers/Saved/SaveToList';
import SweatContinue from 'src/containers/SweatContinue';
import SweatOptions from 'src/containers/SweatOptions';
import SweatOptionsJump from 'src/containers/SweatOptions/SweatOptionsJump';
import SweatOptionsQuit from 'src/containers/SweatOptions/SweatOptionsQuit';
import SweatStart from 'src/containers/SweatStart';
import SweatStartDetail from 'src/containers/SweatStart/SweatStartDetail';
import SweatStartDetailInstruction from 'src/containers/SweatStart/SweatStartDetailInstruction';
import SweatWorkoutComplete from 'src/containers/SweatWorkoutComplete';
import ThriveBreathe from 'src/containers/ThriveBreathe';
import ThriveMeditate from 'src/containers/ThriveMeditate';
import ThriveOptions from 'src/containers/ThriveOption';
import ThriveOptionsJump from 'src/containers/ThriveOption/ThriveOptionsJump';
import ThriveOptionsQuit from 'src/containers/ThriveOption/ThriveOptionsQuit';
import ThriveProgramDayComplete from 'src/containers/ThriveProgramDayComplete';
import ThriveSetTime from 'src/containers/ThriveSetTime';
import ThriveSleep from 'src/containers/ThriveSleep';
import ThriveStart from 'src/containers/ThriveStart';
import ThriveYoga from 'src/containers/ThriveYoga';
import ThriveYogaFilter from 'src/containers/ThriveYoga/ThriveYogaFilter';
import ThriveYogaDetail from 'src/containers/ThriveYogaDetail';
import WorkoutGenerator from 'src/containers/WorkoutGenerator';
import Header from '../../Header';
import ProgramPreview from '../../ProgramDetail/Preview';
import NetPromoterStackModal from '../modals/NetPromoterStackModal';
import SweatWorkoutSummaryEditModal from '../modals/SweatWorkoutSummaryEditModal';

type Props = {};

const Stack = createStackNavigator();

const SavedStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Saved" component={Saved} />
    <Stack.Screen name="ListEdit" component={ListEdit} />
    <Stack.Screen name="ListDelete" component={ListDelete} />
    <Stack.Screen name="PlansEdit" component={PlansEdit} />
    <Stack.Screen name="ListDetail" component={ListDetail} />
    <Stack.Screen name="SaveToList" component={SaveToList} />
    <Stack.Screen name="ProgramDetail" component={ProgramDetail} />
    <Stack.Screen name="ProgramPreview" component={ProgramPreview} />
    <Stack.Screen name="RatingModal" component={RatingModal} />
    <Stack.Screen name="SweatStart" component={SweatStart} />
    <Stack.Screen name="SweatStartDetail" component={SweatStartDetail} />
    <Stack.Screen
      name="SweatStartDetailInstruction"
      component={SweatStartDetailInstruction}
    />
    <Stack.Screen
      name="SweatWorkoutComplete"
      component={SweatWorkoutComplete}
    />
    <Stack.Screen
      name="SweatWorkoutSummary"
      component={SweatWorkoutSummaryEditModal}
    />
    <Stack.Screen name="SweatOptions" component={SweatOptions} />
    <Stack.Screen name="SweatOptionsJump" component={SweatOptionsJump} />
    <Stack.Screen name="SweatOptionsQuit" component={SweatOptionsQuit} />
    <Stack.Screen name="SweatContinue" component={SweatContinue} />
    <Stack.Screen name="ThriveStart" component={ThriveStart} />
    <Stack.Screen name="ThriveOptionsJump" component={ThriveOptionsJump} />
    <Stack.Screen name="ThriveOptionsQuit" component={ThriveOptionsQuit} />
    <Stack.Screen
      name="ThriveProgramDayComplete"
      component={ThriveProgramDayComplete}
    />
    <Stack.Screen name="ThriveSleep" component={ThriveSleep} />
    <Stack.Screen name="ThriveBreathe" component={ThriveBreathe} />
    <Stack.Screen name="ThriveMeditate" component={ThriveMeditate} />
    <Stack.Screen name="ThriveYoga" component={ThriveYoga} />
    <Stack.Screen name="ThriveYogaDetail" component={ThriveYogaDetail} />
    <Stack.Screen name="ThriveYogaFilter" component={ThriveYogaFilter} />
    <Stack.Screen name="ThriveSetTime" component={ThriveSetTime} />
    <Stack.Screen name="ThriveOptions" component={ThriveOptions} />
    <Stack.Screen name="WorkoutGenerator" component={WorkoutGenerator} />
    <Stack.Screen name="AskAnExpert" component={AskAnExpert} />
    <Stack.Screen name="EatStart" component={EatStart} />
    <Stack.Screen name="EventDetail" component={EventDetail} />
    <Stack.Screen name="EventCheckin" component={EventCheckin} />
    <Stack.Screen name="EventWaiver" component={EventWaiver} />
    <Stack.Screen name="EventRelease" component={EventRelease} />
    <Stack.Screen name="EventTerms" component={EventTerms} />
    <Stack.Screen name="EventSignature" component={EventSignature} />
    <Stack.Screen name="EventListEdit" component={EventListEdit} />
    <Stack.Screen name="EventSpecialists" component={NetPromoterStackModal} />
  </Stack.Navigator>
);

export default SavedStack;
