import {
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

type Props = {
  section: {
    key: string;
    label: string;
  };
  fill: string;
  onClearSection: (section: string) => void;
  showHeaderKey?: boolean;
};

const ExploreFilterSectionHeader: React.FC<Props> = (props) => {
  const { section, fill, onClearSection, showHeaderKey } = props;
  const styles = useStyleSheet(themedStyles);

  const onPress = useCallback(() => {
    onClearSection(section.key);
  }, [section]);

  return (
    <Layout style={styles.container}>
      <Text style={styles.label} category="c1">
        {showHeaderKey == undefined || showHeaderKey
          ? section.label || section.key
          : ''}
      </Text>

      <TouchableOpacity style={styles.clear} onPress={onPress}>
        <Text style={[styles.clearLabel, { color: fill }]} category="c1">
          Clear
        </Text>
      </TouchableOpacity>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 12,
  },
  clear: {},
  clearLabel: { textTransform: 'uppercase' },
  label: {
    flex: 1,
    color: 'blue-secondary',
    textTransform: 'uppercase',
  },
});

export default ExploreFilterSectionHeader;
