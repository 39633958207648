import { observer } from 'mobx-react-lite';
import {
  FTEOrganizationModel,
  OSSGroup,
} from 'o2x-store/src/models/FTELibrary';
import User from 'o2x-store/src/models/User';
import React, { useState } from 'react';
import CreateContent from './CreateContent';
import OSSOrganization from './OSSOrganization';

type Props = {};

const Content: React.FC<Props> = (props) => {
  const [showOrgCreate, setShowOrgCreate] = useState(false);
  const [showUserCreate, setShowUserCreate] = useState(false);
  const [showGroupCreate, setShowGroupCreate] = useState(false);
  const [targetOrg, setTargetOrg] = useState<FTEOrganizationModel>();
  const [targetUser, setTargetUser] = useState<User>();
  const [targetGroup, setTargetGroup] = useState<OSSGroup>();

  const createOrgContent = (org: FTEOrganizationModel) => {
    setShowOrgCreate(true);
    setShowUserCreate(false);
    setShowGroupCreate(false);
    setTargetOrg(org);
    setTargetGroup(undefined);
  };
  const createUserContent = (user: User) => {
    setShowUserCreate(true);
    setShowOrgCreate(false);
    setShowGroupCreate(false);
    setTargetUser(user);
  };
  const createGroupContent = (group: OSSGroup) => {
    setShowGroupCreate(true);
    setShowUserCreate(false);
    setShowOrgCreate(false);
    setTargetGroup(group);
  };

  return showOrgCreate ? (
    <CreateContent onClose={() => setShowOrgCreate(false)} org={targetOrg} />
  ) : showUserCreate ? (
    <CreateContent onClose={() => setShowUserCreate(false)} user={targetUser} />
  ) : showGroupCreate ? (
    <CreateContent
      onClose={() => setShowGroupCreate(false)}
      group={targetGroup}
    />
  ) : (
    <OSSOrganization
      handlePressOnUser={createUserContent}
      handlePressOnOrg={createOrgContent}
      handlePressOnGroup={createGroupContent}
      targetGroup={targetGroup}
    />
  );
};

export default observer(Content);
