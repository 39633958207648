import {
  CheckBox,
  Icon,
  Input,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { FTESweatWorkout } from 'o2x-store/src/models/FTELibrary';
import SweatWorkout from 'o2x-store/src/models/SweatWorkout';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import StepDetails from 'src/components/FTE/StepDetails';
import LoadModalTemplate from './LoadModalTemplate';
import MorePopover from './MorePopover';
import SaveModalTemplate from './SaveModalTemplate';

type Props = {
  workoutIndex: number;
  workout: FTESweatWorkout | SweatWorkout;
  workoutList: (FTESweatWorkout | SweatWorkout)[];
  setWorkoutList: Function;
  workoutJSON: string;
  onRemoveWorkout: () => void;
  flag: number;
  setToRender: Function;
  stepClipboard?: string;
  setStepClipboard?: Function;
};

const WorkoutItem: React.FC<Props> = (props) => {
  const {
    workoutIndex,
    workout,
    workoutList,
    setWorkoutList,
    onRemoveWorkout,
    setToRender,
    stepClipboard,
    setStepClipboard,
  } = props;
  const styles = useStyleSheet(themedStyles);
  const store = useStore();

  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [loadModalVisible, setLoadModalVisible] = useState(false);
  const [workoutTemplate, setWorkoutTemplate] = useState<FTESweatWorkout | SweatWorkout>();
  const [templateName, setTemplateName] = useState('');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [prepare, setPrepare] = useState<number | null>(
    workout.ftePrepare
      ? isNaN(workout.ftePrepare)
        ? workout.ftePrepare.id
        : workout.ftePrepare
      : null,
  );
  const [recover, setRecover] = useState<number | null>(
    workout.fteRecover
      ? isNaN(workout.fteRecover)
        ? workout.fteRecover.id
        : workout.fteRecover
      : null,
  );
  const [prepName, setPrepName] = useState<string>(
    workout.ftePrepare && workout.ftePrepare.name
      ? workout.ftePrepare.name
      : workout.prepName
      ? workout.prepName
      : 'O2X Standard Prepare Step',
  );
  const [recName, setRecName] = useState<string>(
    workout.fteRecover && workout.fteRecover.name
      ? workout.fteRecover.name
      : workout.recName
      ? workout.recName
      : 'O2X Standard Recover Step',
  );
  const [workoutCopy, setWorkoutCopy] = useState<FTESweatWorkout | SweatWorkout>(workout);

  useEffect(() => {
    workout.ftePrepare = prepare;
    workout.fteRecover = recover;
    workout.prepName = prepName;
    workout.recName = recName;
  }, [prepare, recover, recName, prepName]);

  useEffect(() => {
    if (workoutTemplate) {
      workoutTemplate.program = null;
      workoutTemplate.week = workout.week;
      workoutTemplate.day = workout.day;
      workoutTemplate.included = true;
      const workoutArr = [...workoutList];
      const stepArr = workoutTemplate.steps;
      stepArr?.forEach((step) => {
        step.id = null;
        step.exercises?.forEach((exercise) => {
          exercise.id = null;
          exercise.exercise = exercise.exercise.id;
        });
      });
      const workoutObj: Partial<FTESweatWorkout> = {
        week: workout.week,
        day: workout.day,
        program: null,
        ftePrepare: workoutTemplate.ftePrepare?.id ? workoutTemplate.ftePrepare.id : null,
        fteRecover: workoutTemplate.fteRecover?.id ? workoutTemplate.fteRecover.id : null,
        steps: stepArr,
        prepName,
        recName,
        included: true,
      };
      workoutArr.splice(workoutList.indexOf(workout), 1, workoutObj);
      setWorkoutList(workoutArr);
      setWorkoutCopy(workoutObj);
    }
  }, [workoutTemplate]);

  const renderToggleButton = () => (
    <TouchableOpacity
      style={{
        marginLeft: 'auto',
        marginRight: 2,
        marginTop: 'auto',
      }}
      onPress={() => setIsOptionsVisible(true)}>
      <Icon
        name="more-vertical-outline"
        fill="white"
        style={{
          width: 15,
          height: 15,
        }}
      />
    </TouchableOpacity>
  );

  const saveTemplate = useCallback(async () => {
    const data = workout;
    data.name = templateName;
    data.fte = store.auth.user?.id;
    data.steps?.forEach((step) => {
      step.exercises?.map((exercise) => {
        Number.isInteger(exercise.exercise) ? null : (exercise.exercise = exercise.exercise.id);
      });
    });
    await store.fteLibrary.saveFteWorkoutTemplate(data);
    setSaveModalVisible(false);
  }, [templateName]);

  const removeWorkout = () => {
    if (workoutTemplate) setWorkoutTemplate(undefined);
    setPrepare(null);
    setRecover(null);
    setPrepName('O2X Standard Prepare Step');
    setRecName('O2X Standard Recover Step');
    onRemoveWorkout();
  };

  const [allPrepare, setAllPrepare] = useState(false);
  const [allRecover, setAllRecover] = useState(false);

  useEffect(() => {
    if (allPrepare) {
      const workoutArr = workoutList;
      workoutArr.forEach((workout) => {
        workout.ftePrepare = prepare;
        workout.prepName = prepName;
      });
      setWorkoutList(workoutArr);
      setToRender(Math.random());
    }
    setAllPrepare(false);
  }, [allPrepare]);

  useEffect(() => {
    setPrepare(workout.ftePrepare);
    setPrepName(workout.prepName);
  }, [workout.ftePrepare, setPrepare]);

  useEffect(() => {
    if (allRecover) {
      const workoutArr = workoutList;
      workoutArr.forEach((workout) => {
        workout.fteRecover = recover;
        workout.recName = recName;
      });
      setWorkoutList(workoutArr);
      setToRender(Math.random());
    }
    setAllRecover(false);
  }, [allRecover]);

  useEffect(() => {
    setRecover(workout.fteRecover);
    setRecName(workout.recName);
  }, [workout.fteRecover, setRecover]);

  const setWorkoutIncluded = () => {
    const workoutCopy = workout;
    const workoutListCopy = [...workoutList!];
    if (workoutCopy) {
      workoutCopy.included = !workoutCopy.included;
    }
    workoutListCopy[workoutIndex + (workout.week - 1) * 7] = workoutCopy;
    setWorkoutList(workoutListCopy);
  };

  const handleNotesChange = useCallback(
    (text) => {
      setWorkoutList((prev: (FTESweatWorkout | SweatWorkout)[]) => {
        const workoutCopy = workout;
        const workoutListCopy = [...prev];
        const globalWorkoutIndex = prev.findIndex(
          (elem) => elem.day == workout.day && elem.week == workout.week,
        );
        workoutCopy.notes = text;
        workoutListCopy[globalWorkoutIndex] = workoutCopy;
        return workoutListCopy;
      });
    },
    [workout, workoutIndex],
  );

  return (
    <Layout key={workoutIndex} style={styles.container}>
      {!!saveModalVisible && (
        <SaveModalTemplate
          saveModalVisible={saveModalVisible}
          hideSaveModalVisible={() => setSaveModalVisible(false)}
          templateName={templateName}
          setTemplateName={setTemplateName}
          saveTemplate={saveTemplate}
        />
      )}
      {!!loadModalVisible && (
        <LoadModalTemplate
          loadModalVisible={loadModalVisible}
          content="workouts"
          hideLoadModalVisible={() => setLoadModalVisible(false)}
          setWorkoutTemplate={setWorkoutTemplate}
          setPrepare={setPrepare}
          setRecover={setRecover}
          setPrepName={setPrepName}
          setRecName={setRecName}
          store={store}
        />
      )}

      <Layout style={{ flexDirection: 'row' }}>
        <Text
          style={{
            fontSize: 15,
            letterSpacing: 0.5,
          }}>
          DAY {workout.day}
        </Text>
        <CheckBox
          checked={workout.included}
          onChange={setWorkoutIncluded}
          style={{ marginLeft: 8 }}>
          {() => <Text style={{ marginLeft: 3, fontSize: 12 }}>include workout</Text>}
        </CheckBox>
        <MorePopover
          isOptionsVisible={isOptionsVisible}
          renderToggleButton={renderToggleButton}
          setIsOptionsVisible={setIsOptionsVisible}
          removeItem={removeWorkout}
          saveItem={() => setSaveModalVisible(true)}
          loadItem={() => setLoadModalVisible(true)}
          content="workout"
        />
      </Layout>
      <Input
        size="large"
        multiline
        numberOfLines={2}
        value={workout.notes || ''}
        onChangeText={handleNotesChange}
        style={styles.notesInput}
        label="Notes"
      />
      <StepDetails
        content="program"
        workoutList={workoutList}
        stepList={workoutCopy.steps}
        workout={workoutCopy}
        workoutIndex={workoutList.indexOf(workout)}
        setWorkoutList={setWorkoutList}
        setPrepare={setPrepare}
        setRecover={setRecover}
        setPrepName={setPrepName}
        setRecName={setRecName}
        prepName={prepName}
        recName={recName}
        prepare={prepare}
        recover={recover}
        setAllPrepare={setAllPrepare}
        setAllRecover={setAllRecover}
        stepClipboard={stepClipboard}
        setStepClipboard={setStepClipboard}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'transparent',
    width: 325,
    marginRight: 20,
    marginBottom: 20,
  },
  notesInput: {
    marginTop: 10,
  },
});

export default React.memo(WorkoutItem, (prevProps, nextProps) => {
  if (
    prevProps.workoutJSON !== nextProps.workoutJSON ||
    prevProps.workoutList.length !== nextProps.workoutList.length ||
    prevProps.flag !== nextProps.flag ||
    prevProps.stepClipboard !== nextProps.stepClipboard
  ) {
    return false;
  }
  return true;
});
