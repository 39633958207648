import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import Eat from '../../assets/images/eat-icon.svg';
import Sweat from '../../assets/images/sweat-icon.svg';
import Thrive from '../../assets/images/thrive-icon.svg';

type Props = {
  style?: StyleProp<ViewStyle>;
  type: TASK_TYPE;
  width?: number;
  height?: number;
};

const IconMapping: { [key: string]: React.FC<SvgProps> } = {
  [TASK_TYPE.EAT]: Eat,
  [TASK_TYPE.SWEAT]: Sweat,
  [TASK_TYPE.THRIVE]: Thrive,
};

const TaskIcon: React.FC<Props> = (props) => {
  const { style, type, width = 24, height = 24 } = props;
  const Icon = IconMapping[type];
  return <Icon style={style} width={width} height={height} />;
};

export default TaskIcon;
