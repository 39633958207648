import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import UserSweatWorkoutExerciseProgress from './UserSweatWorkoutExerciseProgress';

@model('o2x-store/UserSweatWorkoutProgress')
export default class UserSweatWorkoutProgress extends Model({
  id: prop<number>(),
  user: prop<number | null>(null),
  workout: prop<number | null>(null),
  isComplete: prop<boolean>(false),
  dateCompleted: prop<string>(),
  time: prop<number | null>(null),
  notes: prop<string>(''),
  programProgress: prop<number | null>(null),
  userSweatExerciseProgress: prop<UserSweatWorkoutExerciseProgress[]>(),
}) {
  getRefId = () => {
    const ids = [];
    if (this.user) {
      ids.push(`${this.user}`);
    }
    if (this.workout) {
      ids.push(`${this.workout}`);
    }
    if (this.programProgress) {
      ids.push(`${this.programProgress}`);
    }

    return ids.join('-');
  };

  @modelAction
  update(data: ModelInstanceCreationData<UserSweatWorkoutProgress>) {
    Object.assign(this, data);
  }
}
