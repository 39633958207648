import { useNavigation } from '@react-navigation/native';
import { Layout, Spinner, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { isFinite } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Pause from '../../assets/images/pause.svg';
import Play from '../../assets/images/play.svg';
import Stop from '../../assets/images/stop.svg';
import { THRIVE_ACTIVITY } from '../../containers/ThriveSetTime';
import { useNativeStore } from '../../stores';
import { getTimeDisplay } from '../../utils/timeDisplay';
import ThriveStartSlider from './ThriveStartSlider';

type Props = {
  activity: THRIVE_ACTIVITY;
  addTime: number;
  isMeditate?: boolean;
  onTimerPlay?: Function;
  dontShowComplete?: boolean;
  useDownloaded?: boolean;
};

const ThriveStartTimer: React.FC<Props> = (props) => {
  const {
    activity,
    addTime,
    isMeditate = false,
    onTimerPlay,
    dontShowComplete,
    useDownloaded,
  } = props;
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { thriveStart } = useNativeStore();

  const openAddTime = useCallback(() => {
    if (thriveStart.guided) {
      return;
    }

    navigation.navigate('ThriveSetTime', { activity });
  }, [thriveStart.guided]);

  const onAddTime = useCallback(() => {
    // Add 15 minutes.
    thriveStart.addTime(addTime);
  }, [thriveStart, addTime]);

  const onStop = useCallback(() => {
    thriveStart.stop();
  }, [thriveStart]);

  const onPlay = useCallback(() => {
    thriveStart.play(useDownloaded);
    if (onTimerPlay) {
      onTimerPlay();
    }
  }, [thriveStart, onTimerPlay, useDownloaded]);

  const onPause = useCallback(() => {
    thriveStart.pause();
  }, [thriveStart]);

  let addTimeLabel: string;
  const addTimeMoment = moment.duration(addTime * 1000);
  if (addTimeMoment.hours() > 0) {
    addTimeLabel = `+${addTimeMoment.hours()}h`;
  } else if (addTimeMoment.minutes() > 0) {
    addTimeLabel = `+${addTimeMoment.minutes()}m`;
  } else {
    addTimeLabel = `+${addTimeMoment.seconds()}s`;
  }

  useEffect(() => {
    if (thriveStart.done) {
      thriveStart.done = false;
      if (!dontShowComplete) navigation.navigate('ThriveActivityComplete', { activity });
    }
  }, [thriveStart.done, dontShowComplete]);

  return (
    <Layout style={[styles.container, { paddingBottom: insets.bottom }]}>
      <ThriveStartSlider isHidden={!(isMeditate && (thriveStart.playing || thriveStart.paused))} />
      {isFinite(thriveStart.countDown) ? (
        <Layout style={styles.timerContainer}>
          <View style={styles.spacer} />
          <TouchableOpacity style={styles.timerButton} onPress={openAddTime}>
            <Text style={styles.timer}>{getTimeDisplay(thriveStart.countDown)}</Text>
          </TouchableOpacity>
          {thriveStart.guided ? (
            <View style={styles.spacer} />
          ) : (
            <TouchableOpacity style={styles.addTimeButton} onPress={onAddTime}>
              <Text>{addTimeLabel}</Text>
            </TouchableOpacity>
          )}
        </Layout>
      ) : (
        <Layout style={styles.timerContainer}>
          <TouchableOpacity style={styles.timerButton} onPress={openAddTime}>
            <Text style={styles.timer}>∞</Text>
          </TouchableOpacity>
        </Layout>
      )}
      {thriveStart.loading ? (
        <Layout style={styles.actionContainer}>
          <Layout style={styles.actionButton}>
            <Spinner />
          </Layout>
        </Layout>
      ) : (
        <Layout style={styles.actionContainer}>
          <TouchableOpacity style={styles.actionButton} onPress={onStop}>
            <Stop style={styles.actionIcon} />
          </TouchableOpacity>
          {thriveStart.playing ? (
            <TouchableOpacity style={styles.actionButton} onPress={onPause}>
              <Pause style={styles.actionIcon} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={[styles.actionButton, styles.playButton]} onPress={onPlay}>
              <Play style={styles.actionIcon} />
            </TouchableOpacity>
          )}
        </Layout>
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  actionButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'darker-blue',
  },
  actionContainer: {
    flexDirection: 'row',
    height: 48,
  },
  actionIcon: {
    height: 24,
    width: 24,
  },
  addTimeButton: {
    alignItems: 'center',
    padding: 8,
  },
  container: {
    alignSelf: 'stretch',
    width: '100%',
    backgroundColor: 'black',
  },
  playButton: {
    backgroundColor: 'olive',
  },
  spacer: {
    width: 62.5,
  },
  timer: {
    fontSize: 56,
    lineHeight: 67,
    textAlign: 'center',
  },
  timerButton: {
    flex: 1,
  },
  timerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    backgroundColor: 'black',
  },
});

export default observer(ThriveStartTimer);
