import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { get, join } from 'lodash';
import { getSnapshot } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import { UserThriveProfile } from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import CheckBox from '../../components/CheckBox';
import BottomNav from '../../components/Question/BottomNav';
import ProgressBar from '../../components/Question/ProgressBar';
import TopNav from '../../components/Question/TopNav';
import ScoreBadge from '../../components/ScoreBadge';

type Props = {
  route?: any | null;
};

const ThriveProfileSummary: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const [thriveProfile, setThriveProfile] = useState<UserThriveProfile | null>(null);
  const defaultMonitorFields = [
    'hoursSleep',
    'qualitySleep',
    'stressLevel',
    'skillsToManageStress',
    'goalsAchieved',
    'purposeLevel',
  ];
  const [answers, setAnswers] = useState<Array<string>>(defaultMonitorFields);
  const numBars = store.question.thriveProfileQuestions.size;

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    const { thriveProfile } = store.user;
    if (thriveProfile) {
      setThriveProfile(thriveProfile);
      setAnswers(
        (thriveProfile?.monitorFields || []).length > 0
          ? thriveProfile?.monitorFields
          : defaultMonitorFields,
      );
    }
  }, [thriveProfile, store.question.thriveProfileQuestions]);

  const onNext = useCallback(() => {
    store.user.updateThriveProfile({
      ...getSnapshot(thriveProfile),
      id: thriveProfile ? thriveProfile.id : null,
      monitorFields: answers,
    });
    navigation.popToTop();
  }, [answers, store]);

  const onChange = useCallback(
    (key: string, value: boolean) => {
      let updatedAnswers: string[] = answers;
      if (value) {
        updatedAnswers = [...answers, key];
      } else {
        updatedAnswers = answers.filter((a) => a !== key);
      }
      setAnswers(updatedAnswers);
    },
    [answers, setAnswers],
  );

  const goBack = useCallback(() => {
    navigation.popToTop();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose onClose={goBack} />
            </View>
            <View
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <Text style={styles.title}>Great! Choose which statistics to track</Text>
              <Text style={styles.description}>
                We will check in on you daily to monitor the selected items
              </Text>
              <ScrollView style={styles.listContainer}>
                {thriveProfile && (
                  <View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="hoursSleep"
                        fill={theme.orange}
                        checked={answers.indexOf('hoursSleep') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('hoursSleep', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Hours of Sleep</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{thriveProfile.hoursSleep}</Text>
                            <Text style={styles.fieldUnit}> hours a day</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'hoursSleep')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="qualitySleep"
                        fill={theme.orange}
                        checked={answers.indexOf('qualitySleep') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('qualitySleep', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Quality of Sleep</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{thriveProfile.qualitySleep}</Text>
                            <Text style={styles.fieldUnit}> level</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'qualitySleep')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="stressLevel"
                        fill={theme.orange}
                        checked={answers.indexOf('stressLevel') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('stressLevel', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Stress Level</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{thriveProfile.stressLevel}</Text>
                            <Text style={styles.fieldUnit}> level</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'stressLevel')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="skillsToManageStress"
                        fill={theme.orange}
                        checked={answers.indexOf('skillsToManageStress') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('skillsToManageStress', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Skills to Manage Stress</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>
                              {join(thriveProfile.skillsToManageStressDisplay, ', ')}
                            </Text>
                            <Text style={styles.fieldUnit} />
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'skillsToManageStress')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="goalsAchieved"
                        fill={theme.orange}
                        checked={answers.indexOf('goalsAchieved') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('goalsAchieved', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Goals Achieved</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{thriveProfile.goalsAchieved}</Text>
                            <Text style={styles.fieldUnit} />
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'goalsAchieved')} />
                      </View>
                    </View>
                    <View style={styles.inputView}>
                      <CheckBox
                        key="purposeLevel"
                        fill={theme.orange}
                        checked={answers.indexOf('purposeLevel') !== -1}
                        onChange={(key: string, checked: boolean) => {
                          onChange('purposeLevel', checked);
                        }}
                        style={styles.input}
                      />
                      <View style={styles.inputLabel}>
                        <View style={styles.inputText}>
                          <Text style={styles.fieldLabel}>Purpose Level</Text>
                          <View style={styles.fieldValue}>
                            <Text style={styles.fieldNumber}>{thriveProfile.purposeLevel}</Text>
                            <Text style={styles.fieldUnit}> level</Text>
                          </View>
                        </View>
                        <ScoreBadge type={get(thriveProfile.score, 'purposeLevel')} />
                      </View>
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>
            <View>
              <ProgressBar numBars={numBars} currentBar={numBars} />
              <BottomNav
                showBack={false}
                onNext={onNext}
                nextLabel="Finish"
                nextColor={theme.olive}
              />
            </View>
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    paddingHorizontal: '25%',
    marginTop: -20,
    flex: 1,
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    flex: 1,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    flex: 1,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 25,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 25,
  },
  input: {
    paddingTop: 20,
  },
  inputView: {
    flexDirection: 'row',
    paddingBottom: 9,
  },
  inputLabel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'dark-blue',
    marginLeft: 18,
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: 9,
    justifyContent: 'space-between',
  },
  fieldLabel: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: 'white',
  },
  fieldValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  fieldNumber: {
    fontSize: 24,
    color: 'white',
  },
  fieldUnit: {
    fontSize: 10,
    lineHeight: 20,
    color: 'blue-secondary',
    textTransform: 'uppercase',
    marginLeft: 8,
  },
  listContainer: {
    flex: 1,
  },
});

export default observer(ThriveProfileSummary);
