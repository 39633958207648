import { observable } from 'mobx';
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  ModelInstanceCreationData,
  prop,
  _async,
  _await,
} from 'mobx-keystone';
import * as api from '../services/api';
import { RootStore } from '../stores';
import { getError, getSuccess } from '../utils/models';
import { QUESTION_TYPES } from '../utils/questions';

type OPTIONS = {
  id: number;
  item: number;
  label: string;
  value: number;
};

export type FormAssessmentItemExtraData = {
  [key: string]: string | number;
};

@model('o2x-store/FormAssessmentItem')
export default class FormAssessmentItem extends Model({
  id: prop<number>(),
  assessment: prop<number>(),
  section: prop<string>(),
  category: prop<string>(),
  question: prop<string>(),
  questionType: prop<QUESTION_TYPES>(),
  options: prop<OPTIONS[]>(),
  isRequired: prop<boolean>(true),
  extraData: prop<FormAssessmentItemExtraData>(),
}) {
  getRefId = () => `${this.id}`;

  @observable
  loading = false;

  @modelAction
  update(data: ModelInstanceCreationData<FormAssessmentItem>) {
    Object.assign(this, data);
  }

  @modelFlow
  answer = _async(function* (
    this: FormAssessmentItem,
    formAssessmentSubmissionId: number,
    value: string,
  ) {
    const rootStore = getRoot<RootStore>(this);

    if (!rootStore.auth?.token) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(
        api.answerFormAssessmentItem(
          rootStore.auth.token,
          this.assessment,
          this.id,
          formAssessmentSubmissionId,
          value,
        ),
      );
    } catch (error) {
      console.warn('[DEBUG] error answering a Form assessment item', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
