import { CheckBox, Input, Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { FTESweatWorkoutStepExerciseSet } from 'o2x-store/src/models/FTELibrary';
import { SweatGlobalStepExerciseSet } from 'o2x-store/src/models/SweatGlobalStepExercise';
import React from 'react';

type Props = {
  setIndex: number;
  index: number;
  param: string;
  set: SweatGlobalStepExerciseSet | FTESweatWorkoutStepExerciseSet;
  onChangeInput: Function;
  setData: string;
};

const paramsSelect: { [key: string]: string } = {
  reps: 'Reps',
  isRepsEachSide: 'Each Side?',
  weight: 'Weight',
  distance: 'Distance',
  time: 'Time (MM:SS)',
  rest: 'Rest (MM:SS)',
  rpe: 'RPE',
};

const ExerciseSetItem: React.FC<Props> = (props) => {
  const { index, param, set, onChangeInput, setIndex } = props;
  const styles = useStyleSheet(themedStyles);

  const getParamKey = (value: string) =>
    Object.keys(paramsSelect).find((key) => paramsSelect[key] === value)!;

  return param !== 'Each Side?' ? (
    <Input
      key={index}
      style={styles.paramInput}
      value={set[getParamKey(param)]}
      onChangeText={(text) => onChangeInput(getParamKey(param), text, setIndex)}
    />
  ) : (
    <Layout style={styles.paramInput}>
      <CheckBox
        key={index}
        style={{ alignSelf: 'center', marginTop: 'auto', marginBottom: 'auto' }}
        checked={set[getParamKey(param)]}
        onChange={(status) => onChangeInput(getParamKey(param), status, setIndex)}
      />
    </Layout>
  );
};

const themedStyles = StyleService.create({
  paramInput: {
    width: 50,
    borderColor: 'transparent',
    borderRadius: 0,
    borderBottomColor: 'white',
    backgroundColor: 'transparent',
    marginRight: 20,
  },
});

export default React.memo(ExerciseSetItem, (prevProps, nextProps) => {
  if (prevProps.setData !== nextProps.setData) return false;
  return true;
});
