// https://apps.timwhitlock.info/emoji/tables/unicode

export enum EMOJI_NAMES {
  FISTED_HAND_SIGN = 'fistedHandSign',
  SMILING_FACE_WITH_SMILING_EYES = 'smilingFaceWithSmilingEyes',
  CRYING_FACE = 'cryingFace',
  ASTONISHED_FACE = 'astonishedFace',
  THUMBS_UP = 'thumbsUp',
  THUMBS_DOWN = 'thumbsDown',
  HEAVY_BLACK_HEART = 'heavyBlackHeart',
}

export const EMOJI_NAME_TO_UNICODE = {
  [EMOJI_NAMES.FISTED_HAND_SIGN]: '\u{1F44A}',
  [EMOJI_NAMES.SMILING_FACE_WITH_SMILING_EYES]: '\u{1F604}',
  [EMOJI_NAMES.CRYING_FACE]: '\u{1F622}',
  [EMOJI_NAMES.ASTONISHED_FACE]: '\u{1F632}',
  [EMOJI_NAMES.THUMBS_UP]: '\u{1F44D}',
  [EMOJI_NAMES.THUMBS_DOWN]: '\u{1F44E}',
  [EMOJI_NAMES.HEAVY_BLACK_HEART]: '\u{2764}',
};
