import { createStackNavigator } from '@react-navigation/stack';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React from 'react';
import ActiveList from 'src/containers/ActiveList';
import Explore from 'src/containers/Explore';
import ExploreFilter from 'src/containers/ExploreFilter';

export type ExploreFilterModalParamList = {
  ExploreFilter: { type: TASK_TYPE };
};

type Props = {};

const Stack = createStackNavigator();

const ExploreStackModal: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
    }}
    headerMode="none"
    mode="modal"
  >
    <Stack.Screen name="Explore" component={Explore} />
    <Stack.Screen name="ExploreFilter" component={ExploreFilter} />
    <Stack.Screen name="ActiveList" component={ActiveList} />
  </Stack.Navigator>
);

export default ExploreStackModal;
