import { Icon, Layout, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsSubscribed } from 'src/hooks/subscription';
import { Props, themedStyles } from '.';

const SleepItem: React.FC<Props> = (props) => {
  const { task, showIcon = false, showEllipses = false } = props;
  const styles = useStyleSheet(themedStyles);
  const { navigateWithPayWall } = useIsSubscribed();

  const onPress = useCallback(() => {
    navigateWithPayWall('ThriveSleep');
  }, [navigateWithPayWall]);

  return (
    <TouchableOpacity onPress={onPress}>
      <Layout
        style={[
          styles.container,
          { borderLeftWidth: 4, borderColor: task.color, borderStyle: 'solid' },
        ]}
        level="2"
      >
        <Layout style={styles.contentContainer} level="2">
          <Layout style={styles.imageWrapper}>
            <Image
              style={styles.image as StyleProp<ImageStyle>}
              source={require('../../assets/images/sleep_thumbnail.png')}
            />
          </Layout>
          <View>
            <Text style={styles.titleWeb}>{task.title}</Text>
            {!!task.subtitle && (
              <Text style={[styles.subtitleWeb, { color: task.color }]}>
                {task.subtitle}
              </Text>
            )}
          </View>
          {showEllipses && (
            <Icon style={styles.icon} fill="#FFFFFF" name="more-horizontal" />
          )}
        </Layout>
      </Layout>
    </TouchableOpacity>
  );
};

export default observer(SleepItem);
