import {
  RouteProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {
  Layout,
  Spinner,
  StyleService,
  Text,
  useStyleSheet,
  useTheme,
} from '@ui-kitten/components';
import { isNull, isUndefined, size } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import config from 'o2x-store/src/config';
import {
  ASSESSMENT_TYPE,
  FORM_ASSESSMENT_SCORING_TYPE,
} from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import { Alert, FlatList, Linking, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import NavigationBar from '../../components/NavigationBar';
import BottomNav from '../../components/Question/BottomNav';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentSubmission'>;
};

const AssessmentSubmission: React.FC<Props> = (props) => {
  const { assessmentId, submissionId, type, maxScore } = props.route.params;
  const { assessment: assessmentStore } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);
  const submissionHistory = assessment?.history.find(
    (s) => s.id === submissionId,
  );
  const submission = assessmentStore.getSubmission(submissionId, type);

  const navigation = useNavigation();
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState();

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!submission) {
          setLoading(true);
          if (type === ASSESSMENT_TYPE.FORM) {
            await assessmentStore.fetchFormAssessmentSubmission(submissionId);
          } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
            await assessmentStore.fetchPhysicalAssessmentSubmission(
              submissionId,
            );
          }
          setLoading(false);
        } else if (size(submission?.items) == 0) {
          setLoading(true);
          if (type === ASSESSMENT_TYPE.FORM) {
            await assessmentStore.fetchFormAssessmentSubmission(submissionId);
          } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
            await assessmentStore.fetchPhysicalAssessmentSubmission(
              submissionId,
            );
          }
          setLoading(false);
        }
      })();
    }, [submission, submissionId, type, setLoading]),
  );

  const onDelete = useCallback(async () => {
    if (type === ASSESSMENT_TYPE.FORM) {
      const result = await assessmentStore.deleteFormAssessmentSubmission(
        submissionId,
      );
      if (!result.ok) {
        setErrorMessage(result['errors']['detail']);
      } else {
        navigation.navigate('AssessmentHistory', { assessmentId, type });
      }
    } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
      const result = await assessmentStore.deletePhysicalAssessmentSubmission(
        submissionId,
      );
      if (!result.ok) {
        setErrorMessage(result['errors']['detail']);
      } else {
        navigation.navigate('AssessmentHistory', { assessmentId, type });
      }
    }
  }, [assessmentId, submissionId, type, setErrorMessage]);

  const onDeleteConfirm = useCallback(() => {
    Alert.alert(
      'Delete attempt?',
      'Are you sure you want to delete this submission?',
      [
        { text: 'Cancel', style: 'cancel' },
        { text: 'Delete Submission', onPress: onDelete },
      ],
    );
  }, [onDelete]);

  const onContinue = useCallback(() => {
    if (assessment) {
      assessment.setCurrent(submissionId);
      if (assessment?.showPreassessmentForm) {
        navigation.navigate('PreassessmentForm', { type, assessmentId });
      } else if (
        assessment instanceof FormAssessment &&
        assessment?.displayPerSection
      ) {
        navigation.navigate('AssessmentSectionMenu', { assessmentId });
      } else {
        if (type === ASSESSMENT_TYPE.FORM) {
          navigation.navigate('FormAssessmentDetail', { assessmentId });
        } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
          navigation.navigate('PhysicalAssessmentDetail', { assessmentId });
        } else {
          console.warn('[DEBUG] assessment type is not yet handled', type);
        }
      }
    }
  }, [assessmentId, submissionId, type, assessment]);

  const renderSubmissionItem = useCallback(
    ({ item }) => (
      <View style={styles.submissionItem}>
        <View style={styles.submissionScore}>
          <Text>{item.itemDisplay}</Text>
          <Text numberOfLines={1} style={styles.submissionValue}>
            {item.valueDisplay}
          </Text>
        </View>
      </View>
    ),
    [],
  );

  const onDownload = useCallback(async () => {
    let url = `${config.urls.api}${config.urls.formAssessmentDownload}${submissionId}`;
    // window.open(
    //   `${config.urls.api}${config.urls.formAssessmentDownload}${submissionId}`,
    // );
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.warn('[DEBUG] Cannot open URI: ' + url);
      }
    });
  }, [submissionId]);

  if (!submission) {
    return null;
  }

  return (
    <Layout style={styles.container}>
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar />
      </Layout>
      <View style={styles.content}>
        <Text style={styles.header} category="h2">
          {`${moment(submission.created).format('D MMMM YYYY')} Attempt`}
        </Text>
        <View style={styles.score}>
          {!!submission.badge && (
            <Text style={styles.badgeText} category="h3">
              {submission.badge}
            </Text>
          )}
          <Text style={styles.scoreText}>
            {!(isNull(submission.score) || isUndefined(submission.score))
              ? `${submission.score.toFixed(1)} / ${
                  !(isNull(maxScore) || isUndefined(maxScore))
                    ? maxScore.toFixed(1)
                    : 'N/A'
                }`
              : 'Incomplete'}
          </Text>
        </View>
        {assessment?.scoringType ===
          FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_READINESS &&
          !(isNull(submission.score) || isUndefined(submission.score)) && (
            <TouchableOpacity
              style={styles.downloadButton}
              onPress={onDownload}
            >
              <Text style={{ alignSelf: 'center' }}>View PDF</Text>
            </TouchableOpacity>
          )}

        {!!errorMessage && (
          <Text style={styles.error} category="label">
            {errorMessage}
          </Text>
        )}
        {loading ? (
          <View style={styles.loading}>
            <Spinner />
          </View>
        ) : (
          <FlatList
            data={submission.items}
            renderItem={renderSubmissionItem}
            keyExtractor={(item) => `${item.id}`}
            style={styles.submissionItemList}
          />
        )}
      </View>
      {!(isNull(submission.score) || isUndefined(submission.score)) ||
      assessment?.scoringType !==
        FORM_ASSESSMENT_SCORING_TYPE.CUSTOM_READINESS ? (
        <BottomNav
          showBack={true}
          backLabel="Cancel"
          nextLabel="Delete"
          onNext={onDeleteConfirm}
          showNext={true}
          nextColor={theme['danger']}
        />
      ) : (
        <BottomNav
          showBack={true}
          backLabel="Continue"
          nextLabel="Delete"
          onBack={onContinue}
          onNext={onDeleteConfirm}
          showNext={true}
          nextColor={theme['danger']}
        />
      )}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 24,
    paddingTop: 0,
  },
  header: {
    marginBottom: 10,
  },
  score: {
    flexDirection: 'row',
  },
  badgeText: {
    backgroundColor: 'olive',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'center',
    marginRight: 12,
  },
  scoreText: {
    fontSize: 18,
    lineHeight: 22,
  },
  error: {
    color: 'danger',
    marginBottom: 16,
    marginLeft: 24,
  },
  submissionItemList: {
    marginTop: 20,
    marginHorizontal: -24,
  },
  submissionItem: {
    backgroundColor: 'darker-blue',
    padding: 10,
    marginBottom: 10,
    marginHorizontal: 24,
  },
  submissionScore: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submissionValue: {
    flex: 1,
    textAlign: 'right',
    fontSize: 14,
    marginTop: 5,
    color: 'blue-secondary',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  downloadButton: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: 'white',
    width: '33%',
    padding: 5,
  },
});

export default observer(AssessmentSubmission);
