import { Layout, StyleService, useStyleSheet } from '@ui-kitten/components';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React from 'react';
import ListTab from '../Saved/ListTab';

type Props = {
  selected: TASK_TYPE | string;
  onSelect: (type: TASK_TYPE | string) => void;
  height?: number;
};

const ListBar: React.FC<Props> = (props) => {
  const { selected, onSelect, height = 52 } = props;
  const styles = useStyleSheet(themedStyles);
  const taskTypes = [TASK_TYPE.EAT, TASK_TYPE.SWEAT, TASK_TYPE.THRIVE];

  return (
    <Layout style={[styles.container, { height }, ,]}>
      {taskTypes.map((type) => (
        <ListTab key={type} type={type} selected={type === selected} onSelect={onSelect} />
      ))}
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  containerWithBackground: {
    backgroundColor: 'darker-blue',
  },
});

export default ListBar;
