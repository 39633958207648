import React, { Fragment, Ref, useCallback, useState } from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  Text,
  TouchableOpacity,
  NativeScrollEvent,
} from 'react-native';
import { Icon, Input, Layout, Spinner, StyleService, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'react-responsive';
import Message from './Message';
import UploadIcon from './UploadIcon';
import { useStore } from '../../../o2x-store/src/stores';

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
  onSubmit: (data: any) => void;
  setShowConversation: React.Dispatch<React.SetStateAction<boolean>>;
  scrollViewRef: Ref<ScrollView>;
};

const Conversation: React.FC<Props> = ({
  onSubmit,
  setShowConversation,
  setSelected,
  scrollViewRef,
}) => {
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });
  const authUserId = store.auth.user?.id;

  const [message, setMessage] = useState<{ body: string; files: File[] }>({
    body: '',
    files: [],
  });
  // const [messageInputNumberOfLines, setMessageInputNumberOfLines] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const loadMore = async () => {
    setLoadingMore(true);
    await store.contactPortal.fetchConversation(true);
    setLoadingMore(false);
  };

  const deleteSelectedFile = (targetIndex: number) => {
    setMessage((prevState) => ({
      ...prevState,
      files: prevState.files.filter((file, index) => index !== targetIndex),
    }));
  };

  const onChangeSelectedFiles = (newFiles: FileList | null) => {
    setMessage((prevState) => ({
      ...prevState,
      files: prevState.files.concat(newFiles ? Array.from(newFiles) : []),
    }));
  };

  const onSendMessage = useCallback(async () => {
    if (!message.body && message.files.length == 0) return;
    const data = {
      body: message.body,
      files: message.files,
      sender: authUserId as number,
      receiver:
        store.contactPortal.currentUserMessage.receiver === authUserId
          ? store.contactPortal.currentUserMessage.sender
          : store.contactPortal.currentUserMessage.receiver,
      group: store.contactPortal.currentUserMessage.group,
    };
    setMessage({
      body: '',
      files: [],
    });
    // setMessageInputNumberOfLines(1);
    onSubmit(data);
  }, [message, store.contactPortal.currentUserMessage, authUserId]);

  const isCloseToTop = ({ contentOffset, contentSize }: NativeScrollEvent) =>
    contentOffset.y / contentSize.width < 0.2;

  const renderSend = () => (
    <Layout style={{ flexDirection: 'row' }}>
      <UploadIcon
        id="message-upload-attach"
        iconName="attach-2-outline"
        onChange={onChangeSelectedFiles}
      />
      <Layout style={styles.verticalDivider} />
      <TouchableOpacity onPress={onSendMessage}>
        <Icon style={styles.send} name="paper-plane-outline" fill="white" />
      </TouchableOpacity>
    </Layout>
  );

  return (
    <Layout style={isMobile ? styles.mobileConversationContainer : styles.conversationContainer}>
      <Layout style={styles.headerStyle}>
        <Image
          style={styles.profileHeader as StyleProp<ImageStyle>}
          source={
            store.contactPortal.selectedUserMessageProfileImage.length > 0
              ? { uri: store.contactPortal.selectedUserMessageProfileImage }
              : require('../../assets/images/user_placeholder.png')
          }
        />
        <Text style={styles.textHeader}>{store.contactPortal.selectedUserMessageUsername}</Text>
        {isMobile && (
          <TouchableOpacity
            style={styles.close}
            onPress={() => {
              setSelected(undefined);
              setShowConversation(false);
            }}>
            <Icon name="close-outline" fill="white" style={{ height: 20, width: 20 }} />
          </TouchableOpacity>
        )}
      </Layout>
      <ScrollView
        ref={scrollViewRef}
        style={isMobile ? styles.mobileConversation : styles.conversation}
        onScroll={({ nativeEvent }) => {
          if (isCloseToTop(nativeEvent)) {
            (async () => {
              await loadMore();
            })();
          }
        }}
        scrollEventThrottle={400}>
        {loadingMore && (
          <Layout style={styles.loadMore}>
            <Spinner />
          </Layout>
        )}
        {store.contactPortal.conversation.length > 0 ? (
          store.contactPortal.conversation
            .map((conversation, index) => (
              <Fragment key={index}>
                {conversation.body && <Message key={conversation.id} message={conversation} />}
                {conversation.files.map((file) => (
                  <Message
                    key={`${conversation.id}-${file.id}`}
                    message={conversation}
                    file={file}
                  />
                ))}
              </Fragment>
            ))
            .reverse()
        ) : (
          <Text style={styles.emptyConversationText}>NO MESSAGES</Text>
        )}
      </ScrollView>
      <Layout style={styles.messageInput}>
        <Input
          style={styles.input}
          multiline
          numberOfLines={5}
          // numberOfLines={Math.min(5, messageInputNumberOfLines)}
          value={message.body}
          onChangeText={(text) => {
            // !text && setMessageInputNumberOfLines(1);
            setMessage((prevState) => ({ ...prevState, body: text }));
          }}
          accessoryRight={renderSend}
          // onContentSizeChange={(e) => {
          //   setMessageInputNumberOfLines(e.nativeEvent.contentSize.height / 15);
          // }}
        />
        {message.files.length > 0 && (
          <ScrollView style={styles.filesContainer}>
            {message.files.map((file, index) => (
              <Layout style={styles.file} key={index}>
                <Text style={styles.fileName}> {file.name} </Text>
                <TouchableOpacity onPress={() => deleteSelectedFile(index)}>
                  <Icon style={styles.removeFileIcon} name="close-outline" fill="white" />
                </TouchableOpacity>
              </Layout>
            ))}
          </ScrollView>
        )}
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  conversationContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    height: '100%',
  },
  headerStyle: {
    flexDirection: 'row',
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    height: 60,
    borderBottom: '1px solid black',
    backgroundColor: 'transparent',
  },
  profileHeader: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginTop: 10,
  },
  messageInput: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '2%',
    marginTop: '2%',
    backgroundColor: 'transparent',
  },
  input: {
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  textHeader: {
    lineHeight: 17,
    letterSpacing: 0.05,
    color: 'white',
    fontSize: 18,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 10,
  },
  conversation: {
    width: '90%',
    maxHeight: '80%',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: 10,
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  mobileConversation: {
    width: '90%',
    maxHeight: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: 10,
    backgroundColor: 'transparent',
    borderBottom: '1px solid black',
  },
  senderMessage: {
    marginLeft: 'auto',
    marginBottom: '2%',
    color: 'white',
    padding: 10,
    maxWidth: '80%',
    backgroundColor: '#091C2D',
  },
  messageContainer: {
    flexDirection: 'column',
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  receiverMessage: {
    marginRight: 'auto',
    marginBottom: '2%',
    color: 'white',
    padding: 10,
    maxWidth: '80%',
    backgroundColor: 'olive',
  },
  loadMore: {
    margin: 'auto',
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  receiverDate: {
    textAlign: 'left',
    marginTop: '2%',
    marginBottom: 2,
    color: '#8F9BB3',
    fontSize: 9,
  },
  senderDate: {
    textAlign: 'right',
    marginTop: '2%',
    marginBottom: 2,
    color: '#8F9BB3',
    fontSize: 9,
  },
  emptyConversationText: {
    alignSelf: 'center',
    marginTop: 20,
    fontSize: 16,
    color: 'white',
    opacity: 0.5,
  },
  mobileConversationContainer: {
    backgroundColor: 'transparent',
    flexDirection: 'column',
    height: '100%',
  },
  close: {
    marginLeft: 'auto',
    marginRight: 10,
    alignSelf: 'center',
  },

  send: {
    width: 20,
    height: 20,
    padding: 5,
    backgroundColor: 'olive',
  },
  linkStyle: {
    margin: 0,
    padding: 0,
    fontSize: 16,
    color: '#ADD8E6',
  },
  filesContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: 4,
    maxHeight: 100,
  },
  file: {
    flexDirection: 'row',
    padding: 1,
    borderWidth: 1,
    borderColor: 'olive',
    borderRadius: 5,
    width: 'auto',
    alignSelf: 'flex-start',
  },
  removeFileIcon: {
    width: 12,
    height: 12,
    padding: 1,
    backgroundColor: 'transparent',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  fileName: {
    color: 'white',
  },
  verticalDivider: {
    width: 1,
    height: 30,
    marginHorizontal: 8,
    backgroundColor: 'olive',
  },
});

export default observer(Conversation);
