import {
  Divider,
  Icon,
  Input,
  Layout,
  Modal,
  Spinner,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { OSSGroup } from 'o2x-store/src/models/FTELibrary';
import { MessageModel } from 'o2x-store/src/models/Message';
import User from 'o2x-store/src/models/User';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dimensions, TouchableOpacity } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import SelectUserList from 'src/components/FTE/SelectUserList';

type Props = {
  visible: boolean;
  onBackdropPress: () => void;
  onSubmit: (data: MessageModel) => void;
};

const { width, height } = Dimensions.get('window');

const MemberListModal: React.FC<Props> = (props) => {
  const store = useStore();
  const styles = useStyleSheet(themedStyles);
  const { visible, onBackdropPress, onSubmit } = props;
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState('');
  const [groupReceiver, setGroupReceiver] = useState<number[]>([store.auth.user?.id!]);
  const { control, handleSubmit, reset } = useForm();
  const [selectedGroup, setSelectedGroup] = useState<OSSGroup>();
  const [groupName, setGroupName] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        store.fteLibrary.fetchFTEOrganization(),
        store.fteLibrary.fetchOSSGroups(),
      ]);
      setLoading(false);
    })();
  }, []);

  const onSendMessage = useCallback(
    async (data) => {
      reset();
      data.sender = store.auth.user?.id;
      if (groupReceiver.length > 2) {
        const group = await store.contact.createGroupConversation({
          oss: store.auth.user?.id,
          groupName,
          users: groupReceiver,
        });
        data.group = group.extra?.id;
        data.groupUsers = groupReceiver;
        onSubmit(data);
      } else if (groupReceiver.length === 2) {
        data.receiver = groupReceiver[1];
        onSubmit(data);
      }
      setGroupName('');
      setGroupReceiver([]);
    },
    [groupReceiver, groupName],
  );

  const onSendMessageWrapped = handleSubmit(onSendMessage);

  const renderSend = useCallback(
    () => (
      <TouchableOpacity onPress={onSendMessageWrapped} disabled={groupReceiver.length === 0}>
        <Icon style={styles.send} name="paper-plane-outline" fill="white" />
      </TouchableOpacity>
    ),
    [groupReceiver, groupName],
  );

  const onCheckUser = useCallback((checked: boolean, user: User) => {
    if (checked) {
      setGroupReceiver((prevState) => [...prevState, user.id]);
    } else {
      setGroupReceiver((prevState) => prevState.filter((id) => id !== user.id));
    }
  }, []);

  const onCheckOrg = useCallback((checked: boolean, users: User[]) => {
    if (checked) {
      setGroupReceiver((prevState) =>
        [...prevState, ...users.map((user) => user.id)].filter(
          (id, index, self) => self.indexOf(id) === index,
        ),
      );
    } else {
      setGroupReceiver((prevState) => {
        const ids = users.map((user) => user.id).filter((id) => id !== store.auth.user?.id);
        return prevState.filter((id) => !ids.includes(id));
      });
    }
  }, []);

  const isMobile = useMediaQuery({
    maxDeviceWidth: 850,
  });

  return (
    <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={onBackdropPress}>
      <Layout style={isMobile ? [styles.modal, { width: 330 }] : styles.modal}>
        {groupReceiver.length > 2 && (
          <Input
            size="medium"
            style={styles.chatName}
            onChangeText={(text) => setGroupName(text)}
            value={groupName || ''}
            placeholder="Chat Name"
          />
        )}
        <Input
          size="small"
          style={styles.searchInput}
          onChangeText={(text) => setQuery(text)}
          placeholder="Search user"
          accessoryRight={() => <Icon style={styles.icon} name="search-outline" fill="#4E7B89" />}
        />
        <Divider style={styles.divider} />
        {loading ? (
          <Layout style={styles.loading}>
            <Spinner />
          </Layout>
        ) : (
          <SelectUserList
            fteOrganizations={store.fteLibrary.fteOrganization}
            store={store}
            query={query}
            groups={store.fteLibrary.ossGroups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            onCheckOrg={onCheckOrg}
            onCheckUser={onCheckUser}
            addedUsers={groupReceiver}
          />
        )}
        <Controller
          name="body"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <Input
              style={styles.input}
              multiline
              numberOfLines={4}
              {...props}
              accessoryRight={renderSend}
            />
          )}
        />
      </Layout>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
  modal: {
    width: width / 2.5,
    height: height / 2,
    paddingTop: 30,
    backgroundColor: '#1A3248',
  },
  searchInput: {
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  divider: {
    marginBottom: 10,
    marginLeft: '5%',
    width: '50%',
    backgroundColor: 'gray',
  },
  input: {
    marginTop: 10,
    marginLeft: '5%',
    marginBottom: 30,
    width: '90%',
    height: 52,
    backgroundColor: '#0B1F35',
    color: 'white',
  },
  icon: {
    height: 20,
    width: 16,
  },
  send: {
    width: 20,
    height: 20,
    padding: 5,
    backgroundColor: 'olive',
  },
  chatName: {
    marginLeft: '5%',
    width: '50%',
    marginBottom: 10,
    backgroundColor: 'transparent',
  },
  loading: {
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
});

export default observer(MemberListModal);
