import { useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect } from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useIsSubscribed } from 'src/hooks/subscription';
import BottomNav from '../../components/Question/BottomNav';
import TopNav from '../../components/Question/TopNav';

type Props = {};

const EatHabit: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const { navigateWithPayWall } = useIsSubscribed();

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  const isMobile = useMediaQuery({
    maxDeviceWidth: 480,
  });

  useEffect(() => {
    store.user.fetchDailyEatHabit();
    store.question.fetchDailyEatHabitQuestions();
  }, []);

  const onBegin = useCallback(() => {
    navigateWithPayWall('EatHabitQuestion', { index: 0 });
  }, [navigateWithPayWall]);

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout
          style={
            isMobile
              ? styles.modalMobile
              : isDeviceMaxWidth600
              ? styles.modalMaxWidth600
              : styles.modal
          }>
          <Layout style={styles.container}>
            <View style={styles.navigationContainer}>
              <TopNav showBack={false} showClose onClose={goBack} />
            </View>
            <Layout
              style={
                isMobile
                  ? styles.contentContainerMobile
                  : isDeviceMaxWidth600
                  ? styles.contentContainerMaxWidth600
                  : styles.contentContainer
              }>
              <Text style={styles.title}>Record your EAT habits</Text>
              <Text style={styles.description}>
                Track your daily intake to maximize your performance.
              </Text>
              <Text style={styles.description}>
                We will start with a few questions to establish your baseline.
              </Text>
            </Layout>
            <BottomNav
              onBack={() => navigation.goBack()}
              onNext={onBegin}
              nextLabel="Begin"
              nextColor={theme.olive}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMobile: {
    width: '90%',
    height: '90%',
  },
  modalMaxWidth600: {
    width: '80%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    ppaddingHorizontal: 15,
    paddingVertical: 12,
  },
  contentContainer: {
    padding: 20,
    paddingHorizontal: '25%',
  },
  contentContainerMobile: {
    paddingHorizontal: '5%',
    padding: 20,
  },
  contentContainerMaxWidth600: {
    paddingHorizontal: '15%',
    padding: 20,
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 32,
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
});

export default observer(EatHabit);
