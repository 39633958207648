import {
  Layout,
  Modal,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

type Props = {
  task: TASK_TYPE;
};

const topics: { [key: string]: { [key: string]: string } } = {
  eat: {
    quality:
      "Focusing on quality refers to ensuring that you're eating balanced, nutrient dense meals that include protein, carbohydrates, and healthy fats. Think of this as putting premium fuel in your body. ",
    quantity:
      'Quantity refers to the volume of food you consume. Aim to eat enough to feel satisfied but not overly full and base your intake on your daily acitvity level. Fill your tank with enough fuel to perform optimally.',
    timing:
      'Meal timing helps you stay fueled and maintain stable blood sugar throughout the day. Just like quantity, your meal timing should also take your acitivity level and training session times into consideration.',
    h2o:
      'Hydration is critical to maintainig and maximizing performance. A simple rule is to aim to drink 2.5 liters (or ten 8oz glasses) of water per day. ',
  },
  sweat: {
    prepare:
      'In more general terms, this is your warm-up. Prepare readies your body for exercise, improves flexibility and mobility, and reduces the potential for injury.',
    sweat:
      'This is the conditioning portion of your training session and is where muscle adaptation and endurance are activated.',
    recover:
      "This refers to your cool-down or recovery for the day or a training session. Whether you're using a foam roller or static stretching, recovery is a critical component to staying healthy and ready to perform every day.",
  },
  thrive: {
    stress:
      'Stress is not always a bad thing, but it can show up in many different ways and in different areas of our lives. Stressful situations are those require us to work through discomfort or pressure towards an outcome.',
    sleep:
      'Sleep is critical for mental and physical health and performance. Rate your sleep quality and quantity and think about things you can do to optimize your sleep habits, mindset, and environment to get better quality rest every night.',
    resilience:
      'Resilience is not the absence of stress, but rather your ability to handle stress, overcome challenges, and turn obstacles into opportunities. It is our ability to withstand stress and grow from it.',
  },
};

const Topics: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  const { task = TASK_TYPE.EAT } = props;

  const [isModalVisible, setModalVisible] = useState(false);
  const [topicContent, setTopicContent] = useState('');

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const showTopicContent = (task: TASK_TYPE, topic: string) => {
    setTopicContent(topics[task][topic]);
    showModal();
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        backdropStyle={styles.backdrop}
        style={styles.modal}
        onBackdropPress={hideModal}
      >
        <Layout style={styles.card}>
          <Text style={styles.description}>{topicContent}</Text>
        </Layout>
      </Modal>
      <View style={styles.topicContainer}>
        {Object.keys(topics[task]).map((topic) => (
          <TouchableOpacity
            style={styles.topicButton}
            key={topic}
            onPress={() => showTopicContent(task, topic)}
          >
            <Text category="s2" style={styles.topic}>
              {topic}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
  },
  topicContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 30,
  },
  topic: {
    textTransform: 'uppercase',
    color: '#c4c4c4',
  },
  topicButton: {
    backgroundColor: '#203950',
    paddingHorizontal: 14,
    marginHorizontal: 6,
    justifyContent: 'center',
  },
  description: {
    fontSize: 16,
    color: 'white',
    lineHeight: 22,
    marginBottom: 12,
  },
  card: {
    padding: 12,
  },
  backdrop: {
    backgroundColor: '#091C2D',
    opacity: 0.8,
  },
});

export default Topics;
