import {
  model,
  Model,
  modelAction,
  ModelInstanceCreationData,
  prop,
} from 'mobx-keystone';
import { EMOJI_NAMES } from '../utils/emoji';
import { TASK_TYPE } from '../utils/tasks';

export type TeamMember = {
  id: number;
  fullName: string;
  profileImageThumbnail: string | null;
};

@model('o2x-store/Team')
export default class Team extends Model({
  id: prop<number>(),
  user: prop<number>(),
  name: prop<string>(''),
  logo: prop<string>(''),
  logoThumbnail: prop<string>(''),
  logoBanner: prop<string>(''),
  members: prop<Array<TeamMember>>(Array),
  pendingMembers: prop<Array<TeamMember>>(Array),
  formAssessments: prop<Array<TeamAssessment>>(Array),
  physicalAssessments: prop<Array<TeamAssessment>>(Array),
  assessments: prop<Array<TeamAssessment>>(Array),
  teamActivities: prop<Array<TeamActivity>>(Array),
  pending: prop<boolean>(false),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<Team>) {
    Object.assign(this, data);
  }
}

export type ReactionList = {
  [k: string]: number | Array<string>;
};

export type TeamActivityExtraData = {
  type: TASK_TYPE | 'general';
  badge?: string | null;
  cta?: ACTIVITY_CTA_TYPE | null;
  reactions?: ReactionList | null;
  rating?: number | null;
};

@model('o2x-store/TeamActivity')
export class TeamActivity extends Model({
  id: prop<number>(),
  actorObjectId: prop<string | null>(),
  actorAvatarUrl: prop<string | null>(),
  verb: prop<string>(),
  description: prop<string>(),
  targetObjectId: prop<string | null>(),
  actionObjectObjectId: prop<string | null>(),
  timestamp: prop<Date>(),
  data: prop<TeamActivityExtraData>(),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<TeamActivity>) {
    Object.assign(this, data);
  }
}

@model('o2x-store/TeamAssessment')
export class TeamAssessment extends Model({
  assessmentId: prop<number>(),
  assessmentName: prop<string>(),
  assessmentImageUrl: prop<string | null>(),
  maxScore: prop<string | null>(),
  score: prop<string | null>(),
}) {
  @modelAction
  update(data: ModelInstanceCreationData<TeamAssessment>) {
    Object.assign(this, data);
  }
}

export type UserReaction = {
  reaction: EMOJI_NAMES;
  id: number;
  name: string;
  imageThumbnail: string;
};

export type ValidatedUser = {
  id: number;
  name: string;
  imageThumbnail: string;
};

export enum ACTIVITY_CTA_TYPE {
  START_SWEAT_PROGRAM = 'start_sweat_program',
  START_THRIVE_PROGRAM = 'start_thrive_program',
  START_SWEAT_WORKOUT = 'start_sweat_workout',
  START_PHYSICAL_ASSESSMENT = 'start_physical_assessment',
  START_FORM_ASSESSMENT = 'start_form_assessment',
}
