import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Layout, StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import Question from 'o2x-store/src/models/Question';
import { Topic } from 'o2x-store/src/models/UserQuestion';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import LabeledInput from '../../components/LabeledInput';
import BottomNav from '../../components/Question/BottomNav';
import SelectInput from '../../components/Question/SelectInput';
import TopNav from '../../components/Question/TopNav';
import { getErrors } from '../../utils/errors';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AskAnExpert'>;
};

const AskAnExpert: React.FC<Props> = (props) => {
  const { initial } = props.route.params;
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const { register, setValue, getValues, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    error: '',
    success: '',
  });

  const isDeviceMaxWidth600 = useMediaQuery({
    maxDeviceWidth: 600,
  });

  useFocusEffect(
    useCallback(() => {
      store.userQuestion.fetchTopics();
    }, []),
  );

  useEffect(() => {
    register({ name: 'topic' }, { required: true });
    register({ name: 'content' }, { required: true });
  }, [register, setValue]);

  useFocusEffect(
    useCallback(() => {
      if (initial) {
        setValue('topic', initial);
      }
    }, [initial]),
  );

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const result = await store.userQuestion.createQuestion(data);
      setLoading(false);
      if (result.ok) {
        setMessage({
          success:
            'You have successfully submitted a question! \nOne of our experts will contact you shortly.',
          error: '',
        });
      } else {
        setMessage({ error: getErrors(result.errors), success: '' });
      }
    },
    [setLoading, setMessage],
  );

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onChange = useCallback((key: string, value: any) => {
    setValue(key, value);
  }, []);

  const question = new Question({
    question: 'Topic',
    type: 'select',
    key: 'topic',
    options: Array.from(store.userQuestion.topics.values()).map((t: Topic) => [t.id, t.name]),
    label: 'topic',
  });

  const goBack = useCallback(() => {
    navigation.goBack();
  }, []);

  return (
    <TouchableWithoutFeedback>
      <View style={styles.modalOverlay}>
        <Layout style={isDeviceMaxWidth600 ? styles.modalMaxWidth600 : styles.modal}>
          <Layout style={styles.container}>
            <Layout style={styles.navigationContainer}>
              <TopNav onClose={goBack} showBack={false} showClose />
            </Layout>
            <Layout
              style={
                isDeviceMaxWidth600 ? styles.contentContainerMaxWidth600 : styles.contentContainer
              }>
              <Text style={styles.title}>Ask an Expert</Text>
              {!!message.error && (
                <Text style={styles.error} category="label">
                  {message.error}
                </Text>
              )}
              {!!message.success && (
                <Text style={styles.success} category="label">
                  {message.success}
                </Text>
              )}
              <View style={styles.category}>
                <Text style={styles.categoryLabel}>{question.label}</Text>
                <SelectInput question={question} onChange={onChange} required initial={null} />
              </View>
              <View style={styles.category}>
                <LabeledInput
                  autoCapitalize="none"
                  returnKeyType="next"
                  onChangeText={(text) => setValue('content', text)}
                  label="Question"
                  divider
                  multiline
                />
              </View>
            </Layout>
            <BottomNav
              onBack={() => {}}
              showBack={false}
              onNext={onSubmitWrapped}
              nextLabel="Submit"
              nextColor={theme.olive}
            />
          </Layout>
        </Layout>
      </View>
    </TouchableWithoutFeedback>
  );
};

const themedStyles = StyleService.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modal: {
    width: '70%',
    height: '90%',
    maxWidth: 800,
  },
  modalMaxWidth600: {
    width: '90%',
    height: '90%',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  navigationContainer: {
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: '25%',
    marginTop: -20,
  },
  contentContainerMaxWidth600: {
    flex: 1,
    paddingHorizontal: '5%',
  },
  title: {
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 24,
    textAlign: 'center',
  },
  description: {
    fontSize: 18,
    color: 'white',
    lineHeight: 22,
    marginBottom: 32,
  },
  bottomNav: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  backButton: {
    backgroundColor: 'dark-blue',
    flex: 1,
    padding: 9,
  },
  nextButton: {
    backgroundColor: 'green',
    flex: 1,
    padding: 9,
  },
  buttonText: {
    fontSize: 14,
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  category: {
    marginBottom: 40,
  },
  categoryLabel: {
    color: 'white',
    fontSize: 12,
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  error: {
    color: 'danger',
    marginBottom: 15,
  },
  success: {
    backgroundColor: 'blue-secondary',
    paddingVertical: 4,
    paddingHorizontal: 10,
    color: 'white',
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default observer(AskAnExpert);
