import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import Resource from 'src/containers/Resource';
import VideoModal from 'src/containers/Resource/VideoModal';
import Header from '../../Header';

type Props = {};

const Stack = createStackNavigator();

const ResourceStack: React.FC<Props> = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      cardStyleInterpolator: ({ current: { progress } }) => ({
        cardStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1],
          }),
        },
        overlayStyle: {
          opacity: progress.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.5],
            extrapolate: 'clamp',
          }),
        },
      }),
      headerStyle: {
        backgroundColor: '#10283E',
        borderBottomWidth: 0,
      },
      headerTitle: () => <Header />,
      headerLeft: () => null,
    }}
    mode="modal"
  >
    <Stack.Screen name="Resource" component={Resource} />
    <Stack.Screen name="VideoModal" component={VideoModal} />
  </Stack.Navigator>
);

export default ResourceStack;
