import { model, Model, modelAction, prop } from 'mobx-keystone';
import { TASK_TYPE } from 'o2x-store/src/utils/tasks';

@model('o2x-native/Settings')
export default class Settings extends Model({
  hasSeenExploreLanding: prop<boolean>(false),
  lastExplore: prop<TASK_TYPE | undefined>(),
}) {
  @modelAction
  setHasSeenExploreLanding = (value: boolean) => {
    this.hasSeenExploreLanding = value;
  };

  @modelAction
  setLastExplore = (value: TASK_TYPE | undefined) => {
    this.lastExplore = value;
  };
}
