import { RouteProp, useFocusEffect, useNavigation } from '@react-navigation/native';
import { CheckBox, Layout, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { ASSESSMENT_TYPE } from 'o2x-store/src/models/Assessment';
import FormAssessment from 'o2x-store/src/models/FormAssesment';
import { POPUP_CONTAINER_CHOICES } from 'o2x-store/src/models/Tutorial';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useRef, useState } from 'react';
import { Alert, Animated, FlatList, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import NavigationBar from 'src/components/NavigationBar';
import BottomNav from 'src/components/Question/BottomNav';
import config from 'src/config';
import { useIsSubscribed } from 'src/hooks/subscription';
import Popup from '../../components/Popup';
import { AppStackParamList } from '../AppContainer';

type Props = {
  route: RouteProp<AppStackParamList, 'AssessmentSectionMenu'>;
};

type SectionData = {
  name: string;
  done: boolean;
  totalAnswered: number;
  totalCount: number;
};

const AnimatedScrollView = Animated.createAnimatedComponent(ScrollView);

const AssessmentSectionMenu: React.FC<Props> = (props) => {
  const { type, assessmentId } = props.route.params;
  const { auth, assessment: assessmentStore, user: userStore } = useStore();
  const assessment = assessmentStore.getAssessment(assessmentId, type);

  if (!(assessment instanceof FormAssessment)) {
    return <></>;
  }

  const { navigateWithPayWall, isSubscribed } = useIsSubscribed();

  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const titleOpacity = useRef(new Animated.Value(0));
  const [sections, setSections] = useState<SectionData[]>([]);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        if (!isSubscribed) {
          navigateWithPayWall('PayWall');
          return <></>;
        }
        await userStore.fetchMe();
        if (assessment?.currentSubmission) {
          await assessmentStore.fetchFormAssessmentSubmission(assessment?.currentSubmission);
          const formAssessmentSubmission = assessmentStore.formAssessmentSubmissions.get(
            `${assessment?.currentSubmission}`,
          );
          if (formAssessmentSubmission?.sectionsData) {
            setSections(formAssessmentSubmission?.sectionsData);
          }
        }
      })();
    }, [auth.user, isSubscribed, userStore]),
  );

  const onSubmit = useCallback(() => {
    if (type === ASSESSMENT_TYPE.FORM) {
      const allDone = sections.every((section) => section.done);
      if (allDone) {
        navigation.navigate('AssessmentSummary', {
          assessmentId,
          currentSubmission: assessment.currentSubmission,
          type: ASSESSMENT_TYPE.FORM,
        });
      } else {
        Alert.alert('Incomplete Sections', 'You have not finished all sections yet.');
      }
      // not yet supported
    } else if (type === ASSESSMENT_TYPE.PHYSICAL) {
      navigation.navigate('PhysicalAssessmentDetail', { assessmentId });
    } else {
      console.warn('[DEBUG] assessment type is not yet handled', type);
    }
  }, [isSubscribed, assessment, sections]);

  const onSectionPress = useCallback(
    (section: string) => {
      navigation.navigate('FormAssessmentSectionDetail', {
        assessmentId,
        section,
      });
    },
    [assessment, assessmentId],
  );

  const renderItem = useCallback(
    ({ item }) => {
      const section = sections.find((section) => section.name === item);
      const checked = section?.done || false;

      return (
        <TouchableOpacity onPress={() => onSectionPress(item)}>
          <View style={styles.itemContainer}>
            <View style={styles.sectionItem}>
              <CheckBox checked={checked} disabled />
              <Text style={styles.itemText}>{item}</Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    [sections],
  );

  if (!assessment) return <></>;

  return (
    <Layout style={styles.container}>
      <Popup component={POPUP_CONTAINER_CHOICES.ASSESSMENT_SECTION_MENU} />
      <Layout style={{ paddingTop: insets.top }}>
        <NavigationBar
          title="Assessment Sections"
          titleOpacity={titleOpacity.current.interpolate({
            inputRange: [0, config.titleDisplayOffsetOnScroll],
            outputRange: [0, 1],
          })}
        />
      </Layout>
      <AnimatedScrollView
        style={styles.content}
        contentContainerStyle={styles.contentContainer}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: titleOpacity.current } } }],
          { useNativeDriver: true },
        )}>
        <Text style={styles.category}>{assessment.name}</Text>
        <FlatList
          data={Array.from(assessment?.sectionList)}
          renderItem={renderItem}
          keyExtractor={(item) => `${item}`}
        />
      </AnimatedScrollView>
      <Layout>
        <BottomNav nextLabel="Submit" onNext={onSubmit} />
      </Layout>
    </Layout>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    paddingBottom: 16,
    marginHorizontal: 24,
  },
  title: {
    marginHorizontal: 24,
    marginTop: 16,
    marginBottom: 4,
  },
  category: {
    textTransform: 'uppercase',
    fontSize: 24,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 32,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionItem: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 15,
    marginBottom: 10,
    backgroundColor: 'dark-blue',
    borderStyle: 'solid',
    borderLeftWidth: 4,
    borderColor: 'blue-secondary',
  },
  itemText: {
    color: 'white',
    marginLeft: 10,
  },
});

export default observer(AssessmentSectionMenu);
