import React from 'react';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Text } from 'react-native';

interface Props {
  label: string;
}

const InputAccessory: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { label } = props;
  return <Text style={styles.label}>{label}</Text>;
};

const themedStyles = StyleService.create({
  label: {
    color: 'light-gray',
    fontSize: 14,
  },
});

export default InputAccessory;
