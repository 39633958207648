import { useActionSheet } from '@expo/react-native-action-sheet';
import { Layout } from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import * as IntentLauncher from 'expo-intent-launcher';
import * as Linking from 'expo-linking';
import * as Permissions from 'expo-permissions';
import { observer } from 'mobx-react-lite';
import { useStore } from 'o2x-store/src/stores';
import React, { useCallback, useState } from 'react';
import {
  ActivityIndicator,
  Alert,
  Image,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import CameraIcon from '../../assets/images/camera.svg';
import { askPermissions } from '../../utils/permissions';
import { convertPhoto, formatPhoto } from '../../utils/photos';

type Props = {
  onLoading: (isLoading: boolean) => void;
};

const ProfileHeader: React.FC<Props> = (props) => {
  const { auth, user: userStore } = useStore();
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const { onLoading } = props;
  const { showActionSheetWithOptions } = useActionSheet();

  const onSelectPhoto = useCallback(
    async (imageInfo: ImageInfo) => {
      setLoading(true);
      onLoading(true);

      const photo = await convertPhoto(formatPhoto(imageInfo));
      setPhoto(photo);

      await userStore.updateMePhoto(photo);
      setLoading(false);
      onLoading(false);
    },
    [userStore, onLoading],
  );

  const openSettings = useCallback(() => {
    // TODO: checkPermissions again after callback
    if (Platform.OS === 'ios') {
      Linking.canOpenURL('app-settings:')
        .then((supported) => {
          if (!supported) {
            console.warn('[DEBUG] open settings is not supported');
          } else {
            Linking.openURL('app-settings:');
          }
        })
        .catch((error) => console.error('[DEBUG] open settings error', error));
    } else {
      // TODO: see if this works.
      // See: https://forums.expo.io/t/opening-device-settings-on-android-using-linking/2059/8
      IntentLauncher.startActivityAsync(
        IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
        {},
        // `package:${Constants.manifest.android.package}`,
      );
    }
  }, []);

  const alertPermissions = useCallback(() => {
    Alert.alert(
      'Required camera permission',
      'O2X requires camera permission.',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Go to Settings',
          onPress: openSettings,
        },
      ],
    );
  }, [openSettings]);

  const takePhoto = useCallback(async () => {
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.cancelled) {
      onSelectPhoto(result as ImageInfo);
    }
  }, [onSelectPhoto]);

  const openLibrary = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.cancelled) {
      onSelectPhoto(result as ImageInfo);
    }
  }, [onSelectPhoto]);

  const showActionSheet = useCallback(() => {
    showActionSheetWithOptions(
      {
        options: ['Take photo', 'Upload from gallery', 'Cancel'],
        cancelButtonIndex: 2,
        textStyle: {
          textTransform: 'uppercase',
          color: 'white',
          fontSize: 14,
        },
        containerStyle: {
          backgroundColor: '#203950',
        },
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          takePhoto();
        } else if (buttonIndex === 1) {
          openLibrary();
        }
      },
    );
  }, [openLibrary, showActionSheetWithOptions, takePhoto]);

  const onPress = useCallback(() => {
    askPermissions(
      [Permissions.CAMERA, Permissions.CAMERA_ROLL],
      showActionSheet,
      alertPermissions,
    );
  }, [alertPermissions, showActionSheet]);

  let uri: string = '';
  if (photo) {
    uri = photo?.photo.thumbnail || photo?.photo.fullSize || photo?.uri;
  } else if (auth.user?.profileImage) {
    uri = auth.user?.profileImage;
  }

  return (
    <Layout style={styles.header}>
      <Layout style={styles.strip} />
      <TouchableOpacity style={styles.headerButton} onPress={onPress}>
        {uri ? (
          <Image style={styles.headerButton} source={{ uri }} />
        ) : (
          <Image
            style={styles.headerButton}
            source={require('../../assets/images/user_placeholder.png')}
          />
        )}
        <CameraIcon style={styles.icon} />
        {loading && (
          <Layout style={styles.loading}>
            <ActivityIndicator />
          </Layout>
        )}
      </TouchableOpacity>
    </Layout>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingTop: 20,
  },
  headerButton: {
    borderRadius: 60,
    height: 91,
    width: 91,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  strip: {
    height: 40,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  loading: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  icon: {
    width: 30,
    height: 30,
    position: 'relative',
    left: -36,
    bottom: -4,
  },
});

export default observer(ProfileHeader);
